import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductVariant } from "../../../../../utilities";
import ActionButtons from "./actionButtons";
import { useFormik } from "formik";
import { fetchConfig } from "../../../../../middleware/services/cmsApi";
import { getCompanyDisplay, saveCompanyDisplay, fetchCompanyTrend } from "../../../../../middleware/services/companyApi";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import ImageList from "./imageList";

const DisplaySettings = (props) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [addToWidgets, setAddToWidgets] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const formik = useFormik({
    initialValues: {
      companyAddToWidgets: "",
      companyKeyPlayerImage: "",
    },
  });

  useEffect(() => {
    if (props.companyId != "") {
      fetchKeyPlayerImages();
      fetchDisplaySettings();
    }
  }, []);

  const fetchDisplaySettings = async () => {
    try {
      dispatch(showLoader());
      const response = await getCompanyDisplay(props.companyId);
      if (response) {
        let data = response?.data?.data;
        formik.setValues({
          companyAddToWidgets: data.companyAddToWidgets,
          companyKeyPlayerImage: data.companyKeyPlayerImage,
        });

        if (data.companyAddToWidgets === "YES") setAddToWidgets(true);
        else setAddToWidgets(false);

        setSelectedImage(data.companyKeyPlayerImage);
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error("err >>>", err);
      dispatch(actionError(err?.data?.message || "Something went wrong"));
    }
  };

  const fetchKeyPlayerImages = async () => {
    try {
      const companyTrendResponse = await fetchCompanyTrend(props.companyId);
      const companyTrend = companyTrendResponse?.data?.data || [];

      const taggedIndustry = companyTrend
        .filter((item) => item.trendParentId === 0 && item.trendRoot === 0)
        .map(({ trendName, trendId }) => ({ trendName, trendId }));

      const filters = [["config_name", "eq", "company-key-player-images"]];
      const params = {
        filters,
        fields: ["configValue"],
        limit: 1000,
      };

      const configResponse = await fetchConfig(params);
      const data = configResponse?.data?.data || [];

      let filteredImages = data[0]?.configValue.filter((image) => {
        // Check if there is a match in taggedIndustry for trendId and trendName
        const match = taggedIndustry.find((tag) => tag.trendId === image.industryId && tag.trendName === image.industryName);

        // If there is a match, include the image in the filtered array
        return match !== undefined;
      });

      setImages(filteredImages);
    } catch (err) {
      console.error("err >>>", err);
      dispatch(actionError(err?.data?.message || "Something went wrong"));
    }
  };

  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;

  const handleSave = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let overviewData = {
          companyAddToWidgets: addToWidgets === true ? "YES" : "NO",
          companyKeyPlayerImage: selectedImage,
        };
        let saveOverviewData = new FormData();

        for (let uKey in overviewData) {
          if (typeof overviewData[uKey] != "object") {
            if (overviewData[uKey] && overviewData[uKey] !== "") saveOverviewData.append(uKey, overviewData[uKey]);
          }
        }

        saveCompanyDisplay(props.companyId, saveOverviewData)
          .then((res) => {
            dispatch(actionSuccess("Display Settings updated successfully"));
          })
          .catch((err) => {
            dispatch(actionError(err?.data?.message || "Something went wrong"));
          });
      }
    });
  };

  return (
    <div
      className="pt-3 mx-2 gennx-content-wrapper padding-bottom-100i footer-button-wrapper"
      style={{ padding: "1%", marginTop: "1%", "background-color": "#ffffff", width: "98.7%", "border-radius": "5px" }}
    >
      <div className="mt-5 mb-2 ml-3">
        <label>Add To Widget On Landing Page?</label>
        <label className="custom-switch">
          <input
            id="companyAddToWidgets"
            name="companyAddToWidgets"
            type="checkbox"
            value={addToWidgets ? "YES" : "NO"}
            checked={addToWidgets}
            onChange={(e) => {
              setAddToWidgets(!addToWidgets);
            }}
          />
          <div>
            <span className="on">YES</span>
            <span className="off">NO</span>
          </div>
          <i></i>
        </label>
      </div>
      {addToWidgets && (
        <>
          <div className="mt-2 ml-3">
            <strong>Select Image:</strong>
          </div>
          <ImageList images={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        </>
      )}

      {props.path == "/company/:companyId/edit" && (
        <div className={props.companyId != "" ? "form-submit-box" : "form-submit-box d-none"}>
          <ActionButtons
            accessRights={props.accessRights}
            companyId={props.companyId}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            handleSave={handleSave}
            previousPath={`/company/${props.companyId}/edit#taxonomy`}
            formik={formik}
            hashArr={props.hashArr}
          />
        </div>
      )}
    </div>
  );
};
export default DisplaySettings;
