import React, { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

export const StringFilter = (e) => {
  return (
    <div className="form-group">
      <div className="e-float-input e-control-wrapper e-input-group e-outline">
        <input
          autoComplete="off"
          type="text"
          placeholder="Enter the value"
          className="form-control e-control e-textbox e-lib"
          defaultValue={e.value}
        />
      </div>
    </div>
  );
};

export const NumberFilter = (e) => {
  return (
    <div className="form-group">
      <div className="e-float-input e-control-wrapper e-input-group e-outline">
        <input
          autoComplete="off"
          type="number"
          placeholder="Enter the value"
          className="e-flmenu-input e-control e-autocomplete e-lib e-input e-keyboard"
          defaultValue={e.value}
        />
      </div>
    </div>
  );
};

export const DateFilter = (e) => {
  return (
    <input
      autoComplete="off"
      type="date"
      placeholder="Enter the value"
      className="e-flmenu-input e-control e-autocomplete e-lib e-input e-keyboard"
      defaultValue={e.value}
    />
  );
};

export const BooleanFilter = (e) => {
  return (
    <input
      autoComplete="off"
      type="number"
      placeholder="Enter the value (0 or 1)"
      className="e-flmenu-input e-control e-autocomplete e-lib e-input e-keyboard"
      min="0"
      max="1"
      defaultValue={e.value}
    />
  );
};
export const itemFilter = (value) => {
  if (value.column.field === "Overview") {
    // if(!filterArr.includes(value["Overview"])){filterArr.push(value["Overview"])}
    return (
      <>
        {value["Overview"] === "COMPLETE" && <span>Completed</span>}
        {value["Overview"] === "In Progress" && <span>In Progress</span>}
        {value["Overview"] === "" && <span>Blank</span>}
      </>
    );
  }
};
export const DropDownFilter = (props) => {
  const { value, sourceData } = props;
  const [dropDownValue, setDropDownValue] = useState(sourceData[0].id);
  const onChange = (e) => {
    e.preventDefault();
    setDropDownValue(e.target.value);
  };
  return (
    <select
      className="form-control form-control-sm selectPicker "
      onChange={onChange}
      value={dropDownValue}
      name={`fl-${value.column.field}`}
      //  multiple data-live-search="true"
      id={value.column.field}
    >
      {/* <option value=" ">Select All</option> */}
      {sourceData &&
        sourceData.map((item, i) => {
          return (
            <option
              key={`${value.column.field}-${i}`}
              value={
                [
                  "Trend",
                  "Technology",
                  "Industry",
                  "Client Users",
                  "Screening Mode",
                  "Relevancy",
                  "Relevancy Type",
                  "Notification Type",
                  "Communication Mode",
                  "Type of Block",
                  "Visibility.id",
                  "Ticket Category.id",
                ].includes(value.column.field)
                  ? item.text
                  : item.id
              }
            >
              {item.text}
            </option>
          );
        })}
    </select>
  );
};

export const yesNoFilter = (value) => {
  let sourceData = [
    { id: "YES", text: "YES" },
    { id: "NO", text: "NO" },
  ];
  return <DropDownFilter value={value} sourceData={sourceData} />;
};
