/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Industry Use case component nent for Add/Edit
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/June/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { addCurrentChallenge, updateCurrentChallenge, getSingleCurrentChallenge } from "../../../../../middleware/services/kiaApi";
import { addCurrentChallengesValidationSchema } from "../validationSchema";
import { Dropdown,CkEditor } from "../formFields";
import { useFormik } from "formik";
import { TIME_SCALE ,STATUS_OPTIONS} from "../formFieldOptions";

import * as Yup from "yup";

const initialState = {
	challenge:"",
	challengeLevel:"",
	challangeDescription:"",
	challangePotentialBenefit :"",
	challengeTimescale :"",
}

const AddExponentialTechModel = (props) => {
	const dispatch = useDispatch();
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { ...initialState },
		validationSchema: Yup.object().shape(addCurrentChallengesValidationSchema)
	});

	const saveCurrentChallenge = () => {
		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
			} else {
				let payload = {};
				const { challenge, challengeLevel, challangeDescription,challangePotentialBenefit,challengeTimescale} = formik.values;
				payload.challenge = challenge;
				payload.challengeLevel = challengeLevel;
				payload.challangeDescription = challangeDescription;
				payload.challangePotentialBenefit = challangePotentialBenefit;
				payload.challengeTimescale = challengeTimescale;
     			const formData = new FormData();
				for (let key in payload) {
					formData.append(key, payload[key]);
				}
				if (props.chgId) {
					updateCurrentChallenge(props.chgId,formData).then(res => {
						dispatch(actionSuccess("Current Challenges updated successfully"));
						props.closeModal();
						props.setDeleteId(new Date())
					}).catch(err => {
						dispatch(actionError(err?.data?.message || "Something went wrong"));
					})
				} else {
					addCurrentChallenge(props.kiaId,formData).then(res => {
						if (res) {
							dispatch(actionSuccess("Current Challenges saved successfully"));
							props.closeModal();
							props.setDeleteId(new Date())
						} else {
							dispatch(actionError("Something went wrong"));
						}
					}).catch(err => {
						dispatch(actionError(err?.data?.message || "something went wrong"));
					})
				}
			}
		})

	}
	/*
       @Description : Fetch Single Current Challenge
    */
	   useEffect(() => {
		  if (props.chgId !== "") {
			dispatch(showLoader());
			getSingleCurrentChallenge(props.chgId).then((response) => {
				dispatch(hideLoader());
				if (response.status == 200) {
					var currentChallenge = response.data.data || {};
					setTimeout(() => {
					formik.setValues({
						challenge: currentChallenge.challenge,
						challengeLevel: currentChallenge.challengeLevel,
						challangeDescription: currentChallenge.challangeDescription,
						challangePotentialBenefit:currentChallenge.challangePotentialBenefit,
						challengeTimescale:currentChallenge.challengeTimescale

					});
				}, 200);
				}

			});
		}
	
   }, [props.showModal,props.chgId]);

	return <>
		<Modal
			show={props.showModal}
			size="xl"
			backdrop="static"
			className="modal fade people-mgmt"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<form id="addExp" method="post">
				<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">{props.chgId?"Update":"Add"} Current Challenges</h5>
				</div>
				<Modal.Body>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<Dropdown
									formik={formik}
									required="required"
									placeholder="Challenge Level*"
									id="challengeLevel"
									name="challengeLevel"
									value={formik.values.challengeLevel}
									fields={{ text: "label", value: "value" }}
									dataSource={STATUS_OPTIONS}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<Dropdown
									formik={formik}
									required="required"
									placeholder="Time Scale *"
									id="challengeTimescale"
									name="challengeTimescale"
									fields={{ text: "label", value: "value" }}
									value={formik.values.challengeTimescale}
									dataSource={TIME_SCALE}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group required">
							<label className="font-weight-bold" htmlFor="applicationDescription">Challenge</label>
                                <CkEditor
                                        name="challenge"
                                        formik={formik}
                                        id="challenge"
                                        activeClass="editor"
										charCountLimit={2500}
                                />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group required">
							<label className="font-weight-bold" htmlFor="applicationImpact">Potential Benefit </label>
                                <CkEditor
                                        name="challangePotentialBenefit"
                                        formik={formik}
                                        id="challangePotentialBenefit"
                                        activeClass="editor"
										charCountLimit={2500}
                                />
								
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group required">
							<label className="font-weight-bold" htmlFor="challangeDescription">Description</label>
                                <CkEditor
                                        name="challangeDescription"
                                        formik={formik}
                                        id="challangeDescription"
                                        activeClass="editor"
										charCountLimit={2500}
                                />	
							
							</div>
						</div>
					</div>
				
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-outline-dark cy-btn mr-3"
						onClick={() => {
							props.closeModal()
						}}
					>
						Cancel
					</button>
					<button type="button" className="btn btn-primary cy-btn"
						onClick={saveCurrentChallenge}
					>{props.chgId?"Update":"Save"}</button>
				</Modal.Footer>
			</form>
		</Modal>
	</>
}

export default AddExponentialTechModel