/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Client User Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef, useCallback } from "react";
import { ButtonGroup, DropdownButton, Dropdown as Dropdown1 } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import Tabs from "../../../components/tabsComponent";
import { actionStart, actionSuccess, actionError, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../components/accordion";
import { addClientUserValidationSchema, editClientUserValidationSchema } from "../../../helper/validationSchema/userValidationSchema";
import {
  addUser,
  updateUser,
  fetchSingleUser,
  sendLoginCredentials,
  fetchUserProfileImageUrl,
  fetchUserSubscription,
  sendUserCredentials,
} from "../../../../middleware/services/userApi";
import { fetchCountry } from "../../../../middleware/services/cmsApi";
import userImage from "../../../../assets/images/user.jpg";
import { getLoggedInUser, isAdmin, getProductVariant, accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import Relationship from "./subscription";
import { TextField, DatePicker, AutoCompleteDropdown, Dropdown, RadioGroup } from "../../../components/CustomFormElements/syncFusionFormFields";
import { GENDER_LIST, SALUTATION_LIST, PRODUCT_VARIANT, COMMON_ACCESS_ACTION } from "../../../../constants";
import AccessDenied from "../../../components/accessDenied";

const TABS_NAME = ["User Profile", "Relationship"];
const initialValues = {
  userSalutation: "",
  userFname: "",
  userMname: "",
  userLname: "",
  userGender: "",
  userEmail: "",
  userAltEmail1: "",
  // "userAltEmail2": "",
  // "userPersonalEmail": "",
  userMobile: "",
  // "userAltMobile": "",
  // "userOfficeMobile": "",
  userContactTelNo: "",
  twitter: "",
  linkedin: "",
  facebook: "",
  userDesignation: "",
  userCompany: "",
  // "userPassword": "",
  // "userConfirmPassword": "",
  country: "",
  addressLine: "",
  addressLine2: "",
  areaName: "",
  region: "",
  city: "",
  state: "",
  postCode: "",
  addressType: "",
  userActive: undefined,
};

const UserProfile = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const history = useHistory();
  const userTokenDetails = getLoggedInUser();
  let productVariant = getProductVariant();
  const editUser = props.path === "/client-user/add" ? false : true;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [subscriptionsProductVariant, setSubscriptionsProductVariant] = useState([]);
  const isMultipleProductVariant = userTokenDetails.product_variant?.length > 1 ? true : false;
  const userId = editUser ? props.computedMatch?.params?.userId : props.userId || "";
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionSendCredentials = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEND_CREDENTIALS);
  const accessActionResetPassword = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.RESET_PASSWORD);
  const accessActionEditProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_PROFILE_IMAGE);
  const accessActionDeleteProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_PROFILE_IMAGE);

  const hashArr = {
    "#profile": "User Profile",
    "#subscription": "Relationship",
  };

  // specify breadcrumb here
  const breadCrumbLinksAddUser = [
    { linkUrl: "/client-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: "/client-user/add", linkName: "Add User", linkActive: true },
  ];
  const breadCrumbLinksEditUser = [
    { linkUrl: "/client-user", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: `/client-user/${userId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const dispatch = useDispatch();
  const [existingUser, setExistingUser] = useState({});
  const [userProfileImgSrc, setUserProfileImgSrc] = useState(undefined);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editUser
      ? Yup.object().shape({
          ...editClientUserValidationSchema,
          // productVariant: Yup.array().min(1)
        })
      : Yup.object().shape({
          ...addClientUserValidationSchema,
          // productVariant: Yup.array().min(1).required("Product Variant is required")
        }),
  });

  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = (userId) => {
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };
        if (userDetail.userProfileImage && userDetail.userProfileImage !== "") {
          setUserProfileImgSrc(fetchUserProfileImageUrl(userDetail.userProfileImage));
          setIsDefaultImage(false);
        } else {
          setIsDefaultImage(true);
        }
        setExistingUser(userDetail);
        userDetail && userDetail.userDob && formik.setFieldValue("userDob", new Date(userDetail.userDob));
        fetchUserSubscriptionDetail();
        formik.setValues({
          ...formik.values,
          userSalutation: userDetail?.userSalutation || "",
          userFname: userDetail?.userFname || "",
          userMname: userDetail?.userMname || "",
          userLname: userDetail?.userLname || "",
          userGender: userDetail?.userGender || "",
          userEmail: userDetail?.userEmail || "",
          userAltEmail1: userDetail?.userAltEmail1 || "",
          // userAltEmail2: userDetail?.userAltEmail2 || "",
          // userPersonalEmail: userDetail?.userPersonalEmail || "",
          userMobile: userDetail?.userMobile || "",
          // userAltMobile: userDetail?.userAltMobile || "",
          // userOfficeMobile: userDetail?.userOfficeMobile || "",
          userContactTelNo: userDetail?.userContactTelNo || "",
          userDesignation: userDetail?.userDesignation || "",
          userCompany: userDetail?.userCompany || "",
          twitter: userDetail?.userSocial?.twitter || "",
          linkedin: userDetail?.userSocial?.linkedin || "",
          facebook: userDetail?.userSocial?.facebook || "",
          addressLine: userDetail?.userAddress?.addressLine || "",
          addressLine2: userDetail?.userAddress?.addressLine2 || "",
          country: userDetail?.userAddress?.country || "",
          state: userDetail?.userAddress?.state || "",
          city: userDetail?.userAddress?.city || "",
          areaName: userDetail?.userAddress?.areaName || "",
          postCode: userDetail?.userAddress?.postCode || "",
          region: userDetail?.userAddress?.region || "",
          addressType: userDetail?.userAddress?.addressType || "",
        });

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description add new user
   * @param {Object} userData
   */
  const addNewUser = (userData) => {
    dispatch(actionStart());
    addUser(userData)
      .then((res) => {
        setUserProfileImgSrc(undefined);
        userProfileImgRef.current.setAttribute("src", userImage);
        dispatch(actionSuccess("User profile has been added"));
        history.push(`/client-user/${res.data.data.id}/edit#profile`);
        window.location.reload();
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter a valid email"
            : err?.data?.message
          : "Add new user failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description update existing user
   * @param {Object} userData
   */
  const updateExistingUser = (userData) => {
    dispatch(actionStart());
    updateUser(userId, userData)
      .then((res) => {
        dispatch(actionSuccess("User profile has been updated"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter valid email"
            : err?.data?.message
          : "User profile updation failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description Fetch users subscription variants
   * @param {Object} subscription's variant data
   */
  const fetchUserSubscriptionDetail = (params = {}) => {
    const defaultFilter = [
      ["userId", "eq", userId],
      ["subscriptionStatus", "eq", "Active"],
    ];
    let { filters = [] } = params;
    params.fields = ["subscriptionProductVariant"];
    params.filters = [...filters, ...defaultFilter];
    fetchUserSubscription(userId, { ...params })
      .then((res) => {
        if (res && res.data && res.data.data) {
          setSubscriptionsProductVariant([...new Set(res.data.data.map((v) => v.subscriptionProductVariant))]);
        } else {
          dispatch(actionError("Something wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Some thing went wrong"));
      });
  };

  /**
   * @description save user (new / existing) using form submit
   */
  const saveUser = () => {
    const userDetail = { ...formik.values };
    if (!editUser && !isAdmin() && !isMultipleProductVariant) userDetail.productVariant = [{ productName: getProductVariant() }];
    formik.setValues({ ...userDetail });
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const user = { ...formik.values };
        let saveUserData = new FormData();
        if (user.userActivationDate) user.userActivationDate = moment(user.userActivationDate).format("DD-MMM-yyyy");
        for (let uKey in user) {
          if (user[uKey] && user[uKey] !== "") saveUserData.append(uKey, user[uKey]);
        }
        saveUserData.delete("userConfirmPassword");
        saveUserData.delete("userProfileImage");
        if (!editUser) {
          saveUserData.append("userType", "Client");
        }
        if (
          (!editUser && _.isEmpty(user)) ||
          (editUser &&
            _.isEmpty(user) &&
            userProfileImgInputRef.current.files.length === 0 &&
            (!existingUser.userProfileImage ||
              (existingUser.userProfileImage && existingUser.userProfileImage !== "" && userProfileImgSrc !== undefined)))
        ) {
          return true;
        }
        if (userDetail.productVariant) {
          let productVariantTags = userDetail.productVariant.length ? [...userDetail.productVariant] : [];
          saveUserData.set("productVariant", JSON.stringify(productVariantTags));
        }
        if (userProfileImgInputRef.current.files.length) {
          saveUserData.append("userProfileImage", userProfileImgInputRef.current.files[0]);
        } else {
          saveUserData.append("userProfileImage", "");
        }
        saveUserData.set(
          "userSocial",
          JSON.stringify({
            twitter: user.twitter,
            facebook: user.facebook,
            linkedin: user.linkedin,
          })
        );
        saveUserData.delete("twitter");
        saveUserData.delete("facebook");
        saveUserData.delete("linkedin");

        saveUserData.set(
          "userAddress",
          JSON.stringify({
            addressLine: user.addressLine,
            addressLine2: user.addressLine2,
            country: user.country,
            state: user.state,
            city: user.city,
            areaName: user.areaName,
            postCode: user.postCode,
            region: user.region,
            addressType: user.addressType,
          })
        );
        saveUserData.delete("addressLine");
        saveUserData.delete("addressLine2");
        saveUserData.delete("country");
        saveUserData.delete("state");
        saveUserData.delete("city");
        saveUserData.delete("areaName");
        saveUserData.delete("postCode");
        saveUserData.delete("region");
        saveUserData.delete("addressType");

        dispatch(
          showAlertBox({
            okCallback: editUser ? updateExistingUser : addNewUser,
            okText: editUser ? "Update" : "Add",
            cancelText: "Cancel",
            data: saveUserData,
            content: editUser ? "Are you sure, you want to update user details?" : "Are you sure, you want to add user details?",
            title: "dialogAlertCss",
          })
        );
      }
    });
  };

  /**
   * @param {File} imageFile
   * @description read & set image for user profile
   */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      userProfileImgRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const handleResetPassword = (product_variant) => {
    sendLoginCredentials(userId, { notificationType: "Email", notificationTemplate: "reset-pass-by-admin", productVariant: product_variant })
      .then((res) => {
        dispatch(actionSuccess("New password has been sent successfully"));
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Password reset failed"));
      });
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (userId) {
      ![PRODUCT_VARIANT.WHATNEXT, PRODUCT_VARIANT.COGNITION].includes(getProductVariant()) && tabRef.current.enableTab(1, false);
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.select(0);
    }
  };

  /**
	/**
	 * @description fetch countries to tag with user
	 */

  const searchCountry = useCallback(
    _.debounce(async (e) => {
      let filters = [];
      filters.push(["countryName", "cn", e.text]);
      const response =
        e.text.length > 1 && (await fetchCountry({ filters: filters, fields: ["countryName", "region"], sort: "countryName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { country: v.countryName, region: v.region };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    const breadCrumbLinks = editUser ? [...breadCrumbLinksEditUser] : [...breadCrumbLinksAddUser];
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/client-user"));
    selectedIndex === 0 && editUser && fetchUserDetail(userId);
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash]);

  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector("#userEmail");
      emailInput.style.display = "";
    }, 100);
  }, []);

  const userProfileImgInputRef = useRef(null);
  const userProfileImgRef = useRef(null);

  const sendCredentials = () => {
    dispatch(
      showAlertBox({
        okCallback: sendUserLoginCredentials,
        okText: "Send",
        cancelText: "Cancel",
        content: "Are you sure, you want to send login credentials?",
        title: "dialogAlertCss",
      })
    );
  };

  const sendUserLoginCredentials = async () => {
    if (editUser) {
      sendUserCredentials(userId, { productVariant: productVariant })
        .then((res) => {
          dispatch(actionSuccess("Credentials has been sent successfully."));
        })
        .catch((err) => {
          dispatch(actionError(err.data.message || "Credentials send failed."));
        });
    }
  };

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="userProfile">
          {interfaceAccess ? (
            <>
              <div className="gennx-content-wrapper padding-bottom-50i " style={{ width: "99.5%" }}>
                <div className="mx-2 pt-3">
                  <Accordion heading="Basic Details" step={1} stepCompleted={formik.values.userFname ? true : false} activeState={true}>
                    <div className="container-fluid mb-3">
                      <div className="pt-3 pl-0">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-3">
                                <Dropdown
                                  formik={formik}
                                  id="userSalutation"
                                  name="userSalutation"
                                  placeholder="Salutation"
                                  dataSource={SALUTATION_LIST}
                                />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userFname" name="userFname" placeholder="First Name *" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userMname" name="userMname" placeholder="Middle Name" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userLname" name="userLname" placeholder="Last Name *" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userEmail" placeholder="Email *" name="userEmail" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userAltEmail1" name="userAltEmail1" placeholder="Alternate Email" />
                              </div>
                              {/* <div className="col-md-3">
														<TextField
															formik={formik}
															type="text"
															id="userAltEmail2"
															name="userAltEmail2"
															placeholder="Alternate Email 2"
														/>
													</div>
													<div className="col-md-3">
														<TextField
															formik={formik}
															type="text"
															id="userPersonalEmail"
															name="userPersonalEmail"
															placeholder="Personal Email"
														/>
													</div> */}

                              <div className="col-md-3 ">
                                <TextField formik={formik} type="text" id="userMobile" placeholder="Mobile" name="userMobile" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userContactTelNo" placeholder="Telephone" name="userContactTelNo" />
                              </div>
                              {/* <div className="col-md-3 ">
														<TextField
															formik={formik}
															type="text"
															id="userAltMobile"
															name="userAltMobile"
															placeholder="Alternate Mobile"
														/>
													</div>
													<div className="col-md-3 ">
														<TextField
															formik={formik}
															type="text"
															id="userOfficeMobile"
															name="userOfficeMobile"
															placeholder="Official Mobile"
														/>
													</div> */}
                              <div className="col-md-3">
                                <Dropdown formik={formik} id="userGender" name="userGender" placeholder="Gender" dataSource={GENDER_LIST} />
                              </div>
                              <div className="col-md-3">
                                <DatePicker
                                  formik={formik}
                                  properties={{
                                    placeholder: "Date of Birth",
                                    id: `userDob`,
                                    name: `userDob`,
                                    format: "dd MMM yyyy",
                                    floatLabelType: "Auto",
                                    allowEdit: false,
                                    openOnFocus: true,
                                    max: formik.values.userDob
                                      ? formik.values.userDob
                                      : moment(new Date(), "DD-MMM-yyyy").add(-14, "year").format("DD-MMM-yyyy"),
                                  }}
                                />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userDesignation" placeholder="Designation" name="userDesignation" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="userCompany" placeholder="Company *" name="userCompany" />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="twitter" name="twitter" placeholder="Twitter" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="linkedin" name="linkedin" placeholder="LinkedIn URL" />
                              </div>
                              <div className="col-md-3">
                                <TextField formik={formik} type="text" id="facebook" name="facebook" placeholder="Facebook" />
                              </div>
                              <div className="col-md-1">{editUser && <label className="mt-2">User Status</label>}</div>
                              <div className="col-md-2">
                                {editUser ? (
                                  typeof existingUser.userActive !== "undefined" ? (
                                    existingUser.userActive ? (
                                      <blockquote className="active">Active</blockquote>
                                    ) : (
                                      <blockquote className="inactive">Inactive </blockquote>
                                    )
                                  ) : null
                                ) : null}
                              </div>
                            </div>

                            <label>Address Details</label>
                            <br />
                            <div className="row">
                              <div className="col-md-4">
                                <AutoCompleteDropdown
                                  formik={formik}
                                  placeholder="Country *"
                                  id="country"
                                  name="country"
                                  fields={{ value: "country" }}
                                  disabled={false}
                                  // value={formik.values.country}
                                  allowFiltering={true}
                                  filtering={searchCountry}
                                  autofill={true}
                                  select={(selectedItem) => {
                                    selectedItem && formik.setFieldValue("region", selectedItem.itemData?.region);
                                  }}
                                  allowCustom={editUser && formik.values.country ? true : false}
                                  filterType="StartsWith"
                                />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="state" placeholder="State" name="state" />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="addressLine" placeholder="Address Line 1" name="addressLine" />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="addressLine2" name="addressLine2" placeholder="Address Line 2" />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="areaName" placeholder="Area" name="areaName" />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="city" placeholder="City" name="city" />
                              </div>
                              <div className="col-md-4">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  id="region"
                                  placeholder="Region"
                                  name="region"
                                  readOnly
                                  showClearButton={false}
                                />
                              </div>
                              <div className="col-md-4">
                                <TextField formik={formik} type="text" id="postCode" placeholder="Post Code" name="postCode" />
                              </div>
                              <div className={window.innerWidth < 1500 ? `col-md-6` : `col-md-4`}>
                                <RadioGroup
                                  formik={formik}
                                  type="radio"
                                  optionArr={["Home", "Office"]}
                                  properties={{
                                    placeholder: "Address Type",
                                    name: "addressType",
                                    label: "Address Type *",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" style={{ top: "-30px", position: "relative" }}>
                            <div className="image-holder mb-1">
                              <img src={userProfileImgSrc || userImage} alt="User" ref={userProfileImgRef} width="150" height="150" />
                              <div className="image-action" style={{ right: "122px" }}>
                                {accessActionDeleteProfileImage && (
                                  <span
                                    onClick={() => {
                                      dispatch(
                                        showAlertBox({
                                          content: "Are you sure, you want to delete?",
                                          okCallback: async () => {
                                            dispatch(actionSuccess("Image deleted successfully"));
                                            if (userProfileImgInputRef.current.files.length > 0) {
                                              userProfileImgRef.current.setAttribute("src", userProfileImgSrc || userImage);
                                              userProfileImgInputRef.current.value = "";
                                              setImageError("");
                                              setIsDefaultImage(true);
                                            } else {
                                              setUserProfileImgSrc(undefined);
                                              setIsDefaultImage(true);
                                              setImageError("");
                                              userProfileImgRef.current.setAttribute("src", userImage);
                                              userProfileImgInputRef.current.value = "";
                                            }
                                            formik.setValues({ ...formik.values, productProfileImage: "" });
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <i className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : undefined}`} aria-hidden="true"></i>
                                  </span>
                                )}
                                {accessActionEditProfileImage && (
                                  <span onClick={() => userProfileImgInputRef.current.click()}>
                                    <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                                  </span>
                                )}
                              </div>
                            </div>

                            <input
                              type="file"
                              className="d-none"
                              accept=".jpg, .png"
                              id="userProfileImage"
                              name="userProfileImage"
                              ref={userProfileImgInputRef}
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  if (
                                    (e.target.files[0].type == "image/png" ||
                                      e.target.files[0].type == "image/jpg" ||
                                      e.target.files[0].type == "image/jpeg") &&
                                    e.target.files[0].size < 512000
                                  ) {
                                    setImageError("");
                                    setIsDefaultImage(false);
                                    readProfileImage(e.target.files[0]);
                                  } else {
                                    setImageError("Only JPG, PNG files are allowed. Max file size should be 500 Kb.");
                                  }
                                } else {
                                  setImageError("");
                                }
                              }}
                            />
                            <div
                              className="d-flex align-item-center justify-content-center text-danger"
                              style={{ display: imageError ? "block" : "none" }}
                              role="alert"
                            >
                              <small>{imageError}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  {/* <Accordion heading="Basic Details" step={1} stepCompleted={formik.values.userFname ? true : false} activeState={true}> */}
                  {/* <Accordion heading="User Login" step={2} stepCompleted={userId || formik.values.userPassword ? true : false}
								forcedActiveState={formik.errors.userPassword || formik.errors.userConfirmPassword ? true : false}
								activeState={true} required={!editUser}>
								<div className="row p-3">
									<div className="col-md-4">
										<TextField
											formik={formik}
											type="password"
											id="userPassword"
											placeholder={`Enter New Password${editUser ? " " : " *"}`}
											name="userPassword"
										/>
									</div>
									<div className="col-md-4">
										<TextField
											formik={formik}
											type="password"
											id="userConfirmPassword"
											placeholder={`Confirm New Password${editUser ? " " : " *"}`}
											name="userConfirmPassword"
										/>
									</div>
								</div>
							</Accordion> */}
                </div>
              </div>
              <div className="form-submit-box" style={{ overflow: "inherit" }}>
                <div className="container-fluid nopadding">
                  <div className="row">
                    <div className="float-left col-md-6 text-left">
                      {userId !== "" &&
                      [PRODUCT_VARIANT.WHATNEXT, PRODUCT_VARIANT.COGNITION].includes(getProductVariant()) &&
                      (selectedIndex > -1 || selectedIndex === undefined) ? (
                        <span className="pl-4" style={{ marginLeft: "-3em" }}>
                          <span className="span-link">
                            <button
                              type="button"
                              className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                              onClick={() => {
                                selectedIndex < 1 && tabRef.current.select(selectedIndex + 1);
                              }}
                            >
                              Next
                            </button>
                          </span>
                        </span>
                      ) : null}
                    </div>
                    <div className="float-right col-md-6 text-right">
                      {accessActionSendCredentials && existingUser.userActive && editUser && productVariant === "Insider" ? (
                        <button type="button" onClick={sendCredentials} className="btn btn-primary pl-3 pr-3 pt-2 pb-2 ml-3 mr-3">
                          Send Credentials
                        </button>
                      ) : null}

                      <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3" onClick={() => history.push("/client-user")}>
                        Cancel
                      </button>
                      {accessActionSave ? (
                        (existingUser.userActive && editUser) || !editUser ? (
                          <button type="button" onClick={saveUser} className="btn btn-primary cy-btn pl-3 pr-3  ml-3">
                            {editUser ? "Update" : "Save"}
                          </button>
                        ) : null
                      ) : null}
                      {editUser &&
                      existingUser.userActive === 1 &&
                      subscriptionsProductVariant &&
                      subscriptionsProductVariant.length === 1 &&
                      accessActionResetPassword ? (
                        <button
                          type="button"
                          className="btn btn-primary pl-3 pr-3 mr-3 ml-3"
                          style={{ height: "40px !important" }}
                          onClick={() => {
                            handleResetPassword(subscriptionsProductVariant[0]);
                          }}
                        >
                          Reset Password
                        </button>
                      ) : editUser &&
                        existingUser.userActive === 1 &&
                        subscriptionsProductVariant &&
                        subscriptionsProductVariant.length > 1 &&
                        accessActionResetPassword ? (
                        <ButtonGroup className="mr-3 ml-3 pr-3" style={{ height: "40px !important" }}>
                          <DropdownButton title="Reset Password" className="cy-btn">
                            {subscriptionsProductVariant.map((p, i) => {
                              return (
                                <Dropdown1.Item
                                  key={`btn-${i}`}
                                  eventKey={i}
                                  onClick={() => {
                                    handleResetPassword(p);
                                  }}
                                >
                                  {p}
                                </Dropdown1.Item>
                              );
                            })}
                          </DropdownButton>
                        </ButtonGroup>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="subscription">
          {userId && interfaceAccess ? (
            <Relationship
              accessRights={props.accessRights}
              userId={userId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default UserProfile;
