import React, { useEffect } from "react";
import { TIMEZONE_OPTIONS } from "./formFieldOptions";
import { Dropdown, DatePicker, TimePicker } from "../../../../components/CustomFormElements/syncFusionFormFields"
import moment from 'moment';


const TimeZone = props => {
    const { timezone1, timezone2, timezone3 } = props.formik.values;

    useEffect(() => {
        if (props.name === "timezone1") {
            timezone1 && props.formik.setFieldValue(`${props.name}_zone`, timezone1 && timezone1.timezone)
            timezone1 && props.formik.setFieldValue(`${props.name}_date`, timezone1.date && moment(timezone1.date, "DD-MM-YYYY").format())
            timezone1 && props.formik.setFieldValue(`${props.name}_time`, timezone1.time && moment(timezone1.time, "h:mm A").format())
        } else if (props.name === "timezone2") {
            timezone2 && props.formik.setFieldValue(`${props.name}_zone`, timezone2 && timezone2.timezone)
            timezone2 && props.formik.setFieldValue(`${props.name}_date`, timezone2.date && moment(timezone2.date, "DD-MM-YYYY").format())
            timezone2 && props.formik.setFieldValue(`${props.name}_time`, timezone2.time && moment(timezone2.time, "h:mm A").format())
        } else if (props.name === "timezone3") {
            timezone3 && props.formik.setFieldValue(`${props.name}_zone`, timezone3 && timezone3.timezone)
            timezone3 && props.formik.setFieldValue(`${props.name}_date`, timezone3.date && moment(timezone3.date, "DD-MM-YYYY").format())
            timezone3 && props.formik.setFieldValue(`${props.name}_time`, timezone3.time && moment(timezone3.time, "h:mm A").format())
        }

    }, [timezone1, timezone2, timezone3])
    return (
        <div className="row" >
            <div className='col-md-2'>
                <Dropdown
                    id={`${props.name}_zone`}
                    name={`${props.name}_zone`}
                    formik={props.formik}
                    fields={{ text: "label", value: "value" }}
                    dataSource={TIMEZONE_OPTIONS}
                    placeholder={props.isMandatory ? 'Time Zone *' : 'Time Zone'}
                />
            </div>
            <div className='col-md-2'>
                <DatePicker
                    formik={props.formik}
                    properties={{
                        placeholder: props.isMandatory ? 'Date *' : 'Date',
                        id: `${props.name}_date`,
                        name: `${props.name}_date`,
                        format: "dd MMM yyyy",
                        floatLabelType: "Auto",
                        allowEdit: false,
                        openOnFocus: true
                    }}
                />
            </div>
            <div className='col-md-2'>
                <TimePicker
                    formik={props.formik}
                    properties={{
                        placeholder: props.isMandatory ? "Time *" : "Time ",
                        id: `${props.name}_time`,
                        name: `${props.name}_time`,
                        floatLabelType: "Auto",
                        allowEdit: true,
                        openOnFocus: true,
                    }}
                />
            </div>
        </div>
    );
}
export default TimeZone;
