// ‘Copyright ©2023, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Common Info Icon
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :27 Dec 23
// Author:Umesh
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import classes from "./InfoIconwithImage.module.css";
import ImageFile from "../../views/customizableWidgets/ImageFile";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection";
import { Tooltip, styled } from "@material-ui/core";
import { tooltipClasses } from "@mui/material";
import { CONTENT_TYPE } from "../../../constants";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top-end" />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
// Info icon used in most of the components
const InfoIconwithImage = (props) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <ImageFile
            fileName={props?.infoData?.relatedContentData?.profileImage}
            entityType={props?.infoData?.relatedContentData?.entityType}
            item={props?.infoData?.relatedContentData}
            className={classes.infoDataImg}
          />
          <div className={classes.readTimeContainer}>
            {props?.infoData?.relatedContentData?.label &&
              props?.infoData?.relatedContentData?.label !== CONTENT_TYPE.DASHBOARD &&
              props?.infoData?.relatedContentData?.readTime !== 0 &&
              props?.infoData?.relatedContentData?.readTime && (
                <div className={classes.time_play}>
                  <img src="/assets/clock-white-regular.svg" alt="clockIcon"></img>
                  <span className="ml-1 text-xs">{props?.infoData?.relatedContentData?.readTime} min</span>
                </div>
              )}
          </div>
          <div>
            <div>
              <p className={classes.infoCardTitle}>{props?.infoData?.relatedContentData?.title}</p>
              <CommonDateTagSection
                location={props?.infoData?.relatedContentData?.location || ""}
                date={props?.infoData?.relatedContentData?.date}
                label={props?.infoData?.relatedContentData?.label || ""}
                tags={props?.infoData?.relatedContentData?.industryTag || []}
                readTime={0}
                showReadTime={false}
                subType={props?.infoData?.relatedContentData?.subType || ""}
                showSubType={true}
              />
            </div>
          </div>
        </React.Fragment>
      }
    >
      <div className={`${classes.infoImg}`}>
        <img src={process.env.PUBLIC_URL + "/assets/info.svg"} alt="info" />
      </div>
    </HtmlTooltip>
  );
};

export default InfoIconwithImage;
