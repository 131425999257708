import React, { memo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection"; // Adjust the import based on the actual file location
import { ReactComponent as SixDotLogo } from "assets/images/sixDots.svg";
import { FaArrowLeft } from "react-icons/fa";
import { Box, Tooltip, Typography } from "@mui/material";
import { IoInformation } from "react-icons/io5";
import { getFirstPublishedDate, getUniqueTags } from "utils";

const DraggableList = ({ onDragEnd, widgetList, handleArrowClick, showTooltip = false }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="droppablePage">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {widgetList?.length > 0 &&
              widgetList.map((item, index) => {
                if (item) {
                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className="FilterList mainFilterDiv" key={item.id + Math.random()} id={`list${item.id}`}>
                            <div style={{ display: "flex", maxHeight: "120px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  paddingTop: "13px",
                                  paddingLeft: "8px",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                <SixDotLogo />
                              </div>
                              <div className="draggable-content-card">
                                <div className="title_top">
                                  {item?.signalIndicator && (
                                    <Box className="disruptive-signals-percentage-box">
                                      <Typography
                                        className="disruptive-signals-percentage"
                                        style={{ backgroundColor: `${item?.signalIndicatorColor}`}}
                                      >
                                        {item?.signalIndicator}
                                      </Typography>
                                    </Box>
                                  )}
                                  <p className={item?.signalIndicator ? "widget-drag-title" : "itemTitle"}>{item.title}</p>
                                </div>
                                {!showTooltip && (
                                  <CommonDateTagSection
                                    date={getFirstPublishedDate(item) || ""}
                                    label={item.subContentType}
                                    tags={getUniqueTags(item?.industryTag)}
                                    readTime={item?.readTime}
                                    showReadTime={true}
                                    subType={item.contentType}
                                    showSubType={false}
                                  />
                                )}
                                {showTooltip && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#4B4B55",
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      WebkitLineClamp: 2, // Number of lines to clamp
                                      textOverflow: "ellipsis",
                                      lineHeight:"15.18px !important",
                                      margin:"8px 0px !important",
                                    }}
                                  >
                                    {item?.infoText}
                                  </Typography>
                                )}
                              </div>

                              {showTooltip && item?.signalTooltip && (
                                <Tooltip title={item?.signalTooltip} placement="bottom-end" arrow id="custom-widget-tooltip">
                                  <div className="signal-info-container">
                                    <IoInformation height={24} width={24} color="#4B4B55" />
                                  </div>
                                </Tooltip>
                              )}
                              <div style={{ cursor: "pointer" }} className="draggable-content-card-arrow" onClick={() => handleArrowClick(item, "Delete")}>
                                <FaArrowLeft
                                  size={30}
                                  style={{ height: "16px", width: "16px", color: "#4B4B55 !important" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                }
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(DraggableList);
