/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Custom Projects Page
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created			: 24/May/2024
  Author						: Satya Prakash Mall
================================================================ 
*/
import React, { useEffect } from "react";
import { showLoader, hideLoader, actionError } from "../../../middleware/actions/utilityAction";
import { CUSTOM_PROJECTS_URL } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProductVariant } from "../../../utilities";

export default () => {
  // ** HOOKS **
  const history = useHistory();
  const dispatch = useDispatch();

  // ** UTILS **
  const checkEligiblity = async () => {
    const variant = getProductVariant();
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = process.env.REACT_APP_ENTERPRISE_CLIENT_NAME;
    if (!variant) return history.push("/selector");
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access custom projects."));
    dispatch(showLoader());
    redirectToCustomProjects(variant, userId, clientName, enterpriseToken, refreshToken);
    setTimeout(() => dispatch(hideLoader()), 5000);
  };

  const redirectToCustomProjects = (v, e, c, t, r) =>
    window.open(
      CUSTOM_PROJECTS_URL + "/auto-login?productVariant=" + v + "&loginUserId=" + e + "&clientName=" + c + "&token=" + t + "&refreshToken=" + r
    );

  // ** EFFECTS **
  useEffect(() => {
    checkEligiblity();
  }, []);

  return (
    <div
      className="gennx-content-wrapper content-wrapper px-3"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", flexDirection: "column" }}
    >
      <h4>Custom Projects is now a standalone app.</h4>
      <h6>Please wait while we redirect and log you in.</h6>

      <button onClick={checkEligiblity}>Click here if you are not redirected.</button>
    </div>
  );
};
