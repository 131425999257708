/*
================================================================
  Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description    : Content Builder Component 
----------------------------------------------------------------
  Creation Details
  Date Created				: 02/May/2022
  Author						  : Sarvesh Samant
================================================================
*/
import React, { useEffect, useState, useRef } from "react";
import ContentBuilder from "@innovastudio/contentbuilder";
import "./public/contentbuilder/contentbuilder.css";
import "./content.scss";
import { useDispatch } from "react-redux";
import { actionError, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { fetchContentBlock, fetchContentBlockImageUrl, saveImageToLocal } from "../../../middleware/services/contentBlockApi";
import data_basic from "./public/assets/minimalist-blocks/snippetlist_files/content";
import { COMPANY_URL, PRODUCT_URL, NEWS_URL, TV_URL, PEOPLE_URL, PATENT_URL, CMS_API_URL, DELIVERABLE_URL } from "../../../config/index";
import { ENTITY_TYPE, PRODUCT_VARIANT } from "../../../constants";
import ImageEditorModal from "./public/contentbuilder/imageEditorModal";
import { getLoggedInUser, isAdmin } from "../../../utilities";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { fetchPresentation, fetchPresentationCategory } from "middleware/services/presentationApi";
import { getPresentationImageUrl } from "middleware/services/imageService";

const Main = (props) => {
  const user = getLoggedInUser();
  const readOnly = props?.readMode ?? false;
  /* Updating tag section in the block with deliverable type*/
  if (props.remarkEntityType == ENTITY_TYPE.DELIVERABLE)
    [...document.querySelectorAll(".tag_section")].map((a) => (a.innerHTML = props?.deliverableType && props.deliverableType));

  const productVaraint = localStorage.getItem("productVariant") ? localStorage.getItem("productVariant") : undefined;
  const builderRef = useRef();
  const dispatch = useDispatch();
  const [buildState, setBuildState] = useState();
  const [snippets, setSnippets] = useState([]);
  const [toggleBuild, setToggleBuild] = useState(false);
  const buttons = ["align", "textsettings", "createLink", "tags", "|", "undo", "redo", "zoom", "more"];
  const buttonsMore = ["image", "|", "list", "|", "html"];
  const [showModal, setShowModal] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [imageHeight, setImageHeight] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [imageId, setImageId] = useState("");
  const [imgModal, setImgModal] = useState(false);
  let imageData = "";
  let showToast;
  const openImgEditor = () => {
    setShowModal(!showModal);
  };

  const openImgModal = () => {
    setImgModal(!imgModal);
    let wrapper = document.querySelector(".content-builder-container");
    wrapper.click();
  };

  function saveImageToCloud(imageIdToUpdate, base64Url, imageData) {
    dispatch(showLoader());
    let wrapper = document.querySelector(".gennx-content-wrapper");
    wrapper.click();
    let response = saveImageToLocal({ base64: base64Url });
    response
      .then((res) => {
        let contentBuilderContainer = document.querySelector(".content-builder-container");
        if (contentBuilderContainer) {
          [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
            if (a.id === imageIdToUpdate) a.src = res.data.data;
          });
        }
        dispatch(hideLoader());
        setTimeout(() => {
          setToggleBuild(true);
        }, 200);
      })
      .catch((err) => {
        let contentBuilderContainer = document.querySelector(".content-builder-container");
        if (contentBuilderContainer) {
          [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
            if (a.id === imageIdToUpdate) a.src = imageData;
            setToggleBuild(true);
          });
        }
        dispatch(hideLoader());
        console.log("err >>", err);
        dispatch(actionError(err?.data?.message || "Unable to upload image on CDN. Please try again after sometime"));
      });
  }

  /*Description : Fetch all content block function */
  const fetchBlockSnippets = async () => {
    dispatch(showLoader());
    let params = {};
    params.filters = [
      ["contentBlockStatus", "eq", "Published"],
      ["productVariant.productName", "eq", productVaraint],
      ["contentBlockContentType", "neq", "Email Template"],
      ["contentBlockIsDelete", "eq", "NO"],
    ];

    let snippetsBlocks = [];
    await fetchContentBlock(params).then((e) => {
      if (e.data.data) {
        snippetsBlocks = e.data.data
          .map((item) => {
            let html;
            if (props.isPage && props.contentBlockHtml) {
              html = `<div class="container content-builder-container mt-4" ></div>`;
            } else {
              html = `<div class="content-datat-${item.id} mt-3 px-2" data=${item.id}><div class="column-full">${item.contentBlockHtml}
            </div></div>`;
            }
            let thumbnail;
            if (!item.contentBlockThumbnail) {
              thumbnail = "/contentbuilder/Images/contentBlockGrey.png";
            } else {
              thumbnail = fetchContentBlockImageUrl(item.contentBlockThumbnail);
            }
            if (item.contentBlockHtml) {
              let industry = ["All"];
              if (item.contentBlockIndustryTrendsTags) {
                item.contentBlockIndustryTrendsTags.map((a) => {
                  if (a.trendParentId == 0) {
                    industry.push(a.trendId);
                  }
                });
              }
              // industry.push("All");
              return {
                category: item.contentBlockCategoryId.toString(),
                html,
                thumbnail,
                settings: item.contentBlockSetting,
                blockid: item.id,
                industry: industry,
                blockName: item.contentBlockName,
              };
            }
          })
          .filter((j) => j);
      }
      let newSnippets = data_basic.snippets.map((e) => {
        return {
          category: e.category.toString(),
          html: e.html,
          thumbnail: fetchContentBlockImageUrl(e.thumbnail),
        };
      });
      // snippetsBlocks = snippetsBlocks.concat(newSnippets);
      // snippetsBlocks = snippetsBlocks;

      dispatch(hideLoader());
    });
    /* My briefcase fetch call */
    if (props.remarkEntityType == ENTITY_TYPE.DELIVERABLE) {
      let PresentationParams = {
        filters: [["presentationType", "eq", "BRIEFCASE"]],
        limit: 10000,
        sort: "presentationSeqNo:asc",
      };
      const presentations = await fetchPresentation(user._id, PresentationParams);
      let industry = ["All"];
      let userCompetencyIndustry =
        localStorage.getItem("userCompetencyIndustry") !== "null" ? localStorage.getItem("userCompetencyIndustry") : "null";
      if (userCompetencyIndustry !== "null" && userCompetencyIndustry !== undefined) {
        userCompetencyIndustry = JSON.parse(userCompetencyIndustry);
        if (userCompetencyIndustry) userCompetencyIndustry.map((a) => industry.push(a.id));
      }
      const presentationData = presentations?.data?.data?.map((a) => {
        return {
          category: a.presentationCategoryId?.toString(),
          html: a.presentationEntityPageHtmlContent,
          thumbnail: getPresentationImageUrl(a.presentationThumbnail),
          blockid: a.id,
          industry: industry,
          blockName: a?.presentationTitle || "",
        };
      });
      return setSnippets([...snippetsBlocks, ...presentationData]);
    }
    setSnippets(snippetsBlocks);
  };

  /*Description : Show toolbar buttons */
  const addFeaturesForUser = () => {
    const allButtons = ["bold", "italic", "underline", "formatting", "font", "color"];
    return allButtons.concat(buttons);
  };

  /*Description : Add extra buttons in toolbar */
  const addButtonsMoreForUser = () => {
    if (props.userType === "Admin") {
      return ["image", "|", "list", "formatPara", "|", "html", "preferences"];
    }
    return buttonsMore;
  };

  /*Description : Get all content type blocks */
  const snippetCategoryFeature = () => {
    // taking all snippet/block ids
    const snippetCategoryIds = snippets.map((snippet) => snippet.category);
    // removing duplicates
    const uniqueArray = [...new Set(snippetCategoryIds)];
    const snippetOptions = props.remarkEntityType == "deliverable" && props?.catDropdown.length > 0 ? props.catDropdown : props?.snippentList;
    // removing block category from if it doesn't have any block
    return snippetOptions.filter((item) => uniqueArray.includes(String(item[0])));
    /*if (props.remarkEntityType == "deliverable" && props?.catDropdown.length > 0) {
      return props.catDropdown;
    }
    return props?.snippentList;*/

    /*  custom snippent list
    return [
      [600, "Basic"],
      [501, 'Custom Blocks'],
      // [502, "Company"],
      // [503, 'News'],
      // [504, "Product"],
      // [505, "Video"],
      // [506, "Patent"],
      // [507, "People"],
      [508, 'Title Blocks'],
      [509, 'Key Points Blocks'],
      [510, 'Content Blocks'],
      [511, 'Image Blocks'],
      [512, 'Stat Blocks'],
      [513, 'Quote Blocks'],
      [514, 'Chart Blocks'],
      [515, 'Video Block'],
      [516, 'Table Blocks'],
      [517, 'Player Profile Blocks'],
      [518, 'Technology Process Blocks'],
      [519, 'Thanks Blocks'],
      [520, 'Advert Blocks'],
      [521, 'Visual Slides Blocks'],
     ]*/
  };

  useEffect(() => {
    builderRef.current && snippets.length === 0 && fetchBlockSnippets();
  }, [data_basic]);

  useEffect(() => {
    return () => {
      const contentBuilderDiv = document.querySelector("#_cbhtml");
      if (contentBuilderDiv) {
        document.querySelector("#_cbhtml").remove();
        // document.querySelector(".rCSwtyrwf").innerHTML = ""
        // builderRef.current.remove()
        // buildState && buildState.destroy();
      }
    };
  }, []);

  /*Description : Cancel function for delete pop-up modal */
  const cancelBtnFunction = () => {
    const modalContent = document.querySelector(".is-modal.is-confirm.active .is-modal-content");
    const docu = document.querySelector(".is-modal.is-confirm.active");
    let cancelBtn = document.createElement("button");
    cancelBtn.textContent = "Cancel";
    cancelBtn.classList.add("input-ok-custom", "classic", "custom-cancel");
    cancelBtn.style.position = "absolute";
    cancelBtn.style.right = "110px";
    cancelBtn.style.bottom = "20px";
    cancelBtn.style.width = "100px";
    cancelBtn.style.height = "33px";
    cancelBtn.style.border = "1px solid #F94F5E";
    cancelBtn.style.backgroundColor = "transparent";
    cancelBtn.style.color = "#F94F5E";

    let cnc = document.querySelector(".input-ok-custom");
    !cnc && modalContent.appendChild(cancelBtn);
    cancelBtn.addEventListener("click", () => {
      // const close = document.querySelector(".input-ok-custom");
      // close && close.remove();
      // close.innerHTML = ""
      docu.classList.remove("active");
    });
  };
  /*Description : Load content builder function */
  const createContentBulder = () => {
    if (snippets && builderRef.current && snippets.length > 0) {
      let plugins = [
        {
          name: "preview",
          showInMainToolbar: props.userType === "client" ? false : true,
          showInElementToolbar: false,
        },
        {
          name: "videoAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        // { name: 'videoEntity', showInMainToolbar: true, showInElementToolbar: false },
        {
          name: "peopleAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        // { name: 'peopleEntity', showInMainToolbar: true, showInElementToolbar: false },
        // { name: 'patentEntity', showInMainToolbar: true, showInElementToolbar: false },
        {
          name: "patentAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        {
          name: "newsAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        // { name: 'newsEntity', showInMainToolbar: true, showInElementToolbar: false },
        {
          name: "companyAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        // { name: 'companyEntity', showInMainToolbar: true, showInElementToolbar: false },
        {
          name: "productAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        // { name: 'productEntity', showInMainToolbar: true, showInElementToolbar: false },
        {
          name: "wordcount",
          showInMainToolbar: true,
          showInElementToolbar: false,
        },
        {
          name: "linkBlockAttribute",
          showInMainToolbar: props.remarkEntityType == ENTITY_TYPE.DELIVERABLE ? true : false,
          showInElementToolbar: false,
        },
      ];
      if (productVaraint === PRODUCT_VARIANT.INSIDER) {
        plugins.push({
          name: "taxonomyAttribute",
          showInMainToolbar: true,
          showInElementToolbar: false,
        });
      }
      const build = new ContentBuilder({
        container: ".container",

        // commented plugin code for temporary basis

        plugins: plugins,
        pluginPath: `${window.location.origin}/contentbuilder/`,

        sidePanel: "right",
        snippetOpen: false,
        toolbarAddSnippetButton: false,
        useLightbox: false,
        snippetDisplay: "visible",
        snippetCategories: snippetCategoryFeature(),
        // snippetPath: `${window.location.origin}/contentbuilder/`,
        snippetPath: "",
        snippetPathReplace: ["assets/", `http://localhost:3001/assets/`],
        // snippetPathReplace: ['assets/', `${window.location.origin}/contentbuilder/Images/basic-01.png`],
        snippetJSON: {
          snippets: snippets,
        },
        fontAssetPath: `${window.location.origin}/contentbuilder/assets/fonts/`,
        clearPreferences: false,
        defaultSnippetCategory: props.defaultCategoryId,
        buttons: addFeaturesForUser(),
        buttonsMore: addButtonsMoreForUser(),
        elementEditor: true,
        // elementTool: true,
        // columnTool: true,
        snippetAddTool: true,
        columnHtmlEditor: true,
        rowHtmlEditor: true,
        /* Descriptiopn: On content/block click function  */
        onContentClick: function (e) {
          let contentBuilder = document.querySelector("#content_builder");
          if (contentBuilder) {
            [...contentBuilder.querySelectorAll("img")].map((a) => {
              let new_url = a.src;
              a.src = new_url;
            });
          }

          setTimeout(() => {
            const activeBox = document.querySelector(".elm-inspected");
            if (activeBox?.src) {
              imageData = activeBox.src;
              showToast = true;
            }
          }, 100);
          /*Add class name in taxonomy tag section */
          let taxonomyTagSection = document.querySelector(".taxonomy_tag");

          if (taxonomyTagSection === null) {
            [...window.document.querySelectorAll(".tag_section_list ul li")].map((a) => {
              a.classList.add("taxonomy_tag");
            });
          }
          /*Highlight the active section in the block*/
          props.setSaveDeliverable && props.setSaveDeliverable(false);
          let activeBlockBorder = document.querySelector(".is-side.elementstyles");
          if (activeBlockBorder) activeBlockBorder.classList.add("active");

          /* Updating tag section in the block with deliverable type*/
          if (props.remarkEntityType == ENTITY_TYPE.DELIVERABLE)
            [...document.querySelectorAll(".tag_section")].map((a) => (a.innerHTML = props?.deliverableType && props.deliverableType));

          let element;
          if (e?.path) {
            for (let i of e.path.slice(0, -3)) {
              let colClass = i.getAttribute("class");
              if (typeof colClass === "string" && colClass.substring(0, 13) == `content-datat`) {
                element = i;
              }
            }
          }
          let activeBlock = window.document.querySelectorAll(".row-active");
          let a = activeBlock[0].classList;
          let s = a.value.includes("content-datat");
          if (s) element = activeBlock[0];

          const availableOptions = [
            "color",
            "formatting",
            "fontfamily",
            "align",
            "textsettings",
            "link",
            "undo",
            "redo",
            "zoom",
            "image",
            "list",
            "paragraph",
            "html",
            "preferences",
          ];
          for (let i of availableOptions) {
            if (document.querySelector(`.rte-${i}`)) {
              document.querySelector(`.rte-${i}`).style.display = "block";
            }
          }
          [...document.querySelectorAll(".rte-format")].map((a) => (a.style.display = "block"));
          [...document.querySelectorAll(".rte-separator")].map((a) => (a.style.display = "block"));
          let hideColumnTools = document.querySelector(`#_cbhtml .is-column-tool`);
          hideColumnTools.classList.remove("hide-column-tool-setting");
          let hideElementTools = document.querySelector(`#_cbhtml .is-element-tool`);
          hideElementTools.classList.remove("hide-element-tool-setting");
          [...document.querySelectorAll(".is-rowadd-tool")].map((a) => a.firstElementChild.classList.add("hide-plus-icon"));
          [...document.querySelectorAll(".hide-plus-icon")].map((a) => (a.style.display = "block"));

          /* hidding other setting */
          document.querySelector(".elm-add").style.display = "none";
          if (element) {
            const settingsAttr = element.getAttribute("data");

            const scope = snippets.filter((e) => e.blockid === settingsAttr);
            let scopeSettings = scope[0]?.settings;

            let disabledSettings = [];
            for (let j in scopeSettings) {
              if (scopeSettings[j] === "YES") {
                disabledSettings.push(j);
              }
            }
            const selections = document.querySelector(`.content-datat-${scope[0]?.blockid}`)?.getAttribute("data");
            const configSettings = [
              "align",
              "textsettings",
              "link",
              "undo",
              "redo",
              "zoom",
              "list",
              "paragraph",
              "html",
              "preferences",
              "format",
              "seperator",
              "image",
            ];
            for (let i of configSettings) {
              if (i === "format") {
                [...document.querySelectorAll(".rte-format")].map((a) => (a.style.display = "block"));
              }
              if (i === "separator") {
                [...document.querySelectorAll(".rte-separator")].map((a) => (a.style.display = "block"));
              }
              if (document.querySelector(`.rte-${i}`)) {
                document.querySelector(`.rte-${i}`).style.display = "block";
              }
            }
            for (let i of availableOptions) {
              if (document.querySelector(`.rte-${i}`)) {
                document.querySelector(`.rte-${i}`).style.display = "block";
              }
            }

            /* Disable settings */
            for (let i of disabledSettings) {
              if (selections === settingsAttr) {
                /* Disable font style settings */
                if (i == "disableFontStyle") {
                  /* hide align, textsettings, link, undo, redo, list and underline   */
                  let disableFontSettings = ["align", "textsettings", "link", "undo", "redo", "list", "html"];
                  for (let i of disableFontSettings) {
                    if (document.querySelector(`.rte-${i}`)) {
                      document.querySelector(`.rte-${i}`).style.display = "none";
                    }
                  }

                  /* hide bold, italic and underline   */

                  [...document.querySelectorAll(".rte-separator")].map((a) => (a.style.display = "none"));
                }

                /* Disable font color setting */
                if (i === "disableFontColor") {
                  document.querySelector(`.rte-color`).style.display = "none";
                }

                /* Disable font formatting settings */
                if (i === "disableFontFormatting") {
                  let hideFontFormatting = document.querySelector(".rte-for-text");
                  [...hideFontFormatting.querySelectorAll(".rte-format")].map((a) => (a.style.display = "none"));
                  document.querySelector(`.rte-fontfamily`).style.display = "none";
                  document.querySelector(`.rte-formatting`).style.display = "none";
                }

                /* Disable other settings */
                if (i === "disableExtraSettings") {
                  /* hide zoom, paragraph, preferences */
                  let hideSettings = ["zoom", "paragraph", "preferences"];
                  // let hideSettings = ["align", "textsettings", "link", "undo", "redo", "zoom", "image", "list", "paragraph", "html", "preferences"]
                  for (let i of hideSettings) {
                    if (document.querySelector(`.rte-${i}`)) {
                      document.querySelector(`.rte-${i}`).style.display = "none";
                    }
                  }

                  let hideColumnTools = document.querySelector(`#_cbhtml .is-column-tool`);
                  hideColumnTools.classList.add("hide-column-tool-setting");
                  let hideElementTools = document.querySelector(`#_cbhtml .is-element-tool`);
                  hideElementTools.classList.add("hide-element-tool-setting");
                  [...document.querySelectorAll(".hide-plus-icon")].map((a) => (a.style.display = "none"));
                }
              }
            }
          }

          if (e.target.id === "linked-block") {
            document.querySelector(".link-edit").style.display = "none";
          } else {
            document.querySelector(".link-edit").style.display = "block";
          }
        },

        /* Descriptiopn: On content/block change function  */
        onChange: function (e) {
          let contentBuilder = document.querySelector("#content_builder");
          if (contentBuilder) {
            [...contentBuilder.querySelectorAll("img")].map((a) => {
              let new_url = a.src;
              a.src = new_url;
            });
          }
          /*Description : Adding img tag on iframe */
          let video_div = document.querySelectorAll(".embed-responsive");
          if (video_div) {
            video_div.forEach((d) => {
              let iframe = d.querySelector("iframe");
              if (iframe) {
                const videoUrl = `${iframe.src}`;
                const url = new URL(`${iframe.src}`);
                const hostname = url.hostname;
                if (hostname == "www.youtube.com") {
                  let iframe_img_tag = d.querySelector("img");
                  if (iframe_img_tag) iframe_img_tag.remove();
                  let img_tag = d.nextElementSibling;
                  if (img_tag?.tagName === "IMG") img_tag.remove();
                  const youtubeVideoId = videoUrl.substring(videoUrl.lastIndexOf("/") + 1, videoUrl.indexOf("?"));
                  let srcUrl = `http://img.youtube.com/vi/${youtubeVideoId}/0.jpg`;
                  const img = document.createElement("img");
                  img.setAttribute("src", srcUrl);
                  img.setAttribute("alt", "video");
                  // video_div.appendChild(img);
                  const imgClone = img.cloneNode(true);
                  imgClone.setAttribute("style", "height:auto");
                  d.appendChild(imgClone);
                  const newImgTag = img;
                  newImgTag.setAttribute("class", `iframe-thumbnail`);
                  newImgTag.setAttribute("style", "height:500px");
                  d.parentNode.insertBefore(newImgTag, d.nextSibling);
                } else {
                  const vimeoVideoId = videoUrl.substring(videoUrl.lastIndexOf("/") + 1);
                  let iframe_img_tag = d.querySelector("img");
                  if (iframe_img_tag) iframe_img_tag.remove();
                  let img_tag = d.nextElementSibling;
                  if (img_tag?.tagName === "IMG") img_tag.remove();
                  const img = document.createElement("img");
                  // Set the src and alt attributes
                  img.setAttribute("data-vimeo-id", `${vimeoVideoId}`);
                  const imgClone = img.cloneNode(true);
                  imgClone.setAttribute("style", "height:auto");
                  d.appendChild(imgClone);
                  const newImgTag = img;
                  newImgTag.setAttribute("class", `iframe-thumbnail`);
                  newImgTag.setAttribute("style", `height:500px`);
                  // video_div.;
                  d.parentNode.insertBefore(newImgTag, d.nextSibling);
                  if (vimeoVideoId !== "about:blank") {
                    const vimeoApiUrl = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(videoUrl)}`;
                    fetch(vimeoApiUrl)
                      .then((response) => response.json())
                      .then((data) => {
                        const vimeoThumbnailUrl = data.thumbnail_url;
                        // const vimeoImage = d.querySelector("img");
                        const vimeoImage = d.nextElementSibling;
                        const vimeoIframeImage = d.querySelector("img");
                        if (vimeoIframeImage) vimeoIframeImage.src = vimeoThumbnailUrl;
                        if (vimeoImage) vimeoImage.src = vimeoThumbnailUrl;
                        setToggleBuild(true);
                      })
                      .catch((error) => console.error(error));
                  }
                }
              }
            });
          }

          /* Updating tag section in the block with deliverable type*/
          if (props.remarkEntityType == ENTITY_TYPE.DELIVERABLE)
            [...document.querySelectorAll(".tag_section")].map((a) => (a.innerHTML = props?.deliverableType && props.deliverableType));
          [...document.getElementsByTagName("img")].map((e) => {
            if (e.getAttribute("src") === "example.png") {
              e.setAttribute("src", `${window.location.origin}/contentbuilder/Images/example.png`);
            }
          });

          let divImageResizerPosition;

          const divImageResizer = document.querySelector("#divImageResizer");

          if (divImageResizer) {
            divImageResizerPosition = divImageResizer.getBoundingClientRect();
          }

          let contentBuilderContainer = document.querySelector(".content-builder-container");
          let standardHeight = document.querySelector(".elm-inspected")?.getAttribute("data-height");
          let standardWidth = document.querySelector(".elm-inspected")?.getAttribute("data-width");
          let selectedImage = document.querySelector(".elm-inspected");
          showToast = true;
          if (selectedImage) {
            let im = new Image();
            im.src = selectedImage.src;
            im.onload = function () {
              if (Number(standardHeight?.split("px")[0]) < im.naturalHeight || Number(standardWidth?.split("px")[0]) < im.naturalWidth) {
                selectedImage.src = imageData;
                setBuildState(build);
                setToggleBuild(true);
                if (showToast) {
                  showToast = false;
                  return dispatch(
                    actionError(
                      `Selected image dimension is ${im.naturalWidth}px X ${im.naturalHeight}px (Width X Height). Recommended image dimension is ${standardWidth} X ${standardHeight} (Width X Height).`
                    )
                  );
                }
              } else {
                saveImageToCloud(selectedImage.id, selectedImage.src, imageData);
              }
            };
          }

          /* Get image container with - height reference  */
          let selectedSection = document.querySelector(".elm-inspected");
          let height = selectedSection?.getAttribute("data-height");
          let width = selectedSection?.getAttribute("data-width");

          let im = new Image();
          if (selectedSection) {
            im.src = selectedSection.src;
            im.onload = function () {
              if (height && width) {
                if (Number(height?.split("px")[0]) >= im.height && Number(width?.split("px")[0]) >= im.width) {
                  selectedSection.classList.add("custom-object-fit");
                  // if (foo) foo.style.objectFit = "contain";
                }

                if (Number(height?.split("px")[0]) === im.height && Number(width?.split("px")[0]) === im.width) {
                  selectedSection.classList.remove("custom-object-fit");
                }
              }
            };
          }

          if (contentBuilderContainer) {
            let count = 0;
            [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
              a.id = "image" + count.toString();
              count = count + 1;
              let imagePosition = a.getBoundingClientRect();

              if (
                Math.round(imagePosition.x) == Math.round(divImageResizerPosition.x) &&
                Math.round(imagePosition.y) == Math.round(divImageResizerPosition.y)
              ) {
                //   saveImageToCloud(a.id, a.src);
              }
            });
          }

          const imageEditorelement = document.querySelector(".image-edit");
          imageEditorelement &&
            imageEditorelement.addEventListener("click", (event) => {
              event.stopImmediatePropagation();

              let divImageResizerPosition;

              const divImageResizer = document.querySelector("#divImageResizer");

              if (divImageResizer) {
                divImageResizerPosition = divImageResizer.getBoundingClientRect();
              }

              let contentBuilderContainer = document.querySelector(".content-builder-container");

              /* Get image container with - height reference  */
              let height = document.querySelector(".elm-inspected")?.getAttribute("data-height");
              let width = document.querySelector(".elm-inspected")?.getAttribute("data-width");

              if (contentBuilderContainer) {
                let count = 0;
                [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
                  a.id = "image" + count.toString();
                  count = count + 1;
                  let imagePosition = a.getBoundingClientRect();
                  const srcImg = document.querySelector(".elm-inspected").getAttribute("src");

                  if (a.src === srcImg) {
                    setImageId(a.id);
                    setImageURL(a.src);
                    /* image container with - height reference props */
                    setImageHeight(height);
                    setImageWidth(width);
                  }
                });

                setTimeout(() => {
                  openImgEditor();
                }, 100);
              }
            });

          props.setSaveDeliverable && props.setSaveDeliverable(false);
          const social = document.querySelector(".is-social");
          if (social) {
            social.innerHTML = `
            <div class="is-social" style="margin:1em 0; display: flex;
              justify-content: center; widt:"300px" ><a href="https://twitter.com/" style="display: flex;
              justify-content: center;width:100px">
              <img src="${window.location.origin}/contentbuilder/Images/twitter.svg" width="20px" alt="">
              </a >
              <a href="https://www.facebook.com/" style="display: flex;
              justify-content: center;width:100px">
              <img src="${window.location.origin}/contentbuilder/Images/facebook.svg" width="20px" alt="">
              </a >
              <a href="mailto:you@example.com"  style="display: flex;
              justify-content: center;width:100px">
              <img src="${window.location.origin}/contentbuilder/Images/email.svg" width="20px" alt=""></a >
              </div>
          `;
          }
          const videoElement = document.getElementsByTagName("video");
          if (videoElement) {
            [...document.getElementsByTagName("video")].map((e) => {
              e.innerHTML = `
             
              <source src="${window.location.origin}/video.mp4" type="video/mp4">
              `;
            });
          }
          //  const vimeoVideo = document.querySelector(".")
          setToggleBuild(true);
          addCancelButtonInDeleteBlockModel();
        },
        /* Descriptiopn: On content/block  render function  */
        onRender: function (e) {
          const imageEditorelement = document.querySelector(".image-edit");
          // const imageIcon = document.querySelector(".image-modal") ? true : false;
          // const editorTool = document.querySelector("#divImageTool");
          // if (editorTool && imageEditorelement && !imageIcon){
          //   const imageModalButton = document.createElement("button");
          //   imageModalButton.classList.add("image-modal");
          //   /*Add the HTML code to the header div */
          //   imageModalButton.innerHTML = `<svg class="is-icon-flex" style="margin-top:2px;width:15px;height:15px;"><use xlink:href="#icon-zoom-in"></use></svg>`
          //   imageModalButton.setAttribute("title","Image Modal")
          //   imageModalButton.setAttribute("data-title","Image Modal")
          //   editorTool.insertBefore(imageModalButton, editorTool.lastChild);
          // }
          imageEditorelement &&
            imageEditorelement.addEventListener("click", () => {
              let divImageResizerPosition;

              const divImageResizer = document.querySelector("#divImageResizer");

              /* Get image container with - height reference  */
              let height = document.querySelector(".elm-inspected")?.getAttribute("data-height");
              let width = document.querySelector(".elm-inspected")?.getAttribute("data-width");

              if (divImageResizer) {
                divImageResizerPosition = divImageResizer.getBoundingClientRect();
              }

              let contentBuilderContainer = document.querySelector(".content-builder-container");

              [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
                let imagePosition = a.getBoundingClientRect();
                const srcImg = document.querySelector(".elm-inspected").getAttribute("src");

                if (a.src === srcImg) {
                  setImageId(a.id);
                  setImageURL(a.src);
                  /* Get image container with - height reference props */
                  setImageHeight(height);
                  setImageWidth(width);
                }
              });

              setTimeout(() => {
                openImgEditor();
              }, 100);
            });

          const imageModal = document.querySelector(".image-modal");
          imageModal &&
            imageModal.addEventListener("click", () => {
              let divImageResizerPosition;

              const divImageResizer = document.querySelector("#divImageResizer");

              /* Get image container with - height reference  */
              let height = document.querySelector(".elm-inspected")?.getAttribute("data-height");
              let width = document.querySelector(".elm-inspected")?.getAttribute("data-width");

              if (divImageResizer) {
                divImageResizerPosition = divImageResizer.getBoundingClientRect();
              }

              let contentBuilderContainer = document.querySelector(".content-builder-container");

              [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
                let imagePosition = a.getBoundingClientRect();
                const srcImg = document.querySelector(".elm-inspected").getAttribute("src");

                if (a.src === srcImg) {
                  setImageId(a.id);
                  setImageURL(a.src);
                  /* Get image container with - height reference props */
                  setImageHeight(height);
                  setImageWidth(width);
                }
              });

              setTimeout(() => {
                openImgModal();
              }, 100);
            });

          let contentBuilder = document.querySelector("#content_builder");
          if (contentBuilder) {
            [...contentBuilder.querySelectorAll("img")].map((a) => {
              let new_url = a.src;

              a.src = new_url;
            });
          }

          let contentBuilderContainer = document.querySelector(".content-builder-container");
          if (contentBuilderContainer) {
            let count = 0;
            [...contentBuilderContainer.querySelectorAll("img")].map((a) => {
              a.id = "image" + count.toString();
              count = count + 1;
            });
          }

          props.setSaveDeliverable && props.setSaveDeliverable(false);
          // const doc = document.querySelector("");
          addCancelButtonInDeleteBlockModel();
        },

        /* Descriptiopn: Define content block plugin api url's  */
        pluginApiUrl: {
          company: COMPANY_URL,
          product: PRODUCT_URL,
          news: NEWS_URL,
          video: TV_URL,
          people: PEOPLE_URL,
          patent: PATENT_URL,
          taxonomy: CMS_API_URL,
          deliverable: DELIVERABLE_URL,
          cmsApiUrl: CMS_API_URL,
        },
        /* Descriptiopn: Define content block default filters for plugin api url's  */
        pluginApiUrlDefaultFilters: [
          ["productVariant.productName", "eq", productVaraint],
          ["productVariant.isLive", "eq", "YES"],
        ],
        entityId: props.remarkEntityType == ENTITY_TYPE.DELIVERABLE ? props?.contentId : "",
        productVaraint: productVaraint || "",
        insiderV2Url: process.env.REACT_APP_INSIDER_V2_URL || "",
        industriesData: props?.industriesData?.length > 0 ? props?.industriesData : [],
        userCompentencyIndustry: props?.userCompentencyIndustry?.length > 0 ? props?.userCompentencyIndustry[0]?.industryId : "",
        deliverableContentType: props?.deliverableContentType ? props?.deliverableContentType : "",
        deliverableType: props?.deliverableType ? props?.deliverableType : "",
        userId: user._id,
      });
      setBuildState(build);
      // props.contentBuil(build)
    }
  };

  /*Description : Adding dropdown to filter blocks by industry */
  useEffect(() => {
    if (buildState && props.remarkEntityType == "deliverable" && productVaraint == "Insider") {
      let snippetSection = document.querySelector("#divSnippetList");
      let industry_dropDown = document.querySelector(".industry_dropDown");
      let industryData = [];
      let userCompetencyIndustry =
        localStorage.getItem("userCompetencyIndustry") !== "null" ? localStorage.getItem("userCompetencyIndustry") : "null";
      if (userCompetencyIndustry !== "null" && userCompetencyIndustry !== undefined) {
        userCompetencyIndustry = JSON.parse(userCompetencyIndustry);
        industryData = userCompetencyIndustry;
      }
      let defaultSelect = {};
      if (isAdmin()) defaultSelect["All"] = true;
      if (industryData.length > 0 && !isAdmin()) defaultSelect[industryData[0].id] = "selected";
      if (snippetSection && industry_dropDown == null) {
        const headerDiv = document.createElement("div");
        headerDiv.classList.add("industry_dropDown");
        /*Add the HTML code to the header div */
        headerDiv.innerHTML = `
          <label class="dropdown_label" for="dropdown" style="width:48%;">Select Industry</label>
          <select id="industry_selection" name="industry" class="select">
            <option class="industry_options" ${defaultSelect["All"] || ""} value="All">All</option>
            <option class="industry_options" ${defaultSelect["5b1e73b110ddc9e2482e832b"] || ""} value="5b1e73b110ddc9e2482e832b">Energy</option>
            <option class="industry_options" ${
              defaultSelect["5b1e73b110ddc9e2482e832c"] || ""
            } value="5b1e73b110ddc9e2482e832c">Food & Nutrition</option>
            <option class="industry_options" ${defaultSelect["5b1e73b110ddc9e2482e832a"] || ""} value="5b1e73b110ddc9e2482e832a">Mobility</option>
            <option class="industry_options" ${
              defaultSelect["63357c1a3a9be40d03da1127"] || ""
            } value="63357c1a3a9be40d03da1127">Personal Care</option>
          </select>
        `;
        snippetSection.insertBefore(headerDiv, snippetSection.firstChild);

        let selected = document.querySelector("#industry_selection");
        selected.addEventListener("change", function () {
          let selectedIndustry = document.getElementById("industry_selection").value;
          addSnippet(selectedIndustry);
        });
        addSnippet(selected.value);
      }

      /* Get a reference to the snippet_header element */
      const newSnippetDiv = document.querySelector(".snippet_header");
      const snippetList = document.querySelector(".is-design-list");
      snippetList.style.height = "93%";
      newSnippetDiv.style.top = "42px";
    }
  }, [buildState]);

  /*Description : Handle show or hide blocks according to industry*/
  const addSnippet = async (selectedIndustry) => {
    await fetchSnippetOptions(selectedIndustry);
    let selectedBlock = document.querySelector(".snippet-cat");
    let selected_block_id = selectedBlock.getAttribute("data-value");
    /* Get all blocks */
    let allSnippets = document.querySelectorAll(".snippet-item");
    allSnippets.forEach((data) => {
      let industry = data.getAttribute("data-industry");
      let block_category_id = data.getAttribute("data-cat");
      let arry = industry.split(",");
      let selected_industry = document.querySelector("#industry_selection").value;
      /* Show the selected option blocks only and hide rest blocks. */
      if (arry.includes(`${selected_industry}`) && selected_block_id == block_category_id) {
        data.classList.remove("hide");
      } else {
        data.classList.add("hide");
      }
    });
  };

  useEffect(() => {
    /* Reset the industry dropdown*/
    let selectedIndustry = document.getElementById("industry_selection");
    if (selectedIndustry && props.resetIndutryDropdown) addSnippet(selectedIndustry.value);
    if (props.setResetIndutryDropdown) props.setResetIndutryDropdown(false);

    /* Reset the block dropdown value */
    let selectedBlock = document.querySelector(".snippet-cat");
    if (selectedBlock) {
      selectedBlock.setAttribute("data-value", 520);
      const spanEle = selectedBlock.querySelector("span");
      spanEle.textContent = "Advert Blocks";
    }
  }, [props.resetIndutryDropdown]);

  /*Description : Handle show or hide blocks according to industry*/
  const fetchSnippetOptions = async (industry) => {
    let params = {};
    params.fields = ["contentBlockIndustryTrendsTags", "contentBlockName", "contentBlockCategoryId"];
    params.filters = [
      // ["contentBlockContentType", "cn", props.deliverableType],
      ["contentBlockContentType", "neq", "Email Template"],
      ["contentBlockStatus", "eq", "Published"],
      ["productVariant.productName", "eq", productVaraint],
    ];
    if (industry !== "All") {
      params.filters.push(["content_block_industry_trends_tags.trend_id", "in", [industry]]);
    }
    let uniqueArry = [];
    await fetchContentBlock(params).then(async (res) => {
      if (res.status === 200) {
        let categoryIds = res.data.data.map((data) => {
          return data.contentBlockCategoryId;
        });
        uniqueArry = [...new Set([...categoryIds])];
      }
    });
    /* My briefcase changes start */
    let selectedBlock = document.querySelector(".snippet-cat");
    if (props.remarkEntityType == "deliverable") {
      if (props.updateFlag) {
        dispatch(showLoader());
        let CategoryParams = {
          filters: [
            ["categoryActionType", "eq", "BRIEFCASE"],
            ["categoryProductPlatform", "eq", productVaraint + " V2"],
          ],
          sort: "categoryCreatedDate:desc",
          fields: ["id", "categoryName", "categoryType", "categoryProductPlatform", "categoryActionType"],
          limit: 10000,
        };

        const category = await fetchPresentationCategory(user._id, CategoryParams);
        uniqueArry = category.data.data.map((a) => a.id);

        if (selectedBlock) {
          const datVal = selectedBlock.getAttribute("data-value");
          if (datVal.length < 4) {
            selectedBlock.setAttribute("data-value", "");
            const spanEle = selectedBlock.querySelector("span");
            spanEle.textContent = "Please select folder";
          }
        }
        let selected_block_id = selectedBlock.getAttribute("data-value");
        let allSnippets = document.querySelectorAll(".snippet-item");
        allSnippets.forEach((data) => {
          let block_category_id = data.getAttribute("data-cat");
          /* Show the selected option blocks only and hide rest blocks. */
          if (selected_block_id == block_category_id) data.classList.remove("hide");
          else data.classList.add("hide");
        });
        dispatch(hideLoader());
      } else {
        dispatch(showLoader());
        if (selectedBlock) {
          selectedBlock.setAttribute("data-value", 520);
          const spanEle = selectedBlock.querySelector("span");
          spanEle.textContent = "Advert Blocks";
        }
        let selected_block_id = selectedBlock.getAttribute("data-value");
        let allSnippets = document.querySelectorAll(".snippet-item");
        allSnippets.forEach((data) => {
          let industryVal = data.getAttribute("data-industry");
          let block_category_id = data.getAttribute("data-cat");
          let arry = industryVal.split(",");
          let selected_industry = document.querySelector("#industry_selection").value;
          /* Show the selected option blocks only and hide rest blocks. */
          if (arry.includes(`${selected_industry}`) && selected_block_id == block_category_id) {
            data.classList.remove("hide");
          } else {
            data.classList.add("hide");
          }
        });
        dispatch(hideLoader());
      }
    }
    /* My briefcase changes end */
    /*Description : Get all block options from block selection dropdown*/
    let snippentOptions = document.querySelector(".is-selectbox-options");
    /*Description : Hide block option from dropdown if no block is available inside it*/
    snippentOptions.children.forEach((d) => {
      let id = d.getAttribute("data-value");
      if (uniqueArry.includes(`${id}`)) {
        d.style.display = "block";
      } else {
        d.style.display = "none";
      }
    });
  };

  /*Description : Added cancel button for delete pop-up modal */
  const addCancelButtonInDeleteBlockModel = () => {
    const row = document.querySelector(".row-remove");
    const ele = document.querySelector(".elm-remove");
    const cell = document.querySelector(".is-column-tool .cell-remove");
    cell && cell.addEventListener("click", () => cancelBtnFunction());
    row && row.addEventListener("click", () => cancelBtnFunction());
    ele && ele.addEventListener("click", () => cancelBtnFunction());
  };

  useEffect(() => {
    if (toggleBuild) {
      buildState && props.preserveContentHtml && props.preserveContentHtml(buildState.html());
      props.setStoreBlockData && props.setStoreBlockData(buildState.html());
      setToggleBuild(false);
    }
  }, [toggleBuild]);

  useEffect(() => {
    if (props.isPage) {
      if (props.isLoadedContent) {
        // props.setLoadContentBuilder(true)
        createContentBulder();
      }
    } else {
      createContentBulder();
    }
  }, [snippets, data_basic, props.isLoadedContent, props.setContentHtml, props.activeState]);
  useEffect(() => {
    if (props.saveContentFlag) {
      let selector = document.querySelectorAll(".column");
      if (buildState && buildState.html().length < 1) {
        dispatch(actionError("Content cannot be empty"));
      } else {
        const { contentField } = props;
        buildState && props.setContentHtml(buildState.html());
        if (props?.setFlag) {
          props.setFlag(true);
        }
        const sndObject = {
          ...props.params,
          [contentField]: selector && buildState && buildState.html(),
        };
        let response;
        if (props.isPage) {
          if (props.isLoadedContent) {
            if (props.paramsPageId) {
              response = props.saveContentApi(props.contentId, props.paramsPageId, sndObject);
              dispatch(showLoader());
            } else {
              response = "";
            }
          } else {
            dispatch(actionError("Please select page block from navigation"));
          }
        } else {
          props.params && props.params.append("contentBlockHtml", buildState.html());
          response = props.saveContentApi(props.contentId, props.params);
        }
        response &&
          response
            .then((e) => {
              dispatch(hideLoader());
              // dispatch(actionSuccess("Saved successfully."));
            })
            .catch((err) => {
              dispatch(hideLoader());
              if (props.isPage) {
                if (!props.paramsPageId.trim()) {
                  return dispatch(actionError("Create Content Block First"));
                }
              }
              dispatch(actionError("Unable to save content block. Please try again"));
            });
        if (!response) dispatch(actionError("Please create/select page block from navigation"));
      }
    } else {
      if (props.isPage) {
        !readOnly && buildState && buildState.applyBehavior();

        if (props.contentHtml === "" && props.remarkEntityType == ENTITY_TYPE.DELIVERABLE) {
          let a = document.querySelector(".container.content-builder-container");

          if (a) {
            let btn = a.querySelector("button");

            if (props.viewPageBlocks) {
              a.innerHTML = "";
              let btn = document.querySelector(".row-add-initial");
              if (btn) {
                btn.remove();
              }

              // props.setViewPageBlocks(false);
            } else {
              a.classList.remove("d-none");

              // let addBtn = `<button type="button" class="row-add-initial">Empty<br><span>+ Click to add content</span></button>`;
              a.innerHTML = "";
              const button = document.createElement("button");
              button.type = "button";
              button.className = "row-add-initial";
              button.innerHTML = "Empty<br><span>+ Click to add content</span>";

              a.appendChild(button);
              // a.appendChild(btn);
            }
          }
        }
        // props.setContentHtml(props.contentHtml);
      } else {
        buildState && buildState.applyBehavior();
        props.fetchContent(props.contentId);
      }
    }
  }, [props.saveContentFlag, props.isPage, props.contentHtml, props.reRenderContent, props.viewPageBlocks, readOnly]);

  /* My briefcase useEffect */
  useEffect(() => {
    if (document.querySelector(".is-selectbox-options") && props.remarkEntityType == "deliverable" && productVaraint == "Insider")
      fetchSnippetOptions("All");
  }, [props.updateFlag]);
  /*Description : Check wheater any sidebar is active for deliverable and content block module */
  let sideBarActive = props.pageViewSidebar || props.remarkSidebar || props.viewBlocks || props.viewRemark ? true : false;

  /* Description : Added custom close icon for table setting color picker modal */
  let getTableModel = [...document.querySelectorAll(".is-modal.pickcolor")];
  let settingModal = getTableModel[2];
  if (settingModal) {
    let tableModal = settingModal.querySelector(".is-modal-content");
    if (tableModal !== null) {
      const closeBtn = document.createElement("img");
      closeBtn.setAttribute("src", "/contentbuilder/Images/close-icon.svg");
      const closeIcon = document.querySelector(".close-icon");
      if (closeIcon === null) {
        tableModal.appendChild(closeBtn).classList.add("close-icon");
      }
      let tabelSettingModal = document.querySelector("#_cbhtml .edittable.active");
      // if (tabelSettingModal);
      let btn = window.document.querySelector(".input-table-bgcolor");
      btn.addEventListener("click", (e) => {
        settingModal.classList.add("active");
      });
      const xlose = document.querySelector(".close-icon");
      xlose.addEventListener("click", () => {
        let showColorModal = document.querySelector(".is-modal.pickcolor.active");
        if (showColorModal?.classList.contains("active")) {
          showColorModal.classList.remove("active");
        }
      });
    }
  }
  if (props.loadContentBuilder) {
    return props && props.contentHtml ? (
      <>
        <div className={`${sideBarActive ? "sidebar_active" : "sidebar_inactive"}`}>
          <div
            className="container content-builder-container mt-4 mr-auto"
            ref={builderRef}
            dangerouslySetInnerHTML={{ __html: props.contentHtml }}
          ></div>
        </div>

        {showModal && (
          <ImageEditorModal
            showModal={showModal}
            setShowModal={() => {
              setShowModal(!showModal);
            }}
            imageId={imageId}
            imageURL={imageURL}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            setToggleBuild={setToggleBuild}
          />
        )}
        {/* <ImageModal imageURL={imageURL} hideDownload={true} hideZoom={true} onOpen={imgModal} onClose={() => setImgModal(!imgModal)} /> */}
      </>
    ) : (
      <>
        <div className={`${sideBarActive ? "sidebar_active" : "sidebar_inactive"}`}>
          <div
            className={`${props?.defaultBlock} container content-builder-container mt-4`}
            ref={builderRef}
            dangerouslySetInnerHTML={{ __html: props.contentHtml }}
          ></div>
        </div>
        {showModal && (
          <ImageEditorModal
            showModal={showModal}
            setShowModal={() => {
              setShowModal(!showModal);
            }}
            imageId={imageId}
            imageURL={imageURL}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            setToggleBuild={setToggleBuild}
          />
        )}
        {/* <ImageModal imageURL={imageURL} hideDownload={true} hideZoom={true} onOpen={imgModal} onClose={() => setImgModal(!imgModal)} /> */}
      </>
    );
  } else {
    return props && props.contentHtml ? (
      <>
        <div className={`${sideBarActive ? "sidebar_active" : "sidebar_inactive"}`}>
          {typeof props.contentHtml !== "string" &&
            props.contentHtml?.map((a) => {
              return (
                <>
                  {a.merged === "yes" ? (
                    <Carousel
                      showArrows={true}
                      showStatus={false}
                      showThumbs={false}
                      swipeable={false}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                          <button type="button" onClick={onClickHandler} className="next-arrow-icon" style={{ left: 0 }}>
                            <img
                              src="/assets/down-arrow.svg"
                              alt="arrow"
                              style={{
                                transform: "rotate(270deg)",
                              }}
                            />
                          </button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                          <button type="button" onClick={onClickHandler} className="next-arrow-icon" style={{ right: 0 }}>
                            <img
                              src="/assets/down-arrow.svg"
                              alt="arrow"
                              style={{
                                transform: "rotate(90deg)",
                              }}
                            />
                          </button>
                        )
                      }
                    >
                      {a.content.map((d) => (
                        <div
                          className="container content-builder-container mt-4 mr-auto"
                          ref={builderRef}
                          dangerouslySetInnerHTML={{ __html: d.htmlContent }}
                        ></div>
                      ))}
                    </Carousel>
                  ) : (
                    <div
                      className="container content-builder-container mt-4 mr-auto"
                      ref={builderRef}
                      dangerouslySetInnerHTML={{ __html: a.htmlContent }}
                    ></div>
                  )}
                </>
              );
            })}

          {/* <div
            id="page_html"
            className="container content-builder-container mt-4 mr-auto"
            ref={builderRef}
            dangerouslySetInnerHTML={{ __html: props.contentHtml }}
          ></div> */}
        </div>
        {showModal && (
          <ImageEditorModal
            showModal={showModal}
            setShowModal={() => {
              setShowModal(!showModal);
            }}
            imageId={imageId}
            imageURL={imageURL}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            setToggleBuild={setToggleBuild}
          />
        )}
        {/* <ImageModal
          imageURL={imageURL}
          hideDownload={true}
          hideZoom={true}
          large={imageURL}
          onOpen={imgModal}
          onClose={() => setImgModal(!imgModal)}
        /> */}
      </>
    ) : (
      <>
        <div className={`${sideBarActive ? "sidebar_active" : "sidebar_inactive"}`}>
          <div className="container content-builder-container mt-4" ref={builderRef} style={{ display: "none" }}></div>
        </div>
        {showModal && (
          <ImageEditorModal
            showModal={showModal}
            setShowModal={() => {
              setShowModal(!showModal);
            }}
            imageId={imageId}
            imageURL={imageURL}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            setToggleBuild={setToggleBuild}
          />
        )}
        {/* <ImageModal
          imageURL={imageURL}
          hideDownload={true}
          hideZoom={true}
          large={imageURL}
          onOpen={imgModal}
          onClose={() => setImgModal(!imgModal)}
        /> */}
      </>
    );
  }
};

export default Main;
