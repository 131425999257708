/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description            :  Company Advanced info Action Buttons
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 12/Dec/2023 
    Author						  : Lawrence Anthony
================================================================ 
*/

import React, { useEffect, useState } from "react";
import { processingSubmit } from "../../../../../middleware/services/cmsApi";
import { Link, useHistory } from "react-router-dom";
import { accessRightActionCheck, getLoggedInUser } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../../middleware/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const ActionButtons = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const companyId = props.companyId;

  const cancelPath = props.back;
  const updateStatus = useSelector((state) => state.companyState.updateStatus);
  const clickStatus = useSelector((state) => state.companyState.clickStatus);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props?.hashArr)[props.selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);

  useEffect(() => {
    if (clickStatus) props.handleSave();
  }, [clickStatus]);
  useEffect(() => {
    if (updateStatus) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            processingSubmit(companyId, "advanced", {
              userId: loggedInUser._id,
            })
              .then((response) => {
                dispatch(actionSuccess(`Company successfully Saved & Submitted`));
                dispatch(setClickSaveSubmitState());
                dispatch(setOverviewUpdateStatus(false));
              })
              .catch((err) => {
                console.log(err.data.message);
                dispatch(actionError(err.data.message || "Something went wrong"));
              });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to submit ? ",
          title: "dialogAlertCss",
        })
      );
    }
  }, [updateStatus]);

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-6 text-right ">
            <Link to={cancelPath}>
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                onClick={() => {
                  history.push(`${cancelPath}`);
                }}
              >
                Cancel
              </button>
            </Link>
            {accessActionSave ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 mr-3" onClick={props.handleSave}>
                Save
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
