import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { fetchGroupList, fetchUserTraficReport, fetchTraficUsers } from "../../../middleware/services/reportApi";
import { Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import "./style.scss";
import CustomSidebar from "./SideBar";
import { StringFilter, DropDownFilter } from "app/components/dataGrid/fliters";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-treegrid";
import { presets } from "utils";
import * as CONSTANTS from "../../../constants/index";

const SiderBarGrid = ({ onclose, fetch }) => {
  const [rowData, setRowData] = useState([]);

  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const handleQueryCellInfo = (args) => {
    if (args.column.field && !args.data[args.column.field] && args.data[args.column.field] !== 0) args.cell.innerHTML = "NA";
  };

  const headerDualCom = (argOne, argTwo) => {
    return (
      <div>
        <span className="header-cell">{argOne}</span>
        <span className="header-cell">{argTwo}</span>
      </div>
    );
  };

  const enabledisableFilter = (value) => {
    let sourceData = [
      { id: "enabled", text: "Enabled" },
      { id: "disabled", text: "Disabled" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const yesNoFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "YES" },
      { id: "NO", text: "NO" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const columnFields = [
    {
      field: "userName",
      headerText: "Name",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "150px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("User", "Name"),
      isFrozen: true,
    },
    {
      field: "subscriptionBtbCompanyName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "150px",
      allowFiltering: false,
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Group", "Name"),
    },
    {
      field: "userEmail",
      headerText: "Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "120px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("User", "Email"),
    },
    {
      field: "userMobile",
      headerText: "Contact Number",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Contact", "Number"),
    },
    {
      field: "linkedInAddress",
      headerText: "LinkedIn Address",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("LinkedIn", "Address"),
    },
    {
      field: "subscriptionType",
      headerText: "Trial",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "70px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Is", "Trial"),
    },
    {
      field: "roundUp",
      headerText: "Round Up",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "75px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round", "Up"),
    },
    {
      field: "roundUpEmail",
      headerText: "Round Up - Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round Up", "Email"),
    },
    {
      field: "roundUpWhatsApp",
      headerText: "Round Up - WhatsApp",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "95px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round Up", "WhatsApp"),
    },
    {
      field: "myAlert",
      headerText: "My Alerts",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "80px",
      filterTemplate: enabledisableFilter,
      headerTemplate: () => headerDualCom("My", "Alerts"),
    },
    {
      field: "subscriptionStartDate",
      headerText: "Subscription On Start Date",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "138px",
      format: { type: "date", format: "dd-MMM-yyyy" },
      headerTemplate: () => headerDualCom("Subscription On", "Start Date (UTC)"),
    },
    {
      field: "subscriptionEndDate",
      headerText: "Subscription On End Date",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "138px",
      format: { type: "date", format: "dd-MMM-yyyy" },
      headerTemplate: () => headerDualCom("Subscription On", "End Date (UTC)"),
    },
    {
      field: "subscriptionIsActive",
      headerText: "Subscription Status",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "95px",
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Subscription", "Status"),
    },
    {
      field: "firstActionTimestamp",
      headerText: "Visit Start Time (UTC)",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "120px",
      format: { type: "date", format: "dd-MMM-yyyy hh:mm" },
      headerTemplate: () => headerDualCom("Visit Start", "Time (UTC)"),
    },
    {
      field: "deviceType",
      headerText: "Device",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "80px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Device", "Type"),
    },
    {
      field: "lastActionTimestamp",
      headerText: "Visit End Time (UTC)",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "120px",
      format: { type: "date", format: "dd-MMM-yyyy hh:mm" },
      headerTemplate: () => headerDualCom("Visit End", "Time (UTC)"),
    },
    {
      field: "dashboardPage",
      headerText: "Time spent on Dashboard",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "105px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("On Dashboard", "(Mins)"),
    },
    {
      field: "homePage",
      headerText: "Time spent on Home Page",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",

      headerTemplate: () => headerDualCom("Home Page", "(Mins)"),
    },
    {
      field: "searchPage",
      headerText: "Time spent on Search Page",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",

      headerTemplate: () => headerDualCom("Search Page", "(Mins)"),
    },
    {
      field: "contentReadingPage",
      headerText: "Time spent on Content Page",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",

      headerTemplate: () => headerDualCom("Content Page", "(Mins)"),
    },
    {
      field: "customProjectsPage",
      headerText: "Time Spent on Custom Projects",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "125px",

      headerTemplate: () => headerDualCom("Custom Projects", "(Mins)"),
    },
    {
      field: "personalizedViewsPage",
      headerText: "Time Spent on Personalized Views",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "125px",

      headerTemplate: () => headerDualCom("Personalized Views", "(Mins)"),
    },
    {
      field: "myRequestsPage",
      headerText: "Time Spent on My Requests Page",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "125px",

      headerTemplate: () => headerDualCom("My Requests", "(Mins)"),
    },
    {
      field: "myPresentationsPage",
      headerText: "Time Spent on My Presentation",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",

      headerTemplate: () => headerDualCom("My Presentation", "(Mins)"),
    },
    {
      field: "bookmarksPage",
      headerText: "Time Spent on Bookmark",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "105px",

      headerTemplate: () => headerDualCom("On Bookmark", "(Mins)"),
    },
    {
      field: "savedsearchPage",
      headerText: "Time Spent on Saved Searches",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",

      headerTemplate: () => headerDualCom("Saved Searches", "(Mins)"),
    },
    {
      field: "technicalHelpdeskPage",
      headerText: "Time Spent on Technical Helpdesk",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "125px",

      headerTemplate: () => headerDualCom("Technical", "Helpdesk (Mins)"),
    },
  ];

  const fetchUsersData = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetch();
      if (response?.data?.data.length > 0) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <React.Fragment>
      <div className="gennx-grid-client-detail-report client-details-user-grid user-traffic-grid-report" style={{ height: "100%" }}>
        <header className="user-grid-header">
          <span className="header-text">Detail View</span>
          <span
            className="closeIcon"
            onClick={() => {
              onclose();
            }}
          >
            <CloseIcon />
          </span>
        </header>
        {rowData.length > 0 && (
          <TreeDataGridNew
            gridTitle="Client Activity Report"
            rowData={rowData}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            exportFileName={"User Traffic Detail Report"}
            dropDownFilterFields={["subscriptionType", "roundUp", "roundUpEmail", "roundUpWhatsApp", "myAlert", "subscriptionIsActive"]}
            handleQueryCellInfo={handleQueryCellInfo}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
    </React.Fragment>
  );
};

// company dashboard component
const UserTrafic = (props) => {
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [groupDataSource, setGoupDataSource] = useState([]);
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState("");
  const [rowData, setRowData] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const sidebarRef = useRef(null);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const toggleSidebar = (fieldName, groupName) => {
    let filters = [];
    filters.push(["subscriptionBtbCompanyName", "eq", groupName]);
    switch (fieldName) {
      case "desktop":
        setDeviceFilter("Desktop");
        break;
      case "mobile":
        setDeviceFilter("Smartphone");
        break;
      case "tablet":
        setDeviceFilter("Tablet");
        break;
      case "activeUser":
        filters.push(["subscriptionUserIsActive", "eq", "YES"]);
        break;
      case "inActiveUser":
        filters.push(["subscriptionUserIsActive", "eq", "NO"]);
        break;
      default:
        break;
    }

    setSideBarFilters([...filters]);

    if (sidebarRef.current) {
      sidebarRef.current.hide();
    }
    setShowSidePanel(!showSidePanel);
  };

  const fetchUsers = useCallback(
    (params = {}) => {
      const { filters = [] } = params;
      const analysisPeriodFilter = [];
      if (dateRange) {
        const [startDate, endDate] = dateRange;

        const gte = { d: moment(startDate).toISOString() };
        const lt = { d: moment(endDate).add(1, "day").toISOString() };

        analysisPeriodFilter.push(["firstActionTimestamp", "gte", gte.d]);
        analysisPeriodFilter.push(["lastActionTimestamp", "lt", lt.d]);
      }
      if (deviceFilter) {
        analysisPeriodFilter.push(["deviceType", "eq", deviceFilter]);
      }
      params.filters = [...sideBarFilters, ...filters];
      params.analysisPeriodFilter = analysisPeriodFilter;
      setDeviceFilter("");
      return fetchTraficUsers({ ...params });
    },
    [sideBarFilters]
  );

  const initialState = {
    reportGroup: "All",
    reportAccountStatus: "All",
    reportIndustry: "",
  };
  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.BOREPORTDEFAULTDATERANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setDateRange([dateFrom, dateTo]);
    return [dateFrom, dateTo];
  };

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    // validationSchema: Yup.object().shape(fundingValidationSchema)
  });
  const dispatch = useDispatch();

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return a ? (
      <span className="span-link-color" onClick={() => toggleSidebar(value.column.field, value["groupName"])}>
        {a}
      </span>
    ) : (
      <span>{a}</span>
    );
  };

  const columnFields = [
    {
      field: "groupName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "130px",
      allowFiltering: false,
      allowSorting: false,
    },
    {
      textAlign: "Center",
      headerText: "User Count (Subscription)",
      columns: [
        {
          field: "activeUser",
          headerText: "Active User",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "85px" : "82px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "inActiveUser",
          headerText: "Inactive User",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "85px" : "82px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalActiveInactive",
          headerText: "Total User",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "75px" : "72px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Device Type",
      columns: [
        {
          field: "desktop",
          headerText: "Desktop Visits",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "85px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "mobile",
          headerText: "Smart Phone Visits",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "107px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "tablet",
          headerText: "Tablet Visits",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "78px" : "75px",

          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalDeviceCount",
          headerText: "Total Visits",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "80px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Page Wise Time Spent Matrix (Mins)",
      columns: [
        {
          field: "totalCount",
          headerText: "Total Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "95px",
          allowSorting: false,
        },
        {
          field: "avgTotalCount",
          headerText: "Avg Time Per Visit",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          allowSorting: false,
        },
        {
          field: "dashboardPage",
          headerText: "Dashboard Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "105px",
          allowSorting: false,
        },
        {
          field: "homePage",
          headerText: "Home Page Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          allowSorting: false,
        },
        {
          field: "searchPage",
          headerText: "Search Page Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "110px" : "105px",
          allowSorting: false,
        },
        {
          field: "contentReadingPage",
          headerText: "Content Page Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "115px" : "110px",
          allowSorting: false,
        },
        {
          field: "customProjectsPage",
          headerText: "Custom Projects",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "100px" : "95px",
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "My Space Time Matrix (Mins)",
      columns: [
        {
          field: "myRequestsPage",
          headerText: "My Requests",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          allowSorting: false,
        },
        {
          field: "myPresentationsPage",
          headerText: "My Presentation",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "115px" : "110px",
          allowSorting: false,
        },
        {
          field: "bookmarksPage",
          headerText: "My Bookmark",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          allowSorting: false,
        },
        {
          field: "savedsearchPage",
          headerText: "Saved Searches",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          allowSorting: false,
        },
        {
          field: "personalizedViewsPage",
          headerText: "Personalized Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "100px" : "95px",
          allowSorting: false,
        },
        {
          field: "technicalHelpdeskPage",
          headerText: "Technical Helpdesk",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "100px" : "95px",
          allowSorting: false,
        },
      ],
    },
  ];

  const fetchReport = async (reportDateRange, params = {}) => {
    try {
      showLoaderGrid();
      let filters = [];
      let analysisPeriodFilter = [];
      const DateFilter = dateRange === null ? reportDateRange : dateRange;
      if (DateFilter) {
        const [startDate, endDate] = DateFilter;

        const gte = { d: moment(startDate).toISOString() };
        const lt = { d: moment(endDate).add(1, "day").toISOString() };

        analysisPeriodFilter.push(["firstActionTimestamp", "gte", gte.d]);
        analysisPeriodFilter.push(["lastActionTimestamp", "lt", lt.d]);
      }
      if (formik.values.reportGroup && formik.values.reportGroup !== "All") {
        filters.push(["subscription_btb_company_name", "eq", formik.values.reportGroup]);
      }
      if (formik.values.reportAccountStatus && formik.values.reportAccountStatus !== "All") {
        filters.push(["subscriptionUserIsActive", "eq", formik.values.reportAccountStatus]);
      }
      params.analysisPeriodFilter = analysisPeriodFilter;
      params.filters = [...filters];
      let response = await fetchUserTraficReport({ ...params });
      hideLoaderGrid();
      setRowData(response.data.data);
    } catch (e) {
      console.log("err :::::::", e);
      hideLoaderGrid();
    }
  };

  const handleReset = () => {
    window.location.reload();
  };

  const groupsDataSource = useMemo(() => {
    let groupData = groupDataSource?.map((group) => {
      return { label: group.groupName, value: group.groupName };
    });
    groupData.unshift({ label: "All", value: "All" });
    return groupData;
  }, [groupDataSource]);
  const accountStatusDataSource = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "YES",
    },
    {
      label: "Inactive",
      value: "NO",
    },
  ];

  const fetchGroups = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetchGroupList();
      setGoupDataSource(response.data.data);
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    hideLoaderGrid();
  };

  const searchHandler = async () => {
    try {
      showLoaderGrid();
      if (gridRef?.current) {
        const roundupSets = {
          YES: "Active User",
          NO: "Inactive User",
        };

        const selectedIndustry = formik.values.reportAccountStatus;
        const roundupSet = roundupSets[selectedIndustry] ?? "";
        gridRef.current.columns =
          selectedIndustry === "All"
            ? columnFields
            : columnFields
                .map((field) => {
                  const newField = { ...field };
                  if (field?.columns && field?.headerText === "User Count (Subscription)") {
                    newField.columns = field.columns.filter((childColumn) => childColumn.headerText === roundupSet) || [];
                  }
                  return newField;
                })
                .filter(Boolean);
      }
      await fetchReport();
      hideLoaderGrid();
    } catch (error) {
      console.log(error);
      hideLoaderGrid();
    }
  };

  useEffect(() => {
    (async () => {
      await fetchGroups();
      const reportDateRange = fnStartDate();
      await fetchReport(reportDateRange);
    })();
  }, []);

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row">
          <div className="col-md-2 mt-3 grid-container-report-filters pr-0 responsive-report-filters">
            <DateRangePickerComponent
              placeholder="Period for Analysis"
              cssClass="e-outline user-traffic-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Group"
              required="required"
              id="reportGroup"
              name="reportGroup"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportGroup}
              dataSource={groupsDataSource}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Account Status"
              required="required"
              id="reportAccountStatus"
              name="reportAccountStatus"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportAccountStatus}
              dataSource={accountStatusDataSource}
            />
          </div>
          <div className="col-md-3 pr-0 mt-3">
            <button type="button" onClick={searchHandler} className="search-btn px-4 btn btn-primary mr-2">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report user-detail-report-grid user-traffic-report">
        <TreeDataGridNew
          gridTitle="Client Activity Report"
          rowData={rowData}
          getGridRef={getGridRef}
          isPaging={false}
          showResetFilter={true}
          deleteMany={false}
          allowPagination={true}
          showExportButton={true}
          exportFileName={"User Traffic Report"}
          // allowResizing={true}
          // columnChooserHandler={columnChooserOpenedHandler}
          // created={handleCreated}
        >
          <ColumnsDirective>
            {columnFields.map((column, index) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
        </TreeDataGridNew>
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel}>
          <SiderBarGrid onclose={toggleSidebar} fetch={fetchUsers} />
        </CustomSidebar>
      )}
    </div>
  );
};

export default UserTrafic;
