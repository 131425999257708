/**
 * @author: Pankaj Kulshreshtha
 * @date : 21/Jun/2021
 * File Description: Assessment Form Detials Page
 */

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, Dropdown, MultiSelect, NumericTextField } from "../../components/CustomFormElements/syncFusionFormFields";
import { CkEditor } from "../../components/CustomFormElements/ProductInformation";
import { ckEditorMinimalConfig } from "../../../config/ckEditorConfig";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getLoggedInUser } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { assessmentValidationSchema } from "../../helper/validationSchema/outSourcingValidationSchema";
import {
  saveAssessment,
  fetchAssessmentById,
  updateAssessment,
  fetchFunctionalExpertiseData,
  fetchEvaluationParams,
} from "../../../middleware/services/cmsApi";
import FORM_CONSTANTS from "./constants";
import "./css/assessment.scss";

const initialState = {
  assessmentName: "",
  assessmentDuration: "",
  assessmentUniqueCode: "",
  assessmentProficiencyLevel: "",
  assessmentLink: "",
  assessmentDescription: "",
  functionalCompetancy: "",
  assessmentEvaluationParams: [],
};

const AssessmentFormDetails = (props) => {
  const dispatch = useDispatch();
  const assessmentId = props.computedMatch?.params?.assessmentId || "";
  const [editData, setEditData] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [functionalExpertise, setFunctionalExpertise] = useState(null);
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const accessPermissionAdd = true;
  const accessPermissionEdit = true;

  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(assessmentValidationSchema),
  });

  const breadCrumbLinks = [
    { linkUrl: "/assessment", linkName: "Assessment", linkActive: false },
    {
      linkUrl: assessmentId ? `/assessment/${assessmentId}/edit` : "/assessment/add",
      linkName: editData?.assessmentName || "Add New",
      linkActive: true,
    },
    // { linkUrl: "/assessment/form-designer#basic", linkName: "Basic", linkActive: true },
  ];
  const fetchBasicDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "assessmentUniqueCode",
      "assessmentName",
      "assessmentDuration",
      "assessmentProficiencyLevel",
      "assessmentLink",
      "assessmentFunctionalCompetency",
      "assessmentEvaluationParams",
      "assessmentDescription",
    ];
    params.fields = fields;
    params.filters = [...defaultFilter];
    const result = fetchAssessmentById(assessmentId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      formik.setValues({
        assessmentName: data.assessmentName || "",
        assessmentDuration: data.assessmentDuration || "",
        assessmentUniqueCode: data.assessmentUniqueCode,
        assessmentProficiencyLevel: data.assessmentProficiencyLevel || "",
        assessmentLink: data.assessmentLink || "",
        assessmentDescription: data.assessmentDescription || "",
        functionalCompetancy: data.assessmentFunctionalCompetency || "",
        assessmentEvaluationParams:
          (data.assessmentEvaluationParams &&
            data.assessmentEvaluationParams.map((v) => {
              return v.parameterName;
            })) ||
          [],
      });
      dispatch(hideLoader());
    });
  };

  /**
   * @description fetch functional Expertise
   */
  const fetchFunctionalExpertise = () => {
    fetchFunctionalExpertiseData({ sort: "parent:asc,expertise:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setFunctionalExpertise(data);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Functional Expertise Failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    fetchFunctionalExpertise();
    if (assessmentId) {
      dispatch(showLoader());
      if (!editData) fetchBasicDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/assessment"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, assessmentId]);

  const saveAssessmentFormDetails = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const {
          assessmentName,
          assessmentDuration,
          assessmentProficiencyLevel,
          assessmentLink,
          assessmentDescription,
          functionalCompetancy,
          assessmentEvaluationParams,
        } = formik.values;
        payload.assessmentName = assessmentName;
        payload.assessmentProficiencyLevel = assessmentProficiencyLevel;
        payload.assessmentLink = assessmentLink;
        payload.assessmentDescription = assessmentDescription;
        payload.assessmentFunctionalCompetency = functionalCompetancy;
        payload.assessmentEvaluationParams =
          assessmentEvaluationParams &&
          assessmentEvaluationParams.map((v) => {
            return { parameterName: v };
          });
        if (assessmentDuration && assessmentDuration > 0) payload.assessmentDuration = assessmentDuration;
        if (assessmentId) {
          updateAssessment(assessmentId, payload)
            .then((res) => {
              dispatch(actionSuccess("Assessment details updated successfully"));
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          payload.assessmentCreatedBy = loggedInUser._id;
          saveAssessment(payload)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Assessment details saved successfully"));
                setTimeout(() => {
                  history.push({
                    pathname: `/assessment/${res.data.data.id}/edit`,
                    state: { assessmentId: res.data.data.id },
                  });
                }, 1000);
              } else {
                dispatch(actionError("something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };
  const searchEvaluation = async (searchText) => {
    const response = await fetchEvaluationParams({
      fields: ["parameterName"],
      filters: [["assessmentEvaluationParams.parameterName", "cn", searchText]],
      sort: "parameterName:asc",
      limit: 10,
    });
    if (response.data && response.data.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.parameterName, value: v.parameterName };
      });
      if (resData) {
        setEvaluation(resData);
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="gennx-content-wrapper padding-bottom-150i">
          <div className="row p-1">
            <div className="col-md-6">
              <div className="customCss panel-title" style={{ width: "200px" }}>
                {assessmentId ? "Update" : "Add New"} Assessment
              </div>
            </div>
            {/* <div className="col-md-6">
                    <div className="" >Assessment Description *</div>
                    </div> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              {assessmentId && formik.values.assessmentUniqueCode && (
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      formik={formik}
                      readOnly
                      type="text"
                      id="assessmentUniqueCode"
                      placeholder="Assessment Unique Code *"
                      name="assessmentUniqueCode"
                      showClearButton={false}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <TextField formik={formik} type="text" id="assessmentName" placeholder="Assessment Name *" name="assessmentName" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <NumericTextField
                    formik={formik}
                    format="n0"
                    min={0}
                    id="assessmentDuration"
                    placeholder="Assessment Duration in Minute"
                    name="assessmentDuration"
                  />
                </div>
                <div className="col-md-6">
                  {FORM_CONSTANTS.PROFICIENCY_LEVEL_OPTIONS && (
                    <Dropdown
                      formik={formik}
                      // readonly={assessmentId && formik.values.formType && formik.values.formType === "registration" ? true : false}
                      sortOrder="Acending"
                      // showSelectAll={true}
                      placeholder="Proficiency Level *"
                      id="assessmentProficiencyLevel"
                      name="assessmentProficiencyLevel"
                      fields={{ text: "label", value: "value" }}
                      dataSource={FORM_CONSTANTS.PROFICIENCY_LEVEL_OPTIONS}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField formik={formik} type="text" id="assessmentLink" placeholder="External Assessment Link" name="assessmentLink" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {functionalExpertise && (
                    <MultiSelect
                      formik={formik}
                      mode="Box"
                      // enableRtl={true}
                      sortOrder="Acending"
                      required="required"
                      placeholder="Functional Competency *"
                      id="functionalCompetancy"
                      name="functionalCompetancy"
                      value={formik.values["functionalCompetancy"] && formik.values["functionalCompetancy"]}
                      allowCustomValue={false}
                      fields={{
                        text: "expertise",
                        value: "expertise",
                        groupBy: "parent",
                      }}
                      dataSource={functionalExpertise}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <MultiSelect
                    formik={formik}
                    mode="Box"
                    // enableRtl={true}
                    sortOrder="Acending"
                    placeholder="Evaluation Parameters"
                    id="assessmentEvaluationParams"
                    name="assessmentEvaluationParams"
                    value={formik.values["assessmentEvaluationParams"] && formik.values["assessmentEvaluationParams"]}
                    allowCustomValue={true}
                    htmlAttributes={{ maxLength: "30" }}
                    fields={{ text: "label", value: "value" }}
                    filtering={(e) => {
                      searchEvaluation(e.text);
                    }}
                    noRecordsTemplate={() => <div>Please type to search or create new</div>}
                    dataSource={evaluation}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {/* <label>Assessment Description: </label> */}
              {
                <CkEditor
                  formik={formik}
                  properties={{
                    id: "assessmentDescription",
                    name: "assessmentDescription",
                    activeClass: "assessmentDescription",
                    label: "Assessment Description *",
                    config: {
                      ...ckEditorMinimalConfig,
                      editorplaceholder: "Assessment Description *",
                      height: assessmentId ? 266 : 206,
                    },
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="form-submit-box">
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-right col-md-12 text-right ml-2">
              <Link to={"/assessment" || history.location.pathname}>
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>
              {(accessPermissionAdd || (assessmentId && accessPermissionEdit)) && (
                <button
                  type="button"
                  className="btn btn-primary cy-btn pl-4 pr-4"
                  onClick={() => {
                    saveAssessmentFormDetails();
                  }}
                >
                  {assessmentId ? "Update" : "Save"}
                </button>
              )}
              {/* {assessmentId ? <button type="button" className="btn btn-primary pl-4 pr-4 ml-4"
                            onClick={() => {
                                dispatch(showAlertBox({
                                    okCallback: async () => {
                                        await publishAssessment();
                                    },
                                    content: "Are you sure, you want to Publish?"
                                }));
                            }
                            }
                        >Publish</button> : null
                        } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentFormDetails;
