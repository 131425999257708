import React, { Children, useEffect, useRef, useState } from "react";
// import './App.css';
import "./index.scss";
import * as ReactDOM from "react-dom";
import { enableRipple, setStyleAttribute } from "@syncfusion/ej2-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { useDispatch } from "react-redux";
import { actionError } from "../../../../middleware/actions/utilityAction";
import { DataManager, Query, Predicate } from "@syncfusion/ej2-data";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ReactComponent as Hide } from "../../../../assets/images/hide.svg";
import { ReactComponent as Disable } from "../../../../assets/images/disable.svg";

enableRipple(true);

const TreevieW = (props) => {
  let treeObjRef = useRef();
  const dispatch = useDispatch();
  let treeData = props.treeData;
  let newData = undefined;
  // let [fields, setFields] = useState({ dataSource: treeData, id: 'id', text: 'label', child: 'children' })
  let parentId = props?.parentId;
  let maskRefObj = useRef();
  let noFlat = props?.flat;
  const [search, setSearch] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  // const [searchText, setSearchText] = useEffect("")
  let flat = (data) => {
    var result = [];
    data.forEach(function (a, i) {
      a["taxonomySortOrder"] = i + 1;
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };
  // function reloadTree(data) {
  //     setFields(prevState => ({
  //         ...prevState,
  //         ["dataSource"]: data
  //     }))
  //     treeObjRef.current.dataBind();
  // }
  function sortTree(items) {
    for (const i of items) {
      if (i.children && i.children.length) {
        i.children = sortTree(i.children);
      }
    }
    let sortedObjects = items.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    if (props.sort === "DESC") {
      sortedObjects.reverse();
    }
    if (items?.children?.length) {
      items.children = sortedObjects;
    }
    return items;
  }

  function dragStop(args) {
    let draggedParentNodeId = args?.draggedNodeData?.parentID;
    let droppedParentNodeId = args?.droppedNodeData?.parentID;
    let dropLevel = args?.dropLevel;
    let draggedNodeLevel = args?.draggedNode?.ariaLevel;
    if (dropLevel != draggedNodeLevel) {
      args.cancel = true;
      args.dropIndicator = "e-no-drop";
      dispatch(actionError("cannot move a node across different levels"));
    } else if (draggedParentNodeId != droppedParentNodeId) {
      args.cancel = true;
      args.dropIndicator = "e-no-drop";
      dispatch(actionError("cannot move a node across different levels"));
    }
  }
  function dataSourceChanged(args) {
    let newArray = flat(args.data);
    props.saveUpdatedTaxonomy(props?.flat ? args.data : newArray);
  }

  function nodeChecked(args) {
    let checkedNodes = treeObjRef.current.getAllCheckedNodes();
    let checkedObjects = [];
    checkedNodes.map((item) => {
      checkedObjects.push(...treeObjRef.current.getTreeData(item));
    });

    props.onClick(checkedObjects, args);
  }

  function onCreate(data = undefined) {
    let newSortedData = sortTree(data ? data : treeObjRef.current.getTreeData());
    let modifiedArray = newSortedData.sort((a, b) => {
      return a.taxonomySortOrder - b.taxonomySortOrder;
    });
    // reloadTree(modifiedArray)
    if (props.flat) {
      let treeOldData = JSON.parse(JSON.stringify(treeObjRef.current.getTreeData()));
      let newArray = treeOldData.map((element, i) => {
        element.taxonomySortOrder = element.taxonomySortOrder;
        if (element?.children?.length > 0) {
          let newSortChild = (element["children"] = element.children.sort((a, b) => {
            return a.label.localeCompare(b.label);
          }));
          if (props.sort === "DESC") {
            newSortChild.reverse();
          }
          element.children = newSortChild.map((v, i) => {
            v.taxonomySortOrder = i + 1;
            return v;
          });
        }
        return element;
      });
      props.saveUpdatedTaxonomy(newArray);
    } else {
      let newArray = flat(modifiedArray);
      props.saveUpdatedTaxonomy(newArray);
    }
  }
  useEffect(() => {
    if (props.treeCollapse) {
      treeObjRef.current.collapseAll();
    } else {
      treeObjRef.current.expandAll();
    }
  }, [props.treeCollapse]);

  useEffect(() => {
    if (props.sort != "CUSTOM" && props.changeOrder) {
      onCreate();
    }
  }, [props.sort]);

  useEffect(() => {
    // if (!search) {
    treeObjRef.current.fields = {
      dataSource: props.treeData,
      id: "id",
      text: "label",
      child: "children",
      iconCss: "icon",
    };
    if (maskRefObj?.current?.element?.value) searchNodes();
    // }
  }, [props.treeData]);

  // for search
  function changeDataSource(data, bool, noChild) {
    if (!data.length) {
      setShowMessage(true);
      treeObjRef.current.fields = { dataSource: [], id: "id", text: "label", child: "children" };
    } else if (!bool) {
      setShowMessage(false);
      delete treeObjRef.current.fields.properties["hasChildren"];
      delete treeObjRef.current.fields.properties["parentID"];
      treeObjRef.current.fields = { dataSource: data, id: "id", text: "label", child: "children" };
    } else if (noChild) {
      setShowMessage(false);
      treeObjRef.current.fields = { dataSource: data, id: "id", text: "label" };
    } else {
      setShowMessage(false);
      treeObjRef.current.fields = {
        dataSource: data,
        id: "id",
        text: "label",
        parentID: parentId,
        hasChildren: "hasChild",
      };
    }
  }
  function searchNodes(args) {
    let _text = maskRefObj.current.element.value;
    let predicats = [],
      _array = [],
      _filter = [];
    if (_text == "") {
      changeDataSource(props.treeData, false);
      let proxy = treeObjRef;
      setTimeout(function () {
        proxy.current.collapseAll();
        setSearch(false);
        setSearchResult(false);
      }, 100);
    } else {
      setSearch(true);
      let flatData = flat(JSON.parse(JSON.stringify(props.treeData)));
      let predicate = new Predicate("label", "contains", _text, true);
      let filteredList = new DataManager(flatData).executeLocal(new Query().where(predicate));
      for (const element of filteredList) {
        _filter.push(element["id"]);
        let filters = getFilterItems(element, flatData);
        for (const element of filters) {
          if (_array.indexOf(element) == -1 && element != null) {
            _array.push(element);
            predicats.push(new Predicate("id", "equal", element, false));
          }
        }
      }
      if (predicats.length == 0) {
        changeDataSource([]);
        setSearchResult(true);
      } else {
        let query = new Query().where(Predicate.or(predicats));
        let newList = new DataManager(flatData).executeLocal(query);
        let newUpdatedList = newList.map((item) => {
          if (item.level == 2) {
            item["hasChild"] = true;
            delete item.children;
            delete item[parentId];
          }
          return item;
        });
        changeDataSource(newUpdatedList, true, props?.noChild);
        let proxy = treeObjRef;
        setTimeout(function () {
          proxy.current.expandAll();
        }, 100);
      }
    }
  }
  //Find the Parent Nodes for corresponding childs
  function getFilterItems(fList, list) {
    let nodes = [];
    nodes.push(fList["id"]);
    let query2 = new Query().where("id", "equal", fList[parentId], false);
    let fList1 = new DataManager(list).executeLocal(query2);
    if (fList1.length != 0) {
      let pNode = getFilterItems(fList1[0], list);
      for (let i = 0; i < pNode.length; i++) {
        if (nodes.indexOf(pNode[i]) == -1 && pNode[i] != null) nodes.push(pNode[i]);
      }
      return nodes;
    }
    return nodes;
  }
  const created = () => {
    maskRefObj && maskRefObj.current.addIcon("prepend", "e-icons e-search");
  };

  function nodeTemplate(data) {
    console.log("data", data);
    return (
      <div className="row px-2">
        <div>
          <input
            type="checkbox"
            onChange={() => {
              nodeChecked(data);
            }}
          />
        </div>
        <div className="ename">{data.label}</div>
        <div>
          <Hide />
        </div>
        <div>
          <Disable />
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "236px" }} className={`ml-4 mb-3 mt-2 ${props.filterByTaxonomy && props.customSearch}`}>
        <TextBoxComponent
          placeholder="Search"
          // floatLabelType="Always"
          ref={maskRefObj}
          input={searchNodes}
          cssClass="searchBox"
          created={created}
        />
      </div>
      <div className={props?.hieghtClass ? props?.hieghtClass : `custom-taxonomy-tree-container-wrapper`}>
        <TreeViewComponent
          // fields={fields}
          allowDragAndDrop={props.allowDragAndDrop}
          loadOnDemand={true}
          ref={treeObjRef}
          nodeDragStop={dragStop}
          // nodeClicked={onClick}
          dataSourceChanged={dataSourceChanged}
          showCheckBox={true}
          nodeChecked={nodeChecked}
          autoCheck={props?.autoCheck ? true : false}
          animation={{
            expand: { effect: "SlideDown", duration: 300, easing: "linear" },
            collapse: { effect: "SlideUp", duration: 300, easing: "linear" },
          }}
          cssClass={props.cssClass ? props.cssClass : "treeClass"}
          allowTextWrap={true}
          // nodeTemplate={nodeTemplate}

          // enablePersistence = {props?.enablePersistence ? props.enablePersistence : false}
          // checkedNodes ={props.checkedNodes}
          // enablePersistence = {true}
        />
        {showMessage && <div className="ml-4">No result found.Please try a diffrent search ...</div>}
      </div>
    </>
  );
};
export default TreevieW;
