/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  All Form elements will use in making form 
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 16/Mar/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/
import React, { useState, useEffect } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import Select from "react-select";
import CKEditor from "ckeditor4-react";
import { ckEditorConfig } from "../../../../config/ckEditorConfig";
import "./css/custom.scss";

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};
export const DatePicker = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${props.required}`}>
      <label>{label} </label>
      <DatePickerComponent
        cssClass="e-customSyncfusion e-outline"
        // floatLabelType="Auto"
        autoComplete="off"
        {...props.properties}
        value={value}
      />
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const TextField = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${props?.required || ""}`}>
      <label>{label} </label>
      <input
        className="form-control"
        // cssClass={'e-outline'}
        autoComplete="off"
        value={value}
        {...props.properties}
        {...props.formik.getFieldProps(name)}
        onBlur={(e) => {
          !props.properties.readOnly && props.handleBlur != undefined && props.handleBlur(e.target.value);
        }}
      />
      {
        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      }
    </div>
  );
};
export const Checkbox = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;

  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [props.formik.values, name, checked]);

  return (
    <div className={`form-group ${props.required}`}>
      <input
        // className="form-control"
        // cssClass={'e-outline'}
        autoComplete="off"
        {...props.properties}
        {...props.formik.getFieldProps(name)}
        checked={checked}
        onChange={(e) => {
          setChecked(!checked);
          props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
        }}
      />
      <label>&nbsp;{label} </label>
      {
        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      }
    </div>
  );
};
export const RadioGroup = (props) => {
  const { name, label } = props.properties;

  useEffect(() => {
    // props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 ? props.formik.setFieldValue(name, "Client User", true):
    // props.formik.setFieldValue(name, props.formik.values[name], true) ;
  }, [props.formik.values, name, props.formik.values.clientUsers]);

  return (
    <div className={`form-group ${props.required}`}>
      <label>Choose Audience From</label>
      <br />
      <div className="row">
        {props.optionArr &&
          props.optionArr.map((v, i) => {
            return (
              <>
                <div className="col-md-1">
                  <input
                    type="radio"
                    className="form-control radio"
                    checked={
                      props.formik.values[name] === v ||
                      (props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 && v === "Client User")
                    }
                    onClick={(e) => {
                      if (e.target.checked) {
                        const s = e.target.id.split("_");
                        props.formik.setFieldValue(name, props.optionArr[s[1]], true);
                      }
                    }}
                    id={`${name}_${i}`}
                    {...props.properties}
                  />
                </div>
                <span className="pt-1">{v} </span>
              </>
            );
          })}
      </div>
      {
        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      }
    </div>
  );
};

export const TextArea = (props) => {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(props.maxLength);
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
    props.formik.values[name] && setCount(props.maxLength - props.formik.values[name].length);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${props.required}`}>
      <label>{label} </label>
      <textarea
        className="form-control"
        autoComplete="off"
        value={value}
        {...props.properties}
        {...props.formik.getFieldProps(name)}
        onKeyUp={(e) => {
          document.getElementById(name + "_").innerHTML = props.maxLength - e.target.value.length;
        }}
      />
      <b style={{ fontWeight: 500, display: props.showCharCount ? "" : "none" }} htmlFor="objective">
        Characters left:&nbsp;
      </b>
      <span id={name + "_"}>{count}</span>
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};

export const Dropdown = (props) => {
  const [value, setValue] = useState("");
  const [arr, setFormik] = useState(props.formik.values);
  const { name, label } = props.properties;

  const onChange = (selectedOption) => {
    let changedData = {};
    if (selectedOption) {
      changedData[name] = selectedOption;
      setFormik({ ...arr, ...changedData });
      props.formik.setFieldValue(name, selectedOption, true);
      setValue(selectedOption);
    }
  };
  useEffect(() => {
    if (props.searchFunc) props.searchFunc();
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${props?.required || ""}`}>
      <label>{label} </label>
      <Select
        // className="form-control"
        {...props.formik.getFieldProps(name)}
        value={value}
        styles={{
          // ...styles,
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#80bdff !important" : "#ced4da !important",
            boxShadow: state.isFocused && "inset 1px 1px #80bdff, inset -1px 0 #80bdff, inset 0 -1px #80bdff !important",
            "&:hover": {
              borderColor: "#80bdff !important",
            },
            // borderColor: state.isFocused ? '#e3165b !important' : '#ced4da !important',
            // boxShadow: state.isFocused && 'inset 1px 1px #e3165b, inset -1px 0 #e3165b, inset 0 -1px #e3165b !important',
            // '&:hover': {
            // 	borderColor: '#e3165b !important'
            // }
          }),
        }}
        autoComplete="off"
        {...props.properties}
        maxMenuHeight={310}
        onChange={onChange}
        // isMulti
      />
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};

export const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;
  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [name, props.formik.values]);

  return (
    <div className={` form-group ${(props.required && props.required) || ""}`}>
      <div className="mt-1 row">
        <div className="cy-savetemplate_button col-md-2 pl-1">
          <label className="custom-switch3">
            <input
              type="checkbox"
              {...props.properties}
              checked={checked ? true : false}
              onChange={(e) => {
                setChecked(e.target.checked);
                props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
              }}
            />
            <div>
              <span className="on">Yes</span>
              <span className="off">No</span>
            </div>
            <i></i>
          </label>
        </div>
        <div className="col-md-10 mt-1 pl-3">{label}</div>
      </div>

      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};

export const ToggleSwitchNew = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;
  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [name, props.formik.values]);

  return (
    <div className={` form-group ${(props.required && props.required) || ""}`}>
      <div className="mt-1 row">
        <div className="mt-1 pl-3">{label}</div>
        <div className="cy-savetemplate_button pl-1">
          <label className="custom-switch3">
            <input
              type="checkbox"
              {...props.properties}
              checked={checked ? true : false}
              onChange={(e) => {
                setChecked(e.target.checked);
                props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
              }}
            />
            <div>
              <span className="on">Yes</span>
              <span className="off">No</span>
            </div>
            <i></i>
          </label>
        </div>
      </div>

      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};

// Form Input field component for CKEditor
export const CkEditor = (props) => {
  const [values, setFormik] = useState(props.formik.values);
  const [content, setContent] = useState("");
  const { name, label } = props.properties;
  const { defaultContent = undefined } = props;
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[name] = newContent;
    setFormik({ ...values, ...changedData });
    props.formik.setFieldValue(name, newContent, true);
  };

  useEffect(() => {
    setContent(props.formik.values[name].length ? props.formik.values[name] : "");
  }, [props.formik.values, name, defaultContent]);

  return (
    <div className={`form-group ${props.required}`}>
      <label>{label} </label>
      {content.length > 0 && (
        <CKEditor activeClass="editor" data={content} onChange={onChange} config={{ ...ckEditorConfig }} {...props.properties} />
      )}
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};
