/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  News overview - Add | Edit Overview
   1. Import all news overview element from customFormFields
   2 Use Formik for validation
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 20/Aug/2020
  Author						: Aditya Tijare
================================================================
*/

import React, { useState, useEffect, useCallback, useRef } from "react";
import { getProductVariant, getDateFromMongoDate } from "../../../../../../utilities";
import deliverableImage from "../../../../../../assets/images/dashboardDeliverableImage.png";
import { useDropzone } from "react-dropzone";
import ReactTooltip from "react-tooltip";
import {
  fetchDeliverableDocument,
  fetchDeliverables,
  uploadPowerbiFile,
  downloadPowerbiFile,
  removePowerbiFile,
  fetchSingleDeliverable,
  fetchDeliverableImageUrl,
} from "../../../../../../middleware/services/deliverablesApi";
import { getContentType, getObjectiveSgfTag, fetchTaxonomyTrend } from "../../../../../../middleware/services/cmsApi";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import {
  TextField,
  Dropdown,
  DatePicker,
  ToggleSwitchNew,
  MultiSelect,
  AsyncMultiSelect,
  ReactSelectMultiSelect,
} from "../../../../../components/CustomFormElements/syncFusionFormFields";
import CustomTooltip from "../../../../../components/tooltip";
import { fetchNews } from "../../../../../../middleware/services/newsApi";
import { fetchCompany } from "../../../../../../middleware/services/companyApi";
import { fetchProductBasic } from "../../../../../../middleware/services/productApi";
import "./style.css";
import { showLoader, hideLoader, actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { PRODUCT_VARIANT, CONTENT_TYPE, DELIVERABLE_TYPE } from "../../../../../../constants";
import SideBar from "./sideBar";
// import { ReactComponent as AddCircle } from "../../../../../../assets/images/addCircle.svg";
import { ReactComponent as PowerBiImage } from "../../../../../../assets/images/powerbi.svg";
import { ReactComponent as DownloadPowerBi } from "../../../../../../assets/images/download-icon.svg";
import { DELIVERABLE_THUMB_IMG_SIZE } from "config";
import * as moment from "moment";
import { MdDeleteForever } from "react-icons/md";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import SidebarModal from "../../../../../components/sidebar-modal";
import CommonDateTagSection from "../../../../../components/CommonDateTagSection/CommonDateTagSection";
import Select, { components, MultiValueRemoveProps, SelectInstance } from "react-select";
import parse from "react-html-parser";
import CommonDate from "app/components/CommonDate/CommonDate";
import CommonTag from "app/components/CommonTag/CommonTag";

const OverviewForm = (props) => {
  const dispatch = useDispatch();
  let searchBoxRef = useRef();
  const {
    formik,
    formikRelatedContentExternal,
    deliverableDefaultImageRef,
    externalDefaultImageRef,
    deliverableImageSrc,
    mostReadResearch,
    setMostReadResearch,
    addToWidgets,
    setAddToWidgets,
    imageError,
    setImageError,
    setPopularReport,
    popularReport,
    comingSoon,
    setComingSoon,
    deliverableSignalRelatedContentImageSrc,
    setDeliverableSignalRelatedContentImageSrc,
    selectedRelatedContentReportId,
    setSelectedRelatedContentReportId,
    tabRef,
    selectedFileExternal,
    setSelectedFileExternal,
    signalIndicatorColor,
    setSignalIndicatorColor,
    relatedContentSaved,
    setRelatedContentSaved,
    deliverableSignalContent,
    setDeliverableSignalContent,
    deliverableSignalRelatedContentImageSrcExternal,
    setDeliverableSignalRelatedContentImageSrcExternal,
    existingRelatedContentType,
    setExistingRelatedContentType,
    selectedRelatedContentReportIdExisting,
  } = props;
  const deliverableImageRef = useRef(null);
  const externalLinkImageRef = useRef(null);
  const externalLinkImageRefSideBar = useRef(null);
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const productVariant = getProductVariant();
  const [showTrash, setShowTrash] = useState(false);
  const [showTrashSignal, setShowTrashSignal] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [showSideBar, setShowSideBar] = useState(false);
  const [insiderDeliverableDataType, setInsiderDeliverableDataType] = useState([]);
  const [initialInsiderDeliverableContentType, setInitialInsiderDeliverableContentType] = useState([]);
  const [insiderDeliverableContentType, setInsiderDeliverableContentType] = useState([]);
  const [toggleDeliverableDropdown, setToggleDeliverableDropdown] = useState(false);
  const [existingDeliverableTypes, setExistingDeliverableTypes] = useState(false);
  const [resetContentTypeFlag, setResetContentTypeFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isContentTypeSignal, setIsContentTypeSignal] = useState(false);
  const [openRelatedContentReportSideBar, setOpenRelatedContentReportSideBar] = useState(false);
  const [openRelatedContentExternalSideBar, setOpenRelatedContentExternalSideBar] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(undefined);
  const [sgfTrendData, setSgfTrendData] = useState([]);
  const [sgfName, setSgfName] = useState(undefined);
  const [trends, setTrends] = useState([]);
  const [selectedTrend, setSelectedTrend] = useState(undefined);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [reportsSort, setReportsSort] = useState("Newest");
  const [widgetIndustryId, setWidgetIndustryId] = useState("");
  const [sideBarContentTypeFilter, setSideBarContentTypeFilter] = useState("");
  const [sideBarDeliverableTypeFilter, setSideBarDeliverableTypeFilter] = useState("");
  const [sideBarIndustryFilter, setSideBarIndustryFilter] = useState("");
  const [sideBarSGFFilter, setSideBarSGFFilter] = useState("");
  const [sideBarTrendFilter, setSideBarTrendFilter] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [searchText, setSearchText] = useState("");

  const relatedContentReportCount = reportList
    ? reportList.filter((item) => item.title.toLowerCase()?.includes(searchTextFilter.toLowerCase())).length
    : 0;

  const options = [
    { value: "#DBDBDD", label: "#DBDBDD" },
    { value: "#FEDCDF", label: "#FEDCDF" },
    { value: "#EBE4F6", label: "#EBE4F6" },
    { value: "#FEF5D9", label: "#FEF5D9" },
    { value: "#D3EFE8", label: "#D3EFE8" },
    { value: "#DAF1FA", label: "#DAF1FA" },
    { value: "#D8DAEF", label: "#D8DAEF" },
  ];

  const deliverableWhatNextPlatform = [
    {
      label: "WhatNext V1",
      value: "WhatNext V1",
    },
    {
      label: "WhatNext V2",
      value: "WhatNext V2",
    },
  ];

  const deliverableInsiderPlatform = [
    {
      label: "Insider V1",
      value: "Insider V1",
    },
    {
      label: "Insider V2",
      value: "Insider V2",
    },
  ];

  const deliverableOrientation = [
    {
      label: "Potrait",
      value: "Potrait",
    },
    {
      label: "Landscape",
      value: "Landscape",
    },
  ];
  const whatNextDeliverableDataType = [
    {
      label: "WhatNext Perspective",
      value: "WhatNext Perspective",
    },
    {
      label: "Client Business Objective",
      value: "Client Business Objective",
    },
    {
      label: "Quarterly Report",
      value: "Quarterly Report",
    },
  ];
  const CREATE_FORM_OPTIONS = [
    {
      label: "Choose a template option",
      value: "Choose a template option",
    },
    {
      label: "Start with blank template",
      value: "blank",
    },
    {
      label: "Choose from existing templates",
      value: "existing template",
    },
  ];
  const relatedContentType = [
    {
      label: "Report",
      value: "Report",
    },
    {
      label: "External Link",
      value: "External Link",
    },
  ];
  const sortDataSource = [
    { text: "Newest", value: "Newest" },
    { text: "Oldest", value: "Oldest" },
  ];

  const formattedColorLabel = useCallback(
    (label) => {
      return <p className="text-sm">{parse(label)}</p>;
    },
    [options]
  );

  const ColorOption = useCallback(
    (props) => {
      return (
        <components.Option {...props}>
          <div
            style={{
              backgroundColor: props.data.value,
              width: "100%",
              height: "30px",
              border: "1px solid #e5e5e5",
            }}
            className="color-options"
          ></div>
        </components.Option>
      );
    },
    [options]
  );

  const ColorValue = useCallback(
    (props) => {
      return (
        <components.SingleValue {...props}>
          <div
            style={{
              backgroundColor: props.data.value,
              width: "120px",
              height: "30px",
            }}
          ></div>
        </components.SingleValue>
      );
    },
    [options]
  );

  const searchNews = async (value) => {
    let resData = [];
    if (value.length > 2) {
      let filters = [[`productVariant~productName:${productVariant},isLive`, "eq", "YES"]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["newsTitle", "stw", value]);
      let fields = { fields: ["newsID", "newsTitle"] };
      const response = await fetchNews({ filters: filters, ...fields });
      if (response?.data && response?.data?.data) {
        resData =
          response.data.data.map((v) => {
            return { label: v.newsTitle, value: v.id };
          }) || [];
      }
    }
    return resData;
  };

  const fetchContentType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "InsiderV2"]);
    const response = await getContentType({ filters: filters });
    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        if (v.contentType !== CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES && v.contentType !== CONTENT_TYPE.DEVELOPMENTS_NEWS) {
          return { label: v.contentType, value: v.contentType };
        }
      });
      if (resData) {
        let data = resData.filter((item) => typeof item == "object");
        data.sort((a, b) => a.value.localeCompare(b.value));
        setInitialInsiderDeliverableContentType(data);
        setInsiderDeliverableContentType(data);
        if (props.deliverableId) {
          setResetContentTypeFlag(true);
        }
      } else {
        setInsiderDeliverableContentType([]);
      }
    }
  };

  useEffect(() => {
    if (selectedRelatedContentReportIdExisting) {
      const response = fetchSingleDeliverable(selectedRelatedContentReportIdExisting);
      response.then(async (response) => {
        if (response.data.data.deliverableProfileImage && response.data.data.deliverableProfileImage !== "") {
          setDeliverableSignalRelatedContentImageSrc(fetchDeliverableImageUrl(response.data.data.deliverableProfileImage));
        }
      });
    }
  }, [selectedRelatedContentReportIdExisting]);

  useEffect(() => {
    if (formik.values.deliverableProductPlatform?.length > 0) {
      if (formik.values.deliverableProductPlatform.includes("Insider V2") && !formik.values.deliverableProductPlatform.includes("Insider V1")) {
        setInsiderDeliverableContentType(initialInsiderDeliverableContentType);
      } else {
        setInsiderDeliverableContentType(
          initialInsiderDeliverableContentType?.filter((item) => item && item?.value !== CONTENT_TYPE.DASHBOARD) || []
        );
        if (formik.values?.deliverableContentType === CONTENT_TYPE.DASHBOARD) {
          formik.setFieldValue("deliverableContentType", "");
        }
        if (resetContentTypeFlag) {
          setResetContentTypeFlag(false);
        }
      }
    } else {
      setInsiderDeliverableContentType(initialInsiderDeliverableContentType?.filter((item) => item && item?.value !== CONTENT_TYPE.DASHBOARD) || []);
    }
  }, [formik.values.deliverableProductPlatform?.length, resetContentTypeFlag]);

  useEffect(() => {
    if (formik?.values?.deliverableSignalRelatedContentType === "Report" && formik?.values?.deliverableSignalRelatedContentReportId) {
      const response = fetchSingleDeliverable(formik?.values?.deliverableSignalRelatedContentReportId);
      response.then(async (response) => {
        if (response.data.data.deliverableProfileImage && response.data.data.deliverableProfileImage !== "") {
          setDeliverableSignalRelatedContentImageSrc(fetchDeliverableImageUrl(response.data.data.deliverableProfileImage));
        }
        setRelatedContentSaved(true);
      });
    }
    if (formik?.values?.deliverableSignalRelatedContentType === "External Link" && deliverableSignalRelatedContentImageSrcExternal) {
      setRelatedContentSaved(true);
    }
  }, [formik?.values?.deliverableSignalRelatedContentType, formik?.values?.deliverableSignalRelatedContentReportId]);

  useEffect(() => {
    if (formik?.values?.deliverableContentType === CONTENT_TYPE.SIGNALS && formik?.values?.deliverableType === DELIVERABLE_TYPE.SIGNALS) {
      setIsContentTypeSignal(true);
    } else {
      setIsContentTypeSignal(false);
    }
  }, [formik.values.deliverableContentType, formik.values.deliverableType, formik.values.deliverableProductPlatform?.length]);

  useEffect(() => {
    if (existingRelatedContentType !== "") {
      if (existingRelatedContentType === formik.values.deliverableSignalRelatedContentType) setRelatedContentSaved(true);
      else setRelatedContentSaved(false);
    }
  }, [formik.values.deliverableSignalRelatedContentType]);

  const fetchDeliverableType = async () => {
    let filters = [];
    let deliverableTypes = [];
    filters.push(["contentTypeProductVariant", "eq", "Insider"]);
    const response = await getContentType({ filters: filters });

    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        if (v.contentType !== CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES && v.contentType !== CONTENT_TYPE.DEVELOPMENTS_NEWS) {
          deliverableTypes.push(v.contentType);
          return { label: v.contentType, value: v.contentType };
        }
      });
      if (resData) {
        let data = resData.filter((item) => typeof item == "object");
        data.sort((a, b) => a.value.localeCompare(b.value));
        setInsiderDeliverableDataType(data);
        setExistingDeliverableTypes(deliverableTypes);
      } else {
        setInsiderDeliverableDataType([]);
      }
    }
  };

  const searchCompany = async (value) => {
    let resData = [];
    if (value.length > 2) {
      let filters = [[`productVariant~productName:${productVariant},isLive`, "eq", "YES"]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "stw", value]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = await fetchCompany({
        filters: filters,
        ...fields,
        sort: "companyName:asc",
        limit: 10,
      });
      if (response?.data && response?.data?.data) {
        resData =
          response.data.data.map((v) => {
            return { label: v.companyName, value: v.id };
          }) || [];
      }
    }
    return resData;
  };

  const searchProducts = async (value) => {
    let resData = [];
    if (value.length > 2) {
      let filters = [[`productVariant~productName:${productVariant},isLive`, "eq", "YES"]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["productName", "stw", value]);
      let fields = { fields: ["productId", "productName"] };
      const response = await fetchProductBasic({ filters: filters, ...fields });
      if (response?.data && response?.data?.data) {
        resData =
          response.data.data.map((productData) => {
            return { label: productData.productName, value: productData.id };
          }) || [];
      }
    }
    return resData;
  };

  const path = `/deliverable/${props.deliverableId}/edit#taxonomy`;
  if (document.querySelectorAll(`[href="${path}"]`)[0]) {
    if (formik.values.deliverableType.value == DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE) {
      document.querySelectorAll(`[href="${path}"]`)[0].style.display = "none";
    } else {
      document.querySelectorAll(`[href="${path}"]`)[0].style.display = "block";
    }
  }
  const style = {
    label: {
      fontWeight: 600,
    },
  };
  const fetchTemplates = async () => {
    let params = {};
    let defaultFilter = [];
    let filters = [["deliverableIsTemplate", "eq", "YES"]];
    if (props.deliverableId) filters.push(["id", "neq", props.deliverableId]);
    let fields = ["deliverableName"];
    params.fields = fields;
    let newData = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...filters, ...defaultFilter];
    await fetchDeliverables(params).then((res) => {
      // newData.push({ label: "Select Deliverable Template", value: "" });
      res &&
        res.data.data.map((d) => {
          return newData.push({ label: d.deliverableName, value: d.id });
        });
      setTemplateData(newData);
    });
  };

  const saveReportRelatedContent = async () => {
    if (formik?.values?.deliverableSignalRelatedContentType === "Report") {
      const response = fetchSingleDeliverable(selectedRelatedContentReportId);
      response.then(async (response) => {
        if (response.data.data.deliverableProfileImage && response.data.data.deliverableProfileImage !== "") {
          setDeliverableSignalRelatedContentImageSrc(fetchDeliverableImageUrl(response.data.data.deliverableProfileImage));
        }
        setDeliverableSignalContent({
          deliverableSignalName: response?.data?.data?.deliverableName,
          deliverableSignalContent: response?.data?.data?.deliverableContentType,
          deliverableSignalTags: response?.data?.data?.deliverableIndustryTrendsTags,
          deliverableSignalReadTime: response?.data?.data?.deliverableReadTime,
          deliverableSignalDate: response?.data?.data?.deliverablePublishedDate,
        });
        setRelatedContentSaved(true);
        setOpenRelatedContentReportSideBar(false);
      });
    } else if (formik?.values?.deliverableSignalRelatedContentType === "External Link") {
      setDeliverableSignalContent({
        deliverableSignalName: formikRelatedContentExternal?.values?.deliverableSignalRelatedContentTitle,
        deliverableSignalContent: "",
        deliverableSignalTags: [],
        deliverableSignalReadTime: "",
        deliverableSignalDate: formikRelatedContentExternal?.values?.deliverableSignalRelatedContentPublishedDate,
      });

      formikRelatedContentExternal.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });

          formikRelatedContentExternal.setFormikState({ ...formikRelatedContentExternal, touched: touched, errors: res });
        } else {
          setOpenRelatedContentExternalSideBar(false);
          setRelatedContentSaved(true);
        }
      });
    }
  };

  const fetchSgf = (selectedIndustry) => {
    dispatch(showLoader());
    getObjectiveSgfTag({ filters: [["sector.sectorId", "eq", selectedIndustry]], sort: "trendName:asc", limit: 2000 })
      .then((res) => {
        setSgfTrendData(
          res.data.data.filter((item) => {
            if (item.sgfName !== "Others") return item;
          })
        );
        setSgfName(res.data.data[0].id);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [100]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log("err fetchTrend :: ", err);
      });
  };

  /**
   * @description fetch taxonomy trend by selected trendSubRoot
   * @param {String} trendSubRoot
   */
  const fetchTaxonomy = (trendSubRoot) => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    if (trendSubRoot) {
      fetchTaxonomyTrend({
        fields: [
          "id",
          "trendName",
          "trendParentId",
          "trendParentName",
          "trendSortOrder",
          "trendLevel",
          "trendDisplayName",
          "trendIsHidden",
          "trendIsDisabled",
          "trendIsSignal",
        ],
        filters: [["trendSubRoot", "eq", trendSubRoot]],
        sort: "trendSortOrder:asc",
        limit: 2000,
      })
        .then((res) => {
          dispatch(hideLoader());
          let { data = [] } = res.data;
          if (data.length) {
            const treeJosnArray = buildTreeJson(data, trendSubRoot);
            setTaxonomy(treeJosnArray);
            setTreeCollapse(true);
          }
        })
        .catch((err) => {
          console.log("err fetchTrend :: ", err);
          let errMsg = err?.data?.message ? err?.data?.message : "Trends Data Fetch Failed";
          dispatch(actionError(errMsg));
        });
    }
  };

  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.trendName;
      element["showCheckbox"] = false;
      element["taxonomySortOrder"] = element.trendSortOrder;
      element["level"] = element.trendLevel;
      if (element?.trendIsHidden === "YES") {
        element["icon"] = "hidden";
      }
      if (element?.trendIsDisabled === "YES") {
        element["icon"] = "disabled";
      }
      if (element?.trendIsDisabled === "YES" && element?.trendIsHidden === "YES") {
        element["icon"] = "hidden-disabled";
      }
      if (element["trendParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  const fetchAllRelatedReports = async () => {
    try {
      dispatch(showLoader());
      let finalList = [];

      const promises = [];

      const deliverableParams = {};
      deliverableParams.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["deliverableType", "nin", [DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE]],
        ["deliverable_industry_trends_tags.trend_id", "in", [sgfName, selectedTrend || trends[0]?.trendId]],
      ];
      if (sideBarContentTypeFilter != "") deliverableParams.filters.push(["deliverableContentType", "eq", sideBarContentTypeFilter]);
      if (sideBarDeliverableTypeFilter != "") deliverableParams.filters.push(["deliverableType", "eq", sideBarDeliverableTypeFilter]);

      deliverableParams.limit = 10000;

      promises.push(
        fetchDeliverables(deliverableParams).then((res) => {
          return res.data.data.map((ele) => getDeliverableModifiedObj(ele));
        })
      );

      // get all content data
      finalList = await Promise.all(promises);

      finalList = finalList.flat();

      finalList.sort(function (a, b) {
        const c = new Date(a.date === undefined ? new Date() : a.date);
        const d = new Date(b.date === undefined ? new Date() : b.date);
        return d - c;
      });

      setReportList(finalList);
      dispatch(hideLoader());
    } catch (error) {
      console.log("error:", error);
    }
  };

  const getDeliverableModifiedObj = (ele) => {
    const productVariant = ele.productVariant.filter((x) => x.productName === "Insider");
    let firstPublishedDate = productVariant.length ? productVariant[0].firstPublishedDate : "";
    let visitedUser = ele.deliverableVisitedUser ? ele.deliverableVisitedUser : [];
    let documents = [];
    if (ele.deliverableDocuments?.length > 0) {
      documents = ele.deliverableDocuments;
    }

    return {
      id: ele.id,
      title: ele.deliverableName,
      industryTag: getIndustryTag(ele.deliverableIndustryTrendsTags),
      date: firstPublishedDate,
      infoText: ele.deliverableSummary,
      profileImage: ele.deliverableProfileImage,
      subType: ele.deliverableType,
      label: ele.deliverableContentType,
      entityType: "document",
      readTime: ele.deliverableReadTime,
      documents: documents,
      visitedUser,
    };
  };

  const getIndustryTag = (tags) => {
    const sortOrderExists = tags.some((obj) => obj.hasOwnProperty("trendSortOrder"));
    if (sortOrderExists) {
      return tags
        .filter((obj) => obj.hasOwnProperty("trendSortOrder"))
        .sort((a, b) => a.trendSortOrder - b.trendSortOrder)
        .map((ele) =>
          Object.assign({}, ele, {
            trendId: ele.trendParentId ? ele.trendParentId : ele.trendId,
          })
        );
    } else {
      return (
        tags
          .filter(
            // (tag) => tag.trendParentId !== 0 && tag.trendRoot === tag.trendParentId
            (tag) => tag.trendParentId !== 0 && tag.trendIsSgf !== 1 && tag.trendSgfParentId === undefined
          )
          // .slice(0, 3)
          .map((ele) =>
            Object.assign({}, ele, {
              trendId: ele.trendParentId ? ele.trendParentId : ele.trendId,
            })
          )
      );
    }
  };

  /**
   * @description fetch taxonomy trend industry
   */
  const fetchIndustry = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        const setIndustryTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setIndustryTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, industryName: sTT.trendParentName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
        fetchSgf(Object.values(industryDropdownValues)[0].id);
      })
      .catch((err) => {
        console.log("err fetchTechnology :: ", err);
        let errMsg = err?.data?.message ? err?.data?.message : "Industry Fetch Failed";
        dispatch(actionError(errMsg));
      });
  };

  const searchSidebarReports = (index) => {
    let text = searchBoxRef.current.element.value;
    setSearchText(text);
  };

  const handleResetSidebarReports = () => {
    searchBoxRef.current.element.value = "";
    setSearchTextFilter("");
  };

  useEffect(() => {
    if (sgfTrendData[0]?.trend?.[0]?.trendId) {
      setTrends(sgfTrendData[0].trend);
      setSelectedTrend(sgfTrendData[0].trend[0].trendId);
    }
  }, [sgfTrendData?.length]);

  useEffect(() => {
    fetchTemplates();
    //setDeliverableImageSrc(props.deliverableImage);
    if (props.deliverableImage) {
      setIsDefaultImage(false);
      setShowTrash(true);
    }
    isDefaultImage && props.deliverableImage === undefined ? setImageError("Please upload image") : setImageError("");
  }, [props.deliverableImage, isDefaultImage]);

  useEffect(() => {
    setImageError("");
    fetchDeliverableType();
    fetchContentType();
    fetchIndustry();
  }, []);

  useEffect(() => {
    if (toggleDeliverableDropdown) {
      fetchDeliverableType();
    }
    setToggleDeliverableDropdown(false);
  }, [toggleDeliverableDropdown]);

  /*
    @Description : Set news data to fields
  */

  useEffect(() => {
    formik.setValues(props.deliverableInformation);
    setMostReadResearch(props.deliverableInformation.deliverableIsMostResearch === "YES" ? true : false);
    setAddToWidgets(props.deliverableInformation.deliverableAddToWidgets === "YES" ? true : false);
    setPopularReport(props.deliverableInformation.deliverableIsPopularReport === "YES" ? true : false);
    setComingSoon(props.deliverableInformation.deliverableIsComingSoon === "YES" ? true : false);
  }, [props.deliverableInformation]);

  /*
    @Description : Read news image
  */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      deliverableImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  /*
    @Description : Read external link image
  */
  const readExternalLinkImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      externalLinkImageRefSideBar.current.setAttribute("src", e.target.result);
      // externalLinkImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: props.formik.values.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE ? ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx" : ".pdf",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        props.formik.setValues({
          ...props.formik.values,
          deliverableDocuments: acceptedFiles,
        });
      },
    });

    return (
      <>
        <div className="form-group bg-light p-3 border-dotted text-left mb-1 mt-1">
          <div {...getRootProps()} className="d-inline-block">
            <input {...getInputProps()} id="deliverable-document" />
            <button type="button" className="btn btn-dark">
              Upload Document
            </button>
          </div>

          <div className="d-inline-block pl-4">
            {props.formik.values.deliverableDocuments && props.formik.values.deliverableDocuments.length
              ? props.formik.values.deliverableDocuments.map((file, i) => (
                  <li key={i} className="truncate mr-2">
                    {/* <span className="font-weight-bold">File:  &nbsp;</span> */}
                    {`${file.name || file.docName || ""}`}
                  </li>
                ))
              : null}
            {props.formik.values.deliverableDocuments && props.formik.values.deliverableDocuments[0]?.docFileName ? (
              <>
                <a
                  href={fetchDeliverableDocument(
                    props.deliverableId,
                    props.formik.values.deliverableDocuments[0].docFileName,
                    props.formik.values.deliverableDocuments[0].docName
                  )}
                  download={props.formik.values.deliverableDocuments[0].docName}
                >
                  <i className="fas fa-download mr-2" data-tip="Download" download={props.formik.values.deliverableDocuments[0].docName} />
                </a>
                <ReactTooltip />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <small>
          <span className="font-weight-bold">Note : </span>
          <li>
            {productVariant === "WhatNext"
              ? "Only pdf files are accepted for WhatNex Perspective deliverable type except Business Objective Report"
              : "Only pdf files are accepted for all deliverable type except Business Objective Report."}
          </li>
          <li> Only pdf, doc/docx, xls/xlsx, ppt/pptx files are allowed to upload for Business Objective Report.</li>
          <li>You can either upload a document or create a deliverable using the content editor.</li>
          <li>Max file size allowed is 40MB.</li>
        </small>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched.deliverableDocuments === true ? "block" : "none",
          }}
          role="alert"
        >
          {/* <small>
            {props.formik.touched.deliverableDocuments === true
              ? props.formik.errors.deliverableDocuments || ""
              : ""}
          </small> */}
        </div>
      </>
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const isValid = validateFile(file);
      if (isValid) {
        setSelectedFile(file);
      }
    }
  };

  const clearPowerBiFileInput = () => {
    const fileInput = document.getElementById("powerbiFileInput");
    fileInput.value = ""; // Clear the selected file
    setSelectedFile(null);
  };

  const handleRemoveUploadedFile = async (event) => {
    try {
      event.preventDefault();
      formik.setFieldValue("deliverableOldPowerbiReportId", formik.values.deliverablePowerbiReportId);
      formik.setFieldValue("deliverableOldPowerbiReportName", formik.values.deliverablePowerbiReportName);
      formik.setFieldValue("deliverablePowerbiReportId", "");
      formik.setFieldValue("deliverablePowerbiOriginalReportName", "");
      formik.setFieldValue("deliverablePowerbiReportName", "");
      formik.setFieldValue("deliverablePowerbiReportUploadedDate", "");
      clearPowerBiFileInput();
      dispatch(actionSuccess("File removed successfully"));
    } catch (error) {
      dispatch(actionError(error?.message));
    }
  };

  const handleUpload = async (event) => {
    try {
      event.preventDefault();
      if (selectedFile) {
        let fileFormData = new FormData();
        fileFormData.append("deliverableId", props.deliverableId);
        fileFormData.append("deliverablePowerbiReportId", formik.values.deliverablePowerbiReportId);
        fileFormData.append("deliverablePowerbiReportName", formik.values.deliverablePowerbiReportName);
        fileFormData.append("file", selectedFile);
        dispatch(showLoader());
        const uploadResponse = await uploadPowerbiFile(fileFormData);
        dispatch(hideLoader());
        if (uploadResponse && uploadResponse.data?.data && uploadResponse.data.data.reports && uploadResponse.data.data.reports.length > 0) {
          dispatch(actionSuccess("File uploaded successfully"));
          if (formik.values.deliverableContentStatus !== "Published") {
            formik.setFieldValue(
              "deliverableOldPowerbiReportId",
              formik.values.deliverableOldPowerbiReportId || formik.values.deliverablePowerbiReportId
            );
            formik.setFieldValue(
              "deliverableOldPowerbiReportName",
              formik.values.deliverableOldPowerbiReportName || formik.values.deliverablePowerbiReportName
            );
          } else {
            formik.setFieldValue("deliverableOldPowerbiReportId", formik.values.deliverablePowerbiReportId);
            formik.setFieldValue("deliverableOldPowerbiReportName", formik.values.deliverablePowerbiReportName);
          }
          formik.setFieldValue("deliverablePowerbiReportId", uploadResponse.data.data?.reports[0]?.id);
          formik.setFieldValue("deliverablePowerbiOriginalReportName", uploadResponse.data.data?.reports[0]?.name);
          formik.setFieldValue("deliverablePowerbiReportName", uploadResponse.data.data?.fileName);
          formik.setFieldValue("deliverablePowerbiReportUploadedDate", uploadResponse.data.data?.createdDateTime);
          clearPowerBiFileInput();
        } else {
          dispatch(actionError("File upload unsuccessful. Please try again later"));
        }
      }
    } catch (error) {
      dispatch(actionError(error?.message));
    }
  };

  const handleDownload = async (event) => {
    try {
      event.preventDefault();
      const sa = await downloadPowerbiFile(formik.values.deliverablePowerbiReportName, formik.values.deliverablePowerbiOriginalReportName);
      let file = new File([sa.data], formik.values.deliverablePowerbiOriginalReportName, {
        type: "pbix",
      });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = file.name + ".pbix";
      link.click();
    } catch (error) {
      dispatch(actionError(error?.message));
    }
  };

  const validateFile = (file) => {
    if (file.size > 50 * 1024 * 1024) {
      dispatch(actionError("File size exceeds 50MB."));
      return false;
    }
    if (!file.name.endsWith(".pbix")) {
      dispatch(actionError("Invalid file format. Please select a .pbix file."));
      return false;
    }
    return true;
  };

  const handleSearch = () => {
    setSearchTextFilter(searchText);
  };

  useEffect(() => {
    if (!props.deliverableId) {
      if (formik.values.createReportFrom.value !== "existing template") {
        formik.values.existingTemplate && formik.setFieldValue("existingTemplate", "");
      }
    }
  }, [formik.values.createReportFrom.value]);

  useEffect(() => {
    if (deliverableImageSrc) {
      setShowTrash(true);
    }
  }, [deliverableImageSrc]);

  useEffect(() => {
    if (deliverableSignalRelatedContentImageSrc) {
      setShowTrashSignal(true);
    }
  }, [deliverableSignalRelatedContentImageSrc]);
  useEffect(() => {
    fetchAllRelatedReports();
  }, [sideBarContentTypeFilter, sideBarDeliverableTypeFilter, selectedTrend, sgfName]);
  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-12 pt-2">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group pt-3">
                  <ReactSelectMultiSelect
                    formik={formik}
                    id="deliverableProductPlatform"
                    name="deliverableProductPlatform"
                    classNamePrefix="select"
                    placeholder="Application Platform *"
                    filtering={(e) => searchProducts(e)}
                    dataSource={deliverableInsiderPlatform}
                    isDisabled={!props.deliverableId ? false : true}
                  />
                </div>
              </div>
              {productVariant === "WhatNext" ? (
                <div className="col-md-4 pt-3">
                  {whatNextDeliverableDataType && (
                    <Dropdown
                      formik={formik}
                      required="required"
                      placeholder="Deliverable Type *"
                      id="deliverableType"
                      name="deliverableType"
                      fields={{ text: "label", value: "value" }}
                      value={props.deliverableInformation.deliverableType && formik.values && formik.values.deliverableType}
                      dataSource={whatNextDeliverableDataType}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="col-md-2 pt-3">
                    {insiderDeliverableContentType && insiderDeliverableContentType.length > 0 && (
                      <Dropdown
                        formik={formik}
                        placeholder="Content Type *"
                        required="required"
                        id="deliverableContentType"
                        name="deliverableContentType"
                        fields={{ text: "label", value: "value" }}
                        value={props.deliverableInformation.deliverableContentType && formik.values && formik.values.deliverableContentType}
                        dataSource={insiderDeliverableContentType}
                      />
                    )}
                  </div>
                  <div className="col-md-2 pt-3">
                    {insiderDeliverableDataType && insiderDeliverableDataType.length > 0 && (
                      <>
                        {/* Hiding add circle option as advised by Arup */}
                        {/* <div className="row">
                        <div style={{ width: "75%" }}> */}
                        <Dropdown
                          // enabled={formik.values.deliverableType ? true : false}
                          formik={formik}
                          placeholder="Deliverable Type *"
                          required="required"
                          id="deliverableType"
                          name="deliverableType"
                          fields={{ text: "label", value: "value" }}
                          value={props.deliverableInformation.deliverableType && formik.values && formik.values.deliverableType}
                          dataSource={insiderDeliverableDataType}
                        />
                        {/* </div> */}
                        {/* <span
                            className="addCircle"
                            onClick={() => {
                              setShowSideBar(true);
                            }}
                          >
                            <AddCircle height={"25px"} />
                          </span> */}
                        {/* </div> */}
                      </>
                    )}
                  </div>
                </>
              )}
              <div className="col-md-6 pt-3">
                <div className="row">
                  {/* <div className="col-md-12"> */}
                  {productVariant === "WhatNext"
                    ? deliverableWhatNextPlatform && (
                        <div className="col-md-12">
                          <MultiSelect
                            formik={formik}
                            enabled={props.deliverableId ? false : true}
                            mode="CheckBox"
                            allowCustomValue={false}
                            allowFiltering={true}
                            enableRtl={true}
                            showClearButton={false}
                            required="required"
                            placeholder="Applicable Platform * (Multiple Select)"
                            id="deliverableProductPlatform"
                            name="deliverableProductPlatform"
                            value={
                              props.deliverableInformation.deliverableProductPlatform && formik.values && formik.values.deliverableProductPlatform
                            }
                            fields={{ text: "label", value: "value" }}
                            dataSource={deliverableWhatNextPlatform}
                          />
                        </div>
                      )
                    : deliverableInsiderPlatform && (
                        <>
                          <div className="col-md-12">
                            {/* <Dropdown
                              enabled={props.deliverableId ? false : true}
                              formik={formik}
                              mode="CheckBox"
                              placeholder="Applicable Platform1 *"
                              required="required"
                              id="deliverableProductPlatform"
                              name="deliverableProductPlatform"
                              fields={{ text: "label", value: "value" }}
                              value={
                                props.deliverableInformation.deliverableProductPlatform && formik.values && formik.values.deliverableProductPlatform
                              }
                              dataSource={deliverableInsiderPlatform}
                            /> */}
                            <TextField
                              formik={formik}
                              placeholder="Deliverable Name *"
                              name="deliverableName"
                              id="deliverableName"
                              {...props}
                              numberCount={true}
                              maxLength={125}
                              hideInitial={true}
                              characterLimitText={true}
                            />
                          </div>
                        </>
                      )}
                  {/* </div> */}
                  {/* <div className="col-md-6">
										<Dropdown
											formik={formik}
											placeholder="Report Orientation"
											id="deliverableContentOrientation"
											name="deliverableContentOrientation"
											fields={{ text: "label", value: "value" }}
											value={props.deliverableInformation.deliverableContentOrientation && formik.values && formik.values.deliverableContentOrientation}
											dataSource={deliverableOrientation}
										/>
									</div> */}
                </div>
              </div>
            </div>
          </div>

          {formik.values.deliverableProductPlatform.length > 0 &&
            !formik.values.deliverableProductPlatform.includes("Insider V1") &&
            !formik.values.deliverableProductPlatform.includes("WhatNext V1") &&
            formik.values?.deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
            !isContentTypeSignal && (
              <div className="col-md-12 pt-2">
                <div className="row">
                  <div className="col-md-4">
                    <div className={props.deliverableId ? "disable-react-select" : ""}></div>
                    <Dropdown
                      formik={formik}
                      required={true}
                      value={formik.values.createReportFrom.value}
                      enabled={props.deliverableId ? false : true}
                      sortOrder="Acending"
                      placeholder="Create Deliverable With *"
                      id="createReportFrom"
                      name="createReportFrom"
                      fields={{ text: "label", value: "value" }}
                      dataSource={CREATE_FORM_OPTIONS}
                      change={(selectedOption) => {
                        formik.setValues({
                          ...formik.values,
                          createReportFrom: selectedOption
                            ? {
                                label: selectedOption.itemData.label,
                                value: selectedOption.itemData.value,
                              }
                            : {},
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-4">
                    <Dropdown
                      formik={formik}
                      // required={true}
                      enabled={
                        formik.values.createReportFrom.value === "blank" ||
                        formik.values.createReportFrom.value === undefined ||
                        formik.values.createReportFrom.value === "Choose a template option" ||
                        props.deliverableId
                          ? false
                          : true
                      }
                      value={
                        formik.values.createReportFrom.value === "blank" || formik.values.existingTemplate == "" ? "" : formik.values.existingTemplate
                      }
                      sortOrder="Acending"
                      placeholder="Select Deliverable Template *"
                      id="existingTemplate"
                      name="existingTemplate"
                      fields={{ text: "label", value: "value" }}
                      dataSource={templateData}
                    />
                  </div>
                  <div className="col-md-4">
                    <ToggleSwitchNew
                      formik={formik}
                      required="required"
                      properties={{
                        id: "deliverableIsTemplate",
                        name: "deliverableIsTemplate",
                        label: "Save this deliverable as template",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          {!isContentTypeSignal && (
            <div className="col-md-12 pt-2">
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    style={{ height: "100px" }}
                    formik={formik}
                    multiline={true}
                    numberCount={true}
                    maxLength={200}
                    rows={5}
                    name="deliverableSummary"
                    className="form-control"
                    id="deliverableSummary"
                    placeholder="Deliverable Summary *"
                    characterLimitText={true}
                    {...props}
                    hideInitial={true}
                    value={props.deliverableInformation.deliverableSummary && formik.values.deliverableSummary}
                  />
                </div>
              </div>
            </div>
          )}
          {!isContentTypeSignal && (
            <div className="col-md-12 pt-2">
              <div className="row">
                <div className="col-md-4 d-flex m-0 mb-4 pr-0">
                  <div className="flex-fill deliverable-company-container">
                    <AsyncMultiSelect
                      formik={formik}
                      id="deliverableCompany"
                      name="deliverableCompany"
                      classNamePrefix="select"
                      placeholder="Company"
                      filtering={(e) => searchCompany(e)}
                      noRecordsTemplate={(record, inputValue) => {
                        return record?.inputValue.trim().length > 2 || inputValue.trim().length > 2
                          ? "No match found"
                          : "Please type at least 3 characters";
                      }}
                    />
                  </div>
                  <div className="px-0">
                    <a className="btn btn-outline-dark btn-sm add-new-company-btn" target="_blank" href="/company/add">
                      Add company
                    </a>
                  </div>
                </div>
                <div className="col-md-4 m-0 mb-4 pr-0">
                  <AsyncMultiSelect
                    formik={formik}
                    id="deliverableProduct"
                    name="deliverableProduct"
                    classNamePrefix="select"
                    placeholder="Product"
                    filtering={(e) => searchProducts(e)}
                    noRecordsTemplate={(record, inputValue) => {
                      return record?.inputValue.trim().length > 2 || inputValue.trim().length > 2
                        ? "No match found"
                        : "Please type at least 3 characters";
                    }}
                  />
                </div>
                <div className="col-md-4 m-0 mb-4">
                  <AsyncMultiSelect
                    formik={formik}
                    id="deliverableNews"
                    name="deliverableNews"
                    classNamePrefix="select"
                    placeholder="News"
                    filtering={(e) => searchNews(e)}
                    noRecordsTemplate={(record, inputValue) => {
                      return record?.inputValue.trim().length > 2 || inputValue.trim().length > 2
                        ? "No match found"
                        : "Please type at least 3 characters";
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {isContentTypeSignal && (
            <div className="col-md-12 pt-2">
              <div className="row signalRow">
                <div className="col-md-4">
                  <TextField
                    style={{ height: "100px" }}
                    formik={formik}
                    multiline={true}
                    numberCount={true}
                    maxLength={200}
                    // rows={5}
                    name="deliverableSummary"
                    className="form-control"
                    id="deliverableSummary"
                    placeholder="Summary *"
                    characterLimitText={true}
                    {...props}
                    hideInitial={true}
                    value={props.deliverableInformation.deliverableSummary && formik.values.deliverableSummary}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    style={{ height: "100px" }}
                    formik={formik}
                    multiline={true}
                    numberCount={true}
                    maxLength={100}
                    // rows={5}
                    name="deliverableSignalTooltip"
                    className="form-control"
                    id="deliverableSignalTooltip"
                    placeholder="Tooltip"
                    characterLimitText={true}
                    {...props}
                    hideInitial={true}
                    value={props.deliverableInformation.deliverableSignalTooltip && formik.values.deliverableSignalTooltip}
                  />
                </div>
                <div className="indicatorCustomCss" style={{ display: "flex" }}>
                  <TextField
                    formik={formik}
                    placeholder="Indicator"
                    name="deliverableSignalIndicator"
                    id="deliverableSignalIndicator"
                    hideInitial={true}
                    // width="100px"
                    {...props}
                  />
                  <CustomTooltip
                    title={<p style={{ color: "#FFF" }}>Only +, -, %, . and numbers are allowed.</p>}
                    placement={window.innerWidth >= 820 ? "right" : "bottom"}
                    className="d-flex align-items-center"
                    style={{ maxWidth: "fit-content", height: "fit-content", marginTop: "1px", width: "36px", height: "36px" }}
                    disableFocusListener
                  >
                    <img src="/assets/info-icon.svg" alt="info"></img>
                  </CustomTooltip>
                </div>
                <div className="col-md-1">
                  <Select
                    options={options}
                    value={signalIndicatorColor}
                    formatOptionLabel={(op) => formattedColorLabel(op)}
                    components={{ Option: ColorOption, SingleValue: ColorValue }}
                    isMulti={false}
                    onChange={(e) => {
                      setSignalIndicatorColor(e);
                    }}
                    styles={{
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                    }}
                    theme={{
                      colors: {
                        primary: "#f94f5e",
                        primary75: "rgba(0, 0, 0, 0.75)",
                        primary50: "rgba(0, 0, 0, 0.05)",
                        primary25: "rgba(0, 0, 0, 0.05)",
                        neutral90: "rgba(38,38,38,0.9)",
                        neutral80: "rgba(38,38,38,0.8)",
                        neutral70: "rgba(38,38,38,0.7)",
                        neutral60: "rgba(38,38,38,0.6)",
                        neutral50: "rgba(38,38,38,0.5)",
                        neutral40: "rgba(38,38,38,0.4)",
                        neutral30: "rgba(38,38,38,0.3)",
                        neutral20: "rgba(38,38,38,0.2)",
                        neutral10: "rgba(38,38,38,0.1)",
                        neutral5: "rgba(38,38,38,0.05)",
                        neutral0: "rgba(38,38,38,0)",
                        danger: "#DE350B",
                        dangerLight: "#a1a1a1",
                      },
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "Publish Date *",
                      id: "deliverablePublishedDate",
                      name: "deliverablePublishedDate",
                      format: "dd MMM yyyy",
                      allowEdit: false,
                      floatLabelType: "Auto",
                      openOnFocus: true,
                      max: getDateFromMongoDate(new Date()),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {!isContentTypeSignal && (
            <div className="col-md-12 pt-2">
              <div className="row">
                <div className="form-group required col-md-12">
                  <div className="col-md-12 row m-0 p-0">
                    <div className="w-20 px-0 pr-2">
                      <div className="form-group required">
                        {props.deliverableId ? (
                          <DatePicker
                            formik={formik}
                            properties={{
                              placeholder: "Report Est Start Date",
                              id: "deliverableEstStartDate",
                              name: "deliverableEstStartDate",
                              format: "dd MMM yyyy",
                              allowEdit: false,
                              floatLabelType: "Auto",
                              openOnFocus: true,
                            }}
                          />
                        ) : (
                          <DatePicker
                            formik={formik}
                            properties={{
                              placeholder: "Report Est Start Date",
                              id: "deliverableEstStartDate",
                              name: "deliverableEstStartDate",
                              format: "dd MMM yyyy",
                              allowEdit: false,
                              floatLabelType: "Auto",
                              openOnFocus: true,
                              min: new Date(),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-20 px-0 pr-2">
                      <div className="form-group required">
                        <DatePicker
                          // placeholder="Report Estimated End Date"
                          formik={formik}
                          properties={{
                            placeholder: "Report Est End Date",
                            id: "deliverableEstEndDate",
                            name: "deliverableEstEndDate",
                            format: "dd MMM yyyy",
                            allowEdit: false,
                            floatLabelType: "Auto",
                            openOnFocus: true,
                            min: getDateFromMongoDate(formik.values[`deliverableEstStartDate`]),
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-20 px-0 pr-2">
                      <div className="form-group required">
                        <DatePicker
                          formik={formik}
                          properties={{
                            disabled: props.deliverableId ? false : true,
                            placeholder: "Report Act Start Date",
                            id: "deliverableActStartDate",
                            name: "deliverableActStartDate",
                            format: "dd MMM yyyy",
                            allowEdit: false,
                            floatLabelType: "Auto",
                            openOnFocus: true,
                            min: getDateFromMongoDate(formik.values[`deliverableEstStartDate`]),
                            // formik.values && formik.values.deliverableEstStartDate,
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-20 px-0 pr-2">
                      <div className="form-group required">
                        <DatePicker
                          formik={formik}
                          properties={{
                            disabled: props.deliverableId ? false : true,
                            placeholder: "Report Act End Date",
                            id: "deliverableActEndDate",
                            name: "deliverableActEndDate",
                            format: "dd MMM yyyy",
                            allowEdit: false,
                            floatLabelType: "Auto",
                            openOnFocus: true,
                            min: getDateFromMongoDate(formik.values[`deliverableActStartDate`]),
                            // formik.values && formik.values.deliverableActStartDate,
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-20 px-0">
                      <div className="form-group required">
                        {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) ||
                        productVariant == PRODUCT_VARIANT.CHEERSIN ||
                        productVariant == PRODUCT_VARIANT.COGNITION ||
                        productVariant == PRODUCT_VARIANT.INSIDER ? (
                          <DatePicker
                            formik={formik}
                            properties={{
                              placeholder: "Publish Date *",
                              id: "deliverablePublishedDate",
                              name: "deliverablePublishedDate",
                              format: "dd MMM yyyy",
                              allowEdit: false,
                              floatLabelType: "Auto",
                              openOnFocus: true,
                              max: getDateFromMongoDate(new Date()),
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {productVariant === "Insider" && !isContentTypeSignal ? (
                      <div className="col-md-4 deliverable-toggle">
                        <label>Is Most Read Research for Newsletter? </label>
                        <label className="custom-switch">
                          <input
                            type="checkbox"
                            id="deliverableIsMostResearch"
                            name="deliverableIsMostResearch"
                            value={mostReadResearch ? "YES" : "NO"}
                            checked={mostReadResearch}
                            onChange={(e) => {
                              setMostReadResearch(!mostReadResearch);
                              formik.setFieldValue("deliverableIsMostResearch", e.target.checked ? "YES" : "NO", true);
                            }}
                          />
                          <div>
                            <span className="on">Yes</span>
                            <span className="off">No</span>
                          </div>
                          <i></i>
                        </label>
                      </div>
                    ) : null}
                    {/* Hiding Add to Widget option as advised by Arup */}
                    {/* {productVariant === "Insider" ? (
                      <div className="col-md-3 deliverable-toggle">
                        <label>Add to Widget?</label>
                        <label className="custom-switch">
                          <input
                            type="checkbox"
                            id="deliverableAddToWidgets"
                            name="deliverableAddToWidgets"
                            value={addToWidgets ? "YES" : "NO"}
                            checked={addToWidgets}
                            onChange={(e) => {
                              setAddToWidgets(!addToWidgets);
                              formik.setFieldValue("deliverableAddToWidgets", e.target.checked ? "YES" : "NO", true);
                            }}
                          />
                          <div>
                            <span className="on">Yes</span>
                            <span className="off">No</span>
                          </div>
                          <i></i>
                        </label>
                      </div>
                    ) : null} */}
                    {formik.values?.deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
                      !isContentTypeSignal &&
                      (productVariant === "Insider" ? (
                        <div className="col-md-3 deliverable-toggle">
                          <label>Is Popular Report for Newsletter?</label>
                          <label className="custom-switch">
                            <input
                              type="checkbox"
                              id="deliverableIsPopularReport"
                              name="deliverableIsPopularReport"
                              value={popularReport ? "YES" : "NO"}
                              checked={popularReport}
                              onChange={(e) => {
                                setPopularReport(!popularReport);
                                formik.setFieldValue("deliverableIsPopularReport", e.target.checked ? "YES" : "NO", true);
                              }}
                            />
                            <div>
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </div>
                            <i></i>
                          </label>
                        </div>
                      ) : null)}
                    {/* {formik.values?.deliverableContentType !== "Dashboard" && !isContentTypeSignal && (
                      <div className="col-md-2">
                        <ToggleSwitchNew
                          formik={formik}
                          required="required"
                          properties={{
                            id: "deliverablePaywallSetting",
                            name: "deliverablePaywallSetting",
                            label: "Enable Paywall",
                          }}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isContentTypeSignal && (
            <div className="col-md-12 pt-2 mb-4">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <div className="form-group required">
                    <label style={style.label} htmlFor="deliverableCompany">
                      Upload Thumbnail{" "}
                    </label>
                    <div
                      className="image-holder"
                      style={{
                        padding: "unset",
                        width: "350px",
                        height: "200px",
                        background: "#cccccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar
                          style={{ width: "100%", height: "100%" }}
                          variant="square"
                          src={deliverableImageSrc || deliverableImage}
                          alt="Deliverable"
                        /> */}

                      <img width="350px" height="200px" src={deliverableImageSrc || deliverableImage} alt="Deliverable" ref={deliverableImageRef} />

                      <div className="image-action newsThumbnail_fa_icons" style={{ top: "5px" }}>
                        <span
                          onClick={() => {
                            dispatch(
                              showAlertBox({
                                content: "Are you sure, you want to delete?",
                                okCallback: async () => {
                                  dispatch(actionSuccess("Image deleted successfully"));
                                  setShowTrash(false);
                                  setImageError("Please upload image");
                                  // setDeliverableImageSrc("");
                                  deliverableImageRef.current.setAttribute("src", deliverableImage);
                                  deliverableDefaultImageRef.current.value = "";
                                },
                              })
                            );
                          }}
                        >
                          <i className={`fa fa-trash mr-1 ${!showTrash ? "d-none" : undefined}`} aria-hidden="true" />
                        </span>
                        <span onClick={() => deliverableDefaultImageRef.current.click()}>
                          <i className="fa fa-pencil-alt" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <input
                      type="file"
                      className="d-none"
                      accept=".jpg, .png, .jpeg"
                      id="deliverableProfileImage"
                      name="deliverableProfileImage"
                      ref={deliverableDefaultImageRef}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          if (
                            e.target.files[0].size > DELIVERABLE_THUMB_IMG_SIZE ||
                            !["image/png", "image/jpg", "image/jpeg"].includes(e.target.files[0].type)
                          ) {
                            setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                            return;
                          }
                          if (
                            (e.target.files[0].type == "image/png" ||
                              e.target.files[0].type == "image/jpg" ||
                              e.target.files[0].type == "image/jpeg") &&
                            e.target.files[0].size <= DELIVERABLE_THUMB_IMG_SIZE
                          ) {
                            //1000kb == 1000000 byte
                            readProfileImage(e.target.files[0]);
                            deliverableImageRef.current.setAttribute("src", e.target.files[0]);
                            // setDeliverableImageSrc(URL.createObjectURL(e.target.files[0]));
                            setImageError("");
                            setShowTrash(true);
                            setIsDefaultImage(false);
                            formik.setErrors({
                              ...formik.errors,
                              deliverableProfileImage: "",
                            });
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                    {imageError &&
                      imageError.split("while").map((e) => (
                        <small key={e.length}>
                          {e} <br />{" "}
                        </small>
                      ))}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <small>
                      <div className="col-md-12 ">
                        <span className="font-weight-bold">Note : </span>
                      </div>
                      <div className="col-md-12">
                        <li>Only JPG/JPEG or PNG files are allowed.</li>
                      </div>
                      <div className="col-md-12">
                        <li>Max file size allowed is 10MB.</li>
                      </div>
                      <div className="col-md-12">
                        <li>Thumbnail Dimensions: 350 W x 200 H</li>
                      </div>
                    </small>
                  </div>
                </div>
                {((formik.values?.deliverableProductPlatform.includes("Insider V2") &&
                  formik.values.deliverableType == DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE &&
                  formik.values?.deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
                  formik.values?.createReportFrom?.value === "Choose a template option") ||
                  formik.values?.deliverableProductPlatform.includes("Insider V1") ||
                  formik.values?.deliverableProductPlatform.includes("WhatNext V1")) && (
                  <div className="col-md-12 mb-2 form-group required mt-4 upload-deliverable-file">
                    <label style={style.label} htmlFor="objective">
                      Upload Deliverable File{" "}
                    </label>
                    <UploadComponent editDocumentData={formik.values.deliverableDocuments} formik={formik} {...props} />
                  </div>
                )}
              </div>
            </div>
          )}
          {productVariant === "Insider" &&
          formik.values?.deliverableContentType === CONTENT_TYPE.DASHBOARD &&
          formik.values.deliverableProductPlatform.includes("Insider V2") &&
          !formik.values?.deliverableProductPlatform.includes("Insider V1") ? (
            <div className="col-md-12 deliverable-toggle">
              <label>Is Coming Soon? </label>
              <label className="custom-switch">
                <input
                  type="checkbox"
                  id="deliverableIsComingSoon"
                  name="deliverableIsComingSoon"
                  value={comingSoon ? "YES" : "NO"}
                  checked={comingSoon}
                  onChange={(e) => {
                    setComingSoon(!comingSoon);
                    formik.setFieldValue("deliverableIsComingSoon", e.target.checked ? "YES" : "NO", true);
                  }}
                />
                <div>
                  <span className="on">Yes</span>
                  <span className="off">No</span>
                </div>
                <i></i>
              </label>
            </div>
          ) : null}
          {formik.values?.deliverableContentType === CONTENT_TYPE.DASHBOARD &&
            formik.values.deliverableProductPlatform.includes("Insider V2") &&
            !formik.values?.deliverableProductPlatform.includes("Insider V1") &&
            formik.values?.deliverableIsComingSoon === "NO" && (
              <div className="col-md-12 pt-2 mb-4">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="form-group required">
                      <label style={style.label} htmlFor="deliverableCompany">
                        Power BI File{" "}
                      </label>
                      <div className="power-bi-upload-label">
                        <span className="power-bi-upload-label-span">Upload File</span> (Maximum file size allowed for upload: 50 MB and only PBIX
                        file type is allowed)
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="row powerbi-file-uploader-container">
                            <div className="col-md-5">
                              <label className="powerbi-file-uploader-box">
                                <p className="powerbi-file-uploader-choose-file">Choose File</p>
                                <p className="powerbi-file-uploader-no-file">{selectedFile?.name ? selectedFile?.name : "No file chosen"}</p>
                                <input
                                  className="powerbi-file-uploader-input"
                                  type="file"
                                  accept=".pbix"
                                  onChange={handleFileChange}
                                  id="powerbiFileInput"
                                />
                              </label>
                            </div>
                            <div className="col-md-2">
                              <button
                                className={`power-bi-upload-button btn ${!selectedFile ? "disabled-power-bi-upload-button" : "btn-primary"}`}
                                onClick={handleUpload}
                                disabled={!selectedFile}
                              >
                                Upload
                              </button>
                            </div>
                            {formik.values.deliverablePowerbiReportId && (
                              <div className="col-md-5 download-section-box">
                                <PowerBiImage height={"25px"} />
                                <div className="download-section-report-details">
                                  <div className="download-section-report-name">{formik.values.deliverablePowerbiOriginalReportName}</div>
                                  <div className="download-section-report-date">
                                    {moment(formik.values.deliverablePowerbiReportUploadedDate).format("DD-MMM-YYYY")}
                                  </div>
                                </div>
                                <DownloadPowerBi className="cursor-pointer" height={"25px"} onClick={handleDownload} />
                              </div>
                            )}
                          </div>
                        </div>
                        {formik.values.deliverablePowerbiReportId && formik.values.deliverableContentStatus !== "Published" && (
                          <div className="col-md-1 dashboard-close-button-box">
                            <MdDeleteForever
                              className="text-xl cursor-pointer text-gray-400"
                              onClick={handleRemoveUploadedFile}
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "rgb(107 114 128 / 1)",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {isContentTypeSignal && (
            <div className="col-md-12 pt-2 mb-4">
              <div className="row">
                <div className="col-md-3 deliverable-toggle">
                  <Dropdown
                    formik={formik}
                    placeholder="Related Content Type *"
                    required="required"
                    id="deliverableSignalRelatedContentType"
                    name="deliverableSignalRelatedContentType"
                    fields={{ text: "label", value: "value" }}
                    value={
                      props.deliverableInformation.deliverableSignalRelatedContentType &&
                      formik.values &&
                      formik.values.deliverableSignalRelatedContentType
                    }
                    dataSource={relatedContentType}
                  />
                </div>
                {/* Hiding Add to Widget option as advised by Arup */}
                {/* <div className="col-md-3 deliverable-toggle">
                  <label>Add to Widget?</label>
                  <label className="custom-switch">
                    <input
                      type="checkbox"
                      id="deliverableAddToWidgets"
                      name="deliverableAddToWidgets"
                      value={addToWidgets ? "YES" : "NO"}
                      checked={addToWidgets}
                      onChange={(e) => {
                        setAddToWidgets(!addToWidgets);
                        formik.setFieldValue("deliverableAddToWidgets", e.target.checked ? "YES" : "NO", true);
                      }}
                    />
                    <div>
                      <span className="on">Yes</span>
                      <span className="off">No</span>
                    </div>
                    <i></i>
                  </label>
                </div> */}
              </div>
              {!relatedContentSaved ? (
                formik?.values?.deliverableSignalRelatedContentType !== "" ? (
                  <div className="row">
                    <div
                      className="col-md-3 signal-add-related-content"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (formik?.values?.deliverableSignalRelatedContentType === "Report") {
                          fetchAllRelatedReports();
                          setOpenRelatedContentReportSideBar(true);
                          fetchSgf(industry[0].id);
                        } else if (formik?.values?.deliverableSignalRelatedContentType === "External Link")
                          setOpenRelatedContentExternalSideBar(true);
                      }}
                    >
                      <i className="fas fa-plus pr-2"></i> Add Related Content
                    </div>
                  </div>
                ) : null
              ) : (
                <>
                  <div className="row">
                    <div style={{ fontWeight: "bold", marginLeft: "15px" }}>Selected Content for Signal</div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (formik?.values?.deliverableSignalRelatedContentType === "Report") {
                          fetchAllRelatedReports();
                          setOpenRelatedContentReportSideBar(true);
                          fetchSgf(industry[0].id);
                        } else if (formik?.values?.deliverableSignalRelatedContentType === "External Link")
                          setOpenRelatedContentExternalSideBar(true);
                        else {
                          formik.setErrors({
                            ...formik.errors,
                            deliverableSignalRelatedContentType: "Please select Related Content Type",
                          });
                        }
                      }}
                    >
                      <i className="fa fa-pencil-alt text-muted" aria-hidden="true" style={{ marginLeft: "96px" }} />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRelatedContentSaved(false);
                      }}
                    >
                      <i
                        className={`fa fa-trash mr-1 text-muted ${!showTrash ? "d-none" : undefined}`}
                        aria-hidden="true"
                        style={{ marginLeft: "15px" }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 signal-report-card">
                      <div
                        className="image-holder"
                        style={{
                          padding: "unset",
                          width: "350px",
                          height: "200px",
                          background: "#cccccc",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          width="350px"
                          height="200px"
                          src={
                            formik?.values?.deliverableSignalRelatedContentType === "Report"
                              ? deliverableSignalRelatedContentImageSrc || deliverableImage
                              : deliverableSignalRelatedContentImageSrcExternal || deliverableImage
                          }
                          alt="Deliverable"
                          ref={externalLinkImageRef}
                        />
                      </div>
                      <div className="signal-report-details">
                        {deliverableSignalContent.deliverableSignalReadTime !== "" && (
                          <div className="report-clock">
                            <div className="report-clock-innerdiv">
                              <img src="/assets/clock-white.svg" alt="clockIcon" width="12" />
                              <span className="report-read-time">{deliverableSignalContent?.deliverableSignalReadTime} min</span>
                            </div>
                          </div>
                        )}
                        <div className="signal-report-desc">
                          <div>
                            <p className="signal-report-title">{deliverableSignalContent.deliverableSignalName}</p>
                            <div className="signal-report-date-tag">
                              {deliverableSignalContent.deliverableSignalDate !== "" && (
                                <CommonDate date={deliverableSignalContent.deliverableSignalDate} className="signal-report-date" />
                              )}
                              {deliverableSignalContent.deliverableSignalContent !== "" && (
                                <CommonTag label={deliverableSignalContent.deliverableSignalContent}></CommonTag>
                              )}
                            </div>
                          </div>
                          {deliverableSignalContent.deliverableSignalTags && deliverableSignalContent.deliverableSignalTags.length > 0 && (
                            <div className="signal-report-tags">
                              <img src="/assets/tag-white.svg" alt="tag" />
                              <ul>
                                {deliverableSignalContent.deliverableSignalTags.slice(0, 3).map((tag) => {
                                  return <li key={tag.trendId + Math.random()}>{tag.trendName}</li>;
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {showSideBar && (
            <SideBar
              setToggleDeliverableDropdown={setToggleDeliverableDropdown}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              existingDeliverableTypes={existingDeliverableTypes}
            />
          )}
          {openRelatedContentReportSideBar && (
            <div>
              <SidebarModal
                isDeliverableRelatedReport={true}
                className="deliverableSidebarModal"
                backDrop={true}
                // style={{ marginTop: "-80px", maxHeight: "none", height: "800px" }}
                header="Select Related Content"
                closeIcon={
                  <div
                    onClick={() => {
                      setOpenRelatedContentReportSideBar(false);
                      formik.setFormikState({ ...formik, touched: {}, errors: {} });
                    }}
                  >
                    X
                  </div>
                }
                footer={
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div className="pt-2">
                      <span style={{ color: "black", paddingLeft: "23px" }}>Showing {relatedContentReportCount} results</span>
                    </div>
                    <div className="pt-2 pb-4">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                        onClick={() => {
                          setOpenRelatedContentReportSideBar(false);
                          setSideBarContentTypeFilter("");
                          setSideBarDeliverableTypeFilter("");
                          formik.setFormikState({ ...formik, touched: {}, errors: {} });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary ml-3 mr-3"
                        style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                        onClick={() => {
                          saveReportRelatedContent();
                          setSideBarContentTypeFilter("");
                          setSideBarDeliverableTypeFilter("");
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                }
              >
                <div className="sidebar-wrapper">
                  <div className="row" style={{ marginLeft: "6px", marginRight: "6px" }}>
                    <div className="col-2">
                      {industry.length > 0 ? (
                        <DropDownListComponent
                          value={selectedIndustry || industry[0].id}
                          change={(value) => {
                            setSelectedIndustry(value.itemData);
                            fetchSgf(value.itemData.id);
                            setSgfTrendData([]);
                            setTrends([]);
                          }}
                          cssClass={"customCss e-outline"}
                          floatLabelType="Auto"
                          popupHeight="155px"
                          dataSource={industry}
                          fields={{ text: "industryName", value: "id" }}
                          placeholder="Select Industry"
                        />
                      ) : null}
                    </div>
                    <div className="col-3" style={{ marginTop: "20px !important" }}>
                      {sgfTrendData?.length > 0 && (
                        <DropDownListComponent
                          value={sgfName}
                          change={(value) => {
                            setSgfName(value?.itemData.id);
                            setTrends(value?.itemData.trend);
                            setSelectedTrend(value?.itemData.trend[0].trendId);
                            fetchTaxonomy(value?.itemData.trend[0].trendId);
                            // fetchAllRelatedReports();
                          }}
                          cssClass={"customCss e-outline"}
                          floatLabelType="Auto"
                          popupHeight="150px"
                          dataSource={sgfTrendData}
                          fields={{ text: "sgfName", value: "id" }}
                          placeholder="Filter by SGF"
                          // popupWidth="284px"
                        />
                      )}
                    </div>

                    <div className="col-3">
                      {trends.length > 0 && selectedTrend && (
                        <DropDownListComponent
                          value={selectedTrend || trends[0].trendId}
                          cssClass={"customCss e-outline"}
                          floatLabelType="Auto"
                          popupHeight="250px"
                          dataSource={trends}
                          fields={{ text: "trendName", value: "trendId" }}
                          placeholder="Filter by Trend"
                          // popupWidth="284px"
                          change={(value) => {
                            setSelectedTrend(value?.itemData?.trendId);
                            fetchTaxonomy(value?.itemData?.trendId);
                            // fetchAllRelatedReports();
                          }}
                        />
                      )}
                    </div>
                    <div className="col-2">
                      {insiderDeliverableContentType && insiderDeliverableContentType.length > 0 && (
                        <Dropdown
                          formik={formik}
                          placeholder="Filter by Content Type"
                          required="required"
                          id="sideBarDeliverableContentType"
                          name="sideBarDeliverableContentType"
                          fields={{ text: "label", value: "value" }}
                          value={
                            props.deliverableInformation.sideBarDeliverableContentType && formik.values && formik.values.sideBarDeliverableContentType
                          }
                          dataSource={insiderDeliverableContentType.filter((item) => item.label !== "Signals")}
                          change={(selectedValue) => {
                            setSideBarContentTypeFilter(selectedValue.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-2">
                      {insiderDeliverableDataType && insiderDeliverableDataType.length > 0 && (
                        <>
                          <div className="row">
                            <div style={{ width: "95%" }}>
                              <Dropdown
                                formik={formik}
                                placeholder="Filter by Deliverable Type"
                                required="required"
                                id="sideBardeliverableType"
                                name="sideBardeliverableType"
                                fields={{ text: "label", value: "value" }}
                                value={props.deliverableInformation.sideBardeliverableType && formik.values && formik.values.sideBardeliverableType}
                                dataSource={insiderDeliverableDataType.filter((item) => item.label !== DELIVERABLE_TYPE.SIGNALS)}
                                change={(selectedValue) => {
                                  setSideBarDeliverableTypeFilter(selectedValue.value);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: "6px", marginRight: "6px" }}></div>
                  <div style={{ paddingLeft: "23px", paddingRight: "16px" }}>
                    <div className="row">
                      <div className="col-4" style={{ paddingTop: "8px" }}>
                        <div className="widgetHeading">Selected Content for Signal</div>
                      </div>
                      <div className="col-4" style={{ float: "right", display: "flex", marginLeft: "136px" }}>
                        <TextBoxComponent
                          placeholder="Search by Report Name"
                          // floatLabelType="Always"
                          ref={searchBoxRef}
                          input={searchSidebarReports}
                          cssClass="searchBoxWidgets"
                        />
                        <div className="deliverableSearchIcon" onClick={handleSearch} style={{ marginLeft: "5px" }}>
                          <img src="/assets/search.svg" alt="deliverableSearchIcon" />
                        </div>
                      </div>
                      <div className="col-2" style={{ float: "right", marginLeft: "14px", marginTop: "-10px", display: "flex" }}>
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          style={{ marginTop: "10px", float: "right", height: "40px", marginLeft: "-31px", marginRight: "16px" }}
                          onClick={handleResetSidebarReports}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-2" style={{ paddingLeft: "23px", marginLeft: "-151px" }}>
                        <DropDownListComponent
                          value={reportsSort}
                          change={(value) => {
                            setReportsSort(value?.itemData.value);
                          }}
                          cssClass={"customCss e-outline"}
                          floatLabelType="Auto"
                          // popupHeight="150px"
                          dataSource={sortDataSource}
                          fields={{ text: "text", value: "value" }}
                          placeholder="Sort"
                          // popupWidth="284px"
                        />
                      </div>
                    </div>
                    <div className="deliverableSignalsList" style={{}}>
                      {reportsSort === "Newest"
                        ? reportList?.map((item, index) => {
                            if (item.title.toLowerCase()?.includes(searchTextFilter.toLowerCase())) {
                              return (
                                <div
                                  className={`reportList reportListDiv ${
                                    selectedRelatedContentReportId === item.id ? "selectedReportListDiv" : null
                                  }`}
                                  key={item.id + Math.random()}
                                  id={`list${item.id}`}
                                  onClick={() => setSelectedRelatedContentReportId(item.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {selectedRelatedContentReportId === item.id && (
                                    <div style={{ float: "right", margin: "5px 5px 0px 0px" }}>
                                      <img src="/assets/red-circle-check.svg" />
                                    </div>
                                  )}
                                  <div className={"recent_content_card"}>
                                    <div className={"title_top"}>
                                      <p
                                        className={"itemTitle"}
                                        // onClick={() => handleDetailClick(item)}
                                      >
                                        {item.title}
                                      </p>
                                    </div>
                                    <CommonDateTagSection
                                      location={item.location}
                                      date={item.date}
                                      label={item.label}
                                      tags={item.industryTag}
                                      readTime={item.readTime}
                                      showReadTime={true}
                                      subType={item.subType}
                                      showSubType={true}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          })
                        : reportList
                            ?.slice()
                            .reverse()
                            .map((item, index) => {
                              if (item.title.toLowerCase()?.includes(searchTextFilter.toLowerCase())) {
                                return (
                                  <div
                                    className={`reportList reportListDiv ${
                                      selectedRelatedContentReportId === item.id ? "selectedReportListDiv" : null
                                    }`}
                                    key={item.id + Math.random()}
                                    id={`list${item.id}`}
                                    onClick={() => setSelectedRelatedContentReportId(item.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {selectedRelatedContentReportId === item.id && (
                                      <div style={{ float: "right", margin: "5px 5px 0px 0px" }}>
                                        <img src="/assets/red-circle-check.svg" />
                                      </div>
                                    )}
                                    <div className={"recent_content_card"}>
                                      <div className={"title_top"}>
                                        <p
                                          className={"itemTitle"}
                                          // onClick={() => handleDetailClick(item)}
                                        >
                                          {item.title}
                                        </p>
                                      </div>
                                      <CommonDateTagSection
                                        location={item.location}
                                        date={item.date}
                                        label={item.label}
                                        tags={item.industryTag}
                                        readTime={item.readTime}
                                        showReadTime={true}
                                        subType={item.subType}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}
                    </div>
                  </div>
                </div>
              </SidebarModal>
            </div>
          )}

          {openRelatedContentExternalSideBar && (
            <div>
              <SidebarModal
                isDeliverableRelatedReport={true}
                className="externalSidebarModal"
                childrenClassName="externalSideBarChildren"
                backDrop={true}
                style={{ width: "800px" }}
                header="Add Related Content for External Link"
                closeIcon={
                  <div
                    onClick={() => {
                      setOpenRelatedContentExternalSideBar(false);
                      formik.setFormikState({ ...formik, touched: {}, errors: {} });
                    }}
                  >
                    X
                  </div>
                }
                footer={
                  <div className="pt-2 pb-4">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                      onClick={() => {
                        setOpenRelatedContentExternalSideBar(false);
                        formik.setFormikState({ ...formik, touched: {}, errors: {} });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary ml-3 mr-3"
                      style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                      onClick={() => {
                        saveReportRelatedContent();
                      }}
                    >
                      Save
                    </button>
                  </div>
                }
              >
                <div style={{ marginLeft: "35px", marginRight: "35px" }}>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        placeholder="Title *"
                        name="deliverableSignalRelatedContentTitle"
                        id="deliverableSignalRelatedContentTitle"
                        {...props}
                        formik={formikRelatedContentExternal}
                        numberCount={true}
                        maxLength={125}
                        hideInitial={true}
                        characterLimitText={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        placeholder="Related Content Summary *"
                        name="deliverableSignalRelatedContentSummary"
                        id="deliverableSignalRelatedContentSummary"
                        {...props}
                        formik={formikRelatedContentExternal}
                        numberCount={true}
                        maxLength={200}
                        multiline={true}
                        rows={3}
                        hideInitial={true}
                        characterLimitText={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        placeholder="URL *"
                        name="deliverableSignalRelatedContentUrl"
                        id="deliverableSignalRelatedContentUrl"
                        {...props}
                        formik={formikRelatedContentExternal}
                        hideInitial={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DatePicker
                        properties={{
                          placeholder: "Publish Date *",
                          id: "deliverableSignalRelatedContentPublishedDate",
                          name: "deliverableSignalRelatedContentPublishedDate",
                          format: "dd MMM yyyy",
                          allowEdit: false,
                          floatLabelType: "Auto",
                          openOnFocus: true,
                          max: getDateFromMongoDate(new Date()),
                        }}
                        formik={formikRelatedContentExternal}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="form-group required">
                        <label style={style.label} htmlFor="deliverableCompany">
                          Upload Thumbnail{" "}
                        </label>
                        <div
                          className="image-holder"
                          style={{
                            padding: "unset",
                            width: "350px",
                            height: "200px",
                            background: "#cccccc",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            width="350px"
                            height="200px"
                            src={deliverableSignalRelatedContentImageSrcExternal || deliverableImage}
                            alt="Deliverable"
                            ref={externalLinkImageRefSideBar}
                          />

                          <div className="image-action newsThumbnail_fa_icons" style={{ top: "5px" }}>
                            <span
                              onClick={() => {
                                dispatch(
                                  showAlertBox({
                                    content: "Are you sure, you want to delete?",
                                    okCallback: async () => {
                                      dispatch(actionSuccess("Image deleted successfully"));
                                      setShowTrashSignal(false);
                                      setImageError("Please upload image");
                                      // setDeliverableImageSrc("");
                                      externalLinkImageRefSideBar.current.setAttribute("src", deliverableImage);
                                      externalDefaultImageRef.current.value = "";
                                    },
                                  })
                                );
                              }}
                            >
                              <i className={`fa fa-trash mr-1 ${!showTrashSignal ? "d-none" : undefined}`} aria-hidden="true" />
                            </span>
                            <span onClick={() => externalDefaultImageRef.current.click()}>
                              <i className="fa fa-pencil-alt" aria-hidden="true" />
                            </span>
                          </div>
                        </div>
                        <input
                          type="file"
                          className="d-none"
                          accept=".jpg, .png, .jpeg"
                          id="deliverableProfileImage"
                          name="deliverableProfileImage"
                          ref={externalDefaultImageRef}
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              if (
                                e.target.files[0].size > DELIVERABLE_THUMB_IMG_SIZE ||
                                !["image/png", "image/jpg", "image/jpeg"].includes(e.target.files[0].type)
                              ) {
                                setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                                return;
                              }
                              if (
                                (e.target.files[0].type == "image/png" ||
                                  e.target.files[0].type == "image/jpg" ||
                                  e.target.files[0].type == "image/jpeg") &&
                                e.target.files[0].size <= DELIVERABLE_THUMB_IMG_SIZE
                              ) {
                                //1000kb == 1000000 byte
                                readExternalLinkImage(e.target.files[0]);
                                externalLinkImageRefSideBar.current.setAttribute("src", e.target.files[0]);
                                setDeliverableSignalRelatedContentImageSrcExternal(URL.createObjectURL(e.target.files[0]));
                                setSelectedFileExternal(e.target.files[0]);
                                setImageError("");
                                setShowTrashSignal(true);
                                setIsDefaultImage(false);
                                formik.setErrors({
                                  ...formik.errors,
                                  deliverableSignalRelatedContentThumbnail: "",
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                        {imageError &&
                          imageError.split("while").map((e) => (
                            <small key={e.length}>
                              {e} <br />{" "}
                            </small>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <small>
                          <div className="col-md-12 ">
                            <span className="font-weight-bold">Note : </span>
                          </div>
                          <div className="col-md-12">
                            <li>Only JPG, JPEG, PNG files are allowed.</li>
                          </div>
                          <div className="col-md-12">
                            <li>Max file size allowed is 1MB.</li>
                          </div>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </SidebarModal>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default OverviewForm;
