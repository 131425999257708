// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Common date, label & tags section reused in most of the pages
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :11 Oct 2023
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import Moment from "react-moment";
import classes from "./CommonDateTagSection.module.css";

// pair of date and tags used in most of the components
const KeyPlayersTagSection = ({
  createdDate,
  updatedDate,
  date,
  label,
  tags,
  sgfFilters,
  location,
  readTime,
  showReadTime,
  ribbon,
  highlighting,
  subType,
  showSubType,
  keyplayers,
}) => {
  return (
    <div className={classes.recent_content_card} style={{ color: "black" }}>
      <div className={`${classes.date_summary_key_players} mobile_date_summary`}>
        <div className={classes.keyplayersDateCss}>
          {createdDate && createdDate?.length && keyplayers && (
            <span className={classes.card_cal}>
              Added on:&nbsp;<Moment format="DD MMM YYYY">{createdDate}</Moment>
            </span>
          )}

          {updatedDate && updatedDate?.length && keyplayers && (
            <span className={createdDate ? classes.summary_key_players : classes.card_cal}>
              Updated on:&nbsp;<Moment format="DD MMM YYYY">{updatedDate}</Moment>
            </span>
          )}
        </div>

        <div style={{ display: "flex" }}>
          {date && date?.length && keyplayers && (
            <span className={`${updatedDate ? classes.summary_key_players : classes.card_cal} ${classes.removeLeftBorder}`}>
              Published on:&nbsp;<Moment format="DD MMM YYYY">{date}</Moment>
            </span>
          )}

          {showReadTime && readTime ? (
            <span className={`${classes.read_time}`}>
              <img src="/assets/clock.svg" alt="clockIcon"></img>
              {readTime} min
            </span>
          ) : null}
          <span
            className={` ${
              !date && !updatedDate && !createdDate ? classes.summary_key_players_remove : classes.summary_key_players
            } summary_key_players ${!date && classes.removeLeftBorder}`}
            title={label}
          >
            {label}
          </span>
          {showSubType && subType && (
            <span className={`${classes.summary_key_players} summary_key_players`} title={subType}>
              {subType}
            </span>
          )}
        </div>
      </div>

      {tags && tags?.length > 0 ? (
        <div className={classes.des_icon}>
          <img src="/assets/tag.svg" alt="tag" />
          <ul>
            {tags?.map((tag, i) => {
              if (i < 3) {
                return <li key={tag.trendId + i + Math.random()}>{tag.trendName}</li>;
              }
            })}
          </ul>
        </div>
      ) : (
        <div className={classes.des_icon}></div>
      )}
    </div>
  );
};

export default KeyPlayersTagSection;
