/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  All Form elements will be use in making form 
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 11/Jun/2021
  Author						: Pankaj Kulshreshtha
================================================================
*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CheckBoxSelection, Inject, MultiSelectComponent, DropDownListComponent, AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import CKEditor from "ckeditor4-react";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { actionSuccess } from "../../../middleware/actions/utilityAction";
import { ckEditorConfig } from "../../../config/ckEditorConfig";
import "./css/custom.scss";
import "./css/toggle.scss";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { Tooltip } from "@material-ui/core";
import Select from "react-select";

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};

const WNsubscriptionType = [
  {
    label: "Mobility",
    value: "5b1e73b110ddc9e2482e832a",
  },
  {
    label: "Energy",
    value: "5b1e73b110ddc9e2482e832b",
  },
  {
    label: "Food & Nutrition",
    value: "5b1e73b110ddc9e2482e832c",
  },
];
const InsustryInsiderSubscriptionType = [
  {
    label: "Mobility",
    value: "Mobility",
  },
  {
    label: "Energy",
    value: "Energy",
  },
  {
    label: "Food & Nutrition",
    value: "Food & Nutrition",
  },
];
export const DatePicker = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <DatePickerComponent
          cssClass={"e-outline "}
          // floatLabelType="Auto"
          autoComplete="off"
          {...props.properties}
          {...props.formik.getFieldProps(name)}
          change={(e) => {
            e.value && props.formik.setFieldValue(name, new Date(e.value));
          }}
          strictMode={true}
        />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const TimePicker = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <TimePickerComponent
          cssClass={"e-outline"}
          // floatLabelType="Auto"
          autoComplete="off"
          {...props.properties}
          {...props.formik.getFieldProps(name)}
        />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const NumericTextField = (props) => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef();
  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };

  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
    props.multiline && document.getElementsByTagName("textarea")[0].setAttribute("rows", props.rows || 0);
  }, [props.formik.values, props.name, props.rows]);
  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        {props.inputGroupText && (
          <div className="e-float-icon-left">
            {/* <span className="input-group-text mb-1 border-0">{props.inputGroupText}</span> */}

            <NumericTextBoxComponent
              // className="form-control"
              ref={textAreaRef}
              {...props.formik.getFieldProps(...props.name)}
              autoComplete="off"
              value={value}
              showClearButton={true}
              {...props}
              // enablePersistence={true}
              cssClass={"customCss e-outline"}
              floatLabelType="Auto"
              input={props.numberCount && onInput}
            />
          </div>
        )}
        {!props.inputGroupText && (
          <NumericTextBoxComponent
            // className="form-control"
            {...props.formik.getFieldProps(...props.name)}
            autoComplete="off"
            value={value}
            showClearButton={true}
            {...props}
            // enablePersistence={true}
            cssClass={"customCss e-outline"}
            floatLabelType="Auto"
            input={props.numberCount && onInput}
          />
        )}
        {props.numberCount && <span className="float-right" id={`${props.id}Count`}>{`${value.length || 0}/${props.maxLength}`}</span>}
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[props.name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const TextField = (props) => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef();
  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };

  useEffect(() => {
    let name = props.name;
    name && setValue(props.formik.values[name]);
    props.multiline && document.getElementsByTagName("textarea")[0].setAttribute("rows", props.rows || 0);
  }, [props.formik.values, props.name, props.rows]);
  const displayError = () => {
    let error = false;
    if (props.formik.touched[props.name] === true) error = true;
    if (props.formik.errors[props.name] && props.formik.values[props.name] && !props.hideInitial) {
      error = true;
    } else if (!props.formik.errors[props.name] && props.hideInitial) {
      error = false;
    }

    return error;
  };
  return (
    <>
      <div className={`form-group ${props?.required || ""}`}>
        {props.inputGroupText && (
          <div className="e-float-icon-left">
            {/* <span className="input-group-text mb-1 border-0">{props.inputGroupText}</span> */}

            <TextBoxComponent
              // className="form-control"
              ref={textAreaRef}
              {...props.formik.getFieldProps(...props.name)}
              autoComplete="off"
              value={value && value.trim()}
              showClearButton={true}
              {...props}
              // enablePersistence={true}
              cssClass={"customCss e-outline"}
              floatLabelType="Auto"
              input={props.numberCount && onInput}
            />
          </div>
        )}
        {!props.inputGroupText && (
          <TextBoxComponent
            // className="form-control"
            {...props.formik.getFieldProps(...props.name)}
            autoComplete="off"
            value={value === undefined ? "" : value}
            showClearButton={true}
            {...props}
            // enablePersistence={true}
            cssClass={"customCss e-outline"}
            floatLabelType="Auto"
            input={props.numberCount && onInput}
          />
        )}
        {props.numberCount && (
          <span style={{ fontSize: "14px" }} className="float-right" id={`${props.id}Count`}>{`${(value && value.length) || 0}/${
            props.maxLength
          }`}</span>
        )}

        {props.characterLimitText && (
          <strong className="float-right" id={`${props.id}CharacterCount`} style={{ fontSize: "14px" }}>
            Character limit:&nbsp;
          </strong>
        )}

        <div
          className="text-danger"
          style={{
            display: displayError() ? "block" : "none",
          }}
          role="alert"
        >
          <small>{displayError() ? props.formik.errors[props.name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

export const Checkbox = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;

  // delete props.properties.handleBlur;

  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [props.formik.values, name, checked]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <input
          // className="form-control"
          // cssClass={'e-outline'}
          autoComplete="off"
          {...props.properties}
          {...props.formik.getFieldProps(name)}
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
          }}
        />
        <label>&nbsp;{label} </label>
        {
          <div
            className="text-danger"
            style={{
              display: props.formik.touched[name] === true ? "block" : "none",
            }}
            role="alert"
          >
            <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
          </div>
        }
      </div>
    </>
  );
};
export const RadioGroup = (props) => {
  const { name, label } = props.properties;

  useEffect(() => {
    // props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 ? props.formik.setFieldValue(name, "Client User", true):
    // props.formik.setFieldValue(name, props.formik.values[name], true) ;
  }, [props.formik.values, name, props.formik.values.clientUsers]);

  return (
    <>
      {delete props.properties.label}
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <div className="row">
          <label className="pl-3 pt-2">{label}</label>

          {props.type === "radio" &&
            props.optionArr &&
            props.optionArr.map((v, i) => {
              return (
                <React.Fragment key={`div${name}_${i}`}>
                  <div key={`div${name}_${i}`} className="col-md-1">
                    <RadioButtonComponent
                      key={`${name}_${i}`}
                      type="radio"
                      className="form-control radio"
                      checked={
                        props.formik.values[name] === v
                        // || (props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 && v === "Client User")
                      }
                      onClick={(e) => {
                        if (e.target.checked) {
                          const s = e.target.id.split("_");
                          props.formik.setFieldValue(name, props.optionArr[s[1]], true);
                        }
                      }}
                      id={`${name}_${i}`}
                      {...props.properties}
                      // {...props.formik.getFieldProps(name)}
                    />
                  </div>
                  <span className="pt-2">{v} </span>
                </React.Fragment>
              );
            })}
        </div>
        {
          <div
            className="text-danger"
            style={{
              display: props.formik.touched[name] === true ? "block" : "none",
            }}
            role="alert"
          >
            <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
          </div>
        }
      </div>
    </>
  );
};

export const TextArea = (props) => {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(props.maxLength);
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
    props.formik.values[name] && setCount(props.maxLength - props.formik.values[name].length);
  }, [props.formik.values, name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <label>{label} </label>
        <textarea
          className="form-control"
          autoComplete="off"
          value={value}
          {...props.properties}
          {...props.formik.getFieldProps(name)}
          onKeyUp={(e) => {
            document.getElementById(name + "_").innerHTML = props.maxLength - e.target.value.length;
          }}
        />
        <b
          style={{
            fontWeight: 500,
            display: props.showCharCount ? "" : "none",
          }}
          htmlFor="objective"
        >
          Characters left:&nbsp;
        </b>
        <span id={name + "_"}>{count}</span>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const MultiSelect = (props) => {
  const [value, setValue] = useState("");
  const ref = useRef();
  const { name } = props;

  useEffect(() => {
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <>
      <div className="form-group required">
        <MultiSelectComponent
          // className="form-control e-customCss"
          autoComplete="off"
          // {...props.formik.getFieldProps(...props.name)}
          // value={value}
          value={props.formik.values[props.name]}
          // dataSource={...props.options}
          cssClass={"customCss e-outline"}
          // enablePersistence={true}
          // showClearButton={true}
          // cssClass={'e-outline'} floatLabelType="Auto"

          change={(e) => {
            props.formik.setFieldValue(name, e.value);
            setValue(e.value);
            if (e.value) {
              props.formik.setFieldError(name, "");
            }
          }}
          // enableRtl={true}
          floatLabelType="Auto"
          {...props}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const MultiSelectNew = (props) => {
  const [value, setValue] = useState("");
  const { name } = props;
  useEffect(() => {
    props.formik.values[name] && props.formik.values[name].label === "" && setValue(props.formik.values[name]);
  }, [name]);

  return (
    <>
      <div className="form-group required">
        <MultiSelectComponent
          // className="form-control e-customCss"
          autoComplete="off"
          // {...props.formik.getFieldProps(...props.name)}
          // dataSource={...props.options}
          cssClass={"customCss e-outline cy-multiSelect"}
          //   value={value}
          {...props}
          // maximumSelectionLength={1}
          // enablePersistence={true}
          // showClearButton={true}
          // cssClass={'e-outline'} floatLabelType="Auto"
          select={(args) => {
            args.itemData && args.itemData.label !== "" && args.itemData.label !== args.itemData.value && setValue(args.itemData.label);
            args.itemData && args.itemData.label !== "" && props.formik.setFieldValue(name, [...props.formik.values[name], args.itemData]);
          }}
          tagging={(args) => {
            let val = args.itemData;
            if (val.label === "") props.formik.values[name] = [];
          }}
          removing={(args) => {
            let filter = props.formik.values[name].filter((element) => {
              return element.label != args.itemData.label;
            });
            props.formik.values[name] = filter;
          }}
          change={(e) => {
            setValue(e.value);
            if (e.value) {
              props.formik.setFieldError(name, "");
            }
          }}
          // enableRtl={true}
          floatLabelType="Auto"
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const MultiSelectWithAutoSuggest = (props) => {
  const [value, setValue] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const { name } = props;
  useEffect(() => {
    props.formik.values[name] && props.formik.values[name].label === "" && setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  useEffect(() => {
    props.dataSource && props.dataSource.length > 0 && setDataSource(props.dataSource);
  }, [props.dataSource]);

  const handleChange = (e) => {
    props.formik.setFieldValue(name, _.uniqBy([...props.formik.values[name], ...props.dataSource.filter((f) => e.value.includes(f.value))], "value"));
    const formikVal = props.formik.values[name] && props.formik.values[name].length > 0 && props.formik.values[name].map((v) => v.label);
    if (formikVal && formikVal.length > 0) {
      const filterValueWithoutIds =
        e.value &&
        e.value.length > 0 &&
        e.value
          .filter((v) => !formikVal.includes(v))
          .map((v) => {
            return { label: v, value: v };
          });
      props.formik.setFieldValue(name, [...props.formik.values[name], ...filterValueWithoutIds]);
    }
    setValue(e.value);
    if (e.value) {
      props.formik.setFieldError(name, "");
    }
  };
  const handleBeforeOpen = () => {
    const selectedLabels = props.formik.values[name] && props.formik.values[name].length > 0 && props.formik.values[name].map((v) => v.label);
    if (selectedLabels && selectedLabels.length > 0) {
      const newData = dataSource
        .map(
          (v) =>
            (selectedLabels.includes(v.label) && {
              ...v,
              isDisabled: true,
            }) || { ...v, isDisabled: false }
        )
        .filter((f) => !f.isDisabled);
      setDataSource(newData);
    }
  };

  return (
    <>
      <div className="form-group required">
        <MultiSelectComponent
          autoComplete="off"
          value={value}
          cssClass={"customCss e-outline"}
          {...props}
          dataSource={dataSource}
          beforeOpen={handleBeforeOpen}
          tagging={(args) => {
            let val = args.itemData;
            if (val.label === "") props.formik.values[name] = [];
          }}
          removing={(args) => {
            let filter = props.formik.values[name].filter((element) => {
              return element.label != args.itemData.label;
            });
            props.formik.values[name] = filter;
          }}
          change={handleChange}
          floatLabelType="Auto"
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

export const Dropdown = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <DropDownListComponent
          // {...props.formik.getFieldProps(...props.name)}
          value={props.formik.values[props.name]}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.value);
          }}
          filtering={(inputValue) => {
            inputValue && props.handleSearch(inputValue.text);
          }}
          {...props}
          // dataSource={dataSource}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
          </small>
        </div>
      </div>
    </>
  );
};
export const MemoziedDropdown = React.memo(Dropdown);

export const ColorDropdown = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    let name = props.name;
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <DropDownListComponent
          // {...props.formik.getFieldProps(...props.name)}
          value={props.formik.values[props.name]}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.value);
          }}
          filtering={(inputValue) => {
            inputValue && props.handleSearch(inputValue.text);
          }}
          {...props}
          // dataSource={colorData}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
          itemTemplate={(item) => {
            return <span style={{ backgroundColor: item.value, padding: "10px 120px" }} />;
          }}
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
          </small>
        </div>
      </div>
    </>
  );
};
export const MemoziedColorDropdown = React.memo(ColorDropdown);

export const ProductVariantSelect = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    const productVariant = props.formik.values.productVariant;
    if (productVariant) {
      setValue(productVariant);
      props.subscriptionProduct(productVariant);
      props.fetchValidity(productVariant);
    }
  }, [props.formik.values.productVariant, props.isActiveSubscription]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <DropDownListComponent
          // {...props.formik.getFieldProps(...props.name)}
          value={value}
          change={(selectedOption) => {
            if (selectedOption && selectedOption.value) {
              props.isActiveSubscriptionExist(selectedOption.value);
              props.subscriptionProduct(selectedOption.value);
              props.fetchValidity(selectedOption.value);
              props.formik.setFieldValue("productVariant", selectedOption.value);
            }
          }}
          filtering={(inputValue) => {
            inputValue && props.handleSearch(inputValue.text);
          }}
          {...props}
          // dataSource={dataSource}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched["productVariant"] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched["productVariant"] === true ? props.formik.errors["productVariant"] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const MemoziedProductVariantSelect = React.memo(ProductVariantSelect);

export const DropdownNew = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <DropDownListComponent
          value={value}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.itemData);
          }}
          filtering={(inputValue) => {
            inputValue && props.handleSearch(inputValue.text);
          }}
          {...props}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
          </small>
        </div>
      </div>
    </>
  );
};
export const MemoziedDropdownNew = React.memo(DropdownNew);
export const AutoCompleteDropdown = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <AutoCompleteComponent
          // {...props.formik.getFieldProps(...props.name)}
          // value={value}
          value={props.formik.values[props.name]}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.value);
          }}
          // filtering={(inputValue) => { inputValue && props.handleSearch(inputValue.text); }}
          popupHeight="250px"
          highlight={true}
          {...props}
          // dataSource={dataSource}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
          </small>
        </div>
      </div>
    </>
  );
};

export const CountrySelect = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  const noDataTemplate = (e) => {
    return <span className="norecord">{props.noData}</span>;
  };

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <AutoCompleteComponent
          // {...props.formik.getFieldProps(...props.name)}
          // value={value}
          value={props.formik.values[props.name]}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.value);
          }}
          noRecordsTemplate={noDataTemplate}
          // filtering={(inputValue) => { inputValue && props.handleSearch(inputValue.text); }}
          popupHeight="250px"
          highlight={true}
          {...props}
          // dataSource={dataSource}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          // isClearable
        />
        <div
          className="text-danger"
          style={{
            display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
          </small>
        </div>
      </div>
    </>
  );
};

export const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;
  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [name, props.formik.values]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""} row`}>
        <label className="col-md-5 mt-3">{label} </label>
        <div className="col-md-7">
          <label className="custom-switch2">
            <input
              type="checkbox"
              {...props.properties}
              checked={checked ? true : false}
              onChange={(e) => {
                setChecked(e.target.checked);
                props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
              }}
            />
            <div>
              <span className="on">Yes</span>
              <span className="off">No</span>
            </div>
            <i></i>
          </label>
        </div>

        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
export const ToggleSwitchNew = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;
  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [name, props.formik.values]);

  return (
    <>
      <div className={` form-group ${(props.required && props.required) || ""}`}>
        <div className="mt-1 row">
          <div className="mt-1 pl-3">{label}</div>
          <div className="cy-savetemplate_button col-md-4 pl-1">
            <label className="custom-switch3">
              <input
                type="checkbox"
                {...props.properties}
                checked={checked ? true : false}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
                }}
              />
              <div>
                <span className="on">Yes</span>
                <span className="off">No</span>
              </div>
              <i></i>
            </label>
          </div>
        </div>

        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

// Form Input field component for CKEditor
export const TextEditor = (props) => {
  const [values, setFormik] = useState(props.formik.values);
  const [content, setContent] = useState("");
  const { name, label } = props.properties;
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[name] = newContent;
    setFormik({ ...values, ...changedData });
    props.formik.setFieldValue(name, newContent, true);
  };

  useEffect(() => {
    setContent(props.formik.values[name] ? props.formik.values[name] : props?.defaultContent || "");
  }, [props.formik.values[name]]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`}>
        <label>{label} </label>
        <CKEditor
          activeClass="editor"
          data={content && content}
          onChange={onChange}
          // events={{
          // 	"change": onChange

          // }}
          config={{ ...ckEditorConfig }}
          {...props.properties}
        />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[props.properties.name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

export const InputGroupText = (props) => {
  const [value, setValue] = useState("");

  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);
  return (
    <>
      <div className={` ${(props.required && props.required) || ""}`}>
        <div className="e-float-input e-input-group" style={{ top: "-15px", borderBottom: 0 }}>
          <span
            className="e-input-group-text p-2"
            style={{
              border: "1px solid darkgrey",
              width: "340px",
              height: "40px",
              fontSize: "14px",
            }}
          >
            {props.inputGroupText}
          </span>
          {/* <input required type="text" /> */}
          <TextBoxComponent
            // className="form-control"
            {...props.formik.getFieldProps(...props.name)}
            autoComplete="off"
            value={value}
            {...props}
            // enablePersistence={true}
            showClearButton={true}
            cssClass={"customCss e-outline"}
            floatLabelType="Auto"
            input={props.numberCount && onInput}
          />
          {/* <span class="e-float-line"></span> */}
          {/* <label class="e-float-text"> {props.placeholder} </label> */}
        </div>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[props.name] === true ? "block" : "none",
            left: "220px",
            top: "-18px",
            position: "relative",
          }}
          role="alert"
        >
          <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

export const MultiSelectForContentTags = (props) => {
  const [value, setValue] = useState("");
  const [execute, setExecute] = useState("");

  const ref = useRef();
  const { name } = props;
  let mulObj;
  const dispatch = useDispatch();

  useEffect(() => {
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  const removed = (e) => {
    if (execute) {
    }
  };

  return (
    <>
      <div className="form-group required">
        <MultiSelectComponent
          ref={(scope) => {
            mulObj = scope;
          }}
          // className="form-control e-customCss"
          autoComplete="off"
          // {...props.formik.getFieldProps(...props.name)}
          value={props.formik.values[props.name]}
          // dataSource={...props.options}
          cssClass={"customCss e-outline"}
          {...props}
          // enablePersistence={true}
          // showClearButton={true}
          // cssClass={'e-outline'} floatLabelType="Auto"

          change={(e) => {
            if (e && e.e && e.oldValue?.length > e.value?.length) {
              dispatch(
                showAlertBox({
                  content: "Do you want to remove tag?",
                  okText: "Remove",
                  cancelText: "Cancel",
                  title: "dialogAlertCssWarning",
                  okCallback: async () => {
                    console.log("ok callback:", e.value);

                    props.formik.setFieldValue(name, e.value);
                    setValue(e.value);
                    if (e.value) {
                      props.formik.setFieldError(name, "");
                    }
                    dispatch(actionSuccess("Tag removed"));
                  },
                  cancelCallback: async () => {
                    setExecute(execute ? false : true);
                    console.log("cancel callback:", e.oldValue);
                    props.formik.setFieldValue(name, e.oldValue);
                    setValue(e.oldValue);
                    if (e.oldValue) {
                      props.formik.setFieldError(name, "");
                    }

                    dispatch(actionSuccess("Tag restored"));
                  },
                })
              );
            } else {
              props.formik.setFieldValue(name, e.value);
              setValue(e.value);
              if (e.value) {
                props.formik.setFieldError(name, "");
              }
            }
          }}
          changeOnBlur={false}
          removed={(e) => removed(e)}
          // enableRtl={true}
          floatLabelType="Auto"
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

const SelectContainer = ({ children, ...props }) => {
  return (
    <>
      {(props?.isFocused || props?.hasValue) && (
        <label className={`react-multi-select-label ${props?.isFocused && "focused"}`}>{props?.selectProps?.placeholder}</label>
      )}
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
    </>
  );
};

const MultiValueContainer = ({ children, ...props }) => {
  return (
    <Tooltip title={props?.data?.label} enterDelay={300}>
      <span className="custom-multivalue">
        <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
      </span>
    </Tooltip>
  );
};

// * AsyncSelect react-select multiselect component
export const AsyncMultiSelect = (props) => {
  const { name, className, placeholder, filtering, noRecordsTemplate, formik } = props;
  const multiSelectRef = useRef();
  const debounceRef = useRef();

  const handleChange = (selectedOption) => {
    selectedOption ? formik.setFieldValue(name, selectedOption) : formik.setFieldValue(name, []);
  };

  const loadOptions = (inputValue, callback) => {
    // Clear the previous timeout if it exists
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    // Set a new debounce timeout
    debounceRef.current = setTimeout(async () => {
      const results = await filtering(inputValue);
      callback(results);
    }, 250); // 250ms delay
  };

  return (
    <div className="custom-react-select-container">
      <AsyncSelect
        value={formik.values[name] ?? []}
        ref={multiSelectRef}
        isMulti
        name={name}
        className={className}
        classNamePrefix="react-multi-select"
        isSearchable
        onChange={handleChange}
        placeholder={placeholder}
        loadOptions={loadOptions ?? []}
        noOptionsMessage={(record) => noRecordsTemplate(record, multiSelectRef?.current?.select?.state?.inputValue)}
        components={{ SelectContainer, MultiValueContainer }}
        styles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: "38px",
            boxShadow: state.isFocused ? "0 0 0 1px #f94f5e" : 0,
            borderColor: state.isFocused ? "#f94f5e" : "rgba(0, 0, 0, 0.24)",
            "&:hover": {
              borderColor: state.isFocused ? "#f94f5e" : "#000000",
            },
          }),
          placeholder: (provided, state) => ({
            ...provided,
            display: state.isFocused ? "none" : "block",
            color: "rgba(0, 0, 0, 0.35)",
            fontSize: "14px",
            fontWeight: "400",
            paddingLeft: "6px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(230,230,230)" : "#fff",
            "&:hover": {
              backgroundColor: "rgb(230,230,230)",
            },
          }),
          multiValue: (provided) => ({
            ...provided,
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif",
            zIndex: 2,
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            "&:hover": {
              backgroundColor: "rgb(230,230,230)",
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            width: "100%",
            padding: "5px 0px 4px 4px",
          }),
          loadingIndicator: (provided) => ({
            ...provided,
            display: "none", // Hide the loading indicator
          }),
        }}
      />
    </div>
  );
};

// * AsyncSelect react-select multiselect component
export const ReactSelectMultiSelect = (props) => {
  const { name, className, placeholder, filtering, noRecordsTemplate, formik, dataSource, isDisabled } = props;
  const multiSelectRef = useRef();
  const debounceRef = useRef();
  let selectValue = [];

  if (formik.values[name]) {
    selectValue = formik.values[name].map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }

  const handleChange = (selectedOption) => {
    selectedOption?.length
      ? formik.setFieldValue(
          name,
          selectedOption.map((item) => item.value)
        )
      : formik.setFieldValue(name, []);
  };

  const loadOptions = (inputValue, callback) => {
    // Clear the previous timeout if it exists
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    // Set a new debounce timeout
    debounceRef.current = setTimeout(async () => {
      const results = await filtering(inputValue);
      callback(results);
    }, 250); // 250ms delay
  };

  return (
    <div className="custom-react-select-container">
      <Select
        value={selectValue}
        ref={multiSelectRef}
        isMulti
        options={dataSource}
        name={name}
        className={className}
        classNamePrefix="react-multi-select"
        isSearchable
        onChange={handleChange}
        placeholder={placeholder}
        loadOptions={loadOptions ?? []}
        // noOptionsMessage={(record) => noRecordsTemplate(record, multiSelectRef?.current?.select?.state?.inputValue)}
        components={{ SelectContainer, MultiValueContainer }}
        styles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          control: (provided, state) => ({
            ...provided,
            height: "40px",
            boxShadow: state.isFocused ? "0 0 0 1px #f94f5e" : 0,
            borderColor: isDisabled ? "#dbdbdd" : state.isFocused ? "#f94f5e" : "rgba(0, 0, 0, 0.24)",
            "&:hover": {
              borderColor: state.isFocused ? "#f94f5e" : "#000000",
            },
            cursor: isDisabled ? "not-allowed" : "default",
            // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
            backgroundColor: isDisabled ? "transparent" : "transparent",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            display: state.isFocused ? "none" : "block",
            color: "rgba(0, 0, 0, 0.35)",
            fontSize: "14px",
            fontWeight: "400",
            paddingLeft: "6px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(230,230,230)" : "#fff",
            "&:hover": {
              backgroundColor: "rgb(230,230,230)",
            },
            cursor: isDisabled ? 'not-allowed' : 'default',
          }),
          multiValue: (provided) => ({
            ...provided,
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif",
            zIndex: 2,
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            "&:hover": {
              backgroundColor: "rgb(230,230,230)",
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            width: "100%",
            padding: "5px 0px 4px 4px",
          }),
          loadingIndicator: (provided) => ({
            ...provided,
            display: "none", // Hide the loading indicator
          }),
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
