// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Common date structure component for all pages
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created : 05 Oct 2023
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from 'react';
import Moment from 'react-moment';

import classes from './CommonDate.module.css';

// common date format used in every page
const CommonDate = (props) => {
  return (
    <div>
      <p className={`${classes.card_cal} ${props.className}`}>
        {/* <span>
          <img className={classes.max_img} src="/images/cal.png" alt="date"></img>
        </span> */}
        <Moment format="DD MMM YYYY">{props.date}</Moment>
      </p>
    </div>
  );
};

export default CommonDate;
