import React from "react";

export default function FileThumbnail({ fileType }) {
  return (
    <>
      {fileType === "xlsx" && <img src="/assets/pngs/empty-excel.png" style={{ height: "20px", width: "20px" }} />}
      {fileType === "docx" && <img src="/assets/pngs/DOC-file-icon.png" style={{ height: "20px", width: "20px" }} />}
      {fileType === "pdf" && <img src="/assets/pngs/PDF-file-icon.png" style={{ height: "20px", width: "20px" }} />}
      {(fileType === "ppt" || fileType === "pptx") && <img src="/assets/pngs/PPT-file-icon.png" style={{ height: "20px", width: "20px" }} />}
      {fileType?.toLowerCase() === "jpg" ||
        fileType?.toLowerCase() === "jpeg" ||
        (fileType?.toLowerCase() === "png" && <img src="/assets/image-icon.svg" style={{ height: "20px", width: "20px" }} />)}

      {fileType?.toLowerCase() === "mp4" && <img src="/assets/pngs/MP4-file-icon.png" style={{ height: "20px", width: "20px" }} />}
    </>
  );
}
