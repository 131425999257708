/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : React Entry Point index.js
----------------------------------------------------------------
	Creation Details
	Date Created				: 25/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Loader from "Loader";
import * as serviceWorker from "./utilities/serviceWorker";
import { registerLicense } from "@syncfusion/ej2-base";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-widgets/dist/css/react-widgets.css";
import "./assets/styles/index.scss";
import "./assets/styles/type.scss";
import "./assets/styles/toggle.scss";

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      retry: 1,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<Loader />}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
