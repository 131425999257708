import React, { useState, useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useFormik } from "formik";
import Accordion from "../../../../components/accordion";
import { TextEditor } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { fetchProductBasicById } from "../../../../../middleware/services/productApi";
import { getProductVariant } from "../../../../../utilities";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { setValuePreposition } from "../../../../../middleware/actions/productAction";
import { actionError } from "../../../../../middleware/actions/utilityAction";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

//Functional component for ckEditor
const ckeditorFunctional = (props) => {
  return (
    <Accordion
      heading={props.properties.config.editorplaceholder}
      stepCompleted={props.formik.values[props.properties.name] ? true : false}
      step={props.properties.step}
    >
      <div className="row p-3">
        {/* <div className="col-md-12 p-2 border bg-secondary text-white rounded-top mt-sm-3">{props.properties.label}</div> */}
        <div className="col-md-12 ">
          <div className="row">
            <div className="col-lg-12 nopadding">
              <TextEditor {...props} />
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const ValueProposition = (props) => {
  const { onUpdateAdvancedData } = props;
  const history = useHistory();
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [editData, setEditData] = useState({});
  const [saveBtnText, setSaveBtnText] = useState(props.editData ? "Save" : "Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [productId, setProductId] = useState(null);
  const [backLink, setBackLink] = useState(
    props.editData
      ? props.companyId
        ? {
            path: `/company/${props.companyId}/edit#product`,
            data: { companyId: props.companyId },
          }
        : { path: `/product`, data: { companyId: undefined } }
      : undefined
  );

  /* Common access */
  const accessActionSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const commonState = useSelector((state) => state.productState);
  const valuePrepositionDetails = commonState.valuePreposition;
  const productDetails = commonState.productDetails;
  const productImage = commonState.productImage;
  const productVariant = getProductVariant();

  const initialFormValues = {
    timeReduction: "",
    costReduction: "",
    increasesProductivity: "",
    increasesEficiency: "",
    revenueGenration: "",
    technicalBenefits: "",
    enhancesAesthetics: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: false,
    // validationSchema: Yup.object().shape(valuePropValidationSchema)
  });
  const setFormikValues = async (response) => {
    let result = response.data;
    if (Object.keys(valuePrepositionDetails).length > 0) {
      result = valuePrepositionDetails;
    }
    const old_data = {
      timeReduction: result.timeReduction ? result.timeReduction : result.productValuePropositionTime || "",
      costReduction: result.costReduction ? result.costReduction : result.productValuePropositionCost || "",
      increasesProductivity: result.increasesProductivity ? result.increasesProductivity : result.productValuePropositionProductivity || "",
      increasesEficiency: result.increasesEficiency ? result.increasesEficiency : result.productValuePropositionEfficiency || "",
      revenueGenration: result.revenueGenration ? result.revenueGenration : result.productValuePropositionGeneration || "",
      technicalBenefits: result.technicalBenefits ? result.technicalBenefits : result.productValuePropositionBenefits || "",
      enhancesAesthetics: result.enhancesAesthetics ? result.enhancesAesthetics : result.productValuePropositionAesthetics || "",
    };
    setOldData(old_data);
    formik.setValues(old_data);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (clickSaveSubmit) handleSubmit();
    if (props.productId && props.productId.length === 24) setProductId(props.productId);
    dispatch(setSideNavForcedActiveLink("/product"));
    let isMounted = false;
    if (props.editData && props.productId !== "") {
      if (Object.keys(valuePrepositionDetails).length > 0) {
        setEditData(valuePrepositionDetails);
        setFormikValues(valuePrepositionDetails);
      } else {
        const response = fetchProductBasicById(props.productId);
        response
          .then((response2) => {
            if (response2.status === 200) {
              setLoading(false);
              if (!isMounted) {
                setEditData(response2.data);
                setFormikValues(response2.data);
              }
            } else {
              setLoading(false);
              setAlertMessage("Something Went Wrong");
              throw Object.assign(new Error("SOMETHING WENT WRONG"), {
                code: 500,
              });
            }
          })
          .catch((e) => {
            setLoading(false);
            setAlertMessage("Something Went Wrong while processing.");
          });
      }
    } else {
      formik.resetForm();
    }
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [props.companyId, props.editData, clickSaveSubmit]);

  const handleReset = () => {
    formik.resetForm();
    if (props.editData) {
      formik.setValues(oldData);
    }
  };
  /*hanlde save function */
  const saveFormData = async () => {
    const { values } = formik;
    const valuePropositionData = {
      productName: productDetails.productName.trim(),
      productLaunchYear: productDetails.yearLaunched,
      productLicensingAvailability: productDetails.licencingAvailablity.trim(),
      productTechnologyMaturityLevel: productDetails.techMaturityLevel,
      productDomainClassification: productDetails.domainClassification.trim(),
      productUrl: productDetails.productSourceURL.trim(),
      productDescription: productDetails.productDescription,
      productWhatsUnique: productDetails.uniqueProduct !== undefined ? productDetails.uniqueProduct : "",
      productFunctionality: productDetails.functionalitiesProduct !== undefined ? productDetails.functionalitiesProduct : "",
      productObjective: productDetails.objectiveProduct,
      productValuePropositionTime: values.timeReduction,
      productValuePropositionCost: values.costReduction,
      productValuePropositionProductivity: values.increasesProductivity,
      productValuePropositionEfficiency: values.increasesEficiency,
      productValuePropositionGeneration: values.revenueGenration,
      productValuePropositionBenefits: values.technicalBenefits,
      productValuePropositionAesthetics: values.enhancesAesthetics,
    };
    if (productImage != "") valuePropositionData.productProfileImage = productImage.productImgFile;
    let productPaywallProductPlatform = productVariant == "Cognition" ? "Signal V2" : `${productVariant} V2`;
    let productPaywallSettingData =
      productDetails?.productPaywallSetting == "YES"
        ? JSON.stringify([
            {
              productVariant: productVariant,
              productPlatform: productPaywallProductPlatform,
              taxonomyTagging: [],
            },
          ])
        : JSON.stringify([]);
    valuePropositionData.productPaywallSetting = productPaywallSettingData;
    if (props.editData) {
      valuePropositionData.id = productId;
    }
    if (productDetails.productName === "" || productDetails.productDescription === "") {
      productDetails.productName === "" && dispatch(actionError("Please enter product name"));
      productDetails.productDescription === "" && dispatch(actionError("Please enter product description"));
    } else {
      if (props.editData) {
        const response = onUpdateAdvancedData(valuePropositionData);
        setSaveBtnText("SAVING...");
        setBtnDisabled(true);
        response.then((result) => {
          if (props.editData) setSaveBtnText("Save");
          setBtnDisabled(false);
          if (result.status === "success") {
            if (clickSaveSubmit) {
              dispatch(
                showAlertBox({
                  okCallback: async () => {
                    props.onSaveProcessingData("value_proposition");
                  },
                  okText: "Submit",
                  cancelText: "Cancel",
                  content: "Are you sure you want to submit ?",
                  title: "dialogAlertCss",
                })
              );
              setClickSaveSubmit(false);
            }
            if (!props.editData) handleReset();
          }
        });
      }
    }
  };

  /*hanlde submit function */
  const handleSubmit = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveFormData();
      }
    });
  };
  useEffect(() => {
    const options = {
      // damping:0.1,
      thumbMinSize: 20,
      alwaysShowTracks: true,
    };
    // setTimeout(() => {
    let classExist = document.querySelector("#product-value-proposition");
    classExist && Scrollbar.init(document.querySelector("#product-value-proposition"), options);

    // }, 2000);
  }, []);

  /*To save data in redux */
  useEffect(() => {
    dispatch(setValuePreposition(formik.values));
  }, [formik.values]);
  return (
    <>
      {/* <FormToolbar /> */}
      <div id="product-value-proposition" className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
        <div className="mx-2">
          {/* - TIME REDUCTION */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "timeReduction",
              name: "timeReduction",
              activeClass: "timeReduction",
              // label: "Time Reduction",
              step: 1,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Time Reduction",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - TIME REDUCTION END */}
          {/* - COST REDUCTION */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "costReduction",
              name: "costReduction",
              activeClass: "costReduction",
              // label: "Cost Reduction",
              step: 2,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Cost Reduction",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - COST REDUCTION END */}
          {/* - INCREASES PRODUCTIVITY */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "increasesProductivity",
              name: "increasesProductivity",
              activeClass: "increasesProductivity",
              // label: "Increases Productivity",
              step: 3,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Increases Productivity",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - INCREASES PRODUCTIVITY END */}
          {/* - INCREASES EFICIENCY */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "increasesEficiency",
              name: "increasesEficiency",
              activeClass: "increasesEficiency",
              // label: "Increases Efficiency",
              step: 4,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Increases Efficiency",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - INCREASES EFICIENCY END */}
          {/* - REVENUE GENERATION */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "revenueGenration",
              name: "revenueGenration",
              activeClass: "revenueGenration",
              // label: "Revenue Generation",
              step: 5,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Revenue Generation",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - REVENUE GENERATION END */}
          {/* - TEECHNICAL BENEFITS */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "technicalBenefits",
              name: "technicalBenefits",
              activeClass: "technicalBenefits",
              // label: "Technical Benefits",
              step: 6,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Technical Benefits",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - TEECHNICAL BENEFITS END */}
          {/* - ENHANCES AESTHETICS */}
          {ckeditorFunctional({
            formik: formik,
            properties: {
              id: "enhancesAesthetics",
              name: "enhancesAesthetics",
              activeClass: "enhancesAesthetics",
              // label: "Enhances Aesthetics",
              step: 7,
              config: {
                ...ckEditorMinimalConfig,
                editorplaceholder: "Enhances Aesthetics",
                height: props.productId ? 250 : 180,
              },
            },
          })}
          {/* - ENHANCES AESTHETICS END */}
        </div>
      </div>
      {/* -- Footer Button box -*/}
      <div className="form-submit-box">
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-LEFT col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ marginLeft: "-3em" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="cy-valPro btn btn-outline-dark pl-4 pr-4 cy-btn "
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}

              {props.selectedIndex > -1 ? (
                <span className="pl-4">
                  <span className="span-link">
                    <button
                      type="button"
                      className="cy-valPro-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                      }}
                    >
                      Next
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right">
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(props.backPath, { companyId: props.companyId });
                }}
              >
                Cancel
              </button>
              {accessActionSave ? (
                <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 mr-3" onClick={handleSubmit} disabled={btnDisabled}>
                  {props.editData ? "Save" : saveBtnText}
                </button>
              ) : null}
              {accessActionSubmit ? (
                <button
                  type="button"
                  className="btn btn-primary cy-btn pl-2 pr-2"
                  onClick={() => {
                    if (clickSaveSubmit) setClickSaveSubmit(false);
                    setClickSaveSubmit(true);
                  }}
                >
                  Submit
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* End footer Button box */}
    </>
  );
};

export default ValueProposition;
ValueProposition.propTypes = {
  location: PropTypes.object.isRequired,
  productId: PropTypes.string,
  editData: PropTypes.bool.isRequired,
  onUpdateAdvancedData: PropTypes.func.isRequired,
};
