/**
 * @author: Anand Kumar
 * @date : 04/Jan/2021
 * File Description: Custom Form Designer
 */
import React, { useEffect, useState } from "react";
import * as SurveyJSCreator from "survey-creator";
import * as Survey from "survey-knockout";
import * as widgets from "surveyjs-widgets";
import $ from "jquery";
import "jquery-bar-rating";
import "jquery-ui-dist/jquery-ui.css";
import "survey-creator/survey-creator.css";
import "select2/dist/js/select2.js";
import "select2/dist/css/select2.css";
import "./survey.css";
import { useDispatch } from "react-redux";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import ActionButtons from "./actionButtons";
import { updateCustomForms, fetchCustomFormById } from "../../../middleware/services/cmsApi";
import { actionError, actionSuccess, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { getLoggedInUser, getProductVariant } from "../../../utilities";
import FORM_CONSTANTS from "./constants";
import AccessDenied from "../../components/accessDenied";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const CKEDITOR = window.CKEDITOR;
// theme colors
const mainColor = "#343a40";
const mainHoverColor = "#000000"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
const headerBackgroundColor = "#4a4a4a";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray
let showdown = require("showdown");

widgets.prettycheckbox(Survey);
widgets.inputmask(Survey);
widgets.jqueryuidatepicker(Survey, $);
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);
require("jquery-ui-dist/jquery-ui.js");

const CustomFormDesigner = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = getLoggedInUser();
  const productVariant = getProductVariant();
  const formId = props.formId;
  const [formJson, setFormJson] = useState();
  const [isPublished, setIsPublished] = useState(undefined);

  /* Common access */
  const accessActionSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);

  const breadCrumbLinks = [
    { linkUrl: "/custom-forms", linkName: props?.moduleName || "", linkActive: false },
    {
      linkUrl: formId ? `/custom-forms/${formId}/basic-details#basic` : "/custom-forms/basic-details#basic",
      linkName: formJson?.formTitle || "",
      linkActive: false,
    },
    {
      linkUrl: `/custom-forms/${formId}/form-designer${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  const getFormJsonById = async () => {
    let formJsonData = null;
    let params = {};
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params = {
      filters: [...defaultFilter],
      fields: ["formJson", "formType", "formTitle", "productVariant"],
    };
    const res = await fetchCustomFormById(formId, params)
      .then((res) => {
        const data = res.data.data;
        setIsPublished(data.productVariant[0].isLive);
        formJsonData = data;
        setFormJson(data);
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || ""));
        dispatch(hideLoader());
      });
    return formJsonData;
  };
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/custom-forms"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, formJson, props.selectedIndex, props.location.hash]);

  let surveyCreator;

  let saveMyForm = async () => {
    document.getElementsByClassName("svd_save_btn")[0].click();
    const surveyData = surveyCreator?.text || "";
    if (surveyData) {
      let payload = {};
      payload.formCreatedBy = loggedInUser._id;
      payload.formJson = surveyData;
      const formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      await updateCustomForms(formId, formData)
        .then((res) => {
          if (res) {
            dispatch(actionSuccess("Form has been saved successfully"));
          } else {
            dispatch(actionError("Something went wrong"));
          }
        })
        .catch((err) => {
          dispatch(actionError(err.data.message || "Something went wrong"));
        });
    }
  };
  const renderSurveyJS = async () => {
    // SurveyJs locale customization
    let curStrings = SurveyJSCreator.localization.getLocale("");
    curStrings.ed.designer = "Form Designer";
    curStrings.ed.saveSurvey = "Save Form";
    curStrings.ed.saveSurveyTooltip = "Save Form";
    curStrings.ed.testSurvey = "Test Form";
    curStrings.ed.testSurveyAgain = "Test Form Again";

    // hiding page title and description
    // Survey.Serializer.findProperty("userFname", "Title").visible = false;
    Survey.Serializer.findProperty("survey", "title").visible = false;
    Survey.Serializer.findProperty("survey", "description").visible = false;
    Survey.Serializer.findProperty("survey", "description").visible = false;
    Survey.Serializer.findProperty("page", "title").visible = false;
    Survey.Serializer.findProperty("page", "description").visible = false;
    Survey.settings.allowShowEmptyTitleInDesignMode = false;

    // Theme customization
    let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
    defaultThemeColorsSurvey["$text-color"] = textColor;
    defaultThemeColorsSurvey["$header-color"] = headerColor;
    defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
    defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
    let defaultThemeColorsEditor = SurveyJSCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;

    // SurveyJSCreator.StylesManager.applyTheme("winterstone");
    SurveyJSCreator.StylesManager.applyTheme("default");

    //Start - CKEditor Integration

    const CkEditor_ModalEditor = {
      afterRender: function (modalEditor, htmlElement) {
        if (typeof CKEDITOR === "undefined") return;
        let editor =
          CKEDITOR &&
          CKEDITOR.replace(htmlElement, {
            toolbarGroups: [
              { name: "clipboard", groups: ["clipboard", "undo"] },
              { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
              { name: "links", groups: ["links"] },
              { name: "insert", groups: ["insert"] },
              { name: "forms", groups: ["forms"] },
              { name: "tools", groups: ["tools"] },
              { name: "document", groups: ["mode", "document", "doctools"] },
              { name: "others", groups: ["others"] },
              { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
              { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
              { name: "styles", groups: ["styles"] },
              { name: "colors", groups: ["colors"] },
              { name: "about", groups: ["about"] },
            ],
            removeButtons: "Subscript,Superscript,PasteText,PasteFromWord,Scayt,Strike,RemoveFormat,Blockquote,About",
          });

        let isUpdating = false;
        editor.on("change", function () {
          isUpdating = true;
          modalEditor.editingValue = editor.getData();
          isUpdating = false;
        });
        editor.setData(modalEditor.editingValue);
        modalEditor.onValueUpdated = function (newValue) {
          if (!isUpdating) {
            editor.setData(newValue);
          }
        };
      },
      destroy: function (modalEditor, htmlElement) {
        if (typeof CKEDITOR === "undefined") return;
        let instance = CKEDITOR.instances[htmlElement.id];
        if (instance) {
          instance.removeAllListeners();
          instance.destroy(true);
          CKEDITOR.remove(instance);
        }
      },
    };
    SurveyJSCreator.SurveyPropertyModalEditor.registerCustomWidget("html", CkEditor_ModalEditor);
    SurveyJSCreator.SurveyPropertyModalEditor.registerCustomWidget("text", CkEditor_ModalEditor);

    //Create showdown markdown converter
    let converter = new showdown.Converter();
    function doMarkdown(survey, options2) {
      //convert the markdown text to html
      let str = converter.makeHtml(options2.text);
      if (str.indexOf("<p>") == 0) {
        //remove root paragraphs<p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
      }
      //set html
      options2.html = str;
    }
    //End - CKEditor Integration
    let options = {
      showLogicTab: false,
      showEmbededSurveyTab: false,
      showJSONEditorTab: true,
      showPropertyGrid: false,
      // showTestSurveyTab:false,
      pageEditMode: "single",
      allowControlSurveyTitleVisibility: false,
      // showSurveyTitle: "never",
      haveCommercialLicense: true,
    };
    surveyCreator = new SurveyJSCreator.SurveyCreator("surveyCreatorContainer", options);

    surveyCreator.showToolbox = "right";
    surveyCreator.showPropertyGrid = "right";
    surveyCreator.rightContainerActiveItem("toolbox");
    let surveySettingsAction = surveyCreator.toolbarItems().filter((item) => {
      return item.id === "svd-survey-settings";
    })[0];
    surveyCreator.toolbarItems.remove(surveySettingsAction);

    surveyCreator.onElementAllowOperations.add((sender, options) => {
      const defaultNameArr = [
        "userFname",
        "userLname",
        "userEmail",
        "userMobile",
        "country",
        "city",
        "postCode",
        "countryOfResidence",
        "nationality",
        "address1",
        "userLanguageExpertise",
        "panel1",
        "highestEducation",
        "technologyInterest",
        "industryInterest",
        "equipedWithLaptop",
        "internetConnectionAvailability",
        "timeAvailability",
        "defaultTimeZone",
        "userLinkedinUrl",
      ];

      if (!defaultNameArr.includes(options.obj.name)) {
        surveyCreator.showPropertyGrid = "right";
        return; //Disable show properties and delete option for defaultNameArr questions only
      }
      options.allowDelete = false;
      options.allowEdit = false;
      surveyCreator.showPropertyGrid = false;
    });

    await getFormJsonById().then((data) => {
      if (data && !data?.formJson) {
        data.formJson = FORM_CONSTANTS.FORM_JSON[data?.formType];
      }
      surveyCreator.JSON = data?.formJson;
      surveyCreator.saveSurveyFunc = saveMyForm;
      document.getElementsByClassName("svd_save_btn")[0].style.display = "none";
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    dispatch(showLoader());
    renderSurveyJS();
  }, []);
  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="gennx-content-wrapper content-wrapper pt-3 padding-bottom-100i" style={{ width: "99.5%" }}>
            <div id="surveyCreatorContainer" className="mx-2" />
          </div>
          {isPublished === "NO" && (
            <ActionButtons
              accessRights={props.accessRights}
              nextPath=""
              selectedIndex={props.selectedIndex}
              previousPath={formId ? `/custom-forms/${formId}/basic-details#basic` : true}
              setCurrentTab={(selectedIndex) => {
                props.setCurrentTab(selectedIndex);
              }}
              back={props.path === "/custom-forms/:formId/basic-details" ? "/custom-forms" : "/custom-forms"}
              formId={formId}
              handleSave={saveMyForm}
              accessActionSave={accessActionSave}
            />
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default CustomFormDesigner;
