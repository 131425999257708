import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import moment from "moment";
import { setBreadCrumb } from "middleware/actions/breadCrumbAction";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { exportAnalystQuery, fetchAnalystQuery, fetchSingleAnalystQuery } from "middleware/services/reportApi";
import { fetchConfig } from "middleware/services/cmsApi";
import { enableRipple } from "@syncfusion/ej2-base";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import { StringFilter } from "app/components/dataGrid/fliters";
import NewPagination from "app/components/newPagination/newPagination";
import * as CONSTANTS from "../../../constants/index";
import "./style.scss";
import { presets } from "utils";
import CustomSidebar from "./SideBar";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import AnalystQueryDetailsSideBar from "./Analyst QueryDetailsSideBar";

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: "Internal User Report", linkActive: true }];
enableRipple(true);

const AnalystQueryReport = (props) => {
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [queryRefNumber, setQueryRefNumber] = useState("");
  const [rowData, setRowData] = useState([]);
  const [singleQueryData, setSingleQueryData] = useState();
  const [queryCategory, setQueryCategory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const gridState = useSelector((state) => state.gridState);
  const [gridRef, setGridRef] = useState(null);

  const formik = useFormik({
    initialValues: {
      category: "",
      subCategory: "",
    },
  });

  const handelSideBar = () => {
    if (sidebarRef?.current) {
      sidebarRef.current.hide();
    }

    setShowSidePanel(!showSidePanel);
  };

  //** Fetch ask your analyst report
  const fetchAnalystQueryReport = async (reportDateRange = null, params = {}, state = false) => {
    try {
      dispatch(showLoader());
      params.filters = [];
      params.sort = "queryCreatedDate:desc";
      if (gridState?.recordsPerPage) params.limit = gridState.recordsPerPage;
      const DateFilter = dateRange === null ? reportDateRange : dateRange;

      if (DateFilter) {
        const [startDate, endDate] = DateFilter;

        const gte = { d: moment(startDate).toISOString() };
        const lt = { d: moment(endDate).add(1, "day").toISOString() };

        params.filters.push(["queryCreatedDate", "gte", gte.d]);
        params.filters.push(["queryCreatedDate", "lt", lt.d]);
      }
      if (state && formik.values.category !== "" && formik.values.category !== "All" && formik.values.category !== null)
        params.filters.push(["queryCategory", "eq", formik.values.category]);

      if (
        state &&
        formik.values.subCategory !== "" &&
        formik.values.category !== "All" &&
        formik.values.subCategory !== "All" &&
        formik.values.subCategory !== null
      )
        params.filters.push(["querySubCategory", "eq", formik.values.subCategory]);

      const response = await fetchAnalystQuery(params);
      setRowData(response?.data.data);
      setTotalCount(response?.data.totalCount);
      dispatch(hideLoader());
    } catch (err) {
      console.log("error:::::::::", err);
      dispatch(hideLoader());
    }
  };

  //** Fetch ask your analyst category and sub category data
  const fetchAnalystQueryCategory = async () => {
    try {
      showLoaderGrid();
      let params = {};
      params.filters = [["configName", "eq", "analyst-query-category"]];
      await fetchConfig(params).then((res) => {
        if (res.status === 200) {
          const filterData = res.data.data[0].configValue.filter((a) => a.label !== "Business Objective");
          if (filterData.length > 0) filterData.push({ label: "All", value: "All" });
          filterData.sort(compareCategories);
          setQueryCategory(filterData);
        }
      });
      // dispatch(hideLoader());
    } catch (err) {
      console.log("error:::::::::", err);
      dispatch(hideLoader());
    }
  };

  //** Set sub category data
  const subCategoryList = useMemo(() => {
    const categoryData = queryCategory.find((category) => category.value === formik.values.category);
    if (!categoryData || !categoryData.subCategory) return [];
    // Check if "All" category doesn't already exist
    const hasAllCategory = categoryData.subCategory.some((subCategory) => subCategory.value === "All");

    if (!hasAllCategory) categoryData.subCategory.push({ label: "All", value: "All" });
    categoryData.subCategory.sort(compareCategories);
    const filterCategoryData = categoryData.subCategory.filter((a) => a.label !== "Attend FutureBridge Event");

    return filterCategoryData;
  }, [formik.values.category]);

  // ** Sort dropdown data
  function compareCategories(a, b) {
    // Move "All" category to the beginning
    if (a.value === "All") return -1;
    if (b.value === "All") return 1;
    // Sort the rest in ascending order based on label
    return a.label.localeCompare(b.label);
  }

  //** handle reset function
  const handleReset = () => {
    window.location.reload();
  };

  //** handle date range
  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.BOREPORTDEFAULTDATERANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setDateRange([dateFrom, dateTo]);
    return [dateFrom, dateTo];
  };

  const handleViewQuery = async (value) => {
    dispatch(showLoader());
    console.log({ value });
    setQueryRefNumber(value.queryReferenceNumber);
    const response = await fetchSingleAnalystQuery(value.queryId);
    console.log({ response });
    setSingleQueryData(response.data.data);
    handelSideBar();
    dispatch(hideLoader());
  };

  const RefNumberTemplate = (value) => {
    return (
      <span className="reference-number" onClick={() => handleViewQuery(value)}>
        {value.queryReferenceNumber}
      </span>
    );
  };

  const handleExport = async (state = true) => {
    try {
      let filters = [];
      dispatch(showLoader());
      if (dateRange) {
        const [dateFrom, dateTo] = dateRange;

        const gte = { d: moment(dateFrom).toISOString() };
        const lt = { d: moment(dateTo).add(1, "day").toISOString() };

        filters.push(["queryCreatedDate", "gte", gte.d]);
        filters.push(["queryCreatedDate", "lt", lt.d]);
      }
      if (state && formik.values.category !== "" && formik.values.category !== "All" && formik.values.category !== null)
        filters.push(["queryCategory", "eq", formik.values.category]);

      if (
        state &&
        formik.values.subCategory !== "" &&
        formik.values.category !== "All" &&
        formik.values.subCategory !== "All" &&
        formik.values.subCategory !== null
      )
        filters.push(["querySubCategory", "eq", formik.values.subCategory]);

      const response = await exportAnalystQuery({
        filters: filters,
      });

      if (!response) return;
      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_REPORT_URL}/reports/analyst-query/export/${response.data}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      console.log("error: " + error);
    }
  };

  const QueryDetails = useMemo(() => rowData.filter((a) => a.queryReferenceNumber === queryRefNumber) || [], [queryRefNumber]);

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  useEffect(() => {
    (async () => {
      await fetchAnalystQueryCategory();
      const reportDateRange = fnStartDate();
      await fetchAnalystQueryReport(reportDateRange);
      hideLoader();
    })();
  }, []);

  //** Set breadcrumb
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    // hideLoaderGrid();
  };

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white ">
        <div className="row">
          <div className="col-md-2 mt-3 grid-container-report-filters pr-0 responsive-report-filters">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline analyst-query-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0 grid-container-report-filters">
            <Dropdown
              formik={formik}
              placeholder="Purpose of Contacting"
              required="required"
              id="category"
              name="category"
              fields={{ text: "label", value: "value" }}
              value={formik.values.category == "" && queryCategory.length > 0 ? "All" : formik.values.category}
              dataSource={queryCategory || []}
            />
          </div>

          {subCategoryList.length > 0 && (
            <div className="col-md-2 mt-3 pr-0 grid-container-report-filters">
              <Dropdown
                formik={formik}
                placeholder="Sub-Type"
                required="required"
                id="subCategory"
                name="subCategory"
                fields={{ text: "label", value: "value" }}
                value={formik.values.subCategory == "" && subCategoryList.length > 0 ? "All" : formik.values.subCategory}
                dataSource={subCategoryList}
              />
            </div>
          )}

          <div className="col-md-3 mt-3">
            <button type="button" onClick={() => fetchAnalystQueryReport(null, {}, true)} className="search-btn px-4 btn btn-primary mr-2">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report analyst-query-report-grid">
        <TreeDataGridNew
          rowData={rowData}
          isPaging={false}
          showResetFilter={true}
          deleteMany={false}
          allowPagination={true}
          showExportButton={true}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          exportFileName={"Ask Your Analyst Report"}
          exportFunction={handleExport}
          getGridRef={getGridRef}
          created={handleCreated}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="queryReferenceNumber"
              headerText="Ref No."
              filterTemplate={StringFilter}
              type="String"
              textAlign="Left"
              width="120px"
              minWidth="120px"
              maxWidth="120px"
              clipMode="EllipsisWithTooltip"
              showInColumnChooser={false}
              template={RefNumberTemplate}
            />
            <ColumnDirective
              field="queryCategory"
              headerText="Purpose of Contacting"
              filterTemplate={StringFilter}
              type="String"
              textAlign="Left"
              width={window.innerWidth < 1366 ? "180px" : "260px"}
              minWidth={window.innerWidth < 1366 ? "180px" : "260px"}
              maxWidth={window.innerWidth < 1366 ? "180px" : "260px"}
              clipMode="EllipsisWithTooltip"
              showInColumnChooser={false}
            />
            <ColumnDirective
              field="querySubCategory"
              headerText="Sub Type"
              filterTemplate={StringFilter}
              type="String"
              textAlign="Left"
              width={window.innerWidth < 1366 ? "180px" : "260px"}
              minWidth={window.innerWidth < 1366 ? "180px" : "260px"}
              maxWidth={window.innerWidth < 1366 ? "180px" : "260px"}
              clipMode="EllipsisWithTooltip"
            />
            <ColumnDirective
              field="queryCreatedDate"
              headerText="Submission Date"
              width="150px"
              minWidth="160px"
              maxWidth="160px"
              type="date"
              format={{ type: "date", format: "dd-MMM-yyyy" }}
              textAlign="Center"
              headerTextAlign="Left"
              clipMode="EllipsisWithTooltip"
            />
            <ColumnDirective
              field="querySubmittedBy"
              headerText="Submitted By"
              filterTemplate={StringFilter}
              type="String"
              textAlign="Left"
              minWidth="220px"
              width="220px"
              maxWidth="220px"
              clipMode="EllipsisWithTooltip"
            />
            <ColumnDirective
              field="querySpocMembers"
              headerText="Email Sent To"
              filterTemplate={StringFilter}
              type="String"
              textAlign="Left"
              minWidth="250px"
              clipMode="EllipsisWithTooltip"
            />
          </ColumnsDirective>
        </TreeDataGridNew>
        <NewPagination
          rowData={rowData}
          allowPagination={true}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          totalCount={totalCount}
          setRowData={setRowData}
          fetch={fetchAnalystQueryReport}
          defaultPageView={CONSTANTS.DEFAULTANALYSTREPORTPAGEVIEW}
        />
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel} sidebarId="analyst-query-report-sidebar" QueryDetails={QueryDetails}>
          <div className="analyst-query-sidebar">
            <div className="analyst-query-sidebar-header">
              <span className="sidebar-header-name">Detail View</span>
              <span className="sidebar-cross-icon" onClick={() => handelSideBar()}>
                <CloseIcon />
              </span>
            </div>
            <AnalystQueryDetailsSideBar
              QueryDetails={QueryDetails}
              sidebarRef={sidebarRef}
              isOpen={showSidePanel}
              sidebarId="analyst-query-report-sidebar"
              handleClose={handelSideBar}
              singleQueryData={singleQueryData}
            />
          </div>
        </CustomSidebar>
      )}
    </div>
  );
};

export default AnalystQueryReport;
