/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Trend taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Technology taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import "../index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { fetchTaxonomyTrend, getObjectiveSgfTag, fetchContentTags, addContentTags } from "../../../../middleware/services/cmsApi";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import { actionSuccess, showLoader, hideLoader, actionError, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import PropTypes from "prop-types";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import SortableTree from "../sortableTree";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "../../../components/CustomFormElements/syncFusionFormFields";
import SideBar from "./SideBar";

const Taxonomy = (props) => {
  const dispatch = useDispatch();
  const [tagging, setTagging] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [checked, setChecked] = useState([]);
  const [contentTaggedIds, setContentTaggedIds] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [sgfName, setSgfName] = useState(undefined);
  const [selectedSgfIndustry, setSelectedSgfIndustry] = useState("");
  const [contentTaggingData, setContentTaggingData] = useState([]);
  let taggingData = [];
  const [sgfTrendData, setSgfTrendData] = useState([]);
  const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
  const [rightTreeCollapse, setRightTreeCollapse] = useState(true);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [contentTagsDataSource, setContentTagsDataSource] = useState([]);
  const [tagsLength, setTagsLength] = useState(0);
  const [show, setShow] = useState(false);
  const [leftTreeCheckedSequence, setLeftTreeCheckedSequence] = useState([]);
  const [leftTreeCheckedSequenceTag, setLeftTreeCheckedSequenceTag] = useState([]);
  const [sequenceCount, setSequenceCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      contentTag: [],
    },
  });

  /*
        @Description : Use only for avoiding trend change mechanism on save trend
    */
  const [selectedTrend, setSelectedTrend] = useState("");
  const [leftTreeChecked, setLeftTreeChecked] = useState([]);
  const [rightTreeChecked, setRightTreeChecked] = useState([]);
  const [sgfData, setSgfData] = useState({
    trend_id: "",
    trend_name: "",
    trend_parent_id: "",
    trend_is_sgf: 1,
    trend_sub_root: "",
    trend_root: "",
  });

  const fetchIndustry = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({
      fields: ["id", "trendName", "trendParentId", "trendParentName"],
      filters: [["trendParentId", "eq", 0]],
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        setSelectedSgfIndustry(res.data.data[0].id);
        setIndustry(res.data.data);
        fetchTrend(res.data.data[0].id);
        // setSgfData({
        //     ...sgfData,
        //     trend_parent_id: res.data.data[0].id,
        //     trend_root : res.data.data[0].id
        // })
      })
      .catch((err) => {
        console.error("err");
      });
  };
  /*
       @Description : Fetch taxonomy tagging for respective content 
       1. fetchContentTagging received from parent component as a props
   */
  const fetchContentTagging = () => {
    dispatch(showLoader());
    const contentTaxonomy = props.fetchContentTagging(props.contentId);
    contentTaxonomy
      .then((res) => {
        let { data = [] } = res.data;
        data = [...new Map(data.map((v) => [v.trendId, v])).values()];
        if (data.length) {
          setContentTaggingData(data);
          taggingData = data.map((values) => values.trendId);
        }
        const selectedNodeData = buildSelectedTreeJson(data);
        const selectedSgfNodeData = buildSelectedSgfTreeJson(JSON.parse(JSON.stringify(selectedNodeData)));
        setTagging(
          selectedSgfNodeData.map((item) => {
            item["level"] = 2;
            return item;
          })
        );
        setContentTaggedIds(taggingData);
        setLeftTreeCollapse(true);
        setLeftTreeChecked([]);
        setReRender(true);
        if (industry?.length) dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  const fetchSgfTrend = (sgfName) => {
    getObjectiveSgfTag({ filters: [["sgfName", "eq", sgfName]], limit: 2000 })
      .then((res) => {})
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTrend :: ", err);
      });
  };
  /*
        @Description : Fetch all trend 
    */
  const fetchTrend = (selectedIndustry) => {
    dispatch(showLoader());
    getObjectiveSgfTag({
      filters: [["sector.sectorId", "eq", selectedIndustry]],
      sort: "trendName:asc",
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        dispatch(hideLoader());
        setSgfTrendData(
          res.data.data.filter((item) => {
            if (item.sgfName !== "Others") return item;
          })
        );
        setSgfName(res.data.data[0].id);
        setTechnologyData(res.data.data[0].trend);
        setSelectedTechnology(res.data.data[0].trend[0].trendId);
        setSgfData({
          trend_parent_id: res.data.data[0].sector[0].sectorId,
          trend_parent_name: res.data.data[0].sector[0].sectorName,
          trend_id: res.data.data[0].id,
          trend_name: res.data.data[0].sgfName,
          trend_is_sgf: 1,
          trend_sub_root: "",
          trend_root: res.data.data[0].sector[0].sectorId,
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTrend :: ", err);
      });
  };
  /*
        @Description : Fetch all taxonomy for selected trend
        @param 
        trendSubRoot : Trend Id
        taggingData : Content taxonomy tagged data [ Use for mark as default check ]
    */
  const fetchTaxonomy = (trendParentId) => {
    taggingData = [...new Set(contentTaggedIds)];
    if (trendParentId) {
      dispatch(showLoader());
      fetchTaxonomyTrend({
        fields: ["id", "trendName", "trendParentId", "trendParentName", "trendLevel"],
        filters: [["trendSubRoot", "eq", trendParentId]],
        limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
      })
        .then((res) => {
          dispatch(hideLoader());
          let { data = [] } = res.data;
          let checkNode = [];
          if (data.length) {
            const treeJosnArray = buildTreeJson(data, trendParentId);
            data.forEach((element) => {
              if (taggingData.includes(element.id)) {
                checkNode.push(element.id);
                element["isChecked"] = true;
              }
            });
            setChecked(checkNode);
            setTaxonomy(treeJosnArray);
            setRightTreeCollapse(true);
            setRightTreeChecked(checkNode);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.error("err fetchTaxonomy :: ", err);
        });
    }
  };
  /*
        @Description : Build tree data
        @param : 
        data : Trend taxonomy data
        parentId : technology id
    */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.trendName;
      element["level"] = element.trendLevel;
      element["showCheckbox"] = true;
      if (element["trendParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
       @Description : Build tree data for tagged values
       @param : 
       data : taxonomy data
       parentId :industry id
   */
  const buildSelectedTreeJson = (data, parentId = 0) => {
    const result = [];
    let count = 0;
    let fetchedSeq = [];
    data.forEach((element) => {
      if (element?.trendSortOrder) {
        fetchedSeq.push({ trendId: element.id, trendName: element.trendName, trendSortOrder: element.trendSortOrder });
      }
      element["id"] = element.trendId;
      element["value"] = element.trendId;
      element["label"] = element.trendName;
      if (element?.trendSortOrder === 1) {
        element["icon"] = "sortOne";
        count++;
      }
      if (element?.trendSortOrder === 2) {
        element["icon"] = "sortTwo";
        count++;
      }
      if (element?.trendSortOrder === 3) {
        element["icon"] = "sortThree";
        count++;
      }
      if (element?.trendSgfParentId) {
        element["parentId"] = element.trendSgfParentId;
      } else {
        element["parentId"] = element.trendParentId;
      }
      if (element["trendParentId"] == parentId) {
        const children = buildSelectedTreeJson(data, element["trendId"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    fetchedSeq.sort((a, b) => a.trendSortOrder - b.trendSortOrder);
    setLeftTreeCheckedSequence(fetchedSeq);
    setLeftTreeCheckedSequenceTag(fetchedSeq);
    setSequenceCount(count);
    return result;
  };

  const buildSelectedSgfTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      let siblings = element?.children;
      if (siblings) {
        let sgfChilds = siblings.filter((item) => {
          if (item.trendIsSgf === 1) return item.trendId;
        });
        let sgfChild = sgfChilds.map((item) => {
          let sgfChildren = siblings.filter((v) => {
            if (item.trendId == v.trendSgfParentId) {
              return v;
            }
          });
          item["children"] = sgfChildren;
          return item;
        });
        element.children = sgfChild;
      }
      result.push(element);
    });
    return result;
  };
  /*
      @Description : Make bridge to send callback
      @param : 
      selectedTech : selected technology
      checkValues : Checked taxonomy
  */
  const handleSaveBridge = (selectedTech, checkValues) => {
    setSelectedTrend(selectedTech);
    if (checkValues.length) props.handleSave(selectedTech, checkValues, fetchContentTagging, sgfData);
    else dispatch(showMessage("Please select taxonomy..!"));
  };

  const deleteTrend = () => {
    if (props.isDelete) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            props.deleteTrend([...new Set(leftTreeChecked)], fetchContentTagging);
          },
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to remove taxonomy tagging ? ",
          title: "dialogAlertCss",
        })
      );
    } else {
      setShowModalDelete(true);
    }
  };

  const leftTreeOnChecked = (checkedLeft) => {
    let result = [];
    let tagSeq = [];
    checkedLeft.forEach(function (a, i) {
      result.push(a.id);
      tagSeq.push({ trendId: a.id, trendName: a.trendName, trendSortOrder: i + 1 });
      if (Array.isArray(a.children)) {
        result = result.concat(leftTreeOnChecked(a.children));
      }
    });
    setLeftTreeChecked(result);
    setLeftTreeCheckedSequence(tagSeq);
    return result;
  };

  const searchContentTag = async (searchText) => {
    const response = await fetchContentTags({
      fields: ["tagName"],
      filters: [["tagName", "cn", searchText]],
      sort: "tagName:asc",
      limit: 10,
    });
    if (response?.data?.data) {
      const resData = response?.data?.data?.map((v) => {
        return { label: v.tagName, value: v.tagName };
      });
      if (resData) {
        setContentTagsDataSource(resData);
      }
    }
  };

  /*
       @Description : Fetch tags for respective content 
       1. fetchContentTags received from parent component as a props
   */
  const fetchTags = () => {
    const test = props.fetchTags(props.contentId);
    test
      .then((res) => {
        if (props.contentModule === "Deliverable") formik.setValues({ contentTag: res.data.data.deliverableContentTags });
        if (props.contentModule === "News") formik.setValues({ contentTag: res.data.data.newsContentTags });
        if (props.contentModule === "Company") formik.setValues({ contentTag: res.data.data.companyContentTags });
        if (props.contentModule === "Product") formik.setValues({ contentTag: res.data.data.productContentTags });
        if (props.contentModule === "Video") formik.setValues({ contentTag: res.data.data.videoContentTags });
      })
      .catch((err) => {
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  const saveTags = () => {
    dispatch(showLoader());
    let TagsData;
    let TagsDataForDB = formik.values.contentTag;

    if (props.contentModule === "Deliverable") TagsData = { deliverableContentTags: formik.values.contentTag };
    if (props.contentModule === "News") TagsData = { newsContentTags: formik.values.contentTag };
    if (props.contentModule === "Company") TagsData = { companyContentTags: formik.values.contentTag };
    if (props.contentModule === "Product") TagsData = { productContentTags: formik.values.contentTag };
    if (props.contentModule === "Video") TagsData = { videoContentTags: formik.values.contentTag };

    let saveTagsData = new FormData();

    for (let uKey in TagsData) {
      if (typeof TagsData[uKey] !== "object") {
        if (TagsData[uKey]) saveTagsData.append(uKey, TagsData[uKey]);
      } else {
        if (TagsData[uKey]) saveTagsData.append(uKey, JSON.stringify(TagsData[uKey]));
      }
    }
    let response, response2;

    response = props.saveTags(props.contentId, saveTagsData);

    response
      .then((response) => {
        dispatch(hideLoader());
        dispatch(actionSuccess("Content Tags Saved Successfully"));
      })
      .catch((e) => {
        dispatch(hideLoader());
        if (e?.data?.message) {
          dispatch(actionError(e.data.message));
        } else {
          dispatch(actionError("Something Went Wrong...!"));
        }
      });

    response2 = addContentTags(TagsDataForDB);

    response2
      .then((res) => {})
      .catch((e) => {
        if (e?.data?.message) {
          dispatch(actionError(e.data.message));
        } else {
          dispatch(actionError("Something Went Wrong...!"));
        }
      });
  };

  const showInProduct = () => {
    if (leftTreeCheckedSequence.length > 3) {
      dispatch(actionError("You can select maximum 3 nodes."));
    } else {
      setLeftTreeCheckedSequenceTag(leftTreeCheckedSequence);
      setShow(true);
    }
  };

  const handleSaveTagSequence = (sortedTags) => {
    props.handleSaveTagSequence(sortedTags, fetchContentTagging);
    setShow(false);
  };

  useEffect(() => {
    if (formik.values.contentTag) {
      setTagsLength(formik.values.contentTag.length);
    }
  }, [formik.values.contentTag]);

  useEffect(() => {
    fetchContentTagging();
    if (
      props.contentModule === "Deliverable" ||
      props.contentModule === "News" ||
      props.contentModule === "Company" ||
      props.contentModule === "Product" ||
      props.contentModule === "Video"
    )
      fetchTags();
  }, []);
  useEffect(() => {
    if (reRender && selectedTechnology) {
      fetchTaxonomy(selectedTechnology);
      setReRender(false);
    }
  }, [reRender, contentTaggedIds]);

  useEffect(() => {
    if (!selectedSgfIndustry) fetchIndustry();
  }, []);

  useEffect(() => {
    if (props.saveTreeData) {
      handleSaveBridge(selectedTechnology, rightTreeChecked);
    }
  }, [props.saveTreeData]);
  useEffect(() => {
    if (props?.submitStatus) {
      props.handleSubmit(selectedTechnology, rightTreeChecked, fetchContentTagging, sgfData);
    }
  }, [props.submitStatus]);

  return (
    <>
      <div
        className="row trendTreeLayout"
        style={{
          // height: "80vh",
          marginLeft: "0.5em",
          marginRight: "0.3em",
          paddingRight: "0.7em",
          marginTop: "1.5em",
        }}
      >
        <div className="col-md-6 TaxonomyNewTabLeftContent">
          <div className="row" style={{ paddingTop: "2.2vh", paddingBottom: "7px" }}>
            <div className="">
              <h6 className="font-weight-bold headline">Trend</h6>
            </div>
            {tagging?.length > 0 && (
              <div className="ml-auto" style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {props?.accessActionTaxonomyShowInProduct && (
                  <button
                    type="button"
                    className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 || sequenceCount > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                    onClick={() => showInProduct()}
                  >
                    {`Show in Product (${sequenceCount})`}
                  </button>
                )}
                {props?.accessPermissionTaxonomyDelete && (
                  <button
                    type="button"
                    className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                    onClick={() => deleteTrend()}
                  >
                    Delete
                  </button>
                )}

                {leftTreeCollapse ? (
                  <span
                    className="expandButton btn px-3 mr-3"
                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                    onClick={() => setLeftTreeCollapse(false)}
                  >
                    Expand All{" "}
                  </span>
                ) : (
                  <span
                    className="expandButton btn px-3 mr-3"
                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                    onClick={() => setLeftTreeCollapse(true)}
                  >
                    Collapse All
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div style={{ paddingTop: "1em" }}>
                {tagging?.length > 0 ? (
                  <>
                    <SortableTree
                      treeData={tagging}
                      onClick={leftTreeOnChecked}
                      treeCollapse={leftTreeCollapse}
                      parentId={"parentId"}
                      flatData={true}
                      hieghtClass={
                        ["News", "Company", "Deliverable", "Product", "Video"].includes(props.contentModule)
                          ? "custom-tagging-tree-container-wrapper-with-tags"
                          : "custom-tagging-tree-container-wrapper"
                      }
                      icon={true}
                    />

                    {["News", "Company", "Deliverable", "Product", "Video"].includes(props.contentModule) ? (
                      <div className="TaxonomyTagsMultiselect">
                        <div className="TaxonomyTags"></div>
                        <div className="row" style={{ alignItems: "center", justifyContent: "space-between" }}>
                          <h6 className="font-weight-bold" style={{ paddingBottom: "0.5em", paddingTop: "2em", paddingLeft: "2em" }}>
                            Tags({tagsLength})
                          </h6>
                          {props?.accessPermissionAddTags && (
                            <div className=" text-right" style={{ paddingTop: "1em", marginLeft: "2.2em", paddingRight: "30px" }}>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                onClick={() => {
                                  setTagsLength(formik.values.contentTag.length);
                                  saveTags();
                                }}
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <MultiSelect
                            formik={formik}
                            mode="Box"
                            allowCustomValue={true}
                            allowFiltering={true}
                            required=""
                            // placeholder="Content Tag"
                            id="contentTag"
                            name="contentTag"
                            value={formik.values.contentTag}
                            fields={{ text: "label", value: "value" }}
                            filtering={(e) => {
                              searchContentTag(e.text);
                            }}
                            changeOnBlur={false}
                            dataSource={contentTagsDataSource}
                            floatLabelType={undefined}
                          />
                        </div>{" "}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <div className="text-center" style={{ minHeight: "42vh", maxHeight: "42vh" }}>
                      <img src={noTaxonomyImage} alt="No Tagging" width="200" height="200" />
                      <div className="info-container">
                        <p>Select Tags to create Taxonomy</p>
                      </div>
                    </div>
                    {["News", "Company", "Deliverable", "Product", "Video"].includes(props.contentModule) ? (
                      <div className="TaxonomyTagsMultiselect">
                        <div className="TaxonomyTags"></div>
                        <div className="row">
                          <h6 className="font-weight-bold" style={{ paddingBottom: "0.5em", paddingTop: "2em", paddingLeft: "2em" }}>
                            Tags({tagsLength})
                          </h6>
                          {props?.accessPermissionAddTags && (
                            <div className="float-right text-right px-0 col-md-10" style={{ paddingTop: "1em", marginLeft: "2.2em" }}>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                onClick={() => {
                                  setTagsLength(formik.values.contentTag.length);
                                  saveTags();
                                }}
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                          <MultiSelect
                            formik={formik}
                            mode="Box"
                            allowCustomValue={true}
                            allowFiltering={true}
                            required=""
                            // placeholder=""
                            id="contentTag"
                            name="contentTag"
                            value={formik.values.contentTag}
                            fields={{ text: "label", value: "value" }}
                            filtering={(e) => {
                              searchContentTag(e.text);
                            }}
                            changeOnBlur={false}
                            dataSource={contentTagsDataSource}
                            floatLabelType={undefined}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6" style={{ background: "#FFFFFF", "border": "1px solid lightgrey", "border-radius": "4px" }}> */}
        <div className="col-md-6 TaxonomyNewTabRightContent">
          {sgfName && (
            <div
              className="row"
              style={{
                justifyContent: "left",
                paddingBottom: "0.5em",
                paddingTop: "2.2vh",
              }}
            >
              {/* <div className="rounded-top pl-2" style={{ "paddingTop": "2.2vh" }}><h6 className="font-weight-bold"></h6></div> */}
              <div className="col-4" style={{ marginLeft: "0em" }}>
                {industry?.length && selectedSgfIndustry && (
                  <DropDownListComponent
                    value={selectedSgfIndustry}
                    change={(value) => {
                      setSgfData({
                        ...sgfData,
                        trend_parent_id: value?.itemData.id,
                        trend_root: value?.itemData.id,
                      });

                      fetchTrend(value.itemData.id);
                      setSgfTrendData([]);
                      setTechnologyData([]);
                      setTaxonomy([]);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={industry}
                    fields={{ text: "trendName", value: "id" }}
                    placeholder="Select Industry"
                  />
                )}
              </div>

              <div className="col-4">
                {sgfTrendData?.length > 0 && (
                  <DropDownListComponent
                    value={sgfName}
                    change={(value) => {
                      setSgfData({
                        ...sgfData,
                        trend_id: value?.value,
                        trend_name: value?.itemData?.sgfName,
                      });
                      setSgfName(value?.itemData.id);
                      fetchSgfTrend(value?.itemData.sgfName);
                      setTechnologyData(value?.itemData.trend);
                      setSelectedTechnology(value?.itemData.trend[0].trendId);
                      fetchTaxonomy(value?.itemData.trend[0].trendId);
                      setIndustry(industry);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={sgfTrendData}
                    fields={{ text: "sgfName", value: "id" }}
                    placeholder="Select SGF"
                  />
                )}
              </div>

              <div className="col-4">
                {technologyData.length > 0 && (
                  <DropDownListComponent
                    value={selectedTechnology}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={technologyData}
                    fields={{ text: "trendName", value: "trendId" }}
                    placeholder="Select Trend"
                    change={(value) => {
                      setSelectedTechnology(value?.itemData?.trendId);
                      fetchTaxonomy(value?.itemData?.trendId);
                      setIndustry(industry);
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div className="row" style={{ paddingTop: "0.65em" }}>
            <div className="col-12 p-0">
              {taxonomy?.length > 0 && (
                <div className="float-right" style={{ marginBottom: "10px" }}>
                  {props?.accessPermissionTaxonomy && (
                    <button
                      type="button"
                      className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                      onClick={() => {
                        if (props?.isDelete) {
                          props.setSaveTreeData("save");
                        } else {
                          setShowModalAdd(true);
                        }
                      }}
                    >
                      Add
                    </button>
                  )}
                  {rightTreeCollapse ? (
                    <span
                      className="expandButton btn px-3 mr-3"
                      style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                      onClick={() => setRightTreeCollapse(false)}
                    >
                      Expand All{" "}
                    </span>
                  ) : (
                    <span
                      className="expandButton btn px-3 mr-3"
                      style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                      onClick={() => setRightTreeCollapse(true)}
                    >
                      Collapse All
                    </span>
                  )}
                </div>
              )}
              <div>
                {taxonomy.length > 0 ? (
                  <SortableTree
                    treeData={taxonomy}
                    onClick={(checkedRight) => {
                      let ids = checkedRight.map((item) => {
                        return item.id;
                      });
                      setRightTreeChecked(ids);
                    }}
                    treeCollapse={rightTreeCollapse}
                    checked={checked}
                    parentId={"trendParentId"}
                    enablePersistance={true}
                    expanded={checked}
                    hieghtClass={"custom-tagging-tree-container-wrapper"}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {show && <SideBar show={show} setShow={setShow} selectedTaxonomy={leftTreeCheckedSequenceTag} handleSaveSequence={handleSaveTagSequence} />}

      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not add taxonomy on company level. You need to add taxonomy in product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>

      <Modal show={showModalDelete} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not delete taxonomy from company. You need to delete taxonomy from product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalDelete(!showModalDelete)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};
export default Taxonomy;
Taxonomy.propTypes = {
  fetchContentTagging: PropTypes.func.isRequired,
  loadOtherTagInfo: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  saveTreeData: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
};
