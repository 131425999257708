/**
 * File Description: Custom Form Constant declarations
 * Author: Pankaj Kulshreshtha
 * Date: 10/Jun/2021
 */
export default {
    FORM_TYPE_OPTIONS: [{
        label: "Business Objective",
        value: "sbo"
    },
    {
        label: "Registration",
        value: "registration"
    },
    {
        label: "Expert Consultation",
        value: "Expert Consultation"
    },
    {
        label: "Startup Connect Request",
        value: "Startup Connect Request"
    },
    {
        label: "Custom Company Profile",
        value: "Custom Company Profile"
    },
    {
        label: "Startup Identification",
        value: "Startup Identification"
    },
    {
        label: "Survey",
        value: "Survey"
    }
    ],
    FORM_JSON: {
        "sbo": {
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "text",
                            "name": "objective_title",
                            "title": "Objective Title",
                            "hideNumber": true,
                            "isRequired": true
                        },
                        {
                            "type": "comment",
                            "name": "objective_description",
                            "title": "Description ",
                            "hideNumber": true,
                            "isRequired": true,
                            "placeHolder": "Please write a brief note explaining your requirement. On the basis of your brief, our Analysts create a customized feed for you on the platform."
                        }
                    ]
                }],
            "showPreviewBeforeComplete": "showAllQuestions"
        },
        "registration": {

            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "text",
                            "name": "userFname",
                            "title": "First Name",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Enter First Name",
                            "placeHolder": "First Name"
                        },
                        {
                            "type": "text",
                            "name": "userLname",
                            "title": "Last Name",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Enter Last Name",
                            "placeHolder": "Last Name"
                        },
                        {
                            "type": "text",
                            "name": "userEmail",
                            "title": "Email",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Enter Email",
                            "inputType": "email",
                            "placeHolder": "Email"
                        },
                        {
                            "type": "text",
                            "name": "userMobile",
                            "title": " Mobile Number",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Enter Mobile Number",
                            "inputType": "tel",
                            "maxLength": 10,
                            "placeHolder": " Mobile Number"
                        },
                        {
                            "type": "dropdown",
                            "name": "country",
                            "title": "Country",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Select Country",
                            "choicesByUrl": {
                                "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/country?fields=[%22countryName%22,%22id%22]&sort=%7B%22countryName%22:%22asc%22%7D&limit=2000`,
                                "path": "data",
                                "valueName": "id",
                                "titleName": "countryName"
                            },
                            "autoComplete": "country"
                        },
                        {
                            "type": "text",
                            "name": "city",
                            "title": "City",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Select City",
                            "placeHolder": "City"
                        },
                        {
                            "type": "text",
                            "name": "postCode",
                            "title": "Zip Code",
                            "hideNumber": true,
                            "placeHolder": "Zip Code"
                        },
                        {
                            "type": "dropdown",
                            "name": "countryOfResidence",
                            "title": "Country of Residence",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Select Country of Residence",
                            "choicesByUrl": {
                                "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/country?fields=[%22countryName%22,%22id%22]&sort=%7B%22countryName%22:%22asc%22%7D&limit=2000`,
                                "path": "data",
                                "valueName": "countryName",
                                "titleName": "countryName"
                            },
                            "autoComplete": "countryOfResidence"
                        },
                        {
                            "type": "text",
                            "name": "nationality",
                            "title": "Nationality",
                            "hideNumber": true,
                            "placeHolder": "Nationality"
                        },
                        {
                            "type": "comment",
                            "name": "address1",
                            "title": "Full address",
                            "hideNumber": true,
                            "placeHolder": "Full address"
                        },
                        {
                            "type": "tagbox",
                            "name": "userLanguageExpertise",
                            "title": "Languages Knows",
                            "hideNumber": true,
                            "choicesByUrl": {
                                "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/language?sort=order:asc`,
                                "path": "data",
                                "valueName": "language",
                                "titleName": "language"
                            },
                            "colCount": 4,
                            "placeholder": "Languages Knows (multiple selections)"
                        },
                        {
                            "type": "dropdown",
                            "name": "highestEducation",
                            "title": " Highest Education",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "Ph.D.",
                                    "text": "Ph.D."
                                },
                                {
                                    "value": "Masters",
                                    "text": "Masters"
                                },
                                {
                                    "value": "Graduate",
                                    "text": "Graduate"
                                },
                                {
                                    "value": "Under-Graduate",
                                    "text": "Under-Graduate"
                                }
                            ]
                        },
                        {
                            "type": "tagbox",
                            "name": "technologyInterest",
                            "title": "Technology Interest",
                            "isRequired": true,
                            "hideNumber": true,
                            "requiredErrorText": "Please select technology interest\n",
                            "choicesByUrl": {
                                "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/technology?filters=[[%22dtParentId%22,%22eq%22,0]]&fields=[%22id%22,%22dtName%22]&sort=dtName:asc&limit=2000`,
                                "path": "data",
                                "valueName": "id",
                                "titleName": "dtName"
                            },
                            "colCount": 4,
                            "placeholder": "Technology Interest (multiple selections)"
                        },
                        {
                            "type": "tagbox",
                            "name": "industryInterest",
                            "title": "Industry Interest",
                            "isRequired": true,
                            "hideNumber": true,
                            "requiredErrorText": "Please select industry interest\n",
                            "choicesByUrl": {
                                "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/industry?fields=[%22industryName%22]&sort=industryName:asc&limit=50`,
                                "path": "data",
                                "valueName": "id",
                                "titleName": "industryName"
                            },
                            "colCount": 4,
                            "placeholder": "Industry Interest (multiple selections)"
                        },
                        {
                            "type": "radiogroup",
                            "name": "equipedWithLaptop",
                            "title": " Do you have a Laptop/Desktop?",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Select From Choice",
                            "choices": [
                                {
                                    "value": "yes",
                                    "text": "Yes"
                                },
                                {
                                    "value": "no",
                                    "text": "No"
                                }
                            ]
                        },
                        {
                            "type": "dropdown",
                            "name": "internetConnectionAvailability",
                            "title": "Internet Connection Availability (Mbps)",
                            "hideNumber": true,
                            "isRequired": true,
                            "requiredErrorText": "Please Select Internet Connection speed",
                            "choices": [
                                {
                                    "value": "100",
                                    "text": "100"
                                },
                                {
                                    "value": "100-200",
                                    "text": "100-200"
                                },
                                {
                                    "value": "200-300",
                                    "text": "200-300"
                                },
                                {
                                    "value": "300-500",
                                    "text": "300-500"
                                },
                                {
                                    "value": "More than 500",
                                    "text": "More than 500"
                                },
                                {
                                    "value": "No Internet",
                                    "text": "No Internet"
                                }
                            ]
                        },
                        {
                            "type": "dropdown",
                            "name": "timeAvailability",
                            "title": "Time Availability",
                            "hideNumber": true,
                            "requiredErrorText": "Please Select Your Time Availability",
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": "1-2 Hrs. Per Day",
                                    "text": "1-2 Hrs. Per Day"
                                },
                                {
                                    "value": "2-4 Hrs. Per Day",
                                    "text": "2-4 Hrs. Per Day"
                                },
                                {
                                    "value": "4-6 Hrs. Per Day",
                                    "text": "4-6 Hrs. Per Day"
                                },
                                {
                                    "value": "6+ Hrs. Per Day",
                                    "text": "6+ Hrs. Per Day"
                                }
                            ]
                        },
                        {
                            "type": "text",
                            "name": "defaultTimeZone",
                            "title": "Default Time Zone",
                            "hideNumber": true,
                            "placeHolder": "Default Time Zone"
                        },
                        {
                            "type": "text",
                            "name": "userLinkedinUrl",
                            "title": "LinkedIn URL",
                            "hideNumber": true,
                            "validators": [
                                {
                                    "type": "regex",
                                    "text": "Please enter valid LinkedIn URL",
                                    "regex": "/(https?)?:?(\\/\\/)?(([w]{3}||\\w\\w)\\.)?linkedin.com(\\w+:{0,1}\\w*@)?(\\S+)(:([0-9])+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?/"
                                }
                            ],
                            "inputType": "url",
                            "placeHolder": "LinkedIn URL"
                        }
                    ],
                    "description": "Freelancer Registration"
                }
            ]


            // "showPreviewBeforeComplete": "showAllQuestions"
        },
        "Expert Consultation": {
            "title": "Phone / Video Consulting",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "comment",
                            "name": "howexperthelp",
                            "title": "How can our experts help you?",
                            "hideNumber": true,
                            "isRequired": true
                        },
                        {
                            "type": "panel",
                            "name": "panel1",
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "startdate",
                                    "title": "Select Start Date",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "date",
                                    "autoComplete": "bdate",
                                    "minValueExpression": "minDate()"
                                },
                                {
                                    "type": "text",
                                    "name": "enddate",
                                    "startWithNewLine": false,
                                    "title": "Select End Date",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "date",
                                    "autoComplete": "bdate",
                                    "minValueExpression": "minDate()"
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel2",
                            "elements": [
                                {
                                    "type": "radiogroup",
                                    "name": "expertbackground",
                                    "startWithNewLine": false,
                                    "title": "Background of expert",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "choices": [
                                        {
                                            "value": "Any",
                                            "text": "Any"
                                        },
                                        {
                                            "value": "Professional",
                                            "text": "Professional"
                                        },
                                        {
                                            "value": "Academic",
                                            "text": "Academic"
                                        }
                                    ],
                                    "colCount": 3
                                },
                                {
                                    "type": "text",
                                    "name": "expertcount",
                                    "startWithNewLine": false,
                                    "title": "How many experts do you want to connect?",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "number",
                                    "min": "1",
                                    "max": "10",
                                    "step": 1
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel3",
                            "elements": [
                                {
                                    "type": "tagbox",
                                    "name": "selectindustry",
                                    "startWithNewLine": false,
                                    "title": "Industry",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "requiredErrorText": "Please select industry",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/industry?fields=[%22industryName%22]&sort=industryName:asc&limit=50`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "industryName"
                                    }
                                },
                                {
                                    "type": "tagbox",
                                    "name": "selecttechnology",
                                    "startWithNewLine": false,
                                    "title": "Technology",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "requiredErrorText": "Please select technology",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/technology?filters=[[%22dtParentId%22,%22eq%22,0]]&fields=[%22id%22,%22dtName%22]&sort=dtName:asc&limit=2000`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "dtName"
                                    }
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel4",
                            "elements": [
                                {
                                    "type": "tagbox",
                                    "name": "Function",
                                    "visibleIf": "{expertbackground} = 'Any' or {expertbackground} = 'Professional'",
                                    "minWidth": "",
                                    "startWithNewLine": false,
                                    "title": "Function",
                                    "hideNumber": true,
                                    "requiredIf": "{expertbackground} = 'Any' or {expertbackground} = 'Professional'",
                                    "requiredErrorText": "Please select Function",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/core/functional-expertise-open?fields=[]&sort=parent:asc,expertise:asc`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "expertise"
                                    }
                                },
                                {
                                    "type": "comment",
                                    "name": "briefnote",
                                    "title": "Please write a brief note that best describes the profile of experts, who would be most appropriate to address your business need.",
                                    "hideNumber": true
                                }
                            ]
                        }
                    ]
                }
            ],
            "showPageTitles": false,
            "showQuestionNumbers": "off",
            "completeText": "Pay & Publish",
            "showCompletedPage": false
        },
        "Custom Company Profile":
        {
            "title": "Request More Information",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "panel",
                            "name": "panel2",
                            "elements": [
                                {
                                    "type": "comment",
                                    "name": "companyprofile2",
                                    "title": "Develop a fully customized Company profile for this company",
                                    "hideNumber": true,
                                    "maxLength": 200
                                },
                                {
                                    "type": "comment",
                                    "name": "companyprofile1",
                                    "title": "Develop a fully customized people profile for the promoters and owners of this organization",
                                    "hideNumber": true,
                                    "maxLength": 200
                                },
                                {
                                    "type": "comment",
                                    "name": "companyprofile3",
                                    "title": "Develop a fully customized people profile for the management team/leadership in this organization",
                                    "hideNumber": true,
                                    "maxLength": 200
                                },
                                {
                                    "type": "comment",
                                    "name": "companyprofile4",
                                    "title": "Develop a fully customized people Profile for the Inventors in this organization",
                                    "hideNumber": true,
                                    "maxLength": 200
                                }
                            ],
                            "title": "Detailed Profile Request"
                        },
                        {
                            "type": "panel",
                            "name": "panel1",
                            "elements": [
                                {
                                    "type": "comment",
                                    "name": "ipqn",
                                    "title": "I have following questions related to this company's Intellectual Property",
                                    "hideNumber": true,
                                    "maxLength": 200
                                }
                            ],
                            "title": "Intellectual Property related Questions"
                        },
                        {
                            "type": "panel",
                            "name": "panel3",
                            "elements": [
                                {
                                    "type": "comment",
                                    "name": "partnerqn",
                                    "title": "Which organization(s) is the company collaborating/partnering with? What is the nature of collaboration/ partnership",
                                    "hideNumber": true,
                                    "maxLength": 100
                                }
                            ],
                            "title": "Collaborations & Partnerships"
                        },
                        {
                            "type": "panel",
                            "name": "panel4",
                            "elements": [
                                {
                                    "type": "comment",
                                    "name": "duediligenceqn",
                                    "title": "Conduct the following Financial / Business / M&A Due Diligence on this company",
                                    "hideNumber": true,
                                    "maxLength": 200
                                }
                            ],
                            "title": "Due Diligence"
                        },
                        {
                            "type": "panel",
                            "name": "panel5",
                            "elements": [
                                {
                                    "type": "comment",
                                    "name": "fundingqn",
                                    "title": "Who are the investors behind the Start-up? Please provide mrore details on Funding.",
                                    "hideNumber": true,
                                    "maxLength": 200
                                }
                            ],
                            "title": "Funding"
                        },
                        {
                            "type": "comment",
                            "name": "otherqn",
                            "title": "Other",
                            "hideNumber": true,
                            "maxLength": 200
                        }
                    ],
                    "questionTitleLocation": "top"
                }
            ],
            "showPageTitles": false,
            "showQuestionNumbers": "off",
            "completeText": "Save",
            "showCompletedPage": false
        },
        "Startup Connect Request": {
            "title": "Connect with Startup",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "text",
                            "name": "subject",
                            "title": "Subject",
                            "maxLength": 100
                        },
                        {
                            "type": "checkbox",
                            "name": "purpose",
                            "title": "Define your purpose",
                            "hideNumber": true,
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": "Seeking Technology Solution For",
                                    "text": "Seeking Technology Solution for"
                                },
                                {
                                    "value": "Seeking Venturing/Investment Opportunity",
                                    "text": "Seeking Venturing/Investment Opportunity"
                                },
                                {
                                    "value": "Seeking Acquisition Support",
                                    "text": "Seeking Acquisition Support"
                                },
                                {
                                    "value": "Seeking Technology Co-Development Opportunity",
                                    "text": "Seeking Technology Co-Development Opportunity"
                                }
                            ]
                        },
                        {
                            "type": "comment",
                            "name": "description",
                            "title": "Please provide a brief description of your reason to connect",
                            "hideNumber": true,
                            "isRequired": true,
                            "maxLength": 200
                        },
                        {
                            "type": "html",
                            "name": "whentoconnect",
                            "html": "When would you like to connect with the start-ups?"
                        },
                        {
                            "type": "text",
                            "name": "startdate",
                            "title": "Select Start Date",
                            "isRequired": true,
                            "inputType": "date",
                            "autoComplete": "bdate",
                            "minValueExpression": "minDate()"
                        },
                        {
                            "type": "text",
                            "name": "enddate",
                            "startWithNewLine": false,
                            "title": "Select End Date",
                            "isRequired": true,
                            "inputType": "date",
                            "autoComplete": "bdate",
                            "minValueExpression": "minDate()"
                        },
                        {
                            "type": "checkbox",
                            "name": "confirmation",
                            "titleLocation": "hidden",
                            "choices": [
                                {
                                    "value": "I would like to meet/explore other similar startups.",
                                    "text": "I would like to meet/explore other similar startups."
                                }
                            ]
                        }
                    ],
                    "questionTitleLocation": "top"
                }
            ],
            "showPageTitles": false,
            "showQuestionNumbers": "off",
            "completeText": "Pay & Publish",
            "showCompletedPage": false
        },
        "Startup Identification": {
            "title": "Request Startup Identification",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "text",
                            "name": "companyprofile",
                            "title": "Custom Company Profile",
                            "hideNumber": true,
                            "isRequired": true,
                            "maxLength": 100
                        },
                        {
                            "type": "checkbox",
                            "name": "purpose",
                            "title": "Define Your Purpose",
                            "hideNumber": true,
                            "isRequired": true,
                            "choices": [
                                {
                                    "value": "Seeking Technology Solution for",
                                    "text": "Seeking Technology Solution for"
                                },
                                {
                                    "value": "Seeking Venturing/Investment Opportunity",
                                    "text": "Seeking Venturing/Investment Opportunity"
                                },
                                {
                                    "value": "Seeking Acquisition Support",
                                    "text": "Seeking Acquisition Support"
                                }
                            ]
                        },
                        {
                            "type": "comment",
                            "name": "briefnote",
                            "title": "Brief Note Describing Your Requirement &amp; Business Objective",
                            "hideNumber": true,
                            "isRequired": true,
                            "maxLength": 200,
                            "placeHolder": ""
                        },
                        {
                            "type": "comment",
                            "name": "geofocus",
                            "title": "Any Geography / Region Focus",
                            "hideNumber": true,
                            "isRequired": true,
                            "maxLength": 200,
                            "placeHolder": ""
                        },
                        {
                            "type": "html",
                            "name": "whentoconnect",
                            "html": "When would you like to connect with the start-ups?"
                        },
                        {
                            "type": "text",
                            "name": "startdate",
                            "title": "Select Start Date",
                            "isRequired": true,
                            "inputType": "date",
                            "autoComplete": "bdate",
                            "minValueExpression": "minDate()"
                        },
                        {
                            "type": "text",
                            "name": "enddate",
                            "startWithNewLine": false,
                            "title": "Select End Date",
                            "isRequired": true,
                            "inputType": "date",
                            "autoComplete": "bdate",
                            "minValueExpression": "minDate()"
                        },
                        {
                            "type": "checkbox",
                            "name": "confirmation",
                            "titleLocation": "hidden",
                            "choices": [
                                {
                                    "value": "I would like to meet/explore other similar startups.",
                                    "text": "I would like to meet/explore other similar startups."
                                }
                            ]
                        }
                    ],
                    "questionTitleLocation": "top"
                }
            ],
            "showPageTitles": false,
            "showQuestionNumbers": "off",
            "completeText": "Pay & Publish",
            "showCompletedPage": false
        },
        "Survey": {
            "title": "Expert Survey",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "pages": [
                {
                    "name": "page1",
                    "elements": [
                        {
                            "type": "comment",
                            "name": "howexperthelp",
                            "title": "How can our experts help you?",
                            "hideNumber": true,
                            "isRequired": true
                        },
                        {
                            "type": "panel",
                            "name": "panel1",
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "startdate",
                                    "title": "Select Start Date",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "date",
                                    "autoComplete": "bdate",
                                    "minValueExpression": "minDate()"
                                },
                                {
                                    "type": "text",
                                    "name": "enddate",
                                    "startWithNewLine": false,
                                    "title": "Select End Date",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "date",
                                    "autoComplete": "bdate",
                                    "minValueExpression": "minDate()"
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel2",
                            "elements": [
                                {
                                    "type": "radiogroup",
                                    "name": "expertbackground",
                                    "startWithNewLine": false,
                                    "title": "Background of expert",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "choices": [
                                        {
                                            "value": "Any",
                                            "text": "Any"
                                        },
                                        {
                                            "value": "Professional",
                                            "text": "Professional"
                                        },
                                        {
                                            "value": "Academic",
                                            "text": "Academic"
                                        }
                                    ],
                                    "colCount": 3
                                },
                                {
                                    "type": "text",
                                    "name": "expertcount",
                                    "startWithNewLine": false,
                                    "title": "How many experts do you want to connect?",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "inputType": "number",
                                    "min": "1",
                                    "max": "10",
                                    "step": 1
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel3",
                            "elements": [
                                {
                                    "type": "tagbox",
                                    "name": "selectindustry",
                                    "startWithNewLine": false,
                                    "title": "Industry",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "requiredErrorText": "Please select industry",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/industry?fields=[%22industryName%22]&sort=industryName:asc&limit=50`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "industryName"
                                    }
                                },
                                {
                                    "type": "tagbox",
                                    "name": "selecttechnology",
                                    "startWithNewLine": false,
                                    "title": "Technology",
                                    "hideNumber": true,
                                    "isRequired": true,
                                    "requiredErrorText": "Please select technology",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/outsourcing/technology?filters=[[%22dtParentId%22,%22eq%22,0]]&fields=[%22id%22,%22dtName%22]&sort=dtName:asc&limit=2000`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "dtName"
                                    }
                                }
                            ]
                        },
                        {
                            "type": "panel",
                            "name": "panel4",
                            "elements": [
                                {
                                    "type": "tagbox",
                                    "name": "Function",
                                    "visibleIf": "{expertbackground} = 'Any' or {expertbackground} = 'Professional'",
                                    "minWidth": "",
                                    "startWithNewLine": false,
                                    "title": "Function",
                                    "hideNumber": true,
                                    "requiredIf": "{expertbackground} = 'Any' or {expertbackground} = 'Professional'",
                                    "requiredErrorText": "Please select Function",
                                    "choicesByUrl": {
                                        "url": `${process.env.REACT_APP_CMS_API_URL}/core/functional-expertise-open?fields=[]&sort=parent:asc,expertise:asc`,
                                        "path": "data",
                                        "valueName": "id",
                                        "titleName": "expertise"
                                    }
                                },
                                {
                                    "type": "comment",
                                    "name": "briefnote",
                                    "title": "Please write a brief note that best describes the profile of experts, who would be most appropriate to address your business need.",
                                    "hideNumber": true
                                }
                            ]
                        }
                    ]
                }
            ],
            "showPageTitles": false,
            "showQuestionNumbers": "off",
            "completeText": "Pay & Publish",
            "showCompletedPage": false
        }

    },

    CUSTOM_FORM_REGISTRATION_DOMAIN: process.env.REACT_APP_CUSTOM_FORM_REGISTRATION_DOMAIN || "http://localhost:3001/register",
};
