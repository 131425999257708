/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Basic Details
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { surveyAcknowledgementValidationSchema } from "../validationSchema/index";
import { fetchSingleSurvey } from "../../../../../middleware/services/surveyApi";
import { CkEditor, Checkbox } from "../formFields";
import ActionButtons from "../actionButtons";
import SURVEY_CONSTANT from "../../constants";
import { TextField, MultiSelectNew } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { setAcknowledgement } from "../../../../../middleware/actions/surveyAction";
import AccessDenied from "../../../../components/accessDenied";

const defaultMailContent = "Hi {FirstName} {LastName},  <br /><br />" + "Thank you for your valuable time . <br /><br />" + "Thank you, ";
const initialState = {
  senderMail: "",
  senderName: "",
  mailSubject: "",
  mailBody: "",
  includeMailSignature: "YES",
  email_cc: [],
  email_cc2: [],
  email_bcc: [],
  email_bcc2: [],
  email_reply: "",
};
const Acknowledgement = (props) => {
  const dispatch = useDispatch();
  const surveyId = props.surveyId || "";
  const [surveyData, setSurveyData] = useState(null);
  const surveyCommonState = useSelector((state) => state.surveyState);
  let acknowledgementDetail = Object.keys(surveyCommonState?.acknowledgementDetails).length > 0 ? surveyCommonState.acknowledgementDetails : null;
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(surveyAcknowledgementValidationSchema),
    validateOnMount: true,
  });
  const breadCrumbLinks = [
    { linkUrl: "/survey", linkName: "Survey", linkActive: false },
    {
      linkUrl: surveyId ? `/survey/${surveyId}/basic-details#acknowledgement` : "/survey/basic-details#acknowledgement",
      linkName: surveyData?.surveySerialNo,
      linkActive: false,
    },

    {
      linkUrl: `/survey/${surveyId}/edit${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  // save survey data
  const saveSurvey = async () => {
    formik.validateForm().then((res) => {
      if (res["email_cc2"]) {
        res["email_cc2"] = res["email_cc2"].join();
      }
      if (res["email_bcc2"]) {
        res["email_bcc2"] = res["email_bcc2"].join();
      }
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        // common save function
        props.handleCommonSave(surveyId);
      }
    });
  };
  useEffect(() => {
    formik.validateForm();
  }, []);

  const fetchSurvey = () => {
    let params = {};
    let fields = ["surveySerialNo", "surveyShortName", "surveyTitle", "surveyAcknowledgementMailTemplate", "surveyInvitationStatus", "surveyStatus"];
    params.fields = fields;
    fetchSingleSurvey(surveyId, params).then((res) => {
      let data = res.data.data || {};
      setSurveyData(data);
      formik.setValues({
        senderMail: acknowledgementDetail?.senderMail ? acknowledgementDetail?.senderMail : data.surveyAcknowledgementMailTemplate?.senderMail || "",

        senderName: acknowledgementDetail?.senderName ? acknowledgementDetail?.senderName : data.surveyAcknowledgementMailTemplate?.senderName || "",

        mailSubject: acknowledgementDetail?.mailSubject
          ? acknowledgementDetail?.mailSubject
          : data.surveyAcknowledgementMailTemplate?.mailSubject || "",

        mailBody: acknowledgementDetail?.mailBody
          ? acknowledgementDetail?.mailBody
          : data.surveyAcknowledgementMailTemplate && data.surveyAcknowledgementMailTemplate?.mailBody
          ? data.surveyAcknowledgementMailTemplate.mailBody
          : defaultMailContent,

        includeMailSignature: acknowledgementDetail?.includeMailSignature
          ? acknowledgementDetail?.includeMailSignature
          : data.surveyAcknowledgementMailTemplate?.includeMailSignature
          ? data.surveyAcknowledgementMailTemplate.includeMailSignature
          : "YES",

        email_cc: acknowledgementDetail?.email_cc2
          ? acknowledgementDetail?.email_cc2
          : data.surveyAcknowledgementMailTemplate?.emailCc
          ? data.surveyAcknowledgementMailTemplate.emailCc?.map((email) => {
              return { label: email, value: email };
            })
          : [],

        email_cc2: acknowledgementDetail?.email_cc2
          ? acknowledgementDetail?.email_cc2
          : data.surveyAcknowledgementMailTemplate?.emailCc
          ? data.surveyAcknowledgementMailTemplate.emailCc?.map((email) => {
              return { label: email, value: email };
            })
          : [],

        email_bcc: acknowledgementDetail?.email_bcc2
          ? acknowledgementDetail?.email_bcc2
          : data.surveyAcknowledgementMailTemplate?.emailBcc
          ? data.surveyAcknowledgementMailTemplate.emailBcc?.map((email) => {
              return { label: email, value: email };
            })
          : [],
        email_bcc2: acknowledgementDetail?.email_bcc2
          ? acknowledgementDetail?.email_bcc2
          : data.surveyAcknowledgementMailTemplate?.emailBcc
          ? data.surveyAcknowledgementMailTemplate.emailBcc?.map((email) => {
              return { label: email, value: email };
            })
          : [],

        email_reply: acknowledgementDetail?.email_reply
          ? acknowledgementDetail?.email_reply
          : data.surveyAcknowledgementMailTemplate?.emailReply
          ? data.surveyAcknowledgementMailTemplate.emailReply
          : "",
      });
      formik.validateForm();
    });
  };

  useEffect(() => {
    if (surveyId) {
      if (!surveyData) fetchSurvey();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));

    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, surveyData, props.selectedIndex]);

  useEffect(() => {
    dispatch(setAcknowledgement(formik.values));
  }, [formik.values]);

  useEffect(() => {
    props.setTabErrors((prev) => {
      return {
        ...prev,
        acknowledgement: Object.keys(formik?.errors)?.length ? true : false,
      };
    });
  }, [formik.errors]);

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="pt-3 mx-2">
            <div className="gennx-content-wrapper padding-bottom-150i" style={{ width: "99.5%", overflow: "hidden" }}>
              <div style={{ background: "#FFFFFF", overflow: "hidden" }}>
                <div id="content-box">
                  <div className="container-fluid mb-3">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mt-3">
                                <TextField
                                  formik={formik}
                                  required={true}
                                  id="senderMail"
                                  placeholder="Please enter sender's email *"
                                  name="senderMail"
                                />
                              </div>

                              <small>
                                <span>
                                  <b>Note :-</b> Sender's email valid domains are <b>futurebridge.com</b>, <b>cognition-solutions.com</b>,{" "}
                                  <b>cheersin.com</b> and <b>whatnextglobal.com</b> eg. xyz@cheersin.com
                                </span>
                              </small>

                              <div className="mt-3">
                                <TextField
                                  formik={formik}
                                  required={true}
                                  id="senderName"
                                  placeholder="Please enter sender's name *"
                                  name="senderName"
                                />
                              </div>

                              <div className="mt-3">
                                <TextField
                                  formik={formik}
                                  required={true}
                                  id="mailSubject"
                                  placeholder="Please enter email subject *"
                                  name="mailSubject"
                                />
                              </div>
                              <MultiSelectNew
                                formik={formik}
                                mode="Box"
                                sortOrder="Acending"
                                showSelectAll={false}
                                placeholder="Email cc "
                                id="email_cc"
                                name="email_cc2"
                                allowCustomValue={true}
                                value={
                                  formik.values.email_cc &&
                                  formik.values.email_cc.map((v) => {
                                    return v.label;
                                  })
                                }
                                dataSource={formik.values.email_cc}
                                fields={{ text: "label", value: "value" }}
                                noRecordsTemplate={() => <div>Please Enter Members </div>}
                              />
                              {/* {formik?.errors?.email_cc2?.map((err) => err && <div className="text-danger">{err}</div>)} */}
                              <MultiSelectNew
                                formik={formik}
                                mode="Box"
                                sortOrder="Acending"
                                showSelectAll={false}
                                placeholder="Email bcc  "
                                id="email_bcc"
                                name="email_bcc2"
                                allowCustomValue={true}
                                value={
                                  formik.values.email_bcc &&
                                  formik.values.email_bcc.map((v) => {
                                    return v.label;
                                  })
                                }
                                dataSource={formik.values.email_bcc}
                                fields={{ text: "label", value: "value" }}
                                noRecordsTemplate={() => <div>Please Enter Members </div>}
                              />

                              <TextField formik={formik} id="email_reply" placeholder="Enter Reply To " name="email_reply" />
                            </div>
                            <div className="col-md-8">
                              <CkEditor
                                formik={formik}
                                defaultContent={defaultMailContent}
                                properties={{
                                  id: "mailBody1",
                                  name: "mailBody",
                                  label: "Email Content",
                                }}
                              />
                              <Checkbox
                                formik={formik}
                                properties={{
                                  type: "checkbox",
                                  readOnly: false,
                                  id: "includeMailSignature",
                                  name: "includeMailSignature",
                                  label: "Include Email Signature",
                                }}
                              />
                              <small>
                                <div className="d-flex">
                                  <span className="font-weight-bold">Note : </span>
                                  <span className="pl-2">
                                    <li style={{ listStyleType: "none" }}>
                                      Mail content must contains these placeholder{" "}
                                      <b>
                                        {"{FirstName}"},{"{LastName}"}
                                      </b>{" "}
                                      & <b>{"{SurveyLink}"}</b>. Please do not remove these.
                                    </li>
                                  </span>
                                </div>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </Accordion> */}
            </div>
          </div>

          <ActionButtons
            accessRights={props.accessRights}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            surveyId={surveyId ? surveyId : null}
            handleSave={
              surveyData?.surveyInvitationStatus !== SURVEY_CONSTANT.SURVEY_INVITATION_STATUS.SENDING &&
              !["Closed"].includes(surveyData?.surveyStatus)
                ? saveSurvey
                : null
            }
            tabsName={props.tabsName}
            moduleName={props.moduleName}
            hashArr={props.hashArr}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Acknowledgement;
