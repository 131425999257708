/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 26/Mar/2021
 * File Description: Survey Audience Modal Audience user upload or upload internal user
 */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { getProductVariant } from "../../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { surveyAudienceExternalUserValidationSchema, surveyAudienceInternalUserValidationSchema } from "../../validationSchema/index";
import { fetchClientUser } from "../../../../../../middleware/services/cmsApi";
import { addSurveyAudience, fetchImportTemplateFileUrl, uploadTemplateData } from "../../../../../../middleware/services/surveyApi";
import { Dropdown } from "../../../../../components/CustomFormElements/syncFusionFormFields";

const initialState = {
  clientUsers: null,
  documentFile: "",
  documentFileName: "",
};
const AddAudienceModal = (props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const surveyId = props.surveyId || "";
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  const productVariant = getProductVariant();

  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(
      props.openAs && props.openAs === "upload" ? surveyAudienceExternalUserValidationSchema : surveyAudienceInternalUserValidationSchema
    ),
  });

  const searchUser = async function () {
    const response = await fetchClientUser({ productVariant: productVariant });
    if (response.data && response.data.data) {
      setUsers(response.data.data);
      const userData = response.data.data.map((typesData) => {
        return {
          value: typesData.id["$id"],
          label: `${typesData["userFname"]} ${typesData["userLname"]}`,
        };
      });
      setUser(userData);
    }
  };
  const handleUploadTemplate = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        if (surveyId) {
          const formData = new FormData();
          formData.append("docFile", formik.values.documentFile[0]);

          uploadTemplateData(surveyId, formData)
            .then(() => {
              dispatch(actionSuccess("Survey audience uploaded successfully"));
              props.gridReload();
            })
            .catch((err) => {
              dispatch(
                actionError(
                  err?.data?.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)"
                )
              );
            });
        }
      }
    });
  };

  const saveAudienceDetails = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { clientUsers = undefined } = formik.values;
        if (surveyId) {
          const userDetails =
            clientUsers &&
            clientUsers.map((c) => {
              const u1 =
                users &&
                users.filter((u) => {
                  return u.id.$id.toString() === c.value.toString();
                })[0];
              return {
                userId: u1.id.$id,
                userFname: u1.userFname,
                userLname: u1.userLname,
                userEmail: u1.userEmail,
                // userSurveyResponse:u1.userSurveyResponse,userSurveyCode:u1.userSurveyCode
              };
            });

          payload.surveyAudience = userDetails;

          addSurveyAudience(surveyId, payload)
            .then(() => {
              dispatch(actionSuccess("Survey audience added successfully"));
              props.gridReload();
            })
            .catch((err) => {
              dispatch(
                actionError(
                  err?.data?.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)"
                )
              );
            });
        }
      }
    });
  };

  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: ".xls,.xlsx",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          props.formik.setValues({
            ...props.formik.values,
            documentFile: acceptedFiles,
          });
        }
      },
    });

    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            UPLOAD DATA TEMPLATE
          </button>{" "}
          Or drop file here
        </div>
        <small>
          Only xls, xlxs files are allowed.
          <a style={{ float: "right" }} href={fetchImportTemplateFileUrl()} download>
            {" "}
            <i className="fas fa-download" data-tip="Download" /> Download Template
          </a>
        </small>

        <div
          className="text-danger"
          style={{
            display: props.formik.touched.documentFile === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched.documentFile === true ? props.formik.errors.documentFile || "" : ""}</small>
        </div>
      </>
    );
  };

  useEffect(() => {
    props.show ? setShow(true) : setShow(false);
  }, [props.show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        props.closeHandler();
      }}
      scrollable
      centered
    >
      <Modal.Header closeButton className="bg-none text-dark">
        <Modal.Title>{props.openAs === "upload" ? "Import Audience" : "Select existing client user"}</Modal.Title>
      </Modal.Header>
      <div className="mt-2 ml-4 mr-4 ">
        <div className="row">
          <div className="col-md-12 mt-4">
            {props.openAs && props.openAs !== "upload" && (
              <Dropdown
                formik={formik}
                required={true}
                sortOrder="Acending"
                placeholder="Please select client user"
                id="clientUsers"
                name="clientUsers"
                fields={{ label: "label", value: "value" }}
                dataSource={user}
                open={searchUser}
              />
            )}
          </div>
        </div>
      </div>
      <Modal.Body>
        <>
          {" "}
          {props.openAs === "upload" && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <UploadComponent editDocumentData={props.editDocumentData} formik={formik} />
                    {formik.values.documentFile &&
                      formik.values.documentFile.map((file, i) => (
                        <li key={i}>
                          {`File:${file.name}
												`}{" "}
                        </li>
                      ))}
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12">
          <div className="float-right">
            <button
              type="button"
              onClick={(e) => {
                props.closeHandler();
              }}
              className="cy-audience_cancel cy-btn btn btn-outline-dark mr-3"
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={props.openAs && props.openAs === "upload" ? handleUploadTemplate : saveAudienceDetails}
              // disabled={disabled}
              className="btn btn-primary cy-btn"
            >
              {props.openAs === "upload" ? "UPLOAD" : "Save"}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default AddAudienceModal;

AddAudienceModal.propTypes = {
  surveyId: PropTypes.string.isRequired,
  openAs: PropTypes.string,
};
