import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import Accordion from "../../../../../components/accordion";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { fetchProductImagesByProductId, deleteProductImage, fetchProductImageUrl } from "../../../../../../middleware/services/productApi";
import ImageModal from "./imageModal";
import { accessRightActionCheck } from "../../../../../../utilities";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const ImageComponent = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const [editImageData, setEditImageData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = "Overview";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /* Common access */
  const accessPermissionAdd = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_IMAGES);
  const accessPermissionDelete = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_IMAGES);

  const getImages = async () => {
    const response = await fetchProductImagesByProductId(props.productId);
    setImageList(response.data?.data?.productImages || []);
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <Accordion heading="Images" stepCompleted={imageList && imageList.length > 0 ? true : false} step={4}>
      <div className="row p-3">
        <div className="col-md-12">
          <div className="image-wrapper">
            {imageList.map((item) => {
              return (
                <div className="image-holder" key={`pimg-${item.id}`}>
                  <img src={fetchProductImageUrl(item.imgFileName)} alt={item.imgName} />
                  <div className="image-action">
                    {accessPermissionDelete ? (
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            showAlertBox({
                              okCallback: async () => {
                                await deleteProductImage(props.productId, item.id)
                                  .then((resp) => {
                                    dispatch(actionSuccess("Image deleted successfully"));
                                    getImages();
                                  })
                                  .catch((err) => {
                                    dispatch(actionError(err.data.message || "Something went wrong"));
                                  });
                              },
                            })
                          );
                        }}
                      >
                        <i className="fa fa-trash mr-1 " aria-hidden="true" />
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="text-right">
        {accessPermissionAdd ? (
          <button
            type="button"
            className="btn btn-dark cy-btn"
            onClick={() => {
              setShowModal(!showModal);
              setEditImageData(false);
            }}
          >
            Add New
          </button>
        ) : null}
      </div>
      {showModal && (
        <ImageModal
          editImageData={editImageData}
          showModal={showModal}
          productId={props.productId}
          clicked={() => {
            setShowModal(!showModal);
            getImages();
          }}
        />
      )}
    </Accordion>
  );
};
export default ImageComponent;
ImageComponent.propTypes = {
  productId: PropTypes.string,
  productId: PropTypes.string,
};
