/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Customizable Widgets Add | Edit
   Summary : Customizable Widgets
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 01/Oct/2023 
  Author						  : Satya Prakash Mall
================================================================ 

  Creation Details 
  Date Created				: 19/June/2024 
  Author						  : Narenkumar Krishnan
  Description         : React grid layout implemented for dynamic render along with merge and split functionalites responsively. 
================================================================ 
*/
import React, { useEffect, useState, useRef, useMemo, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, MultiSelect } from "../../components/CustomFormElements/syncFusionFormFields";
import { fetchTaxonomyTrend, getCombinedData, fetchWidgetList, getContentType, getObjectiveSgfTag } from "../../../middleware/services/cmsApi";
import {
  fetchWidget,
  fetchWidgetLayout,
  updateWidget,
  saveSingleWidget,
  saveWidget,
  deleteWidget,
  updateSingleWidgetLayout,
} from "../../../middleware/services/customizableWidgetApi";
import { getLoggedInUser, getProductVariant, accessRightActionCheck } from "../../../utilities";
import CommonDateTagSection from "../../components/CommonDateTagSection/CommonDateTagSection";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { fetchNews } from "../../../middleware/services/newsApi";
import { fetchVideos } from "../../../middleware/services/videoApi";
import { fetchCompany, fetchImageURL } from "../../../middleware/services/companyApi";
import { fetchDeliverables, fetchSingleDeliverable } from "../../../middleware/services/deliverablesApi";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ReactComponent as SixDotLogo } from "../../../assets/images/sixDots.svg";
import SidebarModal from "../../components/sidebar-modal";
import InfoIconwithImage from "../../components/InfoIconwithImage/InfoIconwithImage";
import "./index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getNewsModifiedObj, getCompanyModifiedObj, getDeliverableModifiedObj, getVideoModifiedObj } from "./helperService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { PiArrowSquareRightThin, PiArrowSquareLeftThin } from "react-icons/pi";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import emptyImage from "../../../assets/images/images/empty.svg";
import KeyPlayersTagSection from "../../components/CommonDateTagSection/KeyPlayersTagSection";
import { COMMON_ACCESS_ACTION } from "../../../constants";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import {
  PINNED_CONTENT,
  WIDGETS,
  ADD_WIDGETS,
  ADD_CONTENT,
  WIDGET_FILTER,
  GRID_MEASURES,
  MERGE_TEXT,
  SPLIT_TEXT,
  WIDGET_DISPLAY_NAME_MAX_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_MIN_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_REQUIRED_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_MATCHES_VALIDATION_TEXT,
  SAVE_DASHBOARD_TEXT,
  LAYOUT_SUCCESS_MESSAGE,
  LAYOUT_CONFIRMATION_MESSAGE,
  WIDGET_REMOVE_CONFIRMATION_MESSAGE,
  WIDGET_REMOVE_SUCCESS_MESSAGE,
  WIDGET_DELETE_FAILED_MESSAGE,
  PINNED_UPDATED_SUCCESS_MESSAGE,
  PINNED_ADDED_SUCCESS_MESSAGE,
  WIDGET_UPDATE_MESSAGE,
  WIDGET_ADDED_SUCCESS_MESSAGE,
  LAYOUT_ORDER
} from "../../../constants/widget-constants";

import { ReactComponent as Merge } from "../../../assets/images/merge-icon.svg";
import { ReactComponent as Split } from "../../../assets/images/split-icon.svg";
import CustomizableWidgetSidebar from "app/components/CustomizableWidgetSidebar";
import { useRenderComponent } from "../../../hooks/useRenderComponent";
import AddWidgetSideBarModal from "app/components/widgetAddSidebar";
import { fetchConfig } from "middleware/services/cmsApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getData, getKeyPlyersData } from "../../../utils/index";
import {
  setCombinedData,
  setRightSideWidgetList,
  setSelectedWidgetData,
  hideWidgetLoader,
  setPreviewData,
} from "middleware/actions/customizableWidgetActions";
import "react-grid-layout/css/styles.css";
import { fetchSingleUser } from "middleware/services/userApi";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

/** Tabs used in the key players */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
/** */

const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const cols = { lg: 12, md: 8, sm: 6, xs: 4, xxs: 2 };

const GridComponent = ({
  name,
  text,
  layout,
  resolution,
  splitConfig,
  compactType,
  onLayoutChange,
  onDrag,
  handleAddClick,
  mergeWidgets,
  splitWidgets,
  onDeleteClick,
  onSettingClick,
  widgetData,
  widgetConfig,
  isDroppable,
  isDraggable,
  isResizable,
}) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(resolution);
  const onBreakpointChange = (newBreakpoint) => {
    setCurrentBreakpoint(newBreakpoint);
  };
  const renderComponent = useRenderComponent();

  return (
    <ResponsiveReactGridLayout
      // style={{ background: '#f0f0f0', width: '100%', height: '372px' }}
      style={{ background: "#f0f0f0", width: "100%", height: "40vh" }}
      layouts={layout}
      breakpoints={breakpoints}
      cols={cols}
      rowHeight={70}
      maxRows={4}
      measureBeforeMount={false}
      useCSSTransforms={true}
      compactType={compactType}
      preventCollision={false}
      onLayoutChange={(layout, allLayouts) => {
        onLayoutChange(layout, allLayouts, name, currentBreakpoint);
      }}
      onDrag={onDrag}
      // onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts, name)}
      onBreakpointChange={onBreakpointChange}
      isDroppable={isDroppable}
      isDraggable={isDraggable}
      isResizable={isResizable}
      draggableHandle=".drag-handle"
    >
      {layout?.[currentBreakpoint]?.length > 0 &&
        layout?.[currentBreakpoint]?.map((widget, index) => (
          // <div className={`widget-box ${index % 2 == 0 ? 'merge-icon-view-even' : 'merge-icon-view-odd'}`} key={widget?.i}>
          <div
            className={`widget-box ${splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === false ? "merge-icon-view-even" : "merge-icon-view-odd"
              }`}
            key={widget?.i}
          >
            {renderComponent(widget?.i, name, {
              name: name,
              text: text,
              onDeleteClick: onDeleteClick,
              onSettingClick: onSettingClick,
              widgetName: widget?.i,
              widgetData: widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)),
              handleAddClick: handleAddClick,
              isMerge: splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged,
              dragHandleClass: "drag-handle",
            })}

            {splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.dataKeys?.length === 0 &&
              name === "pinned" &&
              !widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)) &&
              splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === false && (
                <div className="merge-icon">
                  <Tooltip title={MERGE_TEXT} arrow>
                    <Merge
                      height={"24px"}
                      width={"24px"}
                      cursor={"pointer"}
                      onClick={() => mergeWidgets(widget?.i, layout?.[currentBreakpoint]?.[index + 1]?.i, name, currentBreakpoint)}
                      data-tip={MERGE_TEXT}
                    />
                  </Tooltip>
                </div>
              )}
            {splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.dataKeys?.length === 0 &&
              name === "pinned" &&
              !widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)) &&
              splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === true && (
                <div className="split-icon">
                  <Tooltip title={SPLIT_TEXT} arrow>
                    <Split
                      height={"24px"}
                      width={"24px"}
                      cursor={"pointer"}
                      onClick={() => splitWidgets(widget?.i, name, currentBreakpoint)}
                      data-tip={SPLIT_TEXT}
                    />
                  </Tooltip>
                </div>
              )}
          </div>
        ))}
    </ResponsiveReactGridLayout>
  );
};

const CustomizableWidgets = (props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let searchBoxRef = useRef();
  let topSignalsSearchBoxRef = useRef();
  let keyPlayersSearchBoxRef = useRef();
  const productVariant = getProductVariant();
  const [industry, setIndustry] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [widgetIndustryName, setWidgetIndustryName] = useState("");
  const [widgetIndustryId, setWidgetIndustryId] = useState("");
  const [openTopSideBar, setOpenTopSideBar] = useState(false);
  const [widgetName, setWidgetName] = useState("");
  const [widgetId, setWidgetId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditWidget, setIsEditWidget] = useState(false);
  const [openTopSignalsSideBar, setOpenTopSignalsSideBar] = useState(false);
  const [openKeyPlayersSideBar, setOpenKeyPlayersSideBar] = useState(false);
  const [htmlCode, setHtmlCode] = useState("");
  const [previewHtml, setPreviewHtml] = useState(false);
  const [sortedWidgetOneIds, setSortedWidgetOneIds] = useState(null);
  const [sortedWidgetTwoIds, setSortedWidgetTwoIds] = useState(null);
  const [sortedWidgetTopSignalsIds, setSortedWidgetTopSignalsIds] = useState(null);
  const [sortedWidgetKeyPlayersIds, setSortedWidgetKeyPlayersIds] = useState(null);
  const [sortedWidgetIds, setSortedWidgetIds] = useState([]);
  const [sortedWidgetOneList, setSortedWidgetOneList] = useState(null);
  const [sortedWidgetTwoList, setSortedWidgetTwoList] = useState(null);
  const [sortedWidgetTopSignalsList, setSortedWidgetTopSignalsList] = useState(null);
  const [sortedWidgetKeyPlayersList, setSortedWidgetKeyPlayersList] = useState(null);
  const [sortedWidgetList, setSortedWidgetList] = useState([]);
  const [contentTypeDataSource, setContentTypeDataSource] = useState([]);
  const [deliverableTypeDataSource, setDeliverableTypeDataSource] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [allWidgetList, setAllWidgetList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [topSignalsSearchText, setTopSignalsSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [topSignalsSearchTextFilter, setTopSignalsSearchTextFilter] = useState("");
  const [widgetContentTypeFilter, setWidgetContentTypeFilter] = useState([]);
  const [widgetDeliverableTypeFilter, setWidgetDeliverableTypeFilter] = useState([]);
  const [widgetSGFFilter, setWidgetSGFFilter] = useState("");
  const [widgetTrendFilter, setWidgetTrendFilter] = useState("");
  const [trends, setTrends] = useState([]);
  const [sgfName, setSgfName] = useState("");
  const [sgfTrendData, setSgfTrendData] = useState([]);
  const [selectedTrend, setSelectedTrend] = useState(undefined);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [topSignalsSort, setTopSignalsSort] = useState("Newest");
  const [keyPlayersSort, setKeyPlayersSortt] = useState("Newest");
  const [topSignalsTimer, setTopSignalsTimer] = useState(5000);
  const { accessRights: aR } = props;
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const rightWidgetList = useSelector((state) => state.customizableWidgetState.rightWidgetList);
  let userId = getLoggedInUser();


  // const dashboardInitialLayout = {
  //   lg: [
  //     { i: "FeaturedContent", x: 0, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "DisruptiveSignals", x: 3, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "Keyplayers", x: 6, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "LatestNews", x: 9, y: 0, w: 3, h: 5, moved: false, static: true },
  //   ],
  //   md: [
  //     { i: "FeaturedContent", x: 0, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "DisruptiveSignals", x: 2, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "Keyplayers", x: 4, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "LatestNews", x: 6, y: 0, w: 2, h: 4, moved: false, static: true },
  //   ],
  //   sm: [
  //     { i: "FeaturedContent", x: 0, y: 0, w: 3, h: 4, moved: false, static: true },
  //     { i: "DisruptiveSignals", x: 3, y: 0, w: 3, h: 4, moved: false, static: true },
  //     { i: "Keyplayers", x: 0, y: 1, w: 3, h: 4, moved: false, static: true },
  //     { i: "LatestNews", x: 3, y: 1, w: 3, h: 4, moved: false, static: true },
  //   ],
  //   xs: [
  //     { i: "FeaturedContent", x: 0, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "DisruptiveSignals", x: 2, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "Keyplayers", x: 0, y: 1, w: 2, h: 4, moved: false, static: true },
  //     { i: "LatestNews", x: 2, y: 1, w: 2, h: 4, moved: false, static: true },
  //   ],
  //   xxs: [
  //     { i: "FeaturedContent", x: 0, y: 0, w: 1, h: 4, moved: false, static: true },
  //     { i: "DisruptiveSignals", x: 1, y: 0, w: 1, h: 4, moved: false, static: true },
  //     { i: "Keyplayers", x: 0, y: 1, w: 1, h: 4, moved: false, static: true },
  //     { i: "LatestNews", x: 1, y: 1, w: 1, h: 4, moved: false, static: true },
  //   ],
  // };

  // const pinnedContentInitialLayout = {
  //   lg: [
  //     { i: "widget_5", x: 0, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "widget_6", x: 3, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "widget_7", x: 6, y: 0, w: 3, h: 5, moved: false, static: true },
  //     { i: "widget_8", x: 9, y: 0, w: 3, h: 5, moved: false, static: true },
  //   ],
  //   md: [
  //     { i: "widget_5", x: 0, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_6", x: 2, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_7", x: 4, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_8", x: 6, y: 0, w: 2, h: 4, moved: false, static: true },
  //   ],
  //   sm: [
  //     { i: "widget_5", x: 0, y: 0, w: 3, h: 4, moved: false, static: true },
  //     { i: "widget_6", x: 3, y: 0, w: 3, h: 4, moved: false, static: true },
  //     { i: "widget_7", x: 0, y: 1, w: 3, h: 4, moved: false, static: true },
  //     { i: "widget_8", x: 3, y: 1, w: 3, h: 4, moved: false, static: true },
  //   ],
  //   xs: [
  //     { i: "widget_5", x: 0, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_6", x: 2, y: 0, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_7", x: 0, y: 1, w: 2, h: 4, moved: false, static: true },
  //     { i: "widget_8", x: 2, y: 1, w: 2, h: 4, moved: false, static: true },
  //   ],
  //   xxs: [
  //     { i: "widget_5", x: 0, y: 0, w: 1, h: 4, moved: false, static: true },
  //     { i: "widget_6", x: 1, y: 0, w: 1, h: 4, moved: false, static: true },
  //     { i: "widget_7", x: 0, y: 1, w: 1, h: 4, moved: false, static: true },
  //     { i: "widget_8", x: 1, y: 1, w: 1, h: 4, moved: false, static: true },
  //   ],
  // };

  //responsive layout config
  const [layouts, setLayouts] = useState({
    dashboard: {},
    pinned: {},
  });
  const [changeLayouts, setChangeLayouts] = useState({
    dashboard: {},
    pinned: {},
  });
  const [currentLayout, setCurrentLayout] = useState([]);
  const [widgetLayout, setWidgetLayout] = useState("");
  const [pinnedContentMerge, setPinnedContentMerge] = useState({});
  const [grid, setGrid] = useState("lg");

  /* Common access */
  const accessActionSettings = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SETTINGS);

  /** State for the key players for tabs
   * - Newly Added: newlyaddedWidgetList, newlyAddedWidgetIds
   * - Recently Updated: recentlyUpdatedWidgetList, recentlyUpdatedWidgetIds
   */
  const [updatedWidgetList, setUpdatedWidgetList] = useState([]);
  const [updatedWidgetIds, setUpdatedWidgetIds] = useState([]);
  const [newlyaddedWidgetList, setNewlyaddedWidgetList] = useState([]);
  const [newlyAddedWidgetIds, setNewlyAddedWidgetIds] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [keyPlayersData, setKeyplayersData] = useState();
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [widName, setWidName] = useState("");
  const [keyPlayersSearchTextFilter, setKeyPlayersSearchTextFilter] = useState("");
  const [keyPlayersSearchText, setKeyPlayersSearchText] = useState("");
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
  const [newsContentCuration, setNewsContentCuration] = useState("manual");
  const [selectedWidgetUniqueName, setSelectedWidgetUniqueName] = useState("");
  const [singleWidgetData, setSingleWidgetData] = useState({});
  const [selectedTheme, setSelectedTheme] = useState("");
  const [widgetDataIds, setWidgetDataIds] = useState([]);
  const { combinedWidgetData } = useSelector((state) => state?.customizableWidgetState);
  const { selectedWidgetData } = useSelector((state) => state?.customizableWidgetState);
  const [newsTabValue, setNewsTabValue] = useState("1");
  const [newlyAddedWidgetList, setNewlyAddedWidgetList] = useState([]);
  const [recentlyUpdatedWidgetList, setRecentlyUpdatedWidgetList] = useState([]);
  const [widgetStatus, setWidgetStatus] = useState({
    featured_content: false,
    signals: false,
    news: false,
    key_players: false,
    upcoming: false,
    new: false,
    key_players_2: false,
  });
  const [sortValue, setSortValue] = React.useState("mostViews");
  const [newsCount, setNewsCount] = React.useState(15);
  const [isNewsCuration, setIsNewsCuration] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  let isReturned = true;
  let isNewlyAddedReturned = true;
  let isRecentlyReturnd = true;
  let isReturnedTopSignals = true;

  const formik = useFormik({
    initialValues: {
      widgetName: "",
      widgetUniqueName: "",
      widgetDisplayName: "",
      widgetContentType: [],
      widgetDeliverableType: [],
      widgetTopSignalsContentType: "Signals",
      widgetTopSignalsDeliverableType: "Top Signals",
      widgetKeyPlayersContentType: "Company/Startups",
      widgetSGF: "",
      widgetTrend: "",
      widgetDashboardContentLink: "",
      widgetTheme: {
        themeColor: "",
        themeName: "",
      },
      isMerged: "NO",
    },
    validationSchema: Yup.object().shape({
      widgetDisplayName: Yup.string()
        .trim()
        .min(3, WIDGET_DISPLAY_NAME_MIN_VALIDATION_TEXT)
        .max(25, WIDGET_DISPLAY_NAME_MAX_VALIDATION_TEXT)
        .required(WIDGET_DISPLAY_NAME_REQUIRED_VALIDATION_TEXT)
        .matches(/^(?=.*[a-zA-Z])(?=.*[&-]?)[a-zA-Z\d\s&-]+$/, WIDGET_DISPLAY_NAME_MATCHES_VALIDATION_TEXT),
    }),
  });

  const resetFormik = () => {
    formik.setValues(formik.initialValues);
  };

  const handleCardClick = (widget) => {
    const deliverableType = WIDGET_FILTER[widget?.name]?.deliverableType || [];
    const contentType = WIDGET_FILTER[widget?.name]?.contentType || [];
    formik.setValues({
      ...formik.values,
      widgetDisplayName: widget?.displayName,
      widgetName: widget?.displayName,
      widgetConfigName: widget?.name,
      widgetDashboardContentLink: widget?.widgetDashboardContentLink,
      widgetContentType: contentType,
      widgetDeliverableType: deliverableType,
      widgetDefaultDashboardContentLink: widget?.widgetDashboardContentLink,
    });

    setSelectedWidgetId(widget?.value);
  };

  const { data: widgetData, isFetching: isSearchFetching } = useQuery({
    queryKey: ["widget-content"],
    queryFn: () => {
      let params = {};
      params.filters = [["configName", "eq", "Add Widget"]];
      return fetchConfig(params);
    },
    suspense: false,
    enabled: true,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const {
    data: widgetInitialData,
    refetch: fetingWidgetLayout,
    isFetching: fetchingWidgetLayout,
  } = useQuery({
    queryKey: ["widget-initial", widgetIndustryId],
    queryFn: () => {
      let params = {};
      params.filters = [["widgetIndustryId", "eq", widgetIndustryId]];
      return fetchWidgetLayout(params);
    },
    enabled: !!widgetIndustryId,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const {
    data: widgetUniqueData,
    refetch: refetchUniqueData,
    isFetching: fetchingWidgetUniqueData,
  } = useQuery({
    queryKey: ["widget-unique", widgetIndustryId],
    queryFn: () => {
      let params = {};
      params.filters = [["widgetIndustryId", "eq", widgetIndustryId]];
      return fetchWidget(params);
    },
    enabled: !!widgetIndustryId?.length,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const pinedContentIds = useMemo(
    () => widgetUniqueData?.data?.data.flatMap((a) => a.widgetContent.map((d) => d.widgetContentId)),
    [widgetUniqueData?.data?.data]
  );
  const {
    data: pinnedContentData,
    refetch: refetchPinnedContent,
    isFetching: fetchingPinnedContent,
  } = useQuery({
    queryKey: ["pinned-content-data"],
    queryFn: () => {
      let params = {
        filters: [
          ["industry_tag.trend_id", "eq", widgetIndustryId],
          ["_id", "in", pinedContentIds],
        ],
        limit: 2000,
      };
      return fetchWidgetList(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: !!pinedContentIds?.length > 0,
  });

  const { data: combinedData, isFetching: fetchingCombineData } = useQuery({
    queryKey: ["combined-data", widgetDataIds],
    enabled: !!widgetDataIds?.length,
    queryFn: () => {
      let params = {};
      params.filters = [["_id", "in", widgetDataIds]];
      params.limit = widgetDataIds?.length || 2000;
      return getCombinedData(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });
  const { data: userDetails, isFetching: fetchingUserData } = useQuery({
    queryKey: ["user-data", userId?._id],
    enabled: !!userId?._id,
    queryFn: () => fetchSingleUser(userId?._id),
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (pinnedContentData?.data?.data?.length < 1) return;
    dispatch(setCombinedData(pinnedContentData?.data?.data));
  }, [pinnedContentData?.data]);

  /** For newly Added */
  const newlyAddedItems = useMemo(() => {
    return sortedWidgetKeyPlayersIds?.filter((item) => item && item?.widgetContentKeyPlayerCategory === "Newly Added");
  }, [sortedWidgetKeyPlayersIds]);

  /** Fetch companies in newlyAdded Tab */
  const f = useMemo(
    () => sortedWidgetKeyPlayersList?.flatMap((d) => (newlyAddedItems?.find((f) => f?.id === d?.id) ? [d] : [])),
    [sortedWidgetKeyPlayersList, newlyAddedItems]
  );

  /** */

  /** For Recently Updated */
  const recenltlyUpdatedItems = useMemo(() => {
    return sortedWidgetKeyPlayersIds?.filter((item) => item && item?.widgetContentKeyPlayerCategory === "Recently Updated");
  }, [sortedWidgetKeyPlayersIds]);

  /** Fetch companies in recently Tab */
  const recentlyUpdated = useMemo(
    () => sortedWidgetKeyPlayersList?.flatMap((d) => (recenltlyUpdatedItems?.find((f) => f?.id === d?.id) ? [d] : [])),
    [sortedWidgetKeyPlayersList, recenltlyUpdatedItems]
  );

  useEffect(() => {
    if ((formik?.values?.widgetConfigName !== "key_players" || formik?.values?.widgetConfigName !== "key_players_2") && !rightWidgetList?.length) {
      setNewlyAddedWidgetList([]);
      setRecentlyUpdatedWidgetList([]);
      return;
    }
    const newlyAddedArray = rightWidgetList
      .filter((c) => c.widgetContentKeyPlayerCategory === "Newly Added")
      .sort((a, b) => a.widgetContentOrder - b.widgetContentOrder);

    const recentlyUpdatedArray = rightWidgetList
      .filter((c) => c.widgetContentKeyPlayerCategory === "Recently Updated")
      .sort((a, b) => a?.widgetContentOrder - b?.widgetContentOrder);

    setNewlyAddedWidgetList(newlyAddedArray);
    setRecentlyUpdatedWidgetList(recentlyUpdatedArray);
  }, [rightWidgetList, formik?.values]);

  /** */

  /** MUI props for tabs in key players*/
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /** Tab panel for MUI tabs */
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  /** SelectedIndex for tabs in key players */
  const handleChange = async (event, newValue) => {
    setSelectedIndex(newValue);
    //  await fetchTrendWidgets(sortedWidgetIds, newValue);
    // if (selectedIndex == 1) await fetchTrendWidgets(sortedWidgetIds, newValue);
  };

  const handleNewsContentCurationChange = (event) => {
    setNewsContentCuration(event.target.value);
    setIsNewsCuration(true);
    dispatch(setRightSideWidgetList([]));
  };

  const sortDataSource = [
    { text: "Newest", value: "Newest" },
    { text: "Oldest", value: "Oldest" },
  ];

  const timerDataSource = [
    { text: "5 Sec", value: 5000 },
    { text: "10 Sec", value: 10000 },
  ];

  function onChange(newValue) {
    setHtmlCode(newValue);
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceIndex = result?.source?.index;
    const destIndex = result?.destination?.index;

    const newItems = Array.from(rightWidgetList);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destIndex, 0, reorderedItem);
    newItems.forEach((element, i) => {
      element.widgetContentOrder = i + 1;
    });
    dispatch(setRightSideWidgetList(newItems));
  };

  const updateWidgetListOrder = (list, sourceIndex, destIndex, setListFunction) => {
    const newItems = Array.from(list);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destIndex, 0, reorderedItem);
    newItems.forEach((element, i) => {
      element.widgetContentOrder = i + 1;
    });
    setListFunction(newItems);
  };

  const onDragEndKeyPlayer = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (newsTabValue === "1") {
      updateWidgetListOrder(newlyAddedWidgetList, sourceIndex, destIndex, setNewlyAddedWidgetList);
    } else {
      updateWidgetListOrder(recentlyUpdatedWidgetList, sourceIndex, destIndex, setRecentlyUpdatedWidgetList);
    }
  };
  const onDragEndKeyPlayers = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    /** Drag for newly Added */
    if (selectedIndex == 0) {
      const newlyAddedItems = Array.from(newlyaddedWidgetList);
      const [newlyAddedReorderedItem] = newlyAddedItems.splice(sourceIndex, 1);
      newlyAddedItems.splice(destIndex, 0, newlyAddedReorderedItem);
      newlyAddedItems.forEach((element, i) => {
        if (element.sgfId === sgfName && element.trendId === selectedTrend) {
          element.widgetOrder = element.widgetOrder == 1 ? 2 : 1;
        }
      });

      setNewlyaddedWidgetList(newlyAddedItems);
    }

    /** Drag for newly Added */
    if (selectedIndex == 1) {
      const recentlyUpdatedItems = Array.from(updatedWidgetList);
      const [recentlyupdatedReorderedItem] = recentlyUpdatedItems.splice(sourceIndex, 1);
      recentlyUpdatedItems.splice(destIndex, 0, recentlyupdatedReorderedItem);
      recentlyUpdatedItems.forEach((element, i) => {
        if (element.sgfId === sgfName && element.trendId === selectedTrend) {
          element.widgetOrder = element.widgetOrder == 1 ? 2 : 1;
        }
      });
      setUpdatedWidgetList(recentlyUpdatedItems);
    }
  };

  const handleDelete = (Id) => {
    setSortedWidgetIds(sortedWidgetIds.filter((item) => item.id !== Id));
    let newArray = sortedWidgetList.filter((ele) => ele.id !== Id);
    newArray.map((e, i) => {
      e.widgetContentOrder = i + 1;
      return e;
    });

    setSortedWidgetList(newArray);
    const updatedData = JSON.parse(JSON.stringify(widgetList));

    for (const item of updatedData) {
      if (item.id === Id) {
        item.isSelected = false;
      }
    }
    setWidgetList(updatedData);
  };

  /** Handle delete for key players: moving key players from right to left */
  const handleDeleteKeyPlayer = (Id) => {
    if (selectedIndex == 0) {
      setSortedWidgetIds((prevIds) => prevIds.filter((item) => item.id !== Id));
      setNewlyAddedWidgetIds((prevIds) => prevIds.filter((item) => item.id !== Id));
      let newArray = newlyaddedWidgetList.filter((ele) => ele.id !== Id); // Fetch the companies based on the Id
      //Add widget content order
      newArray.map((e, i) => {
        e.widgetContentOrder = i + 1;
        return e;
      });

      /** Remove the key players */
      const extractedValues = [];
      keyPlayersData.map((f) => {
        extractedValues.push(f);
      });

      const filteredExtractedValues = extractedValues.filter((item) => !newArray.some((e) => e.id === item.widgetContentId));
      const updatedArray = filteredExtractedValues.map((item, index) => ({
        ...item,
        widgetContentOrder: index + 1,
      }));
      /** */

      /** Remove duplicates from the array */
      const filteredNewlyaddedWidgetList = newlyaddedWidgetList.filter((item) => newArray.some((newArrayItem) => newArrayItem.id === item.id));
      filteredNewlyaddedWidgetList.map((item) => {
        if (item?.trendId == selectedTrend) item.widgetOrder = 1;
        return item;
      });
      setNewlyaddedWidgetList(filteredNewlyaddedWidgetList); // set the data
      setKeyplayersData(updatedArray);
      const updatedData = JSON.parse(JSON.stringify(widgetList));

      /** isSelectedNewlyAdded flag */
      for (const item of updatedData) {
        if (item.id == Id) {
          item.isSelectedNewlyAdded = false;
        }
      }
      setWidgetList(updatedData);
    }

    if (selectedIndex == 1) {
      setSortedWidgetIds((prevIds) => prevIds.filter((item) => item.id !== Id));
      setUpdatedWidgetIds((prevIds) => prevIds.filter((item) => item.id !== Id));

      let newArray = updatedWidgetList.filter((ele) => ele.id !== Id); // Fetch the companies based on the Id
      //Add widget content order
      newArray.map((e, i) => {
        e.widgetContentOrder = i + 1;
        return e;
      });

      /** Remove duplicates from the array */
      const filteredUpdatedWidgetList = updatedWidgetList.filter((item) => newArray.some((newArrayItem) => newArrayItem.id === item.id));
      filteredUpdatedWidgetList.map((item) => {
        if (item?.trendId == selectedTrend) item.widgetOrder = 1;
        return item;
      });
      setUpdatedWidgetList(filteredUpdatedWidgetList);
      // setKeyplayersData(updatedArray);
      const updatedData = JSON.parse(JSON.stringify(widgetList));

      /** isSelectedUploaded flag */
      for (const item of updatedData) {
        if (item.id == Id) {
          item.isSelectedUploaded = false;
        }
      }
      setWidgetList(updatedData);
    }
  };

  const handleSearch = () => {
    setSearchTextFilter(searchText);
    setWidgetContentTypeFilter(formik.values.widgetContentType);
    setWidgetDeliverableTypeFilter(formik.values.widgetDeliverableType);
    setWidgetSGFFilter(formik.values.widgetSGF);
    setWidgetTrendFilter(formik.values.widgetTrend);
  };

  /** Handle Reset for key players */
  const handleResetKeyPlayers = useCallback(async () => {
    setWidgetList(allWidgetList);
    if (keyPlayersSearchBoxRef.current) keyPlayersSearchBoxRef.current.element.value = "";
    setKeyPlayersSearchText("");
    setKeyPlayersSearchTextFilter("");
  }, [allWidgetList, keyPlayersSearchBoxRef]);

  /** Handle search for key players */
  const handleSearchKeyPlayers = () => {
    setKeyPlayersSearchTextFilter(keyPlayersSearchText);
  };

  /** Handle search for Top signals */
  const handleSearchTopSignals = () => {
    setTopSignalsSearchTextFilter(topSignalsSearchText);
  };

  /** Handle Reset for Top signals */
  const handleResetTopSignals = async () => {
    setWidgetList(allWidgetList);
    if (topSignalsSearchBoxRef.current) topSignalsSearchBoxRef.current.element.value = "";
    setTopSignalsSearchText("");
    setTopSignalsSearchTextFilter("");
  };

  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.trendName;
      element["showCheckbox"] = false;
      element["taxonomySortOrder"] = element.trendSortOrder;
      element["level"] = element.trendLevel;
      if (element?.trendIsHidden === "YES") {
        element["icon"] = "hidden";
      }
      if (element?.trendIsDisabled === "YES") {
        element["icon"] = "disabled";
      }
      if (element?.trendIsDisabled === "YES" && element?.trendIsHidden === "YES") {
        element["icon"] = "hidden-disabled";
      }
      if (element["trendParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  /**
   * @description fetch taxonomy trend by selected trendSubRoot
   * @param {String} trendSubRoot
   */
  const fetchTaxonomy = (trendSubRoot) => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    if (trendSubRoot) {
      fetchTaxonomyTrend({
        fields: [
          "id",
          "trendName",
          "trendParentId",
          "trendParentName",
          "trendSortOrder",
          "trendLevel",
          "trendDisplayName",
          "trendIsHidden",
          "trendIsDisabled",
          "trendIsSignal",
        ],
        filters: [["trendSubRoot", "eq", trendSubRoot]],
        sort: "trendSortOrder:asc",
        limit: 2000,
      })
        .then((res) => {
          dispatch(hideLoader());
          let { data = [] } = res.data;
          if (data.length) {
            const treeJosnArray = buildTreeJson(data, trendSubRoot);
            setTaxonomy(treeJosnArray);
            setTreeCollapse(true);
          }
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Trends Data Fetch Failed";
          dispatch(actionError(errMsg));
        });
    }
  };

  const fetchSgf = (selectedIndustry) => {
    dispatch(showLoader());
    getObjectiveSgfTag({ filters: [["sector.sectorId", "eq", selectedIndustry]], sort: "trendName:asc", limit: 2000 })
      .then((res) => {
        let sgf = res?.data?.data?.filter((item) => {
          if (item.sgfName !== "Others") return item;
        });
        if (!sgf) return;
        setSgfTrendData(sgf);
        setSgfName(sgf[0]?.id);
        setWidgetSGFFilter(sgf[0].sgfName);
        setWidgetTrendFilter(sgf[0].trend[0].trendName);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [100]);
      })
      .catch((err) => {
        dispatch(hideLoader());
      });
  };

  const fetchIndustry = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({
      fields: ["id", "trendName"],
      filters: [["trendParentId", "eq", 0]],
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    })
      .then((res) => {
        setWidgetIndustryName(res?.data?.data[0]?.trendName);
        setWidgetIndustryId(res?.data?.data[0]?.id);
        fetchWidgetData(res?.data.data[0]?.id);
        setIndustry(res?.data?.data);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [100]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error(err);
      });
  };

  const industryDropdownData = useMemo(() => {
    if (!userDetails?.data?.data || industry?.length < 1) return;
    if (userDetails?.data?.data?.userCompentencyIndustry?.length > 0) {
      const compentencyIndustryId = userDetails?.data?.data?.userCompentencyIndustry?.map((industry) => industry.industryId);
      const response = industry?.filter((a) => compentencyIndustryId.includes(a?.id));
      setWidgetIndustryName(response[0]?.trendName);
      setWidgetIndustryId(response[0]?.id);
      return response;
    }
    return industry;
  }, [userDetails?.data?.data, industry]);

  const fetchContentType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "InsiderV2"]);
    const response = await getContentType({ filters: filters });
    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.contentType, value: v.contentType };
      });
      if (resData) {
        setContentTypeDataSource(resData);
      } else {
        setContentTypeDataSource([]);
      }
    }
  };

  const fetchDeliverableType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "Insider"]);
    const response = await getContentType({ filters: filters });

    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.contentType, value: v.contentType };
      });
      if (resData) {
        setDeliverableTypeDataSource(resData);
      } else {
        setDeliverableTypeDataSource([]);
      }
    }
  };

  const fetchAllWidgetContent = async () => {
    try {
      dispatch(showLoader());

      let finalList = [];

      const promises = [];

      const deliverableParams = {};
      deliverableParams.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["deliverableAddToWidgets", "eq", "YES"],
        ["deliverable_industry_trends_tags.trend_name", "eq", widgetIndustryName],
        ["deliverableType", "nin", ["Business Objective Report"]],
      ];
      deliverableParams.limit = 10000;

      promises.push(
        fetchDeliverables(deliverableParams).then((res) => {
          return Promise.all(
            res.data.data.map(async (ele) => {
              const e = getDeliverableModifiedObj(ele);
              if (e.signalRelatedContentType === "Report" && e.signalRelatedContentReportId) {
                const additionalResponse = await fetchSingleDeliverable(e.signalRelatedContentReportId);
                return {
                  ...e,
                  relatedContentData: getDeliverableModifiedObj(additionalResponse.data.data),
                };
              } else if (e.signalRelatedContentType === "External Link") {
                return {
                  ...e,
                  relatedContentData: {
                    profileImage: ele.deliverableSignalRelatedContentThumbnail,
                    entityType: "document",
                    title: ele.deliverableSignalRelatedContentTitle,
                    date: ele.deliverableSignalRelatedContentPublishedDate,
                  },
                };
              }
              return e;
            })
          );
        })
      );

      const newsParams = {};
      newsParams.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["newsAddToWidgets", "eq", "YES"],
        ["news_industry_trends_tags.trend_name", "eq", widgetIndustryName],
      ];
      newsParams.limit = 10000;

      promises.push(
        fetchNews(newsParams).then((res) => {
          return res.data.data.map((ele) => getNewsModifiedObj(ele));
        })
      );

      const videoParams = {};
      videoParams.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["videoAddToWidgets", "eq", "YES"],
        ["video_industry_tag.industry_name", "eq", widgetIndustryName],
      ];
      videoParams.limit = 10000;

      promises.push(
        fetchVideos(videoParams).then((res) => {
          return res.data.data.map((ele) => getVideoModifiedObj(ele));
        })
      );

      const companyParams = {};
      companyParams.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["companyAddToWidgets", "eq", "YES"],
        ["company_industry_trends_tags.trend_name", "eq", widgetIndustryName],
      ];
      companyParams.limit = 10000;

      promises.push(
        fetchCompany(companyParams).then((res) => {
          return res.data.data.map((ele) => getCompanyModifiedObj(ele));
        })
      );

      // get all content data
      finalList = await Promise.all(promises);

      finalList = finalList.flat();

      finalList.sort(function (a, b) {
        const c = new Date(a.date === undefined ? new Date() : a.date);
        const d = new Date(b.date === undefined ? new Date() : b.date);
        return d - c;
      });

      setWidgetList(finalList);
      setUpdatedList(finalList);
      setAllWidgetList(finalList);
      dispatch(hideLoader());
    } catch (error) {
      console.log("error:", error);
    }
  };

  /** Get call for fetch widgets */
  const fetchWidgetData = async (industryId) => {
    await fetchWidget({
      fields: [
        "id",
        "widgetName",
        "widgetDisplayName",
        "widgetIndustryId",
        "widgetIndustryName",
        "widgetContent",
        "widgetProductPlatform",
        "productVariant",
        "widgetDashboardContentLink",
        "widgetAutoLoadTime",
      ],
      filters: [
        ["widgetIndustryId", "eq", industryId],
        ["productVariant.productName", "eq", productVariant],
        ["widgetActive", "eq", "YES"],
      ],
      limit: 100,
      sort: "widgetName:asc",
    })
      .then((res) => {
        setWidgets(res.data.data);
        res.data.data.map((ele) => {
          if (ele.widgetName === "Widget 1") {
            setSortedWidgetOneIds(
              ele.widgetContent.map((item) => ({
                id: item.widgetContentId,
                widgetContentOrder: item.widgetContentOrder,
                widgetContentType: item.widgetContentType,
              }))
            );
          } else if (ele.widgetName === "Widget 2") {
            setSortedWidgetTwoIds(
              ele.widgetContent.map((item) => ({
                id: item.widgetContentId,
                widgetContentOrder: item.widgetContentOrder,
                widgetContentType: item.widgetContentType,
              }))
            );
          } else if (ele.widgetName === "Top Signals") {
            setSortedWidgetTopSignalsIds(
              ele.widgetContent.map((item) => ({
                id: item.widgetContentId,
                widgetContentOrder: item.widgetContentOrder,
                widgetContentType: item.widgetContentType,
              }))
            );
            setHtmlCode(ele.widgetDashboardContentLink);
            setTopSignalsTimer(ele.widgetAutoLoadTime ? ele.widgetAutoLoadTime : 5000);
          } else if (ele.widgetName === "Key Players") {
            setSortedWidgetKeyPlayersIds(
              ele.widgetContent.map((item) => ({
                id: item.widgetContentId,
                widgetOrder: item.widgetOrder,
                widgetContentType: item.widgetContentType,
                widgetContentKeyPlayerCategory: item.widgetContentKeyPlayerCategory,
                widgetSgfId: item.widgetSgfId,
                widgetTrendId: item.widgetTrendId,
              }))
            );

            /** Set the widget name dynamic */
            setWidName(ele.widgetDisplayName);
            {
              !isDeleteClicked &&
                setKeyplayersData(
                  ele.widgetContent.map((item) => ({
                    widgetContentId: item.widgetContentId,
                    widgetOrder: item.widgetOrder,
                    widgetContentType: item.widgetContentType,
                    widgetContentKeyPlayerCategory: item.widgetContentKeyPlayerCategory,
                    widgetSgfId: item.widgetSgfId,
                    widgetTrendId: item.widgetTrendId,
                  }))
                );
              setIsDeleteClicked(false);
            }
          }
        });
        if (res.data.data?.length === 0) {
          setSortedWidgetOneIds([]);
          setSortedWidgetTwoIds([]);
        }
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error(err);
      });
  };

  const handleSaveWidget = async () => {
    const res = await formik.validateForm();
    if (Object.keys(res)?.length) {
      const touched = {};
      Object.keys(res).map((field) => {
        touched[field] = true;
        return touched;
      });
      return formik.setFormikState({ ...formik, touched: touched, errors: res });
    }
    if (Object.keys(formik.errors).length !== 0 || formik?.values?.widgetDisplayName == "") return;
    dispatch(
      showAlertBox({
        okCallback: handleSaveDashboardContent,
        okText: "Save",
        cancelText: "Cancel",
        content: `Are you sure you want to publish the ${formik.values?.widgetName?.toLowerCase()}? Once published, the ${formik.values?.widgetName?.toLocaleLowerCase()} will be displayed on the platform homepage.`,
        title: "dialogAlertCss",
      })
    );
  };

  const getWidgetContentType = (contentType) => {
    if (contentType === "company") return "Company";
    else if (contentType === "video") return "Video";
    else if (contentType === "news") return "News";
    else if (contentType === "document") return "Deliverable";
  };

  const resetWidgetState = useCallback(() => {
    setOpenTopSideBar(false);
    setWidgetId("");
    setIsEditWidget(false);
    setWidgetName("");
    formik.setFormikState({ ...formik, touched: {}, errors: {} });
    dispatch(hideLoader());
    dispatch(setRightSideWidgetList([]));
    dispatch(setSelectedWidgetData({}));
    setNewsTabValue("1");
    setNewlyAddedWidgetList([]);
    setRecentlyUpdatedWidgetList([]);
    resetFormik();
    resetSelectedWidgetId();
    setNewsContentCuration("manual");
    setNewsCount(15);
    setIsNewsCuration(false);
    setSortValue("mostViews");
    dispatch(setPreviewData([]));
  }, [formik, dispatch]);

  const handleSavePinnedContent = async () => {
    dispatch(showLoader());
    formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        dispatch(hideLoader());
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        try {
          let payload = {
            widgetUniqueName: formik?.values?.widgetUniqueName,
            widgetName: formik?.values?.widgetName,
            widgetDisplayName: formik?.values?.widgetDisplayName,
            widgetIndustryId: widgetIndustryId,
            widgetIndustryName: widgetIndustryName,
            widgetIsMerged: formik?.values?.isMerged,
            widgetTheme: { themeName: formik?.values?.widgetTheme?.themeName, themeColor: formik?.values?.widgetTheme?.themeColor },
            widgetProductPlatform: "Insider V2",
            productVariant: [{ productName: productVariant }],
            widgetActive: "YES",
            widgetConfigName: formik?.values?.widgetConfigName,
            widgetDeliverableType: formik?.values?.widgetDeliverableType,
            widgetContentType: formik?.values?.widgetContentType,
            widgetContent: rightWidgetList.map((content) => ({
              widgetContentId: content?.id,
              widgetContentType: content?.entityType === "document" ? "Deliverable" : content?.entityType,
            })),
          };

          if (isEditWidget) {
            payload.widgetModifiedByUserId = userId?._id;
            payload.widgetModifiedByUserName = userId?.user_fname + " " + userId?.user_lname;
            await updateWidget(selectedWidgetData?.id, payload);
          } else {
            payload.widgetCreatedByUserId = userId?._id;
            payload.widgetCreatedByUserName = userId?.user_fname + " " + userId?.user_lname;
            await saveSingleWidget(payload);
          }
          updateSplitConfigData(payload?.widgetUniqueName, widgetName, "save");
          formik.setFormikState({ ...formik, touched: {}, errors: {} });
          resetWidgetState();
          queryClient.invalidateQueries(["widget-unique"]);
          queryClient.invalidateQueries(["pinned-content-data"]);
          dispatch(hideLoader());
          if (isEditWidget) return dispatch(actionSuccess(PINNED_UPDATED_SUCCESS_MESSAGE));
          return dispatch(actionSuccess(PINNED_ADDED_SUCCESS_MESSAGE));
        } catch (error) {
          dispatch(hideLoader());
          dispatch(actionError(error?.data?.message));
        }
      }
    });
  };

  const handleSaveDashboardContent = async () => {
    try {
      const res = await formik.validateForm();
      if (Object.keys(res)?.length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let errors = Object.keys(res);
        if (errors.length > 0) dispatch(actionError(res[errors[0]]));
        return formik.setFormikState({ ...formik, touched: touched, errors: res });
      }

      let payload = {
        widgetName: formik.values?.widgetName,
        widgetDisplayName: formik.values?.widgetDisplayName.trim(),
        widgetIndustryId: widgetIndustryId,
        widgetIndustryName: widgetIndustryName,
        widgetProductPlatform: "Insider V2",
        productVariant: [{ productName: productVariant }],
        widgetDashboardContentLink: formik.values?.widgetDashboardContentLink,
        widgetUniqueName: formik.values?.widgetUniqueName,
        widgetConfigName: formik.values?.widgetConfigName,
        widgetDeliverableType: formik?.values?.widgetDeliverableType,
        widgetContentType: formik?.values?.widgetContentType,
      };
      if (formik?.values?.widgetConfigName === "news") payload["widgetContentCuration"] = newsContentCuration;

      let combinedList = [];
      if (formik?.values?.widgetConfigName === "key_players" || formik?.values?.widgetConfigName === "key_players_2") {
        combinedList = [
          ...newlyAddedWidgetList.map((item, index) => ({
            ...item,
            widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
          })),
          ...recentlyUpdatedWidgetList.map((item, index) => ({
            ...item,
            widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
          })),
        ];
      } else {
        combinedList = rightWidgetList.map((item, index) => ({
          ...item,
          widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
        }));
      }
      payload.widgetContent = combinedList.map((item, i) => ({
        widgetContentId: item.id,
        widgetContentOrder: item.widgetContentOrder,
        widgetContentType: getWidgetContentType(item.entityType),
        ...(item.widgetContentKeyPlayerCategory && { widgetContentKeyPlayerCategory: item.widgetContentKeyPlayerCategory }),
      }));

      dispatch(showLoader());
      if (isEditWidget) {
        payload["widgetCreatedByUserId"] = userId._id;
        payload["widgetCreatedByUserName"] = userId.user_fname + " " + userId.user_lname;
        await updateWidget(selectedWidgetData?.id, payload);
        setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
        dispatch(actionSuccess(WIDGET_UPDATE_MESSAGE));
      } else {
        payload["widgetCreatedByUserId"] = userId._id;
        payload["widgetCreatedByUserName"] = userId.user_fname + " " + userId.user_lname;
        await saveWidget(payload);
        setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
        dispatch(actionSuccess(WIDGET_ADDED_SUCCESS_MESSAGE));
        resetSelectedWidgetId();
      }
      updateSplitConfigData(payload?.widgetUniqueName, widgetName, "save");
      dispatch(hideLoader());
      refetchUniqueData();
      resetWidgetState();
    } catch (error) {
      console.log(error, "error");
      dispatch(hideLoader());
      dispatch(actionError(error?.data?.message));
    }
  };

  // Define a function to handle checkbox changes
  const handleCheckboxChange = (index) => {
    const updatedData = JSON.parse(JSON.stringify(widgetList)); // Create a copy of the array
    updatedData[index].isSelected = !updatedData[index].isSelected; // Toggle the isSelected property

    let tempAddArray = sortedWidgetList;
    let tempAddId = sortedWidgetIds;

    // Iterate through the widget list
    updatedData.forEach((item) => {
      if (item.isSelected === true && !tempAddArray.some((obj) => obj.id === item.id)) {
        const newItem = { ...item, widgetContentOrder: tempAddArray.length + 1 };
        const newItemId = { id: item.id, widgetContentOrder: tempAddArray.length + 1, widgetContentType: getWidgetContentType(item.entityType) };
        tempAddArray.push(newItem);
        tempAddId.push(newItemId);
      }
    });
    setSortedWidgetList(tempAddArray);
    setSortedWidgetIds(tempAddId);
    setWidgetList(updatedData);
  };

  /** Handle key player changes - move left to right key players */
  const handleCheckboxChangeKeyPlayers = async (index) => {
    if (selectedIndex == 0) {
      /** Validation */
      if (newlyaddedWidgetList?.length > 0) {
        const widgetLength = newlyaddedWidgetList.filter((r) => r.sgfId == sgfName && r.trendId == selectedTrend);
        if (widgetLength.length >= 2) return dispatch(actionError("You can add upto 2 key players in newly added/recently updated."));
      }
      const updatedData = JSON.parse(JSON.stringify(widgetList)); // Create a copy of the array
      /** Add isSelectedNewlyAdded flag*/
      updatedData[index].isSelectedNewlyAdded = !updatedData[index].isSelectedNewlyAdded;
      const addedWidget = updatedData[index];

      let tempAddArray = [...newlyaddedWidgetList, ...f]; // old and new data
      let tempAddId = newlyAddedWidgetIds;

      /** Remove duplicates */
      const uniqueTempAddArray = tempAddArray.reduce((uniqueArray, item) => {
        if (!uniqueArray.some((obj) => obj.id == item.id) && item.isSelectedNewlyAdded === true) {
          const newItem = { ...item, widgetContentOrder: tempAddArray.length + 1 };
          return [...uniqueArray, newItem];
        }
        return uniqueArray;
      }, []);

      updatedData.forEach((item) => {
        if (item.isSelectedNewlyAdded === true && !uniqueTempAddArray.some((obj) => obj.id === item.id)) {
          const newItem = { ...item, widgetContentOrder: tempAddArray.length + 1 };
          const newItemId = {
            id: item.id,
            widgetContentOrder: tempAddArray.length + 1,
            widgetContentType: getWidgetContentType(item.entityType),
          };
          uniqueTempAddArray.push(newItem);
          tempAddId.push(newItemId);
        }
      });

      addedWidget.sgfId = sgfName;
      addedWidget.sgfName = widgetSGFFilter;
      addedWidget.trendId = selectedTrend;
      addedWidget.trendName = widgetTrendFilter;
      addedWidget.widgetOrder = 1;

      /** Remove duplicates */
      const a = uniqueTempAddArray.filter((d) => d.id !== addedWidget.id && d.isSelectedNewlyAdded === true);
      const newData = a.filter((d) => d.trendId === selectedTrend);
      if (newData.length === 1) {
        addedWidget.widgetOrder = 2;
      }
      a.push(addedWidget);
      setNewlyaddedWidgetList(a); // adding old + new data
      setNewlyAddedWidgetIds(tempAddId);

      setWidgetList(updatedData);
    } else if (selectedIndex == 1) {
      /** Validation */
      if (updatedWidgetList?.length > 0) {
        const widgetLength = updatedWidgetList.filter((r) => r.sgfId == sgfName && r.trendId == selectedTrend);
        if (widgetLength.length >= 2) return dispatch(actionError("You can add upto 2 key players in newly added/recently updated. "));
      }
      const updatedData = JSON.parse(JSON.stringify(widgetList)); // Create a copy of the array
      /** Add isSelectedUploaded flag*/
      updatedData[index].isSelectedUploaded = !updatedData[index].isSelectedUploaded;
      const addedWidget = updatedData[index];

      let tempAddArray = [...updatedWidgetList, ...recentlyUpdated]; // old and new data
      let tempAddId = updatedWidgetIds;

      /** Remove duplicates */
      const uniqueTempAddArray = tempAddArray.reduce((uniqueArray, item) => {
        if (!uniqueArray.some((obj) => obj.id === item.id) && item.isSelectedUploaded === true) {
          const newItem = { ...item, widgetContentOrder: uniqueArray.length + 1 };
          return [...uniqueArray, newItem];
        }
        return uniqueArray;
      }, []);

      updatedData.forEach((item) => {
        if (item.isSelectedUploaded === true && !uniqueTempAddArray.some((obj) => obj.id === item.id)) {
          const newItem = { ...item, widgetContentOrder: tempAddArray.length + 1 };
          const newItemId = {
            id: item.id,
            widgetContentOrder: tempAddArray.length + 1,
            widgetContentType: getWidgetContentType(item.entityType),
          };
          uniqueTempAddArray.push(newItem);
          tempAddId.push(newItemId);
        }
      });

      addedWidget.sgfId = sgfName;
      addedWidget.sgfName = widgetSGFFilter;
      addedWidget.trendId = selectedTrend;
      addedWidget.trendName = widgetTrendFilter;
      addedWidget.widgetOrder = 1;

      /** Remove duplicates */
      const a = uniqueTempAddArray.filter((d) => d.id !== addedWidget.id && d.isSelectedUploaded === true);
      const newData = a.filter((d) => d.trendId === selectedTrend);
      if (newData.length === 1) {
        addedWidget.widgetOrder = 2;
      }
      a.push(addedWidget);
      setUpdatedWidgetList(a); // adding old + new data
      setUpdatedWidgetIds(tempAddId);
      setWidgetList(updatedData);
    }
  };

  const searchWidgets = (index) => {
    let text = searchBoxRef.current.element.value;
    setSearchText(text);
  };

  const searchTopSignalsWidgets = (index) => {
    let text = topSignalsSearchBoxRef.current.element.value;
    setTopSignalsSearchText(text);
  };

  const searchKeyPlayersWidgets = (index) => {
    let text = keyPlayersSearchBoxRef.current.element.value;
    setKeyPlayersSearchText(text);
  };

  useEffect(() => {
    dispatch(setBreadCrumb([]));
    fetchIndustry();
    fetchContentType();
    fetchDeliverableType();
  }, []);

  useEffect(() => {
    if (
      widgetList?.length ||
      sortedWidgetOneIds?.length ||
      sortedWidgetTwoIds?.length ||
      sortedWidgetTopSignalsIds?.length ||
      sortedWidgetKeyPlayersIds?.length
    ) {
      setSortedWidgetOneList(
        sortedWidgetOneIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetTwoList(
        sortedWidgetTwoIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetTopSignalsList(
        sortedWidgetTopSignalsIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetKeyPlayersList(
        sortedWidgetKeyPlayersIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
    }
    if (!sortedWidgetOneIds) setSortedWidgetOneList(null);
    else if (!sortedWidgetOneIds?.length) setSortedWidgetOneList([]);
    if (!sortedWidgetTwoIds) setSortedWidgetTwoList(null);
    else if (!sortedWidgetTwoIds?.length) setSortedWidgetTwoList([]);
    if (!sortedWidgetTopSignalsIds) setSortedWidgetTopSignalsList(null);
    else if (!sortedWidgetTopSignalsIds?.length) setSortedWidgetTopSignalsList([]);
    if (!sortedWidgetKeyPlayersIds) setSortedWidgetKeyPlayersList(null);
    else if (!sortedWidgetKeyPlayersIds?.length) setSortedWidgetKeyPlayersList([]);
  }, [widgetList, sortedWidgetOneIds, sortedWidgetTwoIds, sortedWidgetTopSignalsIds, sortedWidgetKeyPlayersIds]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  const fetchTrendWidgets = async (val, activeTab) => {
    if (val?.length > 0 && widgetList.length > 0) {
      const selectedWidgetIds = val?.map((t) => {
        if (t.widgetContentKeyPlayerCategory == "Newly Added") return t.id;
      });
      const emptyArr = [...newlyaddedWidgetList];

      if (selectedWidgetIds?.length > 0) {
        let widName1 = widgetList.map((y) => {
          let widName2 = val?.map((t) => {
            if (t.widgetContentKeyPlayerCategory === "Newly Added" && t.id === y.id) {
              y.isSelectedNewlyAdded = true;
              y.sgfId = t.widgetSgfId;
              y.trendId = t.widgetTrendId;
              y.widgetOrder = t.widgetOrder;
              emptyArr.push(y);
            }
            return;
          });
          return;
        });

        const uniqueWidgets = await removeDuplicates(emptyArr);
        setNewlyaddedWidgetList(uniqueWidgets);
      }
    }

    if (val?.length > 0 && widgetList.length > 0) {
      const selectedWidgetIds = val?.map((t) => {
        if (t.widgetContentKeyPlayerCategory == "Recently Updated") return t.id;
      });
      const emptyArr = [...updatedWidgetList];

      if (selectedWidgetIds?.length > 0) {
        let widName1 = widgetList.map((y) => {
          let widName2 = val?.map((t) => {
            if (t.widgetContentKeyPlayerCategory === "Recently Updated" && t.id === y.id) {
              y.isSelectedUploaded = true;
              y.sgfId = t.widgetSgfId;
              y.trendId = t.widgetTrendId;
              y.widgetOrder = t.widgetOrder;
              emptyArr.push(y);
            }
            return;
          });
          return;
        });

        const uniqueWidgets = await removeDuplicates(emptyArr);
        setUpdatedWidgetList(uniqueWidgets);
      }
    }
  };

  /** Remove duplicates */
  function removeDuplicates(array) {
    const uniqueObjects = {}; // Object to store unique objects based on the combined keys

    for (const obj of array) {
      const key = obj.id + "|" + obj.sgfId + "|" + obj.trendId;
      uniqueObjects[key] = obj;
    }

    const uniqueArray = Object.values(uniqueObjects);
    return uniqueArray;
  }

  const handleTrendChange = async (value) => {
    setSelectedTrend(value?.itemData?.trendId);
    fetchTaxonomy(value?.itemData?.trendId);
    setWidgetTrendFilter(value?.itemData?.trendName);
    await fetchTrendWidgets(sortedWidgetIds, selectedIndex);
    handleResetKeyPlayers();
    handleResetTopSignals();
  };

  /** Widget count for key players*/
  const widgetCount = updatedWidgetList
    .concat(newlyaddedWidgetList || [])
    .reduce((count, item) => (item?.sgfId === sgfName && item?.trendId === selectedTrend ? count + 1 : count), 0);

  const totalCount = widgetList
    ? widgetList.filter(
      (item) =>
        !item.isSelectedUploaded &&
        !item.isSelectedNewlyAdded &&
        !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        item.title?.toLowerCase()?.includes(searchTextFilter.toLowerCase()) &&
        (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
        (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
        item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
        item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
    ).length
    : 0;

  const synchronizeLayouts = () => {
    let currentLayouts = { ...changeLayouts };
    let curentLayoutChange = [...currentLayout];
    if (Array.isArray(curentLayoutChange) && curentLayoutChange.length > 0) {
      for (let layout of curentLayoutChange) {
        const { i: widgt_name, x: widget_x, y: widget_y } = layout;
        let orderIndex = LAYOUT_ORDER?.[grid]?.findIndex(layout => (layout.x == widget_x && layout.y == widget_y));
        for (let breakPoint of GRID_MEASURES) {
          if (breakPoint !== grid && orderIndex !== -1) {
            let index = currentLayouts?.[widgetName]?.[breakPoint]?.findIndex(layout => layout?.i == widgt_name);
            if (index !== -1) {
              const { x, y } = LAYOUT_ORDER?.[breakPoint][orderIndex];
              currentLayouts[widgetName][breakPoint][index] = {
                ...currentLayouts[widgetName][breakPoint][index],
                x: x,
                y: y
              };
            }
          }
        }
      }
      return currentLayouts;
    }
    return layouts;
  };

  const handleSaveLayout = () => {
    try {
      const changedLayout = synchronizeLayouts();
      if (isDrag && changedLayout) {
        dispatch(
          showAlertBox({
            okText: "Save",
            cancelText: "Cancel",
            content: LAYOUT_CONFIRMATION_MESSAGE,
            title: "dialogAlertCss",
            okCallback: async () => {
              handleLayoutUpdate(changedLayout, pinnedContentMerge);
              dispatch(actionSuccess(LAYOUT_SUCCESS_MESSAGE));
            },
            cancelCallback: async () => {
              setIsDrag(true);
            },
          })
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleLayoutChange = (layout, allLayouts, type, breakpoint) => {
    if (isDrag && type === "dashboard") {
      let currentLayouts = { ...layouts };
      const layoutChanges = currentLayouts?.[type]?.[breakpoint]?.filter(({ i: i1, x:x1, y:y1 }) => !layout.some(({ i: i2, x:x2, y:y2 }) => (i2 === i1 && x2 === x1 && y2 === y1 )));      
      if(Array.isArray(layoutChanges) && layoutChanges?.length > 0){
        if (allLayouts && currentLayouts) {
          currentLayouts = {
            ...currentLayouts,
            [type]: allLayouts,
          };
          // curent layout it should not affect
          // setLayouts((prevLayouts) => ({
          //   ...prevLayouts,
          //   [type]: allLayouts,
          // }));
          setChangeLayouts(currentLayouts);
          setCurrentLayout(layout);
          setWidgetName(type);
        }
      } else {
        setIsDrag(false);
      }
    } 
    // else {
    //   if(type === "pinned"){
    //       setLayouts((prevLayouts) => ({
    //         ...prevLayouts,
    //         [type]: allLayouts,
    //       }));
    //   }
    // }
  };

  const handleDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    // if (newItem.x < 0) newItem.x = 0;
    // if (newItem.y < 0) newItem.y = 0;

    // const gridWidth = 10; // Number of columns in the grid
    // if (newItem.x + newItem.w > gridWidth) {
    //   newItem.x = gridWidth - newItem.w;
    // }
    setIsDrag(true);
  };

  const handleAddClick = (name, widgetUniqueName) => {
    setWidgetName(name);
    const deliverableType = WIDGET_FILTER['pinned']?.deliverableType || [];
    const contentType = WIDGET_FILTER['pinned']?.contentType || [];
    if (name !== "dashboard") {
      formik.setValues({
        ...formik.values,
        widgetDisplayName: PINNED_CONTENT,
        widgetName: PINNED_CONTENT,
        widgetUniqueName: widgetUniqueName,
        widgetContentType: contentType,
        widgetDeliverableType: deliverableType
      });
      return setOpenTopSideBar(true);
    }
    setShowAddWidgetModal(true);
    formik.setValues({ ...formik.values, widgetUniqueName: widgetUniqueName });
    // setSortedWidgetIds(sortedWidgetOneIds);
    // setSortedWidgetList(sortedWidgetOneList);
    // setWidgetList(allWidgetList);
    // setWidgetId(widgets?.find((item) => item.widgetName === "Widget 1")?.id);
    // setWidgetName("Widget 1");
    // setIsEditWidget(widgets?.find((item) => item.widgetName === "Widget 1")?.id ? true : false);
    // formik.setValues({
    //   widgetDisplayName: widgets?.find((item) => item.widgetName === "Widget 1")?.widgetDisplayName || "",
    // });
  };

  const updateSplitConfigData = (i, type, action) => {
    let previousConfig = { ...pinnedContentMerge };
    let currentLayouts = { ...layouts };
    // let widgetKey = pinnedContentMerge?.["ref"]?.[i];
    // let splitConfig = pinnedContentMerge?.[widgetKey];
    // let splitKeys = splitConfig && Object.keys(splitConfig);
    // if (Array.isArray(splitKeys) && splitKeys.length > 0) {
    //   let dataKeys = splitConfig?.dataKeys;
    //   let index = dataKeys.indexOf(i);
    //   if (action === "save") {
    //     //if data is save & publish
    //     if (index === -1) {
    //       dataKeys = [...dataKeys, i];
    //     }
    //     splitConfig = {
    //       ...splitConfig,
    //       ["isData"]: true,
    //       ["dataKeys"]: dataKeys,
    //     };
    //   } else if (action === "delete") {
    //     //if data is delete
    //     if (index !== -1) {
    //       dataKeys.splice(index, 1);
    //       splitConfig = {
    //         ...splitConfig,
    //         ["dataKeys"]: dataKeys,
    //         ["isData"]: dataKeys.length > 0 ? true : false,
    //       };
    //     }
    //   }
    //   previousConfig = {
    //     ...previousConfig,
    //     [widgetKey]: splitConfig,
    //   };
    // }
    // // handleLayoutUpdate(layouts, previousConfig);

    //update all grids
    // if (previousConfig && currentLayouts && type === "dashboard") {
    if (previousConfig && currentLayouts) {
      for (let grid of GRID_MEASURES) {
        let widgetKey = pinnedContentMerge?.[type]?.[grid]?.["ref"]?.[i];
        let splitConfig = pinnedContentMerge?.[type]?.[grid]?.[widgetKey];
        let dataKeys = splitConfig?.dataKeys;
        let index = Array.isArray(dataKeys) && dataKeys.indexOf(i);
        if (splitConfig) {
          if (action === "save" && index) {
            //if data is save & publish
            if (index === -1) {
              dataKeys = [...dataKeys, i];
            }
            splitConfig = {
              ...splitConfig,
              ["isData"]: true,
              ["dataKeys"]: dataKeys,
            };
            //When drag and drop will start need to check this
            // let dashoardLayout = currentLayouts?.[type]?.[grid];
            // for (let index in dashoardLayout) {
            //   if (dashoardLayout[index]?.i == i) {
            //     dashoardLayout[index].static = false;
            //     break; //Stop this loop, we found it!
            //   }
            // }
            // currentLayouts = {
            //   ...currentLayouts,
            //   [type]: {
            //     ...currentLayouts?.[type],
            //     [grid]: dashoardLayout,
            //   },
            // };
          } else if (action === "delete" && index >= 0) {
            //if data is delete
            if (index !== -1) {
              dataKeys.splice(index, 1);
              splitConfig = {
                ...splitConfig,
                ["dataKeys"]: dataKeys,
                ["isData"]: dataKeys.length > 0 ? true : false,
              };
            }
            //When drag and drop will start need to check this
            // let dashoardLayout = currentLayouts?.[type]?.[grid];
            // for (let index in dashoardLayout) {
            //   if (dashoardLayout[index]?.i == i) {
            //     dashoardLayout[index].static = true;
            //     break; //Stop this loop, we found it!
            //   }
            // }
            // currentLayouts = {
            //   ...currentLayouts,
            //   [type]: {
            //     ...currentLayouts?.[type],
            //     [grid]: dashoardLayout,
            //   },
            // };
          }
          previousConfig = {
            ...previousConfig,
            [type]: {
              ...previousConfig?.[type],
              [grid]: {
                ...previousConfig?.[type]?.[grid],
                [widgetKey]: splitConfig,
              },
            },
          };
        }
      }
      handleLayoutUpdate(layouts, previousConfig);
    }
  };

  function updateKeys(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(updateKeys);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = key.replace(/(\D+)(\d+)/, "$1_$2");
      acc[newKey] = updateKeys(value); // Recursively update keys for nested objects
      return acc;
    }, {});
  }

  const createMergeandSplitConfig = () => {
    let splitConfig = { ...pinnedContentMerge };
    if (widgetInitialData?.data?.data[0]?.widgetSplitConfig) {
      const updatedWidgetSplitConfig = updateKeys(widgetInitialData?.data?.data[0]?.widgetSplitConfig);
      return setPinnedContentMerge(updatedWidgetSplitConfig);
    }
    /** New split config structure with type and grid basis*/
    let widgetTypes = Object.keys(layouts);

    for (let type of widgetTypes) {
      for (let grid of GRID_MEASURES) {
        let gridLayout = layouts?.[type]?.[grid];
        if (Array.isArray(gridLayout) && gridLayout?.length > 0) {
          for (let index in gridLayout) {
            let nextIndex = Number(index) + 1;
            if (index % 2 === 0 && layouts?.[type]?.[grid]?.length > index) {
              const config1 = layouts?.[type]?.[grid]?.[index];
              const config2 = layouts?.[type]?.[grid]?.[nextIndex];
              let dataKey = [];
              const configData1 = widgetUniqueData?.data?.data?.find((widgetData) => widgetData?.widgetUniqueName === config1?.i);
              configData1?.widgetUniqueName && dataKey.push(configData1?.widgetUniqueName);
              const configData2 = widgetUniqueData?.data?.data?.find((widgetData) => widgetData?.widgetUniqueName === config2?.i);
              configData2?.widgetUniqueName && dataKey.push(configData2?.widgetUniqueName);
              if(config1?.i && config2?.i){
                splitConfig = {
                  ...splitConfig,
                  [type]: {
                    ...splitConfig?.[type],
                    [grid]: {
                      ...splitConfig?.[type]?.[grid],
                      ref: {
                        ...splitConfig?.[type]?.[grid]?.ref,
                        [config1?.i]: config1?.i,
                        [config2?.i]: config1?.i,
                      },
                      [config1?.i]: {
                        // [config1?.i]: config1,
                        // [config2?.i]: config2,
                        layouts: [config1, config2],
                        isMerged: false,
                        isData: configData1 || configData2 ? true : false,
                        dataKeys: dataKey,
                      },
                    },
                  },
                };
              }
            }
          }
          setPinnedContentMerge(splitConfig);
        }
      }
    }
  };
  const handleLayoutUpdate = async (prevLayouts, splitConfig) => {
    try {
      dispatch(showLoader());
      const layoutPayload = {
        widgetSequence: prevLayouts,
        widgetSplitConfig: splitConfig,
        widgetModifiedByUserId: userId?._id,
        widgetModifiedByUserName: userId?.user_fname + " " + userId?.user_lname,
        widgetProductPlatform: widgetLayout?.widgetProductPlatform,
        widgetIndustryId: widgetLayout?.widgetIndustryId,
      };
      await updateSingleWidgetLayout(widgetLayout?.id, layoutPayload);
      fetingWidgetLayout();
      dispatch(hideLoader());
      setIsDrag(false);
    } catch (error) {
      dispatch(hideLoader());
      dispatch(actionError(error?.data?.message));
    }
  };

  const handleMergeClick = (i1, i2, type, grid) => {
    // update for all grids
    let currentLayouts = { ...layouts };
    let splitConfig = { ...pinnedContentMerge };
    if (currentLayouts && splitConfig) {
      for (let grid of GRID_MEASURES) {
        let layout = currentLayouts?.[type]?.[grid];
        const config1 = layout.find((item) => item?.i === i1);
        const config2 = layout.find((item) => item?.i === i2);
        if (config1 && config2) {
          const mergedConfig = {
            i: config1?.i,
            x: Math.min(config1?.x, config2?.x),
            y: Math.min(config1?.y, config2?.y),
            w: config1?.w + config2?.w,
            h: Math.max(config1?.h, config2?.h),
            moved: false,
            static: true,
          };
          // const newLayout = layouts?.[type]?.[grid]?.filter(item => item.i !== i2);
          // const updatedLayout = newLayout && newLayout.map(item => item.i === i1 ? mergedConfig : item).sort((a,b)=>a.x-b.x);
          const newLayout = layout
            .filter((item) => item?.i !== i2)
            .map((item) => (item?.i === i1 ? mergedConfig : item))
            .sort((a, b) => a?.x - b?.x);

          splitConfig = {
            ...splitConfig,
            [type]: {
              ...splitConfig?.[type],
              [grid]: {
                ...splitConfig?.[type]?.[grid],
                [config1?.i]: {
                  ...splitConfig?.[type]?.[grid]?.[config1?.i],
                  isMerged: true,
                },
              },
            },
          };
          currentLayouts = {
            ...currentLayouts,
            [type]: {
              ...currentLayouts?.[type],
              [grid]: newLayout,
            },
          };
        }
      }
      setPinnedContentMerge(splitConfig);
      setLayouts(currentLayouts);
      handleLayoutUpdate(currentLayouts, splitConfig);
    }
  };

  const handleSplitClick = (i, type, grid) => {
    // update for all grids
    let splitConfig = { ...pinnedContentMerge };
    let currentLayout = { ...layouts };
    if (splitConfig && currentLayout) {
      for (let grid of GRID_MEASURES) {
        let newLayout = currentLayout?.[type]?.[grid]?.filter((item) => item?.i !== i);
        let widgetIndex = splitConfig?.[type]?.[grid]?.ref?.[i];
        let specificSplitConfig = splitConfig?.[type]?.[grid]?.[widgetIndex];
        newLayout = [...newLayout, ...specificSplitConfig?.layouts];
        newLayout = newLayout.sort((a, b) => a?.x - b?.x);
        currentLayout = {
          ...currentLayout,
          [type]: {
            ...currentLayout?.[type],
            [grid]: newLayout,
          },
        };
        specificSplitConfig = { ...specificSplitConfig, isMerged: false };
        splitConfig = {
          ...splitConfig,
          [type]: {
            ...splitConfig?.[type],
            [grid]: {
              ...splitConfig?.[type]?.[grid],
              [widgetIndex]: specificSplitConfig,
            },
          },
        };
      }

      setLayouts(currentLayout);
      setPinnedContentMerge(splitConfig);
      handleLayoutUpdate(currentLayout, splitConfig);
    }
  };

  const onDeleteClick = (type, id, i) => {
    id &&
      dispatch(
        showAlertBox({
          okCallback: async () => {
            deleteWidget(id)
              .then((response) => {
                refetchUniqueData();
                refetchPinnedContent();
                updateSplitConfigData(i, type, "delete");
                dispatch(actionSuccess(WIDGET_REMOVE_SUCCESS_MESSAGE));
              })
              .catch((err) => {
                dispatch(actionError(err.data?.message || WIDGET_DELETE_FAILED_MESSAGE));
              });
          },
          okText: "Remove",
          cancelText: "Cancel",
          content: WIDGET_REMOVE_CONFIRMATION_MESSAGE,
          title: "dialogAlertCssWarning",
        })
      );
  };

  const onSettingClick = async (data) => {
    dispatch(setSelectedWidgetData(data));
    setWidgetName(data?.widgetName === PINNED_CONTENT ? "pinned" : "dashboard");
    let rightData = null;
    if (data?.widgetConfigName === "key_players" || data?.widgetConfigName === "key_players_2")
      rightData = getKeyPlyersData(null, data, combinedWidgetData);
    else rightData = getData(null, data, combinedWidgetData);
    if (rightData?.length > 0) dispatch(setRightSideWidgetList(rightData));
    let widgetDashboardContentLink = widgetData?.data?.data?.[0]?.configValue?.find(
      (d) => d?.name === data?.widgetConfigName
    )?.widgetDashboardContentLink;
    setOpenTopSideBar(true);
    setIsEditWidget(true);
    formik.setValues({
      ...formik.values,
      widgetDisplayName: data?.widgetDisplayName,
      widgetName: data?.widgetName,
      widgetUniqueName: data?.widgetUniqueName,
      widgetDeliverableType: data?.widgetDeliverableType || WIDGET_FILTER[data?.widgetConfigName]?.deliverableType || [],
      widgetContentType: data?.widgetContentType || WIDGET_FILTER[data?.widgetConfigName]?.contentType || [],
      widgetConfigName: data?.widgetConfigName,
      widgetDashboardContentLink: data?.widgetDashboardContentLink,
      widgetDefaultDashboardContentLink: widgetDashboardContentLink
    });
    if (data.widgetName === PINNED_CONTENT)
      formik.setValues({
        ...formik.values,
        widgetDisplayName: data?.widgetDisplayName,
        widgetName: data?.widgetName,
        widgetUniqueName: data?.widgetUniqueName,
        widgetDeliverableType: data?.widgetDeliverableType,
        widgetContentType: data?.widgetContentType,
        widgetTheme: {
          themeColor: data?.widgetTheme?.themeColor,
          themeName: data?.widgetTheme?.themeName,
        },
      });
    if (data?.widgetContentCuration) setNewsContentCuration(data?.widgetContentCuration);
    if(data?.widgetConfigName === "news" && data?.widgetContentCuration === "automatic" && Array.isArray(data?.widgetContent)) setNewsCount(data?.widgetContent?.length); 
  };

  const handlePreviousClick = useCallback(() => {
    resetWidgetState();
    setSelectedWidgetId(null);
    setOpenTopSideBar(false);
    setShowAddWidgetModal(true);
  }, []);

  useEffect(() => {
    createMergeandSplitConfig();
  }, [widgetInitialData?.data]);

  useLayoutEffect(() => {
    if (window.innerWidth >= 1200) {
      setGrid("lg");
    } else if (window.innerWidth < 1200 && window.innerWidth >= 996) {
      setGrid("md");
    } else if (window.innerWidth < 996 && window.innerWidth >= 768) {
      setGrid("sm");
    } else if (window.innerWidth < 768 && window.innerWidth >= 480) {
      setGrid("xs");
    } else {
      setGrid("xs");
    }
  });

  const getWidgetLeftList = useCallback(() => {
    let filteredWidgetList = [];
    filteredWidgetList = widgetList?.filter(
      (item) =>
        !item.isSelected &&
        !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        // !updatedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        // !newlyAddedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        item.title?.toLowerCase()?.includes(searchTextFilter.toLowerCase()) &&
        (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
        (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType))
    );
    return filteredWidgetList;
  }, [widgetList]);

  const getWidgetRightList = useCallback(() => {
    let filteredWidgetList = [];
    filteredWidgetList = sortedWidgetList?.filter((item) => item);
    return filteredWidgetList;
  }, [widgetList]);

  //** Handle content click
  const handleArrowClick = useCallback(
    (item, action, maxLimit) => {
      let array = [...rightWidgetList];

      if (action === "Add") {
        // Check if the item already exists in the array
        const exists = array.some((val) => val.id === item.id);

        if (!exists) {
          if (formik?.values?.widgetConfigName === "key_players" || formik?.values?.widgetConfigName === "key_players_2") {
            item.widgetContentKeyPlayerCategory = newsTabValue === "1" ? "Newly Added" : "Recently Updated";
          }
          array.push(item);
          if (maxLimit && array.length > maxLimit) return dispatch(actionError(`You can select maximum ${maxLimit} content for this widget.`));
        }
      }

      if (action === "Delete") array = array.filter((val) => val.id !== item?.id);

      dispatch(setRightSideWidgetList(array));
    },
    [rightWidgetList, dispatch, newsTabValue, formik]
  );

  const handleNewsTabChange = useCallback((event, newValue) => setNewsTabValue(newValue), []);

  const widgetSelectionStatus = useMemo(() => {
    // let prevWidgetStatus = { ...widgetStatus };
    let prevWidgetStatus = {
      featured_content: false,
      signals: false,
      news: false,
      key_players: false,
      upcoming: false,
      new: false,
      key_players_2: false,
    };
    if (widgetUniqueData?.data?.data?.length > 0) {
      for (let index in widgetUniqueData?.data?.data) {
        let widgetData = widgetUniqueData?.data?.data?.[index];
        if (widgetData?.widgetConfigName) {
          prevWidgetStatus = { ...prevWidgetStatus, [widgetData?.widgetConfigName]: true };
        } else {
          if (widgetData?.widgetName) prevWidgetStatus = { ...prevWidgetStatus, [widgetData?.widgetName]: true };
        }
      }
    }
    return prevWidgetStatus;
  }, [widgetIndustryId, widgetUniqueData]);

  const resetSelectedWidgetId = () => {
    setSelectedWidgetId(null);
  };

  useEffect(() => {
    if (!widgetInitialData?.data?.data?.length) {
      setLayouts((prev) => ({ dashboard: {}, pinned: {} }));
      return; // Early return if the condition is met
    }
    // If the data is available, set the layouts with the appropriate widget sequence
    setLayouts(widgetInitialData.data.data[0]?.widgetSequence);
    setWidgetLayout(widgetInitialData?.data?.data[0]);
  }, [widgetInitialData?.data]);

  useEffect(() => {
    if (!widgetUniqueData?.data?.data?.length) return setWidgetDataIds([]);
    const ids = widgetUniqueData.data.data.map((i) => i?.widgetContent?.map((c) => c?.widgetContentId) || []).flat();
    setWidgetDataIds(ids);
    //    widgetSelectionStatus && setWidgetStatus(widgetSelectionStatus);
    dispatch(hideWidgetLoader());
    createMergeandSplitConfig();
  }, [widgetUniqueData]);

  useEffect(() => {
    if (!combinedData?.data?.data?.length) {
      dispatch(setCombinedData([]));
      return;
    }
    dispatch(setCombinedData(combinedData?.data?.data));
  }, [combinedData]);

  useEffect(() => {
    if (!fetchingPinnedContent || !fetchingWidgetLayout || !fetchingWidgetUniqueData || !fetchingCombineData || !fetchingUserData) {
      dispatch(hideLoader());
    } else {
      dispatch(showLoader());
    }
    // Cleanup function
    return () => {
      dispatch(hideLoader());
    };
  }, [fetchingPinnedContent, fetchingWidgetLayout, fetchingWidgetUniqueData, fetchingCombineData, dispatch]);

  useEffect(() => {
    widgetSelectionStatus && setWidgetStatus(widgetSelectionStatus);
  }, [widgetIndustryId]);
  return (
    <>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        <div className="grid-wrapper">
          <div className="grid-dashboard-widget-container">
            <div className={widgetUniqueData?.data?.data.some((a) => a?.widgetDashboardContentLink !== "") ? "dashboard-widget-box" : "widget-boxes"}>
              <div className="section-heading ht-55 pl-10 m-t-r-l-20">
                <div className="section-text pt-20">{WIDGETS}</div>
                {/* Existing code */}
                <div>
                  {industryDropdownData?.length > 0 && (
                    <div className="col-md-12 pt-2">
                      <DropDownListComponent
                        value={widgetIndustryName}
                        change={(value) => {
                          setWidgetIndustryName(value.itemData.trendName);
                          setWidgetIndustryId(value.itemData.id);
                          fetchWidgetData(value.itemData.id);
                          dispatch(showLoader());
                        }}
                        cssClass={"customCss e-outline"}
                        floatLabelType="Auto"
                        popupHeight="250px"
                        dataSource={industryDropdownData}
                        fields={{ text: "trendName", value: "trendName" }}
                        placeholder="Industry Dashboard *"
                      />
                    </div>
                  )}
                </div>
                {/* save dashboard code*/}
                {/* <div className="right-side-wrapper">
                  {industryDropdownData?.length > 0 && (
                    <div className="dropdown-container">
                      <DropDownListComponent
                        value={widgetIndustryName}
                        change={(value) => {
                          setIsDrag(false);
                          setWidgetIndustryName(value.itemData.trendName);
                          setWidgetIndustryId(value.itemData.id);
                          fetchWidgetData(value.itemData.id);
                          dispatch(showLoader());
                        }}
                        cssClass={"customCss e-outline"}
                        floatLabelType="Auto"
                        popupHeight="250px"
                        dataSource={industryDropdownData}
                        fields={{ text: "trendName", value: "trendName" }}
                        placeholder="Industry Dashboard *"
                      />
                    </div>
                  )}
                  {widgetInitialData?.data?.data[0]?.widgetSequence && <div className="save-dashboard-btn-container">
                    <button
                      type="button"
                      className="btn btn-primary ml-2 save-dashboard-btn"
                      disabled={!isDrag}
                      style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                      onClick={handleSaveLayout}
                    >
                      {SAVE_DASHBOARD_TEXT}
                    </button>
                  </div>
                  }
                </div> */}  {/*End of save dashboard code*/}
              </div>
              <div className="dashboard-box-top">
                <GridComponent
                  name="dashboard"
                  text={ADD_WIDGETS}
                  layout={layouts?.dashboard}
                  resolution={grid}
                  splitConfig={pinnedContentMerge}
                  compactType={"horizontal"}
                  onLayoutChange={handleLayoutChange}
                  onDrag={handleDrag}
                  handleAddClick={handleAddClick}
                  onDeleteClick={onDeleteClick}
                  onSettingClick={onSettingClick}
                  widgetData={widgetUniqueData?.data?.data || []}
                  widgetConfig={widgetData?.data?.data?.[0]?.configValue}
                  isDroppable={true}
                  isDraggable={true}
                  isResizable={false}
                />
              </div>
            </div>
          </div>
          <div className="grid-pinned-content-widget-container">
            <div className="pinned-content-boxes">
              <div className="section-heading pl-10 ml-20 ht-25 m-t-b-10">
                <div className="section-text">{PINNED_CONTENT}</div>
              </div>
              <div className="dashboard-box-bottom">
                <GridComponent
                  name="pinned"
                  text={ADD_CONTENT}
                  layout={layouts?.pinned}
                  resolution={grid}
                  splitConfig={pinnedContentMerge}
                  compactType={"horizontal"}
                  onLayoutChange={handleLayoutChange}
                  onDrag={handleDrag}
                  handleAddClick={handleAddClick}
                  mergeWidgets={handleMergeClick}
                  splitWidgets={handleSplitClick}
                  widgetData={widgetUniqueData?.data?.data || []}
                  onDeleteClick={onDeleteClick}
                  onSettingClick={onSettingClick}
                  isDroppable={false}
                  isDraggable={false}
                  isResizable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openTopSideBar && (
        <CustomizableWidgetSidebar
          formik={formik}
          contentTypeDataSource={contentTypeDataSource}
          deliverableTypeDataSource={deliverableTypeDataSource}
          searchBoxRef={searchBoxRef}
          searchWidgets={searchWidgets}
          widgetLeftList={getWidgetLeftList()}
          widgetRightList={getWidgetRightList()}
          handleCheckboxChange={handleCheckboxChange}
          onClose={resetWidgetState}
          widgetData={widgetData?.data?.data || []}
          selectedWidgetId={selectedWidgetId}
          newsContentCuration={newsContentCuration}
          handleNewsContentCurationChange={handleNewsContentCurationChange}
          onDragEnd={onDragEnd}
          handleDelete={handleDelete}
          selectedWidgetUniqueName={selectedWidgetUniqueName}
          widgetName={widgetName}
          handleSaveWidget={widgetName === "pinned" ? handleSavePinnedContent : handleSaveWidget}
          widgetIndustryId={widgetIndustryId}
          handleArrowClick={handleArrowClick}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          splitConfig={pinnedContentMerge}
          isEditWidget={isEditWidget}
          newsTabValue={newsTabValue}
          handleNewsTabChange={handleNewsTabChange}
          newlyAddedWidgetList={newlyAddedWidgetList}
          recentlyUpdatedWidgetList={recentlyUpdatedWidgetList}
          onDragEndKeyPlayer={onDragEndKeyPlayer}
          isNewsCuration={isNewsCuration}
          newsCount={newsCount}
          newsCurateSortValue={sortValue}
          handleNewsCurateSortValue={(e) => {
            setSortValue(e?.target?.value);
            setIsNewsCuration(true);
          }}
          setIsNewsCuration={setIsNewsCuration}
          setNewsCount={setNewsCount}
          resolution={grid}
          handlePreviousClick={handlePreviousClick}
        />
      )}

      {openTopSignalsSideBar && (
        <div>
          <SidebarModal
            // style={{ marginTop: "65px", width: "1500px" }}
            className="sidebarModalCustomCss"
            backDrop={true}
            header="Settings"
            closeIcon={
              <div
                onClick={() => {
                  setOpenTopSignalsSideBar(false);
                  formik.setValues({ widgetDisplayName: "" });
                  setWidgetName("");
                  setIsEditWidget(false);
                  formik.setFormikState({ ...formik, touched: {}, errors: {} });
                  handleResetTopSignals();
                  setSelectedTrend(trends[0].trendId);
                }}
              >
                X
              </div>
            }
            footer={
              <div className="pt-2 pb-4">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                  onClick={() => {
                    setOpenTopSignalsSideBar(false);
                    setIsEditWidget(false);
                    formik.setValues({ widgetDisplayName: "" });
                    setWidgetName("");
                    formik.setFormikState({ ...formik, touched: {}, errors: {} });
                    setWidgetContentTypeFilter("");
                    setWidgetDeliverableTypeFilter("");
                    handleResetTopSignals();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-3 mr-3"
                  style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                  onClick={() => {
                    handleSaveWidget();
                  }}
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="widget-wrapper">
              <div className="row" style={{ marginLeft: "6px", marginRight: "6px" }}>
                <div className="col-4">
                  <TextField
                    formik={formik}
                    id="widgetDisplayName"
                    name="widgetDisplayName"
                    placeholder="Widget Name *"
                    required="required"
                    value={formik.values.widgetDisplayName}
                    hideInitial={true}
                    numberCount={true}
                    maxLength={50}
                    characterLimitText={true}
                  />
                </div>
                <div className="col-3">
                  <TextField
                    formik={formik}
                    id="widgetTopSignalsContentType"
                    name="widgetTopSignalsContentType"
                    placeholder="Content Type"
                    value={"Signals"}
                    hideInitial={true}
                    enabled={false}
                  />
                </div>
                <div className="col-3">
                  <TextField
                    formik={formik}
                    id="widgetTopSignalsDeliverableType"
                    name="widgetTopSignalsDeliverableType"
                    placeholder="Deliverable Type"
                    value={"Top Signals"}
                    hideInitial={true}
                    enabled={false}
                  />
                </div>
                <div className="col-2">
                  <DropDownListComponent
                    value={topSignalsTimer}
                    change={(value) => {
                      setTopSignalsTimer(value?.itemData.value);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    // popupHeight="150px"
                    dataSource={timerDataSource}
                    fields={{ text: "text", value: "value" }}
                    placeholder="Auto Load Timer"
                  // popupWidth="284px"
                  />
                </div>
              </div>
              <div className="row" style={{ marginLeft: "6px", marginRight: "6px", marginTop: "10px" }}>
                <div className="col-4">
                  <DropDownListComponent
                    value={widgetIndustryName}
                    change={(value) => {
                      setWidgetIndustryName(value.itemData.trendName);
                      setWidgetIndustryId(value.itemData.id);
                      fetchWidgetData(value.itemData.id);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={industry}
                    fields={{ text: "trendName", value: "trendName" }}
                    placeholder="Filter by Industry"
                    enabled={false}
                  />
                </div>
                <div className="col-4" style={{ marginTop: "20px !important" }}>
                  {sgfTrendData?.length > 0 && (
                    <DropDownListComponent
                      value={sgfName}
                      change={(value) => {
                        if (value?.itemData) {
                          if (!value?.itemData?.id) return;
                          setSgfName(value?.itemData?.id);
                          setTrends(value?.itemData?.trend);
                          setSelectedTrend(value?.itemData?.trend[0]?.trendId);
                          fetchTaxonomy(value?.itemData?.trend[0]?.trendId);
                          setWidgetSGFFilter(value?.itemData?.sgfName);
                          handleResetTopSignals();
                        }
                      }}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="150px"
                      dataSource={sgfTrendData}
                      fields={{ text: "sgfName", value: "id" }}
                      placeholder="Filter by SGF"
                    // popupWidth="284px"
                    />
                  )}
                </div>
                <div className="col-4">
                  {trends.length > 0 && selectedTrend && (
                    <DropDownListComponent
                      value={trends[0].trendId || selectedTrend}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="250px"
                      dataSource={trends}
                      fields={{ text: "trendName", value: "trendId" }}
                      placeholder="Filter by Trend"
                      // popupWidth="284px"
                      change={(value) => {
                        setSelectedTrend(value?.itemData?.trendId);
                        fetchTaxonomy(value?.itemData?.trendId);
                        setWidgetTrendFilter(value?.itemData?.trendName);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={`widget-section widgetSectionResponsive`}>
                <div className="col-md-6">
                  <div className="sidebar-children">
                    <div style={{ paddingLeft: "7px", paddingRight: "16px", paddingBottom: "20px" }}>
                      <div className="row">
                        <div className="col-12" style={{ paddingTop: "12px", paddingBottom: "12px" }}>
                          <div className="widgetHeading">Available Content for Widget</div>
                        </div>
                      </div>
                      <div className="borderStyleTopSignals"></div>
                      <div style={{ display: "flex", paddingBottom: "20px" }}>
                        <div className="col-6">
                          <div style={{ display: "flex", marginLeft: "-16px" }}>
                            <TextBoxComponent
                              placeholder="Filter by Title"
                              // floatLabelType="Always"
                              ref={topSignalsSearchBoxRef}
                              input={searchTopSignalsWidgets}
                              cssClass="searchBoxWidgets"
                            />
                            <div className="col-2">
                              <div
                                style={{ float: "right", marginRight: "22px", height: "40px", borderRadius: "0px" }}
                                className="searchIcon"
                                onClick={handleSearchTopSignals}
                              >
                                <img src="/assets/search.svg" alt="searchIcon" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-2" style={{ float: "right", marginLeft: "-14px", marginTop: "-10px", display: "flex" }}>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                            style={{ marginTop: "10px", float: "right", height: "40px", marginLeft: "-31px", marginRight: "16px" }}
                            onClick={handleResetTopSignals}
                          >
                            Clear
                          </button>
                        </div>

                        <div className="col-4" style={{ float: "right", marginTop: "-10px", marginLeft: "42px" }}>
                          <div style={{ marginTop: "10px" }}>
                            <DropDownListComponent
                              value={topSignalsSort}
                              change={(value) => {
                                setTopSignalsSort(value?.itemData.value);
                              }}
                              cssClass={"customCss e-outline"}
                              floatLabelType="Auto"
                              // popupHeight="150px"
                              dataSource={sortDataSource}
                              fields={{ text: "text", value: "value" }}
                              placeholder="Sort"
                            // popupWidth="284px"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="TopSignalsRightSide" style={{}}>
                        {topSignalsSort === "Newest"
                          ? widgetList?.map((item, index) => {
                            if (
                              !item.isSelected &&
                              !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
                              item.title?.toLowerCase()?.includes(topSignalsSearchTextFilter.toLowerCase()) &&
                              (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
                              (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
                              item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                              item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                            ) {
                              isReturnedTopSignals = false;
                              return (
                                <div
                                  className={`FilterList mainFilterDiv`}
                                  key={item.id + Math.random()}
                                  id={`list${item.id}`}
                                  style={{ height: "120px" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className={"recent_content_card"}>
                                      <div className={"title_top"}>
                                        {item.signalIndicator && (
                                          <p className="signalPercent" style={{ backgroundColor: item.signalIndicatorColor }}>
                                            {item.signalIndicator}
                                          </p>
                                        )}
                                        <p className={"itemTitle"}>{item.title}</p>
                                      </div>
                                      <CommonDateTagSection
                                        location={item.location}
                                        date={item.date}
                                        label={item.label}
                                        tags={item.industryTag}
                                        readTime={item.readTime}
                                        showReadTime={true}
                                        subType={item.subType}
                                      />
                                    </div>
                                    <div style={{ paddingTop: "16px" }}>
                                      <InfoIconwithImage infoData={item} index={index} />
                                    </div>
                                    <div style={{ cursor: "pointer", color: "black", paddingTop: "12px", paddingRight: "12px" }}>
                                      <PiArrowSquareRightThin
                                        size={30}
                                        onClick={() => {
                                          handleCheckboxChange(index);
                                        }}
                                        color="black"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                          : widgetList
                            ?.slice()
                            .reverse()
                            .map((item, index) => {
                              if (
                                !item.isSelected &&
                                !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
                                item.title?.toLowerCase()?.includes(topSignalsSearchTextFilter.toLowerCase()) &&
                                (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
                                (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
                                item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                                item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                              ) {
                                isReturnedTopSignals = false;
                                return (
                                  <div
                                    className={`FilterList mainFilterDiv`}
                                    key={item.id + Math.random()}
                                    id={`list${item.id}`}
                                    style={{ height: "120px" }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div className={"recent_content_card"}>
                                        <div className={"title_top"}>
                                          {item.signalIndicator && (
                                            <p className="signalPercent" style={{ backgroundColor: item.signalIndicatorColor }}>
                                              {item.signalIndicator}
                                            </p>
                                          )}
                                          <p className={"itemTitle"}>{item.title}</p>
                                        </div>
                                        <CommonDateTagSection
                                          location={item.location}
                                          date={item.date}
                                          label={item.label}
                                          tags={item.industryTag}
                                          readTime={item.readTime}
                                          showReadTime={true}
                                          subType={item.subType}
                                        />
                                      </div>
                                      <div style={{ paddingTop: "16px" }}>
                                        <InfoIconwithImage infoData={item} index={index} />
                                      </div>
                                      <div style={{ cursor: "pointer", color: "black", paddingTop: "12px", paddingRight: "12px" }}>
                                        <PiArrowSquareRightThin
                                          size={30}
                                          onClick={() => {
                                            handleCheckboxChange(index);
                                          }}
                                          color="black"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        {isReturnedTopSignals && (
                          <div className="noImageContainer">
                            <Image src={emptyImage} style={{ marginTop: "24px" }} alt="No data available" />
                            <p style={{ fontSize: "17px" }}>No Top Signals</p>
                          </div>
                        )}
                      </div>
                      <div className="widgetCountResultTopSignals">
                        <span className="widgetCountResTitle">Showing {totalCount} results</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="sidebar-children" style={{ width: "-webkit-fill-available" }}>
                    <div style={{ marginLeft: "16px", marginRight: "16px" }}></div>
                    <div style={{ marginLeft: "0px", marginRight: "16px", paddingTop: "5px", minHeight: "400px" }}>
                      <span className="widgetHeading" style={{ paddingTop: "20px", paddingBottom: "15px" }}>
                        Selected Content for Widget
                      </span>
                      <span
                        style={{
                          paddingLeft: "7px",
                          backgroundColor: "#DBDBDD",
                          borderRadius: "6px",
                          marginLeft: "8px",
                          paddingRight: "7px",
                          color: "#000000",
                        }}
                      >
                        {
                          sortedWidgetList.filter(
                            (item) =>
                              item &&
                              item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                              item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                          )?.length
                        }
                      </span>
                      {!sortedWidgetList?.length && (
                        <div
                          style={{
                            color: "#939399",
                            size: "16px",
                            display: "flex",
                            justifyContent: "center",
                            height: "27vh",
                            alignItems: "center",
                            paddingLeft: "55px",
                          }}
                        >
                          No data to show. Select items in the Available Content for Widget
                        </div>
                      )}
                      {sortedWidgetList?.length > 0 ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable" type="droppablePage">
                            {(provided, snapshot) => {
                              return (
                                <div ref={provided.innerRef} className="topSignalsRightSideWidget" style={{}}>
                                  {sortedWidgetList?.length
                                    ? sortedWidgetList.map((item, index) => {
                                      if (
                                        item &&
                                        item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                                        item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                                      ) {
                                        return (
                                          <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                // style={{
                                                //   userSelect: "none",
                                                //   padding: 16,
                                                //   margin: "0 0 8px 0",
                                                //   minHeight: "50px",
                                                //   backgroundColor: snapshot.isDragging ? "#263B4A" : "#456C86",
                                                //   color: "white",
                                                //   ...provided.draggableProps.style,
                                                // }}
                                                >
                                                  <div className={`FilterList mainFilterDiv`} key={item.id + Math.random()} id={`list${item.id}`}>
                                                    <div style={{ display: "flex", height: "120px" }}>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginRight: "10px",
                                                          paddingTop: "13px",
                                                          paddingLeft: "8px",
                                                          fontWeight: "bold",
                                                          color: "black",
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        <SixDotLogo /> {index + 1}
                                                      </div>
                                                      <div className={"recent_content_card"}>
                                                        <div className={"title_top"}>
                                                          {item.signalIndicator && (
                                                            <p className="signalPercent" style={{ backgroundColor: item.signalIndicatorColor }}>
                                                              {item.signalIndicator}
                                                            </p>
                                                          )}
                                                          <p className={"itemTitle"}>{item.title}</p>
                                                        </div>
                                                        <CommonDateTagSection
                                                          location={item.location}
                                                          date={item.date}
                                                          label={item.label}
                                                          tags={item.industryTag}
                                                          readTime={item.readTime}
                                                          showReadTime={true}
                                                          subType={item.subType}
                                                          showSubType={false}
                                                        />
                                                      </div>
                                                      <div style={{ paddingTop: "16px" }}>
                                                        <InfoIconwithImage infoData={item} index={index} />
                                                      </div>
                                                      <div style={{ cursor: "pointer", color: "black", paddingTop: "12px", paddingRight: "12px" }}>
                                                        <PiArrowSquareLeftThin
                                                          onClick={() => {
                                                            handleDelete(item.id);
                                                          }}
                                                          size={30}
                                                          color="black"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      }
                                    })
                                    : null}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </DragDropContext>
                      ) : null}
                    </div>
                    <div className="topSignalsViewMore" style={{}}>
                      <span style={{ marginTop: "5px", marginBottom: "5px", fontWeight: "bold" }}>View More Link URL</span>{" "}
                      <span style={{ marginTop: "5px", marginBottom: "5px" }}> (Add HTML Tag)</span>
                      <AceEditor
                        value={htmlCode}
                        mode="java"
                        theme="github"
                        onChange={onChange}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        style={{ width: "100%", height: "100px", border: "1px solid #ced4da", borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SidebarModal>
        </div>
      )}

      {openKeyPlayersSideBar && (
        <div>
          <SidebarModal
            // style={{ marginTop: "65px", width: "1500px" }}
            className="sidebarModalCustomCss"
            backDrop={true}
            header="Settings"
            closeIcon={
              <div
                onClick={() => {
                  setOpenKeyPlayersSideBar(false);
                  formik.setValues({ widgetDisplayName: "" });
                  setWidgetName("");
                  setIsEditWidget(false);
                  formik.setFormikState({ ...formik, touched: {}, errors: {} });
                  handleResetKeyPlayers();
                  setSelectedTrend(trends[0].trendId);
                  setNewlyaddedWidgetList([]);
                  setUpdatedWidgetList([]);
                }}
              >
                X
              </div>
            }
            footer={
              <div className="pt-2 pb-4">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                  onClick={() => {
                    setOpenKeyPlayersSideBar(false);
                    setIsEditWidget(false);
                    formik.setValues({ widgetDisplayName: "" });
                    setWidgetName("");
                    formik.setFormikState({ ...formik, touched: {}, errors: {} });
                    setSelectedIndex(0);
                    setWidgetContentTypeFilter("");
                    setWidgetDeliverableTypeFilter("");
                    handleResetKeyPlayers();
                    setSelectedTrend(trends[0]?.trendId);
                    setNewlyaddedWidgetList([]);
                    setUpdatedWidgetList([]);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-3 mr-3"
                  style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                  onClick={() => {
                    handleSaveWidget();
                  }}
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="widget-wrapper">
              <div className="row" style={{ marginLeft: "6px", marginRight: "6px" }}>
                <div className="col-4">
                  <TextField
                    formik={formik}
                    id="widgetDisplayName"
                    name="widgetDisplayName"
                    placeholder="Widget Name *"
                    required="required"
                    value={formik.values.widgetDisplayName}
                    hideInitial={true}
                    numberCount={true}
                    maxLength={50}
                    characterLimitText={true}
                  />
                </div>
                <div className="col-2">
                  <TextField
                    formik={formik}
                    id="widgetKeyPlayersContentType"
                    name="widgetKeyPlayersContentType"
                    placeholder="Content Type"
                    value={"Company/Startups"}
                    hideInitial={true}
                    enabled={false}
                  />
                </div>
                <div className="col-2">
                  <DropDownListComponent
                    value={widgetIndustryName}
                    change={(value) => {
                      setWidgetIndustryName(value.itemData.trendName);
                      setWidgetIndustryId(value.itemData.id);
                      fetchWidgetData(value.itemData.id);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={industry}
                    fields={{ text: "trendName", value: "trendName" }}
                    placeholder="Filter by Industry"
                    enabled={false}
                  />
                </div>
                <div className="col-2">
                  {sgfTrendData?.length > 0 && (
                    <DropDownListComponent
                      value={sgfName}
                      change={(value) => {
                        if (!value?.itemData?.id) return;
                        setSgfName(value?.itemData?.id);
                        setTrends(value?.itemData?.trend);
                        setSelectedTrend(value?.itemData?.trend[0]?.trendId);
                        fetchTaxonomy(value?.itemData?.trend[0]?.trendId);
                        setWidgetSGFFilter(value?.itemData?.sgfName);
                        handleResetKeyPlayers();
                      }}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="150px"
                      dataSource={sgfTrendData}
                      fields={{ text: "sgfName", value: "id" }}
                      placeholder="Filter by SGF"
                    // popupWidth="284px"
                    />
                  )}
                </div>

                <div className="col-2">
                  {trends.length > 0 && selectedTrend && (
                    <DropDownListComponent
                      value={trends[0].trendId || selectedTrend}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="250px"
                      dataSource={trends}
                      fields={{ text: "trendName", value: "trendId" }}
                      placeholder="Filter by Trend"
                      // popupWidth="284px"
                      change={(value) => handleTrendChange(value)}
                    />
                  )}
                </div>
              </div>
              <div className={`widget-section widgetSectionResponsive`}>
                <div className="col-md-6">
                  <div className="sidebar-children">
                    <div style={{ marginRight: "50px" }}>
                      <div className="row" style={{ paddingLeft: "6px", paddingRight: "16px" }}>
                        <div className="col-6" style={{ marginTop: "9px" }}>
                          <div className="widgetHeading" style={{ paddingBottom: "29px" }}>
                            Available Content for Widget
                          </div>
                        </div>
                      </div>
                      <div className="borderStyle"></div>

                      <div className="filterMainContainer">
                        <div className="col-6">
                          <div className="searchContainer">
                            <TextBoxComponent
                              placeholder="Filter by Name"
                              // floatLabelType="Always"
                              ref={keyPlayersSearchBoxRef}
                              input={searchKeyPlayersWidgets}
                              cssClass="searchBoxWidgets"
                            />
                            <div className="col-2">
                              <div className="searchIcon customSearchIcon" onClick={handleSearchKeyPlayers}>
                                <img src="/assets/search.svg" alt="searchIcon" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-2" style={{ float: "right", marginLeft: "-6px", marginTop: "-10px", display: "flex" }}>
                          <button type="button" className="btn btn-outline-dark customResetIcon" onClick={handleResetKeyPlayers}>
                            Clear
                          </button>
                        </div>

                        <div className="col-4 resetContainer">
                          <DropDownListComponent
                            value={keyPlayersSort}
                            change={(value) => {
                              setKeyPlayersSortt(value?.itemData.value);
                            }}
                            cssClass={"customCss e-outline"}
                            floatLabelType="Auto"
                            // popupHeight="150px"
                            dataSource={sortDataSource}
                            fields={{ text: "text", value: "value" }}
                            placeholder="Sort"
                          // popupWidth="284px"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="keyPlayersRightSide">
                      <div style={{}}>
                        {keyPlayersSort === "Newest"
                          ? widgetList?.map((item, index) => {
                            if (
                              !item.isSelectedUploaded &&
                              !item.isSelectedNewlyAdded &&
                              !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
                              item.title?.toLowerCase()?.includes(keyPlayersSearchTextFilter.toLowerCase()) &&
                              (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
                              (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
                              item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                              item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                            ) {
                              isReturned = false;
                              return (
                                <div className={`FilterList mainFilterDiv`} key={item.id + Math.random()} id={`list${item.id}`}>
                                  <div style={{ display: "flex" }} className="keyPlayerCustomHeight">
                                    <div className={"recent_content_card"}>
                                      <div className={"title_top"}>
                                        <p className={"itemTitle"}>{item.title}</p>
                                      </div>
                                      <KeyPlayersTagSection
                                        location={item.location}
                                        date={item.date}
                                        label={item.label}
                                        tags={item.industryTag}
                                        readTime={item.readTime}
                                        showReadTime={true}
                                        subType={item.subType}
                                        createdDate={item.createdDate}
                                        updatedDate={item.updatedDate}
                                        keyplayers={true}
                                      />
                                    </div>

                                    <div className="rightArrow">
                                      <PiArrowSquareRightThin
                                        size={30}
                                        onClick={() => {
                                          handleCheckboxChangeKeyPlayers(index);
                                          // handleCheckboxChange(index);
                                        }}
                                        color="black"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                          : widgetList
                            ?.slice()
                            .reverse()
                            .map((item, index) => {
                              if (
                                !item.isSelectedUploaded &&
                                !item.isSelectedNewlyAdded &&
                                !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
                                item.title?.toLowerCase()?.includes(keyPlayersSearchTextFilter.toLowerCase()) &&
                                (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
                                (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
                                item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
                                item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
                              ) {
                                isReturned = false;
                                return (
                                  <div className={`FilterList mainFilterDiv`} key={item.id + Math.random()} id={`list${item.id}`}>
                                    <div style={{ display: "flex", height: "120px" }}>
                                      <div className={"recent_content_card"}>
                                        <div className={"title_top"}>
                                          <p className={"itemTitle"}>{item.title}</p>
                                        </div>
                                        <KeyPlayersTagSection
                                          location={item.location}
                                          date={item.date}
                                          label={item.label}
                                          tags={item.industryTag}
                                          readTime={item.readTime}
                                          showReadTime={true}
                                          subType={item.subType}
                                          createdDate={item.createdDate}
                                          updatedDate={item.updatedDate}
                                          keyplayers={true}
                                        />
                                      </div>

                                      <div className="rightArrow">
                                        <PiArrowSquareRightThin
                                          size={30}
                                          onClick={() => {
                                            handleCheckboxChangeKeyPlayers(index);
                                          }}
                                          color="black"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}

                        {isReturned && (
                          <div className="noImageContainer">
                            <Image src={emptyImage} style={{ marginTop: "24px" }} alt="No data available" />
                            <p style={{ fontSize: "17px" }}>No Key Players</p>
                            {/* <p style={{ fontSize: "17px", marginTop: "-16px" }}>Select items in the Available Content for Widget</p> */}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="widgetCountResult">
                      <span className="widgetCountResTitle">Showing {totalCount} results</span>
                    </div>
                  </div>
                </div>

                {/**Right side */}
                <div className="col-md-6">
                  <div className="sidebar-children" style={{ width: "-webkit-fill-available" }}>
                    <div className="keyPlayersRightMainContainer">
                      <div className="rightWidgetTitleContainer">
                        <span className="rightWidgetTitle">Selected Content for Widget</span>
                        <span className="widgetCount">{widgetCount}</span>
                      </div>
                      <div>
                        <div sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={selectedIndex}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            TabIndicatorProps={{
                              sx: {
                                backgroundColor: "#f94f5e !important",
                              },
                            }}
                          >
                            <Tab
                              label="Newly Added"
                              {...a11yProps(0)}
                              sx={{
                                "&.Mui-selected": {
                                  color: "#f94f5e !important",
                                },
                                textTransform: "capitalize",
                                marginRight: "5px",
                              }}
                            />
                            <Tab
                              label="Recently Updated"
                              {...a11yProps(1)}
                              sx={{
                                "&.Mui-selected": {
                                  color: "#f94f5e !important",
                                },
                                textTransform: "capitalize",
                              }}
                            />
                          </Tabs>

                          {/** Newly Added tab */}
                          <TabPanel value={selectedIndex} index={0} className="newlyAddedContainer">
                            <DragDropContext onDragEnd={onDragEndKeyPlayers}>
                              <Droppable droppableId="droppable" type="droppablePage">
                                {(provided, snapshot) => {
                                  return (
                                    <div ref={provided.innerRef}>
                                      <div key="selectIndex0">
                                        {
                                          newlyaddedWidgetList.map((item, index) => {
                                            if (item && item.sgfId == sgfName && item.trendId == selectedTrend) {
                                              isNewlyAddedReturned = false;
                                              return (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <div
                                                          className={`FilterList mainFilterDiv`}
                                                          key={item.id + Math.random()}
                                                          id={`list${item.id}`}
                                                        >
                                                          <div className="cardConatiner">
                                                            {item && item.sgfId === sgfName && item.trendId === selectedTrend && (
                                                              <div className="cardSixDotIcon">
                                                                <SixDotLogo /> {item?.widgetOrder}
                                                              </div>
                                                            )}
                                                            <div className={"recent_content_card"}>
                                                              <div className={"title_top"}>
                                                                <p className={"itemTitle"}>{item.title}</p>
                                                              </div>
                                                              <KeyPlayersTagSection
                                                                location={item.location}
                                                                date={item.date}
                                                                label={item.label}
                                                                tags={item.industryTag}
                                                                readTime={item.readTime}
                                                                showReadTime={true}
                                                                subType={item.subType}
                                                                createdDate={item.createdDate}
                                                                updatedDate={item.updatedDate}
                                                                keyplayers={true}
                                                                showSubType={false}
                                                              />
                                                            </div>
                                                            <div className="leftArrow">
                                                              <PiArrowSquareLeftThin
                                                                onClick={() => {
                                                                  handleDeleteKeyPlayer(item.id);
                                                                  setIsDeleteClicked(true);
                                                                }}
                                                                size={30}
                                                                color="black"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }}
                                                </Draggable>
                                              );
                                            }
                                          })
                                          // : (
                                          //   <div className="noImageContainer">
                                          //     <Image src={emptyImage} style={{ marginTop: "24px" }} alt="No data available" />
                                          //     <p style={{ fontSize: "17px" }}>No Key Players</p>
                                          //     <p style={{ fontSize: "17px", marginTop: "-16px" }}>Select items in the Available Content for Widget</p>
                                          //   </div>
                                          // )}
                                        }

                                        {isNewlyAddedReturned && (
                                          <div className="noImageContainer">
                                            <Image src={emptyImage} style={{ marginTop: "24px" }} alt="No data available" />
                                            <p style={{ fontSize: "17px" }}>No Key Players</p>
                                            <p style={{ fontSize: "17px", marginTop: "-16px" }}>Select items in the Available Content for Widget</p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </DragDropContext>
                          </TabPanel>

                          {/** Reacently updated tab */}
                          <TabPanel value={selectedIndex} className="recentlyUpdatedContainer" index={1}>
                            <DragDropContext onDragEnd={onDragEndKeyPlayers}>
                              <Droppable droppableId="droppable" type="droppablePage">
                                {(provided, snapshot) => {
                                  return (
                                    <div ref={provided.innerRef}>
                                      <div key="selectIndex1">
                                        {updatedWidgetList.map((item, index) => {
                                          if (item && item.sgfId == sgfName && item.trendId == selectedTrend) {
                                            isRecentlyReturnd = false;
                                            return (
                                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => {
                                                  return (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                      <div className={`FilterList mainFilterDiv`} key={item.id + Math.random()} id={`list${item.id}`}>
                                                        <div className="cardConatiner">
                                                          {item && item.sgfId === sgfName && item.trendId === selectedTrend && (
                                                            <div className="cardSixDotIcon">
                                                              <SixDotLogo /> {item?.widgetOrder}
                                                            </div>
                                                          )}
                                                          <div className={"recent_content_card"}>
                                                            <div className={"title_top"}>
                                                              <p className={"itemTitle"}>{item.title}</p>
                                                            </div>
                                                            <KeyPlayersTagSection
                                                              location={item.location}
                                                              date={item.date}
                                                              label={item.label}
                                                              tags={item.industryTag}
                                                              readTime={item.readTime}
                                                              showReadTime={true}
                                                              subType={item.subType}
                                                              createdDate={item.createdDate}
                                                              updatedDate={item.updatedDate}
                                                              keyplayers={true}
                                                            />
                                                          </div>
                                                          <div className="leftArrow">
                                                            <PiArrowSquareLeftThin
                                                              onClick={() => {
                                                                handleDeleteKeyPlayer(item.id);
                                                              }}
                                                              size={30}
                                                              color="black"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }}
                                              </Draggable>
                                            );
                                          }
                                        })}
                                        {provided.placeholder}
                                        {isRecentlyReturnd && (
                                          <div className="noImageContainer">
                                            <Image src={emptyImage} style={{ marginTop: "24px" }} alt="No data available" />
                                            <p style={{ fontSize: "17px" }}>No Key Players</p>
                                            <p style={{ fontSize: "17px", marginTop: "-16px" }}>Select items in the Available Content for Widget</p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </DragDropContext>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SidebarModal>
        </div>
      )}
      {showAddWidgetModal && (
        <AddWidgetSideBarModal
          setter={setShowAddWidgetModal}
          handleSaveClick={() => setOpenTopSideBar(true)}
          handleCardClick={handleCardClick}
          widgetData={widgetData}
          isSearchFetching={isSearchFetching}
          selectedWidgetId={selectedWidgetId}
          widgetStatus={widgetSelectionStatus}
        />
      )}
    </>
  );
};

export default CustomizableWidgets;
