/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Authentication API 
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { AUTHENTICATION_API as API } from "../../config/apiConfig";

export const authentication = (payload) =>
  API({
    url: "/authentication",
    method: "POST",
    auth: {
      username: payload.email,
      password: payload.password,
    },
    data: payload.data,
  });
export const signOut = (payload) =>
  API({
    url: "/logout",
    method: "POST",
    data: payload.data,
  });

export const RefreshToken = (payload) =>
  API({
    url: "/refresh-token",
    method: "POST",
    data: payload,
  });
