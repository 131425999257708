/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Add Edit Documents
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 19/July/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { updateServiceVideo, getSingleServiceVideo, addServiceVideo, fetchThumbnailUrl } from "../../../../../middleware/services/servicesApi";
import { serviceVideoValidationSchema } from "../validationSchema";
import { Dropdown, TextField } from "../formFields";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VIDEO_TYPE_OPTIONS } from "../formFieldOptions";
import docDefImage from "../../../../../assets/images/news.jpg";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";

const initialState = {
  videoTitle: "",
  videoType: "",
  videoUrl: "",
  videoThumbnail: "",
};
const AddVideoModel = (props) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(null);
  const [docImageSrc, setDocImageSrc] = useState(undefined);
  const docDefaultImageRef = useRef(null);
  const docImageRef = useRef(null);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [showTrash, setShowTrash] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(serviceVideoValidationSchema),
  });

  /*
	   @Description : Save/Update Industry Application Details
   */
  useEffect(() => {
    setDocImageSrc(props.docDefImage);
    if (props.docDefImage) {
      setIsDefaultImage(false);
      setShowTrash(true);
    }
  }, [props.docDefImage, isDefaultImage]);
  const saveServiceVideo = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { videoTitle, videoType, videoUrl } = formik.values;
        payload.videoTitle = videoTitle;
        payload.videoType = videoType;
        payload.videoUrl = videoUrl;
        payload.videoThumbnail = (docDefaultImageRef.current && docDefaultImageRef.current.files[0]) || "";
        const formData = new FormData();
        for (let key in payload) {
          formData.append(key, payload[key]);
        }
        if (props.videoId) {
          updateServiceVideo(props.serviceId, props.videoId, formData)
            .then((res) => {
              dispatch(actionSuccess("Video updated successfully"));
              props.fetchVideoData();
              props.closeVideoModal();
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          addServiceVideo(props.serviceId, formData)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Video added successfully"));
                props.fetchVideoData();
                props.closeVideoModal();
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };
  /*
	   @Description : Fetch Single Industry Application Details
   */
  const fetchSingleServiceVideo = () => {
    getSingleServiceVideo(props.serviceId, props.videoId).then((response) => {
      if (response.status == 200) {
        var serviceVid = response.data.data;
        setEditData(serviceVid);
        formik.setValues({
          videoTitle: serviceVid.serviceDeliverableVideos.videoTitle,
          videoType: serviceVid.serviceDeliverableVideos.videoType,
          videoUrl: serviceVid.serviceDeliverableVideos.videoUrl,
        });
        serviceVid.serviceDeliverableVideos.videoThumbnail && setShowTrash(true);
        setDocImageSrc(
          serviceVid.serviceDeliverableVideos.videoThumbnail ? fetchThumbnailUrl(serviceVid.serviceDeliverableVideos.videoThumbnail) : docDefImage
        );
      }
    });
  };
  useEffect(() => {
    if (props.videoId !== "") {
      dispatch(showLoader());
      !editData && fetchSingleServiceVideo();
      dispatch(hideLoader());
    }
  }, [props.showVideoModal]);
  /*
		@Description : Read Doc image
	*/
  const readDocImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      docImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };
  return (
    <Modal show={props.showVideoModal} backdrop="static" className="modal fade service-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.appId ? "Update" : "Add"} Video
        </h5>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <TextField formik={formik} placeholder="Video Title *" id="videoTitle" name="videoTitle" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="image-holder" style={{ paddingTop: "0px", position: "absolute", marginLeft: "10px" }}>
              <img width="100" height="100" src={docImageSrc || docDefImage} alt="Document" ref={docImageRef} />
              <div className="image-action" style={{ top: "5px" }}>
                <span
                  onClick={() => {
                    dispatch(
                      showAlertBox({
                        content: "Are you sure, you want to delete?",
                        okCallback: async () => {
                          dispatch(actionSuccess("Image deleted successfully"));
                          setShowTrash(false);

                          docImageRef.current.setAttribute("src", docDefImage);
                          docDefaultImageRef.current.value = "";
                        },
                      })
                    );
                  }}
                >
                  <i className={`fa fa-trash mr-1 ${!showTrash ? "d-none" : undefined}`} aria-hidden="true" />
                </span>
                <span onClick={() => docDefaultImageRef.current.click()}>
                  <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <input
              type="file"
              className="d-none"
              accept=".jpg, .png"
              id="docImage"
              name="docImage"
              ref={docDefaultImageRef}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  if (
                    (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                    e.target.files[0].size <= 1024000
                  ) {
                    readDocImage(e.target.files[0]);
                    docImageRef.current.setAttribute("src", e.target.files[0]);
                    setImageError("");
                    setShowTrash(true);
                    setIsDefaultImage(false);
                    formik.setErrors({ ...formik.errors, docImage: "" });
                  } else {
                    setImageError(`Only JPG, PNG files are allowed. Max file size should
												be 1000 Kb. Uploaded file size is ${(e.target.files[0].size / 1024).toFixed(2)} Kb`);
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              {VIDEO_TYPE_OPTIONS && (
                <Dropdown
                  formik={formik}
                  sortOrder="Acending"
                  placeholder="Video Source *"
                  id="videoType"
                  name="videoType"
                  fields={{ text: "label", value: "value" }}
                  dataSource={VIDEO_TYPE_OPTIONS}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TextField formik={formik} type="text" id="videoUrl" placeholder="Video URL *" name="videoUrl" />
          </div>
        </div>
        {imageError && (
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                <small>{imageError}</small>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-dark cy-btn"
          onClick={() => {
            props.closeVideoModal();
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary cy-btn" onClick={saveServiceVideo}>
          {props.videoId ? "Update" : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddVideoModel;
