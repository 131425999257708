import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { JsonToTable } from "react-json-to-table";
import moment from "moment";
import { debounce } from "lodash";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { swap, isAdmin, getDateFromMongoDate } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { fetchContentTypeReport, fetchContentTypeReportDetails } from "../../../middleware/services/reportApi";
import { fetchProductAll } from "../../../middleware/services/productApi";
import { fetchInternalUser } from "../../../middleware/services/userApi";
import { showPopup } from "../../../middleware/actions/popupAction";
import { Dropdown, MultiSelect } from "../../components/CustomFormElements/syncFusionFormFields";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import * as CONSTANTS from "../../../constants/index";
import "./style.scss";
import CustomSidebar from "./SideBar";
import { DropDownFilter, StringFilter } from "app/components/dataGrid/fliters";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import { fetchTaxonomyTrend } from "middleware/services/cmsApi";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import { presets } from "utils";

const ACCESS_RIGHTS_INTERNAL = ["Internal User:FULL"];

let userCompetencyIndustry =
  localStorage.getItem("userCompetencyIndustry") !== "null" ? JSON.parse(localStorage.getItem("userCompetencyIndustry")) : [];

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: "Internal User Report", linkActive: true }];
// enableRipple(true);

const SiderBarGrid = ({ onclose, fetch }) => {
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [rowData, setRowData] = useState([]);
  const columnNames = {
    Title: "userName",
    "Content Type": "subscriptionBtbCompanyName",
    "Delivery Type": "userEmail",
    Industry: "industryName",
    SGF: "linkedInAddress",
    Trial: "subscriptionType",
    "My Alerts": "myAlert",
    "My Alerts": "myAlert",
    "Subscription On Start Date": "subscriptionStartDate",
    "Subscription On End Date": "subscriptionEndDate",
    "Subscription Status": "subscriptionIsActive",
    Industry: "industryName",
  };

  const yesNoFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "YES" },
      { id: "NO", text: "NO" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const deviceTypeFilter = (value) => {
    let sourceData = [
      { id: "Desktop", text: "Desktop" },
      { id: "Smartphone", text: "Smartphone" },
      { id: "Tablet", text: "Tablet" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const sourceFilter = (value) => {
    let sourceData = [
      { id: "Search", text: "Search" },
      { id: "Home Page", text: "Home Page" },
      { id: "Saved Search", text: "Saved Search" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const DateTemplate = (value) => {
    const column = value.column.field;
    return ["publishDate"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? <span>{formatDate(value[`${column}`])}</span> : "";
  };

  const actionTimestampTemplate = (value) => {
    const column = value.column.field;
    return ["actionTimestamp"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? <span>{formatDate(value[`${column}`])}</span> : "";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get UTC components of the date
    const day = String(date.getUTCDate()).padStart(2, "0");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

    return formattedDate;
  };

  const headerDualCom = (argOne, argTwo) => {
    return (
      <div>
        <span className="header-cell">{argOne}</span>
        <span className="header-cell">{argTwo}</span>
      </div>
    );
  };
  const columnFields = [
    {
      field: "userName",
      headerText: "User Name",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      isFrozen: true,
      showInColumnChooser: false,
      headerTemplate: () => headerDualCom("User", "Name"),
    },
    {
      field: "userId",
      headerText: "User Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      showInColumnChooser: false,
      headerTemplate: () => headerDualCom("User", "Email"),
    },
    {
      field: "groupName",
      headerText: "Group Name",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      showInColumnChooser: false,
      headerTemplate: () => headerDualCom("Group", "Name"),
    },
    {
      field: "title",
      headerText: "Content Title",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Title"),
      clipMode: "EllipsisWithTooltip",
    },
    {
      field: "contentType",
      headerText: "Content Type",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Type"),
    },
    {
      field: "deliveryType",
      headerText: "Delivery Type",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "125px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Delivery", "Type"),
    },
    {
      field: "industry",
      headerText: "Content Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "150px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Industry"),
    },
    {
      field: "sgf",
      headerText: "Content SGF",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "SGF"),
    },
    {
      field: "trend",
      headerText: "Content Trend",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Trend"),
    },
    {
      field: "timeSpent",
      headerText: "Time Spent (In Mins)",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Time Spent", "(In Mins)"),
    },
    {
      field: "actionTimestamp",
      headerText: "Content Viewed On",
      type: "Date",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "200px",
      format: { type: "date", format: "dd-MMM-yyyy hh:mm" },
      template: actionTimestampTemplate,
      headerTemplate: () => headerDualCom("Content Viewed On", "(Activity Datetime in UTC)"),
    },
    {
      field: "contentViewedOnMonth",
      headerText: "Viewed on Month",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "115px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content Viewed", "on Month"),
    },
    {
      field: "publishDate",
      headerText: "Date of Publication",
      type: "Date",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      format: { type: "date", format: "dd-MMM-yyyy hh:mm" },
      template: DateTemplate,
      headerTemplate: () => headerDualCom("Date of Publication", "(in UTC)"),
    },
    {
      field: "writer",
      headerText: "Content Writer",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Writer"),
    },
    {
      field: "reviewer",
      headerText: "Content Reviewer",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Reviewer"),
    },
    {
      field: "publisher",
      headerText: "Content Publisher",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Content", "Publisher"),
    },
    {
      field: "deviceType",
      headerText: "Device",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: deviceTypeFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Device"),
    },
    {
      field: "source",
      headerText: "Source",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filterTemplate: sourceFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Source"),
    },
    {
      field: "share",
      headerText: "Share",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Share"),
    },
    {
      field: "bookmark",
      headerText: "Bookmark",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Bookmark"),
    },
    {
      field: "like",
      headerText: "Like",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "90px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Like"),
    },
    {
      field: "download",
      headerText: "Download",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Download", ""),
    },
    {
      field: "addToPresentation",
      headerText: "Add to presentation",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Add to", "presentation"),
    },
    {
      field: "sendThisArticle",
      headerText: "Send this article",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Send this", "article"),
    },
  ];

  // formatRowData function required to format rowData for datGrid
  function formatRowData(rowData) {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = {};
      Object.keys(rD).forEach((rDK) => {
        // if (columnNamesValues[rDK]) {
        newRD[`${columnNamesValues[rDK]}`] = rD[rDK];
        // }
      });
      return newRD;
    });
    return formatedRowData;
  }

  const fetchUsersData = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetch();
      if (response?.data?.data.length > 0) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <React.Fragment>
      <div className="gennx-grid-client-detail-report client-details-user-grid content-type-grid-report" style={{ height: "100%" }}>
        <header className="user-grid-header">
          <span className="header-text">Detail View, Total Number of Records: {rowData.length}</span>
          <span
            className="closeIcon"
            onClick={() => {
              onclose();
            }}
          >
            <CloseIcon />
          </span>
        </header>
        {rowData.length > 0 && (
          <TreeDataGridNew
            gridTitle="Content Type Analysis Report Details"
            rowData={rowData}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            exportFileName={"Content Type Analysis Report - Detail View"}
            frozenColumns={1}
            dropDownFilterFields={["share", "bookmark", "like", "download", "addToPresentation", "sendThisArticle", "deviceType", "source"]}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
    </React.Fragment>
  );
};
// company dashboard component
const ContentTypeReport = (props) => {
  const contentTypeRef = useRef();
  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [reportDetailsDateRange, setReportDetailsDateRange] = useState(null);
  const [functionClick, setFunctionOnclick] = useState(undefined);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [industryArray, setIndustryArray] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const sidebarRef = useRef(null);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const dispatch = useDispatch();

  const industrySource = useMemo(() => {
    if (isAdmin()) {
      const filterArray = industryArray.map((industry) => ({ label: industry.trendName, value: industry.trendName }));
      filterArray.unshift({ label: "All", value: "All" });
      return filterArray;
    }
    const uniqueIndustryNames = new Set();
    const industryList = userCompetencyIndustry
      .filter((industry) => {
        const industryName = industry.industryName;
        return !uniqueIndustryNames.has(industryName) && uniqueIndustryNames.add(industryName);
      })
      .map(({ industryName }) => ({ label: industryName, value: industryName }));
    industryList.unshift({ label: "All", value: "All" });
    return industryList;
  }, [userCompetencyIndustry, industryArray]);

  const reportContentType = [
    {
      label: "Insights, Reports & Research",
      value: "Insights, Reports & Research",
    },
    {
      label: "Webinars, Interviews & Podcasts",
      value: "Webinars, Interviews & Podcasts",
    },
    {
      label: "Profiles - Companies & Technologies",
      value: "Profiles - Companies & Technologies",
    },
    {
      label: "Developments & News",
      value: "Developments & News",
    },
    {
      label: "Dashboard",
      value: "Dashboard",
    },
    {
      label: "Signals",
      value: "Signals",
    },
  ];

  const toggleSidebar = (value, fieldName, industryName, contentType, industryNameHidden) => {
    let filters = [];
    filters.push(["contentType", "eq", contentType]);
    filters.push(["industry", "eq", industryNameHidden]);
    filters.push(["fieldName", "eq", fieldName]);

    setSideBarFilters([...filters]);

    if (sidebarRef.current) {
      sidebarRef.current.hide();
    }

    setShowSidePanel(!showSidePanel);
  };

  const fetchContentTypeReportDetail = useCallback(
    (params = {}) => {
      const { filters = [] } = params;
      const reportDateRange = fnStartDate();

      const DateFilter = reportDetailsDateRange === null ? reportDateRange : reportDetailsDateRange;

      if (DateFilter) {
        let gte = { d: DateFilter[0] };
        Date.prototype.toJSON = function () {
          return moment(this).format();
        };
        gte.d.toJSON = function () {
          return moment(this).format();
        };

        let lt = { d: moment(DateFilter[1]) };
        lt.d.toJSON = function () {
          return moment(this).format();
        };

        filters.push(["actionDate", "gte", gte.d]);
        filters.push(["actionDate", "lt", lt.d]);
      }

      params.filters = [...sideBarFilters, ...filters];
      return fetchContentTypeReportDetails({ ...params });
    },
    [sideBarFilters]
  );

  const initialState = {
    reportGroup: "All",
    reportIndustry: "All",
    reportContentType: [
      "Insights, Reports & Research",
      "Webinars, Interviews & Podcasts",
      "Profiles - Companies & Technologies",
      "Dashboard",
      "Signals",
      "Developments & News",
    ],
  };

  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    // dateFrom.setDate(dateFrom.getDate() - 100);
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.ANALYTICS_REPORTS_DEFAULT_DATE_RANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    return [dateFrom, dateTo];
  };

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    // validationSchema: Yup.object().shape(fundingValidationSchema)
  });

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return a ? (
      <span
        className={value.contentType === "" ? "span-link-color span-link-weight" : "span-link-color"}
        onClick={() => toggleSidebar(a, value.column.field, value["industry"], value["contentType"], value["taskData"]["industryHidden"])}
      >
        {a}
      </span>
    ) : (
      <span className={value.contentType === "" ? "span-link-weight" : ""}>{a}</span>
    );
  };

  const columnFields = [
    {
      field: "industry",
      headerText: "Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "120px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "contentType",
      headerText: "Content Type",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "130px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      textAlign: "Center",
      headerText: "Content Views",
      columns: [
        {
          field: "totalNumberOfViews",
          headerText: "Total No. of Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "120px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "userAccess",
          headerText: "User Having Access",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "120px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "uniqueViews",
          headerText: "No. of Unique Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "120px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "desktopViews",
          headerText: "Desktop Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "mobileViews",
          headerText: "Smart Phone Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "107px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "tabletViews",
          headerText: "Tablet Views",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Page Source",
      columns: [
        {
          field: "totalHomeRecentContentListClick",
          headerText: "Home Page",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "80px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalKeywordSearchListClick",
          headerText: "Keyword Search",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalSaveSearchListClick",
          headerText: "Via Saved Search",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "110px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Custom Events",
      columns: [
        {
          field: "totalDownload",
          headerText: "Total Download",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalShare",
          headerText: "Total Share",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "80px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalBookmark",
          headerText: "Total Bookmark",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalLike",
          headerText: "Total Like",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "80px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalReportAddToPresentation",
          headerText: "Add To Presentation",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "120px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalShareEmail",
          headerText: "Send This Article",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "payWallRequest",
          headerText: "PayWall Request",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Time Matrix (Mins)",
      columns: [
        {
          field: "totalTimeSpent",
          headerText: "Total Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "110px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "averageTimeSpent",
          headerText: "Avg. Time Spent",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "110px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
  ];

  const fetchContentTypeReports = async (reportDateRange, params = {}) => {
    try {
      showLoaderGrid();
      let filters = [];
      filters.push(["contentType", "eq", formik.values.reportContentType]);
      filters.push(["industryName", "eq", formik.values.reportIndustry]);
      const DateFilter = dateRange === null ? reportDateRange : dateRange;
      setReportDetailsDateRange(DateFilter);

      if (DateFilter) {
        let gte = { d: DateFilter[0] };
        Date.prototype.toJSON = function () {
          return moment(this).format();
        };
        gte.d.toJSON = function () {
          return moment(this).format();
        };

        let lt = { d: moment(DateFilter[1]) };
        lt.d.toJSON = function () {
          return moment(this).format();
        };

        filters.push(["actionDate", "gte", gte.d]);
        filters.push(["actionDate", "lt", lt.d]);
      }

      params.filters = [...filters];
      let response = await fetchContentTypeReport({ ...params });
      setRowData(response?.data.data);
      hideLoaderGrid();
    } catch (e) {
      console.error(e);
      hideLoaderGrid();
    }
  };

  const handleReset = () => {
    window.location.reload();
  };

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  const rowDataBound = (args) => {
    if (args.data.level == 0) {
      args.row.childNodes[0].style.fontWeight = "600";
    }
    (args.row?.childNodes[0]).style.fontSize = "14px";
    (args.row?.childNodes[0]).style.textAlign = "left";
  };

  const columnChooserOpenedHandler = () => {
    let data = gridRef.current.columnChooserModule.parent.grid.columnChooserModule.dlgDiv;
    const element = data.querySelector(".e-ccul-ele");
    // const createAndInsertHeader = (text, beforeElement) => {
    //   const elementHtml = document.createElement("span");
    //   elementHtml.style.fontSize = "14px";
    //   elementHtml.style.fontWeight = "600";
    //   elementHtml.textContent = text;
    //   element.insertBefore(elementHtml, beforeElement);
    // };

    // createAndInsertHeader("Industry", element.childNodes[1]);
    // createAndInsertHeader("User Count", element.childNodes[6]);
    // createAndInsertHeader("User Added Period", element.childNodes[10]);
    // createAndInsertHeader("Contact", element.childNodes[13]);
    // createAndInsertHeader("Alert", element.childNodes[16]);
    // createAndInsertHeader("LinkedIn", element.childNodes[19]);
    // createAndInsertHeader("Mobility", element.childNodes[22]);
    // createAndInsertHeader("Energy", element.childNodes[27]);
    // createAndInsertHeader("food & Nutrition", element.childNodes[32]);
  };

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      const resultArray = [
        "totalDownload",
        "totalShare",
        "totalBookmark",
        "totalLike",
        "totalReportAddToPresentation",
        "totalShareEmail",
        "payWallRequest",
        "totalTimeSpent",
        "averageTimeSpent",
      ];
      gridRef.current.grid.hideColumns(resultArray, "field");
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    // hideLoaderGrid();
  };

  const fetchIndustry = useCallback(async () => {
    try {
      showLoaderGrid();
      let params = {
        fields: ["id", "trendName"],
        filters: [["trendParentId", "eq", 0]],
        sort: "trendName:asc",
      };
      const result = await fetchTaxonomyTrend(params);
      // let productIndustryIds = result.data.data.map((item) => item.id);
      setIndustryArray(result.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      fetchIndustry();
      const reportDateRange = fnStartDate();
      await fetchContentTypeReports(reportDateRange);
      hideLoaderGrid();
    })();
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  return (
    <div className="px-3">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row">
          <div className="col-md-2 mt-4 grid-container-report-filters responsive-report-filters content-type-report-datepicker">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline analyst-query-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              maxDays={CONSTANTS.KEYWORD_SEARCH_REPORT_DATE_RANGE_MAX}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-2 mt-4">
            <Dropdown
              formik={formik}
              placeholder="Industry"
              required="required"
              id="reportIndustry"
              name="reportIndustry"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportIndustry}
              dataSource={industrySource}
            />
          </div>
          <div className="col-md-2 mt-4 content-type-report-content-type-select">
            <MultiSelect
              formik={formik}
              ref={contentTypeRef}
              mode="CheckBox"
              sortOrder="Acending"
              showSelectAll={true}
              // selectAllText="Select All"
              showClearButton={false}
              enableRtl={true}
              required="required"
              placeholder="Content Type (multiple selections)"
              id="reportContentType"
              name="reportContentType"
              value={formik.values.reportContentType}
              allowCustomValue={false}
              fields={{ text: "label", value: "value" }}
              dataSource={reportContentType}
            />
          </div>

          <div className="col-md-3 mt-4">
            <button
              type="button"
              onClick={() => {
                const reportDateRange = fnStartDate();
                fetchContentTypeReports(reportDateRange);
              }}
              className="search-btn px-4 btn btn-primary"
            >
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report content-type-grid-report">
        <TreeDataGridNew
          gridTitle="Content Type Analysis Report"
          rowData={rowData}
          getGridRef={getGridRef}
          isPaging={false}
          showResetFilter={true}
          deleteMany={false}
          functionClick={functionClick}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          // getGridRef={getGridRef}
          allowPagination={false}
          showExportButton={true}
          freezeColumn={2}
          created={handleCreated}
          columnChooserHandler={columnChooserOpenedHandler}
          exportFileName={"Content Type Analysis Report"}
          rowDataBound={rowDataBound}
        >
          <ColumnsDirective>
            {columnFields.map((column, index) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
        </TreeDataGridNew>
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel}>
          <SiderBarGrid onclose={toggleSidebar} fetch={fetchContentTypeReportDetail} />
        </CustomSidebar>
      )}
    </div>
  );
};

export default ContentTypeReport;
