/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Technology taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Technology taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
        handleSaveValueChain : Save Value chain data
        contentType : Content Type [News | Company | Product]
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import "../index.scss";
import React, { useState, useEffect, useRef } from "react";
import Tabs from "../../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { fetchTaxonomyTechnology } from "../../../../middleware/services/cmsApi";
import ValueChain from "../valueChain";
import KeyImpactAreaTagging from "../keyImpactAreaTagging";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import SortableTree from "../sortableTree";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
const Taxonomy = (props) => {
  const dispatch = useDispatch();
  const [tagging, setTagging] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [checked, setChecked] = useState([]);
  const [contentTaggedIds, setContentTaggedIds] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [showValueChain, setShowValueChain] = useState(true);
  const [flag, toggleFlag] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabRef = useRef();
  let contentTaggingData = [];
  let taggingData = [];
  const TABS_NAME = ["Technology", "Key Impact Area Tagging", "Value Chain"];
  const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
  const [rightTreeCollapse, setRightTreeCollapse] = useState(true);
  const [leftTreeChecked, setLeftTreeChecked] = useState([]);
  const [rightTreeChecked, setRightTreeChecked] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  /*
        @Description : Fetch taxonomy tagging for respective content 
        1. fetchContentTagging received from parent component as a props
    */
  const fetchContentTagging = () => {
    dispatch(showLoader());
    const contentTaxonomy = props.fetchContentTagging(props.contentId);
    contentTaxonomy
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        data = [...new Map(data.map((v) => [v.dtId, v])).values()];
        if (data.length) {
          contentTaggingData = data;
          taggingData = data.map((values) => values.dtId);
        }
        setContentTaggedIds(taggingData);
        const selectedNodeData = buildSelectedTreeJson(data);
        setTagging(
          selectedNodeData.map((item) => {
            item["level"] = 2;
            return item;
          })
        );
        setLeftTreeCollapse(true);
        setReRender(true);
        setLeftTreeChecked([]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  /*
        @Description : Fetch all technology 
    */
  const fetchTechnology = () => {
    dispatch(showLoader());
    fetchTaxonomyTechnology({
      filters: [["dtParentId", "eq", 0]],
      sort: "dtName:asc",
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        dispatch(hideLoader());
        const data = [...res.data.data];

        setTechnology(data);
        if (selectedTechnology) fetchTaxonomy(selectedTechnology);
        else fetchTaxonomy(data[0].id);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTechnology :: ", err);
      });
  };
  /*
        @Description : Fetch all taxonomy for selected technology
        @param 
        dtRoot : Technology Id
        taggingData : Content taxonomy tagged data [ Use for mark as default check ]
    */
  const fetchTaxonomy = (dtRoot) => {
    dispatch(showLoader());
    taggingData = JSON.parse(JSON.stringify(contentTaggedIds));
    fetchTaxonomyTechnology({
      fields: ["id", "dtName", "dtParentId", "dtLevel"],
      filters: [["dtRoot", "eq", dtRoot]],
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        let checkNode = [];
        if (data.length) {
          const treeJosnArray = buildTreeJson(data, dtRoot);
          data.forEach((element) => {
            if (taggingData.includes(element.id)) {
              checkNode.push(element.id);
              element["isChecked"] = true;
            }
          });
          setChecked(checkNode);
          setTaxonomy(treeJosnArray);
          setRightTreeChecked(checkNode);
          setRightTreeCollapse(true);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
        @Description : Build tree data
        @param : 
        data : Technology taxonomy data
        parentId : technology id
    */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.dtName;
      element["level"] = element.dtLevel;
      if (element["dtParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
        @Description : Build tree data for tagged values
        @param : 
        data : taxonomy data
        parentId :technology id
    */
  const buildSelectedTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      element["id"] = element.dtId;
      element["value"] = element.dtId;
      element["label"] = element.dtName;
      element["showCheckbox"] = false;
      if (element["dtParentId"] == parentId) {
        const children = buildSelectedTreeJson(data, element["dtId"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
        @Description : Make bridge to send callback
        @param : 
        selectedTech : selected technology
        checkValues : Checked taxonomy
    */
  const handleSaveBridge = (selectedTech, checkValues) => {
    props.handleSave(selectedTech, checkValues, fetchContentTagging);
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
  };

  const kiaRender = (e) => {
    setReRender(true);
    setTimeout(() => {
      setReRender(false);
    }, 600);
  };

  const valueChainRender = (e) => {
    setReRender(true);
    setTimeout(() => {
      setReRender(false);
    }, 1000);
  };

  const deleteTechnology = () => {
    if (props.isDelete) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            props.deleteTechnology([...new Set(leftTreeChecked)], fetchContentTagging);
          },
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to remove taxonomy tagging ? ",
          title: "dialogAlertCss",
        })
      );
    } else {
      setShowModalDelete(true);
    }
  };

  const leftTreeOnChecked = (checkedLeft) => {
    let result = [];
    checkedLeft.forEach(function (a, i) {
      result.push(a.id);
      if (Array.isArray(a.children)) {
        result = result.concat(leftTreeOnChecked(a.children));
      }
    });
    setLeftTreeChecked(result);
    return result;
  };

  useEffect(() => {
    fetchContentTagging();
  }, []);
  useEffect(() => {
    if (props.saveTreeData && tabRef.current.selectedItem == 0) {
      handleSaveBridge(selectedTechnology, rightTreeChecked);
    }
  }, [props.saveTreeData]);

  useEffect(() => {
    if (reRender) {
      fetchTechnology();
      setReRender(false);
    }
  }, [contentTaggedIds, reRender]);

  useEffect(() => {
    if (props?.submitStatus && tabRef.current.selectedItem == 0) {
      props.handleSubmit(selectedTechnology, rightTreeChecked, "technology", fetchContentTagging);
    }
  }, [props.submitStatus]);

  return (
    <>
      <Tabs
        tabsName={props.contentType != "video" ? TABS_NAME : ["Technology", "Key Impact Area Tagging"]}
        tabRef={tabRef}
        created={created}
        childTabCss={true}
        cssClass="newTabCss"
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
          if (flag && tabRef.current.selectedItem === 0) {
            toggleFlag(false);
            fetchContentTagging();
          } else if (tabRef.current.selectedItem === 1 || tabRef.current.selectedItem === 2) {
            toggleFlag(true);
          }
        }}
        tabHeaderCss={{ background: "transparent" }}
      >
        <div key="taxonomyTab">
          <div
            className="row"
            style={{
              height: "80vh",
              marginLeft: "0px",
              marginRight: "0px",
              paddingRight: "0.7em",
            }}
          >
            <div className="col-md-6 TaxonomyNewTabLeftContent">
              <div className="row" style={{ paddingTop: "2.2vh", paddingBottom: "7px" }}>
                <div className="">
                  <h6 className="font-weight-bold headline">Technology</h6>
                </div>
                {tagging?.length > 0 && (
                  <div className="ml-auto">
                    {props.accessPermissionTaxonomyDelete && (
                      <button
                        type="button"
                        className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                        onClick={() => deleteTechnology()}
                      >
                        Delete
                      </button>
                    )}
                    {leftTreeCollapse ? (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setLeftTreeCollapse(false)}
                      >
                        Expand All{" "}
                      </span>
                    ) : (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setLeftTreeCollapse(true)}
                      >
                        Collapse All
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="row m-0 p-0">
                <div className="col-md-12 m-0 p-0">
                  <div style={{ paddingTop: "1em" }}>
                    {tagging && tagging.length > 0 ? (
                      <SortableTree
                        treeData={tagging}
                        parentId={"dtParentId"}
                        treeCollapse={leftTreeCollapse}
                        onClick={leftTreeOnChecked}
                        hieghtClass={"custom-tagging-tree-container-wrapper"}
                      />
                    ) : (
                      <div className="text-center">
                        <img src={noTaxonomyImage} alt="No Tagging" width="250" height="250" />
                        <p>
                          Select tags to create <span>Technology Taxonomy</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 TaxonomyNewTabRightContent">
              <div className="row" style={{ justifyContent: "left", paddingLeft: "0.4em" }}>
                <div className="col-md-4" style={{ marginLeft: "1em", paddingTop: "2.2vh" }}>
                  {technology.length > 0 ? (
                    <DropDownListComponent
                      value={selectedTechnology || setSelectedTechnology(technology[0].id)}
                      change={(value) => {
                        setSelectedTechnology(value?.itemData.id);
                        fetchTaxonomy(value.value);
                      }}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="250px"
                      dataSource={[...technology]}
                      fields={{ text: "dtName", value: "id" }}
                      placeholder="Select Technology"
                    />
                  ) : null}
                </div>
              </div>
              <div className="row" style={{ paddingTop: "1em" }}>
                <div className="col-md-12">
                  {taxonomy.length > 0 && (
                    <div className="float-right">
                      {props.accessPermissionTaxonomy && (
                        <button
                          type="button"
                          className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                          onClick={() => {
                            if (props?.isDelete) {
                              props.setSaveTreeData("save");
                            } else {
                              setShowModalAdd(true);
                            }
                          }}
                        >
                          Add
                        </button>
                      )}
                      {rightTreeCollapse ? (
                        <span
                          className="expandButton btn px-3 mr-3"
                          style={{
                            border: "1px solid #F94F5E",
                            color: "#F94F5E",
                          }}
                          onClick={() => setRightTreeCollapse(false)}
                        >
                          Expand All{" "}
                        </span>
                      ) : (
                        <span
                          className="expandButton btn px-3 mr-3"
                          style={{
                            border: "1px solid #F94F5E",
                            color: "#F94F5E",
                          }}
                          onClick={() => setRightTreeCollapse(true)}
                        >
                          Collapse All
                        </span>
                      )}
                    </div>
                  )}
                  {taxonomy.length > 0 ? (
                    <SortableTree
                      treeData={taxonomy}
                      parentId={"dtParentId"}
                      treeCollapse={rightTreeCollapse}
                      onClick={(checkedRight) => {
                        let ids = checkedRight.map((item) => {
                          return item.id;
                        });
                        setRightTreeChecked(ids);
                      }}
                      hieghtClass={"custom-tagging-tree-container-wrapper"}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div key="KIA_tab">
          {selectedIndex === 1 && (
            <KeyImpactAreaTagging
              selectedtree={true}
              contentId={props.contentId}
              fetchKiaTagging={props.fetchKiaTagging}
              contentType={props.contentType}
              saveTreeData={props.saveTreeData}
              // handleSave={props.handleSave}
              reRender={reRender}
              kiaRender={kiaRender}
              isDelete={props.isDelete}
              setSaveTreeData={props.setSaveTreeData}
              deleteKia={props.deleteKia}
              selectedTab={tabRef.current.selectedItem}
              handleSaveKIA={props.handleSaveKIA}
              accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
              accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
              handleSubmit={props.handleSubmit}
              submitStatus={props.submitStatus}
            />
          )}
        </div>
        {props.contentType != "video" ? (
          <div key="valueChainTab">
            {selectedIndex === 2 && (
              <ValueChain
                selectedtree={true}
                showModal={showValueChain}
                hideModal={() => setShowValueChain(false)}
                contentId={props.contentId}
                fetchContentData={props.fetchContentData}
                saveTreeData={props.saveTreeData}
                handleSaveValueChain={props.handleSaveValueChain}
                contentType={props.contentType}
                reRender={reRender}
                valueChainRender={valueChainRender}
                isDelete={props.isDelete}
                setSaveTreeData={props.setSaveTreeData}
                deleteValueChain={props.deleteValueChain}
                accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
                accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
                handleSubmit={props.handleSubmit}
                submitStatus={props.submitStatus}
                selectedTab={tabRef.current.selectedItem}
                setSubmitStatus={props.setSubmitStatus}
              />
            )}
          </div>
        ) : null}
      </Tabs>
      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not add taxonomy on company level. You need to add taxonomy in product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>

      <Modal show={showModalDelete} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not delete taxonomy from company. You need to delete taxonomy from product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalDelete(!showModalDelete)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};
export default Taxonomy;
Taxonomy.propTypes = {
  fetchContentTagging: PropTypes.func.isRequired,
  loadOtherTagInfo: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  saveTreeData: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
};
