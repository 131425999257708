/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : API configuration
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import axios from "axios";
import {
  AUTHENTICATION_URL,
  AUTHORIZATION_URL,
  CMS_API_URL,
  USER_URL,
  COMPANY_URL,
  PRODUCT_URL,
  NEWS_URL,
  TV_URL,
  DELIVERABLE_URL,
  OBJECTIVE_URL,
  SURVEY_API_URL,
  PEOPLE_URL,
  PATENT_URL,
  KIA_URL,
  SERVICE_URL,
  NOTIFICATION_URL,
  CONTENT_BUILDER_URL,
  ACTIVITY_LOGGING_URL,
  WIDGET_URL,
  UPLOAD_DATA_URL,
  HELPDESK_URL,
  REPORT_URL,
} from ".";

function createNewInstance(config) {
  const instance = axios.create({
    headers: { "Content-Type": "application/json" },
    ...config,
  });

  // request interceptor
  instance.interceptors.request.use(onBeforeRequestSent, onRequestError);

  // response interceptor
  instance.interceptors.response.use(onBeforeResponseRecieve, onResponseError);
  return instance;
}

function onBeforeRequestSent(config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
}

function onRequestError(error) {
  return Promise.reject(error);
}

function onBeforeResponseRecieve(response) {
  if (response.data.data && response.data.data.token) {
    localStorage.setItem("token", response.data.data.token);
  }
  return response;
}

function onResponseError(error) {
  if (error.message !== "Network Error") {
    const requestUrl = error.response && error.response.config.url;
    if (!requestUrl.includes("/authentication")) {
      let errors = Array.isArray(error.response.data) ? error.response.data : error.response.data.error;
      // const errorCodes = error.response.data.error.map((code) => code.toLowerCase());
      const errorCodes = Array.isArray(errors) ? errors.map((code) => code.toLowerCase()) : [errors];
      if (errorCodes.includes("jwt expired") || errorCodes.includes("invalid signature") || errorCodes.includes("invalid token")) {
        forceLogout("Session expired");
      } else if (error.status === 401 || (error.response && error.response.status === 401)) {
        forceLogout("Unauthorized");
      }
    }
    return Promise.reject(error.response);
  }
}

function forceLogout(errorMessage) {
  if (errorMessage) {
    console.log(errorMessage);
  }
  setTimeout(() => {
    localStorage.clear();
    window.location.href = "/login";
  }, 1000);
}

export const AUTHENTICATION_API = createNewInstance({
  baseURL: AUTHENTICATION_URL,
});

export const AUTHORIZATION_API = createNewInstance({
  baseURL: AUTHORIZATION_URL,
});

export const CMS_API = createNewInstance({
  baseURL: CMS_API_URL,
});
export const SURVEY_API = createNewInstance({
  baseURL: SURVEY_API_URL,
});

export const USER_API = createNewInstance({
  baseURL: USER_URL,
});

export const COMPANY_API = createNewInstance({
  baseURL: COMPANY_URL,
});

export const PRODUCT_API = createNewInstance({
  baseURL: PRODUCT_URL,
});

export const NEWS_API = createNewInstance({
  baseURL: NEWS_URL,
});
export const TV_API = createNewInstance({
  baseURL: TV_URL,
});

export const DELIVERABLE_API = createNewInstance({
  baseURL: DELIVERABLE_URL,
});

export const TEMPLATE_API = createNewInstance({
  baseURL: DELIVERABLE_URL,
});

export const OBJECTIVE_API = createNewInstance({
  baseURL: OBJECTIVE_URL,
});

export const PEOPLE_API = createNewInstance({
  baseURL: PEOPLE_URL,
});
export const PATENT_API = createNewInstance({
  baseURL: PATENT_URL,
});
export const KIA_API = createNewInstance({
  baseURL: KIA_URL,
});
export const SERVICE_API = createNewInstance({
  baseURL: SERVICE_URL,
});
export const NOTIFICATION_API = createNewInstance({
  baseURL: NOTIFICATION_URL,
});
export const CONTENT_BUILDER_API = createNewInstance({
  baseURL: CONTENT_BUILDER_URL,
});
export const ACTIVITY_LOGGING_API = createNewInstance({
  baseURL: ACTIVITY_LOGGING_URL,
});
export const WIDGET_API = createNewInstance({
  baseURL: WIDGET_URL,
});
export const UPLOAD_DATA_API = createNewInstance({
  baseURL: UPLOAD_DATA_URL,
});
export const HELPDESK_API = createNewInstance({
  baseURL: HELPDESK_URL,
});
export const REPORT_API = createNewInstance({
  baseURL: REPORT_URL,
});
