/**
 * File Description: Constant declarations
 * Author: Suraj Varane
 * Date: 15/Mar/2021
 */
export default {
	supportedImageFormats: [
		//jpg or jpeg
		"image/jpeg",
		// png images
		"image/png"
	],
	SURVEY_TYPE: {
		ONGOING: "Ongoing",
		ONETIME: "One Time",
		NPS_SURVEY: "NPS Survey"
	},
	SURVEY_FREQUENCY: {
		MONTHLY: "Monthly",
		QUARTERLY: "Quarterly",
		YEARLY: "Yearly"
	},
	SURVEY_QUARTER: {
		JAN_MAR: "Jan - Mar",
		APR_JUN: "Apr - Jun",
		JUL_SEP: "Jul - Sep",
		OCT_DEC: "Oct - Dec",
		
	},
	TEAM_NAMES: {
		PRODUCT_TEAM: "ESG Product Team",
		APPLICATION_TEAM: "ESG Application Team",
		MARTECH_TEAM: "ESG MarTech Team",
		CORE_TEAM: "ESG Core Team"
	},
	NPS_SURVEY_TYPE: {
		PROJECT_FEEDBACK: "Project Feedback - NPS",
		JOURNEY_AUDIT: "User Journey Audit",
		PRODUCT_USAGE: "Product Usage / Pattern Evaluation"
	},
	SURVEY_STATUS: {
		DRAFT: "Draft",
		NOT_STARTED: "Not Started",
		OPEN: "Open",
		CLOSED: "Closed"
	},
	SURVEY_INVITATION_STATUS: {
		NOT_STARTED: "Not Started",
		SENDING: "Sending",
		SENT: "Sent"
	},
	YES: "YES",
	NO: "NO",
	SURVEY_AUDIENCE: {
		PUBLIC: "Public",
		LIMITED: "Limited",
	},
	SURVEY_DOMAIN_WHATNEXT: process.env.REACT_APP_SURVEY_DOMAIN_WHATNEXT || "survey.whatnextglobal.com",
	SURVEY_DOMAIN_INSIDER: process.env.REACT_APP_SURVEY_DOMAIN_INSIDER || "survey.industryinsider.com",
	SURVEY_DOMAIN_COGNITION: process.env.REACT_APP_SURVEY_DOMAIN_COGNITION || "survey.cognitionsolutions.com",
	SURVEY_DOMAIN_CHEERSIN: process.env.REACT_APP_SURVEY_DOMAIN_CHEERSIN || "survey.cheersin.com",
};
