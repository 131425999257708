/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Auth Saga (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { takeLatest, call, put, fork, takeEvery } from "redux-saga/effects";
import { USER_SIGNIN, USER_SIGNOUT } from "../../constants/actionConstants";
import { actionStart, actionStop, actionError } from "../actions/utilityAction";
import { authentication, signOut } from "../services/authenticationApi";
import { fetchCoreModules } from "../services/cmsApi";
import { getLandingPageRoute } from "../../utilities";

function* authenticateUser(prop) {
  const { payload } = prop;
  try {
    const { email, password } = payload;
    let data = { appId: process.env.REACT_APP_ENTERPRISE_APP_ID, clientName: process.env.REACT_APP_ENTERPRISE_CLIENT_NAME };
    if (email === "" || password === "") {
      yield put(actionError("Please enter email and password."));
    } else {
      yield put(actionStart());
      const response = yield call(authentication, { email, password, data });
      localStorage.setItem("userCompetencyIndustry", "null");
      if (response.data?.data) {
        if (response.data.data?.userCompetencyIndustry) {
          localStorage.setItem("userCompetencyIndustry", JSON.stringify(response.data.data?.userCompetencyIndustry));
        }
      }
      yield put(actionStop());
      if (
        response.data.data &&
        typeof response.data.data.token !== "undefined" &&
        typeof response.data.data.sideBarMenuData !== "undefined" &&
        typeof response?.data?.data?.userAuthorization !== "undefined"
      ) {
        localStorage.setItem("token", response?.data?.data?.token);
        localStorage.setItem("refresh-token", response?.data?.data?.refreshToken);
        localStorage.setItem("userEmail", response?.data?.data?.userEmail);
        localStorage.setItem("userFname", response?.data?.data?.userFname);
        localStorage.setItem("userLname", response?.data?.data?.userLname);
        localStorage.setItem("genxUserId", response?.data?.data?.genxUserId);
        localStorage.setItem("enterprise", response?.data?.data?.token);
        localStorage.setItem("enterpriseUserId", response?.data?.data?.userId);
        localStorage.setItem("userType", response?.data?.data?.userType || "");
        localStorage.setItem("genxUserProductVariant", JSON.stringify(response?.data?.data?.genxUserProductVariant));
        let accessData = {
          userAccess: response?.data?.data?.userAuthorization || [],
          dynamicMenu: response?.data?.data?.sideBarMenuData || [],
        };
        if (
          response?.data?.data?.userAuthorization &&
          typeof response?.data?.data?.userAuthorization !== "undefined" &&
          response?.data?.data?.sideBarMenuData &&
          typeof response?.data?.data?.sideBarMenuData !== "undefined"
        ) {
          const accessToken = window.btoa(JSON.stringify(accessData));
          localStorage.setItem("access-token", accessToken);
        }
        window.location.assign("/selector");
        const coreModules = yield call(fetchCoreModules, {
          filters: [["moduleNavOrder", "ex", ""]],
          sort: "moduleNavOrder:asc",
          limit: 1000,
        });
        window.location.assign(getLandingPageRoute(accessData, coreModules.data.data) || "/company");
      } else {
        let errMsg = response?.data?.message
          ? response?.data?.message !== "Unauthorized"
            ? response?.data?.message
            : "Incorrect email or password!"
          : "Incorrect email or password!";
        yield put(actionError(errMsg || "Incorrect email or password!"));
      }
    }
  } catch (e) {
    let errMsg = e?.data?.message
      ? e?.data?.message !== "Unauthorized"
        ? e?.data?.message
        : "Incorrect email or password!"
      : "ERROR : Unable to handle request";
    yield put(actionError(errMsg || "Incorrect email or password!"));
  }
}

function* signoutUser() {
  try {
    const refreshToken = localStorage.getItem("refresh-token");
    let data = {
      clientName: process.env.REACT_APP_ENTERPRISE_CLIENT_NAME,
      refreshToken: refreshToken,
    };
    yield put(actionStart());
    const response = yield call(signOut, { data });
    yield put(actionStop());
    if (response.data.data) {
      localStorage.clear();
      // yield put(userSignout(null));
      window.location.replace("/");
    } else {
      let errMsg = response?.data?.message;
      yield put(actionError(errMsg || "ERROR : Unable to handle request"));
    }
  } catch (e) {
    let errMsg = e?.data?.message;
    yield put(actionError(errMsg || "ERROR : Unable to handle request"));
  }
}

function* watchAuthenticateUser() {
  yield takeEvery(USER_SIGNIN, authenticateUser);
}

function* watchUserSignout() {
  yield takeLatest(USER_SIGNOUT, signoutUser);
}

const authSaga = [fork(watchAuthenticateUser), fork(watchUserSignout)];

export default authSaga;
