/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company component integrations Basic, Advanced, Taxonomy and and Product
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 25/Jun/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useEffect, useState, useRef } from "react";
import Tabs from "../../../components/tabsComponent";
import BasicUI from "./basic";
import AdvancedUI from "./advanced";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import CollaborationUI from "./collaboration";
import TaxonomyUI from "./tagging";
import Product from "../../product";
import { fetchSingleCompany, saveCompanyAdvanced, updateOverview } from "../../../../middleware/services/companyApi";
import "../style.scss";
import { getProductVariant, accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import AccessDenied from "../../../components/accessDenied";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { resetInternalGrid, setActiveTabs } from "../../../../middleware/actions/gridAction";
import { actionError, actionSuccess, showLoader } from "../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState } from "../../../../middleware/actions/companyAction";
import { PRODUCT_VARIANT } from "../../../../constants/index";
import DisplaySettings from "./display";
// stepper data

const CompanyUI = (props) => {
  const productVariant = getProductVariant();
  const { accessRights: aR } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [advancedFetched, setAdvancedFetched] = useState(false);
  const [basicFetched, setBasicFetched] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  const tabRef = useRef();
  const companyId = props.computedMatch?.params?.companyId || "";
  const [companyName, setCompanyName] = useState("");
  const commonState = useSelector((state) => state.companyState);
  const CommonStateInfo = commonState?.basicDetails;
  const editData = companyId && companyId.length === 24 ? true : false;
  let TABS_NAME = ["Basic", "Advanced", "Products & Technologies", "Collaboration"];
  productVariant === PRODUCT_VARIANT.INSIDER ? TABS_NAME.push("Taxonomy & Tags", "Display Settings") : TABS_NAME.push("Taxonomy");
  // get the company name
  const getCompanyName = async () => {
    let fields = { fields: ["id", "companyName"] };
    const response = await fetchSingleCompany(companyId, { ...fields });
    setCompanyName(response.data.data.companyName);
  };
  useEffect(() => {
    if (companyId) {
      getCompanyName();
    }
  }, [companyId]);
  const hashArr = {
    "#basic": "Basic",
    "#advanced": "Advanced",
    "#product": "Products & Technologies",
    "#collaboration": "Collaboration",
    "#taxonomy": "Taxonomy",
    "#display": "Display Settings",
  };
  const breadCrumbLinks = [
    { linkUrl: "/company", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: companyId ? `/company/${companyId}/edit#basic` : `/company/add#basic`,
      linkName: companyName ? companyName : "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/company/${companyId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const dispatch = useDispatch();
  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };

    if (companyId !== "") {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.enableTab(5, false);
      tabRef.current.select(0);
    }
  };

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/company"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, companyName, props.location.hash]);

  useEffect(() => {
    const options = {
      // damping:0.1,
      thumbMinSize: 20,
      alwaysShowTracks: true,
    };
    setTimeout(() => {
      document.querySelector(".gennx-content-wrapper");
    }, 2000);
  }, []);

  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);
    tabRef.current.enableTab(4, true);
    tabRef.current.enableTab(5, true);
    tabRef.current.select(0);
  };

  const tabSelected = useSelector((state) => state.gridState.internalGridState.tabSelected);

  const handleCommonSave = (id) => {
    dispatch(showLoader());

    try {
      let basicDetails = CommonStateInfo || {};
      if (Object.keys(basicDetails.length > 0)) {
        if (isNaN(basicDetails.companyFoundedYear) || String(basicDetails?.companyFoundedYear)?.length !== 4)
          return dispatch(actionError("Invalid Founded Year in Basic > Overview Section!"));
        if (Object.keys(commonState?.advanceDetails)?.length > 0) {
          if (!commonState?.advanceDetails?.companyAbout) return dispatch(actionError("Please Write Few things about the company!"));
        }
        if (basicDetails.companyName?.trim() === "") return dispatch(actionError("Company Name Cannot be empty!"));
        let payload = {};
        payload.companyWorkInProgress = basicDetails?.companyWorkInProgress;
        payload.companyName = basicDetails?.companyName;
        payload.companyLegalStatus = basicDetails?.companyLegalStatus;
        payload.companyIsStartup = basicDetails?.companyIsStartup;
        payload.companyFoundedYear = basicDetails?.companyFoundedYear;
        payload.companyEmpSize = basicDetails?.companyEmpSize;
        payload.companyEmail = basicDetails?.companyEmail;
        payload.companyWebsite = basicDetails?.companyWebsite;
        payload.companyParent = {
          companyName: basicDetails?.companyParent,
        };
        payload.companySocial = {
          twitter: basicDetails?.companySocialTwitter,
          facebook: basicDetails?.companySocialFacebook,
          linkedIn: basicDetails?.companySocialLinkedIn,
        };
        payload.companyPublishedDate = basicDetails?.companyPublishedDate;
        if (basicDetails?.companyStatus === "Acquired By") {
          payload.companyStatus = {
            value: basicDetails?.companyStatus,
            acquiredBy: {
              companyName: basicDetails?.companyAcquiredBy,
            },
          };
        } else {
          payload.companyStatus = {
            value: basicDetails?.companyStatus,
          };
        }

        let saveOverviewData = new FormData();
        for (let uKey in payload) {
          if (typeof payload[uKey] != "object") {
            if (payload[uKey] && payload[uKey] !== "") saveOverviewData.append(uKey, payload[uKey]);
          } else {
            if (
              payload[uKey].acquiredBy &&
              (payload[uKey].acquiredBy?.companyId == payload[uKey].acquiredBy?.companyName ||
                payload[uKey].acquiredBy?.companyName.includes("Create"))
            ) {
              const value = {
                value: "Acquired By",
                acquiredBy: {
                  companyName: payload[uKey].acquiredBy.companyId,
                },
              };
              saveOverviewData.append(uKey, JSON.stringify(value));
            } else {
              if (payload[uKey] && payload[uKey] !== "") saveOverviewData.append(uKey, JSON.stringify(payload[uKey]));
              if (uKey == "companyLegalStatus" && !payload[uKey]) saveOverviewData.append(uKey, "");
            }
          }
        }

        if (commonState?.basicDetails?.companyName !== "" && commonState?.basicDetails?.companyWebsite !== "") {
          updateOverview(companyId, saveOverviewData)
            .then((response) => {
              if (response.status === 200) {
                if (commonState?.advanceDetails?.companyAbout !== "") {
                  handleCommonSaveAd();
                }
              }
              dispatch(actionSuccess("Company Updated Successfully"));
            })
            .catch((err) => {
              dispatch(actionError("Something went wrong"));
            });
        } else {
          dispatch(actionError("Please enter information in basic tab"));
        }
      }
    } catch {
      dispatch(actionError("Something went wrong"));
    }
  };

  const handleCommonSaveAd = (id) => {
    dispatch(showLoader());
    if (commonState?.advanceDetails?.companyAbout !== "") {
      try {
        let advanceTabDetails = commonState?.advanceDetails || {};
        if (Object.keys(advanceTabDetails.length > 0)) {
          let payload = {};
          payload.companyAbout = advanceTabDetails?.companyAbout || "";
          payload.companyBusinessModel = advanceTabDetails?.companyBusinessModel || "";
          payload.companyFuturePlans = advanceTabDetails?.companyFuturePlans || "";
          payload.companyWhatsUnique = advanceTabDetails?.companyWhatsUnique || "";
          payload.id = companyId;

          const formData = new FormData();
          const data = Object.keys(payload).reduce((formData, key) => {
            formData.append(key, payload[key]);
            return formData;
          }, new FormData());

          saveCompanyAdvanced(companyId, data)
            .then((res) => {
              dispatch(actionSuccess("Company advanced info saved successfully"));

              dispatch(setClickSaveSubmitState());
            })
            .catch((err) => {
              dispatch(actionError("Something went wrong"));
            });
        }
      } catch {
        dispatch(actionError("Something went wrong"));
      }
    } else {
      dispatch(actionError("please enter information in content tab"));
    }
  };

  /* Interface access  */
  const interfaces = ["Basic", "Advanced", "Products & Technologies", "Collaboration", "Taxonomy", "Display Settings"];
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, interfaces);

  /* Interface functionality wise access  */
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
          if (tabSelected != tabRef.current.selectedItem) {
            dispatch(resetInternalGrid());
          }
          dispatch(setActiveTabs(tabRef.current.selectedItem));
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="companyOverview">
          {selectedIndex === 0 && interfaceAccess ? (
            <BasicUI
              basicFetched={basicFetched}
              setBasicFetched={setBasicFetched}
              companyId={companyId}
              selectedIndex={selectedIndex}
              handleCommonSaveAd={handleCommonSaveAd}
              editData={editData}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              {...props}
              newRecord={newRecord}
              hashArr={hashArr}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="companyAdvanced">
          {selectedIndex === 1 && interfaceAccess ? (
            <AdvancedUI
              advancedFetched={advancedFetched}
              setAdvancedFetched={setAdvancedFetched}
              companyId={companyId}
              selectedIndex={selectedIndex}
              handleCommonSave={handleCommonSave}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              {...props}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="companyProducts">
          {selectedIndex === 2 && interfaceAccess ? (
            <Product
              companyId={companyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              {...props}
              interfaceActionAccessProduct={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="companyCollaboration">
          {selectedIndex === 3 && interfaceAccess ? (
            <CollaborationUI
              companyId={companyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              {...props}
              interfaceActionAccessCollaboration={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="companyTaxonomy">
          {selectedIndex === 4 && interfaceAccess ? (
            <TaxonomyUI
              companyId={companyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              {...props}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="companyDisplaySettings">
          {selectedIndex === 5 && interfaceAccess ? (
            <DisplaySettings
              companyId={companyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              {...props}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default CompanyUI;
