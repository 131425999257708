/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  All Form elements will use in making form 
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 05/June/2021
	Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect, useRef } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent, CheckBoxSelection, Inject, MultiSelectComponent, AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import CKEditor from "ckeditor4-react";
import { removeTags } from "../../../../utilities"
import { ckEditorConfigBasic } from "../../../../config/ckEditorConfig"
const customStyles = {
	control: (base, state) => ({
		...base,
		boxShadow: "none"
	})
};
export const TextField = props => {
	const [value, setValue] = useState("");
	const textAreaRef = useRef();
	const onInput = (e) => {
		let word;
		let addressCount;
		word = e.value;
		addressCount = word.length;
		(document.getElementById(`${props.id}Count`)).textContent = addressCount + "/" + props.maxLength;
	}

	useEffect(() => {
		let name = props.name;
		setValue(props.formik.values[name]);
		props.multiline && document.getElementsByName(`${props.name}`)[0].setAttribute("rows", props.rows || 0);

	}, [props.formik.values, props.name, props.rows]);
	return <>
		<div className={`form-group ${props.required && props.required || ""}`}>
			{props.inputGroupText && <div className="e-float-icon-left">
				<TextBoxComponent
					ref={textAreaRef}
					{...props.formik.getFieldProps(...props.name)}
					autoComplete='off'
					value={value}
					{...props}
					showClearButton={true}
					cssClass={'customCss e-outline'} floatLabelType="Auto"
					input={props.numberCount && onInput}
				/>
			</div>
			}
			{!props.inputGroupText && <TextBoxComponent
				{...props.formik.getFieldProps(...props.name)}
				autoComplete='off'
				value={value}
				{...props}
				showClearButton={true}
				cssClass={'customCss e-outline'} floatLabelType="Auto"
				input={props.numberCount && onInput}
			/>}
			{props.numberCount && <span className="float-right" id={`${props.id}Count`}>{`${value.length || 0}/${props.maxLength}`}</span>}
			<div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
				{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}
			</div>
		</div>
	</>;
};

export const TextArea = (props) => {
	const [value, setValue] = useState("");
	const [count, setCount] = useState(props.maxLength);
	const { name, label } = props.properties;


	useEffect(() => {
		setValue(props.formik.values[name]);
		props.formik.values[name] && setCount(props.maxLength - props.formik.values[name].length);
	}, [props.formik.values, name]);

	return <>
		<div className={`form-group ${props.required}`}>
			<label>{label} </label>
			<textarea
				className="form-control"
				autoComplete='off'
				value={value}
				{...props.properties}
				{...props.formik.getFieldProps(name)}
				onKeyUp={(e) => { document.getElementById(name + "_").innerHTML = (props.maxLength - e.target.value.length) }}
			/>
			<b style={{ fontWeight: 500, display: props.showCharCount ? "" : "none" }} htmlFor="objective">Characters left:&nbsp;</b><span id={name + "_"}>{count}</span>
			<div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
				<small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
			</div>
		</div>

	</>
}
export const Dropdown = (props) => {
	const [value, setValue] = useState("");
	useEffect(() => {
		let name = props.name;
		setValue(props.formik.values[name]);
	}, [props.formik.values, props.name]);
	return <>

		<div className="form-group required"><DropDownListComponent
			value={value}
			{...props}
			change={(selectedOption) => {
				props.formik.setFieldValue(props.name, selectedOption.value)
			}}
			cssClass={'customCss e-outline'}
			floatLabelType="Auto"
		/>
			<div className="text-danger"
				style={{
					display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ?
						"block" : "none"
				}}
				role="alert"
			>
				{(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ?
					props.formik.errors[props.name] || "" : ""}

			</div>
		</div>
	</>;
}
export const CkEditor = (props) => {
	const [values, setFormik] = useState(props.formik.values);
	const [content, setContent] = useState("");
	const [charCount, setCharCount] = useState(0);
	const onChange = (evt) => {
		const newContent = evt.editor.getData();
		const textWithoutHtml = removeTags(newContent)
		setCharCount(textWithoutHtml.length)
		setContent(newContent);
		let changedData = {};
		changedData[props.name] = newContent;
		setFormik({ ...values, ...changedData });
		props.formik.setFieldValue(props.name, newContent, true);
	};

	useEffect(() => {
		let name = props.name;
		setContent(props.formik.values[name] ? props.formik.values[name] : "");
	}, [props.formik.values, props.name]);

	return <>
		<CKEditor
			activeClass="editor"
			data={content && content}
			onChange={onChange}
			onReady={(editor) => {
				// You can store the "editor" and use when it is needed.
				// console.log("Editor is ready to use!", editor);
				editor.editing.view.change((writer) => {
					writer.setStyle(
						"height",
						"100px",
						editor.editing.view.document.getRoot()
					);
				});
			}}
			config={{ ...ckEditorConfigBasic }}
			{...props}
		/>
		{/* {props?.charCountLimit > 0 && <small className="float-right"><b>Characters:</b>{` ${charCount} / ${props?.charCountLimit}`}</small>} */}
		{<small className="float-right"><b>Characters:</b>{` ${charCount}`}</small>}
		<div className="text-danger" style={{ display: (props.formik.touched[props.name]) === true ? "block" : "none" }} role="alert">
			{(props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
		</div>
	</>;
}
export const MultiSelect = props => {
	const [value, setValue] = useState("");
	const { name } = props;
	useEffect(() => {
		props.formik.values[name] && setValue(props.formik.values[name]);
	}, [name]);

	return <>
		<div className="form-group required">
			<MultiSelectComponent
				autoComplete='off'
				value={value}
				{...props}
				change={(e) => {
					props.formik.setFieldValue(name, e.value);
					setValue(e.value);
					if (e.value) {
						props.formik.setFieldError(name, "")
					}
				}}
				cssClass={'customCss e-outline'}
				floatLabelType="Auto"
			>
				<Inject services={[CheckBoxSelection]} />
			</MultiSelectComponent>
			<div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
				{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}
			</div>
		</div>
	</>;
};
export const ToggleSwitch = (props) => {
	const [checked, setChecked] = useState(false);
	const { name, label } = props.properties;
	useEffect(() => {
		setChecked(props.formik.values[name] === "Yes" ? true : false);
	}, [name, props.formik.values])

	return <>
		<div className={`form-group ${props.required && props.required || ""} row`}>
			<label className="col-md-4 mt-3">{label} </label>
			<div className="col-md-5" style={{ top: "8px" }}>
				<label className="custom-switch3">
					<input type="checkbox"
						{...props.properties}
						checked={checked ? true : false}
						onChange={(e) => {
							setChecked(e.target.checked);
							props.formik.setFieldValue(name, e.target.checked ? "Yes" : "No", true);

						}}
					/>
					<div>
						<span className="on">Yes</span>
						<span className="off">No</span>
					</div>
					<i></i>
				</label>
			</div>


			<div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
				<small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
			</div>

		</div>

	</>
}
// 
export const DatePicker = (props) => {
	const [value, setValue] = useState("");
	const { name, label } = props.properties;


	useEffect(() => {
		setValue(props.formik.values[name]);
	}, [props.formik.values, name]);

	return <>
		<div className={`form-group ${props.required && props.required || ""}`}>
			<DatePickerComponent
				cssClass={'e-outline '}
				// floatLabelType="Auto"
				autoComplete='off'
				{...props.properties}
				{...props.formik.getFieldProps(name)}
			/>
			<div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
				<small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
			</div>
		</div>

	</>
}


export const AutoCompleteDropdown = (props) => {
	const [value, setValue] = useState("");
	useEffect(() => {
		let name = props.name;
		setValue(props.formik.values[name]);
	}, [props.formik.values, props.name]);


	return <>

		<div className={`form-group ${props.required && props.required || ""}`}><AutoCompleteComponent
			// {...props.formik.getFieldProps(...props.name)}
			value={value}
			change={(selectedOption) => {
				props.formik.setFieldValue(props.name, selectedOption.value)
			}}

			// filtering={(inputValue) => { inputValue && props.handleSearch(inputValue.text); }}
			popupHeight="250px"
			highlight={true}
			{...props}
			// dataSource={dataSource}
			cssClass={'customCss e-outline'}
			floatLabelType="Auto"
			popupHeight="250px"
		// isClearable
		/>
			<div className="text-danger"
				style={{
					display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ?
						"block" : "none"
				}}
				role="alert"
			>
				<small>
					{(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ?
						props.formik.errors[props.name] || "" : ""}

				</small>
			</div>
		</div>
	</>;
}
