import { SidebarComponent } from "@syncfusion/ej2-react-navigations";

import * as React from "react";
import { useRef, useEffect, useState } from "react";
import "../index.scss";
import { ReactComponent as CloseIcon } from "../../../../assets/images/closeIcon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, ToggleSwitchNew } from "../../../components/CustomFormElements/syncFusionFormFields";
const SideBar = (props) => {
  const {
    selectedTaxonomy = undefined,
    selectedNode = undefined,
    handleCancel = undefined,
    handleSubmit = undefined,
    showAddEdit,
    showInstruction,
    show,
    setShowInstruction,
  } = props;
  const sidebarRef = useRef();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      dtDisplayName: "",
      dtName: "",
      dtDisableNode: "NO",
      dtHideNode: "NO",
      dtSignalNode: "NO",
    },
    validationSchema: Yup.object().shape({
      dtName: Yup.string()
        .min(2, "Please enter valid name")
        .max(100, "Please enter within 100 characters")
        .matches(
          /^[A-Za-z0-9][^$,.\n]*$/,
          "Node name must start with alphabet or digit and should not contains $ , and . and should not contain space in starting"
        )
        .required("Please enter node name!")
        .trim("Node name should not contain space in the end.")
        .strict(),
      dtDisplayName: Yup.string()
        .min(2, "Please enter valid name")
        .max(100, "Please enter within 100 characters")
        .matches(
          /^[A-Za-z0-9][^$,.\n]*$/,
          "Node name must start with alphabet or digit and should not contains $ , and . and should not contain space in starting"
        )
        .trim("Node name should not contain space in the end.")
        .strict(),
    }),
  });

  const handleSubmitDefault = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        console.log(res, "res");
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        handleSubmit && handleSubmit(formik.values.dtName, formik.values.dtDisplayName, formik.values.dtHideNode, formik.values.dtDisableNode, formik.values.dtSignalNode);
      }
    });
  };

  const created = () => {
    sidebarRef.current.element.style.visibility = "";
  };
  const toggleClick = () => {
    sidebarRef.current.toggle();
    props.setShow(false);
    props.setShowAddEdit(false);
  };
  useEffect(() => {
    sidebarRef.current.show();
  }, [props.toggle]);

  useEffect(() => {
    if (selectedTaxonomy?.length == 1) {
      console.log(selectedTaxonomy, "taxonomy");
      formik.setValues({
        dtName: selectedTaxonomy[0]?.label,
        dtDisplayName: selectedTaxonomy[0]?.trendDisplayName ? selectedTaxonomy[0]?.trendDisplayName : "",
        dtDisableNode: selectedTaxonomy[0]?.trendIsDisabled ? selectedTaxonomy[0]?.trendIsDisabled : "NO",
        dtHideNode: selectedTaxonomy[0]?.trendIsHidden ? selectedTaxonomy[0]?.trendIsHidden : "NO",
        dtSignalNode: selectedTaxonomy[0]?.trendIsSignal ? selectedTaxonomy[0]?.trendIsSignal : "NO",
      });
    }
  }, [selectedTaxonomy]);

  return (
    <div>
      <SidebarComponent
        id="default-sidebar"
        ref={sidebarRef}
        width={150}
        style={{ visibility: "hidden" }}
        created={created}
        type={"Over"}
        isOpen={show}
        position="Right"
        animate={false}
        enableGestures={false}
      >
        <div className="col-12 p-0 bg-white">
          <div className="row m-0">
            <div className="roundedTop pl-4 pr-4 " style={{ height: "56px" }}>
              <span className="font-weight-bold" style={{ paddingTop: "2.2vh" }}>
                {showAddEdit ? `${selectedTaxonomy ? "Edit" : "Add New"} Node` : "Instructions"}
                {showInstruction && (
                  <span
                    className="closeIcon"
                    onClick={() => {
                      toggleClick();
                    }}
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </div>
            <hr style={{ borderBottom: "1px solid #DBDBDD", width: "100%" }} />
            {showInstruction && (
              <ol
                className="pl-4 pt-2 pr-2 ml-4 mr-4 mt-2 panel-taxonomy"
                style={{ lineHeight: "1.5rem", listStyleType: "decimal-leading-zero", fontSize: "14px" }}
              >
                <li>
                  To create a new parent node, select a Trend and click "Add". Provide 'Node Name (Mandatory)' and 'Node Display Name
                  (Non-mandatory)', then click on "Save".
                </li>
                <li>
                  To create a new child node, click on the checkbox of the parent node and then click on "Add". Then provide the 'Node Name
                  (Mandatory)' and 'Node Display Name (Non-mandatory)' and click on "Save".
                </li>
                <li>Node name can only contain letters A-Z (uppercase and lowercase allowed) except $,. and numbers.</li>
                <li>
                  To edit a node, click on the checkbox of the node. "Selected parent node" and "Selected node" will be displayed. Rename/update the
                  name of the selected node and click "Update".{" "}
                </li>
                <li>
                  To delete a node, click on the checkbox of the node and click "Delete". A confirmatory pop-up would be displayed; select 'Yes' to
                  confirm the deletion.
                </li>
                <li>To display all nodes, click on "Expand All".</li>
                <li>To collapse all child nodes and display only level -1: Parent nodes, click on "Collapse All."</li>
                <li>To search a node in the taxonomy structure using a keyword, use the search bar.</li>
                <li>To sort alphabetically, select "Ascending" for Ascending order and "Descending" for Descending order.</li>
                <li>To manually change the order, click on Custom order. Drag and drop a node within the same level to change the order.</li>
                <li>
                  Any changes on the trend map will reflect on the main website immediately and will impact on the associated data (e.g. Company,
                  Products, News, Videos, Deliverables etc). Please be careful while doing any changes on the Trend Map.
                </li>
              </ol>
            )}
          </div>

          {showAddEdit && (
            <>
              {selectedNode ? (
                <div className="col-md-12">
                  {selectedNode[0]?.trendParentName && <label className="pt-3 pb-0">Selected Parent node: {selectedNode[0]?.trendParentName}</label>}
                  {selectedNode[0]?.label && <label className="pt-3">Selected node: {selectedNode[0]?.label}</label>}
                </div>
              ) : null}
              <div className="col-md-12 pt-3">
                <TextField
                  formik={formik}
                  type="text"
                  id="dtName"
                  enabled={true}
                  value={formik.values.dtName}
                  placeholder="Node Name*"
                  name="dtName"
                  required="required"
                />
                <TextField
                  formik={formik}
                  type="text"
                  id="dtDisplayName"
                  enabled={true}
                  value={formik.values.dtDisplayName}
                  placeholder="Node Display Name"
                  name="dtDisplayName"
                  required="required"
                />
                <ToggleSwitchNew
                  formik={formik}
                  properties={{
                    id: "dtDisableNode",
                    name: "dtDisableNode",
                    label: "Disable Node",
                  }}
                />
                <ToggleSwitchNew
                  formik={formik}
                  properties={{
                    id: "dtHideNode",
                    name: "dtHideNode",
                    label: "Hide Node",
                  }}
                />
                <ToggleSwitchNew
                  formik={formik}
                  properties={{
                    id: "dtSignalNode",
                    name: "dtSignalNode",
                    label: "Signal Node",
                  }}
                />
              </div>
              <hr className="bottomHr" />
              <div className="pt-3 bottomAction">
                <button
                  type="button"
                  className={`btn btn-outline-dark  mr-3 ${btnDisabled ? " disabled " : " "}`}
                  onClick={() => {
                    toggleClick();
                    setShowInstruction(true);
                  }}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" className={`btn btn-primary ${btnDisabled ? " disabled " : " "}`} onClick={handleSubmitDefault}>{`${
                  selectedTaxonomy ? "Update" : "Save"
                }`}</button>
              </div>
            </>
          )}
        </div>
      </SidebarComponent>
    </div>
  );
};
export default SideBar;
