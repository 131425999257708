import "./index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError, actionStart, showMessage } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import {
  fetchObjectiveTaxonomy,
  saveTaxonomy,
  updateTaxonomy,
  deleteTaxonomy,
  updateTaxonomiesObjective,
  fetchSingleObjective,
  updateObjective,
} from "../../../../../../middleware/services/businessObjectiveApi";
import { useLocation } from "react-router";
import { ReactComponent as Ascending } from "../../../../../../assets/images/ascending.svg";
import { ReactComponent as Descending } from "../../../../../../assets/images/descending.svg";
import { ReactComponent as Custom } from "../../../../../../assets/images/custom.svg";
import SideBar from "./Sidebar";
import SortableTree from "../../../../../components/treeView/sortableTree";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const [taxonomy, setTaxonomy] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [reRender, setReRender] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  let objectiveId = "5ebaa59d93011536c7b3062a";
  const location = useLocation();
  if (location.pathname.includes("edit")) {
    objectiveId = location.pathname.substring(location.pathname.length - 29, location.pathname.length - 5);
  }
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [treeSorting, setTreeSorting] = useState("");
  const [allowDragAndDrop, setAllowDragAndDrop] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const saveUpdatedTaxonomy = async (data) => {
    let result = [];
    data.forEach((item) => {
      let resultObj = {
        id: item.id,
        taxonomy_sort_order: item.taxonomySortOrder,
      };
      result.push(resultObj);
    });
    let params = {
      filters: [],
    };
    let payload = {
      taxonomies: result,
    };
    dispatch(showLoader());
    await updateTaxonomiesObjective(params, payload)
      .then((res) => {
        setChangeOrder(false);
        if (treeSorting != "CUSTOM") fetchTaxonomy();
        dispatch(actionSuccess("updated successfully"));

        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong!"));
      });
  };
  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      element["showCheckbox"] = false;
      element["taxonomySortOrder"] = element.taxonomySortOrder;
      element["level"] = element.taxonomyLevel + 2;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  /**
   * @description fetch taxonomy technology by selected dtRoot
   * @param {String} dtRoot
   */
  const fetchTaxonomy = () => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    fetchObjectiveTaxonomy(objectiveId, {
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
      sort: "taxonomySortOrder:asc",
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        const taxonomyApplications = data; //.find(d => d.taxonomyName === "Applications");
        data = data.filter((d) => !(d.taxonomyParentId === taxonomyApplications.id || d.id === taxonomyApplications.id));
        if (data.length) {
          const treeJosnArray = buildTreeJson(data);
          setTaxonomy(treeJosnArray);
          setReRender(true);
          setTreeCollapse(true);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };

  const fetchSortSeq = () => {
    dispatch(showLoader());
    const response = fetchSingleObjective(objectiveId, {
      fields: ["objectiveTaxonomySortSeq"],
    });
    response
      .then((response) => {
        if (response.status === 200) {
          response.data && setTreeSorting(response.data.data?.objectiveTaxonomySortSeq);
        }
      })
      .catch((e) => {
        dispatch(actionError("Something went wrong..!"));
      });
  };

  /**
   * @description function to open add new technology modal
   */
  const openTechnologyAddModal = () => {
    if (selectedTaxonomy == undefined || selectedTaxonomy?.length < 2) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setShowAdd(true);
      setEdit(false);
    } else dispatch(showMessage("Please select single node...!"));
  };

  /**
   * @description function to open edit technology modal
   */
  const openTechnologyEditModal = () => {
    if (selectedTaxonomy?.length == 1) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setEdit(true);
      setShowAdd(false);
    } else dispatch(showMessage("Please select single node...!"));
  };

  /**
   * @description tree node click handler
   * @param {String} dV
   */
  const handleClick = (dV) => {
    if (dV.length > 0) {
      setSelectedTaxonomy(dV);
      setShowAddEdit(true);
      setShowAdd(false);
      setEdit(false);
    } else {
      setSelectedTaxonomy(undefined);
      setShowAddEdit(false);
      setShowInstruction(true);
    }
  };

  /**
   * @description save new technology
   * @param {String} dtName
   * @param {String} dtDisplayname
   */
  const handleSubmitSaveTaxonomy = (taxonomyName, taxonomyDisplayname) => {
    if (taxonomyName && taxonomyName !== "") {
      let payload = {
        taxonomyName: taxonomyName,
        sortSeq: treeSorting,
      };
      if (selectedTaxonomy?.length) payload["taxonomyParentId"] = selectedTaxonomy[0]?.value;
      if (taxonomyDisplayname) payload["taxonomyDisplayName"] = taxonomyDisplayname;
      dispatch(actionStart());
      saveTaxonomy(objectiveId, payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy added successfully"));
          fetchTaxonomy();
          setShow(true);
          setReRender(true);
          setShowAddEdit(false);
          setShowAdd(false);
          setEdit(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Delete trend failed";
          if (err?.data?.statusCode == 451) {
            let string1 = errMsg.split("-");
            dispatch(actionError(`Following nodes can not be deleted, nodes are tagged in ${string1[0]} - ${string1[1]}`));
          } else {
            dispatch(actionError(errMsg));
          }
        });
    }
  };

  /**
   * @description update existing technology
   * @param {String} taxonomyName
   * @param {String} taxonomyDisplayName
   */
  const handleSubmitUpdateTaxonomy = (taxonomyName = undefined, taxonomyDisplayName = undefined) => {
    if (taxonomyName && taxonomyName !== "") {
      dispatch(actionStart());
      let payload = {};
      if (taxonomyName && taxonomyName !== "") payload.taxonomyName = taxonomyName;
      if (taxonomyDisplayName && taxonomyDisplayName !== "") payload.taxonomyDisplayName = taxonomyDisplayName;
      payload.sortSeq = treeSorting;
      updateTaxonomy(objectiveId, selectedTaxonomy[0].value, payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy updated successfully"));
          fetchTaxonomy();
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          err?.data?.message ? dispatch(actionError(`${err?.data?.message}`)) : dispatch(actionError("Update taxonomy failed"));
        });
    }
  };

  const handleSequenceTaxonomy = (payload) => {
    dispatch(showLoader());
    updateObjective(objectiveId, payload)
      .then((res) => {
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(actionError("something went wrorn"));
      });
  };
  /**
   * @description delete technology
   */
  const deleteObjectiveTaxonomy = () => {
    process.nextTick(() => {
      dispatch(actionStart());
      let taxonomyIds = selectedTaxonomy.map((v) => {
        return v.id;
      });
      deleteTaxonomy(objectiveId, taxonomyIds)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy deleted successfully"));
          fetchTaxonomy();
          setReRender(true);
        })
        .catch((err) => {
          dispatch(actionError("Delete taxonomy failed"));
        });
    });
  };

  /**
   * @description function to open delete technology alert box
   */
  const deleteTechnology = () => {
    if (selectedTaxonomy) {
      if (selectedTaxonomy?.treeDepth === 0) {
        dispatch(actionError("Can not delete parent node"));
        return true;
      }
      setReRender(false);
      dispatch(
        showAlertBox({
          okCallback: deleteObjectiveTaxonomy,
          okText: "Delete",
          cancelText: "Cancel",
          content: "Are you sure you want to delete?",
          title: "dialogAlertCssWarning",
        })
      );
    } else dispatch(actionError("Select Taxonomy"));
  };
  function handleSorting(sortObject) {
    handleSequenceTaxonomy({ objectiveTaxonomySortSeq: sortObject.value });
    if (sortObject.value === "CUSTOM") {
      setAllowDragAndDrop(true);
    }
  }

  useEffect(() => {
    fetchSortSeq();
    fetchTaxonomy();
  }, []);

  return (
    <div className="pt-3 pb-0 mr-3 ml-2 " style={{ overflowY: "hidden" }}>
      <div className="col-12 bg-white p-0">
        <div className="row taxonomyHeader">
          {treeSorting ? (
            <div
              className="pt-2"
              style={{
                width: "fitContent",
                display: "inline-block",
                marginLeft: "28px",
              }}
            >
              <div className="pt-2">
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    setTreeSorting("ASC");
                    handleSorting({ id: 1, value: "ASC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "ASC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Ascending fill={treeSorting == "ASC" ? "#F94F5E" : "#939399"} />
                  Ascending
                </span>
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    setTreeSorting("DESC");
                    handleSorting({ id: 2, value: "DESC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "DESC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Descending fill={treeSorting == "DESC" ? "#F94F5E" : "#939399"} />
                  Descending
                </span>
                <span
                  className="ml-2 ordering"
                  style={treeSorting == "CUSTOM" ? { color: "#F94F5E" } : { color: "black" }}
                  onClick={() => {
                    setTreeSorting("CUSTOM");
                    handleSorting({ id: 3, value: "CUSTOM" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(true);
                  }}
                >
                  <Custom fill={treeSorting == "CUSTOM" ? "#F94F5E" : "#939399"} />
                  Custom Order
                </span>
              </div>
            </div>
          ) : null}
          <div className="pr-0 pl-0 ml-auto mr-2" style={{ maxWidth: "fitContent" }}>
            <div className="mata-content p-0" style={{ height: "50px", background: "#FAFAFA" }}>
              <ul>
                {accessActionAdd && (
                  <li className="cursor-pointer">
                    <span className="btn btn-primary pr-3 pt-1 pb-1" onClick={() => openTechnologyAddModal()}>
                      Add
                    </span>
                  </li>
                )}
                {accessActionEdit && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "taxonomyCustomDisable disabled"}`}
                      onClick={() => openTechnologyEditModal()}
                    >
                      Edit
                    </span>
                  </li>
                )}
                {accessActionDelete && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "disabled taxonomyCustomDisable"}`}
                      onClick={() => deleteTechnology()}
                    >
                      Delete
                    </span>
                  </li>
                )}
                {treeCollapse ? (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(false)}>
                      Expand All
                    </span>
                  </li>
                ) : (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(true)}>
                      Collapse All
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 bg-white">
          <div className="p-0 pt-3 objectiveTreeScroll">
            {taxonomy.length > 0 ? (
              <div>
                <SortableTree
                  treeData={taxonomy}
                  onClick={handleClick}
                  saveUpdatedTaxonomy={saveUpdatedTaxonomy}
                  treeCollapse={treeCollapse}
                  sort={treeSorting}
                  allowDragAndDrop={allowDragAndDrop}
                  setTaxonomy={setTaxonomy}
                  selectedMedia={selectedTaxonomy}
                  reRender={reRender}
                  setReRender={setReRender}
                  changeOrder={changeOrder}
                  parentId={"taxonomyParentId"}
                  hieghtClass={"objective-taxonomy-tree-container-wrapper"}
                  className="objective-taxonomy-tree-container-wrapper"
                />
              </div>
            ) : null}
          </div>
          <div className="ml-auto">
            <div className="taxonomySidebar">
              <span
                className={`${showInstruction ? "invertedText-Color" : "invertedText"}`}
                onClick={() => {
                  setToggle(true);
                  setShow(true);
                  setShowInstruction(true);
                  setShowAddEdit(false);
                }}
              >
                {" "}
                Instructions
              </span>
              {showAddEdit && (
                <span
                  className={`${showAddEdit ? "invertedText-Color" : "invertedText"}`}
                  onClick={() => {
                    setToggle(true);
                    setShow(true);
                    setShowAddEdit(true);
                    setShowInstruction(false);
                  }}
                >
                  {" "}
                  Options
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {showAdd && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          handleSubmit={handleSubmitSaveTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}

      {edit && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          selectedTaxonomy={selectedTaxonomy}
          handleSubmit={handleSubmitUpdateTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}

      {showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showInstruction={showInstruction}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}
    </div>
  );
};

export default TaxomonyUI;
