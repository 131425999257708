/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company form fields with validations 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 25/Jun/2020 
    Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect, useCallback } from "react";
import { TextBoxComponent, MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { RadioButtonComponent, SwitchComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { MultiSelectComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Select from "react-select";
import { debounce } from "lodash";
import { fetchCountryForOutSourcing } from "../../../middleware/services/cmsApi";
import { fetchCompany } from "../../../middleware/services/companyApi";
import { addressType } from "constants";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CreatableSelect from "react-select/creatable";
import { getProductVariant } from "../../../utilities";
// import style1 from "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
// import style1 from "../../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
import "./outsourcing.scss";

import style2 from "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
const RadioButton = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      {/* <div> */}
      <SwitchComponent
        // className="e-customCss"
        autoComplete="off"
        {...props.formik.getFieldProps(...props.name)}
        value={value}
        {...props}
        // enablePersistence={true}
        // showClearButton={true}
        // cssClass={'e-customCss'}
        change={(e) => {
          // console.log(props.name,"PPPPP",e);
          props.formik.setFieldValue(props.name, e.checked ? "yes" : "no");
        }}
        // floatLabelType="Auto"
        cssClass="handle-text ml-4"
      />
      {/* </div> */}
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </>
  );
};

export const InputGroupText = (props) => {
  const [value, setValue] = useState("");

  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);
  // console.log(props.name, "UUUU", props.formik.touched[props.name],props.formik.errors[props.name]);
  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""}`} style={{ marginBottom: "0px" }}>
        <div className="e-float-input e-input-group" style={{ top: "-15px", borderBottom: 0 }}>
          <span
            className="e-input-group-text p-2"
            style={{
              border: "1px solid rgb(0 0 2 / 24%)",
              borderRadius: "4px",
              marginRight: "5px",
              width: props.inputGroupTextWidth,
              height: "40px",
              fontSize: "14px",
            }}
          >
            {props.inputGroupText}
          </span>
          {/* <input required type="text" /> */}
          <TextBoxComponent
            // className="form-control"
            {...props.formik.getFieldProps(...props.name)}
            autoComplete="off"
            value={value}
            {...props}
            // enablePersistence={true}
            showClearButton={true}
            cssClass={"customCss e-outline"}
            floatLabelType="Auto"
            input={props.numberCount && onInput}
          />
          {/* <span class="e-float-line"></span> */}
          {/* <label class="e-float-text"> {props.placeholder} </label> */}
        </div>
        <div
          className="text-danger"
          style={{ display: props.formik.touched[props.name] === true ? "block" : "none", left: "8px", top: "-18px", position: "relative" }}
          role="alert"
        >
          <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};

export const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label = "" } = props.properties;
  useEffect(() => {
    // console.log(props.formik.values[name]==="YES", "JJJJJJ",props.formik.values[name], name);
    setChecked(props.formik.values[name] === "yes" ? true : false);
  }, [name, props.formik.values]);

  return (
    <>
      <div className={`form-group ${(props.required && props.required) || ""} row`}>
        <label className="col-md-7 mt-0" style={{ fontSize: "12px" }}>
          {label}{" "}
        </label>
        <div className="col-md-5">
          <label style={{ right: "25px", top: "3px" }} className="custom-switch3">
            <input
              type="checkbox"
              {...props.properties}
              checked={checked ? true : false}
              onChange={(e) => {
                setChecked(e.target.checked);
                props.formik.setFieldValue(name, e.target.checked ? "yes" : "no", true);
              }}
            />
            <div>
              <span className="on">Yes</span>
              <span className="off">No</span>
            </div>
            <i></i>
          </label>
        </div>

        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      </div>
    </>
  );
};
const MultiSelect = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    // setValue(props.formik.values[name]);
  }, [
    // props.formik.values,
    props.name,
  ]);

  return (
    <>
      <MultiSelectComponent
        // className="form-control e-customCss"
        autoComplete="off"
        // {...props.formik.getFieldProps(...props.name)}
        value={value}
        // dataSource={...props.options}
        {...props}
        // enablePersistence={true}
        // showClearButton={true}
        // cssClass={'e-outline'} floatLabelType="Auto"
        change={(e) => {
          // console.log("ÄAAAA",e);
          props.formik.setFieldValue(props.name, e.value);
          setValue(e.value);
          if (e.value) {
            // console.log(props.formik);
            props.formik.setFieldError(props.name, "");
          }
        }}
        cssClass={"customCss e-outline"}
        floatLabelType="Auto"
      />
      {/* <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
            <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
        </div> */}
    </>
  );
};
const TextField = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);
  // console.log(props.name, "UUUU", props.formik.touched[props.name],props.formik.errors[props.name]);
  return (
    <>
      <TextBoxComponent
        // className="form-control"
        {...props.formik.getFieldProps(...props.name)}
        autoComplete="off"
        value={value}
        {...props}
        // enablePersistence={true}
        showClearButton={true}
        cssClass={"customCss e-outline"}
        floatLabelType="Auto"
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </>
  );
};

const TextArea = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <textarea className="form-control" autoComplete="off" {...props.formik.getFieldProps(...props.name)} value={value} {...props} />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </>
  );
};

const Dropdown = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;
    // console.log("AAAAA",props.formik.values[name]);
    // setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      {
        <DropDownListComponent
          // {...props.formik.getFieldProps(...props.name)}
          value={value}
          {...props}
          change={(selectedOption) => {
            props.formik.setFieldValue(props.name, selectedOption.value);
          }}
          cssClass={"e-outline"}
          floatLabelType="Auto"
          // popupHeight="250px"
          // isClearable
        />
      }
      <div
        className="text-danger"
        style={{
          display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
        }}
        role="alert"
      >
        <small>
          {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
        </small>
      </div>
    </>
  );
};
const CountrySelect = (props) => {
  const [country, setCountry] = useState("");
  const [noData, setNoData] = useState("Type atleast 3 characters");
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let name = props.name;
    setCountry(props.formik.values[name]);
  }, [props.formik.values, props.name]);
  // debounce search
  const searchCountry = useCallback(
    debounce(async (value) => {
      let filters = { filters: [["countryName", "cn", value]] };
      let fields = { fields: ["countryName", "countryIsdCode", "id"] };
      let sort = { sort: { countryName: "asc" } };
      const response =
        (await value.length) > 2 &&
        fetchCountryForOutSourcing({ ...filters, ...fields, ...sort }).then((res) => {
          // console.log("GGGG", res);
          if (res.data && !res.data.totalCount) {
            setNoData("No data available");
          }
          if (res.data && res.data.totalCount) {
            setNoData("");
            const countriesOptions = res.data.data.map((item) => {
              return {
                label: item.countryName,
                value: item.id,
                countryCode: item.countryIsdCode,
              };
            });
            setCountries(countriesOptions);
          }
        });
    }, 300),
    []
  );
  const noDataTemplate = (e) => {
    // console.log(e,"RRRR", noData);
    return <span className="norecord">{noData}</span>;
  };
  return (
    <>
      {countries && (
        <DropDownListComponent
          // {...props.formik.getFieldProps(...props.name)}
          value={country ? { label: country, value: country.label } : null}
          fields={{ text: "label", value: "value" }}
          dataSource={countries}
          allowFiltering={true}
          noRecordsTemplate={noDataTemplate}
          filtering={(inputValue) => {
            inputValue && searchCountry(inputValue.text);
          }}
          change={(selectedOption) => {
            // console.log("PPPP", selectedOption);
            const seletectedCountry = selectedOption ? selectedOption.itemData : "";
            setCountry(seletectedCountry);
            props.formik.setFieldValue("country", seletectedCountry);
          }}
          {...props}
          cssClass={"e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
          // isClearable
        />
      )}
      <div
        className="text-danger"
        style={{
          display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
        }}
        role="alert"
      >
        <small>
          {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
        </small>
      </div>
    </>
  );
};

const FundingTypeSelect = (props) => {
  const [type, setType] = useState("");

  useEffect(() => {
    let name = props.name;
    setType(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <>
      <Select
        {...props.formik.getFieldProps(...props.name)}
        value={type ? { label: type, value: type } : null}
        onChange={(selectedOption) => {
          const selectedType = selectedOption ? selectedOption.label : "";
          setType(selectedType);
          props.formik.setFieldValue("fundingTypeRound", selectedType);
        }}
        {...props}
        isClearable
      />
      <div
        className="text-danger"
        style={{ display: (props.formik.touched["react-select-5-input"] || props.formik.touched[props.name]) === true ? "block" : "none" }}
        role="alert"
      >
        <small>
          {(props.formik.touched["react-select-5-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
        </small>
      </div>
    </>
  );
};

const DatePicker = (props) => {
  const [value, setValue] = useState("");
  // console.log(props.formik)
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);
  return (
    <>
      <DatePickerComponent
        className="form-control date-picker"
        openOnFocus={true}
        value={value}
        onChange={(event) => {
          console.log(event.target.value);
          setValue(event.target.value);
        }}
        {...props.formik.getFieldProps(...props.name)}
        {...props}
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </>
  );
};

const FundingInvestors = (props) => {
  const [value, setValue] = useState([]);
  const [investors, setInvestors] = useState([]);
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  // debounce search
  const searchInvestors = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["companyName", "cn", value]);
        let fields = { fields: ["companyId", "companyName"] };
        const response = await fetchCompany({ filters: filters, ...fields });
        if (response.data && response.data.data) {
          setInvestors(response.data.data);
        }
      }
    }, 300),
    []
  );

  return (
    <>
      <CreatableSelect
        {...props}
        options={investors.map((investor) => {
          return { label: investor.companyName, value: investor.id };
        })}
        value={value}
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        onChange={(selectedOption) => {
          selectedOption ? setValue({ ...value, fundingInvestors: selectedOption }) : setValue([]);
          const selectedType = selectedOption ? selectedOption : [];
          setValue(selectedType);
          props.formik.setFieldValue("fundingInvestors", selectedType);
        }}
        onInputChange={(inputValue) => {
          inputValue && searchInvestors(inputValue);
        }}
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </>
  );
};

export { TextField, TextArea, RadioButton, MultiSelect, CountrySelect, Dropdown, DatePicker, FundingTypeSelect, FundingInvestors };
