/**
 * File Description: Component to render a registration form 
 * Author: Pankaj Kulshreshtha
 * Date: 15/Jun/2021
 */

import React, { useEffect, useState } from "react"
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextField } from "../../components/CustomFormElements/syncFusionFormFields"
import * as Survey from "survey-react";
import * as widgets from 'surveyjs-widgets';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registrationValidationSchema } from "../../helper/validationSchema/userValidationSchema";
import {
	getJsonDataForRegistratonForm, generateOtpForOutSourcing, regenerateOtpForOutSourcing, validateOtpForOutSourcing,
	addOutSourcingUser
} from "../../../middleware/services/cmsApi";
import { showPopup } from "../../../middleware/actions/popupAction";
import { termsAndConditions } from "./termsAndConditionsData";
import { showLoader, hideLoader, actionError } from "../../../middleware/actions/utilityAction";
import "nouislider/distribute/nouislider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "select2/dist/js/select2.js";
import "select2/dist/css/select2.css";

import $ from "jquery";
import "jquery-bar-rating";

const productVariant = "Cheersin";
// theme colors
const mainColor = "#fa4f5f";
const mainHoverColor = "#fa4f5f"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
// const headerBackgroundColor = "#4a4a4a";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray 
widgets.jquerybarrating(Survey, $);
widgets.nouislider(Survey);
widgets.sortablejs(Survey);
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);
let showdown = require('showdown');
const RegistrationFormRender = (props) => {

	const formUniqueName = props.computedMatch?.params?.formUniqueName;
	const invitationId = props.computedMatch?.params?.invitationId || '';

	// const [productVariant, setProductVariant] = useState();
	const [formJson, setFormJson] = useState();
	const [formJsonValues, setFormJsonValues] = useState({});
	const [formData, setFormData] = useState({});
	const [errorMessage, setErrorMessage] = useState();
	const [showMobileOtp, setShowMobileOtp] = useState(false);
	const [showField, setShowField] = useState(false);
	const [alertType, setAlertType] = useState("");
	const [alertMsg, setAlertMsg] = useState("");
	const initialState = {
		showOTP: false,
		userTncAccepected: "",
		userEmailOTP: "",
		userMobileOTP: "",
		showMobileOtp: false
	};
	const formik = useFormik({
		initialValues: { ...initialState },
		validationSchema: Yup.object().shape({
			...registrationValidationSchema
		})
	});


	const dispatch = useDispatch();

	let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
	defaultThemeColorsSurvey["$main-color"] = mainColor;
	defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
	defaultThemeColorsSurvey["$text-color"] = textColor;
	defaultThemeColorsSurvey["$header-color"] = headerColor;
	// defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
	defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
	Survey.StylesManager.applyTheme("default");


	//Create showdown markdown converter
	const converter = new showdown.Converter();
	// function doMarkdown(survey, options) {
	// 	//convert the markdown text to html
	// 	var str = converter.makeHtml(options.text);
	// 	if (str.indexOf("<p>") == 0) {
	// 		//remove root paragraphs<p></p>
	// 		str = str.substring(3);
	// 		str = str.substring(0, str.length - 4);
	// 	}
	// 	//set html
	// 	options.html = str;
	// }
	//End - CKEditor Integration

	// load survey data
	const loadRegistrationForm = () => {
		if (formUniqueName) {
			getJsonDataForRegistratonForm({
				formUniqueName: formUniqueName
			}).then((res) => {
				// setFormJson(formJsonData)
				if (res?.data?.data) {
					if (res?.data?.data?.formJson) {
						// setFormJson(doMarkdown(res.data.data.formJson))
						// window.survey = new Survey.Model(res.data.data.formJson);
						setFormJson(res.data.data.formJson)
						// setSurvey(new Survey.Model(res.data.data.surveyForm))
						setFormData({
							formId: res.data.data.id,
							// productVariant: res.data.data.productVariant[0].productName
							productVariant: productVariant

						});
						if (document.getElementsByClassName('sv_complete_btn')[0])
							document.getElementsByClassName('sv_complete_btn')[0].style.display = 'none'
					}

				} else {
					setErrorMessage("Something went wrong!!!.")
				}
			}).catch((e) => {
				console.error('api error', e);
				setErrorMessage(e.data.message)
			})
		}

	}

	const survey = formJson && new Survey.Model(formJson);
	// Submit survey
	const submitRegistrationForm = (data) => {
		data.clear(false, true);
		setFormJsonValues(data.valuesHash)
		generateOTP(data.valuesHash);
	}
	// Save Survey Data
	const generateOTP = async (surveyResponse) => {
		// return false;
		dispatch(showLoader());
		try {
			const { highestEducation, industryInterest = [], technologyInterest = [], address1 = "", city, country, postCode,
				internetConnectionAvailability, timeAvailability } = surveyResponse;
			const response = { ...surveyResponse };
			delete surveyResponse.industryInterest;
			delete surveyResponse.technologyInterest;
			delete surveyResponse.highestEducation;
			delete surveyResponse.internetConnectionAvailability;
			delete surveyResponse.timeAvailability;
			delete surveyResponse.formId;
			delete surveyResponse.address1;
			delete surveyResponse.city;
			delete surveyResponse.country;
			delete surveyResponse.postCode;
			delete surveyResponse.nationality;
			delete surveyResponse.countryOfResidence;
			delete surveyResponse.defaultTimeZone;

			formik.setFieldValue("userEmail", surveyResponse.userEmail);

			generateOtpForOutSourcing({
				...surveyResponse,
				userExperties: { industry: industryInterest, technology: technologyInterest },
				userQualification: { highestEducation: highestEducation },
				userWorkTimeAvailability: timeAvailability,
				userInternetConnectionAvailability: internetConnectionAvailability,
				userAddress: { addressLine: address1, city: city, country: { value: country }, postCode: postCode },
				userTncAccepected: formik.values.userTncAccepected ? "yes" : "no",
				productVariant: [{ product_name: formData.productVariant }],
				userRole: "freelancer",
				userIsRegister: "YES",
				"userForm": {
					"id": formData.formId,
					"formJsonSubmitted": response
				},
				invitationId: invitationId
			}).then((res) => {
				dispatch(hideLoader());
				let message = res.data?.data?.mobile ?
					"OTP has been sent to your Email & Mobile Number, OTP is valid for 30 minutes." :
					"OTP has been sent to your Email, OTP is valid for 30 minutes.";

				setAlertMsg(message);
				setAlertType("success");
				formik.setFieldValue("showOTP", true);
				setShowField(true)
				if (res.data?.data?.mobile) {
					setShowMobileOtp(true)
					formik.setFieldValue("showMobileOtp", true);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				if (e.data.message) {
					dispatch(actionError(e.data.message));
				} else {
					dispatch(actionError("Something Went Wrong...!"));
				}
			})
		} catch (err) {
			dispatch(hideLoader());
			let errMsg = err?.data?.message ? (err?.data?.message === 'payload: "userEmail" must be a valid email' ? "Please enter a valid email" : err?.data?.message) : "Something went wrong";
			setAlertMsg(errMsg);
			setAlertType("danger");
		}

	}

	// Effect hooks
	useEffect(() => {
		loadRegistrationForm();
	}, [])


	formJson && survey.onTextMarkdown.add(function (survey, options) {
		//convert the mardown text to html
		let str = converter.makeHtml(options.text);
		//remove root paragraphs <p></p>
		str = str.substring(3);
		str = str.substring(0, str.length - 4);
		//set html
		options.html = str;
	});

	const regenerateOtp = async () => {
		let payload = {
			userEmail: formik.values["userEmail"]
		}
		await regenerateOtpForOutSourcing(payload).then(res => {
			let message = res.data?.data?.mobile ?
				"New OTP has been sent to your Email & Mobile Number." :
				"New OTP has been sent to your Email.";
			res.data ? setAlertMsg(message) : setAlertMsg("Something went wrong");
			res.data ? setAlertType("success") : setAlertType("danger");
		}).catch(err => {
			// console.log("err", err);
			// dispatch(actionError(err?.data?.message || "Something went wrong", 8000, "Center", "Bottom"))
			setAlertMsg(err?.data?.message || "Something went wrong");
			setAlertType("danger");
		})
	}

	// validate OTP entered by user
	const validateOTP = async () => {
		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				console.log("error response", res)
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
			} else {
				const { userEmailOTP, userMobileOTP } = formik.values;

				let userEmail = formJsonValues.userEmail;
				const payload = {
					userEmail: userEmail,
					userOtp: {
						email: userEmailOTP,
						mobile: userMobileOTP
					}
				}
				register(payload);

			}
		})
	}

	// validate user form 
	const validateUserRegistrationForm = async () => {
		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				// console.log("error response", res)
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
				if (document.getElementsByClassName('sv_complete_btn')[0]) {
					document.getElementsByClassName('sv_complete_btn')[0].click();
				}
			} else {
				if (document.getElementsByClassName('sv_complete_btn')[0]) {
					document.getElementsByClassName('sv_complete_btn')[0].click();
				}
			}
		})
	}
	const register = async (payload) => {
		dispatch(showLoader());
		const resp = await validateOtpForOutSourcing(payload).then(res => {
			dispatch(hideLoader());
			// Your registration attempt was unsuccessful. Please contact <a href=maitto:support@whatnextglobal.com''>support@whatnextglobal.com</a> for further assistance.
			if (res?.data?.data) {
				if (!(res.data.data?.otpValidationStatus?.email)) {
					formik.setFieldError("userEmailOTP", "Please enter valid OTP");
				}
				if (!(res.data.data?.otpValidationStatus?.mobile)) {
					formik.setFieldError("userMobileOTP", "Please enter valid OTP");
				}
				if (res.data.data?.otpValidationStatus?.mobile && res.data.data?.otpValidationStatus?.email) {
					addUser();
				}
			}
		}).catch(err => {
			dispatch(hideLoader());
			setAlertType("danger");
			if (err?.data?.message === "user blocked") {
				setAlertMsg("Your registration attempt was unsuccessful. <br/>Please contact <a class='btn-link text-danger font-weight-bold' href='mailto:support@whatnextglobal.com'>support@whatnextglobal.com</a> for further assistance.");
			} else {
				setAlertMsg(err?.data?.message || "Something went wrong");
			}
		})
	}


	// First validate the user and than create user in genx
	const addUser = async () => {
		dispatch(showLoader());
		await addOutSourcingUser({ userEmail: formJsonValues.userEmail }).then(res => {
			dispatch(hideLoader());
			if (res?.data) {
				// dispatch(actionSuccess("Your are successfully registered. Please check your email for more details.", 8000, "Center", "Bottom"));
				// setHideDiv(true);
				setAlertMsg("Thank you for registering. You will receive an email with the instructions to continue.");
				// 
				setAlertType("success");
				setShowField(!showField);
				// educationYearRef.current.value=null
				formik.resetForm();
				formik.setValues(initialState);
				setTimeout(() => {
					loadRegistrationForm();
				},
					5000);
				// formik.handleReset(initialState);

			} else {
				// actionError("Registration is failed. Please contact to admin.", 8000, "Center", "Bottom");
				setAlertMsg("Registration is failed. Please contact to admin.");
				setAlertType("danger");
			}

		}).catch(err => {
			dispatch(hideLoader());
			// dispatch(actionError(err?.data?.message || "Registration is failed. Please contact to admin.", 8000, "Center", "Bottom"))
			setAlertMsg(err?.data?.message || "Registration is failed. Please contact to admin.");
			setAlertType("danger");
		})
	}


	// render method
	return (
		<>
			<div className="p-2 container" >
				{formJson ?
					<>
						{/* <div className="col-md-6 p-2 pt-0 mt-0 border-0 bg-transparent rounded-top">Logo</div> */}
						{/* <div className="col-md-12 text-center p-2 border bg-dark text-white rounded-top"><h3>{formData.surveyTitle}</h3></div> */}
						<div className="">
							<div id="content-box" >
								<input type="hidden" name="showOTP" id="showOTP" />
								<div className="container-fluid custom-user-basic-bg mb-3">
									<div className="p-2">
										<div className="row">
											<Survey.Survey json={formJson}
												// model={survey}
												showQuestionNumbers={"off"} data={formData} showCompletedPage={false} onComplete={submitRegistrationForm} />
										</div>
										<div className="row bg-white">
											{/* I accept the terms and conditions */}
											<div className="col-md-12">
												<div className="form-group">
													<CheckBoxComponent
														// type="checkbox"
														cssClass="customCss2"
														id="userTncAccepected"
														name="userTncAccepected"
														checked={formik.values["userTncAccepected"]}
														onChange={() => {
															formik.setFieldError("userTncAccepected", "Please check this field")
															formik.setFieldValue("userTncAccepected", !formik.values["userTncAccepected"]);
														}}
													// label={`I accept the`}
													/> I accept the
													<span style={{
														color: "#000",
														cursor: "pointer",
														marginLeft: "0.25rem",
														fontSize: "14px",
														fontWeight: "bold"
													}}
														onClick={() => {
															dispatch(showPopup(
																{
																	width: `40%`,
																	title: `<h2 class="text-secondary" style="font-weight: 700">Terms & Conditions</h2>`,
																	okText: "OK",
																	showCancelButton: false,
																	content: termsAndConditions
																}
															))
														}}
													> Terms &amp; Conditions</span>

													<div className="text-danger" style={{ display: formik.touched["userTncAccepected"] === true ? "block" : "none" }} role="alert">
														<small>{formik.touched["userTncAccepected"] === true ? formik.errors["userTncAccepected"] || "" : ""}</small>
													</div>
												</div>
											</div>
										</div>
										{showField ?
											<div className="row bg-white">
												<div className="col-md-4">
													<div className="form-group required">
														<TextField
															formik={formik}
															type="text"
															id="userEmailOTP"
															placeholder="Email OTP *"
															name="userEmailOTP"
														/>
													</div>
												</div>
												{showMobileOtp ?
													<div className="col-md-4">
														<div className="form-group required">

															<TextField
																formik={formik}
																type="text"
																id="userMobileOTP"
																placeholder="Mobile OTP *"
																name="userMobileOTP"
															/>
														</div>
													</div>
													: null
												}

												<div className="col-md-4 ">
													<div className="form-group">
														<a href="javascript:void(0)" className="btn-link small"
															onClick={regenerateOtp}
														>Regenerate OTP</a>
													</div>
												</div>
											</div>
											: null
										}
										<div className="row bg-white">
											<div className="col-md-12">
												<div className={`text-${alertType}`} >
													<small dangerouslySetInnerHTML={{ __html: alertType !== "" ? alertMsg && alertMsg : "" }}></small>
												</div>
											</div>
										</div>
										<div className="container-fluid form-group">
											<div className="row pt-4 align-items-center justify-content-center">
												{/* <div className=" col-md-2 text-left">
												<button type="button" className="btn btn-outline-dark "
													onClick={() => {
														formik.resetForm(); 
														formik.setValues(initialState);														
														// multiSelectT.current.value=""
														// educationYearRef.current.value=""
													}}
												>Reset</button>
											</div> */}
												<div>
													{showField ?
														<button type="submit" className="btn btn-dark "
															onClick={validateOTP}
														// disabled={formik.values["userTncAccepected"] ? false : true}
														>
															Register Now
														</button>
														: <button type="submit" className="btn btn-dark "
															onClick={validateUserRegistrationForm}
														// disabled={formik.values["userTncAccepected"] ? false : true}
														>Proceed</button>
													}
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>

						</div>
					</>
					:
					<div className="col-md-12 text-center p-2 border rounded-top"><h3>{errorMessage}</h3></div>
				}
			</div>
		</>
	)
}
export default RegistrationFormRender;