/*
================================================================
  Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description    : Content sidebar Component
----------------------------------------------------------------
  Creation Details
  Date Created				: 22/April/2022
  Author						  : Sarvesh Samant
================================================================
*/
import "./sidebar.css";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { fetchContentBlockRemark } from "../../../middleware/services/contentBlockApi";
import message from "./images/message-solid.svg";
import messageActive from "./images/message-solid-white.svg";
import blockActive from "./images/table-cells-large-solid-white.svg";
import pageActive from "./images/page-solid-white.svg";
import page from "./images/page-solid.svg";
import blocks from "./images/table-cells-large-solid.svg";
import { useDispatch } from "react-redux";
import { actionError } from "../../../middleware/actions/utilityAction";
import { ENTITY_TYPE, PRODUCT_VARIANT } from "../../../constants";
import { getLoggedInUser, getProductVariant } from "../../../utilities";

const ContentSideBar = (props) => {
  const [remarkLength, setRemarkLength] = useState("");
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const user = getLoggedInUser();
  const toggleBlocks = () => {
    props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK && props.setViewBlocks(!props.viewBlocks);
    const cbHtml = document.querySelector("#_cbhtml");
    if (cbHtml) {
      const snippetHable = document.querySelector(".snippetlist");
      const showSnippets = [...snippetHable.classList].includes("active");
      if (!showSnippets) {
        snippetHable.classList.add("active");
      } else {
        snippetHable.classList.remove("active");
      }
    }
  };

  /*
    Description : Fetch Remark
    params: remarkEntityId (deliverable or contentBlock Id)
  */
  const loadAllRemarks = (remarkEntityId) => {
    fetchContentBlockRemark(remarkEntityId).then((e) => {
      setRemarkLength(e.data.data.length);
      props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK && props.setRemarkLength(e.data.data.length);
    });
  };
  useEffect(() => {
    loadAllRemarks(props.remarkEntityId);
  }, []);

  /*
    Description : Handle preview function
  */
  const handleSubmit = () => {
    if (props.allPages?.length > 0) {
      let link = `/deliverable/${props.previewDeliverable}/preview`;
      if (productVariant === PRODUCT_VARIANT.INSIDER) {
        link = `${process.env.REACT_APP_INSIDER_V2_URL}preview/${props.previewDeliverable}?userId=${user?._id}`;
      }
      const win = window.open(link, "_blank");
      win.focus();
    } else {
      dispatch(actionError("Please create some page content"));
    }
  };
  useEffect(() => {
    if (props?.isNotification) {
      let blockSelection = window.document.querySelector(`.snippetlist`);
      if (blockSelection) blockSelection.classList.add("notification-blocks");
    }
  }, [props.viewBlocks]);

  /*
    Description : Hide block selection function
  */
  const hideSnippet = () => {
    const cbHtml = document.querySelector("#_cbhtml");
    if (cbHtml) {
      const snippetHable = document.querySelector(".snippetlist");
      if (snippetHable) snippetHable.classList.remove("active");
    }
  };
  return (
    <div>
      <div className="content-block-sidebar" style={{ marginTop: props?.isNotification && "60px" }}>
        <div className="content-block-sidebar-elements">
          <div className="content-block-sidebar-icon-span">
            <div
              className={
                props.viewBlocks || (props.isPage && props.showPageInactive)
                  ? ` ${props.isPage ? "page-btn remark-button-active" : "remark-button remark-button-active"}`
                  : "remark-button"
              }
            >
              {props.isPage ? (
                <img
                  src={props.showPageInactive ? pageActive : page}
                  style={{ paddingLeft: "4px" }}
                  height="26px"
                  width="26px"
                  role="button"
                  data-title="Page Viewer"
                  title="Page Viewer"
                  onClick={() => {
                    props.setHidePageViewer(true);
                    props.showRemark(false);
                    props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK ||
                      (props.remarkEntityType === ENTITY_TYPE.NOTIFICATION && props.setViewRemark(false));
                    props.setShowPageInactive(!props.showPageInactive);
                    props.setShowPage(!props.showPage);
                    hideSnippet();
                    props.setShowSubmitModal(false);
                  }}
                />
              ) : (
                <img
                  src={props.viewBlocks ? blockActive : blocks}
                  height="22px"
                  width="22px"
                  size={24}
                  role="button"
                  data-title="Blocks"
                  title="Blocks"
                  onClick={() => {
                    props.setViewRemark(false);
                    props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK && props.setViewBlocks(!props.viewBlocks);
                    toggleBlocks();
                  }}
                />
              )}
            </div>
            <div>
              <span
                style={{
                  color: !props.viewBlocks || (props.isPage && !props.showPageInactive) ? "#A1A1A1" : "#000000",
                  paddingTop: props.isPage && "5px",
                }}
                className="content-block-sidebar-span"
              >
                {props.isPage ? "Page Viewer" : "Blocks"}
              </span>
            </div>
          </div>
          <div className="content-block-sidebar-icon-span">
            <FaEye
              size={24}
              role="button"
              className="previewcontent-button"
              color="#A1A1A1"
              data-title="Preview"
              title="Preview"
              onClick={() => {
                props.isPage ? handleSubmit() : window["showPreviewWindow"]();
              }}
            />
            <div>
              <span className="content-block-sidebar-span">Preview</span>
            </div>
          </div>

          {props.remarkEntityType && (
            <div className="content-block-sidebar-icon-span">
              <div className={props.viewRemark || props.showReportRemark ? "remark-button remark-button-active" : "remark-button"}>
                {props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK && remarkLength > 0 && !props.viewRemark && (
                  <span className="RemarkSidebarCount">{remarkLength}</span>
                )}
                {props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && props.remarkLength > 0 && !props.showReportRemark && (
                  <span className="RemarkSidebarCount">{props.remarkLength}</span>
                )}

                <img
                  src={props.viewRemark || props.showReportRemark ? messageActive : message}
                  role="button"
                  size={22}
                  title="Remark"
                  onClick={() => {
                    props.remarkEntityType !== ENTITY_TYPE.DELIVERABLE && props.setViewBlocks(false);
                    if (props.remarkEntityType === ENTITY_TYPE.DELIVERABLE) {
                      props.setShowPage(false);
                      props.setShowPageInactive(false);
                      props.showRemark(!props.showReportRemark);
                      props.setHidePageViewer(true);
                    } else {
                      const snippetHable = document.querySelector(".snippetlist");
                      snippetHable.classList.remove("active");
                      props.setViewRemark(!props.viewRemark);
                    }
                    hideSnippet();
                  }}
                />
              </div>

              <div>
                <span
                  className={props.viewRemark || props.showReportRemark ? "content-block-sidebar-span remark-color " : "content-block-sidebar-span"}
                >
                  Remarks
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentSideBar;
