import "../index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { actionSuccess, showLoader, hideLoader, actionError, actionStart, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import {
  fetchTaxonomyTrend,
  saveTaxonomyTrend,
  updateTaxonomyTrend,
  deleteTaxonomyTrend,
  updateTaxonomiesTrend,
  getObjectiveSgfTag,
} from "../../../../middleware/services/cmsApi";
import SortableTree from "../../../components/treeView/sortableTree";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ReactComponent as Ascending } from "../../../../assets/images/ascending.svg";
import { ReactComponent as Descending } from "../../../../assets/images/descending.svg";
import { ReactComponent as Custom } from "../../../../assets/images/custom.svg";
import SideBar from "./SideBar";
import { COMMON_ACCESS_ACTION } from "../../../../constants";
import { accessRightActionCheck } from "../../../../utilities";

const breadCrumbLinks = [];
const TrendUI = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();

  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(undefined);
  const [trends, setTrends] = useState([]);
  const [selectedTrend, setSelectedTrend] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [sgfName, setSgfName] = useState(undefined);
  const [sgfTrendData, setSgfTrendData] = useState([]);
  const treeSort = [
    { id: 1, value: "ASC" },
    { id: 2, value: "DESC" },
    { id: 3, value: "CUSTOM" },
  ];
  const [sort, setSort] = useState("");
  const [treeSorting, setTreeSorting] = useState("");
  const [allowDragAndDrop, setAllowDragAndDrop] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionEdit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);

  const saveUpdatedTaxonomy = async (data) => {
    let result = [];
    data.forEach((item) => {
      let resultObj = {
        id: item.id,
        trend_sort_order: item.taxonomySortOrder,
      };
      result.push(resultObj);
    });
    let params = {
      filters: [],
    };
    params.filters.push(["taxonomyRoot", "eq", selectedTrend]);
    let payload = {
      taxonomies: result,
    };
    setChangeOrder(false);
    dispatch(showLoader());
    await updateTaxonomiesTrend(params, payload)
      .then((res) => {
        if (treeSorting != "CUSTOM") fetchTaxonomy(selectedTrend);
        dispatch(actionSuccess("updated successfully"));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong!"));
      });
  };

  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.trendName;
      element["showCheckbox"] = false;
      element["taxonomySortOrder"] = element.trendSortOrder;
      element["level"] = element.trendLevel;
      if (element?.trendIsHidden === "YES") {
        element["icon"] = "hidden";
      }
      if (element?.trendIsDisabled === "YES") {
        element["icon"] = "disabled";
      }
      if (element?.trendIsDisabled === "YES" && element?.trendIsHidden === "YES") {
        element["icon"] = "hidden-disabled";
      }
      if (element["trendParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  /**
   * @description fetch taxonomy trend industry
   */
  const fetchIndustry = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        const setIndustryTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setIndustryTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, industryName: sTT.trendParentName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
        fetchSgf(Object.values(industryDropdownValues)[0].id);
      })
      .catch((err) => {
        console.log("err fetchTechnology :: ", err);
        let errMsg = err?.data?.message ? err?.data?.message : "Industry Fetch Failed";
        dispatch(actionError(errMsg));
      });
  };

  const fetchSgf = (selectedIndustry) => {
    dispatch(showLoader());
    getObjectiveSgfTag({ filters: [["sector.sectorId", "eq", selectedIndustry]], sort: "trendName:asc", limit: 2000 })
      .then((res) => {
        setSgfTrendData(
          res.data.data.filter((item) => {
            if (item.sgfName !== "Others") return item;
          })
        );
        setSgfName(res.data.data[0].id);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [100]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log("err fetchTrend :: ", err);
      });
  };

  const fetchSortSeq = (trendId) => {
    fetchTaxonomyTrend({ filters: [["_id", "eq", trendId]], sort: "trendName:asc", limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        setTreeSorting(data[0]?.trendSortSeq);
        if (data[0]?.trendSortSeq === "ASC") {
          setSort(1);
        }
        if (data[0]?.trendSortSeq === "DESC") {
          setSort(2);
        }
        if (data[0]?.trendSortSeq === "CUSTOM") {
          setSort(3);
          setAllowDragAndDrop(true);
        }
        dispatch(hideLoader());
      })
      .catch((err) => {
        console.log("err fetchTechnology :: ", err);
        let errMsg = err?.data?.message ? err?.data?.message : "Trends Fetch Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch taxonomy trend by selected trendSubRoot
   * @param {String} trendSubRoot
   */
  const fetchTaxonomy = (trendSubRoot) => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    if (trendSubRoot) {
      fetchTaxonomyTrend({
        fields: [
          "id",
          "trendName",
          "trendParentId",
          "trendParentName",
          "trendSortOrder",
          "trendLevel",
          "trendDisplayName",
          "trendIsHidden",
          "trendIsDisabled",
          "trendIsSignal",
        ],
        filters: [["trendSubRoot", "eq", trendSubRoot]],
        sort: "trendSortOrder:asc",
        limit: 2000,
      })
        .then((res) => {
          dispatch(hideLoader());
          let { data = [] } = res.data;
          if (data.length) {
            const treeJosnArray = buildTreeJson(data, trendSubRoot);
            setTaxonomy(treeJosnArray);
            setTreeCollapse(true);
          }
        })
        .catch((err) => {
          console.log("err fetchTrend :: ", err);
          let errMsg = err?.data?.message ? err?.data?.message : "Trends Data Fetch Failed";
          dispatch(actionError(errMsg));
        });
    }
  };

  /**
   * @description function to open add new trend modal
   */
  const openAddModal = () => {
    if (selectedTaxonomy == undefined || selectedTaxonomy.length < 2) {
      // setSelectedTaxonomy(...selectedTaxonomy)
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setShowAdd(true);
      setEdit(false);
    } else {
      dispatch(showMessage("Please select single node."));
    }
  };

  /**
   * @description function to open edit trend modal
   */
  const openEditModal = () => {
    // if (!selectedTaxonomy) dispatch(actionError("Select Trend"));
    if (selectedTaxonomy?.length == 1) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setEdit(true);
      setShowAdd(false);
    } else {
      dispatch(showMessage("Please select single node."));
    }
  };

  /**
   * @description tree node click handler
   * @param {String} dV
   */
  const handleClick = (dV) => {
    if (dV.length > 0) {
      setSelectedTaxonomy(dV);
      setShowAddEdit(true);
      setShowAdd(false);
      setEdit(false);
    } else {
      setSelectedTaxonomy(undefined);
      setShowAddEdit(false);
      setShowInstruction(true);
    }
  };

  /**
   * @description save new trend
   * @param {String} trendName
   */
  const handleSubmitSaveTaxonomy = (trendName, trendDisplayName, trendIsHidden, trendIsDisabled, trendIsSignal) => {
    if (trendName && trendName !== "") {
      dispatch(actionStart());
      let payload = {
        trendParentId: selectedTaxonomy ? selectedTaxonomy[0].id : selectedTrend || trends[0].id,
        trendName: trendName,
        sortSeq: treeSorting,
        trendIsHidden: trendIsHidden,
        trendIsDisabled: trendIsDisabled,
        trendIsSignal: trendIsSignal,
      };
      if (trendDisplayName) payload["trendDisplayName"] = trendDisplayName;
      saveTaxonomyTrend(payload)
        .then((_res) => {
          dispatch(actionSuccess("Trend added successfully."));
          fetchTaxonomy(selectedTrend || trends[0].id);
          setShow(true);
          setReRender(true);
          setShowAddEdit(false);
          setShowAdd(false);
          setEdit(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Add trend failed";
          dispatch(actionError(errMsg));
        });
    }
  };

  /**
   * @description update existing trend
   * @param {String} trendName
   */
  const handleSubmitUpdateTaxonomy = (
    trendName = undefined,
    trendDisplayName = undefined,
    trendIsHidden = undefined,
    trendIsDisabled = undefined,
    trendIsSignal = undefined
  ) => {
    if (trendName && trendName !== "") {
      dispatch(actionStart());
      let payload = {
        trendName: trendName,
        sortSeq: treeSorting,
        trendIsHidden: trendIsHidden,
        trendIsDisabled: trendIsDisabled,
        trendIsSignal: trendIsSignal,
      };
      if (trendDisplayName) payload["trendDisplayName"] = trendDisplayName;
      updateTaxonomyTrend(selectedTaxonomy[0].value, payload)
        .then((_res) => {
          dispatch(actionSuccess("Trend updated successfully"));
          fetchTaxonomy(selectedTrend || trends[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Update trend failed";
          dispatch(actionError(errMsg));
        });
    }
  };

  /**
   * @description delete trend
   */
  const deleteTaxonomy = () => {
    process.nextTick(() => {
      dispatch(actionStart());
      let taxonomyIds = selectedTaxonomy.map((v) => {
        return v.id;
      });
      deleteTaxonomyTrend(taxonomyIds)
        .then((_res) => {
          dispatch(actionSuccess("Trend deleted successfully"));
          fetchTaxonomy(selectedTrend || trends[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Delete trend failed";
          if (err?.data?.statusCode == 451) {
            let string1 = errMsg.split("-");
            dispatch(actionError(`Following nodes can not be deleted, nodes are tagged in ${string1[0]} - ${string1[1]}`));
          } else {
            dispatch(actionError(errMsg));
          }
        });
    });
  };

  const handleSequenceTaxonomy = (taxonomyId, payload) => {
    dispatch(showLoader());
    updateTaxonomyTrend(taxonomyId, payload)
      .then((res) => {
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(actionError("something went wrorn"));
      });
  };

  /**
   * @description function to open delete trend alert box
   */
  const deleteTrend = () => {
    if (selectedTaxonomy) {
      setReRender(false);
      dispatch(
        showAlertBox({
          okCallback: deleteTaxonomy,
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to delete trend ? ",
          title: "dialogAlertCssWarning",
        })
      );
    } else dispatch(actionError("Select Trend"));
  };

  useEffect(() => {
    fetchIndustry();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useEffect(() => {
    if (changeOrder) {
      if (treeSorting === "ASC") {
        setSort(1);
      }
      if (treeSorting === "DESC") {
        setSort(2);
      }
      if (treeSorting === "CUSTOM") {
        setSort(3);
      }
    }
  }, [changeOrder]);

  function handleSorting(sortObject) {
    selectedTrend && handleSequenceTaxonomy(selectedTrend, { trendSortSeq: sortObject.value });
    if (sortObject.value === "CUSTOM") {
      // setAllowDragAndDrop(true)
      setChangeOrder(false);
    }
  }

  useEffect(() => {
    if (selectedTrend) fetchSortSeq(selectedTrend);
  }, [selectedTrend]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <>
      <div className="pt-2 pb-0" style={{ overflow: "hidden" }}>
        <div className="col-12 bg-white">
          <div className="row taxonomyHeaderTrend">
            <div className="pt-2 pr-0 ml-3 pl-0 mixed">
              {industry.length > 0 ? (
                <DropDownListComponent
                  value={selectedIndustry || industry[0].id}
                  change={(value) => {
                    setSelectedIndustry(value.itemData);
                    fetchSgf(value.itemData.id);
                    setSgfTrendData([]);
                    setTrends([]);
                  }}
                  cssClass={"customCss e-outline"}
                  floatLabelType="Auto"
                  popupHeight="155px"
                  dataSource={industry}
                  fields={{ text: "industryName", value: "id" }}
                  placeholder="Select Industry"
                />
              ) : null}
            </div>
            <div className="  mixed pt-2 pr-0 pl-3">
              {sgfTrendData?.length > 0 && (
                <DropDownListComponent
                  value={sgfName}
                  change={(value) => {
                    setSgfName(value?.itemData.id);
                    setTrends(value?.itemData.trend);
                    setSelectedTrend(value?.itemData.trend[0].trendId);
                    fetchTaxonomy(value?.itemData.trend[0].trendId);
                  }}
                  cssClass={"customCss e-outline"}
                  floatLabelType="Auto"
                  popupHeight="150px"
                  dataSource={sgfTrendData}
                  fields={{ text: "sgfName", value: "id" }}
                  placeholder="Select SGF"
                  popupWidth="284px"
                />
              )}
            </div>

            <div className=" mixed pt-2 pl-3 pr-0">
              {trends.length > 0 && selectedTrend && (
                <DropDownListComponent
                  value={selectedTrend || trends[0].trendId}
                  cssClass={"customCss e-outline"}
                  floatLabelType="Auto"
                  popupHeight="250px"
                  dataSource={trends}
                  fields={{ text: "trendName", value: "trendId" }}
                  placeholder="Select Trend"
                  popupWidth="284px"
                  change={(value) => {
                    setSelectedTrend(value?.itemData?.trendId);
                    fetchTaxonomy(value?.itemData?.trendId);
                  }}
                />
              )}
            </div>
            {treeSorting ? (
              <div className="pt-2 pr-0" style={{ width: "fitContent", display: "inline-block" }}>
                <div className="pt-2">
                  <span
                    className="ml-2 ordering cursor-pointer"
                    onClick={() => {
                      setTreeSorting("ASC");
                      handleSorting({ id: 1, value: "ASC" });
                      setChangeOrder(true);
                      setAllowDragAndDrop(false);
                    }}
                    style={treeSorting == "ASC" ? { color: "#F94F5E" } : { color: "black" }}
                  >
                    <Ascending fill={treeSorting == "ASC" ? "#F94F5E" : "#939399"} />
                    Ascending
                  </span>
                  <span
                    className="ml-2 ordering"
                    onClick={() => {
                      setTreeSorting("DESC");
                      handleSorting({ id: 2, value: "DESC" });
                      setChangeOrder(true);
                      setAllowDragAndDrop(false);
                    }}
                    style={treeSorting == "DESC" ? { color: "#F94F5E" } : { color: "black" }}
                  >
                    <Descending fill={treeSorting == "DESC" ? "#F94F5E" : "#939399"} />
                    Descending
                  </span>
                  <span
                    className="ml-2 ordering"
                    style={treeSorting == "CUSTOM" ? { color: "#F94F5E" } : { color: "black" }}
                    onClick={() => {
                      setTreeSorting("CUSTOM");
                      handleSorting({ id: 3, value: "CUSTOM" });
                      setChangeOrder(true);
                      setAllowDragAndDrop(true);
                    }}
                  >
                    <Custom fill={treeSorting == "CUSTOM" ? "#F94F5E" : "#939399"} />
                    Custom Order
                  </span>
                </div>
              </div>
            ) : null}

            <div className="pr-0 pl-0 ml-auto mr-2" style={{ maxWidth: "fitContent" }}>
              <div className="mata-content p-0" style={{ height: "50px", background: "#FAFAFA" }}>
                <ul>
                  {accessActionAdd && (
                    <li className="cursor-pointer">
                      <span className="btn btn-primary px-3 pr-3 pt-1 pb-1" onClick={() => openAddModal()}>
                        Add
                      </span>
                    </li>
                  )}
                  {accessActionEdit && (
                    <li className="cursor-pointer nopointer">
                      <span
                        className={`btn px-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "taxonomyCustomDisable disabled"}`}
                        onClick={() => openEditModal()}
                      >
                        Edit
                      </span>
                    </li>
                  )}
                  {accessActionDelete && (
                    <li className="cursor-pointer nopointer">
                      <span
                        className={`btn px-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "disabled taxonomyCustomDisable"}`}
                        onClick={() => deleteTrend()}
                      >
                        Delete
                      </span>
                    </li>
                  )}
                  {treeCollapse ? (
                    <li className="cursor-pointer">
                      <span className="btn  px-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(false)}>
                        Expand All
                      </span>
                    </li>
                  ) : (
                    <li className="cursor-pointer">
                      <span className="btn  px-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(true)}>
                        Collapse All
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row bg-white ml-0 mr-0 ">
          <div className=" p-0 pt-3 treeScroll">
            {taxonomy.length > 0 ? (
              <div>
                <SortableTree
                  treeData={taxonomy}
                  onClick={handleClick}
                  saveUpdatedTaxonomy={saveUpdatedTaxonomy}
                  treeCollapse={treeCollapse}
                  sort={treeSorting}
                  allowDragAndDrop={allowDragAndDrop}
                  // setTaxonomy={setTaxonomy}
                  selectedMedia={selectedTrend}
                  reRender={reRender}
                  setReRender={setReRender}
                  changeOrder={changeOrder}
                  parentId={"trendParentId"}
                  icon={true}
                />
              </div>
            ) : null}
          </div>
          <div className="ml-auto">
            <div className="taxonomySidebar">
              <span
                className={`${showInstruction ? "invertedText-Color" : "invertedText"}`}
                onClick={() => {
                  setToggle(true);
                  setShow(true);
                  setShowInstruction(true);
                  setShowAddEdit(false);
                }}
              >
                {" "}
                Instructions
              </span>
              {showAddEdit && (
                <span
                  className={`${showAddEdit ? "invertedText-Color" : "invertedText"}`}
                  onClick={() => {
                    setToggle(true);
                    setShow(true);
                    setShowAddEdit(true);
                    setShowInstruction(false);
                  }}
                >
                  {" "}
                  Options
                </span>
              )}
            </div>
          </div>
        </div>

        {showAdd && !showInstruction && (
          <SideBar
            toggle={toggle}
            show={show}
            setShow={setShow}
            showAddEdit={showAddEdit}
            handleSubmit={handleSubmitSaveTaxonomy}
            selectedNode={selectedTaxonomy}
            setShowAddEdit={setShowAddEdit}
            setShowInstruction={setShowInstruction}
          />
        )}

        {edit && !showInstruction && (
          <SideBar
            toggle={toggle}
            show={show}
            setShow={setShow}
            showAddEdit={showAddEdit}
            selectedTaxonomy={selectedTaxonomy}
            handleSubmit={handleSubmitUpdateTaxonomy}
            selectedNode={selectedTaxonomy}
            setShowAddEdit={setShowAddEdit}
            setShowInstruction={setShowInstruction}
          />
        )}

        {showInstruction && (
          <SideBar
            toggle={toggle}
            show={show}
            setShow={setShow}
            showInstruction={showInstruction}
            setShowAddEdit={setShowAddEdit}
            setShowInstruction={setShowInstruction}
          />
        )}
      </div>
    </>
  );
};

export default TrendUI;
