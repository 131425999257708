// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : image component
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

import {
  getCompanyImageUrl,
  getNewsImageUrl,
  getDeliverableImageUrl,
  getVideoImageUrl,
  // getObjectiveImageUrl,
  getProductImageUrl,
  getPresentationImageUrl,
} from "../../../middleware/services/imageService";
import { CONTENT_TYPE } from "../../../constants/index";

const ImageFile = ({ fileName, className, entityType, imageStyle, redirectToPage, getVideoStatus, item, source, redirectToDashboard }) => {
  const [img, setImg] = useState();

  // set image as per entity type
  useEffect(() => {
    let isSubscribed = true;

    switch (entityType) {
      case "company":
        if (isSubscribed) setImg(getCompanyImageUrl(fileName));
        break;
      case "news":
        if (isSubscribed) setImg(getNewsImageUrl(fileName));
        break;
      case "document":
        if (isSubscribed) setImg(getDeliverableImageUrl(fileName));
        break;
      case "video":
        if (isSubscribed) setImg(getVideoImageUrl(fileName));
        break;
      case "product":
        if (isSubscribed) setImg(getProductImageUrl(fileName));
        break;
      case "presentation":
        if (isSubscribed) setImg(getPresentationImageUrl(fileName));
        break;
      // case 'objective':
      //   getObjectiveImageUrl(fileName).then((res) => {
      //     if (isSubscribed) setImg(res);
      //   });
      //   break;
      default:
        setImg(fileName);
        break;
    }

    return () => (isSubscribed = false);
  }, [entityType, fileName]);

  // get default image of all entity
  const getDefaultImage = (type) => {
    if (type === "company") {
      return "/images/default_company_image.jpg";
    } else if (type === "document" || type === "key_report" || type === "Key Report") {
      return "/images/default_document_image.jpg";
    } else if (type === "video" || type === "presentation") {
      return "/images/default_video_image.png";
    } else if (type === "product") {
      return "/images/default_product_image.jpg";
    } else if (type === "news" || type === "article") {
      return "/images/default_news_image.jpg";
    } else if (type === "objective") {
      return "/images/default_blank_image.jpg";
    }
  };
  return (
    <div
      className={`globalImageStyle ${
        (item?.deliverableContentType === CONTENT_TYPE.DASHBOARD && item?.deliverableIsComingSoon === "YES") ||
        (source === "Dashboard" && item?.deliverableIsComingSoon === "YES")
          ? "pointer-events-none"
          : ""
      }`}
      onClick={() => {
        redirectToPage(item.id, item.entityType);
      }}
    >
      {img && (
        <Image
          src={img}
          alt="Question"
          onError={(event) => {
            event.target.src = process.env.PUBLIC_URL + getDefaultImage(entityType);
          }}
          // className={cn(``, imageStyle)}
        />
      )}
    </div>
  );
};

export default ImageFile;
