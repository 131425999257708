import React, { useEffect, useState } from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant, fetchTechTrendData } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import { fetchCompany, exportScreeningJsonData, downloadFileUrlScreening } from "../../../../middleware/services/companyApi";
import { actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import ActionPopup from "./actionModal";
import ThreeDotMenu from "../../../components/threeDotMenu";
import _ from "lodash";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

enableRipple(true);
// company dashboard component
const CompanyDataScreeing = (props) => {
  const { accessRights: aR } = props;
  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/data-screening", linkName: aR.moduleName, linkActive: true }];
  const productVariant = getProductVariant();
  const [filterValue, setFilterValue] = useState("SCREENING_FRESH");
  const [relevancy, setRelevancy] = useState("most");
  const [matchCondition, setMatchCondition] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState(undefined);
  const [screeningModalValues, setScreeningModalValues] = useState([]);
  const [screeningDt, setScreeningDt] = useState("");
  const [isUpdated, setIsUpdated] = useState(undefined);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const [gridFilters, setGridFilters] = useState([]);
  const dispatch = useDispatch();

  const accessPermissionSelect = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SELECT);
  const accessPermissionReject = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.REJECT);
  const accessPermissionHold = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.HOLD);
  const accessPermissionImport = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.IMPORT);
  const accessPermissionExport = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EXPORT);
  const accessPermissionScreeningImport = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.IMPORT_FOR_REJECTION);

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const saved = (id) => {
    setIsUpdated(id);
  };
  const setActions = (values, actions) => {
    let dt = productVariant && productVariant === "Insider" ? 1 : 0;
    const { Id, dtId, trendId, allocatedDtId, allocatedTrendId, holdDtId, holdTrendId, rejectedDtId, rejectedTrendId } = values;
    if (actions !== actionType) {
      setTimeout(() => {
        setIsOpen(false);
        setScreeningModalValues({
          companyId: Id,
          dtIds: dtId,
          trendIds: trendId,
          allocatedDtIds: allocatedDtId,
          allocatedTrendIds: allocatedTrendId,
          holdDtIds: holdDtId,
          holdTrendIds: holdTrendId,
          rejectedDtIds: rejectedDtId,
          rejectedTrendIds: rejectedTrendId,
        });
        setScreeningDt(dt);
        setActionType(actions);
        dispatch(showLoader());
        setIsOpen(isOpen ? false : true);
      }, 100);
    }
  };

  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    dtId: "companyInQueueScreening~queueStatus:SCREENING_FRESH,dtId",
    trendId: "companyInQueueScreening~queueStatus:SCREENING_FRESH,trendId",
    allocatedDtId: "companyInQueue~queueStatus:ALLOCATION,dtId",
    allocatedTrendId: "companyInQueue~queueStatus:ALLOCATION,trendId",
    rejectedDtId: "companyInQueueScreening~queueStatus:SCREENING_REJECTED,dtId",
    rejectedTrendId: "companyInQueueScreening~queueStatus:SCREENING_REJECTED,trendId",
    holdDtId: "companyInQueueScreening~queueStatus:SCREENING_HOLD,dtId",
    holdTrendId: "companyInQueueScreening~queueStatus:SCREENING_HOLD,trendId",
    Name: "companyName",
    Website: "companyWebsite",
    "Screening Mode": "companyInQueueScreening.queueStatus",
    Relevancy: "companyInQueueScreening.relevancyStatus",
    "Relevancy Type": "companyPossibleDuplicate.dedupedBy",
    // Technology: "companyInQueueScreening.dtName",
    // Trend: "companyInQueueScreening.trendName",
    Source: "companySource.sourceName",
    "Country (HQ)": "companyLocation~type:Headquarter,countryName",
  };
  if (productVariant && productVariant === "Insider") columnNames["Trend"] = "companyInQueueScreening.trendName";
  else columnNames["Technology"] = "companyInQueueScreening.dtName";

  const valueArr = [
    { id: "SCREENING_FRESH", text: "Fresh" },
    { id: "SCREENING_REJECTED", text: "Rejected" },
    { id: "SCREENING_HOLD", text: "Hold" },
  ];
  const relevancyArr = [
    { id: "all", text: "All" },
    { id: "most", text: "Most Relevant" },
    { id: "less", text: "Less Relevant" },
  ];
  const matchesArr = [
    { id: "all", text: "All" },
    { id: "website_domain", text: "Website Matches Partially" },
    { id: "company_name", text: "Company Name Exact Matches" },
    { id: "website_empty", text: "Website Not Available" },
  ];
  /* 
        @Description : Template Component for action column
    */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessPermissionSelect
          ? {
              Select: () => {
                setActions(value, "select");
              },
            }
          : {})}
        {...(accessPermissionHold && value.filterValue !== "SCREENING_HOLD"
          ? {
              Hold: () => {
                setActions(value, "hold");
              },
            }
          : {})}
        {...(accessPermissionReject && value.filterValue !== "SCREENING_REJECTED"
          ? {
              Reject: () => {
                setActions(value, "reject");
              },
            }
          : {})}
      />
    );
  };

  const companyWebsiteTemplate = (value) => {
    return (
      <a
        href={value.Website ? (value.Website.startsWith("http") ? value.Website : "http://" + value.Website) : null}
        target="_blank"
        data-tip="Open Website"
      >
        {value.Website}
        {value.Website ? <i className="fas fa-external-link-alt pl-2"></i> : null}
      </a>
    );
  };
  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      case "Screening Mode":
        sourceData = valueArr;
        break;
      case "Relevancy":
        sourceData = relevancyArr;
        break;
      case "Relevancy Type":
        sourceData = matchesArr;
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    { field: "id", type: "String", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    { field: "dtId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "trendId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "allocatedDtId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "allocatedTrendId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "rejectedDtId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "rejectedTrendId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "holdDtId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    { field: "holdTrendId", visible: false, type: "String", allowFiltering: false, showInColumnChooser: false },
    {
      field: "Name",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 220,
    },
    {
      field: "Website",
      type: "String",
      template: companyWebsiteTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 250,
    },
    {
      field: `${productVariant && productVariant === "Insider" ? "Trend" : "Technology"}`,
      filter: { operator: "contains" },
      type: "String",
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 230,
    },
    // { field: "Trend", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left" },
    { field: "Source", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    {
      field: "Screening Mode",
      type: "String",
      filter: { operator: "equal" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 160,
    },
    {
      field: "Relevancy",
      type: "String",
      filter: { operator: "equal" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 150,
    },
    {
      field: "Relevancy Type",
      type: "String",
      filter: { operator: "equal" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 220,
    },
    // { field: "Country (HQ)", type: "String", textAlign: "Left", headerTextAlign: "Left" },
  ];

  if (accessPermissionSelect || accessPermissionReject || accessPermissionHold || accessPermissionImport || accessPermissionExport) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      allowFiltering: false,
      allowSorting: false,
      freeze: "Right",
    });
  }

  const fetchComanyBasic = (params = {}) => {
    let defaultFilter = [[productVariant === "Insider" ? "companyInQueueScreening.trendId" : "companyInQueueScreening.dtId", "ex", true]];

    // const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    if (filters) {
      filters = filters.map((item) => {
        if (item[0] === "companyInQueueScreening.queueStatus") {
          const columnName = valueArr.find((value) => value.text === item[2]);
          columnName.id && setFilterValue(columnName.id);
          return [item[0], item[1], columnName.id];
        } else if (item[0] === "companyInQueueScreening.relevancyStatus") {
          const columnName = relevancyArr.find((val) => val.text === item[2]);
          columnName.id && setRelevancy(columnName.id);
          return [item[0], item[1], columnName.id];
        } else if (relevancy === "less" && item[0] === "companyPossibleDuplicate.dedupedBy") {
          const columnName = matchesArr.find((val) => val.text === item[2]);
          columnName.id && setMatchCondition(columnName.id);
          if (item[2] === "Website Not Available") {
            item[0] = "companyWebsite";
            return [item[0], item[1], ""];
          } else {
            return [item[0], item[1], columnName.id];
          }
        } else {
          return item;
        }
      });
    }
    const isExistQueStatus = filters.find((f) => f[0] === "companyInQueueScreening.queueStatus");
    if (!isExistQueStatus) {
      filters = [...filters, ["companyInQueueScreening.queueStatus", "eq", filterValue]];
    }

    const isExistRelevancyStatus = filters.find((f) => f[0] === "companyInQueueScreening.relevancyStatus");
    if (!isExistRelevancyStatus) {
      filters = [...filters, ["companyInQueueScreening.relevancyStatus", "eq", relevancy]];
    }
    params.filters = [...filters, ...defaultFilter];
    params.filters.map((result, key) => {
      result.map((value, resp) => {
        if (value == "Website Not Available") {
          result[resp] = "";
        }
      });
      if ((result[0] == "companyInQueueScreening.relevancyStatus" || result[0] == "companyPossibleDuplicate.dedupedBy") && result[2] == "all") {
        params.filters.splice(key, 1);
      }
    });
    setGridFilters(JSON.stringify(params.filters));
    return fetchCompany({ ...params });
  };

  const onQueryCellInfo = (args) => {
    if (args.column.field === "Action") {
      // if (args.cell.textContent === "In Progress") {
      // 	// args.cell.querySelector(".statustxt").classList.add("e-activecolor");
      // 	args.cell.querySelector(".temp-overview").classList.add("inactive");
      // }
      args.colSpan = 3;
    }
  };
  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };
  // Export Company data
  const exportData = async (flag, ids) => {
    if (flag) {
      const payload = {
        reqIds: JSON.stringify(ids),
        productVariant: productVariant,
        queueStatus: filterValue,
      };
      if (gridFilters) payload.filters = gridFilters;
      dispatch(showLoader());
      await exportScreeningJsonData(payload)
        .then((res) => {
          const link = document.createElement("a");
          link.href = downloadFileUrlScreening(`${res.data.data}`);
          link.download = `${res.data.data}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch(hideLoader());
        })
        .catch((err) => {
          dispatch(actionError("Exporting error: Somthing wrong"));
        });
    }
  };
  const filteredTechTrend = (rD) => {
    let techTrend = rD
      .filter((nD) => {
        if (nD.queueStatus === filterValue) return productVariant === "Insider" ? nD.trendName : nD.dtName;
      })
      .map((nD) => {
        return productVariant === "Insider" ? nD.trendName : nD.dtName;
      });
    return (techTrend.length && techTrend.join(", ")) || "";
  };

  // useEffect(() => {
  //     fetchComanyBasic();
  // }, [formatRowData, filterValue])
  useEffect(() => {
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      if (rD.companySource && rD.companySource.length) {
        const Source = rD.companySource.map((nD) => nD.sourceName);
        newRD.Source = Source.join(", ");
      } else {
        newRD.Source = "";
      }
      if (rD.companyInQueueScreening && rD.companyInQueueScreening.length) {
        let screeningMode;
        valueArr.map((v) => {
          if (filterValue == v.id) {
            screeningMode = v.text;
          }
        });
        newRD["Screening Mode"] = screeningMode;
      } else {
        newRD["Screening Mode"] = "";
      }

      if (rD.companyInQueueScreening && rD.companyInQueueScreening.length) {
        let relevantName;
        relevancyArr.map((val) => {
          if (relevancy == val.id) {
            relevantName = val.text;
          }
        });
        newRD.Relevancy = relevantName;
      } else {
        newRD.Relevancy = "";
      }
      if (rD.companyPossibleDuplicate) {
        let relevantTypeName;
        matchesArr.map((val) => {
          if (matchCondition == val.id) {
            relevantTypeName = val.text;
          }
        });
        newRD["Relevancy Type"] = relevantTypeName;
      } else {
        let relevancyTypeName;
        matchesArr.map((val) => {
          if (matchCondition == val.id) {
            relevancyTypeName = val.text;
          }
        });
        newRD["Relevancy Type"] = relevancyTypeName;
      }
      newRD.Technology = "";
      newRD.Trend = "";
      newRD.dtId = [];
      newRD.trendId = [];
      newRD.rejectedDtId = [];
      newRD.rejectedTrendId = [];
      newRD.holdDtId = [];
      newRD.holdTrendId = [];
      if (rD.companyInQueueScreening && rD.companyInQueueScreening.length) {
        const Source2 = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === filterValue && nD.dtId) return nD.dtId;
        });
        const Source3 = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === filterValue && nD.trendId) return nD.trendId;
        });
        const source2Data = Source2.filter(function (element) {
          return element !== undefined;
        });
        const source3Data = Source3.filter(function (element) {
          return element !== undefined;
        });
        const rejectedDt = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === "SCREENING_REJECTED" && nD.dtId) return nD.dtId;
        });
        const rejectedTrend = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === "SCREENING_REJECTED" && nD.trendId) return nD.trendId;
        });
        const rejectedDtId = rejectedDt.filter(function (element) {
          return element !== undefined;
        });
        const rejectedTrendId = rejectedTrend.filter(function (element) {
          return element !== undefined;
        });

        const holdDt = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === "SCREENING_HOLD" && nD.dtId) return nD.dtId;
        });
        const holdTrend = rD.companyInQueueScreening.map((nD) => {
          if (nD.queueStatus === "SCREENING_HOLD" && nD.trendId) return nD.trendId;
        });
        const holdDtId = holdDt.filter(function (element) {
          return element !== undefined;
        });
        const holdTrendId = holdTrend.filter(function (element) {
          return element !== undefined;
        });
        newRD.dtId = source2Data;
        newRD.trendId = source3Data;
        newRD.rejectedDtId = rejectedDtId;
        newRD.rejectedTrendId = rejectedTrendId;
        newRD.holdDtId = holdDtId;
        newRD.holdTrendId = holdTrendId;
        if (productVariant === "Insider") {
          newRD.Trend = filteredTechTrend(rD.companyInQueueScreening);
        } else {
          newRD.Technology = filteredTechTrend(rD.companyInQueueScreening);
        }
      }
      if (rD.companyInQueue) {
        newRD.allocatedDtId = rD.companyInQueue.dtId ? rD.companyInQueue.dtId : [];
        newRD.allocatedTrendId = rD.companyInQueue.trendId ? rD.companyInQueue.trendId : [];
      }
      newRD.filterValue = filterValue;
      return newRD;
    });
    return formatedRowData;
  };

  return (
    <>
      <div className="gennx-content-wrapper content-wrapper px-3">
        {/* div className="gennx-content-wrapper content-wrapper px-4 mt-4">
			<div className="gennx-grid-container */}

        <div className="gennx-grid-container" id="grid">
          {
            // filterValue !== undefined ?
            <>
              {techTrendData && (
                <CommonDataGrid
                  gridTitle="Company Data Screening Records"
                  fetch={fetchComanyBasic}
                  showLoader={showLoaderGrid}
                  hideLoader={hideLoaderGrid}
                  deleteOne={false}
                  deleteMany={false}
                  columnNames={columnNames}
                  columnFields={columnFields}
                  formatRowData={formatRowData}
                  clearFilterValue={() => {
                    setFilterValue("SCREENING_FRESH");
                    setRelevancy("most");
                    setMatchCondition("all");
                  }}
                  filterValue={[filterValue, relevancy, matchCondition]}
                  deleteId={isUpdated}
                  uploadRight={accessPermissionImport}
                  uploadRightScreeningRejection={accessPermissionScreeningImport}
                  exportScreeningRight={accessPermissionExport}
                  isExport={exportData}
                  getMultiSelectedRows={(data) => {
                    localStorage.setItem("selectedCompaniesScreen", JSON.stringify([...data]));
                  }}
                  getMultiDeSelectedRows={(data) => {
                    localStorage.setItem("selectedCompaniesScreen", JSON.stringify([...data]));
                  }}
                  queryCellInfo={onQueryCellInfo}
                  clearFilterByTaxonomy={true}
                />
              )}
            </>
            // : ""
          }
        </div>
      </div>
      {isOpen && <ActionPopup isOpen={isOpen} saved={saved} screeningModalValues={screeningModalValues} dts={screeningDt} actionType={actionType} />}
    </>
  );
};

export default CompanyDataScreeing;
