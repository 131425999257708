/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Technology List Saga (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 28/Dec/2023
	Author						  : Lawrence Anthony
================================================================
*/

import { all, takeLatest, call, put } from "redux-saga/effects";
import { getIndustriesList, toggleLoading } from "../actions/searchAction";
import http from "../services/http-common";
import { fetchTaxonomyTrend } from "../services/cmsApi";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";

function* fetchIndustrySaga() {
  try {
    // yield put(toggleLoading(true));
    
    let allIndustries = yield call(fetchTaxonomyTrend, {
      fields: ["id", "trendName"],
      filters: [["trendParentId", "eq", 0]],
      limit: process.env.REACT_APP_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    });

    let productIndustryIds = allIndustries.data.data.map(item => item.id);

    yield put(getIndustriesList({ productIndustryIds }));
    // yield put(toggleLoading(false));
  } catch (error) {
    // yield put(toggleLoading(false));

    console.error("Error fetching technology list:", error);
    // Handle errors here if needed
  }
}

const industrySaga = [takeLatest("FETCH_INDUSTRIES_LIST", fetchIndustrySaga)];

export default industrySaga;
