import React, { useState, useEffect } from "react";
import { DropdownList } from "react-widgets";
import CKEditor from "ckeditor4-react";
import { ckEditorConfig } from "../../../config/ckEditorConfig";

const TextField = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <input
        className="form-control"
        autoComplete="off"
        {...props.formik.getFieldProps(...props.properties.name)}
        value={value}
        {...props.properties}
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.properties.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
      </div>
    </>
  );
};
const TextFieldInputGroup = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <input
        className="form-control"
        autoComplete="off"
        {...props.formik.getFieldProps(...props.properties.name)}
        value={value}
        {...props.properties}
      />
    </>
  );
};

const CkEditor = (props) => {
  const [ProductInformation, setFormik] = useState(props.formik.values);
  const [content, setContent] = useState("");
  const onChange = (evt) => {
    // evt.preventDefault()
    const newContent = evt.editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[props.properties.name] = newContent;
    setFormik({ ...ProductInformation, ...changedData });
    props.formik.setFieldValue(props.properties.name, newContent, true);
  };

  useEffect(() => {
    let name = props.properties.name;
    setContent(props.formik.values[name] ? props.formik.values[name] : props.defaultContent ? props.defaultContent : "");
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <CKEditor data={content && content} onChange={onChange} config={{ ...ckEditorConfig }} {...props.properties} />
      <div className="text-danger" style={{ display: props.formik.touched[props.properties.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
      </div>
    </>
  );
};

const Dropdown = (props) => {
  const [value, setValue] = useState("");
  const [ProductInformation, setFormik] = useState(props.formik.values);

  const onChange = (val) => {
    let changedData = {};
    changedData[props.properties.name] = val;
    setFormik({ ...ProductInformation, ...changedData });
    props.formik.setFieldValue(props.properties.name, val, true);
    setValue(props.formik.values[props.properties.name]);
  };

  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <DropdownList
        data={[...props.valueArr]}
        dropUp={true}
        formik={props.formik}
        onChange={onChange}
        {...props.properties}
        value={value ? value : props.properties.value}
      />
    </>
  );
};

const CkEditorVideo = (props) => {
  const [ProductInformation, setFormik] = useState(props.formik.values);
  const [content, setContent] = useState("");
  const [contentLength, setContentLength] = useState(0);

  const onChange = (evt) => {
    // evt.preventDefault()
    const newContent = evt.editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[props.properties.name] = newContent;
    setFormik({ ...ProductInformation, ...changedData });
    props.formik.setFieldValue(props.properties.name, newContent, true);
    setContentLength(newContent.length);
  };

  useEffect(() => {
    let name = props.properties.name;
    setContent(props.formik.values[name] ? props.formik.values[name] : props.defaultContent ? props.defaultContent : "");
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <CKEditor data={content && content} onChange={onChange} config={{ ...ckEditorConfig }} {...props.properties} />
      <span className="float-right">{`${contentLength}/200`}</span>
      <div className="text-danger" style={{ display: props.formik.touched[props.properties.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
      </div>
    </>
  );
};

export { TextField, Dropdown, CkEditor, TextFieldInputGroup, CkEditorVideo };
