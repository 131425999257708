import { CONTENT_BUILDER_API as API } from "../../config/apiConfig";
import { CONTENT_BUILDER_URL } from "../../config";
import { method } from "lodash";
// all content blocks
export const fetchContentBlock = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/content-builder/content-block",
    method: "GET",
    params: params,
  });
};

export const fetchSingleContentBlock = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}`,
    method: "GET",
  });
};
export const updateContentBlock = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}`,
    method: "PUT",
    data: payload,
  });
};
export const saveContentBlock = (payload) => {
  return API({
    url: `/content-builder/content-block`,
    method: "POST",
    data: payload,
  });
};
// Get all remark
export const fetchContentBlockRemark = (remarkEntityId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/content-builder/content-block/${remarkEntityId}/remark`,
    method: "GET",
    params: params,
  });
};
// Get single remark
export function fetchSingleContentBlockRemark(remarkId, params = "") {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/content-builder/content-block/remark/${remarkId}`,
    method: "GET",
    params: params,
  });
}
// Save remark
export const saveRemark = (remarkEntityId, payload) => {
  return API({
    url: `/content-builder/content-block/${remarkEntityId}/remark`,
    method: "POST",
    data: payload,
  });
};
// Edit Remark
export function updateRemark(remarkId, payload) {
  return API({
    url: `/content-builder/content-block/remark/${remarkId}`,
    method: "PUT",
    data: payload,
  });
}

export const deleteContentBlock = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}`,
    method: "DELETE",
  });
};

export const fetchcontentBlockImageUrl = (fileName) => {
  return `${CONTENT_BUILDER_URL}/content-builder/image/${fileName}`;
};

export const updateContentBlockSetting = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/settings`,
    method: "PUT",
    data: payload,
  });
};

export const fetchContentBlockSetting = (contentBlockId, params) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/content-builder/content-block/${contentBlockId}/settings`,
    method: "GET",
    params: params,
  });
};
export const updateStatusSubmit = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/submit`,
    method: "PUT",
    data: payload,
  });
};

export const updateStatusApprove = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/approve`,
    method: "PUT",
    data: payload,
  });
};
export const updateStatusReject = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/reject`,
    method: "PUT",
    data: payload,
  });
};
export const updateStatusSendBack = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/send-back`,
    method: "PUT",
    data: payload,
  });
};

export const updateStatusPublish = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/publish`,
    method: "PUT",
    data: payload,
  });
};

//Add remark reply
export const saveRemarkReply = (remarkId, payload) => {
  return API({
    url: `/content-builder/content-block/remark/${remarkId}/reply`,
    method: "POST",
    data: payload,
  });
};
// Edit Remark
export function updateRemarkReply(remarkId, replyId, payload) {
  return API({
    url: `/content-builder/content-block/remark/${remarkId}/reply/${replyId}`,
    method: "PUT",
    data: payload,
  });
}

// Get Disposition List
export const fetchAllDisposition = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/content-builder/content-block/remark/disposition",
    method: "GET",
    params: params,
  });
};

export const deleteManyContentBlocks = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/content-builder/content-block/all",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

export const fetchContentBlockImageUrl = (fileName) => {
  return `${CONTENT_BUILDER_URL}/content-block/image/${fileName}`;
};

export const fetchContentBlockTaxonomyMedia = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/media`,
    method: "GET",
  });
};
export const fetchContentBlockTaxonomy = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/technology`,
    method: "GET",
  });
};

export const fetchContentBlockValueChain = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/industry-value-chain`,
    method: "GET",
  });
};

export const fetchContentBlockTrend = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/trend`,
    method: "GET",
  });
};

// save Content Block Taxonomy Media
export const saveContentBlockTaxonomyMedia = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/media`,
    method: "POST",
    data: payload,
  });
};
// save Content Block Taxonomy
export const saveContentBlockTaxonomy = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/technology`,
    method: "POST",
    data: payload,
  });
};

// save Content Block Trend
export const saveContentBlockTrend = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/trend`,
    method: "POST",
    data: payload,
  });
};

// delete Content Block Trend
export const deleteContentBlockTrend = (contentBlockId, trendId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/trend/${trendId}`,
    method: "DELETE",
  });
};

// save Content Block Trend
export const saveContentBlockValueChain = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/industry-value-chain`,
    method: "POST",
    data: payload,
  });
};

// delete Content Block Trend
export const deleteContentBlockValueChain = (contentBlockId, trendId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/industry-value-chain/${trendId}`,
    method: "DELETE",
  });
};

export const deleteContentBlockTaxonomy = (contentBlockId, taxonomyId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/technology/${taxonomyId}`,
    method: "DELETE",
  });
};
export const deleteContentBlockTaxonomyMedia = (contentBlockId, taxonomyId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/media/${taxonomyId}`,
    method: "DELETE",
  });
};

// Fetch KIA Tagging
export const fetchContentBlockKia = (contentBlockId) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/kia`,
    method: "GET",
  });
};
// Save KIA tags
export const saveContentBlockKiaTaxonomy = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/kia`,
    method: "POST",
    data: payload,
  });
};
// Delete Kia Tags
export const deleteContentBlockKia = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/kia`,
    method: "DELETE",
    data: payload,
  });
};

export const deleteManyContentBlockTrend = (contentBlockId, taxonomyIds = []) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/trend`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

export const deleteManyContentBlockTechnology = (contentBlockId, taxonomyIds = []) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/technology`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

export const deleteManyContentBlockValueChain = (contentBlockId, payload) => {
  return API({
    url: `/content-builder/content-block/${contentBlockId}/taxonomy/industry-value-chain`,
    method: "DELETE",
    data: payload,
  });
};
// Taxonomy bulk tagging
export const saveContentBlockTaxonomyTags = (payload) => {
  return API({
    url: `/content-builder/content-block/taxonomy/technology`,
    method: "PUT",
    data: payload,
  });
};
export const saveContentBlockKiaTags = (payload) => {
  return API({
    url: `/content-builder/content-block/taxonomy/kia`,
    method: "PUT",
    data: payload,
  });
};
export const saveContentBlockTrendTags = (payload) => {
  return API({
    url: `/content-builder/content-block/taxonomy/trend`,
    method: "PUT",
    data: payload,
  });
};
export const saveContentBlockValueChainTags = (payload) => {
  return API({
    url: `/content-builder/content-block/taxonomy/industry-value-chain`,
    method: "PUT",
    data: payload,
  });
};
export const saveImageToLocal = (payload) => {
  return API({
    url: `/content-builder/content-block/image`,
    method: "POST",
    data: payload,
  });
};

export const imageBase64 = (payload) => {
  return API({
    url: `/content-builder/content-block/image-base64`,
    method: "POST",
    data: payload,
  });
};

export const saveCustomDisposition = (payload) => {
  return API({
    url: `/content-builder/content-block/remark/disposition`,
    method: "POST",
    data: payload,
  });
};

export const deleteCustomDisposition = (dispositionId) => {
  return API({
    url: `/content-builder/content-block/remark/disposition/${dispositionId}`,
    method: "DELETE",
  });
};
