// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Common component of bookmark and info buttons
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :6 Oct 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import InfoIcon from "../InfoIcon/InfoIcon";
import ThreeDotMenu from "../../components/threeDotMenu";
import { PRODUCT_VARIANT } from "../../../constants";
import { getProductVariant, getLoggedInUser } from "../../../utilities";
import classes from "./BookmarkInfoPair.module.css";

const productVariant = getProductVariant();

const handleDeliverablePreview = (deliverableId) => {
  let user = getLoggedInUser();

  let link = `/deliverable/${deliverableId}/preview`;
  if (productVariant === PRODUCT_VARIANT.INSIDER) {
    link = `${process.env.REACT_APP_INSIDER_V2_URL}preview/${deliverableId}?userId=${user?._id}`;
  }
  const win = window.open(link, "_blank");
  win.focus();
};

const handleCompanyPreview = (companyId) => {
  let link = `/company/${companyId}/edit#basic`;
  if (productVariant === PRODUCT_VARIANT.INSIDER) {
    link = `${process.env.REACT_APP_INSIDER_V2_URL}company-profile/${companyId}?focusMode=true&previewMode=true`;
  }
  const win = window.open(link, "_blank");
  win.focus();
};

const handleNewsPreview = (newsId) => {
  let link = `/news/${newsId}/edit#basic`;
  if (productVariant === PRODUCT_VARIANT.INSIDER) {
    link = `${process.env.REACT_APP_INSIDER_V2_URL}news-and-commentary/${newsId}?focusMode=true&previewMode=true`;
  }
  const win = window.open(link, "_blank");
  win.focus();
};

// bookmark and info icon component
const BookmarkInfoPair = (props) => {
  const accessAdministrator = props?.accessAdministrator;
  const user = getLoggedInUser();

  let userId = user._id;

  let deliverableAccessUserIds = [];

  if (props.deliverableAccessUsers) {
    deliverableAccessUserIds = props.deliverableAccessUsers.map((entry) => {
      const userId = entry.split("|")[1].trim();
      return userId;
    });
  }

  return (
    <div className={`${classes.bookmarkInfoPairDiv} pairOuterDiv`}>
      {props.infoText && props.infoText.length !== 0 && <InfoIcon infoText={props.infoText} isSiteSearchInfo={true} />}
      {props.showThreeDot && props.item.entityType === "document" && (accessAdministrator || deliverableAccessUserIds.includes(userId)) && (
        <div style={{ cursor: "pointer" }}>
          <ThreeDotMenu
            isSiteSearchThreeDot={true}
            rowID={props.item.id}
            Edit={() => {
              window.open(`/deliverable/${props.item.id}/edit#basic`, "_blank");
            }}
          />
        </div>
      )}
      {props.showThreeDot && props.item.entityType !== "document" && (
        <div style={{ cursor: "pointer" }}>
          <ThreeDotMenu
            isSiteSearchThreeDot={true}
            rowID={props.item.id}
            Edit={() => {
              if (props.item.entityType === "company") window.open(`/company/${props.item.id}/edit#basic`, "_blank");
              else if (props.item.entityType === "news") window.open(`/news/${props.item.id}/edit#basic`, "_blank");
              else if (props.item.entityType === "video") window.open(`/video/${props.item.id}/edit#basic`, "_blank");
            }}
          />
        </div>
      )}
      {!props.infoText && <div style={{ width: "29px" }}></div>}
    </div>
  );
};

export default BookmarkInfoPair;
