export function areArraysDifferent(arr1, arr2) {
  // Check if arrays have different lengths
  if (arr1.length !== arr2.length) {
    return true;
  }
  // Compare elements at each position
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]?.id !== arr2[i]?.id) {
      return true;
    }
  }
  // Arrays are identical
  return false;
}

export function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of properties is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if the values of corresponding properties are equal
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export const presets = [
  { label: "Today", start: new Date(), end: new Date() },
  {
    label: "Last 7 days",
    start: new Date(new Date(new Date().setDate(new Date().getDate() - 6)).toDateString()),
    end: new Date(new Date().toDateString()),
  },
  {
    label: "Current Month",
    start: new Date(new Date(new Date().setDate(1)).toDateString()),
    end: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
  },
  { label: "Last 30 Days", start: new Date(new Date().setDate(new Date().getDate() - 29)), end: new Date() },
  { label: "Last 90 Days", start: new Date(new Date().setDate(new Date().getDate() - 89)), end: new Date() },
];

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const getData = (data, widgetData, combinedWidgetData) => {
  if (data) return data;

  if (!widgetData?.widgetContent?.length || !combinedWidgetData?.length) return [];
  const ids = widgetData?.widgetContent.map((c) => c.widgetContentId);
  const orderMapping = widgetData.widgetContent.reduce((acc, c) => {
    if (c.widgetContentId != null) {
      acc[c.widgetContentId] = c.widgetContentOrder;
    }
    return acc;
  }, {});
  const rightData = combinedWidgetData.filter((e) => ids.includes(e.id)).sort((a, b) => (orderMapping[a.id] || 0) - (orderMapping[b.id] || 0));

  return rightData;
};

export const getKeyPlyersData = (data, widgetData, combinedWidgetData) => {
  // Check if `data` is truthy, return `data` if true
  if (data?.length) return data;

  // Check if `widgetData` or `combinedWidgetData` are empty or undefined, return empty array if true
  if (!widgetData?.widgetContent?.length || !combinedWidgetData?.length) return [];

  const orderMapping = widgetData.widgetContent.reduce((acc, c) => {
    if (c.widgetContentId != null) {
      acc[c.widgetContentId] = c.widgetContentOrder;
    }
    return acc;
  }, {});

  const rightData = combinedWidgetData
    .map((e) => {
      const matchingWidgetContent = widgetData?.widgetContent.find((w) => w.widgetContentId === e.id);
      return { ...e, widgetContentKeyPlayerCategory: matchingWidgetContent?.widgetContentKeyPlayerCategory };
    })
    .filter((e) => e.widgetContentKeyPlayerCategory)
    .sort((a, b) => (orderMapping[a.id] || 0) - (orderMapping[b.id] || 0));

  // Return the processed `rightData`
  const keyPlayersNewlyAddedData = rightData?.filter((c) => c.widgetContentKeyPlayerCategory === "Newly Added") || [];
  const keyPlayersRecentlyUpdatedData = rightData?.filter((c) => c.widgetContentKeyPlayerCategory === "Recently Updated") || [];
  return [...keyPlayersNewlyAddedData, ...keyPlayersRecentlyUpdatedData];
};

export const getFirstPublishedDate = (ele) => {
  if (!ele?.productVariant?.length) return "";
  const productVariant = ele?.productVariant?.filter((x) => x.productName === "Insider" && x.isLive === "YES");
  return productVariant.length ? productVariant[0].firstPublishedDate : "";
};

export const getUniqueTags = (tags) => {
  if (!tags?.length) return [];
  // Using reduce to accumulate unique tags based on trendId
  const uniqueTagsMap = tags.reduce((acc, cur) => {
    acc[cur.trendId] = cur; // Store each tag object with trendId as key
    return acc;
  }, {});
  // Convert the uniqueTagsMap object back to an array of unique tags
  const uniqueTags = Object.values(uniqueTagsMap);
  return uniqueTags;
};

export const getFilteredAndSortedValues = (dataSource, allowedValues) =>
  dataSource.filter((v) => allowedValues.includes(v.value)).sort((a, b) => a.label.localeCompare(b.label));

export const removeHtmlTags = (htmlString) => htmlString?.replace(/&nbsp;/g, " ")?.replace(/<(?!\/?highlight\b)[^>]*>/g, "");

export const getIndustryTags = (tags) => {
  if (!tags) return;
  const sortOrderExists = tags?.some((obj) => obj.hasOwnProperty("trendSortOrder"));
  if (sortOrderExists) {
    return tags
      .filter((obj) => obj.hasOwnProperty("trendSortOrder"))
      .sort((a, b) => a?.trendSortOrder - b?.trendSortOrder)
      .map((ele) =>
        Object.assign({}, ele, {
          trendId: ele.trendParentId ? ele.trendParentId : ele.trendId,
        })
      );
  } else {
    let filteredTags = tags.filter(
      // (tag) => tag.trendParentId !== 0 && tag.trendRoot === tag.trendParentId
      (tag) => tag.trendParentId !== 0 && tag.trendIsSgf !== 1 && tag.trendSgfParentId === undefined
    );

    if (filteredTags.length === 0) {
      filteredTags = tags.filter((tag) => tag.trendParentId !== 0 && tag.trendIsSgf !== 1);
    }
    return filteredTags?.map((ele) =>
      Object.assign({}, ele, {
        trendId: ele.trendParentId ? ele.trendParentId : ele.trendId,
      })
    );
  }
};
