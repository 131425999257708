/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Industry Use case component nent for Add/Edit
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/June/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { addIndustryApplications, getSingleIndustryApplications, updateIndustryApplications } from "../../../../../middleware/services/kiaApi";
import { fetchTaxonomyIndustry} from "../../../../../middleware/services/cmsApi";
import { IndustryAppValidationSchema } from "../validationSchema";
import { Dropdown,CkEditor } from "../formFields";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TIME_SCALE } from "../formFieldOptions";

const initialState = {
	industryName:"",
	applicationTimescale:"",
	applicationDescription:"",
	applicationCurrentStateOfResearch :"",
	applicationImpact:""
}
const AddIndustryAppModel = (props) => {
	const dispatch = useDispatch();
	const [industryData, setindustryData] = useState([]);
	const [industryApp, setIndustryApp] = useState(null)
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { ...initialState },
		validationSchema: Yup.object().shape(IndustryAppValidationSchema)
	});

	/*
       @Description : Save/Update Industry Application Details
   */
	const addIndustryApplication = () => {
		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
			} else {
				
				let payload = {};
				const { industryName, applicationTimescale, applicationDescription,applicationCurrentStateOfResearch, applicationImpact} = formik.values;
				const selectedIndustry = industryData && industryData.map((f) => {
					return industryName.includes(f.id) && { industry_id: f.id, industryName: f.industryName }
				}).filter(v => { return typeof v === "object" })
				payload.industryId = selectedIndustry[0].industry_id;
				payload.industryName = selectedIndustry[0].industryName;
				payload.applicationTimescale = applicationTimescale;
				payload.applicationDescription = applicationDescription;
				payload.applicationCurrentStateOfResearch = applicationCurrentStateOfResearch;
				payload.applicationImpact = applicationImpact;
     			const formData = new FormData();
				for (let key in payload) {
					formData.append(key, payload[key]);
				}
				if (props.appId) {
					updateIndustryApplications(props.appId,formData).then(res => {
						dispatch(actionSuccess("Industry Applications updated successfully"));
						props.closeModal();
						props.setDeleteId(new Date());
					}).catch(err => {
						dispatch(actionError(err?.data?.message || "Something went wrong"));
					})
				} else {
					addIndustryApplications(props.kiaId,formData).then(res => {
						if (res) {
							dispatch(actionSuccess("Industry Applications  saved successfully"));
							props.closeModal();
							props.setDeleteId(new Date());
						} else {
							dispatch(actionError("Something went wrong"));
						}
					}).catch(err => {
						dispatch(actionError(err?.data?.message || "something went wrong"));
					})
				}
			}
		})

	}
	/*
       @Description : Fetch Single Industry Application Details
   */
	const fetchSingleIndustryApplication=()=>{
		getSingleIndustryApplications(props.appId).then((response) => {
			if (response.status == 200) {
				var industryapp = response.data.data || {};
				setIndustryApp(industryapp);
				setTimeout(() => {
					formik.setValues({
						industryName: industryapp.industryId,
						applicationDescription: industryapp.applicationDescription,
						applicationCurrentStateOfResearch: industryapp.applicationCurrentStateOfResearch,
						applicationImpact:industryapp.applicationImpact,
						applicationTimescale:industryapp.applicationTimescale
					});
				  }, 200);
				
			}
		});
	}
	/*
       @Description : Fetch Industries
   */
   const fetchIndustries =() =>{
		fetchTaxonomyIndustry({ fields: ["industryName","Id"], sort: "industryName:asc", limit: 50 }).then(res => {
			const { data = [] } = res.data;
			setindustryData(data)
		}).catch(err => {
			let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
			dispatch(actionError(errMsg));
		});

   }
	useEffect(() => {
		fetchIndustries();	
		 if (props.appId !== "") {
			dispatch(showLoader());
			!industryApp && fetchSingleIndustryApplication();
			dispatch(hideLoader());
		}
	
   }, [props.showModal,props.appId]);

	return <>
		<Modal
			show={props.showModal}
			size="xl"
			backdrop="static"
			className="modal fade people-mgmt"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<form id="addExp" method="post">
				<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">{props.appId?"Update":"Add"} Industry Applications</h5>
				</div>
				<Modal.Body>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								{industryData && <Dropdown
									formik={formik}
									sortOrder="Acending"
									required="required"
									placeholder="Industry *"
									id="industryName"
									name="industryName"
									fields={{ text: "industryName", value: "id" }}
									dataSource={industryData}
								/>}
							</div>
						</div>
					<div className="col-md-6">
							<div className="form-group">
								<Dropdown
									formik={formik}
									required="required"
									placeholder="Time Scale *"
									id="applicationTimescale"
									name="applicationTimescale"
									fields={{ text: "label", value: "value" }}
									dataSource={TIME_SCALE}
								/>
							</div>
						</div>
					</div>	
					<div className="row">
						<div className="col-md-12">
							<div className="form-group Required">
							<label className="font-weight-bold" htmlFor="applicationDescription">Application Description *</label>
                                <CkEditor
                                        name="applicationDescription"
                                        formik={formik}
                                        id="applicationDescription"
                                        activeClass="editor"
										charCountLimit={2500}
                                />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="font-weight-bold" htmlFor="applicationCurrentStateOfResearch">Current State of Research</label>
                                <CkEditor
                                        name="applicationCurrentStateOfResearch"
                                        formik={formik}
                                        id="applicationCurrentStateOfResearch"
                                        activeClass="editor"
										charCountLimit={2500}
                                />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
							<label className="font-weight-bold" htmlFor="applicationImpact">What Impact will it have?</label>
                                <CkEditor
                                        name="applicationImpact"
                                        formik={formik}
                                        id="applicationImpact"
                                        activeClass="editor"
										charCountLimit={2500}
                                />
							</div>
						</div>
					</div>
					

				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-outline-dark cy-btn mr-3"
						onClick={() => {
							props.closeModal()
						}}
					>
						Cancel
					</button>
					<button type="button" className="btn btn-primary cy-btn"
						onClick={addIndustryApplication}
					>{props.appId?"Update":"Save"}</button>
				</Modal.Footer>
			</form>
		</Modal>
	</>
}
export default AddIndustryAppModel