/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Content Block Add | Edit Content Block
   Summary : Render Utility component is responsible for rendering respective component 
   [e.g Properties | Design | Settings | Taxonomy ]
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 12/April/2022
  Author						  : Amrutesh Devadas 
================================================================ */
import React, { useState, Suspense, useEffect, useRef } from "react";
import Tabs from "../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RenderUtility from "./component/utilities/renderUtility";
import FooterBar from "./component/utilities/footerBar";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import {
  updateStatusApprove,
  updateStatusPublish,
  updateStatusReject,
  updateStatusSubmit,
  fetchSingleContentBlock,
  saveContentBlock,
  updateContentBlock,
  updateContentBlockSetting,
  fetchContentBlockImageUrl,
} from "../../../middleware/services/contentBlockApi";
import { getLoggedInUser, accessRightInterfaceCheck, accessRightActionCheck } from "../../../utilities";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { fetchConfig } from "../../../middleware/services/cmsApi";
import { resetContentBlockRedux, setContentBlockRedux } from "../../../middleware/actions/contentBlockAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const Add = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [viewRemark, setViewRemark] = useState(false);
  const [viewBlocks, setViewBlocks] = useState(false);
  const [categoryBlock, setCategoryBlock] = useState([]);
  const [contentThumbnailImageSrc, setcontentThumbnailImageSrc] = useState(undefined);
  const [save, setSave] = useState(true);
  const [imageError, setImageError] = useState("");
  const [imageDelete, setImageDelete] = useState(false);
  const contentThumbnailDefaultImageRef = useRef(null);
  const [showTrash, setShowTrash] = useState(false);
  const [preservedHtml, preserveContentHtml] = useState("");
  const [imageTypeError, setImageTypeError] = useState("");
  const tabRef = useRef();
  const { accessRights: aR } = props;
  const history = useHistory();
  const [saveContentBlockFlag, setSaveContentBlockFlag] = useState(false);
  const [contentBlockName, setContentBlockName] = useState(props?.location?.state?.contentBlockName || "");
  const [contentBlockId, setContentBlockId] = useState(props?.location?.state?.contentBlockId || "");
  const TABS_NAME = ["Properties", "Design", "Settings", "Taxonomy"];
  const hashArr = {
    "#properties": "Properties",
    "#design": "Design",
    "#settings": "Settings",
    "#taxonomy": "Taxonomy",
  };
  const [contentBlockStatus, setContentBlockStatus] = useState(props?.location?.state?.contentBlockStatus || "");
  const [snippetList, setSnippetList] = useState([]);
  const [contentBlockContentTypeArray, setcontentBlockContentTypeArray] = useState([]);
  const [whatNextDeliverableDataType, setWhatNextDeliverableDataType] = useState([]);
  const [insiderDeliverableDataType, setInsiderDeliverableDataType] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  /* Common access */
  const accessActionTaxonomyAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionTaxonomyAddTags = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionTaxonomyDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionTaxonomyShowInProduct = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);
  const creatorAccess = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const reviewerAccess = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.REJECT);

  /*
    @Description : Define breadcrumb for Add | Edit Content Block
  */
  const breadCrumbLinks = [
    { linkUrl: "/content-block", linkName: aR.moduleName, linkActive: false },

    {
      linkUrl: contentBlockId ? `/content-block/${contentBlockName}/edit#properties` : `/content-block/add#properties`,
      linkName: contentBlockId ? `${contentBlockName}` : "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/content-block/${contentBlockName}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      //properties
      contentBlockContentType: [],
      contentBlockName: "",
      contentBlockVariant: [],
      contentBlockType: [],
      contentBlockThumbnail: "",
      disableFontStyle: "NO",
      disableFontFormatting: "NO",
      disableFontColor: "NO",
      disableExtraSettings: "NO",
    },
    validationSchema: Yup.object().shape({
      contentBlockName: Yup.string()
        .min(2, "Too Short!")
        .max(100, "Too Long!")
        .required("Please enter block name")
        .matches(/^[ A-Za-z0-9_./&-]*$/, "Please enter valid name"),
      contentBlockType: Yup.string().required("Please enter type of block"),
      contentBlockContentType: Yup.string().required("Please enter content type"),
      contentBlockVariant: Yup.string().required("Please enter variant/brand"),
    }),
  });

  /*
    @Description : Tab function
  */
  const created = () => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (contentBlockId !== "") {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.select(0);
    }
  };

  /*
    @Description : Set active link on sidebar for news
  */

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash, contentBlockName, contentBlockId]);

  useEffect(() => {
    dispatch(resetContentBlockRedux());
    props.preserveContentHtml && props.preserveContentHtml("");
  }, []);

  useEffect(() => {
    dispatch(setContentBlockRedux(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (contentBlockId) fetchSingleContentBlockData();
  }, [contentBlockId]);

  const fetchSingleContentBlockData = async () => {
    dispatch(showLoader());
    if (selectedIndex === 0) {
      await fetchSnippetList();
    }
    const response = await fetchSingleContentBlock(contentBlockId);
    setContentBlockStatus(response.data.data.contentBlockStatus);
    setContentBlockName(response.data.data.contentBlockName);
    let typeArray = response.data?.data?.productVariant.map((item) => item.productName);

    if (typeArray.includes("WhatNext", "Insider") && response.data?.data?.productVariant.length > 1) {
      let ids = new Set(whatNextDeliverableDataType.map((d) => d.label));
      let merged = [...whatNextDeliverableDataType, ...insiderDeliverableDataType.filter((d) => !ids.has(d.label))];
      setcontentBlockContentTypeArray(merged);
    } else {
      typeArray.map((element) => {
        if (element === "WhatNext") {
          setcontentBlockContentTypeArray(whatNextDeliverableDataType);
        }
        if (element === "Insider") {
          setcontentBlockContentTypeArray(insiderDeliverableDataType);
        }
      });
    }
    if (response.data?.data?.contentBlockThumbnail) {
      setcontentThumbnailImageSrc(fetchContentBlockImageUrl(response.data?.data?.contentBlockThumbnail));
      setShowTrash(true);
    }
    formik.setValues({
      ...formik.values,
      contentBlockName: response.data?.data?.contentBlockName,
      contentBlockType: response.data?.data?.contentBlockType,
      contentBlockVariant: response.data?.data?.productVariant.map((variant) => {
        return { label: variant.productName, value: variant.productName };
      }),
      contentBlockContentType: response.data?.data?.contentBlockContentType.map((v) => {
        return v;
      }),
      ...response.data.data?.contentBlockSetting,
    });
    dispatch(hideLoader());
  };

  /*
    @Description : Change saveContentBlock state for trigger news save event
    State Change trigger from footer component
  */
  const handleSave = async (contentBlockSaveStatus) => {
    setSaveContentBlockFlag(contentBlockSaveStatus);
    if (contentBlockSaveStatus && imageTypeError === "") {
      let userId = getLoggedInUser();
      let categoryId = undefined;
      formik.validateForm().then(async (res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          let errors = Object.keys(res);
          if (errors.length > 0) dispatch(actionError(res[errors[0]]));
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          categoryId = categoryBlock.find((category) => {
            if (category.categoryName === formik.values.contentBlockType) {
              return category["categoryId"];
            }
          });
          let contentData = {
            contentBlockName: formik.values.contentBlockName,
            contentBlockType: formik.values.contentBlockType,
            productVariant: formik.values.contentBlockVariant.map((v) => {
              return { product_name: v.label };
            }),
            contentBlockContentType: formik.values.contentBlockContentType.map((v) => {
              return v;
            }),
            contentBlockCategoryId: categoryId.categoryId,
          };

          let saveOverviewData = new FormData();
          for (let uKey in contentData) {
            if (typeof contentData[uKey] != "object") {
              if (contentData[uKey]) saveOverviewData.append(uKey, contentData[uKey]);
            } else {
              if (contentData[uKey]) saveOverviewData.append(uKey, JSON.stringify(contentData[uKey]));
            }
          }

          if (
            contentThumbnailDefaultImageRef.current.files.length &&
            (contentThumbnailDefaultImageRef.current.files[0].type === "image/jpeg" ||
              contentThumbnailDefaultImageRef.current.files[0].type === "image/png")
          ) {
            saveOverviewData.append("contentBlockThumbnail", contentThumbnailDefaultImageRef.current.files[0]);
            setImageError("");
          } else if (props.contentBlockId !== "" && contentThumbnailImageSrc === undefined) {
            saveOverviewData.append("contentBlockThumbnail", "");
          }
          let response = "";
          if (contentBlockId) {
            dispatch(showLoader());
            try {
              if (imageDelete) {
                saveOverviewData.append("imageDelete", "yes");
              }
              saveOverviewData.append("contentBlockModifiedUserId", userId._id);
              await updateContentBlock(contentBlockId, saveOverviewData);

              let contentBlockHtmlData = new FormData();
              contentBlockHtmlData.append("contentBlockModifiedUserId", userId._id);
              contentBlockHtmlData.append("contentBlockHtml", preservedHtml);
              await updateContentBlock(contentBlockId, contentBlockHtmlData);

              let contentData = new FormData();
              contentData = {
                contentBlockSetting: {
                  disableFontFormatting: formik.values?.disableFontFormatting ? formik.values.disableFontFormatting : "NO",
                  disableFontColor: formik.values?.disableFontColor ? formik.values?.disableFontColor : "NO",
                  disableFontStyle: formik.values?.disableFontStyle ? formik.values.disableFontStyle : "NO",
                  disableExtraSettings: formik.values?.disableExtraSettings ? formik.values?.disableExtraSettings : "NO",
                },
                contentBlockModifiedUserId: userId._id,
              };
              await updateContentBlockSetting(contentBlockId, contentData);
              dispatch(hideLoader());
              dispatch(actionSuccess("Content Block updated Successfully"));
            } catch (err) {
              dispatch(hideLoader());
              dispatch(actionError("Something went wrong"));
            }
          } else {
            saveOverviewData.append("contentBlockCreatedUserId", userId._id);
            dispatch(showLoader());
            response = saveContentBlock(saveOverviewData);

            newRecord();
            response
              .then((res) => {
                if (res.status === 200) {
                  dispatch(actionSuccess("Content Block Saved Successfully"));
                  handleRedirectOnAddContentBlock(res.data.data.id);
                }
              })
              .catch((err) => {
                dispatch(hideLoader());
                dispatch(actionError(err?.data?.message || "Something Went Wrong...!"));
                setSave(true);
              });
          }
        }
      });
    }
  };

  const handleContentBlockId = (obj) => {
    setContentBlockId(obj.id);
    setContentBlockName(obj.contentBlockName);
  };

  const handleSubmit = () => {
    let userId = getLoggedInUser();
    const contentData = {
      contentBlockStatus: "Submitted",
      contentBlockModifiedUserId: userId._id,
    };
    dispatch(
      showAlertBox({
        okCallback: () => {
          updateStatusSubmit(contentBlockId, contentData)
            .then((res) => {
              if (res.status === 200) {
                setViewRemark(false);
                dispatch(actionSuccess("Submitted Successfully"));
                fetchSingleContentBlock(contentBlockId).then((res) => {
                  setContentBlockStatus(res.data.data.contentBlockStatus);
                });
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        content: "Are you sure you want to Submit?",
        okText: "Submit",
        cancelText: "Cancel",
        title: "dialogAlertCss",
      })
    );
  };
  const handleApprove = () => {
    let userId = getLoggedInUser();
    const contentData = {
      contentBlockStatus: "Approved",
      contentBlockModifiedUserId: userId._id,
    };
    dispatch(
      showAlertBox({
        okCallback: () => {
          updateStatusApprove(contentBlockId, contentData)
            .then((res) => {
              if (res.status === 200) {
                setViewRemark(false);
                dispatch(actionSuccess("Approved Successfully"));
                fetchSingleContentBlock(contentBlockId).then((res) => {
                  setContentBlockStatus(res.data.data.contentBlockStatus);
                });
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        content:
          "Please save changes (if any) before switching the tab, if you switch tab without saving then your changes will not be saved. Do you want to continue?",
        okText: "Approve",
        cancelText: "Cancel",
        title: "dialogAlertCssApprove",
      })
    );
  };
  const handleReject = () => {
    let userId = getLoggedInUser();
    const contentData = {
      contentBlockStatus: "Rejected",
      contentBlockModifiedUserId: userId._id,
    };
    dispatch(
      showAlertBox({
        okCallback: () => {
          updateStatusReject(contentBlockId, contentData)
            .then((res) => {
              if (res.status === 200) {
                setViewRemark(true);
                dispatch(actionSuccess("Rejected Successfully"));
                fetchSingleContentBlock(contentBlockId).then((res) => {
                  setContentBlockStatus(res.data.data.contentBlockStatus);
                });
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        content: "Are you sure you want to Reject?",
        okText: "Reject",
        cancelText: "Cancel",
        title: "dialogAlertCssWarning",
      })
    );
  };

  const handlePublish = () => {
    let userId = getLoggedInUser();
    const contentData = {
      contentBlockStatus: "Published",
      contentBlockModifiedUserId: userId._id,
    };

    dispatch(
      showAlertBox({
        okCallback: () => {
          updateStatusPublish(contentBlockId, contentData)
            .then((res) => {
              if (res.status === 200) {
                setViewRemark(false);
                dispatch(actionSuccess("Published Successfully"));
                fetchSingleContentBlock(contentBlockId).then((res) => {
                  setContentBlockStatus(res.data.data.contentBlockStatus);
                });
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        content: "Are you sure, you want to Publish?",
        okText: "Publish",
        cancelText: "Cancel",
        title: "dialogAlertCss",
      })
    );
  };
  /*
    @Description : Redirect to edit page after adding news overview (Applicable only for add new news)
  */
  const handleRedirectOnAddContentBlock = (addContentBlockId) => {
    history.push(`/content-block/${addContentBlockId}/edit#properties`);
    setContentBlockId(addContentBlockId);
  };
  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);

    tabRef.current.select(0);
  };

  useEffect(() => {
    if (selectedIndex != 1) {
      setViewBlocks(false);
      [...window.document.querySelectorAll(".moveable-control")].map((a) => {
        a.style.display = "none";
        a.classList.add("remove-dots");
      });
    }
  }, [selectedIndex]);

  // Description : Get list of content blocks
  const fetchSnippetList = async () => {
    let params = {};
    params.filters = [["configName", "eq", "content_builder_category"]];

    await fetchConfig(params).then((res) => {
      if (res.status === 200) {
        let blockListArry = res.data.data[0].configValue;
        blockListArry.sort((a, b) => (a.categoryName > b.categoryName ? 1 : b.categoryName > a.categoryName ? -1 : 0));
        let result = blockListArry.map((resp) => [parseInt(resp.categoryId), resp.categoryName]);
        setSnippetList(result);
      }
    });
  };

  /* Interface wise action */
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);

  /* Functionality wise action */
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  return (
    <div>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        <div>
          <Tabs
            tabsName={TABS_NAME}
            tabRef={tabRef}
            created={created}
            handleClick={() => {
              setSelectedIndex(tabRef.current.selectedItem);
            }}
            cssClass="newHeaderTabCss"
          >
            <Suspense
              fallback={
                <div className="text-center">
                  <h3>Loading...</h3>
                </div>
              }
            >
              <RenderUtility
                handleContentBlockId={handleContentBlockId}
                newRecord={newRecord}
                activeStepper={Object.keys(hashArr)[selectedIndex]}
                contentBlockId={contentBlockId}
                handleSave={handleSave}
                saveContentBlock={saveContentBlockFlag}
                handleRedirectOnAddContentBlock={handleRedirectOnAddContentBlock}
                selectedIndex={selectedIndex}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                creatorAccess={creatorAccess}
                reviewerAccess={reviewerAccess}
                contentBlockStatus={contentBlockStatus}
                viewRemark={viewRemark}
                setViewRemark={setViewRemark}
                viewBlocks={viewBlocks}
                setViewBlocks={setViewBlocks}
                setSaveContentBlockFlag={setSaveContentBlockFlag}
                snippetList={snippetList}
                setSnippetList={setSnippetList}
                formik={formik}
                categoryBlock={categoryBlock}
                setCategoryBlock={setCategoryBlock}
                contentThumbnailDefaultImageRef={contentThumbnailDefaultImageRef}
                setcontentThumbnailImageSrc={setcontentThumbnailImageSrc}
                contentThumbnailImageSrc={contentThumbnailImageSrc}
                imageError={imageError}
                setImageError={setImageError}
                setSave={setSave}
                save={save}
                setImageDelete={setImageDelete}
                imageDelete={imageDelete}
                setcontentBlockContentTypeArray={setcontentBlockContentTypeArray}
                contentBlockContentTypeArray={contentBlockContentTypeArray}
                setShowTrash={setShowTrash}
                showTrash={showTrash}
                preserveContentHtml={preserveContentHtml}
                preservedHtml={preservedHtml}
                whatNextDeliverableDataType={whatNextDeliverableDataType}
                setWhatNextDeliverableDataType={setWhatNextDeliverableDataType}
                insiderDeliverableDataType={insiderDeliverableDataType}
                setInsiderDeliverableDataType={setInsiderDeliverableDataType}
                imageTypeError={imageTypeError}
                setImageTypeError={setImageTypeError}
                accessRightInterfaceRights={accessRightInterfaceRights}
                hashArr={hashArr}
                accessPermissionTaxonomy={accessActionTaxonomyAdd}
                accessPermissionTaxonomyDelete={accessActionTaxonomyDelete}
                accessActionTaxonomyAddTags={accessActionTaxonomyAddTags}
                accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
              />
            </Suspense>
          </Tabs>
        </div>
      </div>
      <FooterBar
        contentBlockStatus={contentBlockStatus}
        handleReject={handleReject}
        handlePublish={handlePublish}
        handleApprove={handleApprove}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        contentBlockId={contentBlockId}
        activeStepper={Object.keys(hashArr)[selectedIndex]}
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        viewBlocks={viewBlocks}
        setViewBlocks={setViewBlocks}
        tabsName={TABS_NAME}
        moduleName={aR.moduleName}
        hashArr={hashArr}
      />
    </div>
  );
};

export default Add;
