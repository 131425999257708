/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  News Add | Edit Page
   Summary : Render Utility component is responsible for rendering respective component
   [e.g Overview | Content Management | Tagging]
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 21/Aug/2020
  Author						: Rehan Kodekar
================================================================
*/
import React, { useState, Suspense, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Tabs from "../../../components/tabsComponent";
import RenderUtility from "./component/utilities/renderUtility";
import FooterBar from "./component/utilities/footerBar";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import {
  fetchSingleDeliverable,
  updateStatusSubmit,
  updateStatusApprove,
  updateStatusReject,
  saveOverView,
  updateOverView,
  fetchDeliverableImageUrl,
  updateDeliverableSetting,
  updatePageContent,
} from "../../../../middleware/services/deliverablesApi";
import { getLoggedInUser, getProductVariant, accessRightInterfaceCheck, accessRightActionCheck, isAdmin } from "../../../../utilities";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { updateLog } from "../../../../middleware/services/activityLoggingApi";
import { fetchConfig, fetchTaxonomyTrend } from "../../../../middleware/services/cmsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deliverableOverviewValidationSchema, deliverableSignalExternalValidation } from "./component/overview/validationSchema";
import { setDeliverableDetails, setIsPublishClickedState } from "../../../../middleware/actions/deliverableAction";
import { fetchContentBlock } from "../../../../middleware/services/contentBlockApi";
import { ADMIN_USER, COMMON_ACCESS_ACTION, PRODUCT_VARIANT, CONTENT_TYPE, DELIVERABLE_TYPE } from "../../../../constants";
import { fetchSingleUser } from "../../../../middleware/services/userApi";
/*
  @Description : Stepper Labels for deliverable Add | Edit Page
*/

const Add = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const history = useHistory();
  const deliverableId = props.computedMatch?.params?.deliverableId || "";
  const pageId = props.computedMatch?.params?.pageId || "";
  const templateId = props.computedMatch?.params?.templateId || "";
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [deliverableType, setDeliverableType] = useState();
  const [deliverableContentType, setDeliverableContentType] = useState();
  const [saveDeliverable, setSaveDeliverable] = useState(false);
  const [deliverableName, setDeliverableName] = useState("");
  const [deliverableDocuments, setDeliverableDocuments] = useState(false);
  const isPublishClicked = useSelector((state) => state.deliverableState.clickStatus);
  const [deliverableStatus, setDeliverableStatus] = useState("");
  const [logStatus, setLogStatus] = useState(false);
  const [taskId, setTaskId] = useState(undefined);
  const [logTime, setLogTime] = useState(undefined);
  const [showReportRemark, setShowReportRemark] = useState(false);
  const [contentPermission, setContentPermission] = useState(false);
  const [deliverableProductPlatform, setDeliverableProductPlatform] = useState("");
  const [snippentList, setSnippetList] = useState([]);
  const deliverableDefaultImageRef = useRef(null);
  const externalDefaultImageRef = useRef(null);
  const productVariant = getProductVariant();
  const [deliverableImageSrc, setDeliverableImageSrc] = useState(undefined);
  const [deliverableSignalRelatedContentImageSrc, setDeliverableSignalRelatedContentImageSrc] = useState(undefined);
  const [deliverableSignalRelatedContentImageSrcExternal, setDeliverableSignalRelatedContentImageSrcExternal] = useState(undefined);
  const [existingRelatedContentType, setExistingRelatedContentType] = useState("");
  const [mostReadResearch, setMostReadResearch] = useState("");
  const [addToWidgets, setAddToWidgets] = useState("");
  const [comingSoon, setComingSoon] = useState("");
  const [imageError, setImageError] = useState("");
  const settingState = useSelector((state) => state.deliverableState.settingsDetails);
  const user = getLoggedInUser();
  const [preservedHtml, preserveContentHtml] = useState("");
  const [loderContent, setLoaderForContent] = useState(false);
  let pageeId = sessionStorage.getItem("pageeId");
  const [popularReport, setPopularReport] = useState("");
  const [storeBlockData, setStoreBlockData] = useState("");
  const [reRenderContent, setReRenderContent] = useState(false);
  const [taskWriter, setTaskWriter] = useState([]);
  const [contentTabAccess, setContentTabAccess] = useState(false);
  let TABS_NAME = ["Basic", "Task", "Content", "Setting"];
  productVariant === PRODUCT_VARIANT.INSIDER ? TABS_NAME.push("Taxonomy & Tags") : TABS_NAME.push("Taxonomy");
  const [industryData, setIndustryData] = useState([]);
  const [userCompentencyIndustry, setUserCompentencyIndustry] = useState([]);
  const [selectedRelatedContentReportId, setSelectedRelatedContentReportId] = useState("");
  const [selectedRelatedContentReportIdExisting, setSelectedRelatedContentReportIdExisting] = useState("");
  const [selectedFileExternal, setSelectedFileExternal] = useState(null);
  const [signalIndicatorColor, setSignalIndicatorColor] = useState("#FFF");
  const [relatedContentSaved, setRelatedContentSaved] = useState(false);
  const [deliverableSignalContent, setDeliverableSignalContent] = useState({
    deliverableSignalName: "",
    deliverableSignalContent: "",
    deliverableSignalTags: [],
    deliverableSignalReadTime: "",
    deliverableSignalDate: "",
  });
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [listingActionAccess, setListingActionAccess] = useState([]);

  useEffect(() => {
    let interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setListingActionAccess(actionAccess);
  }, []);

  const accessAdministrator = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_ADMINISTRATOR);
  const accessWriter = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_CREATOR);
  const accessReviewer = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_REVIEWER);
  const accessPublisher = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_PUBLISHER);

  const accessActionTaxonomyAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionTaxonomyAddTags = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionTaxonomyDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionTaxonomyShowInProduct = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);
  const accessActionTaskAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionTaskEditTask = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_TASK);
  const accessActionTaskEditContent = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_CONTENT);
  const accessActionTaskDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);

  /*
  @Description : Default value for news overview form
  */
  const [deliverableInformation, setDeliverableInformation] = useState({
    deliverableName: "",
    deliverableContentStatus: "",
    deliverableCompany: [],
    deliverableProduct: [],
    deliverableType: "",
    deliverableContentType: "",
    deliverableNews: [],
    // deliverableObjective: "",
    deliverableSummary: "",
    deliverableEstStartDate: "",
    // deliverableEstStartDate: new Date(),
    deliverableEstEndDate: "",
    deliverableActStartDate: "",
    deliverableActEndDate: "",
    deliverableProductPlatform: ["Insider V2"],
    deliverableContentOrientation: "",
    createReportFrom: {
      label: "Choose a template option",
      value: "Choose a template option",
    },
    existingTemplate: "",
    deliverableIsTemplate: "NO",
    deliverableIsMostResearch: "NO",
    deliverableAddToWidgets: "NO",
    deliverablePaywallSetting: "NO",
    deliverableIsPopularReport: "NO",
    deliverableIsComingSoon: "NO",
    pdfText: "",
    pdfFontFamily: "",
    pdfFontSize: "",
    pdfColor: "",
    pdfOpacity: "",
    pdfTopPosition: "",
    pdfLeftPosition: "",
    pdfRotate: "",
    excelText: "",
    excelFontFamily: "",
    excelFontSize: "",
    excelColor: "",
    excelOpacity: "",
    excelTopPosition: "",
    excelLeftPosition: "",
    excelRotate: "",
    powerPointText: "",
    powerPointFontFamily: "",
    powerPointFontSize: "",
    powerPointColor: "",
    powerPointOpacity: "",
    powerPointTopPosition: "",
    powerPointLeftPosition: "",
    powerPointRotate: "",
    chartsText: "",
    chartsFontFamily: "",
    chartsFontSize: "",
    chartsColor: "",
    chartsOpacity: "",
    chartsTopPosition: "",
    chartsLeftPosition: "",
    chartsRotate: "",
    sectionText: "",
    sectionFontFamily: "",
    sectionFontSize: "",
    sectionColor: "",
    sectionOpacity: "",
    sectionTopPosition: "",
    sectionLeftPosition: "",
    sectionRotate: "",
    exportAsPdf: "No",
    exportAsExcel: "No",
    exportAsPpt: "No",
    exportASection: "No",
    exportAsChart: "No",
    deliverablePublishedDate: new Date() || "",
    deliverablePowerbiReportId: "",
    deliverablePowerbiReportName: "",
    deliverablePowerbiOriginalReportName: "",
    deliverablePowerbiReportUploadedDate: "",
    deliverableSignalTooltip: "",
    deliverableSignalIndicator: "",
    deliverableSignalRelatedContentType: "",
    deliverableOldPowerbiReportId: "",
    deliverableOldPowerbiReportName: "",
    sideBarDeliverableContentType: "",
    sideBardeliverableType: "",
  });
  let timer = undefined;
  let userId = getLoggedInUser();

  const hashArr = {
    "#basic": "Basic",
    "#task": "Task",
    "#content": "Content",
    "#setting": "Setting",
    "#taxonomy": "Taxonomy",
  };
  const [showSave, setShowSave] = useState(true);

  useEffect(() => {
    if (loderContent) {
      if (saveDeliverable) {
        dispatch(showLoader());
      } else {
        dispatch(showLoader());
        setTimeout(() => {
          dispatch(hideLoader());
        }, 1000);
      }
    }
  }, [loderContent]);

  /*
    @Description : Assign default value and bind validation schema
  */
  const formik = useFormik({
    initialValues: deliverableInformation,
    validationSchema: Yup.object().shape(deliverableOverviewValidationSchema),
  });

  const formikRelatedContentExternal = useFormik({
    initialValues: {
      deliverableSignalRelatedContentTitle: "",
      deliverableSignalRelatedContentSummary: "",
      deliverableSignalRelatedContentUrl: "",
      deliverableSignalRelatedContentPublishedDate: new Date() || "",
      deliverableSignalRelatedContentThumbnail: "",
    },
    validationSchema: Yup.object().shape(deliverableSignalExternalValidation),
  });

  /**
   * Description: Common save dispatch
   */
  useEffect(() => {
    dispatch(setDeliverableDetails(formik.values));
  }, [formik.values]);

  /**
   * Description: Remove the content changes from session storage
   */
  useEffect(() => {
    preserveContentHtml("");
    sessionStorage.removeItem("pageeId");
  }, []);

  /*
    @Description : Define breadcrumb for Add | Edit News
  */
  const breadCrumbLinks = [
    { linkUrl: "/deliverable", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: deliverableId ? `/deliverable/${deliverableId}/edit#basic` : `/deliverable/add#basic`,
      linkName: deliverableContentType,
      linkActive: deliverableId ? false : true,
    },
    {
      linkUrl: deliverableId ? `/deliverable/${deliverableId}/edit#basic` : `/deliverable/add#basic`,
      linkName: deliverableId ? `${deliverableName}` : "Add Deliverable",
      linkActive: false,
    },
    {
      linkUrl: `/deliverable/${deliverableId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];
  /*
    @Description : Handle comon save function on single click save all tab data
    State Change trigger from footer component
  */
  const handleSave = (deliverableSaveState) => {
    setSaveDeliverable(deliverableSaveState);
    if (deliverableSaveState && imageError === "") {
      let deliverableIsMostResearch = mostReadResearch === true ? "YES" : "NO";
      let deliverableIsPopularReport = popularReport === true ? "YES" : "NO";
      let deliverableIsComingSoon = comingSoon === true ? "YES" : "NO";
      let deliverableAddToWidgets = addToWidgets === true ? "YES" : "NO";

      let overviewData = {
        deliverableName: formik.values.deliverableName,
        deliverableCompanyAssociated: formik.values.deliverableCompany.map((c) => {
          if (c.label === c.value) {
            return { companyName: c.value };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
            };
          }
        }),
        deliverableType: formik.values.deliverableType,
        deliverableContentType: formik.values.deliverableContentType,
        deliverableProductAssociated: formik.values.deliverableProduct.map((c) => {
          return {
            productName: c.label,
            productId: c.value,
          };
        }),
        deliverableNewsAssociated: formik.values.deliverableNews.map((c) => {
          return {
            newsTitle: c.label,
            newsId: c.value,
          };
        }),
        deliverableSummary: formik.values.deliverableSummary,
        // deliverableEstStartDate: estStartDate,
        // deliverableEstEndDate: formik.values.deliverableEstEndDate ? new Date(formik.values.deliverableEstEndDate) : "",
        // deliverableActEndDate: formik.values.deliverableActEndDate ? new Date(formik.values.deliverableActEndDate) : "",
        // deliverableActStartDate: formik.values.deliverableActStartDate ? new Date(formik.values.deliverableActStartDate) : "",
        deliverableContentOrientation: formik.values.deliverableContentOrientation,
        deliverableIsMostResearch: deliverableIsMostResearch,
        deliverableAddToWidgets: deliverableAddToWidgets,
        deliverableIsComingSoon: deliverableIsComingSoon,
        deliverableIsPopularReport: deliverableIsPopularReport,
        deliverableProductPlatform: formik.values.deliverableProductPlatform,
        deliverableReferenceId:
          formik.values.deliverableProductPlatform.includes("Insider V2") && formik.values.existingTemplate?.length > 0
            ? formik.values.existingTemplate
            : "",
        deliverableIsTemplate: formik.values.deliverableProductPlatform.includes("Insider V2") ? formik.values.deliverableIsTemplate : "",
        deliverableSignalTooltip: formik.values.deliverableSignalTooltip,
        deliverableSignalIndicator: formik.values.deliverableSignalIndicator,
        deliverableSignalIndicatorColor: signalIndicatorColor.value,
        deliverableSignalRelatedContentType: formik.values.deliverableSignalRelatedContentType,
        deliverableSignalRelatedContentTitle: formikRelatedContentExternal.values.deliverableSignalRelatedContentTitle,
        deliverableSignalRelatedContentSummary: formikRelatedContentExternal.values.deliverableSignalRelatedContentSummary,
        deliverableSignalRelatedContentUrl: formikRelatedContentExternal.values.deliverableSignalRelatedContentUrl,
      };

      let saveOverviewData = new FormData();
      for (let uKey in overviewData) {
        if (typeof overviewData[uKey] !== "object") {
          if (overviewData[uKey]) saveOverviewData.append(uKey, overviewData[uKey]);
        } else {
          if (overviewData[uKey]) saveOverviewData.append(uKey, JSON.stringify(overviewData[uKey]));
        }
      }
      if (formik.values.deliverableEstStartDate) {
        let dateToConvert = new Date(formik.values.deliverableEstStartDate);
        saveOverviewData.append("deliverableEstStartDate", dateToConvert);
      }

      if (formik.values.deliverableEstEndDate) {
        let dateToConvert = new Date(formik.values.deliverableEstEndDate);
        saveOverviewData.append("deliverableEstEndDate", dateToConvert);
      }

      if (formik.values.deliverableActStartDate) {
        let dateToConvert = new Date(formik.values.deliverableActStartDate);
        saveOverviewData.append("deliverableActStartDate", dateToConvert);
      }

      if (formik.values.deliverableActEndDate) {
        let dateToConvert = new Date(formik.values.deliverableActEndDate);
        saveOverviewData.append("deliverableActEndDate", dateToConvert);
      }

      if (formik.values.deliverablePowerbiReportId) {
        saveOverviewData.append("deliverablePowerbiReportId", formik.values.deliverablePowerbiReportId);
      }

      if (formik.values.deliverablePowerbiReportName) {
        saveOverviewData.append("deliverablePowerbiReportName", formik.values.deliverablePowerbiReportName);
      }

      if (formik.values.deliverableOldPowerbiReportId) {
        saveOverviewData.append("deliverableOldPowerbiReportId", formik.values.deliverableOldPowerbiReportId);
      }

      if (formik.values.deliverableOldPowerbiReportName) {
        saveOverviewData.append("deliverableOldPowerbiReportName", formik.values.deliverableOldPowerbiReportName);
      }

      if (formik.values.deliverablePowerbiOriginalReportName) {
        saveOverviewData.append("deliverablePowerbiOriginalReportName", formik.values.deliverablePowerbiOriginalReportName);
      }

      if (formik.values.deliverablePowerbiReportUploadedDate) {
        let dateToConvert = new Date(formik.values.deliverablePowerbiReportUploadedDate);
        saveOverviewData.append("deliverablePowerbiReportUploadedDate", dateToConvert);
      }

      // ** Setting published date
      if (formik.values.deliverablePublishedDate) {
        let dateToConvert = new Date(formik.values.deliverablePublishedDate).toUTCString();
        saveOverviewData.append("deliverablePublishedDate", dateToConvert);
      }

      // ** Setting external related content published date
      if (formikRelatedContentExternal.values.deliverableSignalRelatedContentPublishedDate) {
        let dateToConvert = new Date(formikRelatedContentExternal.values.deliverableSignalRelatedContentPublishedDate).toUTCString();
        saveOverviewData.append("deliverableSignalRelatedContentPublishedDate", dateToConvert);
      }

      if (formik.values.deliverablePaywallSetting) {
        let deliverablePaywallSetting =
          formik.values.deliverablePaywallSetting == "YES"
            ? [
                {
                  productVariant: productVariant,
                  productPlatform: formik.values.deliverableProductPlatform,
                  taxonomyTagging: [],
                },
              ]
            : [];
        saveOverviewData.append("deliverablePaywallSetting", JSON.stringify(deliverablePaywallSetting));
      }

      if (formik.values?.deliverableDocuments && formik.values?.deliverableDocuments.length && !formik.values?.deliverableDocuments[0]?.docFileName) {
        saveOverviewData.append("deliverableDocuments", formik.values?.deliverableDocuments[0]);
      }

      if (!(formik.values.deliverableContentType === CONTENT_TYPE.SIGNALS && formik.values.deliverableType === DELIVERABLE_TYPE.SIGNALS)) {
        if (
          !imageError &&
          deliverableDefaultImageRef.current.files.length &&
          (deliverableDefaultImageRef.current.files[0].type === "image/jpeg" || deliverableDefaultImageRef.current.files[0].type === "image/png")
        ) {
          saveOverviewData.append("deliverableProfileImage", deliverableDefaultImageRef.current.files[0]);
        } else if (imageError && deliverableImageSrc) {
          saveOverviewData.delete("deliverableProfileImage");
        } else if (props.deliverableId !== "" && deliverableImageSrc === undefined) {
          saveOverviewData.append("deliverableProfileImage", "");
        }
      } else {
        if (
          externalDefaultImageRef.current &&
          externalDefaultImageRef.current.files.length &&
          (externalDefaultImageRef.current.files[0].type === "image/jpeg" || externalDefaultImageRef.current.files[0].type === "image/png")
        ) {
          saveOverviewData.append("deliverableSignalRelatedContentThumbnail", externalDefaultImageRef.current.files[0]);
        } else if (selectedFileExternal) {
          saveOverviewData.append("deliverableSignalRelatedContentThumbnail", selectedFileExternal);
        }
      }

      if (selectedRelatedContentReportId && selectedRelatedContentReportId !== "") {
        saveOverviewData.append("deliverableSignalRelatedContentReportId", selectedRelatedContentReportId);
      }

      const formDataObj = {};
      saveOverviewData.forEach((value, key) => (formDataObj[key] = value));

      formik.validateForm().then(async (res) => {
        if (Object.keys(res).length) {
          const touched = {};

          Object.keys(res).map((field) => {
            touched[field] = true;

            return touched;
          });

          let errors = Object.keys(res);
          if (errors.length > 0) dispatch(actionError(res[errors[0]]));
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          if (overviewData.deliverableType == DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE) {
            // if (overviewData.deliverableBusinessObjective == '') {
            // 	formik.setFormikState({ ...formik, touched: { deliverableObjective: true }, errors: { deliverableObjective: 'Please select Objective' } });
            // 	return;
            // }
          }
          setImageError("");
          if (
            deliverableDefaultImageRef?.current?.files?.length ||
            deliverableImageSrc ||
            externalDefaultImageRef?.current?.files?.length ||
            deliverableSignalRelatedContentImageSrc ||
            deliverableSignalRelatedContentImageSrcExternal
          ) {
            dispatch(showLoader());
            try {
              let response = "";
              saveOverviewData.append("userId", user._id);
              saveOverviewData.append("deliverableCreatedByUserId", user._id);
              saveOverviewData.append("deliverableCreatedByUserName", user.user_fname + " " + user.user_lname);
              if (deliverableId === "") {
                const productVariant = getProductVariant();
                if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
                response = saveOverView(saveOverviewData);
              } else {
                response = updateOverView(deliverableId, saveOverviewData);
              }
              response
                .then((response) => {
                  if (selectedIndex !== 2) dispatch(hideLoader());
                  dispatch(setIsPublishClickedState(false));
                  if (deliverableId === "") {
                    dispatch(actionSuccess("Deliverable Saved Successfully."));
                    handleRedirectOnAddDeliverable(response.data.data.id);
                    fetchDeliverable(response.data.data.id);
                    newRecord();
                  } else {
                    if (selectedIndex != 2) dispatch(actionSuccess("Deliverable Updated Successfully."));
                    if (selectedIndex == 2 && !pageeId) {
                      dispatch(actionSuccess("Deliverable Updated Successfully."));
                    }
                    fetchDeliverable(deliverableId);
                  }
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  if (e?.data?.message) {
                    if (e.data.statusCode === 413) dispatch(actionError("Maximum file size allowed is 40MB."));
                    else dispatch(actionError(e.data.message));
                  } else {
                    dispatch(actionError("Something Went Wrong...!"));
                  }
                });
              if (settingState && Object.keys(settingState).length > 0) {
                let deliverableData = {
                  deliverableSetting: {
                    ...settingState,
                  },
                  userId: userId._id,
                };
                await updateDeliverableSetting(deliverableId, deliverableData);
              }
              if (sessionStorage.getItem("pageeId") && pageeId !== sessionStorage.getItem("pageeId")) pageeId = sessionStorage.getItem("pageeId");
              if (selectedIndex == 2 && pageeId && preservedHtml) {
                setLoaderForContent(true);
                let pageContentHtmlData = new FormData();
                pageContentHtmlData.append("pageCreatedBy", userId._id);
                pageContentHtmlData.append("pageContentHtml", preservedHtml);
                await updatePageContent(deliverableId, pageeId, pageContentHtmlData);
                setLoaderForContent(false);
                dispatch(actionSuccess("Content Updated successfully"));
                preserveContentHtml("");
                setStoreBlockData("");
                dispatch(hideLoader());
                setSaveDeliverable(false);
              }
              dispatch(setIsPublishClickedState(false));
            } catch (e) {
              dispatch(hideLoader());
              setLoaderForContent(false);
              if (e?.data?.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something Went Wrong...!"));
              }
            }
          } else if (!(formik.values.deliverableContentType === CONTENT_TYPE.SIGNALS && formik.values.deliverableType === DELIVERABLE_TYPE.SIGNALS)) {
            dispatch(actionError("Please upload deliverable thumbnail"));
          } else {
            dispatch(actionError("Please add related content"));
          }
        }
      });
    }
  };

  /*
    @Description : Fetch news data on edit
    1. set news data to respective fields
    2. Set image path for sending to child component
  */
  const fetchDeliverable = (deliverableId) => {
    try {
      const response = fetchSingleDeliverable(deliverableId);
      response
        .then(async (response) => {
          let result = response.data.data;
          if (
            selectedIndex === 0 &&
            (result?.deliverableProductPlatform.includes("Insider V2") || result?.deliverableProductPlatform.includes("WhatNext V2"))
          ) {
            dispatch(showLoader());
            await fetchContentBlocks(result?.deliverableType);
          }
          if (response.status === 200 && response.data.data) {
            let deliverableData = {
              deliverableName: response.data.data.deliverableName,
              deliverableCompany: response.data.data?.deliverableCompanyAssociated
                ? [
                    ...response.data.data?.deliverableCompanyAssociated.map((cC) => {
                      let cCV = { label: cC.companyName };
                      if (cC.companyId) {
                        cCV.value = cC.companyId;
                      } else {
                        cCV.value = cC.companyName;
                      }
                      return cCV;
                    }),
                  ]
                : [],
              deliverableContentStatus: response.data.data?.deliverableContentStatus && response.data.data.deliverableContentStatus,
              deliverableType: response.data.data?.deliverableType && response.data.data.deliverableType,
              deliverableContentType: response.data.data?.deliverableContentType && response.data.data.deliverableContentType,
              deliverableIsMostResearch: response.data.data?.deliverableIsMostResearch && response.data.data.deliverableIsMostResearch,
              deliverableAddToWidgets: response.data.data?.deliverableAddToWidgets && response.data.data.deliverableAddToWidgets,
              deliverableIsComingSoon: response.data.data?.deliverableIsComingSoon && response.data.data.deliverableIsComingSoon,
              deliverableEstStartDate: response.data.data?.deliverableEstStartDate ? new Date(response.data.data.deliverableEstStartDate) : "",
              deliverableEstEndDate: response.data.data?.deliverableEstEndDate ? new Date(response.data.data.deliverableEstEndDate) : "",
              deliverableActStartDate: response.data.data?.deliverableActStartDate ? new Date(response.data.data.deliverableActStartDate) : "",
              deliverableActEndDate: response.data.data?.deliverableActEndDate ? new Date(response.data.data.deliverableActEndDate) : "",
              deliverableContentOrientation: response.data.data?.deliverableContentOrientation
                ? response.data.data.deliverableContentOrientation
                : "",
              deliverableProductPlatform: response?.data.data?.deliverableProductPlatform ? response.data.data.deliverableProductPlatform : [],
              deliverableProduct: response.data.data?.deliverableProductAssociated
                ? [
                    ...response.data.data?.deliverableProductAssociated.map((cC) => {
                      return {
                        label: cC.productName,
                        value: cC.productId,
                      };
                    }),
                  ]
                : [],
              deliverableNews: response.data.data?.deliverableNewsAssociated
                ? [
                    ...response.data.data?.deliverableNewsAssociated.map((cC) => {
                      return {
                        label: cC.newsTitle,
                        value: cC.newsId,
                      };
                    }),
                  ]
                : [],
              // deliverableObjective: response.data.data?.deliverableBusinessObjective ? { label: response.data.data?.deliverableBusinessObjective.objectiveTitle, value: response.data.data?.deliverableBusinessObjective.objectiveId } : "",
              deliverableSummary: response.data.data?.deliverableSummary === undefined ? "" : response.data.data?.deliverableSummary,
              // deliverableSummary: response.data.data?.deliverableSummary && response.data.data.deliverableSummary,
              deliverableDocuments: response.data.data?.deliverableDocuments || [],
              deliverableReferenceId: response?.data?.data?.deliverableReferenceId ? response.data.data.deliverableReferenceId : "",
              existingTemplate: response.data.data.deliverableReferenceId ? response.data.data.deliverableReferenceId : "",
              createReportFrom: response.data.data.deliverableReferenceId
                ? { label: "Existing Template", value: "existing template" }
                : response?.data.data?.deliverableProductPlatform.includes("Insider V2") &&
                  response.data.data?.deliverableType == DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE &&
                  response.data.data?.deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
                  response.data.data?.deliverableDocuments?.length
                ? { label: "Choose a template option", value: "Choose a template option" }
                : { label: "Blank", value: "blank" },
              deliverableIsTemplate: response?.data.data?.deliverableIsTemplate ? response.data.data.deliverableIsTemplate : "",
              deliverablePaywallSetting: response?.data.data?.deliverablePaywallSetting?.length > 0 ? "YES" : "NO",
              deliverableIsPopularReport: response.data.data?.deliverableIsPopularReport && response.data.data.deliverableIsPopularReport,
              exportAsPdf: response.data.data.deliverableSetting?.exportAsPdf === "Yes" ? true : false,
              exportAsPpt: response.data.data.deliverableSetting?.exportAsPpt === "Yes" ? true : false,
              exportAsExcel: response.data.data.deliverableSetting?.exportAsExcel === "Yes" ? true : false,
              exportASection: response.data.data.deliverableSetting?.exportASection === "Yes" ? true : false,
              exportAsChart: response.data.data.deliverableSetting?.exportAsChart === "Yes" ? true : false,
              pdfText: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.text
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.text
                : "",
              pdfFontFamily: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.fontFamily
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.fontFamily
                : "",
              pdfFontSize: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.fontSize
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.fontSize
                : "",
              pdfColor: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.color
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.color
                : "",
              pdfOpacity: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.opacity
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.opacity === 0
                  ? 0
                  : response.data.data.deliverableSetting.pdfWatermarkSettings.opacity
                : "",
              pdfTopPosition: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.topPosition
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.topPosition
                : 0,
              pdfLeftPosition: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.leftPosition
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.leftPosition
                : 0,
              pdfRotate: response.data.data?.deliverableSetting?.pdfWatermarkSettings?.rotate
                ? response.data.data.deliverableSetting.pdfWatermarkSettings.rotate
                : 0,
              excelText: response.data.data?.deliverableSetting?.excelWatermarkSettings?.text
                ? response.data.data.deliverableSetting.excelWatermarkSettings.text
                : "",
              excelFontFamily: response.data.data?.deliverableSetting?.excelWatermarkSettings?.fontFamily
                ? response.data.data.deliverableSetting.excelWatermarkSettings.fontFamily
                : "",
              excelFontSize: response.data.data?.deliverableSetting?.excelWatermarkSettings?.fontSize
                ? response.data.data.deliverableSetting.excelWatermarkSettings.fontSize
                : "",
              excelColor: response.data.data?.deliverableSetting?.excelWatermarkSettings?.color
                ? response.data.data.deliverableSetting.excelWatermarkSettings.color
                : "",
              excelOpacity: response.data.data?.deliverableSetting?.excelWatermarkSettings?.opacity
                ? response.data.data.deliverableSetting.excelWatermarkSettings.opacity === 0
                  ? 0
                  : response.data.data.deliverableSetting.excelWatermarkSettings.opacity
                : "",
              excelTopPosition: response.data.data?.deliverableSetting?.excelWatermarkSettings?.topPosition
                ? response.data.data.deliverableSetting.excelWatermarkSettings.topPosition
                : 0,
              excelLeftPosition: response.data.data?.deliverableSetting?.excelWatermarkSettings?.leftPosition
                ? response.data.data.deliverableSetting.excelWatermarkSettings.leftPosition
                : 0,
              excelRotate: response.data.data?.deliverableSetting?.excelWatermarkSettings?.rotate
                ? response.data.data.deliverableSetting.excelWatermarkSettings.rotate
                : 0,
              powerPointText: response.data.data?.deliverableSetting?.pptWatermarkSettings?.text
                ? response.data.data.deliverableSetting.pptWatermarkSettings.text
                : "",
              powerPointFontFamily: response.data.data?.deliverableSetting?.pptWatermarkSettings?.fontFamily
                ? response.data.data.deliverableSetting.pptWatermarkSettings.fontFamily
                : "",
              powerPointFontSize: response.data.data?.deliverableSetting?.pptWatermarkSettings?.fontSize
                ? response.data.data.deliverableSetting.pptWatermarkSettings.fontSize
                : "",
              powerPointColor: response.data.data?.deliverableSetting?.pptWatermarkSettings?.color
                ? response.data.data.deliverableSetting.pptWatermarkSettings.color
                : "",
              powerPointOpacity: response.data.data?.deliverableSetting?.pptWatermarkSettings?.opacity
                ? response.data.data.deliverableSetting.pptWatermarkSettings.opacity === 0
                  ? 0
                  : response.data.data.deliverableSetting.pptWatermarkSettings.opacity
                : "",
              powerPointTopPosition: response.data.data?.deliverableSetting?.pptWatermarkSettings?.topPosition
                ? response.data.data.deliverableSetting.pptWatermarkSettings.topPosition
                : 0,
              powerPointLeftPosition: response.data.data?.deliverableSetting?.pptWatermarkSettings?.leftPosition
                ? response.data.data.deliverableSetting.pptWatermarkSettings.leftPosition
                : 0,
              powerPointRotate: response.data.data?.deliverableSetting?.pptWatermarkSettings?.rotate
                ? response.data.data.deliverableSetting.pptWatermarkSettings.rotate
                : 0,
              chartsText: response.data.data?.deliverableSetting?.chartWatermarkSettings?.text
                ? response.data.data.deliverableSetting.chartWatermarkSettings.text
                : "",
              chartsFontFamily: response.data.data?.deliverableSetting?.chartWatermarkSettings?.fontFamily
                ? response.data.data.deliverableSetting.chartWatermarkSettings.fontFamily
                : "",
              chartsFontSize: response.data.data?.deliverableSetting?.chartWatermarkSettings?.fontSize
                ? response.data.data.deliverableSetting.chartWatermarkSettings.fontSize
                : "",
              chartsColor: response.data.data?.deliverableSetting?.chartWatermarkSettings?.color
                ? response.data.data.deliverableSetting.chartWatermarkSettings.color
                : "",
              chartsOpacity: response.data.data?.deliverableSetting?.chartWatermarkSettings?.opacity
                ? response.data.data.deliverableSetting.chartWatermarkSettings.opacity === 0
                  ? 0
                  : response.data.data.deliverableSetting.chartWatermarkSettings.opacity
                : "",
              chartsTopPosition: response.data.data?.deliverableSetting?.chartWatermarkSettings?.topPosition
                ? response.data.data.deliverableSetting.chartWatermarkSettings.topPosition
                : 0,
              chartsLeftPosition: response.data.data?.deliverableSetting?.chartWatermarkSettings?.leftPosition
                ? response.data.data.deliverableSetting.chartWatermarkSettings.leftPosition
                : 0,
              chartsRotate: response.data.data?.deliverableSetting?.chartWatermarkSettings?.rotate
                ? response.data.data.deliverableSetting.chartWatermarkSettings.rotate
                : 0,
              sectionText: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.text
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.text
                : "",
              sectionFontFamily: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.fontFamily
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.fontFamily
                : "",
              sectionFontSize: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.fontSize
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.fontSize
                : "",
              sectionColor: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.color
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.color
                : "",
              sectionOpacity: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.opacity
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.opacity === 0
                  ? 0
                  : response.data.data.deliverableSetting.sectionWatermarkSettings.opacity
                : "",
              sectionTopPosition: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.topPosition
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.topPosition
                : 0,
              sectionLeftPosition: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.leftPosition
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.leftPosition
                : 0,
              sectionRotate: response.data.data?.deliverableSetting?.sectionWatermarkSettings?.rotate
                ? response.data.data.deliverableSetting.sectionWatermarkSettings.rotate
                : 0,
              deliverablePublishedDate: response.data.data?.deliverablePublishedDate || new Date(),
              deliverablePowerbiReportId: response.data.data?.deliverablePowerbiReportId ? response.data.data.deliverablePowerbiReportId : "",
              deliverablePowerbiReportName: response.data.data?.deliverablePowerbiReportName ? response.data.data.deliverablePowerbiReportName : "",
              deliverablePowerbiOriginalReportName: response.data.data?.deliverablePowerbiOriginalReportName
                ? response.data.data.deliverablePowerbiOriginalReportName
                : "",
              deliverablePowerbiReportUploadedDate: response.data.data?.deliverablePowerbiReportUploadedDate
                ? response.data.data.deliverablePowerbiReportUploadedDate
                : "",
              deliverableOldPowerbiReportId: "",
              deliverableOldPowerbiReportName: "",
              deliverableSignalTooltip: response.data.data?.deliverableSignalTooltip ? response.data.data.deliverableSignalTooltip : "",
              deliverableSignalIndicator: response.data.data?.deliverableSignalIndicator ? response.data.data.deliverableSignalIndicator : "",
              deliverableSignalRelatedContentType: response.data.data?.deliverableSignalRelatedContentType
                ? response.data.data.deliverableSignalRelatedContentType
                : "",
            };
            const tasksWriter = response.data.data.deliverableTaskWriters ? response.data.data.deliverableTaskWriters.map((a) => a.userId) : [];
            const taskPublisher = response.data.data.deliverableTaskPublishers
              ? response.data.data.deliverableTaskPublishers.map((a) => a.userId)
              : [];
            const taskaskReviewer = response.data.data.deliverableTaskReviewers
              ? response.data.data.deliverableTaskReviewers.map((a) => a.userId)
              : [];
            setTaskWriter([...tasksWriter, ...taskPublisher, ...taskaskReviewer]);
            if (response.data.data.deliverableProfileImage && response.data.data.deliverableProfileImage !== "") {
              setDeliverableImageSrc(fetchDeliverableImageUrl(response.data.data.deliverableProfileImage));
            }
            if (response.data.data.deliverableSignalRelatedContentThumbnail && response.data.data.deliverableSignalRelatedContentThumbnail !== "") {
              setDeliverableSignalRelatedContentImageSrcExternal(
                fetchDeliverableImageUrl(response.data.data.deliverableSignalRelatedContentThumbnail)
              );
              setRelatedContentSaved(true);
            }
            if (response.data.data?.deliverableSignalIndicatorColor)
              setSignalIndicatorColor({
                value: response.data.data?.deliverableSignalIndicatorColor,
                label: response.data.data?.deliverableSignalIndicatorColor,
              });
            if (response.data.data?.deliverableSignalRelatedContentReportId) {
              setSelectedRelatedContentReportId(response.data.data?.deliverableSignalRelatedContentReportId);
              setSelectedRelatedContentReportIdExisting(response.data.data?.deliverableSignalRelatedContentReportId);
              setRelatedContentSaved(true);
            }
            setDeliverableInformation({
              ...deliverableInformation,
              ...deliverableData,
            });
            formikRelatedContentExternal.setValues({
              deliverableSignalRelatedContentTitle: response.data.data?.deliverableSignalRelatedContentTitle
                ? response.data.data.deliverableSignalRelatedContentTitle
                : "",
              deliverableSignalRelatedContentSummary: response.data.data?.deliverableSignalRelatedContentSummary
                ? response.data.data.deliverableSignalRelatedContentSummary
                : "",
              deliverableSignalRelatedContentUrl: response.data.data?.deliverableSignalRelatedContentUrl
                ? response.data.data.deliverableSignalRelatedContentUrl
                : "",
              deliverableSignalRelatedContentPublishedDate: response.data.data?.deliverableSignalRelatedContentPublishedDate || new Date(),
            });
            if (response?.data?.data?.deliverableSignalRelatedContentType === "Report") {
              const signalReport = fetchSingleDeliverable(response?.data?.data?.deliverableSignalRelatedContentReportId);
              signalReport.then(async (res) => {
                setDeliverableSignalContent({
                  deliverableSignalName: res?.data?.data?.deliverableName,
                  deliverableSignalContent: res?.data?.data?.deliverableContentType,
                  deliverableSignalTags: res?.data?.data?.deliverableIndustryTrendsTags,
                  deliverableSignalReadTime: res?.data?.data?.deliverableReadTime,
                  deliverableSignalDate: res?.data?.data?.deliverablePublishedDate,
                });
              });
              setExistingRelatedContentType("Report");
            }
            if (response?.data?.data?.deliverableSignalRelatedContentType === "External Link") {
              setDeliverableSignalContent({
                deliverableSignalName: response?.data?.data?.deliverableSignalRelatedContentTitle,
                deliverableSignalContent: "",
                deliverableSignalTags: [],
                deliverableSignalReadTime: "",
                deliverableSignalDate: response?.data?.data?.deliverableSignalRelatedContentPublishedDate,
              });
              setExistingRelatedContentType("External Link");
            }
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const handleSubmit = () => {
    dispatch(
      showAlertBox({
        okCallback: () => {
          const deliverableData = {
            deliverableStatus: "Submitted",
            userId: userId._id,
            productVariant: productVariant,
          };
          setDeliverableStatus("Submitted");
          updateStatusSubmit(deliverableId, deliverableData)
            .then((res) => {
              if (res.status === 200) {
                setShowReportRemark(false);
                dispatch(actionSuccess("Submitted Successfully"));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to Submit?",
        title: "dialogAlertCss",
      })
    );
  };
  const handleApprove = () => {
    dispatch(
      showAlertBox({
        okCallback: () => {
          const deliverableData = {
            deliverableStatus: "Approved",
            userId: userId._id,
            productVariant: productVariant,
          };
          setDeliverableStatus("Approved");
          updateStatusApprove(deliverableId, deliverableData)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Approved Successfully"));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        okText: "Approve",
        cancelText: "Cancel",
        content: "Are you sure, you want to Approve?",
        title: "dialogAlertCssApprove",
      })
    );
  };

  const handleReject = () => {
    dispatch(
      showAlertBox({
        okCallback: () => {
          const deliverableData = {
            deliverableStatus: "Rejected",
            userId: userId._id,
            productVariant: productVariant,
          };
          setDeliverableStatus("Rejected");
          updateStatusReject(deliverableId, deliverableData)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Reject Successfully"));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            })
            .catch((e) => {
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong!"));
              }
            });
        },
        okText: "Reject",
        cancelText: "Cancel",
        content: "Are you sure you want to Reject?",
        title: "dialogAlertCssWarning",
      })
    );
  };
  const dispatch = useDispatch();
  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (deliverableId !== "") {
      if (props.location.hash && selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.select(0);
    }
  };
  const handleLoggingStatus = (status) => {
    setLogStatus(status);
  };
  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);
    tabRef.current.enableTab(4, true);
    tabRef.current.select(0);
  };
  /*
    @Description : Set active link on sidebar for news
    */

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/deliverable"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, deliverableDocuments, props.location.hash, selectedIndex, deliverableName, deliverableDocuments, breadCrumbLinks]);

  useEffect(() => {
    if (selectedIndex != 2) {
      [...window.document.querySelectorAll(".moveable-control")].map((a) => {
        a.style.display = "none";
        a.classList.add("remove-dots");
      });
    }
  }, [selectedIndex]);
  /*
    @Description : Retrieve respective deliverable data
  */
  useEffect(() => {
    if (isPublishClicked) handleSave(true);
    if (deliverableId) {
      const response = fetchSingleDeliverable(deliverableId, {
        fields: [
          "deliverableName",
          "deliverableType",
          "deliverableContentType",
          "deliverableDocuments",
          "deliverableContentStatus",
          "deliverableProductPlatform",
        ],
      });
      response
        .then((response) => {
          if (response.status == 200) {
            setDeliverableName(response.data.data.deliverableName);
            setDeliverableDocuments(response.data.data?.deliverableDocuments);
            setDeliverableProductPlatform(response.data.data.deliverableProductPlatform);
            if (
              response.data.data.deliverableProductPlatform.includes("Insider V1") ||
              response.data.data.deliverableProductPlatform.includes("WhatNext V1")
            ) {
              tabRef.current.enableTab(1, false);
              tabRef.current.enableTab(2, false);
              tabRef.current.enableTab(3, false);
            } else {
              tabRef.current.enableTab(1, true);
              tabRef.current.enableTab(2, true);
              if (isAdmin() ? true : accessAdministrator || selectedIndex === 2) {
                tabRef.current.enableTab(2, true);
              } else {
                tabRef.current.enableTab(2, false);
              }
              if (response.data.data.deliverableContentType === CONTENT_TYPE.DASHBOARD) {
                tabRef.current.enableTab(1, false);
                tabRef.current.enableTab(2, false);
                tabRef.current.enableTab(3, false);
              } else if (
                response.data.data.deliverableContentType === CONTENT_TYPE.SIGNALS &&
                response.data.data.deliverableType === DELIVERABLE_TYPE.SIGNALS
              ) {
                tabRef.current.enableTab(1, false);
                tabRef.current.enableTab(2, false);
                tabRef.current.enableTab(3, false);
              }
            }

            setDeliverableType(response.data.data?.deliverableType);
            setDeliverableContentType(response.data.data?.deliverableContentType);
            setDeliverableStatus(response.data.data?.deliverableContentStatus ? response.data.data?.deliverableContentStatus : "");
            if (
              response.data.data?.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE &&
              response.data.data?.deliverableDocuments &&
              response.data.data?.deliverableDocuments.length > 0
            ) {
              tabRef.current.enableTab(2, false);
            }
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  }, [deliverableId, saveDeliverable, isPublishClicked, accessAdministrator, accessPublisher, accessReviewer, accessWriter]);

  /*
    @Description : Redirect to edit page after adding news overview (Applicable only for add new news)
  */
  const handleRedirectOnAddDeliverable = (addDeliverableId) => {
    history.push(`/deliverable/${addDeliverableId}/edit#basic`);
    tabRef.current.enableTab(1, true);
  };
  const handleTabRef = (index) => {
    tabRef.current.select(index);
    tabRef.current.enableTab(index, true);
    setSelectedIndex(index);
  };

  const handleTaskId = (task) => {
    setTaskId(task);
  };
  const updateLogData = (activityLogId, activityLogData) => {
    if (isAdmin() ? true : accessAdministrator && !(accessWriter || accessReviewer || accessPublisher)) {
    } else {
      let localLogTime = 10;
      if (logTime) {
        localLogTime = logTime;
      }
      timer = setInterval(() => {
        let localLogData = localStorage.getItem(`localLogData${taskId}`);
        if (!localLogData) {
          activityLogData["log_date_time"] = new Date();
          activityLogData["activity_duration_time"] = 0;
          activityLogData["activity_last_update_time"] = new Date();
          localStorage.setItem(`localLogData${taskId}`, JSON.stringify(activityLogData));
        } else {
          let localData = JSON.parse(localLogData);
          let { activity_duration_time } = localData;
          activity_duration_time = activity_duration_time + localLogTime;
          activityLogData["log_date_time"] = new Date();
          activityLogData["activity_duration_time"] = activity_duration_time;
          activityLogData["activity_last_update_time"] = new Date();
          localStorage.setItem(`localLogData${taskId}`, JSON.stringify(activityLogData));
        }
        if (activityLogId) updateLog(activityLogId, activityLogData);
      }, localLogTime * 1000);
    }
  };

  const saveContentDesignOnTabChange = async () => {
    try {
      dispatch(showLoader());
      let reportPageId = sessionStorage.getItem("pageeId");
      if (reportPageId && preservedHtml) {
        let pageContentHtmlData = new FormData();
        pageContentHtmlData.append("pageCreatedBy", userId._id);
        pageContentHtmlData.append("pageContentHtml", preservedHtml);
        await updatePageContent(deliverableId, reportPageId, pageContentHtmlData);
      }
      setSaveDeliverable(false);
      dispatch(hideLoader());
      dispatch(actionSuccess("Content Updated successfully"));
    } catch (err) {
      dispatch(actionError("Error while updating the content: " + err.data.message));
    }
  };

  const selecting = async () => {
    let reportPageId = sessionStorage.getItem("pageeId");
    if (tabRef.current.selectedID == 2 && reportPageId !== null && contentTabAccess) {
      await saveContentDesignOnTabChange();
    } else {
      setSaveDeliverable(false);
    }
    if (tabRef.current.selectedID !== 2) {
      setShowSave(true);
    }
  };

  /*Description: Fetch content blocks */
  const fetchContentBlocks = async (DeliverableTpye) => {
    let params = {};
    params.filters = [
      ["contentBlockContentType", "neq", "Email Template"],
      ["contentBlockContentType", "neq", "Signal"],
      ["contentBlockStatus", "eq", "Published"],
      ["productVariant.productName", "eq", productVariant],
    ];
    await fetchContentBlock(params).then(async (res) => {
      dispatch(showLoader());
      if (res.status === 200) {
        let categoryIds = res.data.data.map((data) => {
          return data.contentBlockCategoryId;
        });
        let uniqueArry = [...new Set([...categoryIds])];

        let configParams = {};
        configParams.filters = [["configName", "eq", "content_builder_category"]];
        configParams.sort = "configValue.categoryName:asc";
        await fetchConfig(configParams).then((resp) => {
          if (resp.status === 200) {
            let result = [];
            let blockListArry = resp.data.data[0].configValue;
            blockListArry.sort((a, b) => (a.categoryName > b.categoryName ? 1 : b.categoryName > a.categoryName ? -1 : 0));
            blockListArry.map((val) => {
              if (uniqueArry.includes(val.categoryId)) {
                result.push([parseInt(val.categoryId), val.categoryName]);
              }
            });

            if (uniqueArry.length < 1) result.push([100, "No blocks available"]);
            result.length > 0 && dispatch(hideLoader());
            setSnippetList(result);
          }
        });
      }
    });
  };

  // ** Fetching Industry Data and userCompentency for use in plugin**
  const fetchIndustry = async () => {
    if (productVariant !== "Insider") return;
    await fetchTaxonomyTrend({
      fields: ["id", "trendName", "trendParentId"],
      filters: [["trendParentId", "eq", 0]],
      limit: 2000,
    })
      .then((res) => {
        setIndustryData(res.data.data || []);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchUser = async () => {
    if (productVariant !== "Insider") return;
    await fetchSingleUser(user._id)
      .then((res) => {
        setUserCompentencyIndustry(res?.data?.data?.userCompentencyIndustry || []);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUser();
    fetchIndustry();
  }, []);

  useEffect(() => {
    if (userId.user_role === ADMIN_USER) return setContentTabAccess(true);
    setContentTabAccess(taskWriter.includes(userId._id));
  }, [taskWriter]);

  const interfaces = ["Basic", "Task", "Content", "Setting", "Taxonomy"];
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, interfaces);

  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        selecting={selecting}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
          clearInterval(timer);

          if (isAdmin() ? false : tabRef.current.selectedItem !== 2 && !accessAdministrator) {
            tabRef.current.enableTab(2, false);
          }
        }}
        cssClass="newHeaderTabCss"
      >
        <Suspense
          fallback={
            <div className="text-center">
              <h3>Loading...</h3>
            </div>
          }
        >
          <RenderUtility
            creatorAccess={accessWriter}
            reviewerAccess={accessReviewer}
            newRecord={newRecord}
            activeStepper={Object.keys(hashArr)[selectedIndex]}
            deliverableId={deliverableId}
            pageId={pageId}
            templateId={templateId}
            handleSave={handleSave}
            saveDeliverable={selectedIndex == 2 ? (sessionStorage.getItem("pageeId") && preservedHtml ? saveDeliverable : false) : saveDeliverable}
            handleRedirectOnAddDeliverable={handleRedirectOnAddDeliverable}
            selectedIndex={selectedIndex}
            accessAdministrator={accessAdministrator}
            deliverableContentStatus={deliverableStatus}
            deliverableName={deliverableName}
            tabRef={tabRef}
            handleTabRef={handleTabRef}
            handleLoggingStatus={handleLoggingStatus}
            logStatus={logStatus}
            handleTaskId={handleTaskId}
            setTaskId={setTaskId}
            taskId={taskId}
            updateLogData={updateLogData}
            setLogStatus={setLogStatus}
            logTime={logTime}
            setLogTime={setLogTime}
            timer={timer}
            showReportRemark={showReportRemark}
            setShowReportRemark={setShowReportRemark}
            props={props}
            contentPermission={contentPermission}
            setContentPermission={setContentPermission}
            setSaveDeliverable={setSaveDeliverable}
            snippentList={snippentList}
            deliverableType={deliverableType}
            deliverableContentType={deliverableContentType}
            deliverableInformation={deliverableInformation}
            setDeliverableInformation={setDeliverableInformation}
            formik={formik}
            formikRelatedContentExternal={formikRelatedContentExternal}
            deliverableDefaultImageRef={deliverableDefaultImageRef}
            externalDefaultImageRef={externalDefaultImageRef}
            deliverableImageSrc={deliverableImageSrc}
            setDeliverableImageSrc={setDeliverableImageSrc}
            deliverableSignalRelatedContentImageSrc={deliverableSignalRelatedContentImageSrc}
            setDeliverableSignalRelatedContentImageSrc={setDeliverableSignalRelatedContentImageSrc}
            deliverableSignalRelatedContentImageSrcExternal={deliverableSignalRelatedContentImageSrcExternal}
            setDeliverableSignalRelatedContentImageSrcExternal={setDeliverableSignalRelatedContentImageSrcExternal}
            existingRelatedContentType={existingRelatedContentType}
            setExistingRelatedContentType={setExistingRelatedContentType}
            selectedRelatedContentReportId={selectedRelatedContentReportId}
            setSelectedRelatedContentReportId={setSelectedRelatedContentReportId}
            selectedRelatedContentReportIdExisting={selectedRelatedContentReportIdExisting}
            mostReadResearch={mostReadResearch}
            setMostReadResearch={setMostReadResearch}
            addToWidgets={addToWidgets}
            setAddToWidgets={setAddToWidgets}
            imageError={imageError}
            setImageError={setImageError}
            fetchDeliverable={fetchDeliverable}
            preserveContentHtml={preserveContentHtml}
            preservedHtml={preservedHtml}
            setShowSave={setShowSave}
            popularReport={popularReport}
            setPopularReport={setPopularReport}
            storeBlockData={storeBlockData}
            setStoreBlockData={setStoreBlockData}
            reRenderContent={reRenderContent}
            setReRenderContent={setReRenderContent}
            contentTabAccess={contentTabAccess}
            comingSoon={comingSoon}
            setComingSoon={setComingSoon}
            industriesData={industryData}
            userCompentencyIndustry={userCompentencyIndustry}
            setSnippetList={setSnippetList}
            selectedFileExternal={selectedFileExternal}
            setSelectedFileExternal={setSelectedFileExternal}
            signalIndicatorColor={signalIndicatorColor}
            setSignalIndicatorColor={setSignalIndicatorColor}
            relatedContentSaved={relatedContentSaved}
            setRelatedContentSaved={setRelatedContentSaved}
            deliverableSignalContent={deliverableSignalContent}
            setDeliverableSignalContent={setDeliverableSignalContent}
            interfaceAccess={accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex])}
            hashArr={hashArr}
            accessPermissionTaxonomy={accessActionTaxonomyAdd}
            accessPermissionTaxonomyDelete={accessActionTaxonomyDelete}
            accessActionTaxonomyAddTags={accessActionTaxonomyAddTags}
            accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
            accessActionTaskAdd={accessActionTaskAdd}
            accessActionTaskEditTask={accessActionTaskEditTask}
            accessActionTaskEditContent={accessActionTaskEditContent}
            accessActionTaskDelete={accessActionTaskDelete}
            moduleName={aR.moduleName}
          />
        </Suspense>
      </Tabs>
      <FooterBar
        deliverableContentStatus={deliverableStatus}
        handleSubmit={handleSubmit}
        handleApprove={handleApprove}
        handleReject={handleReject}
        deliverableType={deliverableType}
        deliverableContentType={deliverableContentType}
        deliverableDocuments={deliverableDocuments}
        handleSave={handleSave}
        deliverableId={deliverableId}
        activeStepper={Object.keys(hashArr)[selectedIndex]}
        pageId={pageId}
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        contentPermission={contentPermission}
        showSave={showSave}
        contentTabAccess={contentTabAccess}
        fetchDeliverable={fetchDeliverable}
        tabsName={TABS_NAME}
        moduleName={aR.moduleName}
        hashArr={hashArr}
      />
    </div>
  );
};

export default Add;
