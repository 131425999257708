/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : External User Profile
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef, useCallback } from "react";
import _, { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Tabs from "../../../components/tabsComponent";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Experience from "./experience";
import Education from "./education";
import Accordion from "../../../components/accordion";
import {
  TextField,
  NumericTextField,
  TextEditor,
  DatePicker,
  Dropdown,
  AutoCompleteDropdown,
  MultiSelect,
  RadioGroup,
} from "../../../components/CustomFormElements/syncFusionFormFields";
import { ckEditorMinimalConfig } from "../../../../config/ckEditorConfig";
import { addUserValidationSchema, editUserValidationSchema } from "../../../helper/validationSchema/externalUserValidationSchema";
import {
  addUser,
  updateUser,
  fetchSingleUser,
  fetchUserProfileImageUrl,
  sendLoginCredentialsOutsourcing,
} from "../../../../middleware/services/userApi";
import { fetchKias } from "../../../../middleware/services/kiaApi";
import {
  fetchVendor,
  fetchTaxonomyTechnology,
  fetchTaxonomyTrend,
  fetchTaxonomyIndustry,
  fetchTeam,
  fetchLanguages,
  fetchFunctionalExpertiseData,
  fetchCountry,
} from "../../../../middleware/services/cmsApi";
import {
  INTERNET_SPEED,
  TIME_AVAILABILITY,
  USER_ROLES,
  EMPANELMENT_LIST,
  PRODUCT_VARIANT,
  CURRENCY,
  COMMON_ACCESS_ACTION,
} from "../../../../constants";
import userImage from "../../../../assets/images/user.jpg";
import { getLoggedInUser, isAdmin, getProductVariant, accessRightInterfaceCheck, accessRightActionCheck } from "../../../../utilities";
import RegistrationDetails from "./registrationDtls";
import Assessment from "./assessment";
import AccessDenied from "../../../components/accessDenied";
const TABS_NAME = ["User Profile", "Registration Details", "Assessment"];

const initialValues = {
  userFname: "",
  userLname: "",
  userEmail: "",
  userMobile: "",
  userContactTelNo: "",
  userAbout: "",
  userPassword: "",
  userConfirmPassword: "",
  twitter: "",
  linkedin: "",
  facebook: "",
  country: "",
  addressLine: "",
  areaName: "",
  region: "",
  city: "",
  state: "",
  postCode: "",
  addressType: "",
  userDesignation: "",
  userRole: "",
  userEmpaneledBy: "",
  userWorkTimeAvailability: "",
  userInternetConnectionAvailability: "",
  userActivationDate: "",
  userTotalExperience: "",
  userVendorName: "",
  userFunctionalExpertise: [],
  userCompentencyTechnology: [],
  userCompentencyIndustry: [],
  userType: "",
  researchPapersAuthored: "",
  videoConsultingCompleted: "",
  surveyCompleted: "",
  workshopCompleted: "",
  userExpertRating: "",
  bankName: "",
  bankAccountNo: "",
  payPalId: "",
  currency: "",
  bankPaymentDetails: "",
  userActive: undefined,
};

const UserProfile = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const history = useHistory();
  const userTokenDetails = getLoggedInUser();
  const productVariant = getProductVariant();
  const sessonId = userTokenDetails._id;
  const editUser = props.path === "/external-user/add" ? false : true;
  const isMultipleProductVariant = userTokenDetails.product_variant?.length > 1 ? true : false;
  const userId = editUser ? props.computedMatch?.params?.userId : props.userId || "";

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [kiaDataSource, setKiaDataSource] = useState([]);
  const [kiaDatas, setKiaDatas] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessPermission = true;
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionSendLoginDetails = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEND_LOGIN_DETAILS);
  const accessActionEditProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_PROFILE_IMAGE);
  const accessActionDeleteProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_PROFILE_IMAGE);
  const accessActionEditExperience = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_EXPERIENCE);
  const accessActionDeleteExperience = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_EXPERIENCE);
  const accessActionAddExperience = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_EXPERIENCE);
  const accessActionEditEducation = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_EDUCATION);
  const accessActionDeleteEducation = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_EDUCATION);
  const accessActionAddEducation = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_EDUCATION);

  const hashArr = {
    "#profile": "User Profile",
    "#registration": "Registration Details",
    "#assessment": "Assessment",
  };

  const profileAccessId = props?.computedMatch?.params?.userId;
  const pathArr = props?.path.split("/");
  const pathname = pathArr[pathArr.length - 1];
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [existingUser, setExistingUser] = useState({});
  const [teams, setTeams] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const [userProfileImgSrc, setUserProfileImgSrc] = useState(undefined);
  const [technologies, setTechnologies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [functionalExpertise, setFunctionalExpertise] = useState([]);
  const [trends, setTrends] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [trendsMaster, setTrendsMaster] = useState([]);
  const [userVendorId, setUserVendorId] = useState("");

  if (isAdmin() || isMultipleProductVariant) {
    initialValues.productVariant = "";
    editUserValidationSchema.productVariant = Yup.array().min(1, "Product Variant field must have at least 1 item");
    addUserValidationSchema.productVariant = Yup.array()
      .min(1, "Product Variant field must have at least 1 item")
      .required("Product variant is required");
  } else {
    initialValues.productVariant = [getProductVariant()];
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editUser
      ? Yup.object().shape({ ...editUserValidationSchema })
      : Yup.object().shape({
          ...addUserValidationSchema,
          userRole: Yup.string().required("User role is required"),
        }),
  });

  const getKia = async () => {
    let filters = [["kiaIsActive", "eq", "Yes"]];
    if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
    let fields = { fields: ["kiaName", "kiaIndustryTag"] };
    const response = await fetchKias({ filters: filters, ...fields, sort: "kiaName:asc", limit: 2000 });
    if (response?.data && response?.data?.data) {
      setKiaDatas(response.data.data);
      const resData = response.data.data.map((v) => {
        return { label: v.kiaName, value: v.id, parent: v.kiaIndustryTag[0].industryName };
      });
      if (resData) {
        setKiaDataSource(resData);
      }
    }
  };
  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = () => {
    dispatch(showLoader());
    userId &&
      fetchSingleUser(userId)
        .then((res) => {
          if (res && res?.data) {
            const userDetail = { ...res.data.data };
            if (userDetail?.userActivationDate) userDetail.userActivationDate = moment(userDetail.userActivationDate).format("DD-MMM-yyyy");
            if (userDetail?.userProfileImage && userDetail.userProfileImage !== "") {
              setUserProfileImgSrc(fetchUserProfileImageUrl(userDetail.userProfileImage));
              setIsDefaultImage(false);
            } else {
              setIsDefaultImage(true);
            }

            userDetail.equipedWithLaptop && setChecked(userDetail.equipedWithLaptop === "yes" ? true : false);

            setExistingUser(userDetail);

            formik.setValues({
              // ...userDetail,
              userCompany: userDetail?.userCompany || "",
              userFname: userDetail?.userFname || "",
              userLname: userDetail?.userLname || "",
              userEmail: userDetail?.userEmail || "",
              userMobile: userDetail?.userMobile || "",
              userContactTelNo: userDetail?.userContactTelNo || "",
              userAbout: userDetail?.userAbout || "",
              userDesignation: userDetail?.userDesignation || "",
              twitter: userDetail?.userSocial?.twitter || "",
              linkedin: userDetail?.userSocial?.linkedin || "",
              facebook: userDetail?.userSocial?.facebook || "",
              addressLine: userDetail?.userAddress?.addressLine || "",
              country: userDetail?.userAddress?.country || "",
              state: userDetail?.userAddress?.state || "",
              city: userDetail?.userAddress?.city || "",
              areaName: userDetail?.userAddress?.areaName || "",
              postCode: userDetail?.userAddress?.postCode || "",
              region: userDetail?.userAddress?.region || "",
              addressType: userDetail?.userAddress?.addressType || "",
              userRole: userDetail?.userRole,
              userEmpaneledBy: userDetail.userEmpaneledBy,
              userWorkTimeAvailability: userDetail.userWorkTimeAvailability || "",
              userActivationDate: userDetail.userActivationDate || "",
              userTotalExperience: userDetail.userTotalExperience || "",
              userVendorName: userDetail.userVendorName || "",
              userInternetConnectionAvailability: userDetail.userInternetConnectionAvailability || "",
              researchPapersAuthored: userDetail?.userServicesStatus?.researchPapersAuthored || 0,
              videoConsultingCompleted: userDetail?.userServicesStatus?.videoConsultingCompleted || 0,
              surveyCompleted: userDetail?.userServicesStatus?.surveyCompleted || 0,
              workshopCompleted: userDetail?.userServicesStatus?.workshopCompleted || 0,
              userExpertRating: userDetail?.userExpertRating || "",
              bankName: userDetail?.userBankDetails?.bankName || "",
              bankAccountNo: userDetail?.userBankDetails?.bankAccountNo || "",
              payPalId: userDetail?.userBankDetails?.payPalId || "",
              currency: userDetail?.userBankDetails?.currency || "",
              bankPaymentDetails: userDetail?.userBankDetails?.bankPaymentDetails || "",
            });

            formik.setFieldValue(
              "productVariant",
              userDetail?.productVariant?.map((v) => {
                return v.productName;
              })
            );

            formik.setFieldValue(
              "userCompentencyTechnology",
              userDetail?.userCompentencyTechnology?.map((v) => {
                return v.dtId;
              })
            );
            if (userDetail?.userCompentencyIndustry?.length > 0) {
              let userCompentencyIndustryTags = [];
              userDetail.userCompentencyIndustry.forEach((uCI) => {
                if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
                  userCompentencyIndustryTags.push({ id: uCI.industryId, industryName: uCI.industryName });
                } else {
                  userCompentencyIndustryTags.push({ id: uCI.industryId, trendName: uCI.industryName });
                }
              });
              userDetail.userCompentencyIndustry = userCompentencyIndustryTags;
            }

            userDetail?.userCompentencyIndustry &&
              formik.setFieldValue(
                "userCompentencyIndustry",
                userDetail?.userCompentencyIndustry?.map((v) => {
                  return v.id;
                })
              );

            formik.setFieldValue("userLanguageExpertise", (userDetail?.userLanguageExpertise && [...userDetail.userLanguageExpertise]) || []);

            formik.setFieldValue(
              "userFunctionalExpertise",
              userDetail?.userFunctionalExpertise &&
                userDetail?.userFunctionalExpertise?.map((f) => {
                  return f?.experty;
                }),
              []
            );

            if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
              formik.setFieldValue(
                "userKiaExpertise",
                userDetail?.userKiaExpertise?.map((f) => {
                  return f.kiaId;
                })
              );
            }

            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          console.log("err==", err);
          let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
          dispatch(actionError(errMsg));
        });
  };

  const searchVendor = useCallback(
    debounce(async (e) => {
      let filters = [];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["vendorName", "cn", e.text]);
      let fields = { fields: ["vendorName"] };
      const response = e.text.length > 1 && (await fetchVendor({ filters: filters, ...fields, sort: "vendorName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { label: v.vendorName, value: v.id };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  const handleVendorData = (data) => {
    data && setUserVendorId(data.itemData.value);
  };

  /**
   * @description fetch functional Expertise
   */
  const fetchFunctionalExpertise = () => {
    fetchFunctionalExpertiseData({ sort: "parent:asc,expertise:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setFunctionalExpertise(data.map((d) => ({ experty: d.expertise, parent: d.parent })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Functional Expertise Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch language Expertise
   */
  const fetchLanguageExpertise = () => {
    fetchLanguages({ sort: "order:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setLanguages(data.map((d) => d.language));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Language Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch technologies to tag with user
   */
  const fetchTechnologies = () => {
    fetchTaxonomyTechnology({ filters: [["dtParentId", "eq", 0]], fields: ["id", "dtName"], sort: "dtName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTechnologies(data.map((d) => ({ dtId: d.id, dtName: d.dtName })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Technology Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
	/**
	 * @description fetch countries to tag with user
	 */

  const searchCountry = useCallback(
    debounce(async (e) => {
      let filters = [];
      filters.push(["countryName", "cn", e.text]);
      const response =
        e.text.length > 1 && (await fetchCountry({ filters: filters, fields: ["countryName", "region"], sort: "countryName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { country: v.countryName, region: v.region };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  /**
   * @description fetch trends to tag with user
   */
  const fetchTrends = () => {
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTrendsMaster(data);
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, trendName: sTT.trendParentName };
        });
        setTrends([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Trend Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch industry to tag with user
   */
  const fetchIndustry = () => {
    fetchTaxonomyIndustry({ fields: ["industryName"], sort: "industryName:asc", limit: 50 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.id] = { id: sTT.id, industryName: sTT.industryName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch teams for user product variant tagging (whatNext, Insider)
   */
  const fetchTeamsForUser = async () => {
    if (isAdmin()) {
      const res = await fetchTeam({ sort: "teamName:asc" });
      const { data = [] } = res.data;
      setTeams([...data.map((d) => ({ productName: d.teamName }))]);
    } else if (isMultipleProductVariant) {
      setTeams([...userTokenDetails.product_variant.map((p) => ({ productName: p.product_name }))]);
    }
  };

  /**
   * @description add new user
   * @param {Object} userData
   */
  const addNewUser = (userData) => {
    dispatch(actionStart());
    addUser(userData)
      .then((res) => {
        setUser({});
        if (formRef?.current?.querySelectorAll) {
          for (const formElem of formRef?.current?.querySelectorAll(".form-control")) {
            formElem.value = "";
          }
        }
        userProfileImgRef.current.setAttribute("src", userImage);
        dispatch(actionSuccess("User profile has been added"));
        history.push(`/external-user/${res.data.data.id}/edit#profile`);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter a valid email"
            : err?.data?.message
          : "Add new user failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description update existing user
   * @param {Object} userData
   */
  const updateExistingUser = (userData) => {
    dispatch(actionStart());
    updateUser(userId, userData)
      .then((res) => {
        dispatch(actionSuccess("User profile has been updated"));
        setUser({});
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter valid email"
            : err?.data?.message
          : "User profile updation failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description save user (new / existing) using form submit
   */
  const saveUser = () => {
    const userDetail = { ...formik.values };
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        let countryError = "Please enter country";
        formik.setFormikState({
          ...formik,
          touched: touched,
          errors: {
            ...res,
            country: countryError,
          },
        });
      } else {
        const user = { ...formik.values };

        let saveUserData = new FormData();
        if (user.userActivationDate) user.userActivationDate = moment(user.userActivationDate).format("DD-MMM-yyyy");
        for (let uKey in user) {
          if (user[uKey] && user[uKey] !== "") saveUserData.append(uKey, user[uKey]);
        }

        saveUserData.set(
          "userSocial",
          JSON.stringify({
            twitter: user.twitter,
            facebook: user.facebook,
            linkedin: user.linkedin,
          })
        );
        saveUserData.delete("twitter");
        saveUserData.delete("facebook");
        saveUserData.delete("linkedin");

        saveUserData.set(
          "userServicesStatus",
          JSON.stringify({
            researchPapersAuthored: user.researchPapersAuthored,
            videoConsultingCompleted: user.videoConsultingCompleted,
            surveyCompleted: user.surveyCompleted,
            workshopCompleted: user.workshopCompleted,
          })
        );
        saveUserData.delete("researchPapersAuthored");
        saveUserData.delete("videoConsultingCompleted");
        saveUserData.delete("surveyCompleted");
        saveUserData.delete("workshopCompleted");

        saveUserData.set(
          "userBankDetails",
          JSON.stringify({
            bankName: user.bankName,
            bankPaymentDetails: user.bankPaymentDetails,
            bankAccountNo: user.bankAccountNo,
            payPalId: user.payPalId,
            currency: user.currency,
          })
        );
        saveUserData.delete("bankName");
        saveUserData.delete("bankPaymentDetails");
        saveUserData.delete("bankAccountNo");
        saveUserData.delete("payPalId");
        saveUserData.delete("currency");

        saveUserData.set(
          "userAddress",
          JSON.stringify({
            addressLine: user.addressLine,
            country: user.country,
            state: user.state,
            city: user.city,
            areaName: user.areaName,
            postCode: user.postCode,
            region: user.region,
            addressType: user.addressType,
          })
        );
        saveUserData.delete("addressLine");
        saveUserData.delete("country");
        saveUserData.delete("state");
        saveUserData.delete("city");
        saveUserData.delete("areaName");
        saveUserData.delete("postCode");
        saveUserData.delete("region");
        saveUserData.delete("addressType");

        saveUserData.delete("userConfirmPassword");
        saveUserData.delete("userProfileImage");

        if (!editUser) {
          saveUserData.append("userType", "External");
        }
        if (
          (!editUser && _.isEmpty(user)) ||
          (editUser &&
            _.isEmpty(user) &&
            userProfileImgInputRef.current.files.length === 0 &&
            (!existingUser.userProfileImage ||
              (existingUser.userProfileImage && existingUser.userProfileImage !== "" && userProfileImgSrc !== undefined)))
        ) {
          return true;
        }
        if (user?.productVariant) {
          let productVariantTags = user?.productVariant?.map((v) => {
            return { productName: v };
          });
          saveUserData.set("productVariant", JSON.stringify(productVariantTags));
        }

        if (userDetail?.userCompentencyTechnology) {
          let userCompentencyTechnologyTags = [];
          technologies &&
            technologies.map((t) => {
              if ([...userDetail.userCompentencyTechnology].includes(t.dtId)) {
                userCompentencyTechnologyTags.push({
                  dtId: t.dtId,
                  dtName: t.dtName,
                });
              }
            });
          saveUserData.delete("userCompentencyTechnology");
          userCompentencyTechnologyTags && saveUserData.set("userCompentencyTechnology", JSON.stringify(userCompentencyTechnologyTags));
        }

        if (user?.userCompentencyIndustry) {
          let userCompentencyIndustryTags = [];
          if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
            industry &&
              industry.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  userCompentencyIndustryTags.push({
                    industryId: t.id,
                    industryName: t.industryName,
                  });
                }
              });
          } else {
            trends &&
              trends.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  trendsMaster
                    .filter((tM) => tM.trendParentId === t.id)
                    .forEach((tC) => {
                      userCompentencyIndustryTags.push({
                        industryId: t.id,
                        industryName: t.trendName,
                        trendId: tC.id,
                        trendName: tC.trendName,
                      });
                    });
                }
              });
          }
          saveUserData.delete("userCompentencyIndustry");
          saveUserData.set("userCompentencyIndustry", JSON.stringify(userCompentencyIndustryTags));
        }

        if (userDetail?.userFunctionalExpertise) {
          let userFunctionalExpertiseTags =
            userDetail?.userFunctionalExpertise?.length &&
            user?.userFunctionalExpertise?.map((v) => {
              return { experty: v };
            });
          saveUserData.set("userFunctionalExpertise", JSON.stringify(userFunctionalExpertiseTags));
        }
        if (userDetail?.userKiaExpertise) {
          let userKiaExpertises = userDetail?.userKiaExpertise?.length ? [...userDetail.userKiaExpertise] : [];
          const kiaData =
            kiaDatas &&
            kiaDatas.length &&
            kiaDatas
              .map((v, i) => {
                return (
                  [...userKiaExpertises].includes(v.id) && {
                    kiaId: v.id,
                    kiaName: v.kiaName,
                    ...v.kiaIndustryTag[0],
                  }
                );
              })
              .filter((f) => {
                return f !== false;
              });
          kiaData && kiaData.length && saveUserData.set("userKiaExpertise", JSON.stringify(kiaData));
        }

        if (userDetail.userLanguageExpertise) {
          let userLanguageExpertiseTags = userDetail?.userLanguageExpertise?.length ? [...userDetail.userLanguageExpertise] : [];
          saveUserData.set("userLanguageExpertise", JSON.stringify(userLanguageExpertiseTags));
        }

        if (userDetail.equipedWithLaptop) {
          saveUserData.set("equipedWithLaptop", checked ? "yes" : "no");
        }
        if (userVendorId) {
          saveUserData.set("userVendorId", userVendorId);
        }
        if (userProfileImgInputRef.current.files.length) {
          saveUserData.append("userProfileImage", userProfileImgInputRef.current.files[0]);
        } else {
          saveUserData.append("userProfileImage", "");
        }
        dispatch(
          showAlertBox({
            okCallback: editUser ? updateExistingUser : addNewUser,
            data: saveUserData,
            okText: editUser ? "Update" : "Add",
            cancelText: "Cancel",
            content: editUser ? "Are you sure, you want to update user details?" : "Are you sure, you want to add user details?",
            title: "dialogAlertCss",
          })
        );
      }
    });
  };

  /**
   * @param {File} imageFile
   * @description read & set image for user profile
   */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      userProfileImgRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };
  const sendLoginDetails = () => {
    sendLoginCredentialsOutsourcing(userId, { productVariant: productVariant })
      .then((res) => {
        dispatch(actionSuccess("User credentials sent successfully"));
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Some thing went wrong"));
      });
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (userId) {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.select(0);
    }
  };

  useEffect(() => {
    editUser && fetchUserDetail();
    fetchTechnologies();
    getKia();
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
      fetchIndustry();
    } else if (productVariant === PRODUCT_VARIANT.INSIDER) {
      fetchTrends();
    }
    fetchTeamsForUser();
    fetchFunctionalExpertise();
    fetchLanguageExpertise();
  }, [dispatch, selectedIndex, props.location.hash, userId]);

  /*
    @Description : Set active link on sidebar for news
  */
  useEffect(() => {
    if (!accessPermission && sessonId === profileAccessId && !["edit", "registration-detail"].includes(pathname)) {
      history.push(`/external-user/${userId}/edit/#profile`);
    }

    const breadCrumbLinks = [
      { linkUrl: "/external-user", linkName: aR.moduleName, linkActive: false },
      {
        linkUrl: userId ? `/external-user/${userId}/edit#profile` : `/external-user/add`,
        linkName:
          formik.values.userFname.length === 0 && formik.values.userLname.length === 0
            ? "Add New"
            : `${formik.values.userFname} ${formik.values.userLname}`,
        linkActive: false,
      },
      {
        linkUrl: `/external-user/${userId}/edit${Object.keys(hashArr)[selectedIndex]}`,
        linkName: Object.values(hashArr)[selectedIndex],
        linkActive: true,
      },
    ];

    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/external-user"));
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash, userId, formik]);

  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector("#userEmail");
      emailInput.style.display = "";
    }, 100);
  }, []);

  const formRef = useRef(null);
  const userProfileImgInputRef = useRef(null);
  const userProfileImgRef = useRef(null);
  const userCompetencyPanelRef = useRef(null);

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="userProfile">
          {interfaceAccess ? (
            <div className="gennx-content-wrapper padding-bottom-50i pt-3" style={{ width: "99.5%" }}>
              <div className="mx-2 pt-3">
                <Accordion heading="Basic Details" step={1} stepCompleted={formik.values.userFname ? true : false} activeState={true}>
                  <div className="container-fluid mb-3 pl-0">
                    <div className="pt-3">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userFname" name="userFname" placeholder="First Name *" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userLname" name="userLname" placeholder="Last Name *" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userEmail" placeholder="Email *" name="userEmail" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userMobile" placeholder="Mobile *" name="userMobile" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userContactTelNo" placeholder="Telephone" name="userContactTelNo" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="twitter" placeholder="Twitter" name="twitter" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="linkedin" placeholder="LinkedIn URL" name="linkedin" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="facebook" placeholder="Facebook" name="facebook" />
                            </div>
                            <div className="col-md-1">{editUser && <label className="mt-2">User Status</label>}</div>
                            <div className="col-md-2">
                              {editUser ? (
                                typeof existingUser.userActive !== "undefined" ? (
                                  existingUser.userActive ? (
                                    <blockquote className="active">Active</blockquote>
                                  ) : (
                                    <blockquote className="inactive">Inactive </blockquote>
                                  )
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <label style={{ top: "20px", position: "relative" }}>About / Description</label>
                          <div className="row">
                            <div className="col-md-12">
                              <TextEditor
                                formik={formik}
                                properties={{
                                  id: "userAbout",
                                  name: "userAbout",
                                  activeClass: "userAbout",
                                  // label: "About",
                                  config: { ...ckEditorMinimalConfig, editorplaceholder: "About / Description", height: userId ? 250 : 180 },
                                }}
                              />
                            </div>
                          </div>
                          <label>Address Details</label>
                          <br />
                          <div className="row">
                            <div className="col-md-4">
                              <AutoCompleteDropdown
                                formik={formik}
                                placeholder="Country *"
                                id="country"
                                name="country"
                                fields={{ value: "country" }}
                                disabled={false}
                                // value={formik.values.country}
                                allowFiltering={true}
                                filtering={searchCountry}
                                autofill={true}
                                select={(selectedItem) => {
                                  selectedItem && formik.setFieldValue("region", selectedItem.itemData?.region);
                                }}
                                allowCustom={editUser && formik.values.country ? true : false}
                                filterType="StartsWith"
                              />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="state" placeholder="State" name="state" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="addressLine" placeholder="Address Line" name="addressLine" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="areaName" placeholder="Area" name="areaName" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="city" placeholder="City" name="city" />
                            </div>
                            <div className="col-md-4">
                              <TextField
                                formik={formik}
                                type="text"
                                id="region"
                                placeholder="Region"
                                name="region"
                                readOnly
                                showClearButton={false}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="postCode" placeholder="Post Code" name="postCode" />
                            </div>
                            <div className={window.innerWidth < 1500 ? `col-md-6` : `col-md-4`}>
                              <RadioGroup
                                formik={formik}
                                type="radio"
                                optionArr={["Home", "Office"]}
                                properties={{
                                  placeholder: "Address Type",
                                  name: "addressType",
                                  label: "Address Type *",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" style={{ top: "-30px", position: "relative" }}>
                          <div className="image-holder mb-1">
                            <img src={userProfileImgSrc || userImage} alt="User" ref={userProfileImgRef} width="150" height="150" />
                            <div className="image-action" style={{ right: "122px" }}>
                              {accessActionDeleteProfileImage && (
                                <span
                                  onClick={() => {
                                    dispatch(
                                      showAlertBox({
                                        content: "Are you sure, you want to delete?",
                                        okCallback: async () => {
                                          dispatch(actionSuccess("Image deleted successfully"));
                                          if (userProfileImgInputRef.current.files.length > 0) {
                                            userProfileImgRef.current.setAttribute("src", userProfileImgSrc || userImage);
                                            userProfileImgInputRef.current.value = "";
                                            setImageError("");
                                            setIsDefaultImage(true);
                                          } else {
                                            setUserProfileImgSrc(undefined);
                                            setIsDefaultImage(false);
                                            setImageError("");
                                            userProfileImgRef.current.setAttribute("src", userImage);
                                          }
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <i className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : undefined}`} aria-hidden="true"></i>
                                </span>
                              )}
                              {accessActionEditProfileImage && (
                                <span onClick={() => userProfileImgInputRef.current.click()}>
                                  <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                                </span>
                              )}
                            </div>
                          </div>

                          <input
                            type="file"
                            className="d-none"
                            accept=".jpg, .png"
                            id="userProfileImage"
                            name="userProfileImage"
                            ref={userProfileImgInputRef}
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                if (
                                  (e.target.files[0].type == "image/png" ||
                                    e.target.files[0].type == "image/jpg" ||
                                    e.target.files[0].type == "image/jpeg") &&
                                  e.target.files[0].size < 512000
                                ) {
                                  setImageError("");
                                  setIsDefaultImage(false);

                                  readProfileImage(e.target.files[0]);
                                } else {
                                  setImageError("Only JPG, PNG files are allowed. Max file size should be 500 Kb.");
                                }
                              } else {
                                setImageError("");
                              }
                            }}
                          />
                          <div
                            className="d-flex align-item-center justify-content-center text-danger"
                            style={{ display: imageError ? "block" : "none" }}
                            role="alert"
                          >
                            <small>{imageError}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  heading="User Role / Login / Work Details"
                  step={2}
                  stepCompleted={formik.values.userRole ? true : false}
                  forcedActiveState={formik.errors.userPassword || formik.errors.userConfirmPassword ? true : false}
                  activeState={!editUser}
                >
                  <div className="row p-3">
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        type="password"
                        id="userPassword"
                        placeholder={`Enter New Password${editUser ? " " : " *"}`}
                        name="userPassword"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        type="password"
                        id="userConfirmPassword"
                        placeholder={`Confirm New Password${editUser ? " " : " *"}`}
                        name="userConfirmPassword"
                      />
                    </div>
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "col-md-6"}`}>
                      <Dropdown
                        formik={formik}
                        id="userRole"
                        name="userRole"
                        placeholder="User Role *"
                        dataSource={USER_ROLES}
                        fields={{ text: "user_role_name", value: "user_role_value" }}
                        disabled={false}
                      />
                    </div>
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "d-none"}`}>
                      {teams.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          required="required"
                          placeholder="Product Variant *"
                          id="productVariant"
                          name="productVariant"
                          value={formik.values.productVariant}
                          allowCustomValue={false}
                          fields={{ text: "productName", value: "productName" }}
                          dataSource={teams}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "col-md-6"}`}>
                      <Dropdown
                        formik={formik}
                        id="userEmpaneledBy"
                        name="userEmpaneledBy"
                        placeholder="Empanelment By"
                        dataSource={EMPANELMENT_LIST}
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-4">
                      <Dropdown
                        formik={formik}
                        id="userWorkTimeAvailability"
                        name="userWorkTimeAvailability"
                        placeholder="Time Availability"
                        value={formik.values.userWorkTimeAvailability}
                        dataSource={TIME_AVAILABILITY}
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-4">
                      <Dropdown
                        formik={formik}
                        id="userInternetConnectionAvailability"
                        name="userInternetConnectionAvailability"
                        placeholder="Internet Connection Availability (Mbps)"
                        value={formik.values.userInternetConnectionAvailability}
                        dataSource={INTERNET_SPEED}
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-2">
                      <DatePicker
                        formik={formik}
                        properties={{
                          placeholder: "User Activation Date",
                          id: `userActivationDate`,
                          name: `userActivationDate`,
                          format: "dd MMM yyyy",
                          floatLabelType: "Auto",
                          allowEdit: false,
                          openOnFocus: true,
                          disabled: false,
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <NumericTextField
                        formik={formik}
                        format="n0"
                        min={0}
                        id="userTotalExperience"
                        name="userTotalExperience"
                        placeholder="Total Year Of Experience"
                        disabled={false}
                      />
                    </div>

                    <div className="col-md-4">
                      {/* <Dropdown
								formik={formik}
								id="userVendorId"
								name="userVendorId"
								placeholder="Vendor"
								value={formik.values.userVendorId}
								dataSource={vendors}
								fields={{ text: "vendorName", value: "id" }}
								disabled={false}
							/> */}
                      <AutoCompleteDropdown
                        formik={formik}
                        id="userVendorName"
                        name="userVendorName"
                        placeholder="Vendor"
                        value={formik.values.userVendorName}
                        fields={{ value: "label", id: "value" }}
                        filtering={searchVendor}
                        select={handleVendorData}
                        allowFiltering={true}
                        autofill={true}
                        // allowCustom ={false}
                        // noRecordsTemplate={() => <div>No data found</div>}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="mb-2" htmlFor="equipedWithLaptop">
                        Laptop/Desktop Available
                      </label>
                    </div>
                    <div className="col-md-1">
                      <div className="form-group">
                        <div style={{ marginLeft: "-10px" }}>
                          <label className="custom-switch3">
                            <input
                              type="checkbox"
                              name="equipedWithLaptop"
                              checked={checked ? true : false}
                              onChange={(e) => {
                                setChecked(e.target.checked);
                                formik.setFieldValue("equipedWithLaptop", e.target.checked ? "yes" : "no");
                              }}
                            />
                            <div>
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </div>
                            <i></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>
                {userId && (
                  <Experience
                    userId={userId}
                    moduleName={aR.moduleName}
                    accessActionAddExperience={accessActionAddExperience}
                    accessActionEditExperience={accessActionEditExperience}
                    accessActionDeleteExperience={accessActionDeleteExperience}
                  />
                )}
                {userId && (
                  <Education
                    userId={userId}
                    moduleName={aR.moduleName}
                    accessActionAddEducation={accessActionAddEducation}
                    accessActionEditEducation={accessActionEditEducation}
                    accessActionDeleteEducation={accessActionDeleteEducation}
                  />
                )}
                <Accordion
                  heading="User Competency"
                  step={userId ? 5 : 3}
                  stepCompleted={
                    formik.values.userCompentencyTechnology ||
                    formik.values.userCompentencyIndustry ||
                    formik.values.userKiaExpertise ||
                    formik.values.userFunctionalExpertise ||
                    formik.values.userLanguageExpertise ||
                    formik.values.researchPapersAuthored ||
                    formik.values.videoConsultingCompleted ||
                    formik.values.surveyCompleted ||
                    formik.values.workshopCompleted
                      ? true
                      : false
                  }
                  activeState={!editUser}
                >
                  <div className="row p-3" ref={userCompetencyPanelRef}>
                    <div className="col-md-4">
                      {technologies.length && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Technology Expertise"
                          id="userCompentencyTechnology"
                          name="userCompentencyTechnology"
                          value={formik.values.userCompentencyTechnology}
                          allowCustomValue={false}
                          fields={{ text: "dtName", value: "dtId" }}
                          dataSource={technologies}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      {trends.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Industry Expertise"
                          id="userCompentencyIndustry"
                          name="userCompentencyIndustry"
                          value={formik.values.userCompentencyIndustry}
                          allowCustomValue={false}
                          fields={{ text: "trendName", value: "id" }}
                          dataSource={trends}
                          disabled={false}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}

                      {industry.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Industry Expertise"
                          id="userCompentencyIndustry"
                          name="userCompentencyIndustry"
                          value={formik.values.userCompentencyIndustry}
                          allowCustomValue={false}
                          fields={{ text: "industryName", value: "id" }}
                          dataSource={industry}
                          disabled={false}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    {productVariant === PRODUCT_VARIANT.WHATNEXT && (
                      <div className="col-md-4">
                        {kiaDataSource.length > 0 && (
                          <MultiSelect
                            formik={formik}
                            mode="CheckBox"
                            sortOrder="Acending"
                            showSelectAll={true}
                            // selectAllText="Select All"
                            showClearButton={false}
                            //enableRtl={true}
                            placeholder="KIA Expertise"
                            id="userKiaExpertise"
                            name="userKiaExpertise"
                            value={formik.values.userKiaExpertise}
                            allowCustomValue={false}
                            fields={{ text: "label", value: "value", groupBy: "parent" }}
                            dataSource={kiaDataSource}
                            showDropDownIcon={true}
                            disabled={false}
                          />
                        )}
                      </div>
                    )}

                    <div className="col-md-4">
                      {functionalExpertise.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Functional Expertise"
                          id="userFunctionalExpertise"
                          name="userFunctionalExpertise"
                          value={formik.values.userFunctionalExpertise}
                          allowCustomValue={false}
                          fields={{ text: "experty", value: "experty", groupBy: "parent" }}
                          dataSource={functionalExpertise}
                          disabled={false}
                          showDropDownIcon={true}
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      {languages.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Language Expertise"
                          id="userLanguageExpertise"
                          name="userLanguageExpertise"
                          value={formik.values.userLanguageExpertise}
                          allowCustomValue={false}
                          // fields={{ text: "language", value: "language" }}
                          dataSource={languages}
                          disabled={false}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <NumericTextField
                        formik={formik}
                        format="n0"
                        min={0}
                        id="researchPapersAuthored"
                        name="researchPapersAuthored"
                        placeholder="Research Papers Authored"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="row p-3" style={{ top: "-28px", position: "relative" }}>
                    <div className="col-md-4">
                      <NumericTextField
                        formik={formik}
                        format="n0"
                        min={0}
                        id="videoConsultingCompleted"
                        placeholder="Video Consulting Completed"
                        name="videoConsultingCompleted"
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-4">
                      <NumericTextField
                        formik={formik}
                        format="n0"
                        min={0}
                        id="surveyCompleted"
                        placeholder="Survey Completed"
                        name="surveyCompleted"
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-2">
                      <NumericTextField
                        formik={formik}
                        format="n0"
                        min={0}
                        id="workshopCompleted"
                        placeholder="Workshop completed"
                        name="workshopCompleted"
                        disabled={false}
                      />
                    </div>
                    <div className="col-md-2">
                      <div className="disable-div" style={{ width: "70%" }}></div>
                      <NumericTextField
                        formik={formik}
                        decimals={5}
                        format="n1"
                        validateDecimalOnType={true}
                        min={0.5}
                        max={5}
                        step={0.5}
                        keyup={() => {
                          return false;
                        }}
                        id="userExpertRating"
                        name="userExpertRating"
                        placeholder="Expert Rating"
                        disabled={false}
                      />
                    </div>
                  </div>
                </Accordion>

                <Accordion
                  heading="Bank Details"
                  step={userId ? 6 : 4}
                  stepCompleted={
                    formik.values.bankName ||
                    formik.values.bankAccountNo ||
                    formik.values.payPalId ||
                    formik.values.currency ||
                    formik.values.bankPaymentDetails
                      ? true
                      : false
                  }
                  activeState={!editUser}
                >
                  <div className="row p-3">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <TextField formik={formik} type="text" id="bankName" placeholder="Bank Name" name="bankName" disabled={false} />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            formik={formik}
                            format="n0"
                            min={0}
                            id="bankAccountNo"
                            placeholder="Account No."
                            name="bankAccountNo"
                            disabled={false}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField formik={formik} type="text" id="payPalId" placeholder="PayPal ID" name="payPalId" disabled={false} />
                        </div>
                        <div className="col-md-6">
                          <Dropdown
                            formik={formik}
                            id="currency"
                            name="currency"
                            placeholder="Currency of Payment"
                            dataSource={CURRENCY}
                            // fields={{ text: "field", value: "id" }}
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            formik={formik}
                            multiline={true}
                            rows={3}
                            type="text"
                            id="bankPaymentDetails"
                            placeholder="Bank Details for Payment"
                            name="bankPaymentDetails"
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>
              </div>
              <div className="form-submit-box">
                <div className="container-fluid">
                  <div className="row">
                    <div className="float-left col-md-6 text-left">
                      {/* {props.selectedIndex > 0 || props.selectedIndex === undefined ?
                            <span className="pl-4" style={{ "margin-left": "-3em" }}>
                                <span
                                    // to={props.nextPath}
                                    className="span-link"
                                >
                                    <button type="button" className="btn btn-outline-dark pl-4 pr-4 cy-btn" onClick={() => { (props.selectedIndex === undefined && props.setCurrentTab(3)) || (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1)) }}
                                    // disabled={props.nextPath ? false : true}
                                    >Previous</button>
                                </span>
                            </span>
                            : null
                        } */}

                      {userId !== "" && selectedIndex > -1 && selectedIndex < 3 ? (
                        <span className="pl-4" style={{ marginLeft: "-3em" }}>
                          <span className="span-link">
                            <button
                              type="button"
                              className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                              onClick={() => {
                                selectedIndex < 3 && tabRef.current.select(selectedIndex + 1);
                              }}
                            >
                              Next
                            </button>
                          </span>
                        </span>
                      ) : null}
                    </div>
                    <div className="float-right col-md-6 text-right">
                      <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3" onClick={() => history.push("/external-user")}>
                        Cancel
                      </button>
                      {accessActionSave ? (
                        (existingUser.userActive && editUser) || !editUser ? (
                          <button type="button" onClick={saveUser} className="btn btn-primary cy-btn pl-3 pr-3 ml-3">
                            {editUser ? "Update" : "Save"}
                          </button>
                        ) : null
                      ) : null}
                      {editUser && existingUser.userActive && accessActionSendLoginDetails ? (
                        <button
                          type="button"
                          className="btn btn-primary pl-3 pr-3 ml-3 "
                          style={{ height: "40px !important" }}
                          onClick={() => {
                            dispatch(
                              showAlertBox({
                                okCallback: sendLoginDetails,
                                content: "Password will be reset and login details will be sent to user's email id. Do you want to continue?",
                              })
                            );
                          }}
                        >
                          Send Login Details
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="registrationDtls">
          {userId && selectedIndex === 1 && interfaceAccess ? (
            <RegistrationDetails
              accessRights={props.accessRights}
              userId={userId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="assessment">
          <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
            {userId && selectedIndex === 2 && interfaceAccess ? (
              <Assessment
                accessRights={props.accessRights}
                userId={userId}
                selectedIndex={selectedIndex}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                interfaceActionAccess={interfaceActionAccess}
              />
            ) : (
              <AccessDenied />
            )}
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default UserProfile;
