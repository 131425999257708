// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Status filter component for site search results page
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :12 Jan 2024
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useMemo, useRef, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./statusFilterSearch.module.css";
import { setStatusFilter } from "../../../../middleware/actions/searchAction";
import "./index.css";
import { Form } from "react-bootstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { MdClose } from "react-icons/md";

const statusFilter = ["All", "In Progress", "Published"];

export default function StatusFilterSearch({ anchorEl, setAnchorEl, setActiveIndex }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const containerRef = useRef(null);

  const filters = useSelector((state) => state["searchState"]["allFilters"]);

  const selectedStatusFilter = useSelector((state) => state["searchState"]["statusFilter"]);

  const coords = useMemo(() => {
    return anchorEl?.getBoundingClientRect();
  }, [anchorEl]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        const loaderNode = document.getElementsByClassName('mainLoader');
        if (ref.current && !ref.current.contains(event.target) && loaderNode.length !== 1) {
          setAnchorEl(null);
          setTimeout(() => {
            setActiveIndex(null);
          }, 10);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(containerRef);

  // set published status to filter data
  const setStatusFilterValue = (value) => {
    dispatch(setStatusFilter(value));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.1 },
        transform: `translateX(${coords?.right - coords.left - 144}px) translateY(${coords?.y - coords.top + 35}px)`,
      }}
      exit={{ opacity: 0 }}
      style={{
        position: "absolute",
        zIndex: 1,
        left: coords.left,
        top: coords?.top,
        transform: `translateX(${coords?.right - coords.left - 144}px) translateY(${coords?.y - coords.top + 35}px)`,
        width: "230px",
        height: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.25)",
        transition: "all 0.3s ease",
      }}
      className="adjustZoomContainer"
      ref={containerRef}
    >
      <div key="inline-radio" className={`${classes.statusFilter}`} style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            alignItems: "center",
            padding: "15px 20px",
          }}
          className="popup-header-modal"
        >
          <div>Status Presets</div>
          <MdClose
            onClick={() => {
              setAnchorEl(null);
              setTimeout(() => {
                setActiveIndex(null);
              }, 10);
            }}
            style={{ cursor: "pointer" }}
            fontSize="20px"
          />
        </div>
        <div
          style={{
            // maxHeight: `calc(100vh - ${coords.top}px)`,
            // height: '100%',
            overflowY: "auto",
            padding: "15px 20px",
          }}
          className="adjustZoom"
        >
          {statusFilter.map((type, i) => {
            return (
              <Form.Check
                className="custom_radio"
                label={type}
                value={type}
                checked={type === selectedStatusFilter}
                name={"status" + i}
                type="radio"
                key={`inline-radio-${i}`}
                id={`inline-radio-${i}`}
                onChange={(e) => {
                  console.log("e.currentTarget.value >>>", e.currentTarget.value);
                  setStatusFilterValue(e.currentTarget.value);
                    setAnchorEl(null);
                    setTimeout(() => {
                      setActiveIndex(null);
                    }, 10);
                }}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}
