/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : External User Profile
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef, useCallback } from "react";
import _, { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Stepper from "../../../components/stepper";
import Experience from "./experience";
import Education from "./education";
import Accordion from "../../../components/accordion";
import {
  TextField,
  NumericTextField,
  TextEditor,
  AutoCompleteDropdown,
  Dropdown,
  MultiSelect,
  RadioGroup,
} from "../../../components/CustomFormElements/syncFusionFormFields";
import { ckEditorMinimalConfig } from "../../../../config/ckEditorConfig";
import { myProfileValidationSchema } from "../../../helper/validationSchema/externalUserValidationSchema";
import { updateUser, fetchSingleUser, fetchUserProfileImageUrl } from "../../../../middleware/services/userApi";
import { fetchKias } from "../../../../middleware/services/kiaApi";
import {
  fetchTaxonomyTechnology,
  fetchTaxonomyTrend,
  fetchTaxonomyIndustry,
  fetchTeam,
  fetchLanguages,
  fetchFunctionalExpertiseData,
  fetchCountry,
} from "../../../../middleware/services/cmsApi";
import { INTERNET_SPEED, TIME_AVAILABILITY, PRODUCT_VARIANT, CURRENCY, COMMON_ACCESS_ACTION } from "../../../../constants";
import userImage from "../../../../assets/images/user.jpg";
import { getLoggedInUser, isAdmin, getProductVariant, accessRightActionCheck } from "../../../../utilities";

const initialValues = {
  userFname: "",
  userLname: "",
  userEmail: "",
  userMobile: "",
  userContactTelNo: "",
  userAbout: "",
  userPassword: "",
  twitter: "",
  linkedin: "",
  facebook: "",
  country: "",
  addressLine: "",
  areaName: "",
  region: "",
  city: "",
  postCode: "",
  addressType: "",
  userDesignation: "",
  userWorkTimeAvailability: "",
  userInternetConnectionAvailability: "",
  userCompentencyTechnology: [],
  userCompentencyIndustry: [],
  userType: "",
  researchPapersAuthored: "",
  videoConsultingCompleted: "",
  surveyCompleted: "",
  workshopCompleted: "",
  bankName: "",
  bankAccountNo: "",
  payPalId: "",
  currency: "",
  bankPaymentDetails: "",
  userActive: undefined,
};
const UserProfile = (props) => {
  const { accessRights: aR } = props;
  const history = useHistory();
  const editUser = true;
  const userTokenDetails = getLoggedInUser();
  const isMultipleProductVariant = userTokenDetails.product_variant?.length > 1 ? true : false;
  const userId = props.computedMatch?.params?.userId;
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [kiaDataSource, setKiaDataSource] = useState([]);
  const [kiaDatas, setKiaDatas] = useState([]);
  const productVariant = getProductVariant();
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessPermission = true;
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionEditProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_PROFILE_IMAGE);
  const accessActionDeleteProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_PROFILE_IMAGE);
  // specify breadcrumb here
  const breadCrumbLinksEditUser = [{ linkUrl: `/my-profile/${userId}/edit#profile`, linkName: "My Profile", linkActive: true }];

  const sessonId = userTokenDetails._id;
  const profileAccessId = props?.computedMatch?.params?.userId;
  const pathArr = props?.path.split("/");
  const pathname = pathArr[pathArr.length - 1];
  const stepperData = [
    {
      id: 1,
      path: editUser ? `/my-profile/${userId}/edit#profile` : "#profile",
      step: "1",
      name: "User Profile",
      isLine: true,
    },
    {
      id: 2,
      path: editUser ? `/my-profile/${userId}/edit/registration-detail#registration` : "#registration",
      step: "2",
      name: "Registration Details",
      isLine: true,
      linkDisabled: editUser ? false : true,
    },
  ];

  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [existingUser, setExistingUser] = useState({});
  const [teams, setTeams] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const [userProfileImgSrc, setUserProfileImgSrc] = useState(undefined);
  const [technologies, setTechnologies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [functionalExpertise, setFunctionalExpertise] = useState([]);
  const [trends, setTrends] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [trendsMaster, setTrendsMaster] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({ ...myProfileValidationSchema }),
  });
  const getKia = async () => {
    let filters = [["kiaIsActive", "eq", "Yes"]];
    if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
    let fields = { fields: ["kiaName", "kiaIndustryTag"] };
    const response = await fetchKias({ filters: filters, ...fields, sort: "kiaName:asc", limit: 2000 });
    if (response?.data && response?.data?.data) {
      setKiaDatas(response.data.data);
      const resData = response.data.data.map((v) => {
        return { label: v.kiaName, value: v.id, parent: v.kiaIndustryTag[0].industryName };
      });
      if (resData) {
        setKiaDataSource(resData);
      }
    }
  };
  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = async () => {
    dispatch(showLoader());
    userId &&
      (await fetchSingleUser(userId)
        .then((res) => {
          const userDetail = { ...res.data.data };
          if (userDetail.userProfileImage && userDetail.userProfileImage !== "") {
            setUserProfileImgSrc(fetchUserProfileImageUrl(userDetail.userProfileImage));
            setIsDefaultImage(false);
          } else {
            setIsDefaultImage(true);
          }

          userDetail.equipedWithLaptop && setChecked(userDetail.equipedWithLaptop === "yes" ? true : false);

          setExistingUser(userDetail);

          userDetail &&
            formik.setValues({
              userFname: userDetail.userFname || "",
              userLname: userDetail.userLname || "",
              userEmail: userDetail.userEmail || "",
              userMobile: userDetail.userMobile || "",
              userContactTelNo: userDetail.userContactTelNo || "",
              userAbout: userDetail.userAbout || "",
              userDesignation: userDetail.userDesignation || "",
              twitter: (userDetail.userSocial && userDetail.userSocial.twitter) || "",
              linkedin: (userDetail.userSocial && userDetail.userSocial.linkedin) || "",
              facebook: (userDetail.userSocial && userDetail.userSocial.facebook) || "",
              addressLine: (userDetail.userAddress && userDetail.userAddress.addressLine) || "",
              country: (userDetail.userAddress && userDetail.userAddress.country) || "",
              state: (userDetail.userAddress && userDetail.userAddress.state) || "",
              city: (userDetail.userAddress && userDetail.userAddress.city) || "",
              areaName: (userDetail.userAddress && userDetail.userAddress.areaName) || "",
              postCode: (userDetail.userAddress && userDetail.userAddress.postCode) || "",
              region: (userDetail.userAddress && userDetail.userAddress.region) || "",
              addressType: (userDetail.userAddress && userDetail.userAddress.addressType) || "",
              userWorkTimeAvailability: userDetail.userWorkTimeAvailability || "",
              userInternetConnectionAvailability: userDetail.userInternetConnectionAvailability || "",
              researchPapersAuthored: (userDetail.userServicesStatus && userDetail.userServicesStatus.researchPapersAuthored) || 0,
              videoConsultingCompleted: (userDetail.userServicesStatus && userDetail.userServicesStatus.videoConsultingCompleted) || 0,
              surveyCompleted: (userDetail.userServicesStatus && userDetail.userServicesStatus.surveyCompleted) || 0,
              workshopCompleted: (userDetail.userServicesStatus && userDetail.userServicesStatus.workshopCompleted) || 0,
              bankName: (userDetail.userBankDetails && userDetail.userBankDetails.bankName) || "",
              bankAccountNo: (userDetail.userBankDetails && userDetail.userBankDetails.bankAccountNo) || "",
              payPalId: (userDetail.userBankDetails && userDetail.userBankDetails.payPalId) || "",
              currency: (userDetail.userBankDetails && userDetail.userBankDetails.currency) || "",
              bankPaymentDetails: (userDetail.userBankDetails && userDetail.userBankDetails.bankPaymentDetails) || "",
            });

          userDetail &&
            formik.setFieldValue(
              "userCompentencyTechnology",
              userDetail?.userCompentencyTechnology?.map((v) => {
                return v.dtId;
              })
            );
          if (userDetail?.userCompentencyIndustry?.length > 0) {
            let userCompentencyIndustryTags = [];
            userDetail.userCompentencyIndustry.forEach((uCI) => {
              if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
                userCompentencyIndustryTags.push({ id: uCI.industryId, industryName: uCI.industryName });
              } else {
                userCompentencyIndustryTags.push({ id: uCI.industryId, trendName: uCI.industryName });
              }
            });
            userDetail.userCompentencyIndustry = userCompentencyIndustryTags;
          }

          userDetail?.userCompentencyIndustry &&
            formik.setFieldValue(
              "userCompentencyIndustry",
              userDetail?.userCompentencyIndustry?.map((v) => {
                return v.id;
              })
            );

          formik.setFieldValue("userLanguageExpertise", (userDetail?.userLanguageExpertise && [...userDetail.userLanguageExpertise]) || []);
          formik.setFieldValue(
            "userFunctionalExpertise",
            userDetail?.userFunctionalExpertise?.map((f) => {
              return f?.experty;
            })
          );
          if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
            formik.setFieldValue(
              "userKiaExpertise",
              userDetail?.userKiaExpertise?.map((f) => {
                return f.kiaId;
              })
            );
          }

          dispatch(hideLoader());
        })
        .catch((err) => {
          console.log("err", err);
          let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
          dispatch(actionError(errMsg));
        }));
  };

  /**
   * @description fetch functional Expertise
   */
  const fetchFunctionalExpertise = () => {
    fetchFunctionalExpertiseData({ sort: "parent:asc,expertise:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setFunctionalExpertise(data.map((d) => ({ experty: d.expertise, parent: d.parent })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Functional Expertise Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch language Expertise
   */
  const fetchLanguageExpertise = () => {
    fetchLanguages({ sort: "order:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setLanguages(data.map((d) => d.language));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Language Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch technologies to tag with user
   */
  const fetchTechnologies = () => {
    fetchTaxonomyTechnology({ filters: [["dtParentId", "eq", 0]], fields: ["id", "dtName"], sort: "dtName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTechnologies(data.map((d) => ({ dtId: d.id, dtName: d.dtName })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Technology Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
	/**
	 * @description fetch countries to tag with user
	 */

  const searchCountry = useCallback(
    debounce(async (e) => {
      let filters = [];
      filters.push(["countryName", "cn", e.text]);
      const response =
        e.text.length > 1 && (await fetchCountry({ filters: filters, fields: ["countryName", "region"], sort: "countryName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { country: v.countryName, region: v.region };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  /**
   * @description fetch trends to tag with user
   */
  const fetchTrends = () => {
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTrendsMaster(data);
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, trendName: sTT.trendParentName };
        });
        setTrends([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Trend Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch industry to tag with user
   */
  const fetchIndustry = () => {
    fetchTaxonomyIndustry({ fields: ["industryName"], sort: "industryName:asc", limit: 50 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.id] = { id: sTT.id, industryName: sTT.industryName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch teams for user product variant tagging (whatNext, Insider)
   */
  const fetchTeamsForUser = async () => {
    if (isAdmin()) {
      const res = await fetchTeam({ sort: "teamName:asc" });
      const { data = [] } = res.data;
      setTeams([...data.map((d) => ({ productName: d.teamName }))]);
    } else if (isMultipleProductVariant) {
      setTeams([...userTokenDetails.product_variant.map((p) => ({ productName: p.product_name }))]);
    }
  };

  /**
   * @description update existing user
   * @param {Object} userData
   */
  const updateExistingUser = (userData) => {
    dispatch(actionStart());
    updateUser(userId, userData)
      .then((res) => {
        dispatch(actionSuccess("User profile has been updated"));
        setUser({});
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter valid email"
            : err?.data?.message
          : "User profile updation failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description save user (new / existing) using form submit
   */
  const saveUser = () => {
    const userDetail = { ...formik.values };
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let countryNameError = "Please enter country";
        formik.setFormikState({
          ...formik,
          touched: touched,
          errors: {
            ...res,
            country: countryNameError,
          },
        });
      } else {
        const user = { ...formik.values };
        let saveUserData = new FormData();

        for (let uKey in user) {
          if (user[uKey] && user[uKey] !== "") saveUserData.append(uKey, user[uKey]);
        }

        saveUserData.set(
          "userSocial",
          JSON.stringify({
            twitter: user.twitter,
            facebook: user.facebook,
            linkedin: user.linkedin,
          })
        );
        saveUserData.delete("twitter");
        saveUserData.delete("facebook");
        saveUserData.delete("linkedin");

        saveUserData.set(
          "userServicesStatus",
          JSON.stringify({
            researchPapersAuthored: user.researchPapersAuthored,
            videoConsultingCompleted: user.videoConsultingCompleted,
            surveyCompleted: user.surveyCompleted,
            workshopCompleted: user.workshopCompleted,
          })
        );
        saveUserData.delete("researchPapersAuthored");
        saveUserData.delete("videoConsultingCompleted");
        saveUserData.delete("surveyCompleted");
        saveUserData.delete("workshopCompleted");

        saveUserData.set(
          "userBankDetails",
          JSON.stringify({
            bankName: user.bankName,
            bankPaymentDetails: user.bankPaymentDetails,
            bankAccountNo: user.bankAccountNo,
            payPalId: user.payPalId,
            currency: user.currency,
          })
        );
        saveUserData.delete("bankName");
        saveUserData.delete("bankPaymentDetails");
        saveUserData.delete("bankAccountNo");
        saveUserData.delete("payPalId");
        saveUserData.delete("currency");

        saveUserData.set(
          "userAddress",
          JSON.stringify({
            addressLine: user.addressLine,
            country: user.country,
            state: user.state,
            city: user.city,
            areaName: user.areaName,
            postCode: user.postCode,
            region: user.region,
            addressType: user.addressType,
          })
        );
        saveUserData.delete("addressLine");
        saveUserData.delete("country");
        saveUserData.delete("state");
        saveUserData.delete("city");
        saveUserData.delete("areaName");
        saveUserData.delete("postCode");
        saveUserData.delete("region");
        saveUserData.delete("addressType");

        saveUserData.delete("userConfirmPassword");
        saveUserData.delete("userProfileImage");

        if (!editUser) {
          saveUserData.append("userType", "External");
        }
        if (
          (!editUser && _.isEmpty(user)) ||
          (editUser &&
            _.isEmpty(user) &&
            userProfileImgInputRef.current.files.length === 0 &&
            (!existingUser.userProfileImage ||
              (existingUser.userProfileImage && existingUser.userProfileImage !== "" && userProfileImgSrc !== undefined)))
        ) {
          return true;
        }

        if (userDetail?.userCompentencyTechnology) {
          let userCompentencyTechnologyTags = [];
          technologies &&
            technologies.map((t) => {
              if ([...userDetail.userCompentencyTechnology].includes(t.dtId)) {
                userCompentencyTechnologyTags.push({
                  dtId: t.dtId,
                  dtName: t.dtName,
                });
              }
            });
          saveUserData.delete("userCompentencyTechnology");
          userCompentencyTechnologyTags && saveUserData.set("userCompentencyTechnology", JSON.stringify(userCompentencyTechnologyTags));
        }

        if (user?.userCompentencyIndustry) {
          let userCompentencyIndustryTags = [];
          if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
            console.log("industry", industry);
            industry &&
              industry.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  userCompentencyIndustryTags.push({
                    industryId: t.id,
                    industryName: t.industryName,
                  });
                }
              });
          } else {
            trends &&
              trends.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  trendsMaster
                    .filter((tM) => tM.trendParentId === t.id)
                    .forEach((tC) => {
                      userCompentencyIndustryTags.push({
                        industryId: t.id,
                        industryName: t.trendName,
                        trendId: tC.id,
                        trendName: tC.trendName,
                      });
                    });
                }
              });
          }
          saveUserData.delete("userCompentencyIndustry");
          saveUserData.set("userCompentencyIndustry", JSON.stringify(userCompentencyIndustryTags));
        }

        if (userDetail.userFunctionalExpertise) {
          let userFunctionalExpertiseTags =
            userDetail?.userFunctionalExpertise?.length &&
            user?.userFunctionalExpertise?.map((v) => {
              return { experty: v };
            });
          saveUserData.set("userFunctionalExpertise", JSON.stringify(userFunctionalExpertiseTags));
        }
        if (userDetail?.userKiaExpertise) {
          let userKiaExpertises = userDetail?.userKiaExpertise?.length ? [...userDetail.userKiaExpertise] : [];
          const kiaData =
            kiaDatas &&
            kiaDatas.length &&
            kiaDatas
              .map((v, i) => {
                return (
                  [...userKiaExpertises].includes(v.id) && {
                    kiaId: v.id,
                    kiaName: v.kiaName,
                    ...v.kiaIndustryTag[0],
                  }
                );
              })
              .filter((f) => {
                return f !== false;
              });
          kiaData && kiaData.length && saveUserData.set("userKiaExpertise", JSON.stringify(kiaData));
        }

        if (userDetail.userLanguageExpertise) {
          let userLanguageExpertiseTags = userDetail?.userLanguageExpertise?.length ? [...userDetail.userLanguageExpertise] : [];
          saveUserData.set("userLanguageExpertise", JSON.stringify(userLanguageExpertiseTags));
        }

        if (userDetail.equipedWithLaptop) {
          saveUserData.set("equipedWithLaptop", checked ? "yes" : "no");
        }
        if (userProfileImgInputRef.current.files.length) saveUserData.append("userProfileImage", userProfileImgInputRef.current.files[0]);

        dispatch(
          showAlertBox({
            okCallback: updateExistingUser,
            data: saveUserData,
            okText: "Update",
            cancelText: "Cancel",
            content: "Are you sure, you want to update user details??",
          })
        );
      }
    });
  };

  /**
   * @param {File} imageFile
   * @description read & set image for user profile
   */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      userProfileImgRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    if (!accessPermission && sessonId === profileAccessId && !["edit", "registration-detail"].includes(pathname)) {
      history.push(`/external-user/${userId}/edit/#profile`);
    }
    const breadCrumbLinks = [...breadCrumbLinksEditUser];
    dispatch(setBreadCrumb(breadCrumbLinks));
    editUser && fetchUserDetail();
    fetchTechnologies();
    getKia();
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
      fetchIndustry();
    } else if (productVariant === PRODUCT_VARIANT.INSIDER) {
      fetchTrends();
    }
    fetchTeamsForUser();
    fetchFunctionalExpertise();
    fetchLanguageExpertise();
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector("#userEmail");
      emailInput.style.display = "";
    }, 100);
  }, []);

  const userProfileImgInputRef = useRef(null);
  const userProfileImgRef = useRef(null);
  const userCompetencyPanelRef = useRef(null);

  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, aR.interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <>
      <Stepper data={stepperData} />
      <div className="gennx-content-wrapper padding-bottom-150i footer-button-wrapper">
        <div className="mt-4 mx-2">
          <Accordion heading="Basic Details" step={1} stepCompleted={userId ? true : false} activeState={true}>
            <div className="container-fluid mb-3">
              <div className="p-3">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="userFname" name="userFname" placeholder="First Name *" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="userLname" name="userLname" placeholder="Last Name *" />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          formik={formik}
                          type="text"
                          id="userEmail"
                          placeholder="Email *"
                          name="userEmail"
                          readOnly
                          showClearButton={false}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="userMobile" placeholder="Mobile *" name="userMobile" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="userContactTelNo" placeholder="Telephone" name="userContactTelNo" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="twitter" placeholder="Twitter" name="twitter" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="linkedin" placeholder="LinkedIn URL" name="linkedin" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="facebook" placeholder="Facebook" name="facebook" />
                      </div>
                      <div className="col-md-1">{editUser && <label className="mt-2">User Status</label>}</div>
                      <div className="col-md-2">
                        {editUser ? (
                          typeof existingUser.userActive !== "undefined" ? (
                            existingUser.userActive ? (
                              <blockquote className="active">Active</blockquote>
                            ) : (
                              <blockquote className="inactive">Inactive </blockquote>
                            )
                          ) : null
                        ) : null}
                      </div>
                    </div>
                    <label style={{ top: "20px", position: "relative" }}>About / Description</label>
                    <div className="row">
                      <div className="col-md-12">
                        <TextEditor
                          formik={formik}
                          properties={{
                            id: "userAbout",
                            name: "userAbout",
                            activeClass: "userAbout",
                            // label: "About",
                            config: { ...ckEditorMinimalConfig, editorplaceholder: "About / Description", height: userId ? 250 : 180 },
                          }}
                        />
                      </div>
                    </div>
                    <label>Address Details</label>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <AutoCompleteDropdown
                          formik={formik}
                          placeholder="Country *"
                          id="country"
                          name="country"
                          fields={{ value: "country" }}
                          value={formik.values.country}
                          allowFiltering={true}
                          filtering={searchCountry}
                          autofill={true}
                          select={(selectedItem) => {
                            selectedItem && formik.setFieldValue("region", selectedItem.itemData?.region);
                          }}
                          allowCustom={editUser && formik.values.country ? true : false}
                          filterType="StartsWith"
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="state" placeholder="State" name="state" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="addressLine" placeholder="Address Line" name="addressLine" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="areaName" placeholder="Area" name="areaName" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="city" placeholder="City" name="city" />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="region" placeholder="Region" name="region" readOnly showClearButton={false} />
                      </div>
                      <div className="col-md-4">
                        <TextField formik={formik} type="text" id="postCode" placeholder="Post Code" name="postCode" />
                      </div>
                      <div className="col-md-4">
                        <RadioGroup
                          formik={formik}
                          type="radio"
                          optionArr={["Home", "Office"]}
                          properties={{
                            placeholder: "Address Type",
                            name: "addressType",
                            label: "Address Type *",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2" style={{ top: "-30px", position: "relative" }}>
                    <div className="image-holder mb-1">
                      <img src={userProfileImgSrc || userImage} alt="User" ref={userProfileImgRef} width="150" height="150" />
                      <div className="image-action">
                        {
                          <>
                            {accessActionDeleteProfileImage && (
                              <i
                                className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : undefined}`}
                                aria-hidden="true"
                                onClick={() => {
                                  if (userProfileImgInputRef.current.files.length > 0) {
                                    userProfileImgRef.current.setAttribute("src", userProfileImgSrc || userImage);
                                    userProfileImgInputRef.current.value = "";
                                    setImageError("");
                                    setIsDefaultImage(true);
                                  } else {
                                    setUserProfileImgSrc(undefined);
                                    setIsDefaultImage(true);
                                    setImageError("");
                                    userProfileImgRef.current.setAttribute("src", userImage);
                                  }
                                }}
                              ></i>
                            )}
                            {accessActionEditProfileImage && (
                              <i className="fa fa-pencil-alt " aria-hidden="true" onClick={() => userProfileImgInputRef.current.click()}></i>
                            )}
                          </>
                        }
                      </div>
                    </div>

                    <input
                      type="file"
                      className="d-none"
                      accept=".jpg, .png"
                      id="userProfileImage"
                      name="userProfileImage"
                      ref={userProfileImgInputRef}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          if (
                            (e.target.files[0].type == "image/png" ||
                              e.target.files[0].type == "image/jpg" ||
                              e.target.files[0].type == "image/jpeg") &&
                            e.target.files[0].size < 512000
                          ) {
                            setImageError("");
                            setIsDefaultImage(false);
                            readProfileImage(e.target.files[0]);
                          } else {
                            setImageError("Only JPG, PNG files are allowed. Max file size should be 500 Kb.");
                          }
                        } else {
                          setImageError("");
                        }
                      }}
                    />
                    <div
                      className="d-flex align-item-center justify-content-center text-danger"
                      style={{ display: imageError ? "block" : "none" }}
                      role="alert"
                    >
                      <small>{imageError}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
          <Accordion
            heading="User Role / Login / Work Details"
            step={2}
            stepCompleted={userId ? true : false}
            forcedActiveState={formik.errors.userPassword || formik.errors.userConfirmPassword ? true : false}
            activeState={!editUser}
            required={!editUser}
          >
            <div className="row p-3">
              <div className="col-md-4">
                <TextField
                  formik={formik}
                  type="password"
                  id="userPassword"
                  placeholder={`Enter New Password${editUser ? " " : " *"}`}
                  name="userPassword"
                />
              </div>
              <div className="col-md-4">
                <TextField
                  formik={formik}
                  type="password"
                  id="userConfirmPassword"
                  placeholder={`Confirm New Password${editUser ? " " : " *"}`}
                  name="userConfirmPassword"
                />
              </div>

              <div className="col-md-4">
                <Dropdown
                  formik={formik}
                  id="userWorkTimeAvailability"
                  name="userWorkTimeAvailability"
                  placeholder="Time Availability"
                  value={formik.values.userWorkTimeAvailability}
                  dataSource={TIME_AVAILABILITY}
                />
              </div>
              <div className="col-md-4">
                <Dropdown
                  formik={formik}
                  id="userInternetConnectionAvailability"
                  name="userInternetConnectionAvailability"
                  placeholder="Internet Connection Availability (Mbps)"
                  value={formik.values.userInternetConnectionAvailability}
                  dataSource={INTERNET_SPEED}
                />
              </div>

              <div className="col-md-2">
                <label className="mb-2" htmlFor="equipedWithLaptop">
                  Laptop/Desktop Available
                </label>
              </div>
              <div className="col-md-1">
                <div className="form-group">
                  <div style={{ marginLeft: "-10px" }}>
                    <label className="custom-switch3">
                      <input
                        type="checkbox"
                        name="equipedWithLaptop"
                        checked={checked ? true : false}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          formik.setFieldValue("equipedWithLaptop", e.target.checked ? "yes" : "no");
                        }}
                      />
                      <div>
                        <span className="on">Yes</span>
                        <span className="off">No</span>
                      </div>
                      <i></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Accordion>

          {userId && <Experience userId={userId} />}

          {userId && <Education userId={userId} />}

          <Accordion
            heading="User Competency"
            step={userId ? 5 : 3}
            stepCompleted={userId && formik.values.userCompentencyTechnology && formik.values.workshopCompleted ? true : false}
            activeState={!editUser}
          >
            <div className="row p-3" ref={userCompetencyPanelRef}>
              <div className="col-md-4">
                {technologies.length && (
                  <MultiSelect
                    formik={formik}
                    mode="CheckBox"
                    sortOrder="Acending"
                    showSelectAll={true}
                    // selectAllText="Select All"
                    showClearButton={false}
                    enableRtl={true}
                    placeholder="Technology Expertise"
                    id="userCompentencyTechnology"
                    name="userCompentencyTechnology"
                    value={formik.values.userCompentencyTechnology}
                    allowCustomValue={false}
                    fields={{ text: "dtName", value: "dtId" }}
                    dataSource={technologies}
                    cssClass="multi-without-parent e-outline customCss"
                  />
                )}
              </div>
              {(trends.length > 0 || industry.length > 0) && (
                <div className="col-md-4">
                  {trends.length > 0 && (
                    <MultiSelect
                      formik={formik}
                      mode="CheckBox"
                      sortOrder="Acending"
                      showSelectAll={true}
                      // selectAllText="Select All"
                      showClearButton={false}
                      enableRtl={true}
                      placeholder="Industry Expertise"
                      id="userCompentencyIndustry"
                      name="userCompentencyIndustry"
                      value={formik.values.userCompentencyIndustry}
                      allowCustomValue={false}
                      fields={{ text: "trendName", value: "id" }}
                      dataSource={trends}
                      cssClass="multi-without-parent e-outline customCss"
                    />
                  )}

                  {industry.length > 0 && (
                    <MultiSelect
                      formik={formik}
                      mode="CheckBox"
                      sortOrder="Acending"
                      showSelectAll={true}
                      // selectAllText="Select All"
                      showClearButton={false}
                      enableRtl={true}
                      placeholder="Industry Expertise"
                      id="userCompentencyIndustry"
                      name="userCompentencyIndustry"
                      value={formik.values.userCompentencyIndustry}
                      allowCustomValue={false}
                      fields={{ text: "industryName", value: "id" }}
                      dataSource={industry}
                      cssClass="multi-without-parent e-outline customCss"
                    />
                  )}
                </div>
              )}
              {productVariant === PRODUCT_VARIANT.WHATNEXT && (
                <div className="col-md-4">
                  {kiaDataSource.length > 0 && (
                    <MultiSelect
                      formik={formik}
                      mode="CheckBox"
                      sortOrder="Acending"
                      showSelectAll={true}
                      // selectAllText="Select All"
                      showClearButton={false}
                      enableRtl={true}
                      placeholder="KIA Expertise"
                      id="userKiaExpertise"
                      name="userKiaExpertise"
                      value={formik.values.userKiaExpertise}
                      allowCustomValue={false}
                      fields={{ text: "label", value: "value", groupBy: "parent" }}
                      dataSource={kiaDataSource}
                    />
                  )}
                </div>
              )}

              <div className="col-md-4">
                {functionalExpertise.length > 0 && (
                  <MultiSelect
                    formik={formik}
                    mode="CheckBox"
                    sortOrder="Acending"
                    showSelectAll={true}
                    // selectAllText="Select All"
                    showClearButton={false}
                    enableRtl={true}
                    placeholder="Functional Expertise"
                    id="userFunctionalExpertise"
                    name="userFunctionalExpertise"
                    value={formik.values.userFunctionalExpertise}
                    allowCustomValue={false}
                    fields={{ text: "experty", value: "experty", groupBy: "parent" }}
                    dataSource={functionalExpertise}
                  />
                )}
              </div>
              <div className="col-md-4">
                {languages.length > 0 && (
                  <MultiSelect
                    formik={formik}
                    mode="CheckBox"
                    sortOrder="Acending"
                    showSelectAll={true}
                    // selectAllText="Select All"
                    showClearButton={false}
                    enableRtl={true}
                    placeholder="Language Expertise"
                    id="userLanguageExpertise"
                    name="userLanguageExpertise"
                    value={formik.values.userLanguageExpertise}
                    allowCustomValue={false}
                    // fields={{ text: "language", value: "language" }}
                    dataSource={languages}
                    cssClass="multi-without-parent e-outline customCss"
                  />
                )}
              </div>
              <div className="col-md-4">
                <NumericTextField
                  formik={formik}
                  format="n0"
                  min={0}
                  id="researchPapersAuthored"
                  name="researchPapersAuthored"
                  placeholder="Research Papers Authored"
                />
              </div>
              <div className="col-md-4">
                <NumericTextField
                  formik={formik}
                  format="n0"
                  min={0}
                  id="videoConsultingCompleted"
                  placeholder="Video Consulting Completed"
                  name="videoConsultingCompleted"
                />
              </div>
              <div className="col-md-4">
                <NumericTextField formik={formik} format="n0" min={0} id="surveyCompleted" placeholder="Survey Completed" name="surveyCompleted" />
              </div>
              <div className="col-md-4">
                <NumericTextField
                  formik={formik}
                  format="n0"
                  min={0}
                  id="workshopCompleted"
                  placeholder="Workshop completed"
                  name="workshopCompleted"
                />
              </div>
            </div>
          </Accordion>
          <Accordion
            heading="Bank Details"
            step={userId ? 6 : 4}
            stepCompleted={userId && formik.values.bankName && formik.values.payPalId ? true : false}
            activeState={!editUser}
          >
            <div className="row p-3">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <TextField formik={formik} type="text" id="bankName" placeholder="Bank Name" name="bankName" />
                  </div>
                  <div className="col-md-6">
                    <TextField formik={formik} format="n0" min={0} id="bankAccountNo" placeholder="Account No." name="bankAccountNo" />
                  </div>
                  <div className="col-md-6">
                    <TextField formik={formik} type="text" id="payPalId" placeholder="PayPal ID" name="payPalId" />
                  </div>
                  <div className="col-md-6">
                    <Dropdown
                      formik={formik}
                      id="currency"
                      name="currency"
                      placeholder="Currency of Payment"
                      dataSource={CURRENCY}
                      // fields={{ text: "field", value: "id" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      formik={formik}
                      multiline={true}
                      rows={3}
                      type="text"
                      id="bankPaymentDetails"
                      placeholder="Bank Details for Payment"
                      name="bankPaymentDetails"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        <div className="form-submit-box">
          <div className="container-fluid">
            <div className="row">
              <div className="float-right col-md-12 text-right">
                <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 mr-3" onClick={() => history.push("/selector")}>
                  Cancel
                </button>
                {accessActionSave && (
                  <button type="button" onClick={saveUser} className="btn btn-primary cy-btn pl-3 pr-3 mr-3">
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
