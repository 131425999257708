/**
 * File Description: Component to render a survey
 * Author: Suraj Varane
 * Date: 24/Mar/2021
 */

import React, { useEffect, useState } from "react";
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import { useDispatch } from "react-redux";
import SURVEY_CONSTANT from "../../constants";
import { getSurveyDataForRender, submitSurveyResponse } from "../../../../../middleware/services/surveyApi";
import { showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import "nouislider/distribute/nouislider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";

import $ from "jquery";
import "jquery-bar-rating";

// theme colors
const mainColor = "#fa4f5f";
const mainHoverColor = "#fa4f5f"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray
widgets.jquerybarrating(Survey, $);
widgets.nouislider(Survey);
widgets.sortablejs(Survey);
let showdown = require("showdown");
const SurveyRender = (props) => {
  const surveyShortName = props.computedMatch?.params?.surveyShortName;
  const surveyAudienceCode = props.computedMatch?.params?.surveyAudienceCode || "";

  const [formJson, setFormJson] = useState();
  const [surveyData, setSurveyData] = useState({});
  const [surveyAudienceData, setSurveyAudienceData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [showDraftMessage, setShowDraftMessage] = useState(false);

  const dispatch = useDispatch();

  let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColorsSurvey["$main-color"] = mainColor;
  defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
  defaultThemeColorsSurvey["$text-color"] = textColor;
  defaultThemeColorsSurvey["$header-color"] = headerColor;
  defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
  Survey.StylesManager.applyTheme("default");

  //Create showdown markdown converter
  const converter = new showdown.Converter();

  // load survey data
  const loadSurvey = () => {
    if (surveyShortName) {
      const form = getSurveyDataForRender({
        surveyShortName: surveyShortName,
        ...(surveyAudienceCode && { surveyAudienceCode: surveyAudienceCode }),
      });
      form.then((res) => {
        if (res?.data?.data) {
          if (res?.data?.data?.surveyForm) {
            setFormJson(res.data.data.surveyForm);
            setSurveyData({
              surveyTitle: res.data.data.surveyTitle,
              surveyId: res.data.data.surveyId,
              surveyAudience: res.data.data.surveyAudienceType,
            });
            if (res?.data?.data?.surveyAudience) {
              setSurveyAudienceData(res?.data?.data?.surveyAudience);
            }
            if (res?.data?.data?.surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.DRAFT) {
              setShowDraftMessage(true);
            }
          }
        } else {
          setErrorMessage("Unable to process your request. Please contact system administrator (product.support@cheersin.com)!!!.");
        }
      });
      form.catch((e) => {
        console.error("api error", e);
        setErrorMessage(e.data.message);
      });
    }
  };
  // Submit survey
  const submitSurvey = (data) => {
    saveSurvey(data.valuesHash);
  };
  // Save Survey Data
  const saveSurvey = async (surveyResponse) => {
    dispatch(showLoader());
    try {
      let response = "";
      response = submitSurveyResponse(surveyData.surveyId, {
        userSurveyResponse: surveyResponse,
        userSurveyAudience: surveyData.surveyAudience,
        ...(surveyAudienceCode && { userSurveyCode: surveyAudienceCode }),
        ...surveyAudienceData,
      });
      response
        .then(() => {
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e.data.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)...!"));
    }
  };

  // Effect hooks
  useEffect(() => {
    loadSurvey();
  }, []);

  const survey = formJson && new Survey.Model(formJson);
  formJson &&
    survey.onTextMarkdown.add(function (survey, options) {
      //convert the mardown text to html
      let str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.replace(/<\/?p[^>]*>/g, "");
      //set html
      options.html = str;
    });

  // render method
  return (
    <div className="p-2 container">
      {formJson ? (
        <>
          {showDraftMessage && (
            <h3>
              <center>Survey is under review phase and is not published yet</center>
            </h3>
          )}
          <div className="">
            <div id="content-box">
              <div className="container-fluid custom-user-basic-bg survey-render mb-3">
                <div className="p-2">
                  <div className="row">
                    <Survey.Survey model={survey} onComplete={submitSurvey} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="col-md-12 text-center p-2 border rounded-top">
          <h3>{errorMessage}</h3>
        </div>
      )}
    </div>
  );
};
export default SurveyRender;
