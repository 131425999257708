/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 06/Aug/2021
 * File Description:  Add / Edit Colaboration Modal
 */
import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { getProductVariant, accessRightActionCheck } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { collaborationValidationSchema } from "../../validation/companyValidationSchema";
import TreeView from "../../../../components/treeView/trend/collaborationTrend";
import {
  fetchSingleCollaboration,
  saveCollaboration,
  updateCollaboration,
  fetchSingleCompany,
  fetchCompany,
  fetchCollaborationTrend,
  saveCollaborationTrend,
  deleteCollaborationTrend,
  saveFunding,
  deleteManycollaborationTrend,
} from "../../../../../middleware/services/companyApi";
import { fetchTaxonomyTechnology, fetchTaxonomyTrend, fetchTaxonomyIndustry, fetchCountry } from "../../../../../middleware/services/cmsApi";

import {
  Dropdown,
  TextField,
  TextEditor,
  AutoCompleteDropdown,
  DatePicker,
  MultiSelectNew,
} from "../../../../components/CustomFormElements/syncFusionFormFields";
import { COLLABORATION_TYPE, PRODUCT_VARIANT, COMMON_ACCESS_ACTION } from "../../../../../constants";
import fundingTypeOptions, { COLLABORATION_TYPES } from "../../constants";
import { Modal } from "react-bootstrap";

const initialState = {
  pVariant: getProductVariant(),
  collaborationName: "",
  collaborationType: "",
  collaborationDealValue: "",
  collaborationDate: "",
  collaborationDescription: "",
  collaborationTechnologyPurpose: "",
  collaborationPartnerSource: [],
  collaborationPartnerTarget: [],
  collaborationSourceUrl: "",
  collaborationCountry: "",
  collaborationRegion: "",
  collaborationTechnologyTags: [],
  collaborationIndustryTags: [],
  collaborationTrendIndustryTags: [],
  collaborationIndustryTrendsTags: [],
  undisclosed: "Undisclosed",
  fundingTypeRound: "",
};
const CollaborationDtls = (props) => {
  const dispatch = useDispatch();
  const companyId = props?.computedMatch?.params?.companyId;
  const collaborationId = props?.computedMatch?.params?.collaborationId || "";
  const [companyData, setCompanyData] = useState(null);
  const [initialCompanies, setInitialCompanies] = useState([]);
  const [sourceCompanies, setSourceCompanies] = useState([]);
  const [targetCompanies, setTargetCompanies] = useState([]);
  const [companyDataSource, setCompanyDataSource] = useState([{ value: "Undisclosed", label: "Undisclosed" }]);
  const [collaborationData, setCollaborationData] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [technologies, setTechnologies] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [contentLength, setContentLength] = useState(0);
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [trends, setTrends] = useState([]);
  const [displayUpdateMessageFlag, setDisplayUpdateMessageFlag] = useState(true);
  const [industry, setIndustry] = useState([]);
  const [trendsMaster, setTrendsMaster] = useState([]);
  const [initialCollaborationName, setInitialCollaborationName] = useState("");
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [sourcePartnerLabel, setSourcePartnerLabel] = useState("Collaboration Partners Source *");
  const [targetPartnerLabel, setTargetPartnerLabel] = useState("Collaboration Partners Target *");

  const sourceRoles = ["Acquirer", "Adopter", "Investor", "Licenser", "Source"];
  const targetRoles = ["Acquiree", "Adoptee", "Investee", "Licensee", "Target"];

  const history = useHistory();

  const productVariant = getProductVariant();
  const { accessRights: aR } = props;
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  /* Interface functionality wise access  */
  useEffect(() => {
    let interfaceName = "Add New Collaboration";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessPermissionTaxonomyDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);

  useEffect(() => {
    if (saveTreeDataAction) {
      setSaveTreeData(true);
    }
  }, [saveTreeDataAction]);

  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(collaborationValidationSchema),
  });
  const breadCrumbLinks = [
    { linkUrl: "/company", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: companyId ? `/company/${companyId}/edit#collaboration` : "/company/edit#collaboration",
      linkName: companyData?.companyName || "",
      linkActive: false,
    },
    { linkUrl: `/company/${companyId}/edit#collaboration`, linkName: "Collaboration", linkActive: false },
    {
      linkUrl: companyId ? `/company/${companyId}/edit#collaboration` : "/company/edit#collaboration",
      linkName: collaborationData?.collaborationName || "Add",
      linkActive: true,
    },
  ];

  const handleSave = (selectedTech, checkValues, callback, sgfData) => {
    saveTrend(selectedTech, checkValues, callback, sgfData);
  };

  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData) => {
    if (!collaborationId && !taxonomyIds.length) {
      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        }
      });
      dispatch(actionError("Please select Trend Taxonomy"));
    } else if (!collaborationId && taxonomyIds.length) {
      saveCollaborationDetails();
    } else if (collaborationId && selectedTech) {
      fetchCollaborationTrend(collaborationId).then((resp) => {
        let trendParentIds = [];
        let techCount = 0;
        let noTaxonomySaveFlag = false;
        let trendParents = [];
        let trendIds = [];
        resp.data.data.forEach((ele) => {
          if (ele.trendParentId === 0) {
            trendParents.push(ele.trendId);
          } else {
            if (trendParents.includes(ele.trendParentId)) {
              techCount = techCount + 1;
              trendParentIds.push(ele.trendParentId);
            }
          }
          trendIds.push(ele.trendId);
        });

        trendParentIds = [...new Set(trendParentIds)];

        if (!trendIds.includes(selectedTech) || trendParentIds.length > 1 || techCount > 1) noTaxonomySaveFlag = true;

        if (noTaxonomySaveFlag || taxonomyIds.length > 0) {
          deleteCollaborationTrend(collaborationId, selectedTech)
            .then(() => {
              if (noTaxonomySaveFlag || taxonomyIds.length > 0) {
                try {
                  const response = saveCollaborationTrend(collaborationId, { taxonomyIds: taxonomyIds, sgfData: sgfData });
                  response
                    .then((response) => {
                      callback();
                      saveCollaborationDetails();
                    })
                    .catch((e) => {
                      dispatch(actionError("Something Went Wrong...!"));
                    });
                } catch (e) {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                }
              } else {
                dispatch(actionError("Please select Trend Taxonomy"));
                setSaveTreeDataAction(false);
                setSaveTreeData(false);
              }
            })
            .catch((e) => {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            });
        } else {
          dispatch(actionError("Please select Trend Taxonomy"));
          setSaveTreeDataAction(false);
          setSaveTreeData(false);
        }
      });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const searchCompany = async (val) => {
    if (val.length > 2) {
      let filters = [];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "cn", val], ["productVariant.isLive", "eq", "YES"]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = await fetchCompany({ filters: filters, ...fields, sort: "companyName:asc", limit: 10 });
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { label: v.companyName, value: v.id };
        });

        let combinedPartners = [...formik.values.collaborationPartnerSource, ...formik.values.collaborationPartnerTarget];

        if (resData && resData != combinedPartners) {
          let selectedLabels = [...combinedPartners].splice(initialCompanies.length, combinedPartners.length);
          let labels = [...resData, ...selectedLabels, { value: "Undisclosed", label: "Undisclosed" }];
          setCompanyDataSource(labels);
        } else {
          setCompanyDataSource([
            { value: "Undisclosed", label: "Undisclosed" },
            { value: val, label: val },
          ]);
        }
      }
    }
  };

  /**
   * @description fetch technologies to tag with user
   */
  const fetchTechnologies = () => {
    fetchTaxonomyTechnology({ filters: [["dtParentId", "eq", 0]], fields: ["id", "dtName"], sort: "dtName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTechnologies(data.map((d) => ({ dtId: d.id, dtName: d.dtName })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Technology Failed";
        dispatch(actionError(errMsg));
      });
  };

  /*
    @Description : Fetch all trend
    */
  const fetchTrend = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({
      filters: [
        ["isTrendNode", "eq", 1],
        ["trendParentId", "eq", formik.values.collaborationTrendIndustryTags],
      ],
      sort: "trendName:asc",
      limit: 2000,
    })
      .then((res) => {
        dispatch(hideLoader());
        const data = [...res.data.data];
        const tech =
          data &&
          data.map((v) => {
            return { trendId: v.id, trendName: v.trendName };
          });
        setTechnology(tech);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log("err fetchTrend :: ", err);
      });
  };

  /**
   * @description fetch trends to tag with user
   */
  const fetchTrends = () => {
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTrendsMaster(data);
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, trendName: sTT.trendParentName };
        });
        setTrends([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Trend Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch industry to tag with user
   */
  const fetchIndustry = () => {
    fetchTaxonomyIndustry({ fields: ["industryName"], sort: "industryName:asc", limit: 50 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.id] = { id: sTT.id, industryName: sTT.industryName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
        dispatch(actionError(errMsg));
      });
  };
  const getCompany = () => {
    let params = {};
    let fields = ["companyName"];
    params.fields = fields;
    fetchSingleCompany(companyId, params).then((res) => {
      let data = res.data.data || {};
      setCompanyData(data);
    });
  };

  const saveCollaborationDetails = async () => {
    formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        setBtnDisabled(true);
        let payload = {};
        const {
          collaborationName,
          collaborationDescription,
          collaborationType,
          collaborationDealValue,
          collaborationDate,
          collaborationRegion,
          collaborationCountry,
          collaborationSourceUrl,
          collaborationTechnologyPurpose,
          collaborationTechnologyTags,
          collaborationIndustryTags,
          collaborationTrendIndustryTags,
          collaborationIndustryTrendsTags,
          fundingTypeRound,
        } = formik.values;

        let { collaborationPartnerSource, collaborationPartnerTarget } = formik.values;

        let companyWithNoIds = collaborationPartnerSource
          .filter((c) => {
            return c.label === c.value && c.label !== "Undisclosed";
          })
          .map((c) => {
            return c.label;
          });
        companyWithNoIds = companyWithNoIds.concat(
          collaborationPartnerTarget
            .filter((c) => {
              return c.label === c.value && c.label !== "Undisclosed";
            })
            .map((c) => {
              return c.label;
            })
        );
        if (companyWithNoIds.length > 0) {
          let filters = [];
          if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
          filters.push(["companyName", "in", companyWithNoIds], ["productVariant.isLive", "eq", "YES"]);
          let fields = { fields: ["companyId", "companyName"] };
          let companies = await fetchCompany({ filters: filters, ...fields, sort: "companyName:asc", limit: companyWithNoIds.length });
          if (companies.data.data) {
            companies = companies.data.data;
            collaborationPartnerSource.forEach((c) => {
              if (c.label === c.value) {
                const companyExist = companies.filter((comp) => {
                  return comp.companyName === c.label;
                });
                if (companyExist[0]) {
                  c.value = companyExist[0].id;
                }
              }
            });
            collaborationPartnerTarget.forEach((c) => {
              if (c.label === c.value) {
                const companyExist = companies.filter((comp) => {
                  return comp.companyName === c.label;
                });
                if (companyExist[0]) {
                  c.value = companyExist[0].id;
                }
              }
            });
          }
        }

        let collaborationPartnerSourceRole,
          collaborationPartnerTargetRole,
          mainCompanyPresentFlag = false;

        if (collaborationType === COLLABORATION_TYPES.ACQUISITION) {
          collaborationPartnerSourceRole = "Acquirer";
          collaborationPartnerTargetRole = "Acquiree";
        }
        if (collaborationType === COLLABORATION_TYPES.TECHNOLOGY_ADOPTION) {
          collaborationPartnerSourceRole = "Adopter";
          collaborationPartnerTargetRole = "Adoptee";
        }
        if (collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT) {
          collaborationPartnerSourceRole = "Investor";
          collaborationPartnerTargetRole = "Investee";

          if (
            collaborationPartnerTarget.length != 1 ||
            (collaborationPartnerTarget.length == 1 && collaborationPartnerTarget[0].label == "Undisclosed")
          ) {
            setShowModalAdd(true);
            setBtnDisabled(false);
            return;
          }
        }
        if (collaborationType === COLLABORATION_TYPES.IN_LICENSING) {
          collaborationPartnerSourceRole = "Licenser";
          collaborationPartnerTargetRole = "Licensee";
        }
        if (
          collaborationType === COLLABORATION_TYPES.COMMERCIAL_PARTNERSHIP ||
          collaborationType === COLLABORATION_TYPES.TECHNOLOGY_CO_DEVELOPMENT ||
          collaborationType === COLLABORATION_TYPES.PARTNERSHIP_JOINT_VENTURE
        ) {
          collaborationPartnerSourceRole = "Source";
          collaborationPartnerTargetRole = "Target";
        }

        collaborationPartnerSource = collaborationPartnerSource.map((c) => {
          if (companyId === c.value) {
            mainCompanyPresentFlag = true;
          }

          if (c.label === c.value) {
            return {
              companyName: c.value,
              collaborationPartnerRole: collaborationPartnerSourceRole,
            };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
              collaborationPartnerRole: collaborationPartnerSourceRole,
            };
          }
        });

        collaborationPartnerTarget = collaborationPartnerTarget.map((c) => {
          if (companyId === c.value) {
            mainCompanyPresentFlag = true;
          }

          if (c.label === c.value) {
            return {
              companyName: c.value,
              collaborationPartnerRole: collaborationPartnerTargetRole,
            };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
              collaborationPartnerRole: collaborationPartnerTargetRole,
            };
          }
        });

        if (!mainCompanyPresentFlag) {
          dispatch(actionError("Current company must be present in either of the collaboration partners fields"));
          setBtnDisabled(false);
          return;
        }

        let collaborationPartners = [...collaborationPartnerSource, ...collaborationPartnerTarget];

        let collaborationDate1 = new Date(collaborationDate);
        collaborationDate1.setHours(collaborationDate1.getHours() + 1);
        payload.collaborationName = collaborationName;
        payload.collaborationDescription = collaborationDescription;
        payload.collaborationType = collaborationType;
        payload.collaborationDealValue = collaborationDealValue;
        payload.collaborationDealCurrency = "USD";
        payload.collaborationDate = collaborationDate ? collaborationDate1 : "";
        payload.collaborationPartners = collaborationPartners;

        if (collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT) {
          payload.collaborationSubType = fundingTypeRound;
        }

        if (collaborationCountry) {
          payload.collaborationCountry = collaborationCountry;
        }
        if (collaborationSourceUrl) {
          payload.collaborationSourceUrl = collaborationSourceUrl;
        }
        if (collaborationRegion) {
          payload.collaborationRegion = collaborationRegion;
        }
        if (collaborationTechnologyPurpose) {
          payload.collaborationTechnologyPurpose = collaborationTechnologyPurpose;
        }
        if (productVariant === PRODUCT_VARIANT.INSIDER) {
          // if (collaborationIndustryTags) {
          //     payload.collaborationIndustryTags = trends.map(t => { return collaborationIndustryTags.includes(t.id) && { industryId: t.id, industryName: t.trendName } }).filter(f => { return f !== false })
          // }
          // if (collaborationIndustryTrendsTags) {
          //     payload.collaborationIndustryTrendsTags = technology.map(t => { return collaborationIndustryTrendsTags.includes(t.trendId) && { trendId: t.trendId, trendName: t.trendName } }).filter(f => { return f !== false })
          // }
        } else if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
          if (collaborationIndustryTags) {
            payload.collaborationIndustryTags = industry
              .map((t) => {
                return collaborationIndustryTags.includes(t.id) && { industryId: t.id, industryName: t.industryName };
              })
              .filter((f) => {
                return f !== false;
              });
          }
          if (collaborationTechnologyTags) {
            payload.collaborationTechnologyTags = technologies
              .map((t) => {
                return collaborationTechnologyTags.includes(t.dtId) && { dtId: t.dtId, dtName: t.dtName };
              })
              .filter((f) => {
                return f !== false;
              });
          }
        }

        if (collaborationId) {
          updateCollaboration(collaborationId, payload)
            .then((res) => {
              if (displayUpdateMessageFlag) dispatch(actionSuccess("Collaboration details updated successfully"));
              setDisplayUpdateMessageFlag(true);
              setBtnDisabled(false);
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
              setBtnDisabled(false);
            });
        } else {
          payload.companyId = companyId;
          payload.companyName = companyData?.companyName;
          payload.collaborationStatus = "Active";

          saveCollaboration(payload)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Collaboration details saved successfully"));
                setDisplayUpdateMessageFlag(false);
                setBtnDisabled(false);
                // setTimeout(() => {
                history.push({
                  pathname: `/company/${companyId}/collaboration/${res.data.data.id}/edit`,
                  state: { collaborationId: res.data.data.id },
                });
                setSaveTreeDataAction("save");
                setCollaborationData(payload);
                // }, 1000);
              } else {
                dispatch(actionError("something went wrong"));
                setBtnDisabled(false);
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
              setBtnDisabled(false);
            });
        }

        if (collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT) {
          let payload = {};
          payload.fundingName = collaborationName;
          payload.fundingDate = collaborationDate1;
          payload.fundingTypeRound = fundingTypeRound;
          payload.fundingAmount = collaborationDealValue;
          payload.fundingOnCompanyId = collaborationPartnerTarget.map((e) => {
            return {
              companyId: e.companyId,
              companyName: e.companyName,
            };
          });
          payload.fundingInvestors = collaborationPartnerSource.map((e) => {
            return {
              companyId: e.companyId,
              companyName: e.companyName,
            };
          });
          payload.fundingComment = collaborationDescription;

          saveFunding(payload)
            .then((response) => {})
            .catch((err) => {});
        }
      }
    });
  };

  /**
     /**
      * @description fetch countries to tag with user
      */

  const searchCountry = useCallback(
    debounce(async (e) => {
      let filters = [];
      filters.push(["countryName", "cn", e.text]);
      const response = e.text.length > 1 && (await fetchCountry({ filters: filters, fields: ["countryName"], sort: "countryName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return v.countryName;
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  /**
   * @description fetch single collaboration detail
   * @param {String} collaborationId
   */
  const fetchCollaborationDetail = async () => {
    dispatch(showLoader());
    collaborationId &&
      (await fetchSingleCollaboration(collaborationId)
        .then((res) => {
          const collaborationDetail = { ...res.data.data };
          setCollaborationData(collaborationDetail);
          setInitialCollaborationName(collaborationDetail.collaborationName);

          collaborationDetail &&
            formik.setValues({
              collaborationName: collaborationDetail.collaborationName || "",
              collaborationType: collaborationDetail.collaborationType || "",
              collaborationDealValue: collaborationDetail.collaborationDealValue || "",
              collaborationDate: collaborationDetail.collaborationDate || "",
              collaborationDescription: collaborationDetail.collaborationDescription || "",
              collaborationStatusComment: collaborationDetail.collaborationStatusComment || "",
              collaborationTechnologyPurpose: collaborationDetail.collaborationTechnologyPurpose || "",
              collaborationPartnerSource: collaborationDetail.collaborationPartners
                ? [
                    ...collaborationDetail.collaborationPartners.map((cC) => {
                      if (sourceRoles.includes(cC.collaborationPartnerRole)) {
                        let cCV = { label: cC.companyName };
                        if (cC.companyId) {
                          cCV.value = cC.companyId;
                        } else {
                          cCV.value = cC.companyName;
                        }
                        cCV.collaborationPartnerRole = cC.collaborationPartnerRole;
                        return cCV;
                      }
                    }),
                  ].filter(function (element) {
                    return element !== undefined;
                  })
                : [],
              collaborationPartnerTarget: collaborationDetail.collaborationPartners
                ? [
                    ...collaborationDetail.collaborationPartners.map((cC) => {
                      if (targetRoles.includes(cC.collaborationPartnerRole)) {
                        let cCV = { label: cC.companyName };
                        if (cC.companyId) {
                          cCV.value = cC.companyId;
                        } else {
                          cCV.value = cC.companyName;
                        }
                        cCV.collaborationPartnerRole = cC.collaborationPartnerRole;
                        return cCV;
                      }
                    }),
                  ].filter(function (element) {
                    return element !== undefined;
                  })
                : [],
              collaborationSourceUrl: collaborationDetail.collaborationSourceUrl || "",
              collaborationCountry: collaborationDetail.collaborationCountry || "",
              collaborationRegion: collaborationDetail.collaborationRegion || "",
              collaborationIndustryTrendsTags: collaborationDetail.collaborationIndustryTrendsTags || "",
              undisclosed: "Undisclosed",
              fundingTypeRound: collaborationDetail.collaborationSubType || "",
            });

          setInitialCompanies([
            ...collaborationDetail.collaborationPartners.map((cC) => {
              let cCV = { label: cC.companyName };
              if (cC.companyId) {
                cCV.value = cC.companyId;
              } else {
                cCV.value = cC.companyName;
              }
              cCV.collaborationPartnerRole = cC.collaborationPartnerRole;
              return cCV;
            }),
          ]);

          let initialSourceComapnies = [],
            initialTargetCompanies = [];

          collaborationDetail.collaborationPartners.forEach((cC) => {
            if (sourceRoles.includes(cC.collaborationPartnerRole)) {
              let cCV = { label: cC.companyName };
              if (cC.companyId) {
                cCV.value = cC.companyId;
              } else {
                cCV.value = cC.companyName;
              }
              cCV.collaborationPartnerRole = cC.collaborationPartnerRole;
              initialSourceComapnies.push(cCV);
            } else if (targetRoles.includes(cC.collaborationPartnerRole)) {
              let cCV = { label: cC.companyName };
              if (cC.companyId) {
                cCV.value = cC.companyId;
              } else {
                cCV.value = cC.companyName;
              }
              cCV.collaborationPartnerRole = cC.collaborationPartnerRole;
              initialTargetCompanies.push(cCV);
            }
          });

          setSourceCompanies(initialSourceComapnies);
          setTargetCompanies(initialTargetCompanies);

          productVariant === PRODUCT_VARIANT.WHATNEXT &&
            formik.setFieldValue("collaborationTechnologyTags", collaborationDetail?.collaborationTechnologyTags[0]?.dtId);
          productVariant === PRODUCT_VARIANT.WHATNEXT &&
            formik.setFieldValue("collaborationIndustryTags", collaborationDetail?.collaborationIndustryTags[0]?.industryId);

          dispatch(hideLoader());
        })
        .catch((err) => {
          console.log("err", err);
          let errMsg = err?.data?.message ? err?.data?.message : "Fetch Collaboration Details Failed";
          dispatch(actionError(errMsg));
        }));
  };

  useEffect(() => {
    setContentLength(formik.values.collaborationDescription.length);
  }, [formik.values.collaborationDescription]);

  useEffect(() => {
    if (formik.values.collaborationType === COLLABORATION_TYPES.ACQUISITION) {
      setSourcePartnerLabel("Collaboration Partners Acquirer *");
      setTargetPartnerLabel("Collaboration Partners Acquiree *");
    }
    if (formik.values.collaborationType === COLLABORATION_TYPES.TECHNOLOGY_ADOPTION) {
      setSourcePartnerLabel("Collaboration Partners Adopter *");
      setTargetPartnerLabel("Collaboration Partners Adoptee *");
    }
    if (formik.values.collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT) {
      setSourcePartnerLabel("Collaboration Partners Investor *");
      setTargetPartnerLabel("Collaboration Partners Investee *");
    }
    if (formik.values.collaborationType === COLLABORATION_TYPES.IN_LICENSING) {
      setSourcePartnerLabel("Collaboration Partners Licenser *");
      setTargetPartnerLabel("Collaboration Partners Licensee *");
    }
    if (
      formik.values.collaborationType === COLLABORATION_TYPES.COMMERCIAL_PARTNERSHIP ||
      formik.values.collaborationType === COLLABORATION_TYPES.TECHNOLOGY_CO_DEVELOPMENT ||
      formik.values.collaborationType === COLLABORATION_TYPES.PARTNERSHIP_JOINT_VENTURE
    ) {
      setSourcePartnerLabel("Collaboration Partners Source *");
      setTargetPartnerLabel("Collaboration Partners Target *");
    }
  }, [formik.values.collaborationType]);

  useEffect(() => {
    companyId && getCompany();
  }, [companyId]);

  useEffect(() => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
      fetchTechnologies();
      fetchIndustry();
    } else if (productVariant === PRODUCT_VARIANT.INSIDER) {
      fetchTrends();
    }
    collaborationId && !collaborationData && fetchCollaborationDetail();
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/company"));
  }, [companyData, collaborationData, dispatch]);

  // delete taxonomy trend
  const deleteTrend = (checkedTrends, callback, callback2, selectedTech) => {
    deleteManycollaborationTrend(collaborationId, checkedTrends)
      .then((res) => {
        callback();
        callback2(selectedTech);
        dispatch(actionSuccess("deleted successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <>
      <div className="gennx-content-wrapper" style={{ height: "100%", background: "#ffffff" }}>
        <div className="p-4 ">
          <div>
            <div className="row p-1">
              <div className="col-md-6">
                <div className="customCss panel-title" style={{ width: "200px" }}>
                  {collaborationId ? "Update" : "Add New"} Collaboration
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <input type="hidden" name="pVariant" id="pVariant" value={productVariant} />
                <TextField formik={formik} type="text" id="collaborationName" name="collaborationName" placeholder="Collaboration Name *" />
              </div>
              {formik.values.collaborationType !== COLLABORATION_TYPES.FUNDING_INVESTMENT && (
                <div className="col-md-4">
                  <Dropdown
                    formik={formik}
                    id="collaborationType"
                    name="collaborationType"
                    placeholder="Collaboration Type *"
                    dataSource={COLLABORATION_TYPE}
                    // showClearButton={true}
                    allowFiltering={true}
                    filtering={() => {
                      return false;
                    }}
                  />
                </div>
              )}
              {formik.values.collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT && (
                <div className="col-md-2">
                  <Dropdown
                    formik={formik}
                    id="collaborationType"
                    name="collaborationType"
                    placeholder="Collaboration Type *"
                    dataSource={COLLABORATION_TYPE}
                    // showClearButton={true}
                    allowFiltering={true}
                    filtering={() => {
                      return false;
                    }}
                  />
                </div>
              )}
              {formik.values.collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT && (
                <div className="col-md-2">
                  <Dropdown
                    formik={formik}
                    id="fundingTypeRound"
                    name="fundingTypeRound"
                    placeholder="Funding Type *"
                    dataSource={fundingTypeOptions}
                    fields={{ text: "label", value: "value" }}
                  />
                </div>
              )}
              <div className="col-md-2" data-tip="Enter NA if Amount not available">
                <TextField
                  formik={formik}
                  format="c0"
                  min={0}
                  id="collaborationDealValue"
                  name="collaborationDealValue"
                  placeholder="Deal Value (In USD Millions) *"
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  formik={formik}
                  properties={{
                    placeholder: "Deal Date",
                    id: `collaborationDate`,
                    name: `collaborationDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    max: new Date(),
                  }}
                />
              </div>

              <div className="col-md-4" data-tip="Select Undisclosed from dropdown if company is undisclosed">
                <MultiSelectNew
                  formik={formik}
                  mode="Box"
                  allowCustomValue={true}
                  allowFiltering={true}
                  sortOrder="Acending"
                  showSelectAll={true}
                  required=""
                  placeholder={sourcePartnerLabel}
                  id="collaborationPartnerSource"
                  name="collaborationPartnerSource"
                  value={sourceCompanies?.map((v) => {
                    return v.label;
                  })}
                  fields={{ text: "label", value: "value" }}
                  filtering={(e) => {
                    searchCompany(e.text);
                  }}
                  // noRecordsTemplate={() => <div className="norecord">Please type at least 3 characters to search company</div>}
                  dataSource={companyDataSource}
                />
                <ReactTooltip />
              </div>

              <div className="col-md-4" data-tip="Select Undisclosed from dropdown if company is undisclosed">
                <MultiSelectNew
                  formik={formik}
                  mode="Box"
                  allowCustomValue={true}
                  allowFiltering={true}
                  sortOrder="Acending"
                  showSelectAll={true}
                  required=""
                  placeholder={targetPartnerLabel}
                  id="collaborationPartnerTarget"
                  name="collaborationPartnerTarget"
                  value={targetCompanies?.map((v) => {
                    return v.label;
                  })}
                  fields={{ text: "label", value: "value" }}
                  filtering={(e) => {
                    searchCompany(e.text);
                  }}
                  // noRecordsTemplate={() => <div className="norecord">Please type at least 3 characters to search company</div>}
                  dataSource={companyDataSource}
                />
                <ReactTooltip />
              </div>

              <div className="col-md-2">
                <AutoCompleteDropdown
                  formik={formik}
                  placeholder="Country"
                  id="collaborationCountry"
                  name="collaborationCountry"
                  autocomplete="off"
                  // value={formik.values.country}
                  allowFiltering={true}
                  filtering={searchCountry}
                  autofill={true}

                  // allowCustom ={false}
                />
              </div>
              <div className="col-md-2">
                <TextField formik={formik} type="text" id="collaborationRegion" placeholder="Region" name="collaborationRegion" />
              </div>
              <div className="col-md-4">
                <TextField formik={formik} type="url" id="collaborationSourceUrl" placeholder="Source URL" name="collaborationSourceUrl" />
              </div>

              {productVariant !== PRODUCT_VARIANT.INSIDER ? (
                <>
                  <div className="col-md-4">
                    {technologies.length > 0 && (
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Technology *"
                        id="collaborationTechnologyTags"
                        name="collaborationTechnologyTags"
                        value={formik.values.collaborationTechnologyTags}
                        allowCustomValue={false}
                        fields={{ text: "dtName", value: "dtId" }}
                        dataSource={technologies}
                      />
                    )}
                  </div>

                  <div className="col-md-4">
                    {industry.length > 0 && (
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Industry *"
                        id="collaborationIndustryTags"
                        name="collaborationIndustryTags"
                        value={formik.values.collaborationIndustryTags}
                        allowCustomValue={false}
                        fields={{ text: "industryName", value: "id" }}
                        dataSource={industry}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
            <div className="row">
              {productVariant === PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-12">
                  <TreeView
                    isCollaborationTaxonomy={true}
                    handleSave={handleSave}
                    saveTreeData={saveTreeData}
                    contentId={collaborationId}
                    isDelete={true}
                    fetchContentTagging={fetchCollaborationTrend}
                    loadOtherTagInfo={props.loadOtherTagInfo}
                    setSaveTreeDataAction={setSaveTreeDataAction}
                    deleteTrend={deleteTrend}
                    accessPermissionTaxonomyDelete={accessPermissionTaxonomyDelete}
                  />
                </div>
              ) : null}
            </div>
            {productVariant === PRODUCT_VARIANT.INSIDER ? (
              <div className="row" style={{ paddingTop: "1em" }}>
                <div className="col-md-6">
                  <label style={{ top: "20px", position: "relative" }}>Collaboration Description *</label>
                </div>
                <div className="col-md-6">
                  <label style={{ top: "20px", position: "relative" }}>Technology Purpose</label>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <label style={{ top: "20px", position: "relative" }}>Collaboration Description *</label>
                </div>
                <div className="col-md-6">
                  <label style={{ top: "20px", position: "relative" }}>Technology Purpose</label>
                </div>
              </div>
            )}
            {productVariant === PRODUCT_VARIANT.INSIDER ? (
              <div className="row" style={{ paddingBottom: "10vh" }}>
                <div className="col-md-6">
                  <TextEditor
                    formik={formik}
                    properties={{
                      id: "collaborationDescription",
                      name: "collaborationDescription",
                      activeClass: "collaborationDescription",
                      config: { ...ckEditorMinimalConfig, editorplaceholder: "Collaboration Description", height: 200 },
                    }}
                  />
                  <span className="float-right">{`${contentLength}/500`}</span>
                </div>

                <div className="col-md-6">
                  <TextEditor
                    formik={formik}
                    properties={{
                      id: "collaborationTechnologyPurpose",
                      name: "collaborationTechnologyPurpose",
                      activeClass: "collaborationTechnologyPurpose",
                      config: { ...ckEditorMinimalConfig, editorplaceholder: "Technology Purpose", height: 200 },
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <TextEditor
                    formik={formik}
                    properties={{
                      id: "collaborationDescription",
                      name: "collaborationDescription",
                      activeClass: "collaborationDescription",
                      config: { ...ckEditorMinimalConfig, editorplaceholder: "Collaboration Description", height: 200 },
                    }}
                  />
                  <span className="float-right">{`${contentLength}/500`}</span>
                </div>

                <div className="col-md-6">
                  <TextEditor
                    formik={formik}
                    properties={{
                      id: "collaborationTechnologyPurpose",
                      name: "collaborationTechnologyPurpose",
                      activeClass: "collaborationTechnologyPurpose",
                      config: { ...ckEditorMinimalConfig, editorplaceholder: "Technology Purpose", height: 200 },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-submit-box">
          <div className="container-fluid nopadding">
            <div className="row">
              <div className="float-right col-md-12 text-right">
                <Link to={`/company/${companyId}/edit#collaboration` || history.location.pathname}>
                  <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                    Cancel
                  </button>
                </Link>
                {accessActionSave ? (
                  <button
                    type="button"
                    disabled={btnDisabled ? "disabled" : ""}
                    className="btn btn-primary cy-btn pl-4 pr-4 "
                    onClick={() => {
                      if (productVariant !== PRODUCT_VARIANT.INSIDER) saveCollaborationDetails();
                      else setSaveTreeDataAction("save");
                    }}
                  >
                    {collaborationId ? "Update" : "Save"}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              In the Investee field, only one company can be listed, and Undisclosed information is not allowed.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CollaborationDtls;
