/**
 * @author: Pankaj Kulshreshtha
 * @date : 21/Jun/2021
 * File Description: Assessment Form Detials Page
 */

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, MultiSelect } from "../../components/CustomFormElements/syncFusionFormFields";
import { CkEditor } from "../../components/CustomFormElements/ProductInformation";
import { ckEditorMinimalConfig } from "../../../config/ckEditorConfig";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getLoggedInUser } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { trainingVideoValidationSchema } from "../../helper/validationSchema/outSourcingValidationSchema";
import { saveVideo, fetchVideoById, updateVideo, fetchFunctionalExpertiseData } from "../../../middleware/services/cmsApi";

const initialState = {
  videoTitle: "",
  videoContent: "",
  videoUniqueCode: "",
  videoLink: "",
  videoFunctionalCompetency: "",
};

const VideoDetails = (props) => {
  const dispatch = useDispatch();
  const videoId = props.computedMatch?.params?.videoId || "";
  const [editData, setEditData] = useState(null);
  const [functionalExpertise, setFunctionalExpertise] = useState(null);
  const history = useHistory();
  const loggedInUser = getLoggedInUser();

  const accessPermissionAdd = true;
  const accessPermissionEdit = true;

  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(trainingVideoValidationSchema),
  });

  const breadCrumbLinks = [
    { linkUrl: "/training-video", linkName: "Training Video", linkActive: false },
    {
      linkUrl: videoId ? `/training-video/${videoId}/edit` : "/training-video/add",
      linkName: (editData && editData?.videoTitle) || "Add New",
      linkActive: true,
    },
    // { linkUrl: "/assessment/form-designer#basic", linkName: "Basic", linkActive: true },
  ];
  const fetchBasicDetails = async () => {
    let params = {};
    let fields = ["videoUniqueCode", "videoTitle", "videoLink", "videoFunctionalCompetency", "videoContent"];
    params.fields = fields;
    videoId &&
      (await fetchVideoById(videoId, params).then((res) => {
        if (res) {
          let data = res.data.data || {};
          data && setEditData(data);
          data &&
            formik.setValues({
              videoTitle: data.videoTitle || "",
              videoUniqueCode: data.videoUniqueCode || "",
              videoLink: data.videoLink || "",
              videoContent: data.videoContent || "",
              videoFunctionalCompetency: data.videoFunctionalCompetency || "",
            });
          dispatch(hideLoader());
        }
      }));
  };

  /**
   * @description fetch functional Expertise
   */
  const fetchFunctionalExpertise = () => {
    fetchFunctionalExpertiseData({ sort: "parent:asc,expertise:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setFunctionalExpertise(data);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Functional Expertise Failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    fetchFunctionalExpertise();
    if (videoId) {
      dispatch(showLoader());
      if (!editData) fetchBasicDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/training-video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, videoId]);

  const saveVideoDetails = async () => {
    formik.validateForm().then((res) => {
      // console.log(res, formik)
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { videoTitle, videoLink, videoContent, videoFunctionalCompetency } = formik.values;

        payload.videoTitle = videoTitle;
        payload.videoLink = videoLink;
        payload.videoContent = videoContent;
        payload.videoFunctionalCompetency = videoFunctionalCompetency;

        // console.log(formik.values)
        if (videoId) {
          // payload.productVariant = [{ productName: productVariant }]
          updateVideo(videoId, payload)
            .then((res) => {
              // console.log(res.data);
              dispatch(actionSuccess("Video details updated successfully"));
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          payload.videoCreatedBy = loggedInUser._id;
          saveVideo(payload)
            .then((res) => {
              if (res) {
                let data = res.data.data || {};
                dispatch(actionSuccess("Video details saved successfully"));
                data &&
                  history.push({
                    pathname: `/training-video/${data.id}/edit`,
                    state: { videoId: res.data.data.id },
                  });
              } else {
                dispatch(actionError("something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };

  return (
    <>
      <div className="p-4">
        <div className="gennx-content-wrapper padding-bottom-150i">
          <div className="row p-1">
            <div className="col-md-6">
              <div className="customCss panel-title" style={{ width: "250px" }}>
                {videoId ? "Update" : "Add New"} Training Video
              </div>
            </div>
            {/* <div className="col-md-6">
                    <div className="" >Assessment Description *</div>
                    </div> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              {videoId && formik.values.videoUniqueCode && (
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      formik={formik}
                      readOnly
                      type="text"
                      id="videoUniqueCode"
                      placeholder="Video Unique Code *"
                      name="videoUniqueCode"
                      showClearButton={false}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <TextField formik={formik} type="text" id="videoTitle" placeholder="Video Title *" name="videoTitle" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField formik={formik} type="text" id="videoLink" placeholder="Video Link *" name="videoLink" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {functionalExpertise && (
                    <MultiSelect
                      formik={formik}
                      mode="Box"
                      // enableRtl={true}
                      sortOrder="Acending"
                      required="required"
                      placeholder="Functional Competency *"
                      id="videoFunctionalCompetency"
                      name="videoFunctionalCompetency"
                      value={formik.values["videoFunctionalCompetency"] && formik.values["videoFunctionalCompetency"]}
                      allowCustomValue={false}
                      fields={{ text: "expertise", value: "expertise", groupBy: "parent" }}
                      dataSource={functionalExpertise}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {
                <CkEditor
                  formik={formik}
                  required="required"
                  properties={{
                    id: "videoContent",
                    name: "videoContent",
                    activeClass: "videoContent",
                    label: "Video Content *",
                    config: { ...ckEditorMinimalConfig, editorplaceholder: "Video Content *", height: videoId ? 100 : 88 },
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="form-submit-box">
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-right col-md-12 text-right">
              <Link to={"/training-video" || history.location.pathname}>
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>
              {(accessPermissionAdd || (videoId && accessPermissionEdit)) && (
                <button
                  type="button"
                  className="btn btn-primary cy-btn pl-4 pr-4"
                  onClick={() => {
                    saveVideoDetails();
                  }}
                >
                  {videoId ? "Update" : "Save"}
                </button>
              )}
              {/* {videoId ? <button type="button" className="btn btn-primary pl-4 pr-4 ml-4"
                            onClick={() => {
                                dispatch(showAlertBox({
                                    okCallback: async () => {
                                        await publishAssessment();
                                    },
                                    content: "Are you sure, you want to Publish?"
                                }));
                            }
                            }
                        >Publish</button> : null
                        } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoDetails;
