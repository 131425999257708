/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Documents field validations
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 27/Jul/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { DropdownList } from "react-widgets";
import CKEditor from "ckeditor4-react";
import { ckEditorConfig } from "../../../../../config/ckEditorConfig";

const TextField = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <input
        className="form-control"
        autoComplete="off"
        {...props.formik.getFieldProps(...props.properties.name)}
        value={value}
        {...props.properties}
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.properties.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
      </div>
    </>
  );
};
const TextFieldInputGroup = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <input
      className="form-control"
      autoComplete="off"
      {...props.formik.getFieldProps(...props.properties.name)}
      value={value}
      {...props.properties}
    />
  );
};

const CkEditor = (props) => {
  const [content, setContent] = useState("");
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[props.properties.name] = newContent;
    props.formik.setFieldValue(props.properties.name, newContent);
  };

  useEffect(() => {
    let name = props.properties.name;
    setContent(props.formik.values[name] ? props.formik.values[name] : props.defaultContent ? props.defaultContent : "");
  }, [props.formik.values, props.properties.name]);

  return (
    <>
      <CKEditor activeClass="editor" data={content} onChange={onChange} config={{ ...ckEditorConfig }} {...props.properties} />
      <div className="text-danger" style={{ display: props.formik.touched[props.properties.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
      </div>
    </>
  );
};

const Dropdown = (props) => {
  const [value, setValue] = useState("");
  const onChange = (val) => {
    setValue(val);
    props.formik.setFieldValue(props.properties.name, val);
  };

  useEffect(() => {
    let name = props.properties.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.properties.name]);

  return (
    <DropdownList
      data={[...props.valueArr]}
      dropUp
      formik={props.formik}
      onChange={onChange}
      {...props.properties}
      value={value ? value : props.properties.value}
    />
  );
};

export { TextField, Dropdown, CkEditor, TextFieldInputGroup };
