import "./index.scss";
import { getProductVariant } from "../../../../../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../../middleware/actions/utilityAction";
import { fetchDeliverableSetting, updateDeliverableSetting } from "../../../../../../middleware/services/deliverablesApi";
import React, { useEffect, useState } from "react";
import { setDeliverableSettingDetails } from "../../../../../../middleware/actions/deliverableAction";
import { TextField, Dropdown, NumericTextField } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import "../../../../../components/accordion/index.scss";
import { fetchConfig, fetchFontFamily } from "../../../../../../middleware/services/cmsApi";

const Settings = (props) => {
  const [fontstate, setFontState] = useState(false);
  const [colorState, setcolorState] = useState(false);
  const [formattingState, setFormattingState] = useState(false);
  const [exportToPDFState, setExportToPDFState] = useState(false);
  const [exportToExcelState, setExportToExcelState] = useState(false);
  const [exportToSectionState, setExportToSectionState] = useState(false);
  const [exportToPowerPointState, setExportToPowerPointState] = useState(false);
  const [exportToChartState, setExportToChartState] = useState(false);
  const [applyPdfSettingState, setApplyPdfSettingState] = useState(false);
  const [applyExcelSettingState, setApplyExcelSettingState] = useState(false);
  const [applyPowerPointSettingState, setApplyPowerPointSettingState] = useState(false);
  const [applySectionSettingState, setApplySectionSettingState] = useState(false);
  const [applyChartsSettingState, setApplyChartsSettingState] = useState(false);
  const [fontFamilyList, setFontFamilyList] = useState("");
  const [colorList, setColorList] = useState("");

  const [pdfText, setPdfText] = useState("");

  const settingState = useSelector((state) => state.deliverableState.settingsDetails);

  const watermarkRotate = [
    {
      label: "0 Degree",
      value: 0,
    },
    {
      label: "30 Degrees",
      value: 30,
    },
    {
      label: "45 Degrees",
      value: 45,
    },
    {
      label: "60 Degrees",
      value: 60,
    },
    {
      label: "90 Degrees",
      value: 90,
    },
  ];

  const exportTypes = ["pdf", "excel", "powerPoint", "section", "charts"];

  const { formik } = props;
  const deliverableId = props.deliverableId;
  const dispatch = useDispatch();
  const activeState = false,
    forcedActiveState = false,
    stepCompleted = false;
  const [pdfActive, setPdfActive] = useState(activeState);
  const [excelActive, setExcelActive] = useState(activeState);
  const [powerPointActive, setPowerPointActive] = useState(activeState);
  const [sectionActive, setSectionActive] = useState(activeState);
  const [chartsActive, setChartsActive] = useState(activeState);

  useEffect(() => {
    if (!props.saveDeliverable) {
      {
        setSettingsData();
        getFontFamily();
        getWatermarkColors();
      }
    }
  }, [props.saveDeliverable]);

  const getFontFamily = async () => {
    dispatch(showLoader());
    fetchFontFamily({ sort: "name:asc" })
      .then((res) => {
        setFontFamilyList(res.data.data);
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };

  const getWatermarkColors = async () => {
    dispatch(showLoader());
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-colors"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let variantColors = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        setColorList(variantColors);
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };

  const setSettingsData = async () => {
    dispatch(showLoader());
    fetchDeliverableSetting(deliverableId)
      .then((res) => {
        setFontState(
          settingState?.allowChangeFont
            ? settingState?.allowChangeFont === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.allowChangeFont === "Yes"
            ? true
            : false
        );
        setcolorState(
          settingState?.allowChangeColor
            ? settingState?.allowChangeColor === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.allowChangeColor === "Yes"
            ? true
            : false
        );
        setFormattingState(
          settingState?.allowChangeFormatting
            ? settingState?.allowChangeFormatting === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.allowChangeFormatting === "Yes"
            ? true
            : false
        );
        setExportToPDFState(
          settingState?.exportAsPdf
            ? settingState?.exportAsPdf === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsPdf === "Yes"
            ? true
            : false
        );
        setApplyPdfSettingState(
          settingState?.applyPdfSetting
            ? settingState?.applyPdfSetting === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.applyPdfSetting === "Yes"
            ? true
            : false
        );
        setExportToPowerPointState(
          settingState?.exportAsPpt
            ? settingState?.exportAsPpt === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsPpt === "Yes"
            ? true
            : false
        );
        setExportToExcelState(
          settingState?.exportAsExcel
            ? settingState?.exportAsExcel === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsExcel === "Yes"
            ? true
            : false
        );
        setExportToSectionState(
          settingState?.exportASection
            ? settingState?.exportASection === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportASection === "Yes"
            ? true
            : false
        );
        setExportToChartState(
          settingState?.exportAsChart
            ? settingState?.exportAsChart === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsChart === "Yes"
            ? true
            : false
        );
        formik.setFieldValue(
          "exportAsPdf",
          settingState?.exportAsPdf
            ? settingState?.exportAsPdf === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsPdf === "Yes"
            ? true
            : false
        );
        formik.setFieldValue(
          "exportAsPpt",
          settingState?.exportAsPpt
            ? settingState?.exportAsPpt === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsPpt === "Yes"
            ? true
            : false
        );
        formik.setFieldValue(
          "exportAsExcel",
          settingState?.exportAsExcel
            ? settingState?.exportAsExcel === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsExcel === "Yes"
            ? true
            : false
        );
        formik.setFieldValue(
          "exportASection",
          settingState?.exportASection
            ? settingState?.exportASection === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportASection === "Yes"
            ? true
            : false
        );
        formik.setFieldValue(
          "exportAsChart",
          settingState?.exportAsChart
            ? settingState?.exportAsChart === "Yes"
              ? true
              : false
            : res.data.data.deliverableSetting?.exportAsChart === "Yes"
            ? true
            : false
        );
        if (
          !res.data.data.deliverableSetting &&
          !(
            settingState.pdfWatermarkSettings ||
            settingState.excelWatermarkSettings ||
            settingState.pptWatermarkSettings ||
            settingState.chartWatermarkSettings ||
            settingState.sectionWatermarkSettings
          )
        ) {
          setWatermarkDefaultData(res);
        } else {
          setWatermarkAccordianData(res);
        }
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(actionError("Something went wrong"));
        dispatch(hideLoader());
      });
  };

  const setWatermarkAccordianData = (res) => {
    // dispatch(showLoader());

    formik.setFieldValue(
      "pdfText",
      settingState?.pdfWatermarkSettings?.text
        ? settingState.pdfWatermarkSettings.text
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.text
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "pdfFontFamily",
      settingState?.pdfWatermarkSettings?.fontFamily
        ? settingState.pdfWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "pdfFontSize",
      settingState?.pdfWatermarkSettings?.fontSize
        ? settingState.pdfWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "pdfColor",
      settingState?.pdfWatermarkSettings?.color
        ? settingState.pdfWatermarkSettings.color
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.color
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "pdfOpacity",
      settingState?.pdfWatermarkSettings?.opacity
        ? settingState.pdfWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.opacity === 0
          ? 0
          : res.data.data.deliverableSetting.pdfWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "pdfTopPosition",
      settingState?.pdfWatermarkSettings?.topPosition
        ? settingState.pdfWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "pdfLeftPosition",
      settingState?.pdfWatermarkSettings?.leftPosition
        ? settingState.pdfWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "pdfRotate",
      settingState?.pdfWatermarkSettings?.rotate
        ? settingState.pdfWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.rotate
        : 0
    );

    formik.setFieldValue(
      "excelText",
      settingState?.excelWatermarkSettings?.text
        ? settingState.excelWatermarkSettings.text
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.text
        ? res.data.data.deliverableSetting.excelWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "excelFontFamily",
      settingState?.excelWatermarkSettings?.fontFamily
        ? settingState.excelWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.excelWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "excelFontSize",
      settingState?.excelWatermarkSettings?.fontSize
        ? settingState.excelWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.excelWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "excelColor",
      settingState?.excelWatermarkSettings?.color
        ? settingState.excelWatermarkSettings.color
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.color
        ? res.data.data.deliverableSetting.excelWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "excelOpacity",
      settingState?.excelWatermarkSettings?.opacity
        ? settingState.excelWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.excelWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "excelTopPosition",
      settingState?.excelWatermarkSettings?.topPosition
        ? settingState.excelWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.excelWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "excelLeftPosition",
      settingState?.excelWatermarkSettings?.leftPosition
        ? settingState.excelWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.excelWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "excelRotate",
      settingState?.excelWatermarkSettings?.rotate
        ? settingState.excelWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.excelWatermarkSettings.rotate
        : 0
    );
    formik.setFieldValue(
      "powerPointText",
      settingState?.pptWatermarkSettings?.text
        ? settingState.pptWatermarkSettings.text
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.text
        ? res.data.data.deliverableSetting.pptWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "powerPointFontFamily",
      settingState?.pptWatermarkSettings?.fontFamily
        ? settingState.pptWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.pptWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "powerPointFontSize",
      settingState?.pptWatermarkSettings?.fontSize
        ? settingState.pptWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.pptWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "powerPointColor",
      settingState?.pptWatermarkSettings?.color
        ? settingState.pptWatermarkSettings.color
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.color
        ? res.data.data.deliverableSetting.pptWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "powerPointOpacity",
      settingState?.pptWatermarkSettings?.opacity
        ? settingState.pptWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.pptWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "powerPointTopPosition",
      settingState?.pptWatermarkSettings?.topPosition
        ? settingState.pptWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.pptWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "powerPointLeftPosition",
      settingState?.pptWatermarkSettings?.leftPosition
        ? settingState.pptWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.pptWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "powerPointRotate",
      settingState?.pptWatermarkSettings?.rotate
        ? settingState.pptWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.pptWatermarkSettings.rotate
        : 0
    );
    formik.setFieldValue(
      "chartsText",
      settingState?.chartWatermarkSettings?.text
        ? settingState.chartWatermarkSettings.text
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.text
        ? res.data.data.deliverableSetting.chartWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "chartsFontFamily",
      settingState?.chartWatermarkSettings?.fontFamily
        ? settingState.chartWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.chartWatermarkSettings.fontFamily
        : ""
    );

    formik.setFieldValue(
      "chartsFontSize",
      settingState?.chartWatermarkSettings?.fontSize
        ? settingState.chartWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.chartWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "chartsColor",
      settingState?.chartWatermarkSettings?.color
        ? settingState.chartWatermarkSettings.color
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.color
        ? res.data.data.deliverableSetting.chartWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "chartsOpacity",
      settingState?.chartWatermarkSettings?.opacity
        ? settingState.chartWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.chartWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "chartsTopPosition",
      settingState?.chartWatermarkSettings?.topPosition
        ? settingState.chartWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.chartWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "chartsLeftPosition",
      settingState?.chartWatermarkSettings?.leftPosition
        ? settingState.chartWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.chartWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "chartsRotate",
      settingState?.chartWatermarkSettings?.rotate
        ? settingState.chartWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.chartWatermarkSettings.rotate
        : 0
    );

    formik.setFieldValue(
      "sectionText",
      settingState?.sectionWatermarkSettings?.text
        ? settingState.sectionWatermarkSettings.text
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.text
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "sectionFontFamily",
      settingState?.sectionWatermarkSettings?.fontFamily
        ? settingState.sectionWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "sectionFontSize",
      settingState?.sectionWatermarkSettings?.fontSize
        ? settingState.sectionWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "sectionColor",
      settingState?.sectionWatermarkSettings?.color
        ? settingState.sectionWatermarkSettings.color
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.color
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "sectionOpacity",
      settingState?.sectionWatermarkSettings?.opacity
        ? settingState.sectionWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "sectionTopPosition",
      settingState?.sectionWatermarkSettings?.topPosition
        ? settingState.sectionWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "sectionLeftPosition",
      settingState?.sectionWatermarkSettings?.leftPosition
        ? settingState.sectionWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "sectionRotate",
      settingState?.sectionWatermarkSettings?.rotate
        ? settingState.sectionWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.rotate
        : 0
    );
    dispatch(
      setDeliverableSettingDetails({
        allowChangeFont: settingState?.allowChangeFont
          ? settingState?.allowChangeFont
          : res.data.data.deliverableSetting?.allowChangeFont === "Yes"
          ? "Yes"
          : "No",
        allowChangeColor: settingState?.allowChangeColor
          ? settingState?.allowChangeColor
          : res.data.data.deliverableSetting?.allowChangeColor === "Yes"
          ? "Yes"
          : "No",
        allowChangeFormatting: settingState?.allowChangeFormatting
          ? settingState?.allowChangeFormatting
          : res.data.data.deliverableSetting?.allowChangeFormatting === "Yes"
          ? "Yes"
          : "No",
        exportAsPdf: settingState?.exportAsPdf ? settingState?.exportAsPdf : res.data.data.deliverableSetting?.exportAsPdf === "Yes" ? "Yes" : "No",
        exportAsExcel: settingState?.exportAsExcel
          ? settingState?.exportAsExcel
          : res.data.data.deliverableSetting?.exportAsExcel === "Yes"
          ? "Yes"
          : "No",
        exportASection: settingState?.exportASection
          ? settingState?.exportASection
          : res.data.data.deliverableSetting?.exportASection === "Yes"
          ? "Yes"
          : "No",
        exportAsChart: settingState?.exportAsChart
          ? settingState?.exportAsChart
          : res.data.data.deliverableSetting?.exportAsChart === "Yes"
          ? "Yes"
          : "No",
        exportAsPpt: settingState?.exportAsPpt ? settingState?.exportAsPpt : res.data.data.deliverableSetting?.exportAsPpt === "Yes" ? "Yes" : "No",
        pdfWatermarkSettings: {
          text: settingState?.pdfWatermarkSettings?.text
            ? settingState.pdfWatermarkSettings.text
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.text
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.text
            : "",
          fontFamily: settingState?.pdfWatermarkSettings?.fontFamily
            ? settingState.pdfWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.pdfWatermarkSettings?.fontSize
            ? settingState.pdfWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontSize
            : "",
          color: settingState?.pdfWatermarkSettings?.color
            ? settingState.pdfWatermarkSettings.color
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.color
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.color
            : "",
          opacity: settingState?.pdfWatermarkSettings?.opacity
            ? settingState.pdfWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.opacity === 0
              ? 0
              : res.data.data.deliverableSetting.pdfWatermarkSettings.opacity
            : "",
          topPosition: settingState?.pdfWatermarkSettings?.topPosition
            ? settingState.pdfWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.pdfWatermarkSettings?.leftPosition
            ? settingState.pdfWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.pdfWatermarkSettings?.rotate
            ? settingState.pdfWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.rotate
            : 0,
        },
        excelWatermarkSettings: {
          text: settingState?.excelWatermarkSettings?.text
            ? settingState.excelWatermarkSettings.text
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.text
            ? res.data.data.deliverableSetting.excelWatermarkSettings.text
            : "",
          fontFamily: settingState?.excelWatermarkSettings?.fontFamily
            ? settingState.excelWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.excelWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.excelWatermarkSettings?.fontSize
            ? settingState.excelWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.excelWatermarkSettings.fontSize
            : "",
          color: settingState?.excelWatermarkSettings?.color
            ? settingState.excelWatermarkSettings.color
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.color
            ? res.data.data.deliverableSetting.excelWatermarkSettings.color
            : "",
          opacity: settingState?.excelWatermarkSettings?.opacity
            ? settingState.excelWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.excelWatermarkSettings.opacity
            : "",
          topPosition: settingState?.excelWatermarkSettings?.topPosition
            ? settingState.excelWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.excelWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.excelWatermarkSettings?.leftPosition
            ? settingState.excelWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.excelWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.excelWatermarkSettings?.rotate
            ? settingState.excelWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.excelWatermarkSettings.rotate
            : 0,
        },
        pptWatermarkSettings: {
          text: settingState?.pptWatermarkSettings?.text
            ? settingState.pptWatermarkSettings.text
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.text
            ? res.data.data.deliverableSetting.pptWatermarkSettings.text
            : "",
          fontFamily: settingState?.pptWatermarkSettings?.fontFamily
            ? settingState.pptWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.pptWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.pptWatermarkSettings?.fontSize
            ? settingState.pptWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.pptWatermarkSettings.fontSize
            : "",
          color: settingState?.pptWatermarkSettings?.color
            ? settingState.pptWatermarkSettings.color
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.color
            ? res.data.data.deliverableSetting.pptWatermarkSettings.color
            : "",
          opacity: settingState?.pptWatermarkSettings?.opacity
            ? settingState.pptWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.pptWatermarkSettings.opacity
            : "",
          topPosition: settingState?.pptWatermarkSettings?.topPosition
            ? settingState.pptWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.pptWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.pptWatermarkSettings?.leftPosition
            ? settingState.pptWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.pptWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.pptWatermarkSettings?.rotate
            ? settingState.pptWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.pptWatermarkSettings.rotate
            : 0,
        },
        sectionWatermarkSettings: {
          text: settingState?.sectionWatermarkSettings?.text
            ? settingState.sectionWatermarkSettings.text
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.text
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.text
            : "",
          fontFamily: settingState?.sectionWatermarkSettings?.fontFamily
            ? settingState.sectionWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.sectionWatermarkSettings?.fontSize
            ? settingState.sectionWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontSize
            : "",
          color: settingState?.sectionWatermarkSettings?.color
            ? settingState.sectionWatermarkSettings.color
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.color
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.color
            : "",
          opacity: settingState?.sectionWatermarkSettings?.opacity
            ? settingState.sectionWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.opacity
            : "",
          topPosition: settingState?.sectionWatermarkSettings?.topPosition
            ? settingState.sectionWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.sectionWatermarkSettings?.leftPosition
            ? settingState.sectionWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.sectionWatermarkSettings?.rotate
            ? settingState.sectionWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.rotate
            : 0,
        },
        chartWatermarkSettings: {
          text: settingState?.chartWatermarkSettings?.text
            ? settingState.chartWatermarkSettings.text
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.text
            ? res.data.data.deliverableSetting.chartWatermarkSettings.text
            : "",
          fontFamily: settingState?.chartWatermarkSettings?.fontFamily
            ? settingState.chartWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.chartWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.chartWatermarkSettings?.fontSize
            ? settingState.chartWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.chartWatermarkSettings.fontSize
            : "",
          color: settingState?.chartWatermarkSettings?.color
            ? settingState.chartWatermarkSettings.color
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.color
            ? res.data.data.deliverableSetting.chartWatermarkSettings.color
            : "",
          opacity: settingState?.chartWatermarkSettings?.opacity
            ? settingState.chartWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.chartWatermarkSettings.opacity
            : "",
          topPosition: settingState?.chartWatermarkSettings?.topPosition
            ? settingState.chartWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.chartWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.chartWatermarkSettings?.leftPosition
            ? settingState.chartWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.chartWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.chartWatermarkSettings?.rotate
            ? settingState.chartWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.chartWatermarkSettings.rotate
            : 0,
        },
      })
    );
    // dispatch(hideLoader());
  };

  const setWatermarkDefaultData = () => {
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-setting"]] };
    // dispatch(showLoader());
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let getVariantSetting = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        let settings = getVariantSetting.deliverable;
        let i = 0;
        let pushData = {};
        for (let key in settings) {
          formik.setFieldValue(exportTypes[i] + "Text", settings[key].watermarkText);
          formik.setFieldValue(exportTypes[i] + "FontFamily", settings[key].watermarkFontFamily);
          formik.setFieldValue(exportTypes[i] + "FontSize", settings[key].watermarkFontSize);
          formik.setFieldValue(exportTypes[i] + "Color", settings[key].watermarkColor);
          formik.setFieldValue(exportTypes[i] + "Opacity", settings[key].watermarkOpacity);
          formik.setFieldValue(exportTypes[i] + "TopPosition", settings[key].watermarkTopPosition);
          formik.setFieldValue(exportTypes[i] + "LeftPosition", settings[key].watermarkLeftPosition);
          formik.setFieldValue(exportTypes[i] + "Rotate", settings[key].watermarkRotate);

          let defaultData = {
            text: settings[key].watermarkText,
            fontFamily: settings[key].watermarkFontFamily,
            fontSize: settings[key].watermarkFontSize,
            color: settings[key].watermarkColor,
            opacity: settings[key].watermarkOpacity,
            topPosition: settings[key].watermarkTopPosition,
            leftPosition: settings[key].watermarkLeftPosition,
            rotate: settings[key].watermarkRotate,
          };

          if (key === "pdfFormat") pushData = { ...pushData, pdfWatermarkSettings: defaultData };

          if (key === "pptFormat") pushData = { ...pushData, pptWatermarkSettings: defaultData };

          if (key === "excelFormat") pushData = { ...pushData, excelWatermarkSettings: defaultData };

          if (key === "sectionFormat") pushData = { ...pushData, sectionWatermarkSettings: defaultData };

          if (key === "chartFormat") pushData = { ...pushData, chartWatermarkSettings: defaultData };
          i = i + 1;
        }

        dispatch(
          setDeliverableSettingDetails({
            ...settingState,
            pdfWatermarkSettings: pushData.pdfWatermarkSettings,
            ...settingState,
            excelWatermarkSettings: pushData.excelWatermarkSettings,
            ...settingState,
            pptWatermarkSettings: pushData.pptWatermarkSettings,
            ...settingState,
            sectionWatermarkSettings: pushData.sectionWatermarkSettings,
            ...settingState,
            chartWatermarkSettings: pushData.chartWatermarkSettings,
            ...settingState,
            allowChangeFont: "No",
            ...settingState,
            allowChangeColor: "No",
            ...settingState,
            allowChangeFormatting: "No",
            ...settingState,
            exportAsPdf: "No",
            ...settingState,
            exportAsExcel: "No",
            ...settingState,
            exportASection: "No",
            ...settingState,
            exportAsChart: "No",
            ...settingState,
            exportAsPpt: "No",
          })
        );
        // dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        // dispatch(hideLoader());
      });
  };

  const resetSettings = (type) => {
    formik.setFieldValue(type + "Text", "");
    formik.setFieldValue(type + "FontFamily", null);
    formik.setFieldValue(type + "FontSize", null);
    formik.setFieldValue(type + "Color", null);
    formik.setFieldValue(type + "Opacity", null);
    formik.setFieldValue(type + "TopPosition", "");
    formik.setFieldValue(type + "LeftPosition", "");
    formik.setFieldValue(type + "Rotate", null);
    if (type === "pdf") dispatch(setDeliverableSettingDetails({ ...settingState, pdfWatermarkSettings: {} }));

    if (type === "excel") dispatch(setDeliverableSettingDetails({ ...settingState, excelWatermarkSettings: {} }));

    if (type === "powerPoint") dispatch(setDeliverableSettingDetails({ ...settingState, pptWatermarkSettings: {} }));

    if (type === "section") dispatch(setDeliverableSettingDetails({ ...settingState, sectionWatermarkSettings: {} }));

    if (type === "charts") dispatch(setDeliverableSettingDetails({ ...settingState, chartWatermarkSettings: {} }));
  };

  const applyWatermarkSettings = (settingType) => {
    let currentSettings;

    if (settingType === "pdf") currentSettings = settingState.pdfWatermarkSettings;

    if (settingType === "excel") currentSettings = settingState.excelWatermarkSettings;

    if (settingType === "powerPoint") currentSettings = settingState.pptWatermarkSettings;

    if (settingType === "section") currentSettings = settingState.sectionWatermarkSettings;

    if (settingType === "charts") currentSettings = settingState.chartWatermarkSettings;

    for (const type of exportTypes) {
      formik.setFieldValue(type + "Text", currentSettings.text);
      formik.setFieldValue(type + "FontFamily", currentSettings.fontFamily);
      formik.setFieldValue(type + "FontSize", currentSettings.fontSize);
      formik.setFieldValue(type + "Color", currentSettings.color);
      formik.setFieldValue(type + "Opacity", currentSettings.opacity);
      formik.setFieldValue(type + "TopPosition", currentSettings.topPosition);
      formik.setFieldValue(type + "LeftPosition", currentSettings.leftPosition);
      formik.setFieldValue(type + "Rotate", currentSettings.rotate);
    }
    if (settingType === "pdf") {
      setApplyPdfSettingState(!applyPdfSettingState);
      setApplyExcelSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyPdfSettingForAllFormat: !applyPdfSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.pdfWatermarkSettings,
          pptWatermarkSettings: settingState.pdfWatermarkSettings,
          sectionWatermarkSettings: settingState.pdfWatermarkSettings,
          chartWatermarkSettings: settingState.pdfWatermarkSettings,
        })
      );
    }
    if (settingType === "excel") {
      setApplyExcelSettingState(!applyExcelSettingState);
      setApplyPdfSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyExcelSettingForAllFormat: !applyExcelSettingState ? "Yes" : "No",
          pdfWatermarkSettings: settingState.excelWatermarkSettings,
          pptWatermarkSettings: settingState.excelWatermarkSettings,
          sectionWatermarkSettings: settingState.excelWatermarkSettings,
          chartWatermarkSettings: settingState.excelWatermarkSettings,
        })
      );
    }
    if (settingType === "powerPoint") {
      setApplyPowerPointSettingState(!applyPowerPointSettingState);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyPptSettingForAllFormat: !applyPowerPointSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.pptWatermarkSettings,
          pdfWatermarkSettings: settingState.pptWatermarkSettings,
          sectionWatermarkSettings: settingState.pptWatermarkSettings,
          chartWatermarkSettings: settingState.pptWatermarkSettings,
        })
      );
    }
    if (settingType === "section") {
      setApplySectionSettingState(!applySectionSettingState);
      setApplyPowerPointSettingState(false);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applySectionSettingForAllFormat: !applySectionSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.sectionWatermarkSettings,
          pptWatermarkSettings: settingState.sectionWatermarkSettings,
          pdfWatermarkSettings: settingState.sectionWatermarkSettings,
          chartWatermarkSettings: settingState.sectionWatermarkSettings,
        })
      );
    }
    if (settingType === "charts") {
      setApplyChartsSettingState(!applyChartsSettingState);
      setApplySectionSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyChartSettingForAllFormat: !applyChartsSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.chartWatermarkSettings,
          pptWatermarkSettings: settingState.chartWatermarkSettings,
          sectionWatermarkSettings: settingState.chartWatermarkSettings,
          pdfWatermarkSettings: settingState.chartWatermarkSettings,
        })
      );
    }
  };

  const restoreDefaultSettings = async (settingType, settingName) => {
    dispatch(showLoader());
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-setting"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let getVariantSetting = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        let settingFormat = [settingType];
        let settings = getVariantSetting.deliverable;
        for (var i = 0, n = settingFormat.length; i < n; ++i) {
          var k = settingFormat[i];
          if (k in settings) {
            settings = settings[k];
          } else {
            return;
          }
        }

        formik.setFieldValue(settingName + "Text", settings.watermarkText);
        formik.setFieldValue(settingName + "FontFamily", settings.watermarkFontFamily);
        formik.setFieldValue(settingName + "FontSize", settings.watermarkFontSize);
        formik.setFieldValue(settingName + "Color", settings.watermarkColor);
        formik.setFieldValue(settingName + "Opacity", settings.watermarkOpacity);
        formik.setFieldValue(settingName + "TopPosition", settings.watermarkTopPosition);
        formik.setFieldValue(settingName + "LeftPosition", settings.watermarkLeftPosition);
        formik.setFieldValue(settingName + "Rotate", settings.watermarkRotate);

        let defaultData = {
          text: settings.watermarkText,
          fontFamily: settings.watermarkFontFamily,
          fontSize: settings.watermarkFontSize,
          color: settings.watermarkColor,
          opacity: settings.watermarkOpacity,
          topPosition: settings.watermarkTopPosition,
          leftPosition: settings.watermarkLeftPosition,
          rotate: settings.watermarkRotate,
        };
        if (settingType === "pdfFormat") dispatch(setDeliverableSettingDetails({ ...settingState, pdfWatermarkSettings: defaultData }));

        if (settingType === "pptFormat") dispatch(setDeliverableSettingDetails({ ...settingState, pptWatermarkSettings: defaultData }));

        if (settingType === "excelFormat") dispatch(setDeliverableSettingDetails({ ...settingState, excelWatermarkSettings: defaultData }));

        if (settingType === "sectionFormat") dispatch(setDeliverableSettingDetails({ ...settingState, sectionWatermarkSettings: defaultData }));

        if (settingType === "chartFormat") dispatch(setDeliverableSettingDetails({ ...settingState, chartWatermarkSettings: defaultData }));

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };

  return (
    <>
      {props.selectedIndex === 3 && (
        <div className="gennx-content-wrapper padding-bottom-100i p-2 mt-3 mx-2 bg-white" style={{ width: "98.5%", height: "100vh" }}>
          <form>
            <div>
              <div>
                <div className="design-color">
                  <span className="header-name"> Design</span>
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: "1%",
                    paddingBottom: "3%",
                    marginLeft: "11px",
                  }}
                >
                  <div className="my-3 ">
                    Change Font
                    <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                      <label className="custom-switch">
                        <input
                          id="allowChangeFont"
                          name="allowChangeFont"
                          type="checkbox"
                          value={fontstate ? "Yes" : "No"}
                          checked={fontstate}
                          onChange={(e) => {
                            setFontState(!fontstate);
                            dispatch(
                              setDeliverableSettingDetails({
                                ...settingState,
                                allowChangeFont: !fontstate ? "Yes" : "No",
                              })
                            );
                          }}
                        />
                        <div>
                          <span className="on">Yes</span>
                          <span className="off">No</span>
                        </div>
                        <i></i>
                      </label>
                    </div>
                  </div>
                  <div className="my-3 ">
                    Change Color
                    <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                      <label className="custom-switch">
                        <input
                          id="allowChangeColor"
                          name="allowChangeColor"
                          type="checkbox"
                          value={colorState ? "Yes" : "No"}
                          checked={colorState}
                          onChange={(e) => {
                            setcolorState(!colorState);
                            dispatch(
                              setDeliverableSettingDetails({
                                ...settingState,
                                allowChangeColor: !colorState ? "Yes" : "No",
                              })
                            );
                          }}
                        />
                        <div>
                          <span className="on">Yes</span>
                          <span className="off">No</span>
                        </div>
                        <i></i>
                      </label>
                    </div>
                  </div>
                  <div className="my-3 ">
                    Change Formatting
                    <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                      <label className="custom-switch">
                        <input
                          id="allowChangeFormatting"
                          name="allowChangeFormatting"
                          type="checkbox"
                          value={formattingState ? "Yes" : "No"}
                          checked={formattingState}
                          onChange={(e) => {
                            setFormattingState(!formattingState);
                            dispatch(
                              setDeliverableSettingDetails({
                                ...settingState,
                                allowChangeFormatting: !formattingState ? "Yes" : "No",
                              })
                            );
                          }}
                        />
                        <div>
                          <span className="on">Yes</span>
                          <span className="off">No</span>
                        </div>
                        <i></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="design-color">
                  <span className="header-name">Export</span>
                </div>
                <div
                  className=""
                  style={{
                    paddingTop: "1%",
                    paddingBottom: "3%",
                  }}
                >
                  <div key={`acc-1`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                    <div className={` panel-heading ${forcedActiveState || exportToPDFState ? " active " : " "}`} role="tab">
                      <i
                        className={`fa accordion-chevron-icon ${forcedActiveState || exportToPDFState ? " fa-chevron-up " : " fa-chevron-down "}`}
                        style={{ top: "10px" }}
                      ></i>
                      <h4 className="panel-title">
                        <div
                          className={` custom-panel-title-action py-0 ${forcedActiveState || exportToPDFState ? " collapsed " : " "} ${
                            forcedActiveState ? " cursor-no-drop " : " "
                          }`}
                          style={{ display: "flex", fontWeight: "normal" }}
                        >
                          <div className="col-md-4">
                            <div className="my-3 ">
                              Export as PDF
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="exportAsPdf"
                                    name="exportAsPdf"
                                    type="checkbox"
                                    value={exportToPDFState ? "Yes" : "No"}
                                    checked={exportToPDFState}
                                    onChange={(e) => {
                                      formik.setFieldValue("exportAsPdf", !exportToPDFState);
                                      setExportToPDFState(!exportToPDFState);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          exportAsPdf: !exportToPDFState ? "Yes" : "No",
                                        })
                                      );
                                    }}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 row justify-content-end ml-4">
                            {exportToPDFState && (
                              <div className="my-2">
                                <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("pdf")}>
                                  Clear
                                </button>
                              </div>
                            )}
                            {exportToPDFState && (
                              <div className="my-2 ml-3">
                                <button
                                  type="button"
                                  className="btn pl-4 pr-4 clear-reset-btn"
                                  onClick={() => restoreDefaultSettings("pdfFormat", "pdf")}
                                >
                                  Reset to Default
                                </button>
                              </div>
                            )}
                            <div className="my-3 ml-3">
                              Apply watermark settings for all formats
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="applyPdfSetting"
                                    name="applyPdfSetting"
                                    type="checkbox"
                                    value={applyPdfSettingState ? "Yes" : "No"}
                                    checked={applyPdfSettingState}
                                    onChange={(e) => applyWatermarkSettings("pdf")}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div
                      className={` panel-collapse ${forcedActiveState || exportToPDFState ? " collapsed " : " collapse "}`}
                      style={{ border: "1px solid #DBDBDD" }}
                      role="tabpanel"
                    >
                      <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                        <div>
                          <span className="mt-3 ml-2">
                            <strong>Watermark Settings</strong>
                          </span>
                          <div
                            className="row"
                            style={{
                              paddingTop: "1%",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mb-2">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  multiline={true}
                                  numberCount={true}
                                  maxLength={100}
                                  rows={5}
                                  name="pdfText"
                                  // value={}
                                  className="form-control"
                                  id="pdfText"
                                  placeholder="Text *"
                                  // {...props}
                                  characterLimitText={true}
                                  onChange={(e) => {
                                    formik.setFieldValue("pdfText", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          text: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                                
                              </div>
                              <div className="col-md-4">
                                {fontFamilyList && fontFamilyList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="pdfFontFamily"
                                    placeholder="Font Family *"
                                    name="pdfFontFamily"
                                    fields={{ text: "name", value: "value" }}
                                    dataSource={fontFamilyList}
                                    change={(e) => {
                                      formik.setFieldValue("pdfFontFamily", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            fontFamily: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <NumericTextField
                                  formik={formik}
                                  min={10}
                                  max={32}
                                  format="##.# px"
                                  id="pdfFontSize"
                                  placeholder="Font Size *"
                                  name="pdfFontSize"
                                  onChange={(e) => {
                                    formik.setFieldValue("pdfFontSize", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          fontSize: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                {colorList && colorList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="pdfColor"
                                    placeholder="Color *"
                                    name="pdfColor"
                                    fields={{ text: "name", value: "rgbValue" }}
                                    dataSource={colorList}
                                    change={(e) => {
                                      formik.setFieldValue("pdfColor", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            color: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  min={0.1}
                                  max={1}
                                  step={0.1}
                                  format="#.#"
                                  id="pdfOpacity"
                                  placeholder="Opacity *"
                                  name="pdfOpacity"
                                  onChange={(e) => {
                                    formik.setFieldValue("pdfOpacity", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          opacity: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="pdfTopPosition"
                                  placeholder="Top Position *"
                                  name="pdfTopPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("pdfTopPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          topPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="pdfLeftPosition"
                                  placeholder="Left Position *"
                                  name="pdfLeftPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("pdfLeftPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          leftPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <Dropdown
                                  formik={formik}
                                  id="pdfRotate"
                                  placeholder="Rotation/Angle *"
                                  name="pdfRotate"
                                  fields={{ text: "label", value: "value" }}
                                  dataSource={watermarkRotate}
                                  change={(e) => {
                                    formik.setFieldValue("pdfRotate", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pdfWatermarkSettings: {
                                          ...settingState.pdfWatermarkSettings,
                                          rotate: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key={`acc-2`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                    <div className={` panel-heading ${forcedActiveState || exportToExcelState ? " active " : " "}`} role="tab">
                      <i
                        className={`fa accordion-chevron-icon ${forcedActiveState || exportToExcelState ? " fa-chevron-up " : " fa-chevron-down "}`}
                        style={{ top: "10px" }}
                      ></i>
                      <h4 className="panel-title">
                        <div
                          className={` custom-panel-title-action py-0 ${forcedActiveState || exportToExcelState ? " collapsed " : " "} ${
                            forcedActiveState ? " cursor-no-drop " : " "
                          }`}
                          style={{ display: "flex", fontWeight: "normal" }}
                        >
                          <div className="col-md-4">
                            <div className="my-3 ">
                              Export as Excel
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="exportAsExcel"
                                    name="exportAsExcel"
                                    type="checkbox"
                                    value={exportToExcelState ? "Yes" : "No"}
                                    checked={exportToExcelState}
                                    onChange={(e) => {
                                      formik.setFieldValue("exportAsExcel", !exportToExcelState);
                                      setExportToExcelState(!exportToExcelState);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          exportAsExcel: !exportToExcelState ? "Yes" : "No",
                                        })
                                      );
                                    }}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 row justify-content-end ml-4">
                            {exportToExcelState && (
                            <div className="my-2">
                              <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("excel")}>
                                Clear
                              </button>
                            </div>)}
                            {exportToExcelState && (
                            <div className="my-2 ml-3">
                              <button
                                type="button"
                                className="btn pl-4 pr-4 clear-reset-btn"
                                onClick={() => restoreDefaultSettings("excelFormat", "excel")}
                              >
                                Reset to Default
                              </button>
                            </div>)}
                            <div className="my-3 ml-3">
                              Apply watermark settings for all formats
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="applyExcelSetting"
                                    name="applyExcelSetting"
                                    type="checkbox"
                                    value={applyExcelSettingState ? "Yes" : "No"}
                                    checked={applyExcelSettingState}
                                    onChange={(e) => applyWatermarkSettings("excel")}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div
                      className={` panel-collapse ${forcedActiveState || exportToExcelState ? " collapsed " : " collapse "}`}
                      style={{ border: "1px solid #DBDBDD" }}
                      role="tabpanel"
                    >
                      <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                        <div>
                          <span className="mt-3 ml-2">
                            <strong>Watermark Settings</strong>
                          </span>
                          <div
                            className="row"
                            style={{
                              paddingTop: "1%",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mb-2">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  multiline={true}
                                  numberCount={true}
                                  maxLength={100}
                                  rows={5}
                                  name="excelText"
                                  className="form-control"
                                  id="excelText"
                                  placeholder="Text *"
                                  // {...props}
                                  characterLimitText={true}
                                  onChange={(e) => {
                                    formik.setFieldValue("excelText", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          text: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                                
                              </div>
                              <div className="col-md-4">
                                {fontFamilyList && fontFamilyList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="excelFontFamily"
                                    placeholder="Font Family *"
                                    name="excelFontFamily"
                                    fields={{ text: "name", value: "value" }}
                                    dataSource={fontFamilyList}
                                    change={(e) => {
                                      formik.setFieldValue("excelFontFamily", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            fontFamily: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <NumericTextField
                                  formik={formik}
                                  min={10}
                                  max={32}
                                  format="##.# px"
                                  id="excelFontSize"
                                  placeholder="Font Size *"
                                  name="excelFontSize"
                                  onChange={(e) => {
                                    formik.setFieldValue("excelFontSize", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          fontSize: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                {colorList && colorList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="excelColor"
                                    placeholder="Color *"
                                    name="excelColor"
                                    fields={{ text: "name", value: "rgbValue" }}
                                    dataSource={colorList}
                                    change={(e) => {
                                      formik.setFieldValue("excelColor", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            color: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  min={0.1}
                                  max={1}
                                  step={0.1}
                                  format="#.#"
                                  id="excelOpacity"
                                  placeholder="Opacity *"
                                  name="excelOpacity"
                                  onChange={(e) => {
                                    formik.setFieldValue("excelOpacity", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          opacity: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="excelTopPosition"
                                  placeholder="Top Position *"
                                  name="excelTopPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("excelTopPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          topPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="excelLeftPosition"
                                  placeholder="Left Position *"
                                  name="excelLeftPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("excelLeftPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          leftPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <Dropdown
                                  formik={formik}
                                  id="excelRotate"
                                  placeholder="Rotation/Angle *"
                                  name="excelRotate"
                                  fields={{ text: "label", value: "value" }}
                                  dataSource={watermarkRotate}
                                  change={(e) => {
                                    formik.setFieldValue("excelRotate", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        excelWatermarkSettings: {
                                          ...settingState.excelWatermarkSettings,
                                          rotate: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key={`acc-3`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                    <div className={` panel-heading ${forcedActiveState || exportToPowerPointState ? " active " : " "}`} role="tab">
                      <i
                        className={`fa accordion-chevron-icon ${forcedActiveState || exportToPowerPointState ? " fa-chevron-up " : " fa-chevron-down "}`}
                        style={{ top: "10px" }}
                      ></i>
                      <h4 className="panel-title">
                        <div
                          className={` custom-panel-title-action py-0 ${forcedActiveState || exportToPowerPointState ? " collapsed " : " "} ${
                            forcedActiveState ? " cursor-no-drop " : " "
                          }`}
                          style={{ display: "flex", fontWeight: "normal" }}
                        >
                          <div className="col-md-4">
                            <div className="my-3 ">
                              Export as PowerPoint
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="exportAsPpt"
                                    name="exportAsPpt"
                                    type="checkbox"
                                    value={exportToPowerPointState ? "Yes" : "No"}
                                    checked={exportToPowerPointState}
                                    onChange={(e) => {
                                      formik.setFieldValue("exportAsPpt", !exportToPowerPointState);
                                      setExportToPowerPointState(!exportToPowerPointState);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          exportAsPpt: !exportToPowerPointState ? "Yes" : "No",
                                        })
                                      );
                                    }}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 row justify-content-end ml-4">
                            {exportToPowerPointState && (
                              <div className="my-2">
                              <button
                                type="button"
                                className="btn pl-4 pr-4 clear-reset-btn"
                                onClick={() => resetSettings("powerPoint")}
                              >
                                Clear
                              </button>
                            </div>)}
                            {exportToPowerPointState && (<div className="my-2 ml-3">
                              <button
                                type="button"
                                className="btn pl-4 pr-4 clear-reset-btn"
                                onClick={() => restoreDefaultSettings("pptFormat", "powerPoint")}
                              >
                                Reset to Default
                              </button>
                            </div>)}
                            <div className="my-3 ml-3">
                              Apply watermark settings for all formats
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="applyPowerPointSetting"
                                    name="applyPowerPointSetting"
                                    type="checkbox"
                                    value={applyPowerPointSettingState ? "Yes" : "No"}
                                    checked={applyPowerPointSettingState}
                                    onChange={(e) => applyWatermarkSettings("powerPoint")}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div
                      className={` panel-collapse ${forcedActiveState || exportToPowerPointState ? " collapsed " : " collapse "}`}
                      style={{ border: "1px solid #DBDBDD" }}
                      role="tabpanel"
                    >
                      <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                        <div>
                          <span className="mt-3 ml-2">
                            <strong>Watermark Settings</strong>
                          </span>
                          <div
                            className="row"
                            style={{
                              paddingTop: "1%",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mb-2">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  multiline={true}
                                  numberCount={true}
                                  maxLength={100}
                                  rows={5}
                                  name="powerPointText"
                                  className="form-control"
                                  id="powerPointText"
                                  placeholder="Text *"
                                  // {...props}
                                  characterLimitText={true}
                                  onChange={(e) => {
                                    formik.setFieldValue("powerPointText", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          text: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                                
                              </div>
                              <div className="col-md-4">
                                {fontFamilyList && fontFamilyList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="powerPointFontFamily"
                                    placeholder="Font Family *"
                                    name="powerPointFontFamily"
                                    fields={{ text: "name", value: "value" }}
                                    dataSource={fontFamilyList}
                                    change={(e) => {
                                      formik.setFieldValue("powerPointFontFamily", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            fontFamily: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <NumericTextField
                                  formik={formik}
                                  min={10}
                                  max={32}
                                  format="##.# px"
                                  id="powerPointFontSize"
                                  placeholder="Font Size *"
                                  name="powerPointFontSize"
                                  onChange={(e) => {
                                    formik.setFieldValue("powerPointFontSize", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          fontSize: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                {colorList && colorList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="powerPointColor"
                                    placeholder="Color *"
                                    name="powerPointColor"
                                    fields={{ text: "name", value: "rgbValue" }}
                                    dataSource={colorList}
                                    change={(e) => {
                                      formik.setFieldValue("powerPointColor", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            color: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  min={0.1}
                                  max={1}
                                  step={0.1}
                                  format="#.#"
                                  id="powerPointOpacity"
                                  placeholder="Opacity *"
                                  name="powerPointOpacity"
                                  onChange={(e) => {
                                    formik.setFieldValue("powerPointOpacity", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          opacity: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="powerPointTopPosition"
                                  placeholder="Top Position *"
                                  name="powerPointTopPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("powerPointTopPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          topPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="powerPointLeftPosition"
                                  placeholder="Left Position *"
                                  name="powerPointLeftPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("powerPointLeftPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          leftPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <Dropdown
                                  formik={formik}
                                  id="powerPointRotate"
                                  placeholder="Rotation/Angle *"
                                  name="powerPointRotate"
                                  fields={{ text: "label", value: "value" }}
                                  dataSource={watermarkRotate}
                                  change={(e) => {
                                    formik.setFieldValue("powerPointRotate", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        pptWatermarkSettings: {
                                          ...settingState.pptWatermarkSettings,
                                          rotate: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key={`acc-4`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                    <div className={` panel-heading ${forcedActiveState || exportToSectionState ? " active " : " "}`} role="tab">
                      <i
                        className={`fa accordion-chevron-icon ${forcedActiveState || exportToSectionState ? " fa-chevron-up " : " fa-chevron-down "}`}
                        style={{ top: "10px" }}
                      ></i>
                      <h4 className="panel-title">
                        <div
                          className={` custom-panel-title-action py-0 ${forcedActiveState || exportToSectionState ? " collapsed " : " "} ${
                            forcedActiveState ? " cursor-no-drop " : " "
                          }`}
                          style={{ display: "flex", fontWeight: "normal" }}
                        >
                          <div className="col-md-4">
                            <div className="my-3 ">
                              Export a Section
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="exportASection"
                                    name="exportASection"
                                    type="checkbox"
                                    value={exportToSectionState ? "Yes" : "No"}
                                    checked={exportToSectionState}
                                    onChange={(e) => {
                                      formik.setFieldValue("exportASection", !exportToSectionState);
                                      setExportToSectionState(!exportToSectionState);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          exportASection: !exportToSectionState ? "Yes" : "No",
                                        })
                                      );
                                    }}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 row justify-content-end ml-4">
                            {exportToSectionState && (<div className="my-2">
                              <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("section")}>
                                Clear
                              </button>
                            </div>)}
                            {exportToSectionState && (<div className="my-2 ml-3">
                              <button
                                type="button"
                                className="btn pl-4 pr-4 clear-reset-btn"
                                onClick={() => restoreDefaultSettings("sectionFormat", "section")}
                              >
                                Reset to Default
                              </button>
                            </div>)}
                            <div className="my-3 ml-3">
                              Apply watermark settings for all formats
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="applySectionSetting"
                                    name="applySectionSetting"
                                    type="checkbox"
                                    value={applySectionSettingState ? "Yes" : "No"}
                                    checked={applySectionSettingState}
                                    onChange={(e) => applyWatermarkSettings("section")}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div
                      className={` panel-collapse ${forcedActiveState || exportToSectionState ? " collapsed " : " collapse "}`}
                      style={{ border: "1px solid #DBDBDD" }}
                      role="tabpanel"
                    >
                      <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                        <div>
                          <span className="mt-3 ml-2">
                            <strong>Watermark Settings</strong>
                          </span>
                          <div
                            className="row"
                            style={{
                              paddingTop: "1%",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mb-2">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  multiline={true}
                                  numberCount={true}
                                  maxLength={100}
                                  rows={5}
                                  name="sectionText"
                                  className="form-control"
                                  id="sectionText"
                                  placeholder="Text *"
                                  // {...props}
                                  characterLimitText={true}
                                  onChange={(e) => {
                                    formik.setFieldValue("sectionText", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          text: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                                
                              </div>
                              <div className="col-md-4">
                                {fontFamilyList && fontFamilyList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="sectionFontFamily"
                                    placeholder="Font Family *"
                                    name="sectionFontFamily"
                                    fields={{ text: "name", value: "value" }}
                                    dataSource={fontFamilyList}
                                    change={(e) => {
                                      formik.setFieldValue("sectionFontFamily", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            fontFamily: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <NumericTextField
                                  formik={formik}
                                  min={10}
                                  max={32}
                                  format="##.# px"
                                  id="sectionFontSize"
                                  placeholder="Font Size *"
                                  name="sectionFontSize"
                                  onChange={(e) => {
                                    formik.setFieldValue("sectionFontSize", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          fontSize: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                {colorList && colorList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="sectionColor"
                                    placeholder="Color *"
                                    name="sectionColor"
                                    fields={{ text: "name", value: "rgbValue" }}
                                    dataSource={colorList}
                                    change={(e) => {
                                      formik.setFieldValue("sectionColor", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            color: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  min={0.1}
                                  max={1}
                                  step={0.1}
                                  format="#.#"
                                  id="sectionOpacity"
                                  placeholder="Opacity *"
                                  name="sectionOpacity"
                                  onChange={(e) => {
                                    formik.setFieldValue("sectionOpacity", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          opacity: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="sectionTopPosition"
                                  placeholder="Top Position *"
                                  name="sectionTopPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("sectionTopPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          topPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="sectionLeftPosition"
                                  placeholder="Left Position *"
                                  name="sectionLeftPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("sectionLeftPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          leftPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <Dropdown
                                  formik={formik}
                                  id="sectionRotate"
                                  placeholder="Rotation/Angle *"
                                  name="sectionRotate"
                                  fields={{ text: "label", value: "value" }}
                                  dataSource={watermarkRotate}
                                  change={(e) => {
                                    formik.setFieldValue("sectionRotate", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        sectionWatermarkSettings: {
                                          ...settingState.sectionWatermarkSettings,
                                          rotate: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key={`acc-5`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                    <div className={` panel-heading ${forcedActiveState || exportToChartState ? " active " : " "}`} role="tab">
                      <i
                        className={`fa accordion-chevron-icon ${forcedActiveState || exportToChartState ? " fa-chevron-up " : " fa-chevron-down "}`}
                        style={{ top: "10px" }}
                      ></i>
                      <h4 className="panel-title">
                        <div
                          className={` custom-panel-title-action py-0 ${forcedActiveState || exportToChartState ? " collapsed " : " "} ${
                            forcedActiveState ? " cursor-no-drop " : " "
                          }`}
                          style={{ display: "flex", fontWeight: "normal" }}
                        >
                          <div className="col-md-4">
                            <div className="my-3 ">
                              Export Charts
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="exportAsChart"
                                    name="exportAsChart"
                                    type="checkbox"
                                    value={exportToChartState ? "Yes" : "No"}
                                    checked={exportToChartState}
                                    onChange={(e) => {
                                      formik.setFieldValue("exportAsChart", !exportToChartState);
                                      setExportToChartState(!exportToChartState);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          exportAsChart: !exportToChartState ? "Yes" : "No",
                                        })
                                      );
                                    }}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 row justify-content-end ml-4">
                            {exportToChartState && (<div className="my-2">
                              <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("charts")}>
                                Clear
                              </button>
                            </div>)}
                            {exportToChartState && (<div className="my-2 ml-3">
                              <button
                                type="button"
                                className="btn pl-4 pr-4 clear-reset-btn"
                                onClick={() => restoreDefaultSettings("chartFormat", "charts")}
                              >
                                Reset to Default
                              </button>
                            </div>)}
                            <div className="my-3 ml-3">
                              Apply watermark settings for all formats
                              <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                <label className="custom-switch">
                                  <input
                                    id="applyChartsSetting"
                                    name="applyChartsSetting"
                                    type="checkbox"
                                    value={applyChartsSettingState ? "Yes" : "No"}
                                    checked={applyChartsSettingState}
                                    onChange={(e) => applyWatermarkSettings("charts")}
                                  />
                                  <div>
                                    <span className="on">Yes</span>
                                    <span className="off">No</span>
                                  </div>
                                  <i></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div
                      className={` panel-collapse ${forcedActiveState || exportToChartState ? " collapsed " : " collapse "}`}
                      style={{ border: "1px solid #DBDBDD" }}
                      role="tabpanel"
                    >
                      <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                        <div>
                          <span className="mt-3 ml-2">
                            <strong>Watermark Settings</strong>
                          </span>
                          <div
                            className="row"
                            style={{
                              paddingTop: "1%",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mb-2">
                                <TextField
                                  formik={formik}
                                  type="text"
                                  multiline={true}
                                  numberCount={true}
                                  maxLength={100}
                                  rows={5}
                                  name="chartsText"
                                  className="form-control"
                                  id="chartsText"
                                  placeholder="Text *"
                                  // {...props}
                                  characterLimitText={true}
                                  onChange={(e) => {
                                    formik.setFieldValue("chartsText", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          text: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                                
                              </div>
                              <div className="col-md-4">
                                {fontFamilyList && fontFamilyList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="chartsFontFamily"
                                    placeholder="Font Family *"
                                    name="chartsFontFamily"
                                    fields={{ text: "name", value: "value" }}
                                    dataSource={fontFamilyList}
                                    change={(e) => {
                                      formik.setFieldValue("chartsFontFamily", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            fontFamily: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <NumericTextField
                                  formik={formik}
                                  min={10}
                                  max={32}
                                  format="##.# px"
                                  id="chartsFontSize"
                                  placeholder="Font Size *"
                                  name="chartsFontSize"
                                  onChange={(e) => {
                                    formik.setFieldValue("chartsFontSize", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          fontSize: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                {colorList && colorList.length > 0 && (
                                  <Dropdown
                                    formik={formik}
                                    id="chartsColor"
                                    placeholder="Color *"
                                    name="chartsColor"
                                    fields={{ text: "name", value: "rgbValue" }}
                                    dataSource={colorList}
                                    change={(e) => {
                                      formik.setFieldValue("chartsColor", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            color: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  min={0.1}
                                  max={1}
                                  step={0.1}
                                  format="#.#"
                                  id="chartsOpacity"
                                  placeholder="Opacity *"
                                  name="chartsOpacity"
                                  onChange={(e) => {
                                    formik.setFieldValue("chartsOpacity", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          opacity: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="chartsTopPosition"
                                  placeholder="Top Position *"
                                  name="chartsTopPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("chartsTopPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          topPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <NumericTextField
                                  formik={formik}
                                  format="#.# px"
                                  id="chartsLeftPosition"
                                  placeholder="Left Position *"
                                  name="chartsLeftPosition"
                                  onChange={(e) => {
                                    formik.setFieldValue("chartsLeftPosition", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          leftPosition: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <Dropdown
                                  formik={formik}
                                  id="chartsRotate"
                                  placeholder="Rotation/Angle *"
                                  name="chartsRotate"
                                  fields={{ text: "label", value: "value" }}
                                  dataSource={watermarkRotate}
                                  change={(e) => {
                                    formik.setFieldValue("chartsRotate", e.value);
                                    dispatch(
                                      setDeliverableSettingDetails({
                                        ...settingState,
                                        chartWatermarkSettings: {
                                          ...settingState.chartWatermarkSettings,
                                          rotate: e.value,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Settings;
