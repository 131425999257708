import { hideSpinner } from "@syncfusion/ej2-popups";

export const ckEditorConfig = {
  // startupFocus : true,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "links", groups: ["links"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    { name: "tools", groups: ["tools"] },
    { name: "paragraph", groups: ["list", "indent", "align", "bidi", "paragraph"] },
    { name: "insert", groups: ["insert"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
  extraPlugins: "editorplaceholder",
  removeButtons: "PasteFromWord,Subscript,Superscript,About",
  format_tags: "p;h1;h2;h3;pre",
  disableNativeSpellChecker: false,
  scayt_autoStartup: true,
  grayt_autoStartup: true,
  scayt_sLang: "en_US",
  scayt_inlineModeImmediateMarkup: true,
  scayt_ignoreDomainNames: true,
  scayt_maxSuggestions: 4,
  autoParagraph: false,
  enterMode: 2,
  shiftEnterMode: 2,
  removeDialogTabs: "",
  entities: false,
  forcePasteAsPlainText: true,
  pasteFilter: "plain-text",
  height: 400,
};

export const ckEditorMinimalConfig = {
  // startupFocus : true,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    // { name: 'forms', groups: ['forms'] },
    // { name: 'links', groups: ['links'] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    // // { name: 'tools', groups: ['tools'] },
    { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
    // // { name: 'insert', groups: ['insert'] },
    { name: "styles", groups: ["styles"] },
    // { name: 'colors', groups: ['colors'] },
    { name: "others", groups: ["others"] },
    // { name: 'about', groups: ['about'] }
  ],
  extraPlugins: "editorplaceholder",
  removeButtons: "PasteFromWord,Subscript,Superscript,About",
  format_tags: "p;h1;h2;h3;pre",
  disableNativeSpellChecker: false,
  scayt_autoStartup: true,
  grayt_autoStartup: true,
  scayt_sLang: "en_US",
  scayt_inlineModeImmediateMarkup: true,
  scayt_ignoreDomainNames: true,
  scayt_maxSuggestions: 4,
  autoParagraph: false,
  enterMode: 2,
  shiftEnterMode: 2,
  removeDialogTabs: "",
  entities: false,
  forcePasteAsPlainText: true,
  pasteFilter: "plain-text",
  height: 400,
};

export const ckEditorConfigBasic = {
  // startupFocus : true,
  toolbarGroups: [
    { name: "editing", groups: ["spellchecker"] },
    { name: "basicstyles", groups: ["basicstyles"] },
    { name: "tools", groups: ["tools"] },
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "links", groups: ["links"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    { name: "tools", groups: ["tools"] },
    { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
  removeButtons: "PasteFromWord,Subscript,Superscript,About",
  format_tags: "p;h1;h2;h3;pre",
  disableNativeSpellChecker: false,
  scayt_autoStartup: true,
  grayt_autoStartup: true,
  scayt_sLang: "en_US",
  scayt_inlineModeImmediateMarkup: true,
  scayt_ignoreDomainNames: true,
  scayt_maxSuggestions: 4,
  autoParagraph: false,
  enterMode: 2,
  shiftEnterMode: 2,
  removeDialogTabs: "",
  entities: false,
  forcePasteAsPlainText: true,
  pasteFilter: "plain-text",
};

export const ckEditorSmsConfig = {
  // startupFocus : true,
  toolbarGroups: [
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
  ],
  extraPlugins: "editorplaceholder",
  removeButtons: "PasteFromWord,Subscript,Superscript,About",
  format_tags: "p;h1;h2;h3;pre",
  disableNativeSpellChecker: false,
  scayt_autoStartup: true,
  grayt_autoStartup: true,
  scayt_sLang: "en_US",
  scayt_inlineModeImmediateMarkup: true,
  scayt_ignoreDomainNames: true,
  scayt_maxSuggestions: 4,
  autoParagraph: false,
  enterMode: 2,
  shiftEnterMode: 2,
  removeDialogTabs: "",
  entities: false,
  forcePasteAsPlainText: true,
  pasteFilter: "plain-text",
  height: 400,
};
