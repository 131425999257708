/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Expert Community Registration
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 08/Mar/2021
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { TextField, Dropdown, MultiSelect, InputGroupText } from "./formField"
import { CountrySelect } from "../../components/CustomFormElements/syncFusionFormFields"
import { expertUserValidationSchema } from "../../helper/validationSchema/userValidationSchema";
import {
	fetchTaxonomyTechnologyForIntern, fetchIndustryExpert,
	generateOtpForOutSourcing, validateOtpForOutSourcing, addOutSourcingUser, regenerateOtpForOutSourcing,
	fetchWhatNextLogo, fetchInsiderLogo, fetchCountryForOutSourcing
} from "../../../middleware/services/cmsApi";
import { showPopup } from "../../../middleware/actions/popupAction";
import { termsAndConditions } from "./termsAndConditionsData";
import { leftPaneHtml } from "./expertLeftPaneHtml";
import { HIGHEST_EDUCATION } from "../../../constants";
import "./outsourcingTnc.scss";

const OutsourcingRegistration = (props) => {
	// specify breadcrumb here
	const breadCrumbLinksAddUser = [
		{ linkUrl: "/signup-expert", linkName: "Expert Community Rregistration", linkActive: true },
	];

	const dispatch = useDispatch();
	const [alertType, setAlertType] = useState("");
	const [alertMsg, setAlertMsg] = useState("");
	const [technologies, setTechnologies] = useState([]);
	const [industry, setIndustry] = useState([]);
	const [industryMaster, setIndustryMaster] = useState([]);
	const [showField, setShowField] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [disabled, setDisabled] = useState("");
	const [showMobileOtp, setShowMobileOtp] = useState(false);
	const [noData, setNoData] = useState("Type atleast 3 characters");
	const [countries, setCountries] = useState([]);
	const [isd, setIsd] = useState("");
	const [countryId, setCountryID] = useState(null);

	const [hideDiv, setHideDiv] = useState(false);
	// const variant = queryString.parse(props.location.search).variant;
	const variant = props.location.pathname === "/signup-expert" ? 1 : 0;
	const initialState = {
		showOTP: false,
		userFname: "",
		userLname: "",
		userEmail: "",
		userLinkedinUrl: "",
		userMobile: "",
		userHighestEducation: null,
		institutionName: "",
		educationYear: "",
		address1: "",
		address2: "",
		cityName: "",
		country: "",
		postCode: "",
		technologyInterest: null,
		industryInterest: null,
		userTncAccepected: "",
		userEmailOTP: "",
		userMobileOTP: "",
		showMobileOtp: false
	};

	const formik = useFormik({
		initialValues: { ...initialState },
		validationSchema: Yup.object().shape({
			...expertUserValidationSchema
		})
	});

	/**
	 * @description fetch technologies to tag with user
	 */
	const fetchTechnologies = () => {
		fetchTaxonomyTechnologyForIntern({ filters: [["dtParentId", "eq", 0]], fields: ["id", "dtName"], sort: "dtName:asc", limit: 2000 }).then(res => {
			const { data = [] } = res.data;
			setTechnologies(data.map(d => ({ value: d.id, label: d.dtName })));
		}).catch(err => {
			let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Technology Failed";
			setAlertType("danger");
			setAlertMsg(errMsg);
			// dispatch(actionError(errMsg));
		});
	};

	/**
	 * @description fetch WhatNext Industry to tag with user
	 */
	const fetchIndustries = () => {
		fetchIndustryExpert({ fields: ["industryName"], sort: "industryName:asc", limit: 50 }).then(res => {
			const { data = [] } = res.data;
			setIndustryMaster(data);
			const setTrendsTags = JSON.parse(JSON.stringify(data));
			let industryDropdownValues = {};
			setTrendsTags.forEach(sTT => {
				industryDropdownValues[sTT.id] = { value: sTT.id, label: sTT.industryName };
			});
			setIndustry([...Object.values(industryDropdownValues)]);
		}).catch(err => {
			let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
			// dispatch(actionError(errMsg));
			setAlertMsg(errMsg);
			setAlertType("danger");
		});
	};

	useEffect(() => {
		const breadCrumbLinks = [...breadCrumbLinksAddUser];
		// dispatch(setBreadCrumb(breadCrumbLinks));		
		fetchTechnologies();
		fetchIndustries();
		return () => {
		};
	}, [dispatch]);

	useEffect(() => {
		countries.forEach(element => {
			if (element.label === formik.values.country) {
				setIsd(element.countryCode)
				setCountryID(element.value)
			}
		});
	}, [formik.values.country]);

	useEffect(() => {
		formik.values.userTncAccepected && setDisabledBtn(false);
	}, [formik.values.userTncAccepected])

	// generate user OTP 
	const generateOtp = async () => {
		const { userFname, userLname, userEmail, userLinkedinUrl, userMobile,
			userHighestEducation, industryInterest, technologyInterest,
			address1, address2, cityName, postCode,
			userTncAccepected, institutionName, educationYear
		} = formik.values;
		let country = { value: countryId, label: formik.values.country, countryCode: isd }
		let payload = {
			userFname, userLname,
			userEmail, userLinkedinUrl, userMobile, userHighestEducation,
			productVariant: variant == 1 || variant === undefined ? [{
				product_name: "WhatNext"
			}] : [{
				product_name: "Insider"
			}],
			userExperties: {
				industry: industryInterest,
				technology: variant == 1 || variant === undefined ? technologyInterest : []
			},
			userAddress: {
				address1, address2, cityName,
				country, postCode
			},
			userQualification: {
				institutionName,
				educationYear
			},
			userTncAccepected: userTncAccepected ? "yes" : "no",
			userRole: "expert",
			userIsRegister: "YES"
		}
		const response = await generateOtpForOutSourcing(payload).then(res => {

			let message = res.data?.data?.mobile ?
				"OTP has been sent to your Email & Mobile Number, OTP is valid for 30 minutes." :
				"OTP has been sent to your Email, OTP is valid for 30 minutes.";

			setAlertMsg(message);
			setAlertType("success");

			formik.setFieldValue("showOTP", true);
			setShowField(true)
			if (res.data?.data?.mobile) {
				setShowMobileOtp(true)
				formik.setFieldValue("showMobileOtp", true);
			}


		}).catch(err => {
			setAlertMsg(err?.data?.message ? (err?.data?.message === 'payload: "userEmail" must be a valid email' ? "Please enter a valid email" : err?.data?.message) : "Something went wrong");
			setAlertType("danger");
			setDisabledBtn(false)
		})
	}

	const regenerateOtp = async () => {
		setDisabled("disabled")
		let payload = {
			userEmail: formik.values["userEmail"]
		}
		await regenerateOtpForOutSourcing(payload).then(res => {
			let message = res.data?.data?.mobile ?
				"New OTP has been sent to your Email & Mobile Number." :
				"New OTP has been sent to your Email.";

			res.data ? setAlertMsg(message) : setAlertMsg("Something went wrong");
			res.data ? setAlertType("success") : setAlertType("danger");
			setDisabled("")
		}).catch(err => {
			setDisabled("")
			setAlertMsg(err?.data?.message || "Something went wrong");
			setAlertType("danger");
		})
	}

	// validate user form 
	const validateUserRegistrationForm = async () => {
		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				// console.log("error response", res)
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
			} else {
				setDisabledBtn(true)
				generateOtp()
			}
		})
	}

	// validate OTP entered by user
	const validateOTP = async () => {

		formik.validateForm().then((res) => {
			if (Object.keys(res).length) {
				console.log("error response", res)
				const touched = {};
				Object.keys(res).map((field) => {
					touched[field] = true;
					return touched
				});
				formik.setFormikState({ ...formik, touched: touched, errors: res });
			} else {
				const { userEmailOTP, userMobileOTP, userEmail } = formik.values;
				const payload = {
					userEmail,
					userOtp: {
						email: userEmailOTP,
						mobile: userMobileOTP
					}
				}
				register(payload);

			}
		})
	}
	const register = async (payload) => {
		const resp = await validateOtpForOutSourcing(payload).then(res => {

			// Your registration attempt was unsuccessful. Please contact <a href=maitto:support@whatnextglobal.com''>support@whatnextglobal.com</a> for further assistance.
			if (res?.data?.data) {
				if (!(res.data.data?.otpValidationStatus?.email)) {
					formik.setFieldError("userEmailOTP", "Please enter valid OTP");
				}
				if (!(res.data.data?.otpValidationStatus?.mobile)) {
					formik.setFieldError("userMobileOTP", "Please enter valid OTP");
				}
				if (res.data.data?.otpValidationStatus?.mobile && res.data.data?.otpValidationStatus?.email) {
					addUser();
				}
			}
		}).catch(err => {
			setAlertType("danger");
			if (err?.data?.message === "user blocked") {
				setAlertMsg("Your registration attempt was unsuccessful. <br/>Please contact <a class='btn-link text-danger font-weight-bold' href='mailto:support@whatnextglobal.com'>support@whatnextglobal.com</a> for further assistance.");
			} else {
				setAlertMsg(err?.data?.message || "Something went wrong");
			}
		})
	}
	// First validate the user and than create user in genx
	const addUser = async () => {
		const { userEmail } = formik.values;
		await addOutSourcingUser({ userEmail: userEmail }).then(res => {
			if (res?.data) {
				// dispatch(actionSuccess("Your are successfully registered. Please check your email for more details.", 8000, "Center", "Bottom"));
				setHideDiv(true);
				setAlertMsg("Thank you for registering. You will receive an email with the instructions to continue.");
				// 
				setAlertType("success");
				setShowField(!showField);
				// educationYearRef.current.value=null
				formik.resetForm();
				formik.setValues(initialState);
				// setTimeout(() => {
				// 	window.location.reload();
				// }, 5000);
				// formik.handleReset(initialState);

			} else {
				// actionError("Registration is failed. Please contact to admin.", 8000, "Center", "Bottom");
				setAlertMsg("Registration is failed. Please contact to admin.");
				setAlertType("danger");
			}

		}).catch(err => {
			// dispatch(actionError(err?.data?.message || "Registration is failed. Please contact to admin.", 8000, "Center", "Bottom"))
			setAlertMsg(err?.data?.message || "Registration is failed. Please contact to admin.");
			setAlertType("danger");
		})
	}

	/**
	  /**
	   * @description fetch countries to tag with user
	   */

	const searchCountry = useCallback(debounce(async (e) => {
		let filters = [];
		filters.push(["countryName", "cn", e.text]);
		let fields = { fields: ["countryName", "countryIsdCode", "id"] };
		const response = e.text.length > 2 && await fetchCountryForOutSourcing({ filters: filters, ...fields, sort: "countryName:asc", limit: 10 });
		if (response.data && response.data.data) {
			if (response.data.data.length) {
				setNoData("");
			}
			else {
				setNoData("No Record Found");
			}
			const resData = response.data.data.map(v => {
				return v.countryName
			});
			if (resData) {
				e.updateData(resData)

			} else {
				e.updateData([])
			}

			const countriesOptions = response.data.data.map(item => {
				return {
					label: item.countryName,
					value: item.id,
					countryCode: item.countryIsdCode,
				}
			})
			if (countriesOptions) {
				setCountries(countriesOptions)
			}
			else {
				setCountries([])
			}
		}
		else if (e.text.length < 2) {
			setCountries([])
			setNoData("Type atleast 3 characters");
		}
	}, 300), []);

	return <>
		<div className="p-2 container" >
			<div className="col-md-6 p-2 pt-0 mt-0 border-0 bg-transparent rounded-top"><img src={variant == 1 || variant === undefined ? fetchWhatNextLogo() : fetchInsiderLogo()} /></div>
			<div className="col-md-12 text-center p-2 border bg-dark text-white rounded-top"><h3>{variant == 1 || variant === undefined ? "WhatNext" : "Insider"} Expert Community Registration</h3></div>
			<div className="">
				{/* <div className="padding-bottom-150i"> */}
				<div id="content-box" >
					<div className="container-fluid custom-user-basic-bg mb-3">

						<div className="p-2">
							<div className="row">
								<div className="col-md-6 bg-white" dangerouslySetInnerHTML={{ __html: leftPaneHtml }}></div>
								<div className="col-md-6 bg-light">
									{hideDiv && <div style={{ fontFamily: "montserrat,sans-serif", position: "absolute", top: "50%", textAlign: "center", fontSize: "16px", fontWeight: "bold", color: "#044972" }}>{alertMsg}</div>}
									<div className={hideDiv ? "d-none" : ""}>
										<h5 className="text-center text-secondary p-2">Register & Get Started</h5>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group required">
													{/* <label htmlFor="fname">First Name</label> */}
													<input type="hidden" name="showOTP" id="showOTP" />
													<TextField
														type="text"
														placeholder="First Name *"
														autoComplete="off"
														id="userFname"
														name="userFname"
														formik={formik}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group required">
													{/* <label htmlFor="lname">Last Name</label> */}
													<TextField
														type="text"
														placeholder="Last Name *"
														autoComplete="off"
														id="userLname"
														name="userLname"
														formik={formik}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group required">
													{/* <label htmlFor="country">Country</label> */}
													<CountrySelect
														formik={formik}
														placeholder="Country *"
														id="country"
														name="country"
														autocomplete="off"
														// value={formik.values.country}
														allowFiltering={true}
														filtering={searchCountry}
														noData={noData}
														autofill={true}
														allowCustom={formik.values.country}
														filterType="StartsWith"
													/>
												</div>
											</div>
											<div className="col-md-6 " data-tip="Please enter valid mobile number without ISD code">
												<InputGroupText
													formik={formik}
													required="required"
													inputGroupText={(formik.values.country && `+${isd}`) || ""}
													inputGroupTextWidth="50px"
													type="text"
													id="userMobile"
													placeholder="Mobile Number *"
													name="userMobile"
												/>
												<ReactTooltip multiline={true} delay={1000} />
											</div>

										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group required">
													{/* <label htmlFor="email">Email</label> */}
													<TextField
														type="text"
														placeholder="Email *"
														autoComplete="off"
														id="userEmail"
														name="userEmail"
														formik={formik}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group required">
													{/* <label htmlFor="mobile">Mobile Number</label> */}
													{HIGHEST_EDUCATION && <Dropdown placeholder="Select highest education"
														className="custom-dropdownlist"
														formik={formik}
														value={formik.values.highestEducation}
														mode="Box"
														placeholder="Highest Education"
														// className="custom-multi-select"
														// fields={{ text: "label", value: "value" }}																
														dataSource={HIGHEST_EDUCATION}
														name="userHighestEducation"
														id="userHighestEducation"
													/>}
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													{/* <label htmlFor="cityName">City</label> */}
													<TextField
														formik={formik}
														type="text"
														id="city"
														placeholder="City"
														name="cityName"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													{/* <label htmlFor="address1">Address Line 1</label> */}
													<TextField
														formik={formik}
														type="text"
														placeholder="Address Line 1"
														id="address1"
														name="address1"
													/>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													{/* <label htmlFor="address2">Address Line 2</label> */}
													<TextField
														formik={formik}
														type="text"
														placeholder="Address Line 2"
														id="address2"
														name="address2"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													{/* <label htmlFor="postCode">Post Code</label> */}
													<TextField
														formik={formik}
														type="text"
														id="postCode"
														placeholder="Post Code"
														name="postCode"
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12">
												<div className="form-group">

													<TextField
														type="text"
														placeholder="LinkedIn URL"
														autoComplete="off"
														id="userLinkedinUrl"
														name="userLinkedinUrl"
														formik={formik}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group required">
													{/* <label>Technology Interest</label> */}
													{(variant == 1 || variant === undefined) && technologies.length > 0 && <MultiSelect
														formik={formik}
														value={formik.values.technologyInterest}
														mode="Box"
														placeholder="Technology Interest (multiple selections) *"
														// className="custom-multi-select"
														fields={{ text: "label", value: "value" }}
														dataSource={technologies}
														// value={formik.values["technologyInterest"]}												
														name="technologyInterest"
														id="technologyInterest"
													/>}
													{/* <Select placeholder="Technology Interest*"
													// className="custom-multi-select"
													options={technologies}
													value={formik.values["technologyInterest"]}
													onChange={(value) => {
														formik.setFieldValue("technologyInterest", value);
														if (value) {
															console.log(formik);
															formik.setFieldError("technologyInterest", "")
														}
													}}
													name="technologyInterest"
													isMulti
												/> */}
													<div className="text-danger" style={{ display: formik.touched["technologyInterest"] === true ? "block" : "none" }} role="alert">
														<small>{formik.touched["technologyInterest"] === true ? formik.errors["technologyInterest"] || "" : ""}</small>
													</div>
												</div>

											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group required">
													{/* <label>Industry Interest</label> */}
													{industry.length > 0 && <MultiSelect
														formik={formik}
														value={formik.values.industryInterest}
														mode="Box"
														placeholder="Industry Interest (multiple selections) *"
														// className="custom-multi-select"
														fields={{ text: "label", value: "value" }}
														dataSource={industry}
														// value={formik.values["industryInterest"]}												
														name="industryInterest"
														id="industryInterest"
													/>}
													<div className="text-danger" style={{ display: formik.touched["industryInterest"] === true ? "block" : "none" }} role="alert">
														<small>{formik.touched["industryInterest"] === true ? formik.errors["industryInterest"] || "" : ""}</small>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											{/* I accept the terms and conditions */}
											<div className="col-md-12">
												<div className="form-group">
													<CheckBoxComponent
														// type="checkbox"
														cssClass="customCss2"
														id="userTncAccepected"
														name="userTncAccepected"
														checked={formik.values["userTncAccepected"]}
														onChange={() => {
															formik.setFieldError("userTncAccepected", "Please check this field")
															formik.setFieldValue("userTncAccepected", !formik.values["userTncAccepected"]);
														}}
													// label={`I accept the`}
													/> I accept the
													<span style={{
														color: "#000",
														cursor: "pointer",
														marginLeft: "0.25rem",
														fontSize: "14px",
														fontWeight: "bold"
													}}
														onClick={() => {
															dispatch(showPopup(
																{
																	width: `40%`,
																	title: `<h2 class="text-secondary" style="font-weight: 700">Terms & Conditions</h2>`,
																	okText: "OK",
																	showCancelButton: false,
																	content: termsAndConditions
																}
															))
														}}
													> Terms &amp; Conditions</span>

													<div className="text-danger" style={{ display: formik.touched["userTncAccepected"] === true ? "block" : "none" }} role="alert">
														<small>{formik.touched["userTncAccepected"] === true ? formik.errors["userTncAccepected"] || "" : ""}</small>
													</div>
												</div>
											</div>
										</div>
										{showField ?
											<div className="row">
												<div className="col-md-4">
													<div className="form-group required">
														{/* <label htmlFor="userEmailOTP">Enter Email OTP</label> */}
														<TextField
															formik={formik}
															type="text"
															id="userEmailOTP"
															placeholder="Email OTP *"
															name="userEmailOTP"
														/>
													</div>
												</div>
												{showMobileOtp ?
													<div className="col-md-4">
														<div className="form-group required">

															<TextField
																formik={formik}
																type="text"
																id="userMobileOTP"
																placeholder="Mobile OTP *"
																name="userMobileOTP"
															/>
														</div>
													</div>
													: null
												}

												<div className="col-md-4 ">
													<div className="form-group">
														<a href="javascript:void(0)" className={`btn-link small ${disabled}`}
															onClick={regenerateOtp}
														>Regenerate OTP</a>
													</div>
												</div>
											</div>
											: null
										}
										<div className="container-fluid form-group">
											<div className="row pt-4 align-items-center justify-content-center">
												{/* <div className=" col-md-2 text-left">
												<button type="button" className="btn btn-outline-dark "
													onClick={() => {
														formik.resetForm(); 
														formik.setValues(initialState);														
														// multiSelectT.current.value=""
														// educationYearRef.current.value=""
													}}
												>Reset</button>
											</div> */}
												<div>
													{showField ?
														<button type="submit" className="btn btn-dark "
															onClick={validateOTP}
														// disabled={formik.values["userTncAccepected"] ? false : true}
														>
															Register Now
														</button>
														: <button type="submit" className="btn btn-dark "
															onClick={validateUserRegistrationForm}
															disabled={disabledBtn}
														>Proceed</button>
													}
												</div>
											</div>
											<div className={`text-${alertType}`} >
												<small dangerouslySetInnerHTML={{ __html: alertType !== "" ? alertMsg : "" }}></small>
											</div>
										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</>;
};

export default OutsourcingRegistration;
