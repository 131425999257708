import React, { useState } from "react";
import ContentBuilder from "../../../../components/contentBuilder";
import ContentSideBar from "../../../../components/contentSideBar";
import Remark from "../../../../components/contentBlockRemark/index";
import { fetchSingleContentBlock, updateContentBlock } from "../../../../../middleware/services/contentBlockApi";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { getLoggedInUser } from "../../../../../utilities";
import { ENTITY_TYPE } from "../../../../../constants";

function Design(props) {
  let remarkEntityType = ENTITY_TYPE.CONTENT_BLOCK;
  const [contentHtml, setContentHtml] = useState("");
  const contentBlockId = props?.contentBlockId;
  const dispatch = useDispatch();
  const [remarkLength, setRemarkLength] = useState("");
  let userId = getLoggedInUser();
  let formData = new FormData();
  formData.append("contentBlockModifiedUserId", userId._id);
  let defaultCategoryId = props?.snippetList.length > 0 && props.snippetList[0][0];

  /*
    @Description : Fetch single content block
  */
  const fetchContent = async () => {
    if (contentBlockId && !props.preservedHtml) {
      dispatch(showLoader());
      const response = fetchSingleContentBlock(contentBlockId, {
        fields: ["contentBlockContent"],
      });
      response
        .then((res) => {
          if (res.status == 200) {
            setContentHtml(res.data.data.contentBlockHtml);
            dispatch(hideLoader());
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something went wrong"));
        });
    } else {
      setContentHtml(props.preservedHtml);
    }
  };

  return (
    <div className="mt-4 px-2 pr-3 content-builder-wrapper d-flex">
      {
        <div
          className={`content-block-add editor_background
            ${props.viewRemark || props.viewBlocks ? "content_builder_width" : ""}
              `}
        >
          {/* Load content builder */}
          <ContentBuilder
            userType="Admin"
            fetchContent={fetchContent}
            contentHtml={contentHtml}
            contentField="contentBlockHtml"
            contentId={props.contentBlockId}
            setContentHtml={setContentHtml}
            saveContentFlag={props.saveContentBlock}
            saveContentApi={updateContentBlock}
            params={formData}
            loadContentBuilder={true}
            remarkEntityType={remarkEntityType}
            snippentList={props.snippetList}
            preserveContentHtml={props.preserveContentHtml}
            viewBlocks={props.viewBlocks}
            viewRemark={props.viewRemark}
            defaultCategoryId={defaultCategoryId}
          />
        </div>
      }
      {/* Show remark section */}
      {props.viewRemark && (
        <Remark
          remarkEntityType={remarkEntityType}
          remarkEntityId={props.contentBlockId}
          remarkLength={remarkLength}
          remarkCss="select_disposition"
          dispositionCss="select_disposition_overflow"
          {...props}
        />
      )}
      {/* Show sidebar */}
      <ContentSideBar
        remarkEntityId={props.contentBlockId}
        remarkEntityType={remarkEntityType}
        viewRemark={props.viewRemark}
        setViewRemark={props.setViewRemark}
        setRemarkLength={setRemarkLength}
        {...props}
      />
    </div>
  );
}

export default Design;
