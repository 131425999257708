/**
 * Author Mr. Narenkumar Krishnan
 * Date  16/06/24
 * @description This constants to display on the Landing page widgets
 */

import { CONTENT_TYPE, DELIVERABLE_TYPE } from "../constants/index";
import { lazy } from "react";

export const DASHBOARD_WIDGETS = {
  widget_1: lazy(() => import("../app/components/FeaturedReports")),
  widget_2: lazy(() => import("../app/components/DisruptiveSignals")),
  widget_3: lazy(() => import("../app/components/LatestNews")),
  widget_4: lazy(() => import("../app/components/KeyPlayers")),
  featured_content: lazy(() => import("../app/components/FeaturedReports")),
  signals: lazy(() => import("../app/components/DisruptiveSignals")),
  key_players: lazy(() => import("../app/components/KeyPlayers")),
  news: lazy(() => import("../app/components/LatestNews")),
  key_players_2: lazy(() => import("../app/components/KeyPlayers2")),
};
export const PINNED_WIDGETS = {
  widget_5: lazy(() => import("../app/components/PinnedContent")),
  widget_6: lazy(() => import("../app/components/PinnedContent")),
  widget_7: lazy(() => import("../app/components/PinnedContent")),
  widget_8: lazy(() => import("../app/components/PinnedContent")),
};
export const GRID_MEASURES = ["lg", "md", "sm", "xs", "xxs"];
export const NEWS_COUNT = [10, 15];
export const NEWS_SORT = [
  { key: "mostViews", value: "Most Views" },
  { key: "mostRecent", value: "Most Recent" },
  { key: "highestRated", value: "Highest Rated" },
];
export const CONTENT_SORT = [
  { key: "desc", value: "Newest" },
  { key: "asc", value: "Oldest" },
];

export const WIDGET_FILTER = {
  featured_content: {
    contentType: [CONTENT_TYPE.DEVELOPMENTS_NEWS, CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH, CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS],
    deliverableType: [DELIVERABLE_TYPE.DEVELOPMENTS_NEWS, DELIVERABLE_TYPE.INSIGHTS_REPORTS_RESEARCH, DELIVERABLE_TYPE.WEBINARS_INTERVIEWS_PODCASTS],
    readOnly: false,
  },
  signals: {
    contentType: [CONTENT_TYPE.SIGNALS],
    deliverableType: [DELIVERABLE_TYPE.SIGNALS],
    readOnly: true,
  },
  news: {
    contentType: [CONTENT_TYPE.DEVELOPMENTS_NEWS],
    deliverableType: [DELIVERABLE_TYPE.DEVELOPMENTS_NEWS],
    readOnly: true,
  },
  key_players: {
    contentType: [CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES],
    deliverableType: [DELIVERABLE_TYPE.PROFILES_COMPANIES_TECHNOLOGIES],
    readOnly: true,
  },
  key_players_2: {
    contentType: [CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES],
    deliverableType: [DELIVERABLE_TYPE.PROFILES_COMPANIES_TECHNOLOGIES],
    readOnly: true,
  },
  upcoming: {
    contentType: "",
    deliverableType: "",
    readOnly: false,
  },
  new: {
    contentType: "",
    deliverableType: "",
    readOnly: false,
  },
  pinned: {
    contentType: [
      CONTENT_TYPE.DEVELOPMENTS_NEWS,
      CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
      CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
      CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES,
    ],
    deliverableType: [
      DELIVERABLE_TYPE.DEVELOPMENTS_NEWS,
      DELIVERABLE_TYPE.INSIGHTS_REPORTS_RESEARCH,
      DELIVERABLE_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
      DELIVERABLE_TYPE.PROFILES_COMPANIES_TECHNOLOGIES,
    ],
    readOnly: false,
  },
};

//Dont change the values of LAYOUT_ORDER until change the Layout configurations of widgets
export const LAYOUT_ORDER = {
  "lg": [
      {
          "w": 3,
          "h": 5,
          "x": 0,
          "y": 0,
          "i": "widget_1",
          "moved": false,
          "static": false,
      },
      {
          "w": 3,
          "h": 5,
          "x": 3,
          "y": 0,
          "i": "widget_2",
          "moved": false,
          "static": false
      },
      {
          "w": 3,
          "h": 5,
          "x": 6,
          "y": 0,
          "i": "widget_3",
          "moved": false,
          "static": false
      },
      {
          "w": 3,
          "h": 5,
          "x": 9,
          "y": 0,
          "i": "widget_4",
          "moved": false,
          "static": false
      }
  ],
  "md": [
      {
          "i": "widget_1",
          "x": 0,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_2",
          "x": 2,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_3",
          "x": 4,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_4",
          "x": 6,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      }
  ],
  "sm": [
      {
          "i": "widget_1",
          "x": 0,
          "y": 0,
          "w": 3,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_2",
          "x": 3,
          "y": 0,
          "w": 3,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_3",
          "x": 0,
          "y": 1,
          "w": 3,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_4",
          "x": 3,
          "y": 1,
          "w": 3,
          "h": 4,
          "moved": false,
          "static": false
      }
  ],
  "xs": [
      {
          "i": "widget_1",
          "x": 0,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_2",
          "x": 2,
          "y": 0,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_3",
          "x": 0,
          "y": 1,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_4",
          "x": 2,
          "y": 1,
          "w": 2,
          "h": 4,
          "moved": false,
          "static": false
      }
  ],
  "xxs": [
      {
          "i": "widget_1",
          "x": 0,
          "y": 0,
          "w": 1,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_2",
          "x": 1,
          "y": 0,
          "w": 1,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_3",
          "x": 0,
          "y": 1,
          "w": 1,
          "h": 4,
          "moved": false,
          "static": false
      },
      {
          "i": "widget_4",
          "x": 1,
          "y": 1,
          "w": 1,
          "h": 4,
          "moved": false,
          "static": false
      }
  ]
};
export const WIDGETS = "Widgets";
export const PINNED_CONTENT = "Pinned Content";
export const ADD_WIDGETS = "Add Widget";
export const ADD_CONTENT = "Add Content";
export const SAVE_AND_NEXT_BUTTON_TEXT = "Save and Next";
export const SAVE_AND_PUBLISH_TEXT = "Save and Publish";
export const SAVE_DASHBOARD_TEXT = "Save Dashboard";
export const CANCEL_BUTTON_TEXT = "Cancel";
export const SIDEBAR_WIDGET_TITLE = "Select Widget Screen";
export const NEWS_CONTENT_TYPE_TEXT = "Content Curation";
export const NEWS_COUNT_TEXT = "Number of news to show";
export const WIDGET_SORT_TEXT = "Sort";
export const WIDGET_SORT_CLEAR = "Clear";
export const WIDGET_CONTENT_LEFT_SIDE_HEADER_TEXT = "Available Content for Widget";
export const WIDGET_CONTENT_RIGHT_SIDE_HEADER_TEXT = "Selected Content For Widget";
export const WIDGET_CONTENT_RIGHT_SIDE_MESSAGE = "Select items in the Available Content for Widget.";
export const HTML_TAG_HEADER_TEXT = "View More Link URL (Add HTML Tag)";
export const NO_DATA = "No data to show.";
export const NO_WIDGET_FOUND = "No Widget found!";
export const WIDGET_UPDATE_MESSAGE = "Widget updated successfully";
export const WIDGET_REMOVED_MESSAGE = "Widget removed successfully";
export const WIDGET_ADDED_SUCCESS_MESSAGE = "Widget added to dashboard successfully";
export const LINK =
  '<div class="home_signal_bottom" style="margin-bottom:15px"><div style="display:flex;flex-wrap: wrap;align-items:center;margin-top:15px"><a href="https://devdashboard.futurebridge.com/dashboard/6597e0c00331650162b8fc68" style="text-decoration:none;font-size:16px;"><span style="color:#f94f5e;font-family:\'Circular-Std-Bold\';padding-left:5px;line-height:1;font-size:16px;cursor:pointer;">Click here</span></a><p style="line-height:1;margin:0;margin-left:5px;font-size:16px;margin-right:6px;color:#4b4b55!important"> to view dashboard </p><img src="/assets/arrow.svg" alt="arrow" style="vertical-align:middle;transform:rotate(-90deg);" id="image6"></div></div>';
export const NEWS_LINK =
  '<div class="home_signal_bottom" style="margin-bottom:15px"><div style="display:flex;flex-wrap: wrap;align-items:center;margin-top:15px"><a href="https://devdashboard.futurebridge.com/dashboard/6597e0c00331650162b8fc68"            style="text-decoration:none;font-size:16px;"><span                style="color:#f94f5e;font-family:\'Circular-Std-Bold\';padding-left:5px;line-height:1;font-size:16px;cursor:pointer;">Click                here</span></a><p style="line-height:1;margin:0;margin-left:5px;font-size:16px;margin-right:6px;color:#4b4b55!important"> to            view all news </p><img src="/assets/arrow.svg" alt="arrow"            style="vertical-align:middle;transform:rotate(-90deg);" id="image6"></div></div>';
export const KEY_PLAYER_LINK =
  '<div class="home_signal_bottom" style="margin-bottom:15px"><div style="display:flex;flex-wrap: wrap;align-items:center;margin-top:15px"><a href="https://devdashboard.futurebridge.com/dashboard/6597e0c00331650162b8fc68"            style="text-decoration:none;font-size:16px;"><span                style="color:#f94f5e;font-family:\'Circular-Std-Bold\';padding-left:5px;line-height:1;font-size:16px;cursor:pointer;">Click                here</span></a><p style="line-height:1;margin:0;margin-left:5px;font-size:16px;margin-right:6px;color:#4b4b55!important"> to            view all startups </p><img src="/assets/arrow.svg" alt="arrow"            style="vertical-align:middle;transform:rotate(-90deg);" id="image6"></div></div>';
// export const MAXIMUM_CONTENT_LIMIT = 50;
export const MAXIMUM_CONTENT_LIMIT = 15;
export const PREVIOUS_BUTTON_TEXT = "Previous";
export const MERGE_TEXT = "Merge Content Blocks";
export const SPLIT_TEXT = "Split Content Blocks";
export const WIDGET_DISPLAY_NAME_MAX_VALIDATION_TEXT = "Please enter within 25 characters.";
export const WIDGET_DISPLAY_NAME_MIN_VALIDATION_TEXT = "Please enter min 3 characters.";
export const WIDGET_DISPLAY_NAME_REQUIRED_VALIDATION_TEXT = "Please enter widget display name.";
export const WIDGET_DISPLAY_NAME_MATCHES_VALIDATION_TEXT = "Please add valid widget display name using (A - Z, a-z, 0-9 , - and &)";

export const LAYOUT_SUCCESS_MESSAGE = "Dashboard changes saved successfully.";
export const LAYOUT_CONFIRMATION_MESSAGE ="Are you sure you want to save the changes on your dashboard?";
export const WIDGET_REMOVE_SUCCESS_MESSAGE = "Widget removed successfully.";
export const WIDGET_DELETE_FAILED_MESSAGE ="Failed to delete widget";
export const WIDGET_REMOVE_CONFIRMATION_MESSAGE ="Are you sure you want to remove the widget?";
// export const CONTENT_LIMIT_EXCEED_MESSAGE ="Maximum limit of 50 exceeded for the selected content.";
export const CONTENT_LIMIT_EXCEED_MESSAGE ="Maximum limit of 15 exceeded for the selected content.";
export const PINNED_UPDATED_SUCCESS_MESSAGE = "Pinned content updated successfully.";
export const PINNED_ADDED_SUCCESS_MESSAGE = "Pinned content added successfully.";
export const PINNED_CONTENT_MAX_LIMIT = 1;
export const EDITOR_RESTORE_TEXT ="Restore Default";