/* 
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : React Entry Point App Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 25/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./middleware/store";
import Routes from "./routes";
import { createSpinner } from "@syncfusion/ej2-popups";
import clearCache from "./utilities/clearCache";

const supportsHistory = "pushState" in window.history;
const ClearCacheComponent = clearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
	createSpinner({
		target: document.getElementById("root")
	});
	return (
		<Provider store={store}>
			<Router forceRefresh={!supportsHistory}>
				<Routes />
			</Router>
		</Provider>
	);
}

export default App;
