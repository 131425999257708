// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Content Type Filter
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 2023
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:


import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import { setContentTypeFilterSearch } from '../../../../middleware/actions/searchAction';
// import { uiAction } from '../../../../middleware/store/ui-slice';
// import { companyAction } from '../../../../middleware/store/company-slice';
// import { routes } from '../../../../routes/routes';
// import { newsAction } from '../../../../middleware/store/news-slice';
// import { keyReportAction } from '../../../../middleware/store/keyReports-slice';
import './index.css';
import PopupHeader from '../../popupHeader';
import { motion } from 'framer-motion/dist/framer-motion';

export default function IndustryFilter({ anchorEl, setAnchorEl, setActiveIndex }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const containerRef = useRef(null);
  const [modifiedContentTypeList, setModifiedContentTypeList] = useState([]);

  const contentType = useSelector((state) => state["searchState"]['contentTypeListSearch']);

  const selectedSearchFilters = useSelector((state) => state["searchState"]['selectedSearchFilters']);

  let contentTypeCount = useSelector((state) => state["searchState"]['contentTypeCount']);

  const coords = useMemo(() => {
    return anchorEl?.getBoundingClientRect();
  }, [anchorEl]);

  // Show Content Type
  const showContentType = () => {
    let path = location.pathname.split('/')[1];
    return !['company-profile', 'news-and-commentary', 'key-reports', 'search-result'].includes(path);
  };

  // const resetFilter = () => {
  //   dispatch(
  //     uiAction.updateUrlParams({
  //       id: '',
  //       page: '',
  //       sort: '',
  //     }),
  //   );
  //   if (location.pathname.toString().includes('/company-profile/')) {
  //     dispatch(companyAction.resetParams(''));
  //   }

  //   if (location.pathname.toString().includes('/news-and-commentary/')) {
  //     history.push(routes.newsPath);
  //     dispatch(newsAction.resetParams());
  //   }

  //   if (location.pathname.toString().includes('/key-reports/')) {
  //     history.push(routes.keyReportsPath);
  //     dispatch(keyReportAction.resetParams());
  //   }
  // };

  const addPrefixToLabels = (data, IdCount) => {
    const newData = [];

    for (const item of data) {
      let newItem = { ...item }; // Create a new object with the same properties
      if (item.contentType in IdCount) {
        const entityCount = IdCount[item.contentType];
        newItem.label = item.label.replace('0', entityCount);
        if (IdCount[item.contentType] == 0) newItem.disabled = true;
      } else {
        newItem.disabled = true;
      }
      newData.push(newItem);
    }

    return newData;
  }

  useEffect(() => {
    if (Object.entries(contentTypeCount).length) {
      let contentTypeWithCount;

      contentTypeWithCount = addPrefixToLabels(contentType, contentTypeCount);
      setModifiedContentTypeList(contentTypeWithCount);
    }
  }, [contentTypeCount]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        const loaderNode = document.getElementsByClassName('mainLoader');
        if (ref.current && !ref.current.contains(event.target) && loaderNode.length !== 1) {
          setAnchorEl(null);
          setTimeout(() => {
            setActiveIndex(null);
          }, 10);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(containerRef);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.1 },
        transform: `translateX(${coords?.right - coords.left - 190}px) translateY(${coords?.y - coords.top + 35}px)`,
      }}
      exit={{ opacity: 0 }}
      style={{
        position: 'absolute',
        zIndex: 1,
        left: coords.left,
        top: coords?.top,
        transform: `translateX(${coords?.right - coords.left - 190}px) translateY(${coords?.y - coords.top + 35}px)`,
        width: 'auto',
        height: 'fit-content',
        maxHeight: `calc(100% - ${coords.top}px - 10px)`,
        backgroundColor: 'white',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.25)',
        transition: 'all 0.3s ease',
      }}
      ref={containerRef}
    >
      <PopupHeader setAnchorEl={setAnchorEl} />
      {/* {showContentType() && ( */}
      <div
        className="content-type-div"
        style={{
          padding: '10px',
        }}
      >
        <CheckboxTree
          id="content-type-tree"
          nodes={modifiedContentTypeList}
          showNodeIcon={false}
          checked={selectedSearchFilters.selectedContentType}
          onCheck={(checked) => {
            dispatch(setContentTypeFilterSearch(checked));
            // resetFilter();
          }}
          icons={{
            check: <img src="/assets/check.svg" alt="checkbox" className="checkTree" />,
            uncheck: <img src="/assets/uncheck.svg" alt="checkbox" className="checkTree" />,
          }}
        />
      </div>
      {/* )} */}
    </motion.div>
  );
}
